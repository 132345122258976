import { AxiosInstance, AxiosPromise } from 'axios'

export type MinorityType = {
  id: string
  minority_type: string
  minority_description: string
  order: number
}

type MinorityStatusType = {
  firm_id: string
  minority_type: number
  status: boolean
}

export type MinorityStatusUpdate = {
  updates: { minority_type: number; status: boolean | null }[]
}

export interface MinorityService {
  fetchMinorityTypes: (countryCode: string) => AxiosPromise<MinorityType[]>
  fetchFundMinorityStatuses: (fundId: string) => AxiosPromise<{ fund_minority_statuses: MinorityStatusType[] }>
  fetchFirmMinorityStatuses: (firmId: string) => AxiosPromise<{ firm_minority_statuses: MinorityStatusType[] }>
  updateFundMinorityStatuses: (fundId: string, param: MinorityStatusUpdate) => AxiosPromise<void>
  updateFirmMinorityStatuses: (firmId: string, param: MinorityStatusUpdate) => AxiosPromise<void>
}

export const MinorityService = (request: AxiosInstance): MinorityService => {
  return {
    fetchMinorityTypes: (countryCode) => request.get(`/manager-link/countries/${countryCode}/minority-types`),
    fetchFundMinorityStatuses: (fundId) => request.get(`/manager-link/funds/${fundId}/minority-statuses`),
    fetchFirmMinorityStatuses: (firmId) => request.get(`/manager-link/firms/${firmId}/minority-statuses`),
    updateFundMinorityStatuses: (fundId, param) =>
      request.post(`/manager-link/funds/${fundId}/minority-statuses`, param),
    updateFirmMinorityStatuses: (firmId, param) =>
      request.post(`/manager-link/firms/${firmId}/minority-statuses`, param),
  }
}
