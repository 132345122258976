import { Button, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import { LeftNavExNewFundEnum } from '../../../shared/SearchExNewFundLabel'
import { TAB_LIST_CREATE_NEW_FUND } from './ExNewFundMain'
type Props = {
  setStepTab: (value: LeftNavExNewFundEnum) => void
  setActiveTab: (value: LeftNavExNewFundEnum) => void
  activeTab: LeftNavExNewFundEnum
  stepTab: LeftNavExNewFundEnum
  ArrayValidateField: string[]
  form: FormInstance
}
export const NextBackNewFundTab: React.FC<Props> = ({
  setActiveTab,
  setStepTab,
  activeTab,
  stepTab,
  ArrayValidateField,
  form,
}) => {
  const indexActive = TAB_LIST_CREATE_NEW_FUND.findIndex((i) => i.id === activeTab)

  const handleNext = () => {
    form
      .validateFields(ArrayValidateField)
      .then(() => {
        if (activeTab === stepTab) {
          setStepTab(TAB_LIST_CREATE_NEW_FUND[indexActive + 1].id)
        }
        setActiveTab(TAB_LIST_CREATE_NEW_FUND[indexActive + 1].id)
      })
      .catch(() => console.log('error '))
  }

  const handleBack = () => {
    form.validateFields(ArrayValidateField).then(() => {
      setActiveTab(TAB_LIST_CREATE_NEW_FUND[indexActive - 1].id)
    })
  }
  return (
    <Row style={{ justifyContent: 'center', margin: '1rem 0' }}>
      {indexActive > 0 && (
        <Button onClick={handleBack} style={{ marginRight: 8 }}>
          Back
        </Button>
      )}

      {indexActive + 1 !== TAB_LIST_CREATE_NEW_FUND.length && (
        <Button type="primary" onClick={handleNext}>
          Next
        </Button>
      )}
    </Row>
  )
}
