import React from 'react'
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom'
import { ForgotPassword } from './ForgotPassword/ForgotPassword'
import { SignUpView } from './signup/SignUpView'
import { LoginView } from './LoginView'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/rootReducer'
import { history } from '../../shared/history'

const Auth: React.FC = () => {
  const { path } = useRouteMatch()

  const auth = useSelector((state: RootState) => state.auth)
  const accessToken = auth?.accessToken

  React.useEffect(() => {
    if (accessToken) {
      history.push('/')
    }
  }, [accessToken])

  return (
    <Switch>
      <Route path={`${path}/log-in`}>
        <LoginView />
      </Route>
      <Route path={`${path}/sign-up`} component={SignUpView} />
      <Route path={`${path}/forgot-password`} component={ForgotPassword} />
      <Redirect to={`${path}/log-in`} />
    </Switch>
  )
}

export default Auth
