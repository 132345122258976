import { Space, Row, Col } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'
import { FundDropdownCodeType } from '../../../shared/api/models/FundDetails'
import { MinorityType } from '../../../shared/api/services/minority.service'
import { FormItemCheckBox, FormItemRadio } from '../../../shared/components/FormItemComponents'
import { RenderLabelForm } from '../../../shared/components/RenderLabelForm'
import { LeftNavExNewFundEnum } from '../../../shared/SearchExNewFundLabel'
import { NextBackNewFundTab } from './NextBackExNewFundTab'

type Props = {
  form: FormInstance
  setStepTab: (value: LeftNavExNewFundEnum) => void
  setActiveTab: (value: LeftNavExNewFundEnum) => void
  activeTab: LeftNavExNewFundEnum
  stepTab: LeftNavExNewFundEnum
  firmCountry: FundDropdownCodeType | undefined
  minorityTypes?: MinorityType[]
  setDataFundDetailChange: any
}

const Wrapper = styled.div`
  padding: 1rem;
`

const WrapperMinority = styled.div`
  padding: 1rem;
`
const ARRAY_FIELD_VALIDATE: string[] = []

const ExNewFundDI: React.FC<Props> = ({
  form,
  setActiveTab,
  setStepTab,
  activeTab,
  stepTab,
  firmCountry,
  minorityTypes,
  setDataFundDetailChange,
}) => {
  const isMinorityManagedFund = form.getFieldValue('is_minority_or_woman_managed_fund')

  React.useEffect(() => {
    if (!minorityTypes || !minorityTypes.length) {
      return
    }
    if (isMinorityManagedFund === false) {
      minorityTypes?.map((item) => {
        setDataFundDetailChange((prevState: any) => ({
          ...prevState,
          [`minority_type_${item.id}`]: false,
        }))
        form.setFieldsValue({ [`minority_type_${item.id}`]: false })
      })
    }
  }, [form, isMinorityManagedFund, minorityTypes, setDataFundDetailChange])

  return (
    <Wrapper>
      <Space direction="vertical">
        <Row>
          <Col span={24}>
            <h3>Minority-Owned Management Company</h3>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <i>
              Applicable only to the management firm that is physically headquartered in{' '}
              {firmCountry === undefined ? '[Firm_Country]' : firmCountry.code_description}
            </i>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <span>
              Minority-Owned: A hedge fund management company is deemed to be a minority-owned business if: Greater than
              50% of the management company is owned by one or more minorities, or in the case of a publicly owned
              business, greater than 50% of all issued stock is owned by one or more minorities; and whose management
              and daily business operations are controlled by one or more such individuals
            </span>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItemRadio
              name="is_minority_or_woman_managed_fund"
              label={<RenderLabelForm value="Is the fund/product managed by one or more minorities?" />}
              options={[
                {
                  value: true,
                  title: 'Yes',
                },
                {
                  value: false,
                  title: 'No',
                },
              ]}
            />
          </Col>
        </Row>
        <Row>
          {isMinorityManagedFund ? (
            <Col span={24}>
              <span>
                <i>If ‘Yes’, please check all that apply:</i>
              </span>
            </Col>
          ) : null}
        </Row>
        {isMinorityManagedFund ? (
          <WrapperMinority>
            <Row>
              <Col span={10}>
                <b>Type</b>
              </Col>
              <Col span={12}>
                <b>Description</b>
              </Col>
            </Row>
            {minorityTypes?.map((item, index) => {
              return (
                <Row key={index} style={{ margin: '5px 0' }}>
                  <Col span={10}>
                    <FormItemCheckBox
                      disable={!isMinorityManagedFund}
                      name={`minority_type_${item.id}`}
                      text={item.minority_type}
                    />
                  </Col>
                  <Col span={12}>{item.minority_description}</Col>
                </Row>
              )
            })}
          </WrapperMinority>
        ) : null}
      </Space>
      <NextBackNewFundTab
        setStepTab={setStepTab}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        stepTab={stepTab}
        ArrayValidateField={ARRAY_FIELD_VALIDATE}
        form={form}
      />
    </Wrapper>
  )
}

export default ExNewFundDI
