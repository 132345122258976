import React from 'react'
import styled from 'styled-components'
import { Colors } from '../../shared/colors'

const Container = styled.div`
  display: flex;
  height: 100vh;
`

const Cover = styled.div`
  flex-grow: 1;
  background: url('/cover.jpg');
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 40px;

  justify-content: center;
`

const CoverTitle = styled.h1`
  color: ${Colors.white};
  text-align: right;
  display: block;
  font-size: 100px;
  font-family: 'Open Sans', verdana, arial, sans-serif;
  font-weight: bolder;
  line-height: 100px;
  max-width: 750px;
  background: rgba(0, 0, 0, 0.2);
  margin: 0;
  padding: 10px;
`
const CoverSub = styled.h2`
  color: ${Colors.white};
  text-align: right;
  background: rgba(0, 0, 0, 0.2);
  font-size: 40px;
  font-family: 'Open Sans', verdana, arial, sans-serif;
  padding: 0 10px;
`

type Props = {
  title: string
  description?: string
}

const AuthLayout: React.FC<Props> = ({ title, description, children }) => {
  return (
    <Container>
      <Cover>
        <CoverTitle>{title}</CoverTitle>
        {description && <CoverSub>{description}</CoverSub>}
      </Cover>
      {children}
    </Container>
  )
}

export default AuthLayout
