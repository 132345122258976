import { Button, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import moment from 'moment'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import APIService from '../../../../../shared/api'
import { FundDetailDataContext } from '../../../../../shared/api/context/FundDetailDataContext'
import { FundDetailDropdownContext } from '../../../../../shared/api/context/FundDetailDropdownContext'
import { Colors } from '../../../../../shared/colors'
import { DividerForm } from '../../../../../shared/components/DividerForm'
import { FormItemInput, FormItemSelect, FormItemTextArea } from '../../../../../shared/components/FormItemComponents'
import { RenderLabelForm } from '../../../../../shared/components/RenderLabelForm'
import { useServiceState } from '../../../../../shared/hooks/useServiceState'

import { FirmDetails } from '../../../../../shared/api/models/FirmDetails'
import { FirmSelectModal } from './FirmSelectModal'
import { FormInstance } from 'antd/lib/form'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
const Flex = styled.div`
  display: flex;
`
type Props = { form: FormInstance; setIsUpdateFund: (isUpdate: boolean) => void }
export const FundContact: React.FC<Props> = ({ form, setIsUpdateFund }) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)

  const { dataFundDetail, dataFundDetailChange, setDataFundDetailChange } = React.useContext(FundDetailDataContext)
  const { invoke: getFirmUsers, data: firmUsers, loading: fetchingFirmUser } = useServiceState(
    APIService.firmDetailService.fetchFirmUser,
  )

  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = (contactInfo: FirmDetails) => {
    const result: Record<string, string | undefined> = {
      contact_name: contactInfo?.contact_name1,
      contact_address1: contactInfo?.contact_address1,
      contact_address2: contactInfo?.contact_address2,
      contact_city: contactInfo?.contact_city,
      contact_state: contactInfo?.contact_state,
      contact_postal_code: contactInfo?.contact_postal_code,
      contact_country: contactInfo?.country,
      contact_phone: contactInfo?.contact_phone,
      contact_fax: contactInfo?.contact_fax,
      contact_email: contactInfo?.contact_email,
      contact_web: contactInfo?.contact_web,
    }
    const keys = Object.keys(result)
    // // remove blank values
    // keys.forEach((key: string) => {
    //   if (!result[key]) {
    //     delete result[key]
    //   }
    // })
    // keys = Object.keys(result)

    form.setFieldsValue(result)
    // set changed + form status
    setDataFundDetailChange(
      {
        ...(dataFundDetailChange?.current || {}),
        ...result,
      },
      keys[keys.length - 1],
    )
    setIsUpdateFund(true)
    // close
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    if (!dataFundDetail) {
      return
    }
    getFirmUsers(dataFundDetail.firm_id)
  }, [dataFundDetail, getFirmUsers])

  const userColumns: ColumnProps<any>[] = [
    {
      title: 'Company',
      key: 'company',
      dataIndex: 'company',
      sorter: (a, b) => (b.company > a.company ? -1 : 1),
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      defaultSortOrder: 'ascend',
      render: (value, record) => {
        return value ? (
          <a
            href={`${process.env.REACT_APP_AUTH_API_URL}${record.change_user_admin_url}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {value}
          </a>
        ) : null
      },
      sorter: (a, b) => (b.email > a.email ? -1 : 1),
    },
    {
      title: 'First Name',
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: (a, b) => (b.email > a.email ? -1 : 1),
    },
    {
      title: 'Last Name',
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: (a, b) => (b.email > a.email ? -1 : 1),
    },
    {
      title: 'User Status',
      key: 'active',
      dataIndex: 'active',
      render: (value) => {
        return value ? (
          <span style={{ color: Colors.success, fontWeight: 500 }}>Active</span>
        ) : (
          <span style={{ color: Colors.danger, fontWeight: 500 }}>Inactive</span>
        )
      },
      sorter: (a, b) => (b.email > a.email ? -1 : 1),
    },
    {
      title: 'Last Activity',
      key: 'last_event_timestamp',
      dataIndex: 'last_event_timestamp',
      sorter: (a, b) => (moment(b).isAfter(moment(a)) ? 1 : -1),
      render: (value, record) => {
        return value ? (
          <a
            href={`${process.env.REACT_APP_AUTH_API_URL}${record.user_events_url}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {moment(value).format('YYYY-MM-DD HH:mm')}
          </a>
        ) : null
      },
    },
  ]

  return (
    <Wrapper>
      <Flex style={{ flexDirection: 'column' }}>
        <Flex
          style={{
            flexDirection: 'column',
            flex: 1,
            margin: '1rem',
            padding: '1rem',
            border: `solid 1px ${Colors.border}`,
          }}
        >
          <Button title="Import" onClick={showModal}>
            Import Contact from Firm
          </Button>
          <DividerForm />
          <FormItemInput
            name="contact_name"
            label={<RenderLabelForm value="Name" />}
            labelCol={6}
            wrapperCol={12}
            maxLength={60}
          />
          <FormItemInput
            name="contact_company"
            label={<RenderLabelForm value="Company" />}
            labelCol={6}
            wrapperCol={12}
            maxLength={60}
          />
          <FormItemInput
            name="contact_address1"
            label={<RenderLabelForm value="Address 1" />}
            labelCol={6}
            wrapperCol={12}
            maxLength={60}
          />
          <FormItemInput
            name="contact_address2"
            label={<RenderLabelForm value="Address 2" />}
            labelCol={6}
            wrapperCol={12}
            maxLength={60}
          />
          <Flex style={{ flex: 1 }}>
            <FormItemInput
              name="contact_city"
              label={<RenderLabelForm value="City" />}
              labelCol={12}
              wrapperCol={8}
              maxLength={30}
            />
            <FormItemSelect
              name="contact_state"
              label={<RenderLabelForm value="State" />}
              labelCol={4}
              wrapperCol={8}
              allowClear={true}
              options={
                fundDataDropdown?.contact_state && fundDataDropdown?.contact_state.length > 0
                  ? fundDataDropdown.contact_state.map((item) => ({
                      value: item.code_code,
                      text: item.code_description,
                    }))
                  : []
              }
            />
          </Flex>
          <Flex style={{ flex: 1 }}>
            <FormItemInput
              name="contact_postal_code"
              label={<RenderLabelForm value="Postal Code" />}
              labelCol={12}
              wrapperCol={8}
              maxLength={10}
            />
            <FormItemSelect
              name="contact_country"
              label={<RenderLabelForm value="Country" />}
              labelCol={4}
              wrapperCol={8}
              options={
                fundDataDropdown?.contact_country && fundDataDropdown?.contact_country.length > 0
                  ? fundDataDropdown.contact_country.map((item) => ({
                      value: item.code_code,
                      text: item.code_description,
                    }))
                  : []
              }
            />
          </Flex>
          <Flex style={{ flex: 1 }}>
            <FormItemInput
              name="contact_phone"
              label={<RenderLabelForm value="Phone" />}
              labelCol={12}
              wrapperCol={8}
              maxLength={30}
            />
            <FormItemInput
              name="contact_fax"
              label={<RenderLabelForm value="Fax" />}
              labelCol={4}
              wrapperCol={8}
              maxLength={30}
            />
          </Flex>
          <FormItemInput
            name="contact_email"
            label={<RenderLabelForm value="Email" />}
            labelCol={6}
            wrapperCol={12}
            maxLength={255}
          />
          <FormItemInput
            name="contact_web"
            label={<RenderLabelForm value="Web" />}
            labelCol={6}
            wrapperCol={12}
            maxLength={60}
          />
          <DividerForm />
          <FormItemTextArea row={5} name="contact_description" maxLength={255} />
          <DividerForm />
          <Table
            title={() => 'ML Users'}
            size="small"
            columns={userColumns}
            dataSource={firmUsers}
            loading={fetchingFirmUser}
          />
          <FirmSelectModal visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} />
        </Flex>
      </Flex>
    </Wrapper>
  )
}
