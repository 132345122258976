import { Col, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../../app/rootReducer'
import { FundDetailDataContext } from '../../../shared/api/context/FundDetailDataContext'
import { FundDetailDropdownContext } from '../../../shared/api/context/FundDetailDropdownContext'
import { FormItemSelect, FormItemTextArea } from '../../../shared/components/FormItemComponents'
import { RenderLabelExFund } from '../../../shared/components/RenderLabelExFund'
import { useFieldValidate } from '../../../shared/hooks/useEffectFieldValidate'
import { LeftNavExFundEnum } from '../../../shared/SearchExFundLabel'
import { EMERGING_MARKET_SET } from '../../Dashboard/FundDetail/FundInformation/Region/FundInfoRegion'
import { FormFieldType } from '../ExFundDetailMain'
import { RequiredDataHelper } from '../RequiredDataHelper'
const Flex = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 1rem;
  padding: 0 2rem;
`

type Props = {
  isDirty: boolean
  form: FormInstance
  onUpdateTabDirty: (id: LeftNavExFundEnum, isDirty: boolean) => void
}

type InvestmentStrategyFieldsType = Pick<
  FormFieldType,
  | 'strategy_code_new'
  | 'sub_strategy_code_new'
  | 'region_inv_focus'
  | 'strategy_description'
  | 'region_inv_focus_country'
>

const FIELDS: InvestmentStrategyFieldsType = {
  strategy_code_new: {
    key: 'strategy_code_new',
    name: 'Main Strategy Classification',
    isRequired: true,
  },
  sub_strategy_code_new: {
    key: 'sub_strategy_code_new',
    name: 'Sub-Strategy Classification',
    isRequired: true,
  },
  region_inv_focus: {
    key: 'region_inv_focus',
    name: 'Regional Investment Focus',
    isRequired: true,
  },
  strategy_description: {
    key: 'strategy_description',
    name: 'Strategy Description',
    isRequired: true,
  },
  region_inv_focus_country: {
    key: 'region_inv_focus_country',
    name: 'Sub-Region Focus',
    isRequired: true,
  },
}

export const ExFundInvestmentStrategy: React.FC<Props> = ({ isDirty, form, onUpdateTabDirty }) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)
  const { setDataFundDetailChange, dataFundDetailChange } = React.useContext(FundDetailDataContext)
  const GlobaldataDropdown = useSelector((state: RootState) => state.appData)

  useFieldValidate(
    Object.keys(FIELDS).filter((item) => FIELDS[item as keyof InvestmentStrategyFieldsType].isRequired),
    isDirty,
    form,
    onUpdateTabDirty,
    LeftNavExFundEnum.INVESTMENT_STRATEGY,
  )

  const selectedStrategy = form.getFieldValue('strategy_code_new')

  const optionSubstrategy = React.useMemo(() => {
    const newSubStrategyOptions = selectedStrategy
      ? GlobaldataDropdown && GlobaldataDropdown.subStrategies && GlobaldataDropdown.subStrategies.length > 0
        ? GlobaldataDropdown.subStrategies
            .filter((i) => i.strategy_code === form.getFieldValue('strategy_code_new'))
            .map((item) => ({
              value: item.sub_strategy_code,
              text: item.sub_strategy_name,
            }))
        : []
      : []

    return newSubStrategyOptions
  }, [GlobaldataDropdown, form, selectedStrategy])

  React.useEffect(() => {
    if (dataFundDetailChange?.current && Object.keys(dataFundDetailChange?.current).includes('strategy_code_new')) {
      if (
        (selectedStrategy &&
          optionSubstrategy.length > 0 &&
          optionSubstrategy.findIndex((item) => item.value === form.getFieldValue('sub_strategy_code_new')) === -1) ||
        (!selectedStrategy && form.getFieldValue('sub_strategy_code_new'))
      ) {
        form.setFieldsValue({ sub_strategy_code_new: null })
        setDataFundDetailChange((prev) => ({ ...prev, sub_strategy_code_new: null }))
      }
    }
  }, [dataFundDetailChange, form, optionSubstrategy, selectedStrategy, setDataFundDetailChange])

  // useFieldValidate(
  //   ARRAY_FIELD_VALIDATE,
  //   isDirty,
  //   form,
  //   onUpdateTabList,
  //   onUpdateTabDirty,
  //   LeftNavExFundEnum.INVESTMENT_STRATEGY,
  // )

  const selectedRegion = form.getFieldValue('region_inv_focus')
  const isFundActive = form.getFieldValue('fund_status_code') === '2'
  const regionCountryOptions = React.useMemo(() => {
    if (!fundDataDropdown) {
      return []
    }
    if (!fundDataDropdown.region_inv_focus_country || fundDataDropdown.region_inv_focus_country.length === 0) {
      return []
    }

    const options = selectedRegion
      ? fundDataDropdown.region_inv_focus_country.filter(
          (item) => item.code_code.slice(0, selectedRegion.length) === selectedRegion,
        )
      : fundDataDropdown.region_inv_focus_country
    return options.map((item) => ({ value: item.code_code, text: item.code_description }))
  }, [fundDataDropdown, selectedRegion])

  const regionValue = form.getFieldValue('region_inv_focus')

  React.useEffect(() => {
    if (dataFundDetailChange?.current && Object.keys(dataFundDetailChange?.current).includes('region_inv_focus')) {
      if (EMERGING_MARKET_SET.includes(regionValue)) {
        form.setFieldsValue({ is_emerging_markets_fund: true })
        setDataFundDetailChange((prev) => ({ ...prev, is_emerging_markets_fund: true }))
      } else {
        setDataFundDetailChange((prev) => ({ ...prev, is_emerging_markets_fund: false }))
        form.setFieldsValue({ is_emerging_markets_fund: false })
      }
      if (
        (selectedRegion &&
          form.getFieldValue('region_inv_focus_country') &&
          regionCountryOptions.length > 0 &&
          regionCountryOptions.findIndex((item) => item.value === form.getFieldValue('region_inv_focus_country')) ===
            -1) ||
        (!selectedRegion && form.getFieldValue('region_inv_focus_country'))
      ) {
        form.setFieldsValue({ region_inv_focus_country: null })
        setDataFundDetailChange((prev) => ({ ...prev, region_inv_focus_country: null }))
      }
    }
  }, [dataFundDetailChange, form, regionCountryOptions, regionValue, selectedRegion, setDataFundDetailChange])

  return (
    <Flex>
      <Row>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemSelect
            name="strategy_code_new"
            label={<RenderLabelExFund value="Main Strategy Classification" />}
            labelCol={12}
            wrapperCol={18}
            disabled={isFundActive}
            options={
              GlobaldataDropdown?.strategies && GlobaldataDropdown?.strategies.length > 0
                ? GlobaldataDropdown.strategies.map((item) => ({
                    value: item.strategy_code,
                    text: item.strategy_name,
                  }))
                : []
            }
            rules={
              FIELDS['strategy_code_new'].isRequired ? [{ required: true, message: 'This field is required!' }] : []
            }
          />
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemSelect
            name="sub_strategy_code_new"
            label={<RenderLabelExFund value="Sub-Strategy Classification" />}
            labelCol={12}
            wrapperCol={18}
            disabled={!selectedStrategy || isFundActive}
            options={optionSubstrategy}
            rules={
              FIELDS['sub_strategy_code_new'].isRequired ? [{ required: true, message: 'This field is required!' }] : []
            }
          />
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemSelect
            name="region_inv_focus"
            label={<RenderLabelExFund value="Regional Investment Focus" />}
            labelCol={12}
            wrapperCol={18}
            disabled={isFundActive}
            options={
              fundDataDropdown?.region_inv_focus && fundDataDropdown?.region_inv_focus.length > 0
                ? fundDataDropdown.region_inv_focus.map((item) => ({
                    value: item.code_code,
                    text: item.code_description,
                  }))
                : []
            }
            rules={
              FIELDS['region_inv_focus'].isRequired ? [{ required: true, message: 'This field is required!' }] : []
            }
          />
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemSelect
            name="region_inv_focus_country"
            label={<RenderLabelExFund value="Sub-Region Focus" />}
            labelCol={12}
            wrapperCol={18}
            options={regionCountryOptions}
            disabled={!selectedRegion || isFundActive}
            rules={
              FIELDS['region_inv_focus_country'].isRequired
                ? [{ required: true, message: 'This field is required!' }]
                : []
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItemTextArea
            name="strategy_description"
            label={<RenderLabelExFund value="Strategy Description" />}
            row={15}
            wrapperCol={24}
            rules={
              FIELDS['strategy_description'].isRequired ? [{ required: true, message: 'This field is required!' }] : []
            }
          />
        </Col>
      </Row>
      {!isFundActive && (
        <Row>
          <div style={{ fontStyle: 'italic' }}>
            <b>Note:</b> Contact HFR at <a href="mailto:db-support@hfr.com">db-support@hfr.com</a> for questions
            regarding the strategy or regional focus classifications
          </div>
        </Row>
      )}
    </Flex>
  )
}
RequiredDataHelper.default().registerRequirement(
  LeftNavExFundEnum.INVESTMENT_STRATEGY,
  Object.keys(FIELDS).filter((item) => FIELDS[item as keyof InvestmentStrategyFieldsType].isRequired),
)
