import {
  CaretDownOutlined,
  CaretUpOutlined,
  DownloadOutlined,
  ExclamationCircleFilled,
  UploadOutlined,
} from '@ant-design/icons'
import { Button, notification, Popconfirm, Row, Select, Spin, Tooltip } from 'antd'
import JsFileDownload from 'js-file-download'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import ReactDataSheet from 'react-datasheet'
import { Link } from 'react-router-dom'
import ReactSelect, { ValueType } from 'react-select'
import styled from 'styled-components'
import { DATE_FORMAT } from '../../routes/Dashboard/FundDetail/FundROR/PerformanceUpdate'
import APIService from '../api'
import { FirmDetailDataContext } from '../api/context/FirmDetailDataContext'
import { FundDetailDataContext } from '../api/context/FundDetailDataContext'
import { Colors } from '../colors'
import { getMonthRange } from '../utils/datetimeUtils'
import { isWarningAssetData } from '../utils/validateData'
import { ModalConfirmUpload } from './ModalConfirmUpload'

const Wrapper = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`

export enum DataSheetType {
  ROR = 'ROR',
  ASSET = 'ASSET',
  NAV = 'NAV',
  DISTRIBUTION = 'DISTRIBUTION',
  FIRM_ASSETS = 'FIRM_ASSET',
  FIRM_ASSETS_HF = 'FIRM_ASSET_HF',
}

export interface GridElement extends ReactDataSheet.Cell<GridElement> {
  value: string | null
  originalValue?: string | null
  date?: string | null
}

export interface RORResponseType {
  value: number | null
  yyyy_mm: string
  isEdited?: boolean
  isReadOnly?: boolean
}

export interface RORResponseCalculate {
  value: number
  date: string
}

export interface UpdateRORType {
  values: RORResponseType[] | undefined
}

const monthConvert = [
  { text: 'Jan', value: '01' },
  { text: 'Feb', value: '02' },
  { text: 'Mar', value: '03' },
  { text: 'Apr', value: '04' },
  { text: 'May', value: '05' },
  { text: 'Jun', value: '06' },
  { text: 'Jul', value: '07' },
  { text: 'Aug', value: '08' },
  { text: 'Sep', value: '09' },
  { text: 'Oct', value: '10' },
  { text: 'Nov', value: '11' },
  { text: 'Dec', value: '12' },
]

const parseGridData = (data: GridElement[][]): UpdateRORType => {
  const arrayUpdate: RORResponseType[] = []

  data.forEach((row, rowIndex) => {
    if (rowIndex === 0) {
      return
    }

    row.forEach((cell, cellIndex) => {
      if (cellIndex === 0 || cellIndex === 13) {
        return
      }
      const year = row[0].value
      if (!year) {
        return
      }

      if (cell.value && cell.value !== '') {
        arrayUpdate.push({
          yyyy_mm: `${row[0].value}-${monthConvert.find((item) => item.text === data[0][cellIndex].value)?.value}`,
          value: +cell.value,
          isEdited: cell.value !== cell.originalValue,
        })
      }
    })
  })

  return {
    values: arrayUpdate,
  }
}

const sortByYear = (isAscending: boolean) => (a: GridElement[], b: GridElement[]) => {
  if (b[0].value === 'Year') {
    return 1
  }
  if (!a[0].value) {
    return 1
  }
  if (!b[0].value) {
    return -1
  }

  return isAscending
    ? Number.parseInt(a[0].value) - Number.parseInt(b[0].value)
    : Number.parseInt(b[0].value) - Number.parseInt(a[0].value)
}

const compareCellValue = (prev: string | null, next: string | null, type?: DataSheetType) => {
  const prevValue = prev ? +prev : null
  const nextValue = next ? +next : null
  if (type === DataSheetType.ASSET) {
    if (!prev && next) {
      return false
    }
    return isWarningAssetData(prevValue, nextValue)
  }
  if (type === DataSheetType.NAV) {
    if (next !== null && nextValue === 0) {
      return true
    }
  }
  if (type === DataSheetType.ROR && nextValue !== null) {
    if (nextValue >= 20 || nextValue <= -20 || (next && nextValue === 0)) {
      return true
    }
  }

  return false
}

type Props = {
  draft?: RORResponseType[]
  initialData?: RORResponseType[]
  loading?: boolean
  canAdd?: boolean
  setRORDataChange?: (value: UpdateRORType) => void
  type?: DataSheetType
  isReadOnly?: boolean
  isInCreateNewExFund?: boolean
  currencyValue?: string
  minDate?: string | null
  onCellSelect?: (date: string) => void
  isExternal?: boolean
}

//////////////////////////////////////
const DataSheet: React.FC<Props> = ({
  draft,
  initialData,
  loading,
  setRORDataChange,
  type,
  isReadOnly,
  isInCreateNewExFund,
  currencyValue,
  minDate,
  onCellSelect,
  isExternal = false,
}) => {
  const headerRow = React.useMemo(() => {
    const header = [
      { readOnly: true, value: 'Year' },
      ...monthConvert.map((item) => ({ readOnly: true, value: item.text })),
    ]
    return type === DataSheetType.ROR ? [...header, { value: 'Annual', readOnly: true }] : header
  }, [type])
  const { dataFundDetail } = React.useContext(FundDetailDataContext)
  const { dataFirmDetail } = React.useContext(FirmDetailDataContext)
  const [grid, setGrid] = React.useState<GridElement[][]>([headerRow])
  const [originalGrid, setOriginalGrid] = React.useState<GridElement[][]>([headerRow])
  const [fileData, setFileData] = React.useState<RORResponseType[]>()
  const [draftData, setDraftData] = useState(draft)
  const [showModalUpload, setShowModalUpload] = React.useState(false)
  const [isUpdate, setIsUpdate] = React.useState(false)

  const [isAscending, setIsAscending] = React.useState(true)

  React.useEffect(() => {
    // Clear draft if no draft is given (used for when cancel)
    if (!draft) {
      setDraftData(undefined)
      setIsUpdate(false)
      setGrid(originalGrid)
    }
  }, [draft, originalGrid])

  const formatNumber = React.useCallback(
    (value: number) => {
      return type === DataSheetType.ASSET || type === DataSheetType.FIRM_ASSETS || type === DataSheetType.FIRM_ASSETS_HF
        ? value.toFixed(3)
        : type === DataSheetType.NAV
        ? value.toFixed(6)
        : type === DataSheetType.ROR
        ? value.toFixed(4)
        : value.toFixed(2)
    },
    [type],
  )

  // HFRDB-1751 - generate table from firm's year founded to the current month - 1 for External Firm Asset/Firm Asset HF
  const generateDataSheetData = React.useCallback(
    (data?: RORResponseType[]) => {
      // console.log(minDate)
      // if (!minDate) {
      //   return data
      // }
      const startDate = moment.min([
        data && data.length ? moment(data[0].yyyy_mm, DATE_FORMAT) : moment(),
        minDate ? moment(minDate, 'YYYY-MM') : moment('2016-01', DATE_FORMAT),
      ])

      const endDate = moment.max([
        data && data.length ? moment(data[data.length - 1].yyyy_mm, DATE_FORMAT) : moment().subtract(1, 'month'),
        moment().subtract(1, 'month'),
      ])

      const monthRange = getMonthRange(startDate.format(DATE_FORMAT), endDate.format(DATE_FORMAT))

      return monthRange.reduce((acc, curr) => {
        const value = data && data.find((item) => item.yyyy_mm === curr)?.value
        return [...acc, { yyyy_mm: curr, value: value != null ? value : null }]
      }, [] as RORResponseType[])
    },
    [minDate],
  )

  const calculateAnnual = React.useCallback(
    (data: GridElement[]) => {
      const numerator =
        data
          .filter((x, index) => x.value && index !== 0 && x.value !== '')
          .map((x) => {
            const value = x.value ? parseFloat(x.value) : 0
            return Math.log(1 + value / 100)
          })
          .reduce((x, y) => x + y, 0) * 12

      const value = 100 * (-1 + Math.exp(numerator / 12))
      return { value: formatNumber(value), readOnly: true }
    },
    [formatNumber],
  )

  // transform data from yyyy_mm format to GridElement[][]
  const transformedData = React.useCallback(
    (data: RORResponseType[], type?: DataSheetType) => {
      return data.reduce((accumulator, currentValue) => {
        const year = currentValue.yyyy_mm.split('-')[0]
        const month = +currentValue.yyyy_mm.split('-')[1]
        const index = accumulator.findIndex((item) => item[0] && item[0].value === year)
        if (index > -1) {
          const currentRow = accumulator[index]
          const rowData = [
            ...currentRow.slice(0, month),
            {
              value: currentValue.value != null ? formatNumber(currentValue.value) : null,
              originalValue: currentValue.value != null ? formatNumber(currentValue.value) : null,
              date: currentValue.yyyy_mm,
            },
            ...currentRow.slice(month + 1, currentRow.length),
          ]

          const finalRow =
            type === DataSheetType.ROR
              ? [...rowData.slice(0, rowData.length - 1), calculateAnnual(rowData.slice(0, rowData.length - 1))]
              : rowData

          return [...accumulator.slice(0, index), finalRow, ...accumulator.slice(index + 1, accumulator.length)]
        }

        // special case to handle calculating the annual if there is only data for the last month of the first year
        if (month === 12 && type === DataSheetType.ROR) {
          const rowTemplate = [{ value: year }, ...new Array(13).fill({ date: '' })]
          const rowData = [
            ...rowTemplate.slice(0, 12),
            {
              value: currentValue.value != null ? formatNumber(currentValue.value) : null,
              originalValue: currentValue.value != null ? formatNumber(currentValue.value) : null,
              date: currentValue.yyyy_mm,
            },
            calculateAnnual([
              ...rowTemplate.slice(0, 12),
              {
                value: currentValue.value != null ? formatNumber(currentValue.value) : null,
                originalValue: currentValue.value != null ? formatNumber(currentValue.value) : null,
                date: currentValue.yyyy_mm,
              },
            ]),
          ]
          return [...accumulator, rowData]
        }

        const rowTemplate = [{ value: year }, ...new Array(type === DataSheetType.ROR ? 13 : 12).fill({ date: '' })]
        const rowData = [
          ...rowTemplate.slice(0, month),
          {
            value: currentValue.value != null ? formatNumber(currentValue.value) : null,
            originalValue: currentValue.value != null ? formatNumber(currentValue.value) : null,
            date: currentValue.yyyy_mm,
          },
          ...rowTemplate.slice(month + 1, rowTemplate.length),
        ]
        return [...accumulator, rowData]
      }, [] as GridElement[][])
    },
    [calculateAnnual, formatNumber],
  )

  React.useEffect(() => {
    if (draftData) {
      setIsUpdate(true)
    }
  }, [draftData])

  const finalData = useMemo(() => {
    const data = fileData || draftData || initialData
    const generatedData = generateDataSheetData(data)
    return generatedData
  }, [fileData, draftData, initialData, generateDataSheetData])

  React.useEffect(() => {
    if (initialData) {
      setIsUpdate(false)
    }
  }, [initialData])

  React.useEffect(() => {
    if (isUpdate && setRORDataChange) {
      setRORDataChange(parseGridData(grid))
    }
  }, [grid, isUpdate, setRORDataChange])

  React.useEffect(() => {
    if (initialData) {
      const generatedInitialData = generateDataSheetData(initialData)
      generatedInitialData && setOriginalGrid([headerRow, ...transformedData(generatedInitialData, type)])
    }
  }, [generateDataSheetData, headerRow, initialData, transformedData, type])

  const hashedOriginalData = useMemo(() => {
    if (!initialData) {
      return
    }
    return initialData.reduce((s, v) => {
      const year = v.yyyy_mm.split('-')[0]
      const month = +v.yyyy_mm.split('-')[1]

      return {
        ...s,
        [`${year}_${month}`]:
          v.value != null
            ? type === DataSheetType.ASSET ||
              type === DataSheetType.FIRM_ASSETS ||
              type === DataSheetType.FIRM_ASSETS_HF
              ? +v.value.toFixed(3)
              : type === DataSheetType.NAV
              ? +v.value.toFixed(6)
              : type === DataSheetType.ROR
              ? +v.value.toFixed(4)
              : +v.value.toFixed(2)
            : null,
      }
    }, {} as { [key: string]: number | null })
  }, [initialData, type])

  React.useEffect(() => {
    if (finalData) {
      setGrid([headerRow, ...transformedData(finalData, type)])
    }
  }, [finalData, headerRow, transformedData, type])

  // const handleAddRow = () => {
  //   setGrid((prev) => [
  //     ...prev,
  //     type === DataSheetType.ROR ? [...new Array(13).fill({}), { readOnly: true }] : new Array(13).fill({}),
  //   ])
  // }

  const handleRevertChanges = () => {
    setFileData(undefined)
    setGrid(originalGrid)
    setRORDataChange && setRORDataChange(parseGridData(originalGrid))
    setIsUpdate(false)
  }

  type OptionType = { label: string; value: string }

  const YearSelect = ({
    value,
    onCommit,
    onRevert,
  }: {
    value: string | undefined
    onCommit: (value: string, event?: React.KeyboardEvent<HTMLElement>) => void
    onRevert: () => void
  }) => {
    const ENTER_KEY = 13
    const TAB_KEY = 9
    const [event, setEvent] = React.useState<React.KeyboardEvent<HTMLElement> | undefined>()

    function handleChange(opt: ValueType<OptionType>) {
      if (minDate && parseInt((opt as OptionType).value) - moment(minDate).year() < 0) {
        notification.error({
          message:
            type === DataSheetType.FIRM_ASSETS || type === DataSheetType.FIRM_ASSETS_HF
              ? `Year value must be on or after the year of Firm's Founded Date.`
              : `Year value must be on or after the year of Fund's Inception Date.`,
        })
        return onRevert()
      }
      if (!opt) {
        return onRevert()
      }
      onCommit((opt as OptionType).value, event)
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLElement>) {
      // record last key pressed so we can handle enter
      if (e.which === ENTER_KEY || e.which === TAB_KEY) {
        e.persist()
        setEvent(e)
      } else {
        setEvent(undefined)
      }
    }

    const options = new Array(2100 - 1970)
      .fill(null)
      .map((_, index) => ({ label: (1970 + index).toString(), value: (1970 + index).toString() }))

    return (
      <ReactSelect
        autoFocus
        openMenuOnFocus
        menuPlacement="top"
        showSearch
        style={{ width: '100%' }}
        options={options}
        value={value ? { label: value, value } : undefined}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        styles={{
          indicatorSeparator: () => ({ display: 'none' }),
        }}
      >
        {options.map((option) => {
          return (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          )
        })}
      </ReactSelect>
    )
  }

  // object with year keys to track duplicated year

  const sortedGrid = React.useMemo(() => grid.sort(sortByYear(isAscending)), [grid, isAscending])

  const currentYears = React.useMemo(
    () =>
      grid.slice(1, grid.length).reduce<{ [key: string]: number[] }>((acc, row, index) => {
        if (row[0].value) return { ...acc, [row[0].value]: [...(acc[row[0].value] || []), index] }
        return acc
      }, {}),
    [grid],
  )

  const setFileName = (type: DataSheetType) => {
    switch (type) {
      case DataSheetType.ASSET:
        return 'asset'
      case DataSheetType.ROR:
        return 'ror'
      case DataSheetType.NAV:
        return 'nav'
      case DataSheetType.DISTRIBUTION:
        return 'distribution'
      case DataSheetType.FIRM_ASSETS:
        return 'firm_asset'
      default:
        break
    }
  }

  const handleExportCSV = async () => {
    if (!type) {
      return
    }
    const fileName = setFileName(type) || ''
    if (dataFirmDetail) {
      const id = dataFirmDetail.firm_id
      const res = await APIService.fundService.fetchRORExport(fileName, id)
      JsFileDownload(res.data, `${id}-${fileName}.csv`, 'application/csv')
    }
    if (dataFundDetail) {
      const id = dataFundDetail.fund_id
      const res = await APIService.fundService.fetchRORExport(fileName, id)
      JsFileDownload(res.data, `${id}-${fileName}.csv`, 'application/csv')
    }
  }

  return (
    <Wrapper>
      {!isReadOnly && (
        <>
          <Row style={{ marginBottom: 8, justifyContent: 'space-between', flex: 1 }}>
            <div>
              {!isInCreateNewExFund && (
                <Button onClick={handleExportCSV}>
                  <DownloadOutlined />
                  Export as CSV
                </Button>
              )}
              {!isExternal && (
                <>
                  <Button type="primary" style={{ marginLeft: '0.5rem' }} onClick={() => setShowModalUpload(true)}>
                    <UploadOutlined /> Import from CSV
                  </Button>

                  {showModalUpload && (
                    <ModalConfirmUpload
                      setIsUpdate={setIsUpdate}
                      setFileData={setFileData}
                      isOpen={showModalUpload}
                      setOpen={setShowModalUpload}
                      minDate={minDate}
                    />
                  )}
                  <Link to="/ror-template.csv" target="_blank">
                    <Button type="link">
                      <DownloadOutlined />
                      Download Template CSV
                    </Button>
                  </Link>
                </>
              )}
            </div>
            {type !== DataSheetType.FIRM_ASSETS && type !== DataSheetType.FIRM_ASSETS_HF && (
              <div>
                <Popconfirm
                  title="Are you sure you want to discard the changes?"
                  onConfirm={handleRevertChanges}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="link" loading={loading} disabled={!isUpdate}>
                    Revert Changes
                  </Button>
                </Popconfirm>
              </div>
            )}
          </Row>
          {currencyValue && (
            <Row justify="end">
              <span style={{ marginRight: '0.5rem' }}>
                Currency: <b>{currencyValue}</b>
              </span>
            </Row>
          )}
        </>
      )}
      <ReactDataSheet<GridElement>
        onSelect={(selection) => {
          // single select
          if (
            onCellSelect &&
            selection.end.i === selection.start.i &&
            selection.start.j === selection.end.j &&
            selection.start.i >= 1 &&
            1 <= selection.start.j &&
            selection.start.j <= 12
          ) {
            const selectedYear = grid[selection.start.i][0].value
            const selectedMonth = selection.start.j < 10 ? `0${selection.start.j}` : selection.start.j
            selectedYear && setTimeout(() => onCellSelect(`${selectedYear}-${selectedMonth}`), 0)
          }
        }}
        data={
          sortedGrid
            ? sortedGrid.map((row, index) => [
                index === 0
                  ? {
                      ...row[0],
                      readOnly: isReadOnly ? true : row[0].readOnly,
                      valueViewer: (props) => {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                            onClick={() => setIsAscending(!isAscending)}
                          >
                            {isAscending ? <CaretUpOutlined size={8} /> : <CaretDownOutlined size={8} />}
                            <span style={{ alignSelf: 'flex-end' }}>{props.value}</span>
                          </div>
                        )
                      },
                    }
                  : {
                      ...row[0],
                      readOnly: true, // disable editting year according to https://hfrsvc.atlassian.net/jira/software/projects/HFRDB/boards/1?assignee=61d7b2c068926d0068780b3f&selectedIssue=HFRDB-2083
                      dataEditor: (props) => (
                        <YearSelect
                          onCommit={props.onCommit}
                          onRevert={props.onRevert}
                          value={props.value?.toString() || undefined}
                        />
                      ),
                      valueViewer: (props) => {
                        const isDuplicated = row[0].value && currentYears[row[0].value.toString()]?.length > 1
                        return (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: isDuplicated ? 'space-between' : 'flex-end',
                              alignItems: 'center',
                            }}
                          >
                            {isDuplicated && (
                              <Tooltip title="Duplicated Year">
                                <ExclamationCircleFilled style={{ color: 'red' }} />
                              </Tooltip>
                            )}
                            <span style={{ alignSelf: 'flex-end' }}>{props.value}</span>
                          </div>
                        )
                      },
                    },

                ...row.slice(1, row.length).map((item, rowIndex) => {
                  const year = row[0].value
                  const prevMonthAsDate = moment(`${year}-${rowIndex + 1}`, DATE_FORMAT).subtract(1, 'month')

                  let prevCell = null

                  if (prevMonthAsDate.get('month') === 11 && index > 1) {
                    prevCell =
                      sortedGrid[index - 1].find((item) => item.date === prevMonthAsDate.format(DATE_FORMAT))?.value ||
                      null
                  } else {
                    prevCell = row.find((item) => item.date === prevMonthAsDate.format(DATE_FORMAT))?.value || null
                  }

                  const originalCell =
                    hashedOriginalData &&
                    hashedOriginalData[`${year}_${rowIndex + 1}`] !== undefined &&
                    hashedOriginalData[`${year}_${rowIndex + 1}`] !== null
                      ? `${hashedOriginalData[`${year}_${rowIndex + 1}`]}`
                      : null

                  const isEdited =
                    ((!item.value && originalCell) ||
                      (item.value && !originalCell) ||
                      (item.value && originalCell && +item.value !== +originalCell)) &&
                    index !== 0 &&
                    rowIndex !== 12 &&
                    !(isExternal && dataFundDetail?.fund_status_code === '2' && originalCell === null)

                  const isWarning =
                    rowIndex !== 12 &&
                    item.value &&
                    (compareCellValue(type === DataSheetType.ASSET ? prevCell : originalCell, item.value, type) ||
                      (type === DataSheetType.ASSET && +item.value === 0))

                  const isOutOfDateRange =
                    rowIndex !== 12 &&
                    (moment()
                      .subtract(1, 'month')
                      .isBefore(moment(`${year}-${rowIndex + 1}`)) ||
                      (moment().subtract(1, 'month').month() < rowIndex &&
                        year &&
                        parseInt(year) === moment().year()) ||
                      (minDate &&
                        moment(minDate).month() > rowIndex &&
                        year &&
                        parseInt(year) === moment(minDate).year()))

                  return {
                    ...item,
                    className: isEdited && !isOutOfDateRange ? 'edited' : '',
                    // readOnly for all cells in tables in Overview tab and the Annual Column in ROR Tables

                    readOnly:
                      isReadOnly ||
                      rowIndex === 12 ||
                      // ((type === DataSheetType.FIRM_ASSETS || type === DataSheetType.FIRM_ASSETS_HF) &&
                      //   originalCell &&
                      //   isExternal) ||
                      isOutOfDateRange ||
                      (isExternal && dataFundDetail?.fund_status_code === '2' && originalCell === null)
                        ? true
                        : row[0].readOnly,

                    valueViewer: (props: any) => {
                      if (isOutOfDateRange) {
                        return <div />
                      }
                      return (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: isWarning && !isReadOnly ? 'space-between' : 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          {isWarning && !isReadOnly && (
                            <Tooltip title="Beyond Certain Thresholds">
                              <ExclamationCircleFilled style={{ color: '#ffec3d' }} />
                            </Tooltip>
                          )}
                          <span
                            style={{
                              color: parseFloat(props.value) < 0 ? Colors.danger : Colors.black,
                            }}
                          >
                            {parseFloat(props.value) < 0
                              ? `(${formatNumber(Math.abs(parseFloat(props.value)))})`
                              : props.value}
                          </span>
                        </div>
                      )
                    },
                  }
                }),
              ])
            : []
        }
        valueRenderer={(cell) => cell.value}
        onCellsChanged={(changes) => {
          if (isReadOnly || !changes.length) {
            return
          }
          if (changes[0].value && isNaN(+changes[0].value)) {
            notification.error({ message: 'Input must be Number!' })
            return
          }
          if (
            changes[0].value &&
            parseFloat(changes[0].value) < 0 &&
            (type === DataSheetType.FIRM_ASSETS ||
              type === DataSheetType.ASSET ||
              type === DataSheetType.NAV ||
              type === DataSheetType.FIRM_ASSETS_HF)
          ) {
            notification.error({ message: 'Value must be greater than 0' })
            return
          }
          if (changes[0].value && parseFloat(changes[0].value) < -100) {
            notification.error({ message: 'Value must be greater than or equal to -100' })
            return
          }
          const tempGrid = grid.map((row) => [...row])
          changes.forEach(({ row, col, value }) => {
            if (col === 0) {
              tempGrid[row][col] = { ...tempGrid[row][col], value }
            } else {
              let resolvedValue: string | null = value

              // Disable delete original cell data for External user
              if (
                isExternal &&
                originalGrid.sort(sortByYear(isAscending))[row][col].originalValue &&
                (value == null || value === '')
              ) {
                notification.warn({ message: 'Delete historical data is not allowed', key: 'delete-not-allowed' })
                return
              }

              if (
                !value ||
                isNaN(+value) ||
                ((type === DataSheetType.FIRM_ASSETS ||
                  type === DataSheetType.ASSET ||
                  type === DataSheetType.NAV ||
                  type === DataSheetType.FIRM_ASSETS_HF) &&
                  +value < 0)
              ) {
                resolvedValue = null
              }
              tempGrid[row][col] = {
                ...tempGrid[row][col],
                value: resolvedValue ? formatNumber(+resolvedValue) : null,
              }
            }
          })

          const tempGridROR = tempGrid.map((row, indexRow) => {
            if (indexRow === 0) {
              return row
            }
            return [...row.slice(0, row.length - 1), { ...calculateAnnual(row.slice(0, row.length - 1)) }]
          })

          if (type === DataSheetType.ROR) {
            setGrid(tempGridROR)
          } else {
            setGrid(tempGrid)
          }
          setIsUpdate(true)
        }}
      />

      {/* {!isReadOnly && !(type === DataSheetType.ROR || type === DataSheetType.NAV || type === DataSheetType.ASSET) && (
        <Row style={{ flex: 1, marginTop: 8, justifyContent: 'flex-end' }}>
          <Button type="primary" onClick={handleAddRow}>
            <PlusCircleOutlined /> Add New Row
          </Button>
        </Row>
      )} */}
      {loading && (
        <div
          style={{
            width: '100%',
            top: 0,
            left: 0,
            height: finalData ? '100%' : '200px',
            position: finalData ? 'absolute' : 'initial',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(255, 255, 255, 0.5)',
          }}
        >
          <Spin tip="Loading..." />
        </div>
      )}
    </Wrapper>
  )
}

export default DataSheet
