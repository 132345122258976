import { Col, Divider, Row } from 'antd'
import React from 'react'
import { FirmFundDescriptionRecord } from '../../../shared/api/models/FundDetails'

interface Props {
  description: Array<FirmFundDescriptionRecord[]>
}

const renderHeaderCell = (data: FirmFundDescriptionRecord, index: number) => {
  return data.show ? (
    <Row key={index}>
      <Col span={8} style={{ fontWeight: 200 }}>
        <Row style={{ width: '100%', padding: '10px 5px' }}> {data.key} </Row>
      </Col>
      <Col span={1} style={{ padding: '10px 0px' }}>
        <Divider style={{ height: 20, backgroundColor: '#ece9e9' }} type="vertical" />
      </Col>
      <Col span={15}>
        {Array.isArray(data.value) ? (
          data.value.map((item, idx) => (
            <Row key={`${idx}-${item}`} style={{ width: '100%', height: 50, padding: '10px 5px' }}>
              {item}
            </Row>
          ))
        ) : (
          <Row style={{ width: '100%', height: 50, padding: '10px 5px' }}> {data.value} </Row>
        )}
      </Col>
    </Row>
  ) : null
}
/**
 * Shows Fund or Firm description data as top columns in profile page
 * @param description
 */

const FirmFundDescription: React.FC<Props> = ({ description }) => {
  return (
    <Row justify="space-between">
      {description.map((col: FirmFundDescriptionRecord[], idx: number) => {
        return (
          <Col
            span={24 / description.length}
            style={{ border: '1px solid #f1f1f1', padding: '8px' }}
            className="headerCol"
            key={idx}
          >
            {col.map((data: FirmFundDescriptionRecord, index: number) => renderHeaderCell(data, index))}
          </Col>
        )
      })}
    </Row>
  )
}

export default FirmFundDescription
