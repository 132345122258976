import { Col, Form, List, Radio, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'
import { FundDetailDropdownContext } from '../../../shared/api/context/FundDetailDropdownContext'
import { FormItemSelect } from '../../../shared/components/FormItemComponents'
import { RenderLabelExFirm } from '../../../shared/components/RenderLabelExFirm'
import { updateFieldValue } from '../../../shared/utils/formUtils'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 0 1rem;
  margin-top: 1rem;
  flex-direction: column;
`

type Props = { form: FormInstance }
const FirmCoInvestments: React.FC<Props> = ({ form }) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)
  const [yesSelected, setYesSelected] = React.useState(form.getFieldValue('offer_coinvestments') === '1')

  React.useEffect(() => {
    const { getFieldValue } = form
    if (yesSelected) {
      return
    }
    !!getFieldValue('typical_size') && updateFieldValue(form, 'typical_size', null)
    !!getFieldValue('typical_liquidity') && updateFieldValue(form, 'typical_liquidity', null)
    !!getFieldValue('coinvestment_number') && updateFieldValue(form, 'coinvestment_number', null)
    //
    !!getFieldValue('private_equity') && updateFieldValue(form, 'private_equity', null)
    !!getFieldValue('public_equity') && updateFieldValue(form, 'public_equity', null)
    !!getFieldValue('private_debt') && updateFieldValue(form, 'private_debt', null)
    !!getFieldValue('public_debt') && updateFieldValue(form, 'public_debt', null)
    !!getFieldValue('other_instrument') && updateFieldValue(form, 'other_instrument', null)
    //
    !!getFieldValue('vehicle_commingled_fund') && updateFieldValue(form, 'vehicle_commingled_fund', null)
    !!getFieldValue('vehicle_fund_of_one') && updateFieldValue(form, 'vehicle_fund_of_one', null)
    !!getFieldValue('vehicle_managed_account') && updateFieldValue(form, 'vehicle_managed_account', null)
    !!getFieldValue('other_vehicle') && updateFieldValue(form, 'other_vehicle', null)
  }, [yesSelected])

  const LIST_SELECT_FORM = [
    {
      name: 'typical_size',
      label: 'What is the typical size of your co-investment deals?',
      options:
        fundDataDropdown?.typical_size && fundDataDropdown.typical_size.length > 0
          ? fundDataDropdown.typical_size.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
    },
    {
      name: 'typical_liquidity',
      label: 'What is the typical liquidity profile (days to liquidate) of your co-investment deals?',
      options:
        fundDataDropdown?.typical_liquidity && fundDataDropdown.typical_liquidity.length > 0
          ? fundDataDropdown.typical_liquidity.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
    },
    {
      name: 'coinvestment_number',
      label: 'Approximately how many co-investment deals do you offer per year?',
      options:
        fundDataDropdown?.coinvestment_number && fundDataDropdown.coinvestment_number.length > 0
          ? fundDataDropdown.coinvestment_number.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
    },
  ]

  const ListFirst = [
    {
      name: 'private_equity',
      label: 'Private Equity',
    },
    {
      name: 'public_equity',
      label: 'Public Equity',
    },
    {
      name: 'private_debt',
      label: 'Private Debt',
    },
    {
      name: 'public_debt',
      label: 'Public Debt',
    },
    {
      name: 'other_instrument',
      label: 'Other',
    },
  ]
  const ListSecond = [
    {
      name: 'vehicle_commingled_fund',
      label: 'Commingled Fund',
    },
    {
      name: 'vehicle_fund_of_one',
      label: 'Fund of One',
    },
    {
      name: 'vehicle_managed_account',
      label: 'Separately Managed Account',
    },

    {
      name: 'other_vehicle',
      label: 'Other',
    },
  ]

  return (
    <Wrapper>
      <Row justify="center">
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <Form.Item
            name="offer_coinvestments"
            label={<RenderLabelExFirm value="Does your firm offer co-investment opportunities?" />}
            {...{ labelCol: { span: 18 }, wrapperCol: { span: 6 } }}
            labelAlign="left"
            rules={[
              ({ getFieldValue }) => ({
                validator() {
                  setYesSelected(getFieldValue('offer_coinvestments') === '1')
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <Radio.Group>
              <Radio value={'1'}>Yes</Radio>
              <Radio value={'2'}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <div style={{ display: 'flex', fontStyle: 'italic' }}>If Yes, please answer the following:</div>
        </Col>
      </Row>
      {LIST_SELECT_FORM.map((item) => {
        return (
          <Row key={item.name} justify="center">
            <Col span={12} xl={12} md={16} xs={24} sm={24}>
              <FormItemSelect
                disabled={!yesSelected}
                name={item.name}
                label={<RenderLabelExFirm value={item.label} />}
                options={item.options}
                labelCol={24}
                wrapperCol={24}
              />
            </Col>
          </Row>
        )
      })}
      <Row justify="center">
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <div style={{ display: 'flex' }}>
            Which of the following instrument types are part of your co-investment deals?
          </div>
          <div style={{ display: 'flex', fontStyle: 'italic' }}>
            Select &apos;Yes&apos;, &apos;No&apos; or &apos;Not Responded&apos; for each:
          </div>
          <List
            bordered
            dataSource={ListFirst}
            style={{ width: '100%' }}
            renderItem={(item) => (
              <List.Item>
                <div style={{ width: '100%' }}>
                  <Form.Item
                    name={item.name}
                    label={<RenderLabelExFirm value={item.label} />}
                    {...{ labelCol: { span: 18 }, wrapperCol: { span: 6 } }}
                    labelAlign="left"
                  >
                    <Radio.Group disabled={!yesSelected}>
                      <Radio value="1">Yes</Radio>
                      <Radio value="2">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row justify="center">
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <div style={{ display: 'flex' }}>What investment vehicles have you used to offer co-investments?</div>
          <div style={{ display: 'flex', fontStyle: 'italic' }}>Select &apos;Yes&apos; or &apos;No&apos; for each:</div>

          <List
            bordered
            dataSource={ListSecond}
            style={{ width: '100%' }}
            renderItem={(item) => (
              <List.Item>
                <div style={{ width: '100%' }}>
                  <Form.Item
                    name={item.name}
                    label={<RenderLabelExFirm value={item.label} />}
                    {...{ labelCol: { span: 18 }, wrapperCol: { span: 6 } }}
                    labelAlign="left"
                  >
                    <Radio.Group disabled={!yesSelected}>
                      <Radio value="1">Yes</Radio>
                      <Radio value="2">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </Wrapper>
  )
}

export default FirmCoInvestments
