import { ExclamationCircleFilled } from '@ant-design/icons'
import { notification, Row, Tooltip } from 'antd'
import moment from 'moment'
import React from 'react'
import ReactDataSheet from 'react-datasheet'
import styled from 'styled-components'
import { PerformanceUpdateType } from '../../../../shared/api/context/FundDetailDataContext'
import { LatestPerformanceResponse } from '../../../../shared/api/services/fund.service'
import { Colors } from '../../../../shared/colors'
import { DataSheetType, GridElement } from '../../../../shared/components/DataSheet'
import { LoadingDetails } from '../../../../shared/components/LoadingDetails'
import { getMonthRange } from '../../../../shared/utils/datetimeUtils'
import { isWarningAssetData } from '../../../../shared/utils/validateData'

const Wrapper = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .data-grid-container .data-grid .cell.read-only {
    background: #f3f3f3;
  }

  padding: 1rem;
`

export const DATE_FORMAT = 'YYYY-MM'

const headerRow = [
  { readOnly: true, value: 'Outstanding performance updates' },
  { readOnly: true, value: 'Returns (%)' },
  { readOnly: true, value: 'Asset (MM)' },
  { readOnly: true, value: 'NAV' },
]

type Props = {
  dataLatestPerformance?: LatestPerformanceResponse
  currency?: string
  loading: boolean
  data?: PerformanceUpdateType
  onDataChange: (data?: PerformanceUpdateType) => void
  inceptionDate?: string
}

const PerformanceDataSheet: React.FC<Props> = ({
  currency,
  dataLatestPerformance,
  loading,
  data,
  onDataChange,
  inceptionDate,
}) => {
  const [grid, setGrid] = React.useState<GridElement[][]>([headerRow])

  const parseGridData = (data: GridElement[][]): PerformanceUpdateType => {
    const arrayUpdate: PerformanceUpdateType = {}

    data.forEach((row, rowIndex) => {
      if (rowIndex === 0) {
        return
      }

      row.forEach((cell, cellIndex) => {
        if (cellIndex === 0) {
          return
        }

        // ROR
        if (cellIndex === 1 && row[0].value && cell.value && cell.value !== '') {
          arrayUpdate.rorChanges = [
            ...(arrayUpdate.rorChanges || []),
            {
              yyyy_mm: row[0].value,
              value: +cell.value,
              isReadOnly: cell.readOnly,
            },
          ]
        }
        if (cellIndex === 2 && row[0].value && cell.value && cell.value !== '') {
          arrayUpdate.assetChanges = [
            ...(arrayUpdate.assetChanges || []),
            {
              yyyy_mm: row[0].value,
              value: +cell.value,
              isReadOnly: cell.readOnly,
            },
          ]
        }
        if (cellIndex === 3 && row[0].value && cell.value && cell.value !== '') {
          arrayUpdate.navChanges = [
            ...(arrayUpdate.navChanges || []),
            {
              yyyy_mm: row[0].value,
              value: +cell.value,
              isReadOnly: cell.readOnly,
            },
          ]
        }
      })
    })

    return arrayUpdate
  }

  const updateGrid = React.useCallback(
    (data?: PerformanceUpdateType) => {
      if (!dataLatestPerformance) {
        return
      }
      const hasNoPerformance =
        !dataLatestPerformance.latest_asset && !dataLatestPerformance.latest_nav && !dataLatestPerformance.latest_ror

      const latestROR = dataLatestPerformance.latest_ror
      const latestNav = dataLatestPerformance.latest_nav
      const latestAsset = dataLatestPerformance.latest_asset

      // get the min date of latest data
      const startDate = moment.min([
        latestROR ? moment(latestROR, DATE_FORMAT) : moment(),
        latestNav ? moment(latestNav, DATE_FORMAT) : moment(),
        latestAsset ? moment(latestAsset, DATE_FORMAT) : moment(),
      ])

      // Prepare the available row as remaining months till now
      const dateList = getMonthRange(
        hasNoPerformance ? moment(inceptionDate).format(DATE_FORMAT) : startDate.format(DATE_FORMAT),
        moment().subtract(1, 'month').format(DATE_FORMAT),
      )

      const grid = [
        headerRow,
        ...dateList.map((dateRow) => {
          const latestRorMoment = moment(latestROR, DATE_FORMAT)
          const latestAssetMoment = moment(latestAsset, DATE_FORMAT)
          const latestNavMoment = moment(latestNav, DATE_FORMAT)
          const dateRowMoment = moment(dateRow, DATE_FORMAT)

          return [
            { readOnly: true, value: dateRow },
            {
              readOnly:
                !hasNoPerformance &&
                (latestRorMoment.isAfter(dateRowMoment, 'month') ||
                  latestRorMoment.isSame(dateRowMoment, 'month') ||
                  !latestRorMoment),
              value:
                data?.rorChanges?.find((item) => item.yyyy_mm === dateRow)?.value?.toString() ||
                (dataLatestPerformance &&
                  dataLatestPerformance.latest_ror === dateRow &&
                  dataLatestPerformance.latest_ror_value?.toFixed(4)) ||
                '',
            },
            {
              readOnly:
                !hasNoPerformance &&
                (latestAssetMoment.isAfter(dateRowMoment) ||
                  latestAssetMoment.isSame(dateRowMoment, 'month') ||
                  !latestAsset),
              value:
                data?.assetChanges?.find((item) => item.yyyy_mm === dateRow)?.value?.toString() ||
                (dataLatestPerformance &&
                  dataLatestPerformance.latest_asset === dateRow &&
                  dataLatestPerformance.latest_asset_value?.toFixed(3)) ||
                '',
            },
            {
              readOnly:
                !hasNoPerformance &&
                (latestNavMoment.isAfter(dateRowMoment) ||
                  latestNavMoment.isSame(dateRowMoment, 'month') ||
                  !latestNav),
              value:
                data?.navChanges?.find((item) => item.yyyy_mm === dateRow)?.value?.toString() ||
                (dataLatestPerformance &&
                  dataLatestPerformance.latest_nav === dateRow &&
                  dataLatestPerformance.latest_nav_value?.toFixed(6)) ||
                '',
            },
          ]
        }),
      ]
      setGrid(grid)
    },
    [dataLatestPerformance, inceptionDate],
  )

  React.useEffect(() => {
    updateGrid(data)
  }, [data, updateGrid])

  const compareCellValue = (prev: string | null, next: string | null, type?: DataSheetType) => {
    const prevValue = prev ? +prev : null
    const nextValue = next ? +next : null
    if (type === DataSheetType.ASSET) {
      if (!prev && next) {
        return false
      }
      return isWarningAssetData(prevValue, nextValue)
    }
    if (type === DataSheetType.NAV) {
      if (next !== null && nextValue === 0) {
        return true
      }
    }
    if (type === DataSheetType.ROR && nextValue !== null) {
      if (nextValue >= 20 || nextValue <= -20 || (next && nextValue === 0)) {
        return true
      }
    }

    return false
  }

  return (
    <Wrapper>
      {currency && (
        <Row justify="end">
          <span style={{ marginRight: '0.5rem' }}>
            Currency: <b>{currency}</b>
          </span>
        </Row>
      )}
      <ReactDataSheet<GridElement>
        data={
          grid
            ? grid.map((cols, rowIndex) => [
                // First Column
                {
                  ...cols[0],
                  valueViewer: (cell) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                      >
                        {cell.value}
                      </div>
                    )
                  },
                },
                // Rest Columns
                ...cols.slice(1, cols.length).map((item, index) => {
                  const isRor = index === 0
                  const isAsset = index === 1
                  const isNav = index === 2

                  const type = isRor
                    ? DataSheetType.ROR
                    : isAsset
                    ? DataSheetType.ASSET
                    : isNav
                    ? DataSheetType.NAV
                    : undefined

                  const prevRow = rowIndex > 1 && grid[rowIndex - 1]

                  const prevCell = prevRow ? prevRow[index + 1].value : null

                  const isWarning = compareCellValue(isAsset ? prevCell : item.value, item.value, type)

                  return {
                    ...item,
                    className: item.value ? 'edited' : '',
                    valueViewer: (props: any) => {
                      // Header Row
                      if (props.row === 0) {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                            }}
                          >
                            {props.value}
                          </div>
                        )
                      }
                      return (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          {isWarning && (
                            <Tooltip title="Beyond Certain Thresholds">
                              <ExclamationCircleFilled style={{ color: '#ffec3d' }} />
                            </Tooltip>
                          )}
                          <span
                            style={{
                              marginLeft: 8,
                              color: parseFloat(props.value) < 0 ? Colors.danger : Colors.black,
                            }}
                          >
                            {props.value
                              ? parseFloat(props.value) < 0
                                ? `(${Math.abs(parseFloat(props.value)).toFixed(isNav ? 6 : isAsset ? 3 : 4)})`
                                : (+props.value).toFixed(isNav ? 6 : isAsset ? 3 : 4)
                              : null}
                          </span>
                        </div>
                      )
                    },
                  }
                }),
              ])
            : []
        }
        valueRenderer={(cell) => cell.value}
        isCellNavigable={(cell) => !cell.readOnly}
        onCellsChanged={(changes) => {
          if (changes[0].value && isNaN(+changes[0].value)) {
            notification.warn({ message: 'Input must be Number!' })
            return
          }

          const tempGrid = grid.map((row) => [...row])

          changes.forEach(({ row, col, value }) => {
            // ROR columns
            if (col === 1) {
              if (value && parseFloat(value) < -100) {
                notification.error({ message: 'Value must be greater than or equal to -100' })
                return
              }
              tempGrid[row][col] = {
                ...tempGrid[row][col],
                value: value && !isNaN(+value) ? parseFloat(value).toFixed(4) : null,
              }
            }
            if (col === 2) {
              tempGrid[row][col] = {
                ...tempGrid[row][col],
                value: value && !isNaN(+value) && +value >= 0 ? parseFloat(value).toFixed(3) : null,
              }
              if (value && +value < 0) {
                notification.warn({ message: 'Asset/NAV values should be positive' })
              }
            }
            if (col === 3) {
              tempGrid[row][col] = {
                ...tempGrid[row][col],
                value: value && !isNaN(+value) && +value > 0 ? parseFloat(value).toFixed(6) : null,
              }
              if (value && +value < 0) {
                notification.warn({ message: 'Asset/NAV values should be positive' })
              }
            }
          })
          const parsedGrid = parseGridData(tempGrid)
          onDataChange(Object.keys(parsedGrid).length > 0 ? parsedGrid : undefined)
        }}
      />
      {loading && <LoadingDetails name="Loading performance" />}
    </Wrapper>
  )
}

export default PerformanceDataSheet
