import { Col, Form, Input, notification, Row, Spin } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import APIService from '../../../../shared/api'
import { FirmDueDiligenceActivities } from '../../../../shared/api/models/FirmDetails'

type Props = {
  isShowModal: boolean
  setIsShowModal: (value: boolean) => void
  selectedActivity: FirmDueDiligenceActivities | undefined
  setSelectedActivity: (value: FirmDueDiligenceActivities | undefined) => void
  updateDueDiligence: () => void
  firmId: string
}
export const ModalFundDueDiligence: React.FC<Props> = ({
  isShowModal,
  setIsShowModal,
  selectedActivity,
  setSelectedActivity,
  updateDueDiligence,
  firmId,
}) => {
  const [loadingSubmit, setLoadingSubmit] = React.useState(false)

  const [form] = Form.useForm()
  React.useEffect(() => {
    if (selectedActivity) {
      form.setFieldsValue(selectedActivity)
    } else {
      form.resetFields()
    }
  }, [form, selectedActivity])

  const submitChangeDueDiligence = () => {
    const match = selectedActivity?.date_time.match('(\\d{4}-\\d{2}-\\d{2})T(\\d{2}:\\d{2}:\\d{2})')
    const dateTimeUpdate = match ? `${match[1]} ${match[2]}` : ''
    const submitData = selectedActivity
      ? {
          date_time: dateTimeUpdate,
          username: selectedActivity.oper_id,
          comment: form.getFieldValue('comment'),
        }
      : {
          comment: form.getFieldValue('comment'),
        }

    setLoadingSubmit(true)
    selectedActivity
      ? APIService.firmDetailService
          .updateDueDiligenceAct(firmId, submitData)
          .then(() => {
            notification.success({ message: 'Activity Updated!' })
            updateDueDiligence()
          })
          .catch((err) => {
            console.error({ err })
            notification.error({ message: 'Update Failed!' })
          })
          .finally(() => {
            setLoadingSubmit(false)
            setIsShowModal(false)
            setSelectedActivity(undefined)
          })
      : APIService.firmDetailService
          .createDueDiligenceAct(firmId, submitData)
          .then(() => {
            notification.success({ message: 'Activity Created!' })
            updateDueDiligence()
          })
          .catch((err) => {
            console.error({ err })
            notification.error({ message: 'Create Failed!' })
          })
          .finally(() => {
            setLoadingSubmit(false)
            setIsShowModal(false)
            setSelectedActivity(undefined)
          })
  }
  return (
    <Modal
      visible={isShowModal}
      title={selectedActivity ? 'Update Activity' : 'Add New Activity'}
      okText={
        loadingSubmit ? (
          <>
            <Spin style={{ marginRight: 4 }} />
          </>
        ) : (
          'OK'
        )
      }
      onCancel={() => {
        setIsShowModal(false)
        setSelectedActivity(undefined)
      }}
      onOk={submitChangeDueDiligence}
    >
      <div>
        <Form layout="vertical" form={form}>
          <Row>
            <Col span={24}>
              <Form.Item label="Comment" name="comment">
                <Input.TextArea placeholder="Comment" rows={4} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
