import { CheckCircleTwoTone, EditTwoTone } from '@ant-design/icons'
import { Card, Col, Empty, Form, notification, Row } from 'antd'
import { useForm } from 'antd/lib/form/util'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../../app/rootReducer'
import APIService from '../../../shared/api'
import {
  DUE_DILIGENCE_REQUEST_ENUM,
  FundDetailDataContext,
  PERFORMANCE_REQUEST_ENUM,
} from '../../../shared/api/context/FundDetailDataContext'
import { FundDetailLabelSearchProvider } from '../../../shared/api/context/FundDetailLabelSearchContext'
import { FundDetails } from '../../../shared/api/models/FundDetails'
import { TrackingEventSource } from '../../../shared/api/services/event.service'
import { Colors } from '../../../shared/colors'
import FFPerfectScrollbar from '../../../shared/components/FFPerfectScrollbar'
import FundDetailsPreview from '../../../shared/components/FundDetailPreview/FundDetailPreview'
import { FundESGSection, MODE_ESG_VIEW } from '../../../shared/components/FundESGSection'
import { LoadingDetails } from '../../../shared/components/LoadingDetails'
import { TabContentWrapper } from '../../../shared/components/TabContentWrapper'
import { useServiceState } from '../../../shared/hooks/useServiceState'
import { LeftNavExFundEnum, SEARCH_FIELD_INDEX } from '../../../shared/SearchExFundLabel'
import { LeftNavFundEnum, renderSearchListFund } from '../../../shared/SearchFundLabel'
import { getMonthRange } from '../../../shared/utils/datetimeUtils'
import { fundAssetUpdated, fundNavUpdated, fundRORUpdated } from '../../../slice/appEventSlice'
import { updateTabTitle } from '../../../slice/appSettingsSlice'
import { ExFundDetailMain } from '../../ExternalDashboard/ExFundDetailMain'
import { FundBasicInformation } from './BasicInformation/FundBasicInformation'
import { DueDiligenceMain } from './DueDiligence/DueDiligenceMain'
import ExecutiveOrders from './ExecutiveOrders/ExecutiveOrders'
import ExportSetup from './ExportSetup/ExportSetup'
import { CardTitle } from './FundCardTitle'
import { FundInformationMain } from './FundInformation/FundInformationMain'
import { FundProperties } from './FundProperties/FundProperties'
import { FundPerformance } from './FundROR/FundPerformance'
import { DATE_FORMAT } from './FundROR/PerformanceUpdate'
import { HFRAMInfoMain } from './HFRAMInfo/HFRAMInfoMain'
import { Indices } from './Indices/Indices'
import { Universes } from './Universes/Universes'
import FundDI from './FundDI/FundDI'
import { transformFundIndexEligibilityDetailDataToSubmit } from './Indices/utils'
import { FundFamily } from './FundFamily/FundFamily'
import { useRouteMatch } from 'react-router-dom'

const StyledCard = styled(Card)`
  overflow: hidden;

  .ant-card-head {
    padding: 0 1rem;
  }

  .ant-card-head-title {
    padding: 0;
  }

  .ant-card-body {
    padding: 0;
    overflow: hidden;
    flex: 1;
  }

  .ant-card-head {
    border-bottom: 2px solid #f0f0f0 !important;
  }
`

const StyledForm = styled(Form)`
  flex: 1;
  overflow: hidden;
  height: 100%;

  .ant-form-item {
    margin-bottom: 8px;
  }
`

const StyledTabList = styled.div<{ active?: boolean }>`
  background: ${(props) => (props.active ? '#EDF6FF' : '#fff')};
  font-weight: ${(props) => (props.active ? 'bold' : 'none')};
  display: flex;
  flex: 1;
  align-items: center;
  border-left: solid 1px ${Colors.border};
  border-right: solid 1px ${Colors.border};
  border-bottom: solid 1px ${Colors.border};
  border-top: none;
  padding: 1rem 0 1rem 3rem;

  :hover {
    cursor: pointer;
  }
`

const TAB_LIST = [
  {
    id: LeftNavFundEnum.BASIC_INFO,
    isEdited: false,
  },
  {
    id: LeftNavFundEnum.PERFORMANCE,
    isEdited: false,
  },
  {
    id: LeftNavFundEnum.INFORMATION,
    isEdited: false,
  },
  {
    id: LeftNavFundEnum.EXPORT_SETUP,
    isEdited: false,
  },
  {
    id: LeftNavFundEnum.DUE_DILIGENCE,
    isEdited: false,
  },
  {
    id: LeftNavFundEnum.UNIVERSES,
    isEdited: false,
  },
  {
    id: LeftNavFundEnum.HFR_AM_INFO,
    isEdited: false,
  },
  {
    id: LeftNavFundEnum.FUND_ESG,
    isEdited: false,
  },
  {
    id: LeftNavFundEnum.FUND_DIVERSITY_INCLUSION,
    isEdited: false,
  },
  {
    id: LeftNavFundEnum.FUND_PROPERTIES,
    isEdited: false,
  },
  {
    id: LeftNavFundEnum.INDICES,
    isEdited: false,
  },
  {
    id: LeftNavFundEnum.EXECUTIVE_ORDERS,
    isEdited: false,
  },
  {
    id: LeftNavFundEnum.FUND_FAMILY,
    isEdited: false,
  },
]
export const FUND_DATE_FIELDS = [
  'inception_date',
  'om_date',
  'multi_manager_platform_reviewed_date',
  'liquidated_at',
  'last_audit_date',
  'most_recent_review',
  'hfri500_reviewed',
  'added_to_db',
]
const numberDateField = ['min_invest', 'add_invest', 'min_invest_indv']
const monthFormattedFields = ['added_to', 'removed_from', 'fund_assets_date', 'firm_assets_date']

type Props = {
  initialPath?: LeftNavFundEnum
  hyperlinkTable?: string
  isPreview?: boolean
  previewTab?: string
}

export const FundDetailMain: React.FC<Props> = ({ initialPath, hyperlinkTable, isPreview, previewTab }) => {
  const [showPreview, setShowPreview] = React.useState(!!isPreview)
  useEffect(() => {
    setShowPreview(!!isPreview)
  }, [isPreview])

  const {
    dataFundDetail,
    refetch,
    loading,
    dataChangeChecklistMain,
    getDueDiligenceRequest,
    dataChangeLegalChecklist,
    dataChangeOldChecklist,
    dataChangeInvestor,
    dataLatestPerformance,
    performanceDataChange,
    setPerformanceDataChange,
    rorDataChange: RORDataChange,
    setRORDataChange,
    navDataChange: NAVDataChange,
    setNAVDataChange,
    getPerformanceRequest,
    setAssetDataChange,
    assetDataChange,
    dataFundDetailChange,
    setDataFundDetailChange,
    snapshot,
    clearSnapshot,
    distributionDataChange,
    setDistributionDataChange,
    viewMode,
    setViewMode,
    fundDataDropdown,
    originalFundIndexEligibilityDetail,
    fundIndexEligibilityDetailChange,
    setFundIndexEligibilityDetailChange,
  } = React.useContext(FundDetailDataContext)

  const [activeTab, setActiveTab] = React.useState(initialPath || LeftNavFundEnum.BASIC_INFO)
  const [activeExTab, setActiveExTab] = React.useState(LeftNavExFundEnum.FUND_OVERVIEW)
  const [optionFilter, setOptionFilter] = React.useState(renderSearchListFund)
  const [textFilterSearch, setTextFilterSearch] = React.useState('')
  const [loadingSubmitForm, setLoadingSubmitForm] = React.useState(false)

  const [isUpdateFund, setIsUpdateFund] = React.useState(false)

  const [listTab, setListTab] = React.useState(TAB_LIST)

  const dispatch = useDispatch()

  const [form] = useForm()

  const numberWithCommas = (x: string) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const { invoke: getAllMinorityTypes, data: minorityTypes } = useServiceState(
    APIService.minorityService.fetchMinorityTypes,
  )

  const firmCountry = fundDataDropdown?.firm_contact_country.find(
    (item) => item.code_code === dataFundDetail?.firm_contact_country,
  )

  React.useEffect(() => {
    const contryCode = dataFundDetail?.firm_contact_country
    if (!contryCode) {
      return
    }
    getAllMinorityTypes(contryCode)
  }, [dataFundDetail?.firm_contact_country, getAllMinorityTypes])

  const { invoke: getFundMinority, data: dataFundMinority } = useServiceState(
    APIService.minorityService.fetchFundMinorityStatuses,
  )

  React.useEffect(() => {
    if (!dataFundDetail) {
      return
    }
    getFundMinority(dataFundDetail.fund_id)
  }, [dataFundDetail, getFundMinority])

  React.useEffect(() => {
    const fundMinorityStatuses = dataFundMinority?.fund_minority_statuses
    if (fundMinorityStatuses?.length) {
      fundMinorityStatuses?.map((item) => form.setFieldsValue({ [`minority_type_${item.minority_type}`]: item.status }))
    }
  }, [dataFundMinority, form])

  // change active tab if re-route
  const route = useRouteMatch<{ fundId: string; firmId: string; activeFundNav: string; activeFirmNav: string }>()
  React.useEffect(() => {
    if (initialPath && route.params.activeFundNav) {
      setActiveTab(initialPath)
    }
  }, [route])
  //
  React.useEffect(() => {
    if (initialPath) {
      setActiveTab(initialPath)
    }
  }, [initialPath])

  React.useEffect(() => {
    if (form && snapshot) {
      // @ts-ignore
      form.draft = snapshot
      form.setFieldsValue(snapshot)
      setIsUpdateFund(true)
    }
  }, [form, snapshot, setIsUpdateFund])

  const dataChangeRef = dataFundDetailChange?.current

  React.useEffect(() => {
    if (dataChangeRef) {
      Object.keys(dataChangeRef).map((item) => {
        const leftNav = renderSearchListFund.find((i) => i.key === item)?.leftNav
        const index = leftNav ? TAB_LIST.findIndex((tab) => tab.id === leftNav) : -1
        if (index > -1) {
          setListTab((prev) => {
            return [...prev.slice(0, index), { ...prev[index], isEdited: true }, ...prev.slice(index + 1, prev.length)]
          })
        }
      })
    }
  }, [dataChangeRef])

  const appSetting = useSelector((state: RootState) => state.appSettings)
  const fundDraft = appSetting.fundDraft.find((item) => item.id === dataFundDetail?.fund_id)
  const lastKey = fundDraft?._lastFieldChanged

  React.useEffect(() => {
    if (lastKey) {
      if (viewMode === 'external') {
        const activeNav = SEARCH_FIELD_INDEX.find((item) => item.key === lastKey)?.leftNav
        activeNav && setActiveExTab(activeNav)
        return
      }
      const activeNav = renderSearchListFund.find((item) => item.key === lastKey)?.leftNav
      activeNav && setActiveTab(activeNav)
    }
  }, [lastKey, viewMode])
  // Extra data updated
  React.useEffect(() => {
    if (
      (performanceDataChange && Object.keys(performanceDataChange).length > 0) ||
      RORDataChange.values ||
      distributionDataChange.values ||
      NAVDataChange.values ||
      assetDataChange.values ||
      fundIndexEligibilityDetailChange ||
      // dataFundDetailChange is a ref so value change will not trigger this effect
      Object.keys(dataFundDetailChange?.current || {}).length
    ) {
      setIsUpdateFund(true)
    } else if (dataChangeChecklistMain || dataChangeLegalChecklist || dataChangeOldChecklist || dataChangeInvestor) {
      setDataFundDetailChange((prevState) => ({
        ...prevState,
      }))
      setIsUpdateFund(true)
    } else {
      setIsUpdateFund(false)
    }
  }, [
    NAVDataChange.values,
    RORDataChange.values,
    assetDataChange.values,
    distributionDataChange.values,
    performanceDataChange,
    fundIndexEligibilityDetailChange,
    dataChangeChecklistMain,
    dataChangeInvestor,
    dataChangeLegalChecklist,
    dataChangeOldChecklist,
    dataFundDetailChange,
    setDataFundDetailChange,
  ])

  React.useEffect(() => {
    if (dataFundDetail) {
      dispatch(updateTabTitle({ tabName: dataFundDetail.fund_name || '', id: dataFundDetail.fund_id }))
    }
  }, [dataFundDetail, dispatch])

  const { invoke: getAllIndex, data: dataListIndices, loading: loadingListIndices } = useServiceState(
    APIService.indexManagerService.getAllIndexManager,
  )

  React.useEffect(() => {
    getAllIndex()
  }, [getAllIndex])

  // support fund index eligibility
  const findInitialValue = (id: number) => {
    if (!dataFundDetail?.hfr_indices) return undefined
    const foundIndex = dataFundDetail?.hfr_indices.find((item) => +item.id === +id)
    return foundIndex?.value
  }

  /////////////////////////////////
  const parsedDateFormat = (dateString: string) => {
    if (dateString.length === 7 && dateString[4] === '0') {
      return `${dateString.slice(0, 4)}-${dateString.slice(5, 7)}`
    }
    return dateString
  }

  const initialDataForm = React.useMemo(() => {
    if (!dataFundDetail) {
      return undefined
    }

    return {
      ...dataFundDetail,
      firm_assets_date: dataFundDetail.firm_assets_date
        ? moment(parsedDateFormat(dataFundDetail.firm_assets_date))
        : null,
      fund_assets_date: dataFundDetail.fund_assets_date
        ? moment(parsedDateFormat(dataFundDetail.fund_assets_date))
        : null,
      inception_date: dataFundDetail.inception_date ? moment(dataFundDetail.inception_date) : null,
      om_date: dataFundDetail.om_date ? moment(dataFundDetail.om_date) : null,
      multi_manager_platform_reviewed_date: dataFundDetail.multi_manager_platform_reviewed_date
        ? moment(dataFundDetail.multi_manager_platform_reviewed_date)
        : null,
      liquidated_at: dataFundDetail.liquidated_at ? moment(dataFundDetail.liquidated_at) : null,
      last_audit_date: dataFundDetail.last_audit_date ? moment(dataFundDetail.last_audit_date) : null,
      most_recent_review: dataFundDetail.most_recent_review ? moment(dataFundDetail.most_recent_review) : null,
      hfri500_reviewed: dataFundDetail.hfri500_reviewed ? moment(dataFundDetail.hfri500_reviewed) : null,
      added_to_hfri: dataFundDetail.added_to_hfri
        ? moment(parsedDateFormat(dataFundDetail.added_to_hfri), 'YYYY-MM')
        : null,
      removed_from_hfri: dataFundDetail.removed_from_hfri
        ? moment(parsedDateFormat(dataFundDetail.removed_from_hfri), 'YYYY-MM')
        : null,
      added_to_db: dataFundDetail.added_to_db ? moment(dataFundDetail.added_to_db) : null,
      min_invest: dataFundDetail.min_invest ? numberWithCommas(dataFundDetail.min_invest) : null,
      add_invest: dataFundDetail.add_invest ? numberWithCommas(dataFundDetail.add_invest) : null,
      min_invest_indv: dataFundDetail.min_invest_indv ? numberWithCommas(dataFundDetail.min_invest_indv) : null,
      is_lockup: dataFundDetail.is_lockup === '1' ? true : false,
      // not sure what is this? probably old attemd at implementing Index Eligibility
      ...dataListIndices
        ?.map((item) => {
          return item.id
        })
        .reduce((acc, key) => {
          return { ...acc, [key]: findInitialValue(key) }
        }, {}),
      ///////////////////////////////////////////////////////////////////////////////
      ...dataFundMinority?.fund_minority_statuses.reduce((acc, item) => {
        return {
          ...acc,
          [`minority_type_${item.minority_type}`]: item.status,
        }
      }, {}),
    }
  }, [dataFundDetail, dataListIndices, dataFundMinority])

  const validateLatestPerformance = () => {
    if (performanceDataChange && dataLatestPerformance && Object.keys(performanceDataChange).length > 0) {
      // get the latest updated date
      const latestRORData = performanceDataChange.rorChanges
        ? moment(performanceDataChange.rorChanges[performanceDataChange.rorChanges?.length - 1].yyyy_mm, DATE_FORMAT)
        : moment(new Date(1971))
      const latestNAVData = performanceDataChange.navChanges
        ? moment(performanceDataChange.navChanges[performanceDataChange.navChanges?.length - 1].yyyy_mm, DATE_FORMAT)
        : moment(new Date(1971))
      const latestAssetData = performanceDataChange.assetChanges
        ? moment(
            performanceDataChange.assetChanges[performanceDataChange.assetChanges?.length - 1].yyyy_mm,
            DATE_FORMAT,
          )
        : moment(new Date(1971))
      const latestUpdatedDate = moment.max([latestRORData, latestNAVData, latestAssetData])
      let isDataValid = true

      dataLatestPerformance.latest_ror &&
        getMonthRange(
          moment(dataLatestPerformance.latest_ror, DATE_FORMAT).add(1, 'month').format(DATE_FORMAT),
          latestUpdatedDate.format(DATE_FORMAT),
        ).forEach((rorMonth) => {
          if (!performanceDataChange.rorChanges?.some((item) => item.yyyy_mm === rorMonth)) {
            isDataValid = false
            return
          }
        })

      dataLatestPerformance.latest_asset &&
        getMonthRange(
          moment(dataLatestPerformance.latest_asset, DATE_FORMAT).add(1, 'month').format(DATE_FORMAT),
          latestUpdatedDate.format(DATE_FORMAT),
        ).forEach((rorMonth) => {
          if (!performanceDataChange.assetChanges?.some((item) => item.yyyy_mm === rorMonth)) {
            isDataValid = false
            return
          }
        })

      dataLatestPerformance.latest_nav &&
        getMonthRange(
          moment(dataLatestPerformance.latest_nav, DATE_FORMAT).add(1, 'month').format(DATE_FORMAT),
          latestUpdatedDate.format(DATE_FORMAT),
        ).forEach((rorMonth) => {
          if (!performanceDataChange.navChanges?.some((item) => item.yyyy_mm === rorMonth)) {
            isDataValid = false
            return
          }
        })

      if (!isDataValid) {
        notification.warn({ message: 'Please fill in the missing performance data!' })
        return false
      }
    }
    return true
  }

  const updateLatestPerformance = async () => {
    if (
      performanceDataChange &&
      dataLatestPerformance &&
      Object.keys(performanceDataChange).length > 0 &&
      dataFundDetail
    ) {
      try {
        setLoadingSubmitForm(true)
        await APIService.fundService.updateLatestPerformance(dataFundDetail.fund_id, {
          rors: performanceDataChange.rorChanges,
          navs: performanceDataChange.navChanges,
          assets: performanceDataChange.assetChanges,
        })
        setIsUpdateFund(false)
        notification.success({ message: 'Quick Performance Updated' })
        setPerformanceDataChange(undefined)
        getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.LATEST_PERFORMANCE)
        if (performanceDataChange.rorChanges) {
          getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.ROR)
          dispatch(fundRORUpdated())
        }
        if (performanceDataChange.navChanges) {
          getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.NAV)
          dispatch(fundNavUpdated())
        }
        if (performanceDataChange.assetChanges) {
          getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.ASSET)
          dispatch(fundAssetUpdated())
        }
      } catch (error) {
        notification.error({ message: error.response.statusText })
      } finally {
        setLoadingSubmitForm(false)
        return true
      }
    }
  }

  const handleSubmit = async () => {
    if (!validateLatestPerformance()) {
      return
    }
    await updateLatestPerformance()

    if (dataChangeChecklistMain && dataFundDetail?.firm_id) {
      setLoadingSubmitForm(true)
      APIService.firmDetailService
        .updateChecklistMain(dataFundDetail.firm_id, { updates: dataChangeChecklistMain })
        .then(() => {
          notification.success({ message: 'Check List Main Updated!' })
          getDueDiligenceRequest(DUE_DILIGENCE_REQUEST_ENUM.DUE_DILIGENCE_MAIN)
          setIsUpdateFund(false)
        })
        .catch((err) => {
          console.error({ err })
          notification.error({ message: 'Check List Main Update Failed!' })
        })
        .finally(() => {
          setLoadingSubmitForm(false)
        })
    }

    if (dataChangeLegalChecklist && dataFundDetail?.firm_id) {
      setLoadingSubmitForm(true)
      APIService.firmDetailService
        .updateLegalChecklist(dataFundDetail.firm_id, { updates: dataChangeLegalChecklist })
        .then(() => {
          notification.success({ message: 'Legal Documents Updated!' })
          getDueDiligenceRequest(DUE_DILIGENCE_REQUEST_ENUM.DUE_DILIGENCE_LEGAL_CHECKLIST)
          setIsUpdateFund(false)
        })
        .catch((err) => {
          console.error({ err })
          notification.error({ message: 'Legal Documents Update Failed!' })
        })
        .finally(() => {
          setLoadingSubmitForm(false)
        })
    }

    if (dataChangeOldChecklist && dataFundDetail?.firm_id) {
      setLoadingSubmitForm(true)
      APIService.firmDetailService
        .updateOldChecklist(dataFundDetail.firm_id, { updates: dataChangeOldChecklist })
        .then(() => {
          notification.success({ message: 'Old Background Checks Updated!' })
          getDueDiligenceRequest(DUE_DILIGENCE_REQUEST_ENUM.DUE_DILIGENCE_OLD_CHECKLIST)
          setIsUpdateFund(false)
        })
        .catch((err) => {
          console.error({ err })
          notification.error({ message: 'Old Background Checks Update Failed!' })
        })
        .finally(() => {
          setLoadingSubmitForm(false)
        })
    }
    if (dataChangeInvestor && dataFundDetail?.fund_id) {
      setLoadingSubmitForm(true)
      APIService.fundDetailService
        .updateDueDiligenceInvestor(dataFundDetail.fund_id, dataChangeInvestor)
        .then(() => {
          getDueDiligenceRequest(DUE_DILIGENCE_REQUEST_ENUM.DUE_DILIGENCE_INVESTOR)
          setIsUpdateFund(false)
        })
        .catch((err) => {
          console.error({ err })
          notification.error({ message: 'Investor Opinion Update Failed!' })
        })
        .finally(() => {
          setLoadingSubmitForm(false)
        })
    }
    if (distributionDataChange.values && dataFundDetail) {
      try {
        setLoadingSubmitForm(true)
        distributionDataChange &&
          (await APIService.fundDetailService.updateFundDistributions(
            dataFundDetail.fund_id,
            JSON.parse(JSON.stringify(distributionDataChange)),
          ))
        // refetch data
        notification.success({ message: 'Distribution Updated' })
        getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.DISTRIBUTION)
        setDistributionDataChange({ values: undefined })
        setIsUpdateFund(false)
      } catch (error) {
        console.log(error.response)
        notification.error({ message: error.response.statusText })
      } finally {
        setLoadingSubmitForm(false)
      }
    }

    if (RORDataChange.values && dataFundDetail) {
      try {
        setLoadingSubmitForm(true)
        RORDataChange &&
          (await APIService.fundDetailService.updateFundROR(
            dataFundDetail.fund_id,
            JSON.parse(JSON.stringify(RORDataChange)),
          ))
        // refetch data

        notification.success({ message: 'ROR Updated' })
        setRORDataChange({ values: undefined })
        getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.ROR)
        dispatch(fundRORUpdated())
        getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.LATEST_PERFORMANCE)
        setIsUpdateFund(false)
      } catch (error) {
        console.log(error.response)
        notification.error({ message: error.response.statusText })
      } finally {
        setLoadingSubmitForm(false)
      }
    }

    if (NAVDataChange.values && dataFundDetail) {
      try {
        setLoadingSubmitForm(true)
        NAVDataChange &&
          (await APIService.fundDetailService.updateNAVROR(
            dataFundDetail.fund_id,
            JSON.parse(JSON.stringify(NAVDataChange)),
          ))
        // refetch data
        notification.success({ message: 'NAV Updated' })
        getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.NAV)
        getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.LATEST_PERFORMANCE)
        setNAVDataChange({ values: undefined })
        dispatch(fundNavUpdated())
        setIsUpdateFund(false)
      } catch (error) {
        console.log(error.response)
        notification.error({ message: error.response.statusText })
      } finally {
        setLoadingSubmitForm(false)
      }
    }

    if (assetDataChange.values && dataFundDetail) {
      try {
        setLoadingSubmitForm(true)
        assetDataChange &&
          (await APIService.fundDetailService.updateAssetROR(
            dataFundDetail.fund_id,
            JSON.parse(JSON.stringify(assetDataChange)),
          ))
        // refetch data
        notification.success({ message: 'Assets Updated' })
        getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.ASSET)
        getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.LATEST_PERFORMANCE)
        setAssetDataChange({ values: undefined })
        dispatch(fundAssetUpdated())
        setIsUpdateFund(false)
      } catch (error) {
        console.log(error.response)
        notification.error({ message: error.response.statusText })
      } finally {
        setLoadingSubmitForm(false)
      }
    }

    if (fundIndexEligibilityDetailChange && dataFundDetail) {
      try {
        setLoadingSubmitForm(true)
        if (!originalFundIndexEligibilityDetail) {
          throw new Error('Missing Fund Index Detail data')
        }
        const { fund_id, index_family_id } = originalFundIndexEligibilityDetail
        const { ...body } = transformFundIndexEligibilityDetailDataToSubmit({
          // ...originalFundIndexEligibilityDetail,
          ...fundIndexEligibilityDetailChange,
        })
        if (!fund_id || !index_family_id) {
          throw new Error('Missing Fund Index Detail Id')
        }
        await APIService.fundDetailService.updateFundIndexEligibilityDetails({
          fundId: fund_id,
          indexFamilyId: index_family_id,
          body,
        })
        // refetch data
        notification.success({ message: 'Fund Index Eligibility Detail Updated' })
        setFundIndexEligibilityDetailChange(undefined)
        setIsUpdateFund(false)
      } catch (error) {
        console.log(error)
        notification.error({ message: error?.response?.statusText || error?.message || 'Error' })
      } finally {
        setLoadingSubmitForm(false)
      }
    }

    if (initialDataForm && dataFundDetailChange) {
      const fieldChange = Object.keys(dataFundDetailChange.current)
        .filter(
          (key) => dataFundDetailChange.current[key as keyof FundDetails] !== initialDataForm[key as keyof FundDetails],
        )
        .reduce(
          (accum, key) => ({
            ...accum,
            [key]: dataFundDetailChange.current[key as keyof FundDetails],
          }),
          {},
        )

      // Update minority types
      const dataMinority = Object.entries(fieldChange).filter((item) => item[0].includes('minority_type_'))

      // extract minority types out of field changes
      const dataChange = Object.entries(fieldChange)
        .filter((item) => !item[0].includes('minority_type_'))
        .reduce((acc, item) => {
          return {
            ...acc,
            [item[0]]: item[1],
          }
        }, {})

      const minorityChange = {
        updates: dataMinority.map((item) => {
          return {
            minority_type: +item[0].replace('minority_type_', ''),
            status: item[1] as boolean,
          }
        }),
      }
      if (minorityChange && minorityChange.updates.length > 0 && dataFundDetail) {
        APIService.minorityService
          .updateFundMinorityStatuses(dataFundDetail.fund_id, minorityChange)
          .then(() => {
            notification.success({ message: 'Diversity & Inclusion Updated!' })
          })
          .catch((err) => {
            console.error({ err })
            notification.error({ message: 'Update Failed!' })
          })
          .finally(() => {
            setLoadingSubmitForm(false)
          })
      }

      // update indices
      const indicesChange =
        dataListIndices &&
        Object.keys(dataFundDetailChange.current)
          .filter((key) => dataListIndices.findIndex((item) => item.id.toString() === key) > -1)
          .reduce((acc, key) => {
            return [
              ...acc,
              {
                index_id: parseInt(key),
                value: !!dataFundDetailChange.current[key as keyof FundDetails],
              },
            ]
          }, [] as { index_id: number; value: boolean }[])
      //
      if (indicesChange && indicesChange.length > 0) {
        setLoadingSubmitForm(true)
        APIService.fundDetailService
          .updateFundIndices(initialDataForm.fund_id, { updates: indicesChange })
          .then(() => {
            notification.success({ message: 'Indices Updated!' })
            setIsUpdateFund(false)
          })
          .catch((err) => {
            console.error({ err })
            notification.error({ message: 'Update Indices Failed!' })
          })
          .finally(() => {
            setLoadingSubmitForm(false)
          })
      }
      //
      const dataUpload: Partial<FundDetails> = form.getFieldValue('is_liquidated')
        ? Object.keys(dataChange)
            .filter((key) => dataListIndices?.findIndex((item) => item.id.toString() === key) === -1)
            .reduce(
              (accum, key) => ({
                ...accum,
                [key]: dataFundDetailChange.current[key as keyof FundDetails],
              }),
              {},
            )
        : Object.keys(dataChange)
            .filter(
              (key) =>
                key !== 'liquidated_at' && dataListIndices?.findIndex((item) => item.id.toString() === key) === -1,
            )
            .reduce(
              (accum, key) => ({
                ...accum,

                [key]: dataFundDetailChange.current[key as keyof FundDetails],
              }),
              {},
            )
      //
      const dataFinalUpload: Partial<FundDetails> = Object.keys(dataUpload).reduce((acc, key) => {
        const value = dataUpload[key as keyof Partial<FundDetails>]
        if (key === 'is_lockup') {
          return value ? { ...acc, [key]: '1' } : { ...acc, [key]: '2' }
        }
        if (FUND_DATE_FIELDS.includes(key) && value) {
          return { ...acc, [key]: moment(value as string).format('YYYY-MM-DD') }
        }

        if (containsStr(key, monthFormattedFields) && value) {
          return { ...acc, [key]: moment(value as string).format('YYYY-MM') }
        }
        return { ...acc, [key]: dataUpload[key as keyof FundDetails] }
      }, {})
      //
      if (initialDataForm.fund_id && Object.keys(dataFinalUpload).length > 0) {
        setLoadingSubmitForm(true)
        APIService.fundDetailService
          .updateFundBasicInfo(initialDataForm.fund_id, dataFinalUpload)
          .then(() => {
            notification.success({ message: 'Fund Updated!' })
            refetch()
            setDataFundDetailChange({})
            clearSnapshot()
            setIsUpdateFund(false)
            setListTab(TAB_LIST)
          })
          .catch((err) => {
            console.error({ err })
            notification.error({ message: 'Update Failed!' })
          })
          .finally(() => {
            setLoadingSubmitForm(false)
          })
      } else {
        setDataFundDetailChange({})
        refetch()
        clearSnapshot()
        setIsUpdateFund(false)
      }
    }
  }

  const hyperlink = React.useMemo(() => {
    if (hyperlinkTable) {
      return hyperlinkTable
    }
    if (!dataFundDetail) {
      return
    }
    const fundId = dataFundDetail.fund_id
    return appSetting.fundFirmTabs.tabList.find((item) => item.id === fundId)?.hyperlinkTable
  }, [appSetting.fundFirmTabs.tabList, dataFundDetail, hyperlinkTable])

  const renderTabContent = (activeTab: LeftNavFundEnum) => {
    if (!dataFundDetail) {
      return null
    }
    switch (activeTab) {
      case LeftNavFundEnum.BASIC_INFO:
        return <FundBasicInformation form={form} dataListIndices={dataListIndices} />
      case LeftNavFundEnum.PERFORMANCE:
        return <FundPerformance setIsUpdateFund={setIsUpdateFund} hyperlinkTable={hyperlink} />
      case LeftNavFundEnum.INFORMATION:
        return <FundInformationMain form={form} setIsUpdateFund={setIsUpdateFund} />
      case LeftNavFundEnum.EXPORT_SETUP:
        return <ExportSetup />
      case LeftNavFundEnum.DUE_DILIGENCE:
        return <DueDiligenceMain />
      case LeftNavFundEnum.UNIVERSES:
        return <Universes />
      case LeftNavFundEnum.FUND_DIVERSITY_INCLUSION:
        return (
          <FundDI
            form={form}
            minorityTypes={minorityTypes}
            initialDataForm={initialDataForm}
            setDataFundDetailChange={setDataFundDetailChange}
            firmCountry={firmCountry}
          />
        )
      case LeftNavFundEnum.FUND_ESG:
        return <FundESGSection form={form} modeView={MODE_ESG_VIEW.INTERNAL} />
      case LeftNavFundEnum.FUND_PROPERTIES:
        return <FundProperties />
      case LeftNavFundEnum.HFR_AM_INFO:
        return <HFRAMInfoMain />
      case LeftNavFundEnum.INDICES:
        return <Indices form={form} dataListIndices={dataListIndices} loadingListIndices={loadingListIndices} />
      case LeftNavFundEnum.EXECUTIVE_ORDERS:
        return (
          <ExecutiveOrders
            form={form}
            data={[
              {
                fund_id: dataFundDetail.fund_id,
                fund_name: dataFundDetail.fund_name,
                has_securities: dataFundDetail.has_securities,
                manager_confirms: dataFundDetail.manager_confirms,
                separate_share_classes: dataFundDetail.separate_share_classes,
                pre_trade_compliance_restrictions: dataFundDetail.pre_trade_compliance_restrictions,
                other_measures: dataFundDetail.other_measures,
                subscription_notice: dataFundDetail.subscription_advance_notice || '',
              },
            ]}
          />
        )
      case LeftNavFundEnum.FUND_FAMILY:
        return <FundFamily />
      default:
        break
    }
  }

  return (
    <>
      {viewMode === 'external' ? (
        <ExFundDetailMain disableSnapshot={true} selectedTab={activeExTab} setViewMode={setViewMode} />
      ) : (
        <TabContentWrapper style={{ position: 'relative', background: 'white', height: '100%' }}>
          {dataFundDetail && (
            <StyledForm
              form={form}
              onFinish={handleSubmit}
              initialValues={{ ...initialDataForm }}
              onFieldsChange={() => {
                // update is udpate status for dataFundDetailChange
                setIsUpdateFund(true)
              }}
              onValuesChange={(changedValues) => {
                const convertObject = Object.keys(changedValues).reduce((acc, key) => {
                  if (numberDateField.includes(key)) {
                    return {
                      ...acc,
                      [key]: changedValues[key] ? parseInt(changedValues[key].replace(/,0/g, '0')) : null,
                    }
                  }
                  return { ...acc, [key]: changedValues[key] !== undefined ? changedValues[key] : null }
                }, {})
                setDataFundDetailChange(
                  { ...(dataFundDetailChange?.current || {}), ...convertObject },
                  Object.keys(changedValues)[0],
                )
              }}
            >
              <StyledCard
                title={
                  <CardTitle
                    id={dataFundDetail.fund_id}
                    name={dataFundDetail?.fund_name}
                    firmName={dataFundDetail.firm_name}
                    firmId={dataFundDetail.firm_id}
                    optionFilter={optionFilter}
                    textFilterSearch={textFilterSearch}
                    setOptionFilter={setOptionFilter}
                    onCancel={() => {
                      setIsUpdateFund(false)
                      clearSnapshot()
                      refetch()
                      form.resetFields()
                      setListTab(TAB_LIST)
                      setDataFundDetailChange({})
                    }}
                    setTextFilterSearch={setTextFilterSearch}
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                    loadingSubmitForm={loadingSubmitForm}
                    isPreviewing={showPreview}
                    setViewMode={setViewMode}
                    onPreview={() => setShowPreview((prev) => !prev)}
                    isUpdateFund={isUpdateFund}
                  />
                }
                size="default"
                style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}
              >
                <Row style={{ height: '100%' }}>
                  {showPreview ? (
                    <FundDetailsPreview
                      previewTab={previewTab}
                      source={TrackingEventSource.MANAGER_LINK_INTERNAL}
                      fundId={dataFundDetail.fund_id}
                      isShowButtonFactsheet={dataFundDetail.has_index_factsheet}
                    />
                  ) : (
                    <>
                      <Col span={4} style={{ height: '100%' }}>
                        <FFPerfectScrollbar>
                          {listTab.map((item) => {
                            const existTab = optionFilter.findIndex((i) => i.leftNav === item.id)
                            if (existTab === -1 && textFilterSearch !== '') {
                              return null
                            }
                            return (
                              <StyledTabList
                                key={item.id}
                                onClick={() => {
                                  setActiveTab(item.id)
                                }}
                                active={activeTab === item.id}
                              >
                                <div style={{ position: 'absolute', left: '1.5rem' }}>
                                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                                </div>
                                {item.id} {item.isEdited && <EditTwoTone style={{ marginLeft: 8 }} />}
                              </StyledTabList>
                            )
                          })}
                        </FFPerfectScrollbar>
                      </Col>
                      <Col span={20} style={{ height: '100%' }}>
                        <FFPerfectScrollbar>
                          <FundDetailLabelSearchProvider text={textFilterSearch} filterArr={optionFilter}>
                            {optionFilter.length === 0 ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flex: 1,
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <Empty />
                              </div>
                            ) : (
                              renderTabContent(activeTab)
                            )}
                          </FundDetailLabelSearchProvider>
                        </FFPerfectScrollbar>
                      </Col>
                    </>
                  )}
                </Row>
              </StyledCard>
            </StyledForm>
          )}
          {loading && <LoadingDetails name="Loading Fund" />}
        </TabContentWrapper>
      )}
    </>
  )
}

function containsStr(inputString: string, stringArray: string[]) {
  for (let i = 0; i < stringArray.length; i++) {
    if (inputString.includes(stringArray[i])) {
      return true
    }
  }
  return false
}
