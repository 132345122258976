import { Collapse, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import moment from 'moment'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { ExNewFundContext } from '../../../shared/api/context/ExNewFundContext'
import DataSheet, { DataSheetType } from '../../../shared/components/DataSheet'
import { LeftNavExNewFundEnum } from '../../../shared/SearchExNewFundLabel'
import { DATE_FORMAT } from '../../Dashboard/FundDetail/FundROR/PerformanceUpdate'
import { NextBackNewFundTab } from './NextBackExNewFundTab'

const Flex = styled.div`
  display: flex;
  flex: 1;
  margin-top: 1rem;
  flex-direction: column;
  padding: 0 2rem;
`
const { Panel } = Collapse
type Props = {
  setStepTab: (value: LeftNavExNewFundEnum) => void
  setActiveTab: (value: LeftNavExNewFundEnum) => void
  activeTab: LeftNavExNewFundEnum
  stepTab: LeftNavExNewFundEnum
  form: FormInstance
}
const ARRAY_FIELD_VALIDATE: string[] = []
export const ExNewFundPerformance: React.FC<Props> = ({ form, setStepTab, setActiveTab, activeTab, stepTab }) => {
  const {
    setAssetDataChange,
    setNAVDataChange,
    setRORDataChange,
    rorDataChange,
    assetDataChange,
    navDataChange,
  } = React.useContext(ExNewFundContext)

  // eslint-disable-next-line
  const rorData = useMemo(() => rorDataChange.values, [])
  // eslint-disable-next-line
  const assetData = useMemo(() => assetDataChange.values, [])
  // eslint-disable-next-line
  const navData = useMemo(() => navDataChange.values, [])

  const currencyValue = form.getFieldValue('denomination')
  const reportingStyle = form.getFieldValue('reporting_style')
  const inceptionDate = moment(form.getFieldValue('inception_date')).format('YYYY-MM-DD')
  return (
    <Flex>
      <Row>
        <div>
          Fund Inception Date: <b>{inceptionDate}</b>
        </div>
      </Row>
      <Collapse>
        <Panel header="ROR" key="ROR">
          <>
            <Row style={{ marginBottom: '1rem' }}>
              <div>
                Reporting Style: <b>{reportingStyle}</b>
              </div>
            </Row>
            <DataSheet
              initialData={rorData}
              type={DataSheetType.ROR}
              canAdd
              isInCreateNewExFund
              setRORDataChange={setRORDataChange}
              currencyValue={currencyValue}
              minDate={inceptionDate}
            />
          </>
        </Panel>

        <Panel header="Assets" key="Assets">
          <DataSheet
            initialData={assetData}
            type={DataSheetType.ASSET}
            canAdd
            isInCreateNewExFund
            setRORDataChange={setAssetDataChange}
            currencyValue={currencyValue}
            minDate={inceptionDate}
          />
        </Panel>
        <Panel header="Nav" key="Nav">
          <DataSheet
            initialData={navData}
            type={DataSheetType.NAV}
            canAdd
            isInCreateNewExFund
            setRORDataChange={setNAVDataChange}
            currencyValue={currencyValue}
            minDate={inceptionDate ? moment(inceptionDate).subtract(1, 'month').format(DATE_FORMAT) : undefined}
          />
        </Panel>
      </Collapse>
      <NextBackNewFundTab
        setStepTab={setStepTab}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        stepTab={stepTab}
        ArrayValidateField={ARRAY_FIELD_VALIDATE}
        form={form}
      />
    </Flex>
  )
}
