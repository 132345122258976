import { DeleteOutlined, DeleteTwoTone, DownloadOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Input, notification, Popconfirm, Space, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import Text from 'antd/lib/typography/Text'
import JsFileDownload from 'js-file-download'
import moment from 'moment'
import Papa from 'papaparse'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../shared/api'
import { IndexFundType } from '../../shared/api/models/IndexManager'
import { Colors } from '../../shared/colors'
import { useServiceState } from '../../shared/hooks/useServiceState'
import { LeftNavFundEnum } from '../../shared/SearchFundLabel'
import GenUtil from '../../shared/utils/gen-util'
import { IndexManagerModalAddFund } from './IndexManagerModalAddFund'

const TextID = styled(Text)`
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
`
const Flex = styled.div`
  display: flex;
  flex: 1;
`

type ListTabType = {
  id: number
  name: string
  type: 'index' | 'funds'
  initialPath?: LeftNavFundEnum
  hyperlink?: string
}

type Props = {
  name: string
  id: number
  listTab: ListTabType[]
  setListTab: (value: ListTabType[]) => void
  setActiveTab: (value: string) => void
}
export enum IndexManagerActionFund {
  DELETE_FUNDS,
  ADD_FUNDS,
}
export const IndexManagerDetails: React.FC<Props> = ({ name, id, listTab, setListTab, setActiveTab }) => {
  const { invoke: getIndexManagerDetail, data: dataDetail, loading: dataLoading } = useServiceState(
    APIService.indexManagerService.getIndexManagerDetail,
  )

  const [showModal, setShowModal] = React.useState(false)
  const [loadingDelete, setLoadingDelete] = React.useState(false)
  const [actionFunds, setActionFunds] = React.useState<IndexManagerActionFund>()

  const [currentSource, setCurrentSource] = React.useState<IndexFundType[]>([])

  React.useEffect(() => {
    getIndexManagerDetail(id)
  }, [getIndexManagerDetail, id])

  React.useEffect(() => {
    if (dataDetail && dataDetail.index_funds) {
      setCurrentSource(dataDetail.index_funds)
    }
  }, [dataDetail])

  const handleExportIndexConstituents = () => {
    JsFileDownload(Papa.unparse(currentSource), `index-constituents-results.csv`, 'application/csv')
  }

  const updateIndexManagerDetail = () => {
    getIndexManagerDetail(id)
  }

  const handleSearch = (selectedKeys: React.ReactText[], confirm: (() => void) | undefined, _dataIndex: string) => {
    confirm && confirm()
  }

  const handleReset = (clearFilters: (() => void) | undefined) => {
    clearFilters && clearFilters()
  }

  const searchInput = React.useRef<Input>(null)
  const getColumnSearchProps = (dataIndex: string): ColumnProps<IndexFundType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex as keyof IndexFundType]
        ? record[dataIndex as keyof IndexFundType].toString().toLowerCase().includes(value.toString().toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select())
      }
    },
  })

  const deleteOneFunds = (fund_id: string) => {
    setLoadingDelete(true)
    APIService.indexManagerService
      .updateIndexManagerDetail(id, { delete_fund_ids: [fund_id] })
      .then(() => {
        notification.success({ message: 'Deleted Fund' })
        setListTab(listTab.filter((item) => item.type !== 'funds'))
        getIndexManagerDetail(id)
      })
      .catch((err) => {
        console.error({ err })
        notification.error({ message: 'Delete Failed!' })
      })
      .finally(() => {
        setLoadingDelete(false)
      })
  }

  const columns: ColumnProps<IndexFundType>[] = [
    {
      title: 'ID',
      dataIndex: 'fund_id',
      key: 'fund_id',
      width: 90,
      ...getColumnSearchProps('fund_id'),
      sorter: (a, b) => {
        if (a.fund_id && b.fund_id) {
          return a.fund_id.localeCompare(b.fund_id)
        }
        return 0
      },
    },
    {
      title: 'Fund Name',
      dataIndex: 'fund_name',
      key: 'fund_name',
      ...getColumnSearchProps('fund_name'),
      sorter: (a, b) => {
        if (a.fund_name && b.fund_name) {
          return a.fund_name.localeCompare(b.fund_name)
        }
        return 0
      },
      render: (_, record) => (
        <Button
          type="link"
          style={{ padding: '4px 8px' }}
          onClick={() => {
            if (!listTab.find((e) => e.id.toString() === record.fund_id)) {
              setListTab([...listTab, { id: parseInt(record.fund_id), name: record.fund_name, type: 'funds' }])
            }
            setActiveTab(record.fund_id)
          }}
        >
          {record.fund_name}
        </Button>
      ),
    },
    {
      title: 'Latest ROR',
      dataIndex: 'latest_ror',
      key: 'latest_ror',
      width: 130,
      ...getColumnSearchProps('latest_ror'),
      sorter: (a, b) => {
        if (a.latest_ror && b.latest_ror) {
          return a.latest_ror - b.latest_ror > 0 ? 1 : -1
        }
        return 0
      },
    },
    {
      title: 'Latest ROR Date',
      dataIndex: 'latest_ror_date',
      key: 'latest_ror_date',
      width: 200,
      render: (_, record) => {
        return record.latest_ror_date ? (
          <Button
            type="link"
            style={{ padding: '4px 8px' }}
            onClick={() => {
              const index = listTab.findIndex((e) => e.id.toString() === record.fund_id)
              if (index === -1) {
                setListTab([
                  ...listTab,
                  {
                    id: parseInt(record.fund_id),
                    name: record.fund_name,
                    type: 'funds',
                    initialPath: LeftNavFundEnum.PERFORMANCE,
                    hyperlink: 'latest_ror_date',
                  },
                ])
              } else {
                setListTab([
                  ...listTab.slice(0, index),
                  { ...listTab[index], hyperlink: 'latest_ror_date' },
                  ...listTab.slice(index + 1, listTab.length),
                ])
              }
              setActiveTab(record.fund_id)
            }}
          >
            {GenUtil.getFormattedYearAndMonth(record.latest_ror_date)}
          </Button>
        ) : null
      },
      sorter: (a, b) => {
        if (a.latest_ror_date && b.latest_ror_date) {
          return moment(a.latest_ror_date).isAfter(moment(b.latest_ror_date)) ? 1 : -1
        }
        return 0
      },
    },
    {
      title: 'Fund Assets',
      dataIndex: 'fund_assets',
      key: 'fund_assets',
      ...getColumnSearchProps('fund_assets'),
      width: 140,
      sorter: (a, b) => {
        if (a.fund_assets && b.fund_assets) {
          return a.fund_assets - b.fund_assets > 0 ? 1 : -1
        }
        return 0
      },
    },
    {
      title: 'Fund Assets Date',
      dataIndex: 'fund_assets_date',
      key: 'fund_assets_date',
      width: 200,
      render: (_, record) => {
        return record.fund_assets_date ? (
          <Button
            type="link"
            style={{ padding: '4px 8px' }}
            onClick={() => {
              const index = listTab.findIndex((e) => e.id.toString() === record.fund_id)
              if (index === -1) {
                setListTab([
                  ...listTab,
                  {
                    id: parseInt(record.fund_id),
                    name: record.fund_name,
                    type: 'funds',
                    initialPath: LeftNavFundEnum.PERFORMANCE,
                    hyperlink: 'fund_assets_date',
                  },
                ])
              } else {
                setListTab([
                  ...listTab.slice(0, index),
                  { ...listTab[index], hyperlink: 'fund_assets_date' },
                  ...listTab.slice(index + 1, listTab.length),
                ])
              }
              setActiveTab(record.fund_id)
            }}
          >
            {GenUtil.getFormattedYearAndMonth(record.fund_assets_date)}
          </Button>
        ) : null
      },
      sorter: (a, b) => {
        if (a.fund_assets_date && b.fund_assets_date) {
          return moment(a.fund_assets_date).isAfter(moment(b.fund_assets_date)) ? 1 : -1
        }
        return 0
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 100,
      render: (_, record) => (
        <>
          <Popconfirm title="Are you sure you want to delete?" onConfirm={() => deleteOneFunds(record.fund_id)}>
            <Button type="link" style={{ padding: '4px 8px' }} loading={loadingDelete}>
              <DeleteTwoTone twoToneColor={Colors.danger} />
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ]

  return (
    <div style={{ padding: '1rem' }}>
      <div>
        <TextID>Index Manager #{id}</TextID>
        <Flex style={{ marginBottom: 10, alignItems: 'baseline' }}>
          <Flex>
            <Text style={{ fontSize: 24, fontWeight: 'bold', color: '#484848' }}>{name}</Text>
          </Flex>
          <Flex style={{ justifyContent: 'flex-end' }}>
            <Button
              danger
              type="primary"
              style={{ marginRight: '0.5rem' }}
              onClick={() => {
                setActionFunds(IndexManagerActionFund.DELETE_FUNDS)
                setShowModal(true)
              }}
            >
              <DeleteOutlined />
              Bulk Delete
            </Button>

            <Button
              type="primary"
              onClick={() => {
                setActionFunds(IndexManagerActionFund.ADD_FUNDS)
                setShowModal(true)
              }}
            >
              Add New Fund
            </Button>
          </Flex>
        </Flex>
        {actionFunds !== undefined && showModal && (
          <IndexManagerModalAddFund
            actionFunds={actionFunds}
            indexId={id}
            showModal={showModal}
            setShowModal={setShowModal}
            updateIndexManagerDetail={updateIndexManagerDetail}
          />
        )}
      </div>
      <Table
        rowKey="fund_id"
        size="small"
        scroll={{ y: 'calc(100vh - 400px)', x: 'min-content' }}
        dataSource={dataDetail?.index_funds}
        columns={columns}
        loading={dataLoading}
        pagination={{ defaultPageSize: 20 }}
        onChange={(_pagination, _filter: any, _sorter: any, extra) => {
          setCurrentSource(extra.currentDataSource)
        }}
      />
      <Button
        type="primary"
        style={{ position: 'absolute', bottom: 16, left: 10 }}
        onClick={handleExportIndexConstituents}
        disabled={currentSource.length === 0}
      >
        <DownloadOutlined />
        Export
      </Button>
    </div>
  )
}
