import { AxiosInstance, AxiosPromise } from 'axios'
import { FundListRecord } from '../models/ResponsesTypes'

export interface UniverseFundListRecord {
  fund_id: string
  fund_name: string
  firm_name: string
  strategy_name: string
  sub_strategy_name: string
  inception_date: string
  user_fund_id: string
  user_fund_name: string
  user_fund_strategy_name: string
  user_fund_sub_strategy_name: string
}

export interface UpdateFundListParams {
  name?: string
  add?: string[]
  delete?: string[]
  user_fund_add?: number[]
  user_fund_delete?: string[]
}

export interface UniverseFundListResponse {
  count: number
  funds: UniverseFundListRecord[]
}

export interface FundAndUniverseListService {
  fetchFundList: () => AxiosPromise<FundListRecord[]>
  fetchFundListByListId: ({
    listId,
    page,
    sortBy,
    orderBy,
  }: {
    listId: string
    page?: number
    sortBy: 'ASC' | 'DESC'
    orderBy: keyof UniverseFundListRecord
  }) => AxiosPromise<UniverseFundListResponse>
  sendGroupReportByListId: (listId: string) => AxiosPromise<void>
  createFundList: (name: string, fundIds: string[]) => AxiosPromise<void>
  updateFundList: (id: string, data: UpdateFundListParams) => AxiosPromise<void>
  deleteFundList: (fundId: string) => AxiosPromise<void>
  shareFundList: (id: string, shareTo: number[], isShareAll: boolean) => AxiosPromise<void>

  fetchUniverseList: () => AxiosPromise<FundListRecord[]>
  fetchFundsByUniverseListId: (listId: string) => AxiosPromise<string[]>
  sendGroupREportByUniverseListId: (listId: string) => AxiosPromise<void>
}

export const FundAndUniverseListService = (request: AxiosInstance): FundAndUniverseListService => {
  return {
    fetchFundList: () => request.get('/fund-list'),
    fetchFundListByListId: ({ listId, page, sortBy, orderBy }) =>
      request.get(`/fund-list/${listId}`, {
        params: {
          page,
          sort_by: sortBy,
          orderBy: orderBy,
        },
      }),
    sendGroupReportByListId: (listId) => request.post(`/fund-list/${listId}/group-report`),
    createFundList: (name, fundIds) =>
      request.post(`/fund-list`, {
        name,
        fund_ids: fundIds,
      }),
    updateFundList: (id, data) => request.put(`/fund-list/${id}`, data),
    deleteFundList: (id) => request.delete(`/fund-list/${id}`),
    shareFundList: (id, shareTo, isShareAll) =>
      request.post(`/fund-list/${id}/share`, {
        user_ids: shareTo,
        all: isShareAll,
      }),

    fetchUniverseList: () => request.get('/universe-list', { params: { manager_link: true } }),
    fetchFundsByUniverseListId: (listId) => request.get(`/universe-list/${listId}`),
    sendGroupREportByUniverseListId: (listId) => request.post(`/universe-list/${listId}/group-report`),
  }
}
