import { CopyOutlined, DeleteTwoTone, EditOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu, notification, Select, Tooltip } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import APIService from '../../../shared/api'
import { Colors } from '../../../shared/colors'
import { useServiceState } from '../../../shared/hooks/useServiceState'
import { updateFundSearchColumns } from '../../../slice/appSettingsSlice'
import { ModalEditQuerySearch } from './ModalEditQuerySearch'
import { updateFundSearchParams } from './SearchForm/redux/searchParamsSlice'

type Props = {}
export const FundQuerySearch: React.FC<Props> = () => {
  const [showSaveQuery, setShowSaveQuery] = React.useState(false)
  const [showCreateQuery, setShowCreateQuery] = React.useState(false)
  const [selectedQuery, setSelectedQuery] = React.useState<{ id: number; name: string } | undefined>()
  const [loadingDelete, setLoadingDelete] = React.useState(false)
  const [createdQueryName, setCreatedQueryName] = React.useState<string | undefined>()

  const { invoke: fetchSearchQuery, data: dataSearchQuery } = useServiceState(APIService.fundService.fetchMeFundSearch)

  const dispatch = useDispatch()

  React.useEffect(() => {
    fetchSearchQuery()
  }, [fetchSearchQuery])

  React.useEffect(() => {
    if (createdQueryName && dataSearchQuery) {
      const selected = dataSearchQuery.find((item) => item.name === createdQueryName)
      if (selected) {
        selected && setSelectedQuery({ id: selected.id, name: selected.name })
        setCreatedQueryName(undefined)
      }
    }
  }, [createdQueryName, dataSearchQuery])

  const renderAction = () => {
    return (
      <Menu
        onClick={(e) => {
          if (e.key === '1') {
            setShowSaveQuery(true)
          }
          if (e.key === '2') {
            setShowCreateQuery(true)
          }
          if (e.key === '3') {
            handleDeleteQuery()
          }
        }}
      >
        <Menu.Item key="1" disabled={!selectedQuery} style={{ textAlign: 'center' }}>
          <Tooltip title="Save">
            <SaveOutlined style={{ fontSize: 18 }} />
          </Tooltip>
        </Menu.Item>
        <Menu.Item key="2" style={{ textAlign: 'center' }}>
          <Tooltip title="Save As">
            <CopyOutlined style={{ fontSize: 18 }} />
          </Tooltip>
        </Menu.Item>
        <Menu.Item key="3" disabled={!selectedQuery} style={{ textAlign: 'center' }}>
          <Tooltip title="Delete">
            <DeleteTwoTone twoToneColor={Colors.danger} style={{ fontSize: 18 }} />
          </Tooltip>
        </Menu.Item>
      </Menu>
    )
  }

  const handleUpdateQuery = async (id: number) => {
    try {
      const res = await APIService.fundService.fetchFundSearchQueryDetail(id)
      const data = res.data.json

      data && data.params && dispatch(updateFundSearchParams(data.params))
      data && data.columns && dispatch(updateFundSearchColumns(data.columns))
    } catch (err) {
      console.error({ err })
      notification.error({ message: 'Load Query Failed!' })
    }
  }

  const refetchQuery = () => {
    fetchSearchQuery()
  }

  const handleDeleteQuery = () => {
    if (!selectedQuery) {
      return
    }
    setLoadingDelete(true)
    APIService.fundService
      .deleteFundSearchQueryDetail(selectedQuery?.id)
      .then(() => {
        notification.success({ message: 'Search Query Delete!' })
        refetchQuery()
        setSelectedQuery(undefined)
      })
      .catch((err) => {
        console.error({ err })
        notification.error({ message: 'Search Query Delete Failed!' })
      })
      .finally(() => {
        setLoadingDelete(false)
      })
  }

  React.useEffect(() => {
    if (dataSearchQuery && selectedQuery) {
      const index = dataSearchQuery.findIndex((item) => item.id === selectedQuery.id)
      if (index > -1 && dataSearchQuery[index].name !== selectedQuery.name)
        setSelectedQuery({ id: dataSearchQuery[index].id, name: dataSearchQuery[index].name })
    }
  }, [dataSearchQuery, selectedQuery])

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {dataSearchQuery && (
        <div>
          <span style={{ marginRight: 4 }}>Query:</span>
          <Select
            allowClear
            style={{ width: 200 }}
            placeholder="Please Select Query"
            value={selectedQuery?.id}
            onChange={(e) => {
              if (e) {
                handleUpdateQuery(Number(e))
                const selected = dataSearchQuery.find((item) => item.id === e)
                selected && setSelectedQuery({ id: selected.id, name: selected.name })
              } else {
                setSelectedQuery(undefined)
              }
            }}
          >
            {dataSearchQuery.map((item) => {
              return (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              )
            })}
          </Select>
          <Dropdown trigger={['click']} overlay={renderAction}>
            <Button loading={loadingDelete}>
              <EditOutlined />
            </Button>
          </Dropdown>
        </div>
      )}

      {showSaveQuery && selectedQuery && (
        <ModalEditQuerySearch
          refetchQuery={refetchQuery}
          showModal={showSaveQuery}
          setShowModal={setShowSaveQuery}
          selectedQuery={selectedQuery}
        />
      )}
      {showCreateQuery && (
        <ModalEditQuerySearch
          refetchQuery={refetchQuery}
          showModal={showCreateQuery}
          setShowModal={setShowCreateQuery}
          setCreatedQueryName={setCreatedQueryName}
        />
      )}
    </div>
  )
}
