import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DraftType, Snapshot, ViewModeType } from '../shared/DraftManager'
import { LeftNavExFirmEnum } from '../shared/SearchExFirmLabel'
import { LeftNavExFundEnum } from '../shared/SearchExFundLabel'

export const DASHBOARD_KEY = 'dashboard'
export const MANAGER_LIST_KEY = 'manager-list'
export const PREFIX_EXTERNAL = 'ex'
export const EX_OVERVIEW_KEY = `${PREFIX_EXTERNAL}-overview`

export enum FundFirmTypeEnum {
  FUNDS = 'FUNDS',
  FIRMS_IN_FUNDS = 'FIRMS_IN_FUNDS',
  FIRMS = 'FIRMS',
}

interface FundTabsType {
  fundFirmTabs: {
    tabList: {
      name?: string
      id: string
      type: FundFirmTypeEnum
      path?: string
      viewMode?: ViewModeType
      hyperlinkTable?: string
      isPreview?: boolean
      previewTab?: string
    }[]
    activeTab: string
  }
}

interface ExTabsType {
  exTabs: {
    tabList: {
      name?: string
      id: string
      type: FundFirmTypeEnum
      activeNav: LeftNavExFundEnum | LeftNavExFirmEnum
      path?: string
      hyperlinkTable?: string
      isPreview?: boolean
      previewTab?: string
    }[]
    activeTab: string
  }
}

interface FirmTabsType {
  firmTabs: {
    tabList: {
      id: string
      name?: string
      type: FundFirmTypeEnum
      path?: string
    }[]
    activeTab: string
  }
}

interface AppSettingsState {
  exFundTableColumns: string[]
  fundSearchColumns: string[]
  firmSearchColumns: string[]
  fundsOfFirmColumns: string[]
  performanceNavDate: { navStartDate: string; navToDate: string } | undefined
  showPrompt: boolean
}

const initialState: AppSettingsState & FundTabsType & ExTabsType & FirmTabsType & { [key in DraftType]: Snapshot[] } = {
  exFundTableColumns: [],
  fundSearchColumns: [],
  firmSearchColumns: [],
  fundsOfFirmColumns: [],

  fundFirmTabs: { tabList: [], activeTab: DASHBOARD_KEY },
  exTabs: { tabList: [], activeTab: EX_OVERVIEW_KEY },
  firmTabs: { tabList: [], activeTab: MANAGER_LIST_KEY },
  fundDraft: [],
  exFirmDraft: [],
  exFundDraft: [],
  firmDraft: [],
  performanceNavDate: undefined,
  showPrompt: true,
}

const appSettings = createSlice({
  name: 'appSettings',
  initialState,
  reducers: {
    // Nav From & To Date
    updateNavDate: (state, action: PayloadAction<{ fromDate: string; toDate: string }>) => {
      const val = { navStartDate: action.payload.fromDate, navToDate: action.payload.toDate }
      state.performanceNavDate = val
    },

    // funds of firm columns
    updateFundsOfFirmColumns: (state, action: PayloadAction<AppSettingsState['fundsOfFirmColumns']>) => {
      state.fundsOfFirmColumns = action.payload
    },

    // fund/firm search columns
    updateExFundColumns: (state, action: PayloadAction<AppSettingsState['exFundTableColumns']>) => {
      state.exFundTableColumns = action.payload
    },
    updateFundSearchColumns: (state, action: PayloadAction<AppSettingsState['fundSearchColumns']>) => {
      state.fundSearchColumns = action.payload
    },
    updateFirmSearchColumns: (state, action: PayloadAction<AppSettingsState['firmSearchColumns']>) => {
      state.firmSearchColumns = action.payload
    },

    updateSelectedNav: (
      state,
      action: PayloadAction<{ id: string; activeNav: LeftNavExFirmEnum | LeftNavExFundEnum }>,
    ) => {
      const index = state.exTabs.tabList.findIndex((item) => item.id === action.payload.id)
      if (index > -1) {
        state.exTabs.tabList[index].activeNav = action.payload.activeNav
      }
    },
    // Tab
    addTabs: (
      state,
      action: PayloadAction<{
        name?: string
        id: string
        type: FundFirmTypeEnum
        activeNav?: LeftNavExFirmEnum | LeftNavExFundEnum
        path?: string
        viewMode?: ViewModeType
        hyperlinkTable?: string
        isPreview?: boolean
        previewTab?: string
      }>,
    ) => {
      const tabId = action.payload.id
      // External Tabs
      if (tabId.includes(PREFIX_EXTERNAL)) {
        // add new tab
        if (!state.exTabs.tabList.find((e) => e.id === tabId)) {
          const activeNav =
            action.payload.activeNav || action.payload.type === FundFirmTypeEnum.FIRMS_IN_FUNDS
              ? LeftNavExFirmEnum.ASSETS_UNDER_MANAGEMENT
              : LeftNavExFundEnum.FUND_OVERVIEW
          state.exTabs.tabList.push({
            ...action.payload,
            activeNav,
          })
        }
        // update active nav (left side nav)
        if (action.payload.activeNav) {
          const index = state.exTabs.tabList.findIndex((item) => item.id === tabId)
          state.exTabs.tabList[index].activeNav = action.payload.activeNav
          // update active table for Fund Performance - hyperlink feature
          if (action.payload.hyperlinkTable) {
            state.exTabs.tabList[index].hyperlinkTable = action.payload.hyperlinkTable
          }
        }
        const current = state.exTabs.tabList.findIndex((item) => item.id === tabId)
        state.exTabs.tabList[current].isPreview = action.payload.isPreview
        state.exTabs.tabList[current].previewTab = action.payload.previewTab

        state.exTabs.activeTab = action.payload.id
        return
      }

      // Internal

      // // Manager List (Firm Menu)
      if (action.payload.type === FundFirmTypeEnum.FIRMS) {
        const existingTab = state.firmTabs.tabList.findIndex((tab) => tab.id === tabId)
        if (existingTab === -1) {
          state.firmTabs.tabList.push(action.payload)
        } else {
          state.firmTabs.tabList[existingTab] = action.payload
        }
        state.firmTabs.activeTab = action.payload.id
        return
      }

      // // Dashboard Tabs
      const existingTab = state.fundFirmTabs.tabList.findIndex((e) => e.id === tabId)
      if (existingTab === -1) {
        const viewMode = state.fundDraft.find((i) => i.id === action.payload.id)?.viewMode
        state.fundFirmTabs.tabList.push({ viewMode: viewMode || 'internal', ...action.payload })
      } else {
        state.fundFirmTabs.tabList[existingTab] = { ...action.payload, ...state.fundFirmTabs.tabList[existingTab] }
        state.fundFirmTabs.tabList[existingTab].isPreview = action.payload.isPreview
        state.fundFirmTabs.tabList[existingTab].previewTab = action.payload.previewTab
      }
      // update active nav (left side nav)
      if (action.payload.activeNav) {
        const index = state.fundFirmTabs.tabList.findIndex((item) => item.id === tabId)
        state.fundFirmTabs.tabList[index].path = action.payload.activeNav
      }
      state.fundFirmTabs.activeTab = action.payload.id
    },

    updateTabTitle: (state, action: PayloadAction<{ id: string; tabName: string }>) => {
      const updatedTabId = action.payload.id
      const selectedTab = state.fundFirmTabs.tabList.find((item) => item.id === updatedTabId)
      const selectedExTab = state.exTabs.tabList.find((item) => item.id === updatedTabId)
      if (selectedTab) {
        selectedTab.name = action.payload.tabName
      }
      if (selectedExTab) {
        selectedExTab.name = action.payload.tabName
      }
    },
    updateActiveTabs: (state, action: PayloadAction<string>) => {
      if (action.payload.includes(PREFIX_EXTERNAL)) {
        state.exTabs.activeTab = action.payload
        return
      }
      state.fundFirmTabs.activeTab = action.payload
    },
    removeTabs: (state, action: PayloadAction<string>) => {
      const removeTabId = action.payload
      if (removeTabId.includes(PREFIX_EXTERNAL)) {
        const newState = state.exTabs.tabList.filter((item) => item.id !== removeTabId)
        state.exTabs.tabList = newState
        if (state.exTabs.activeTab === removeTabId) {
          state.exTabs.activeTab = EX_OVERVIEW_KEY
        }
        return
      }
      const newState = state.fundFirmTabs.tabList.filter((item) => item.id !== removeTabId)
      state.fundFirmTabs.tabList = newState
      const removingFundDraft = state.fundDraft.find((item) => item.id === removeTabId)
      if (removingFundDraft && removingFundDraft.lastFieldChange) {
        removingFundDraft._lastFieldChanged = removingFundDraft.lastFieldChange
      }
      if (state.fundFirmTabs.activeTab === removeTabId) {
        state.fundFirmTabs.activeTab = DASHBOARD_KEY
      }
    },
    // Draft
    addDraft: (state, action: PayloadAction<{ type: DraftType; data: Snapshot }>) => {
      const { type, data } = action.payload
      const drafts = state[type]

      // keep `lastFieldUpdate` if not provided
      const currentSnapshot = drafts.find((item) => item.snapshotId === data.snapshotId)
      return {
        ...state,
        [type]: [
          ...drafts.filter((draft) => draft.snapshotId !== data.snapshotId),
          {
            ...data,
            lastFieldChange:
              data.lastFieldChange === undefined ? currentSnapshot?.lastFieldChange : data.lastFieldChange,
          },
        ],
      }
    },
    removeDraft: (state, action: PayloadAction<{ type: DraftType; snapshotId: string }>) => {
      const { type, snapshotId } = action.payload
      const drafts = state[type]
      return { ...state, [type]: drafts.filter((draft) => draft.snapshotId !== snapshotId) }
    },

    updateFirmActiveTabs: (state, action: PayloadAction<string>) => {
      state.firmTabs.activeTab = action.payload
    },
    removeFirmTabs: (state, action: PayloadAction<string>) => {
      const removeTabId = action.payload
      const newState = state.firmTabs.tabList.filter((item) => item.id !== removeTabId)
      state.firmTabs.tabList = newState
      if (state.firmTabs.activeTab === removeTabId) {
        state.firmTabs.activeTab = MANAGER_LIST_KEY
      }
    },
    toggleShowPrompt: (state, action: PayloadAction<boolean>) => {
      return { ...state, showPrompt: action.payload }
    },
  },
})

const { reducer, actions } = appSettings

export const {
  updateFundSearchColumns,
  updateFirmSearchColumns,
  updateExFundColumns,
  updateFundsOfFirmColumns,
  addTabs,
  updateNavDate,
  updateActiveTabs,
  removeTabs,
  updateTabTitle,
  updateSelectedNav,
  updateFirmActiveTabs,
  removeFirmTabs,
  addDraft,
  removeDraft,
  toggleShowPrompt,
} = actions
export default reducer
