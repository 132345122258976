import { Tabs } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../../../app/rootReducer'
import APIService from '../../../../shared/api'
import { FundDetailDataContext } from '../../../../shared/api/context/FundDetailDataContext'
import { FundDetailLabelSearchContext } from '../../../../shared/api/context/FundDetailLabelSearchContext'
import { useServiceState } from '../../../../shared/hooks/useServiceState'
import { LeftNavFundEnum, renderSearchListFund, RightTabFundEnum } from '../../../../shared/SearchFundLabel'
import { FundInfoElectronicTrading } from './ElectronicTrading/FundInfoElectronicTrading'
import { FundContact } from './FundContact/FundContact'
import { FundInfoMain } from './FundInfo/FundInfoMain'
import { FundInvestmentInfoMain } from './FundInvestmentInfo/FundInvestmentInfoMain'
import { FundInfoIndustry } from './Industry/FundInfoIndustry'
import { FundInfoInstrument } from './Instrument/FundInfoInstrument'
import { FundInfoInvestorType } from './InvestorType/FundInfoInvestorType'
import { FundInfoLeverage } from './Leverage/FundInfoLeverage'
import { OffshoreFundInfo } from './OffshoreFundInfo/OffshoreFundInfo'
import { FundInfoRegion } from './Region/FundInfoRegion'
const { TabPane } = Tabs

const StyledTabs = styled(Tabs)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`

const RIGHT_TAB_PANE = [
  {
    id: RightTabFundEnum.FUND_INFO,
  },
  {
    id: RightTabFundEnum.FUND_INVESTMENT_INFO,
  },
  {
    id: RightTabFundEnum.OFFSHORE_FUND_INFO,
  },
  {
    id: RightTabFundEnum.FUND_CONTACT,
  },
  {
    id: RightTabFundEnum.LEVERAGE,
  },
  {
    id: RightTabFundEnum.INSTRUMENT,
  },
  {
    id: RightTabFundEnum.INDUSTRY,
  },
  {
    id: RightTabFundEnum.INVESTOR_TYPE,
  },
  {
    id: RightTabFundEnum.REGION,
  },
  {
    id: RightTabFundEnum.ELECTRONIC_TRADING_PLATFORM,
  },
]

type Props = { form: FormInstance; setIsUpdateFund: (isUpdate: boolean) => void }

export const FundInformationMain: React.FC<Props> = ({ form, setIsUpdateFund }) => {
  const { dataFundDetail } = React.useContext(FundDetailDataContext)
  const { isFiltering, filterArr } = React.useContext(FundDetailLabelSearchContext)

  const listSearch = React.useMemo(() => {
    return filterArr.filter((item) => item.leftNav === LeftNavFundEnum.INFORMATION)
  }, [filterArr])

  const { invoke: getFundTableDropdown, data: dataTableDropdown } = useServiceState(
    APIService.fundDetailService.fetchFundTableDropdown,
  )

  const [activeTab, setActiveTab] = React.useState(RightTabFundEnum.FUND_INFO)

  React.useEffect(() => {
    if (isFiltering && listSearch[0].rightTab) {
      setActiveTab(listSearch[0].rightTab)
    }
  }, [isFiltering, listSearch])

  const appSetting = useSelector((state: RootState) => state.appSettings)
  const lastKey = appSetting.fundDraft.find((item) => item.id === dataFundDetail?.fund_id)?.lastFieldChange

  React.useEffect(() => {
    if (lastKey) {
      const activeTab = renderSearchListFund.find((item) => item.key === lastKey)?.rightTab
      activeTab && RIGHT_TAB_PANE.findIndex((item) => item.id === activeTab) > -1 && setActiveTab(activeTab)
    }
  }, [lastKey])

  React.useEffect(() => {
    getFundTableDropdown()
  }, [getFundTableDropdown])

  const renderTab = (index: RightTabFundEnum) => {
    switch (index) {
      case RightTabFundEnum.FUND_INFO:
        return <FundInfoMain form={form} />
      case RightTabFundEnum.FUND_INVESTMENT_INFO:
        return <FundInvestmentInfoMain form={form} />
      case RightTabFundEnum.OFFSHORE_FUND_INFO:
        return <OffshoreFundInfo />
      case RightTabFundEnum.FUND_CONTACT:
        return <FundContact form={form} setIsUpdateFund={setIsUpdateFund} />
      case RightTabFundEnum.LEVERAGE:
        return <FundInfoLeverage />
      case RightTabFundEnum.INSTRUMENT:
        return dataTableDropdown && <FundInfoInstrument data={dataTableDropdown.instruments} />
      case RightTabFundEnum.INDUSTRY:
        return dataTableDropdown && <FundInfoIndustry data={dataTableDropdown.industries} />
      case RightTabFundEnum.INVESTOR_TYPE:
        return dataTableDropdown && <FundInfoInvestorType data={dataTableDropdown.investor_types} />
      case RightTabFundEnum.REGION:
        return dataTableDropdown && <FundInfoRegion form={form} data={dataTableDropdown.region_weights} />
      case RightTabFundEnum.ELECTRONIC_TRADING_PLATFORM:
        return (
          dataTableDropdown && (
            <FundInfoElectronicTrading dataDropdown={dataTableDropdown.electronic_trading_platforms} />
          )
        )
      default:
        break
    }
  }
  return (
    <StyledTabs
      hideAdd
      type="editable-card"
      activeKey={activeTab}
      onChange={(e) => setActiveTab(e as RightTabFundEnum)}
    >
      {RIGHT_TAB_PANE.map((item) => {
        const showTabPane = listSearch.findIndex((i) => i.rightTab && i.rightTab === item.id)
        if (showTabPane === -1 && isFiltering) {
          return null
        }
        return (
          <TabPane tab={item.id} key={item.id} closable={false}>
            {renderTab(item.id)}
          </TabPane>
        )
      })}
    </StyledTabs>
  )
}
