import { Col, Form, Modal, notification, Row, Spin } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/rootReducer'
import APIService from '../../shared/api'
import { ExFundDetails } from '../../shared/api/models/ExFundDetails'
import { FormItemInput, FormItemTextArea } from '../../shared/components/FormItemComponents'
type Props = {
  showModal: boolean
  setShowModal: (value: boolean) => void
  selectedFund: ExFundDetails
  setSelectedFund: (value: ExFundDetails | undefined) => void
  updateListFund: () => void
}
export const ModalFundActivation: React.FC<Props> = ({
  showModal,
  setShowModal,
  selectedFund,
  setSelectedFund,
  updateListFund,
}) => {
  const [loadingSubmit, setLoadingSubmit] = React.useState(false)
  const [form] = Form.useForm()

  const handleSubmit = () => {
    form.validateFields(['contact_person', 'contact_phone', 'contact_email', 'additional_comments']).then(() => {
      setLoadingSubmit(true)
      const submitData = {
        contact_person: form.getFieldValue('contact_person'),
        contact_phone: form.getFieldValue('contact_phone'),
        contact_email: form.getFieldValue('contact_email'),
        additional_comments: form.getFieldValue('additional_comments'),
      }
      APIService.fundDetailService
        .updateFundStatusActivate(selectedFund.fund_id, submitData)
        .then(() => {
          notification.success({ message: 'Your activation request has been sent to our team!' })
          updateListFund()
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          setLoadingSubmit(false)
          setShowModal(false)
          setSelectedFund(undefined)
        })
    })
  }

  const { user } = useSelector((state: RootState) => state.user)

  React.useEffect(() => {
    if (user) {
      form.setFieldsValue({
        contact_person: user.first_name,
        contact_phone: user.business_phone,
        contact_email: user.email,
      })
    }
  }, [form, user])

  return (
    <Modal
      width={500}
      visible={showModal}
      onCancel={() => {
        setShowModal(false)
        setSelectedFund(undefined)
      }}
      title="Fund Status Change Request"
      okText={
        loadingSubmit ? (
          <>
            <Spin style={{ marginRight: 4 }} />
          </>
        ) : (
          'OK'
        )
      }
      onOk={handleSubmit}
    >
      <Form layout="vertical" form={form}>
        <Row>
          <Col span={24}>
            <FormItemInput
              name="contact_person"
              label="Contact Person"
              placeholder="Contact Person..."
              rules={[{ required: true, message: 'This Field is required!' }]}
            />
            <FormItemInput
              name="contact_phone"
              label="Contact Phone"
              placeholder="Contact Phone..."
              rules={[{ required: true, message: 'This Field is required!' }]}
            />
            <FormItemInput
              name="contact_email"
              label="Contact Email"
              placeholder="Contact Email..."
              rules={[{ required: true, message: 'This Field is required!' }]}
            />
            <FormItemTextArea
              name="additional_comments"
              label="Additional Comment"
              row={4}
              rules={[{ required: true, message: 'This Field is required!' }]}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
