import { Modal, notification, Tabs } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'
import { FundDetailDataContext } from '../../../../shared/api/context/FundDetailDataContext'
import { FundDetailLabelSearchContext } from '../../../../shared/api/context/FundDetailLabelSearchContext'
import { useDraft } from '../../../../shared/DraftManager'
import { LeftNavFundEnum, RightTabFundEnum } from '../../../../shared/SearchFundLabel'
import { APGL } from '../NAVMMMain/APGL'
import { Distributions } from '../NAVMMMain/Distributions'
import { MilionsManaged } from '../NAVMMMain/NAVMM/MilionsManaged'
import { NAVPerUnit } from '../NAVMMMain/NAVMM/NAVPerUnit'
import { FundROR } from './FundROR'
import { PerformanceUpdate } from './PerformanceUpdate'

const { TabPane } = Tabs

const StyledTabs = styled(Tabs)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`

const LIST_TABPANE = [
  {
    id: RightTabFundEnum.PERFORMANCE_UPDATE,
  },
  {
    id: RightTabFundEnum.ROR,
  },
  {
    id: RightTabFundEnum.ASSET,
  },
  {
    id: RightTabFundEnum.NAV,
  },
  {
    id: RightTabFundEnum.DISTRIBUTIONS,
  },
  {
    id: RightTabFundEnum.APGL,
  },
]
type Props = {
  setIsUpdateFund: (isUpdate: boolean) => void
  hyperlinkTable?: string
}
export const FundPerformance: React.FC<Props> = ({ setIsUpdateFund, hyperlinkTable }) => {
  const { isFiltering, filterArr } = React.useContext(FundDetailLabelSearchContext)
  const { dataFundDetail } = React.useContext(FundDetailDataContext)

  const listSearch = React.useMemo(() => {
    return filterArr.filter((item) => item.leftNav === LeftNavFundEnum.PERFORMANCE)
  }, [filterArr])

  const [activeKey, setActiveKey] = React.useState(RightTabFundEnum.PERFORMANCE_UPDATE)

  React.useEffect(() => {
    if (isFiltering) {
      listSearch.length > 0 && listSearch[0].rightTab && setActiveKey(listSearch[0].rightTab)
    }
  }, [isFiltering, listSearch])

  const { loadSnapshot } = useDraft()

  React.useEffect(() => {
    if (!dataFundDetail) {
      return
    }
    const draft = loadSnapshot('fundDraft', dataFundDetail.fund_id)
    if (hyperlinkTable) {
      if (draft && draft._latestPerformanceDataChange) {
        setActiveKey(RightTabFundEnum.PERFORMANCE_UPDATE)
        notification.warn({
          message: 'There are unsaved changes in Performance Update. Please save or discard the changes to continue.',
        })
        return
      }
      switch (hyperlinkTable) {
        case 'fund_assets':
        case 'fund_assets_date':
          setActiveKey(RightTabFundEnum.ASSET)
          break
        case 'latest_ror':
        case 'latest_ror_date':
          setActiveKey(RightTabFundEnum.ROR)
          break
        default:
          setActiveKey(RightTabFundEnum.PERFORMANCE_UPDATE)
      }
      return
    }
    if (draft && draft._RORDataChange) {
      setActiveKey(RightTabFundEnum.ROR)
      return
    }
    if (draft && draft._navDataChange) {
      setActiveKey(RightTabFundEnum.NAV)
      return
    }
    if (draft && draft._assetDataChange) {
      setActiveKey(RightTabFundEnum.ASSET)
      return
    }
  }, [dataFundDetail, hyperlinkTable, loadSnapshot])

  const renderTab = (index: RightTabFundEnum) => {
    switch (index) {
      case RightTabFundEnum.PERFORMANCE_UPDATE:
        return (
          <PerformanceUpdate fundDetails={dataFundDetail} inceptionDate={dataFundDetail?.inception_date || undefined} />
        )
      case RightTabFundEnum.ROR:
        return <FundROR />
      case RightTabFundEnum.ASSET:
        return <MilionsManaged />
      case RightTabFundEnum.NAV:
        return <NAVPerUnit setIsUpdateFund={setIsUpdateFund} />
      case RightTabFundEnum.DISTRIBUTIONS:
        return <Distributions />
      case RightTabFundEnum.APGL:
        return <APGL />
      default:
        break
    }
  }

  const {
    performanceDataChange,
    setPerformanceDataChange,
    rorDataChange,
    setRORDataChange,
    navDataChange,
    setNAVDataChange,
    assetDataChange,
    setAssetDataChange,
    clearSnapshot,
    dataFundDetailChange,
  } = React.useContext(FundDetailDataContext)

  const dataChangeRef = dataFundDetailChange?.current

  const handleTabChange = (key: string) => {
    // Case that update Latest Performance and switch to one of ROR/Asset/Nav tab
    if (
      performanceDataChange &&
      activeKey === RightTabFundEnum.PERFORMANCE_UPDATE &&
      Object.keys(performanceDataChange).length > 0 &&
      (key === RightTabFundEnum.ROR || key === RightTabFundEnum.ASSET || key === RightTabFundEnum.NAV)
    ) {
      setShowConfirm(true)
      setTargetKey(key)
      return
    }
    // Reversed case
    if (
      key === RightTabFundEnum.PERFORMANCE_UPDATE &&
      (rorDataChange.values || navDataChange.values || assetDataChange.values)
    ) {
      setShowConfirm(true)
      setTargetKey(key)
      return
    }
    setActiveKey(key as RightTabFundEnum)
  }

  const [showConfirm, setShowConfirm] = useState(false)
  const [targetKey, setTargetKey] = useState<RightTabFundEnum>()

  const handleOk = () => {
    // discard changes
    if (
      targetKey === RightTabFundEnum.ROR ||
      targetKey === RightTabFundEnum.NAV ||
      targetKey === RightTabFundEnum.ASSET
    ) {
      if (
        dataChangeRef &&
        Object.keys(dataChangeRef).filter((item) => item !== '_latestPerformanceDataChange').length === 0
      ) {
        setIsUpdateFund(false)
      }
      setPerformanceDataChange(undefined)
      // TODO: quick fix for now. Need to clear the specific key instead of the whole snapshot
      clearSnapshot()
      notification.success({ message: `Discard changes in ${activeKey}` })
      setShowConfirm(false)
      setActiveKey(targetKey)
      return
    }
    if (targetKey === RightTabFundEnum.PERFORMANCE_UPDATE) {
      if (
        dataChangeRef &&
        Object.keys(dataChangeRef).filter(
          (item) => item !== '_RORDataChange' && item !== '_assetDataChange' && item !== '_navDataChange',
        ).length === 0
      ) {
        setIsUpdateFund(false)
      }
      notification.success({ message: `Discard changes in ROR/Asset/Nav` })
      setRORDataChange({ values: undefined })
      setNAVDataChange({ values: undefined })
      setAssetDataChange({ values: undefined })
      clearSnapshot()
      setShowConfirm(false)
      setActiveKey(targetKey)
    }
  }

  const handleCancel = () => {
    setShowConfirm(false)
  }

  return (
    <>
      <StyledTabs hideAdd type="editable-card" activeKey={activeKey} onChange={handleTabChange}>
        {LIST_TABPANE.map((item) => {
          const showTabPane = listSearch.findIndex((i) => i.rightTab && i.rightTab === item.id)
          if (showTabPane === -1 && isFiltering) {
            return null
          }
          return (
            <TabPane tab={item.id} key={item.id} closable={false}>
              {renderTab(item.id)}
            </TabPane>
          )
        })}
      </StyledTabs>
      <Modal title="Discard Changes?" visible={showConfirm} onOk={handleOk} onCancel={handleCancel}>
        <span>
          <p>You have unsaved changes. </p>
          <p>
            Because of the conflict in data between the tables, the changes here should be saved or discarded before
            continue.
          </p>
          Select <b>OK</b> to discard the changes or <b>Cancel</b> to continue.
        </span>
      </Modal>
    </>
  )
}
