import { ReloadOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, DatePicker, Input, Pagination, Radio, Row, Space, Tag } from 'antd'
import Table, { ColumnProps } from 'antd/lib/table'
import { SorterResult } from 'antd/lib/table/interface'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../shared/api'
import { LegacyLogDetail, LegacyLogFilter } from '../../shared/api/models/ActivityLog'
import { Colors } from '../../shared/colors'
import { useServiceState } from '../../shared/hooks/useServiceState'
import { LegacyLogModal } from './LegacyLogModal'

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const LegacyActivityLog: React.FC = () => {
  const { invoke: fetchDataLegacy, data: dataLegacy, loading } = useServiceState(
    APIService.activityLogService.fetchLegacyLog,
  )

  const [filteredInfo, setFilteredInfo] = React.useState<LegacyLogFilter>({})

  const [page, setPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(25)
  const [sortBy, setSortBy] = React.useState<string | undefined>()
  const [orderBy, setOrderBy] = React.useState<string | undefined>()
  const [showModal, setShowModal] = React.useState(false)
  const [selectedLegacyLog, setSelectedLegacyLog] = React.useState<LegacyLogDetail>()

  const legacyParams = React.useMemo(() => {
    setPage(1)
    return Object.keys(filteredInfo).reduce((acc, key) => {
      const valueFilter = filteredInfo[key as keyof LegacyLogFilter]
      if (valueFilter && valueFilter.length > 0) {
        if (key === 'fund_id') {
          const type = valueFilter?.[0].toString().split('-')[0]
          const value = valueFilter?.[0].toString().split('-')[1]
          return type === '[Equals]' ? { ...acc, fund_id: { equals: value } } : { ...acc, fund_id: { contains: value } }
        }
        if (key === 'is_www') {
          const value = valueFilter?.[0]
          return value === 'Yes' ? { ...acc, [key]: true } : { ...acc, [key]: false }
        }
        if (key === 'work_date') {
          const startDate = valueFilter?.[0].toString().split(',')[0]
          const endDate = valueFilter?.[0].toString().split(',')[1]
          return { ...acc, [key]: { gte: startDate?.trim(), lte: endDate?.trim() } }
        }
        return { ...acc, [key]: valueFilter?.[0] }
      }
      return acc
    }, {})
  }, [filteredInfo])

  React.useEffect(() => {
    fetchDataLegacy({
      where: Object.keys(legacyParams).length > 0 ? legacyParams : undefined,
      page,
      limit: pageSize,
      sort_by: sortBy,
      order_by: orderBy,
    })
  }, [page, pageSize, sortBy, orderBy, legacyParams, fetchDataLegacy])

  const handleTableChange = (sorter: SorterResult<any>, filter: Record<string, string | null>) => {
    setSortBy(sorter.order ? sorter.columnKey?.toString() : undefined)
    setOrderBy(sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : undefined)
    setFilteredInfo(filter)
  }

  const handlePageSizeChange = (current: number, size: number) => {
    setPageSize(size)
    if (current !== 1) {
      setPage(1)
    }
  }

  const handleClearAllFilter = () => {
    setFilteredInfo({})
  }

  const searchInput = React.useRef<Input>(null)

  const getColumnSearchProps = (dataIndex: string): ColumnProps<LegacyLogDetail> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      if (dataIndex === 'is_www') {
        return (
          <div style={{ padding: 8 }}>
            <Row>
              <Radio.Group
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys([e.target.value])}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              >
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </Row>
            <Row>
              <Space>
                <Button
                  type="primary"
                  onClick={() => confirm && confirm()}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button onClick={() => clearFilters && clearFilters()} size="small" style={{ width: 90 }}>
                  Reset
                </Button>
              </Space>
            </Row>
          </div>
        )
      }
      if (dataIndex === 'fund_id') {
        return (
          <Row style={{ padding: 8 }}>
            <Space>
              <Input
                ref={searchInput}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0] ? selectedKeys[0].toString().split('-')[1] : undefined}
                allowClear
                onChange={(e) => {
                  setSelectedKeys(
                    e.target.value
                      ? [
                          selectedKeys[0]
                            ? `${selectedKeys[0].toString().split('-')[0]}-${e.target.value}`
                            : `-${e.target.value}`,
                        ]
                      : [],
                  )
                }}
              />
              <Button
                type="primary"
                onClick={() => {
                  if (selectedKeys[0]) {
                    setSelectedKeys([`[Equals]-${selectedKeys[0].toString().split('-')[1]}`])
                    return confirm && confirm()
                  }
                  return clearFilters && clearFilters()
                }}
              >
                Exact
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  if (selectedKeys[0]) {
                    setSelectedKeys([`[Contains]-${selectedKeys[0].toString().split('-')[1]}`])
                    return confirm && confirm()
                  }
                  return clearFilters && clearFilters()
                }}
              >
                Partial
              </Button>
              <Button onClick={() => clearFilters && clearFilters()} icon={<ReloadOutlined />} />
            </Space>
          </Row>
        )
      }
      if (dataIndex === 'work_date') {
        return (
          <div style={{ padding: 8 }}>
            <Row>
              <DatePicker.RangePicker
                value={
                  selectedKeys[0]
                    ? [
                        moment((selectedKeys[0] as string).split(',')[0]),
                        moment((selectedKeys[0] as string).split(',')[1]),
                      ]
                    : undefined
                }
                onChange={(e) => {
                  if (e) {
                    setSelectedKeys([`${moment(e[0]).format('YYYY-MM-DD')}, ${moment(e[1]).format('YYYY-MM-DD')}`])
                  }
                }}
                style={{ width: 250, marginBottom: 8 }}
              />
            </Row>
            <Row>
              <Space>
                <Button
                  type="primary"
                  onClick={() => confirm && confirm()}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button onClick={() => clearFilters && clearFilters()} size="small" style={{ width: 90 }}>
                  Reset
                </Button>
              </Space>
            </Row>
          </div>
        )
      }

      return (
        <Row style={{ padding: 8 }}>
          <Space>
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              allowClear
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => {
                if (selectedKeys[0] !== '') {
                  confirm && confirm()
                } else clearFilters && clearFilters()
              }}
            />
            <Button
              type="primary"
              onClick={() => {
                if (selectedKeys[0] !== '') {
                  confirm && confirm()
                } else clearFilters && clearFilters()
              }}
              icon={<SearchOutlined />}
            />
            <Button onClick={() => clearFilters && clearFilters()} icon={<ReloadOutlined />} />
          </Space>
        </Row>
      )
    },

    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select())
      }
    },
  })

  const columns: ColumnProps<LegacyLogDetail>[] = [
    {
      title: 'Work Date',
      dataIndex: 'work_date',
      key: 'work_date',
      width: 200,
      ...getColumnSearchProps('work_date'),
      filteredValue: filteredInfo.work_date || null,
      sorter: true,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 200,
      ...getColumnSearchProps('id'),
      filteredValue: filteredInfo.id || null,
      sorter: true,
    },
    {
      title: 'Action Done',
      dataIndex: 'action_done',
      key: 'action_done',
      width: 200,
      sorter: true,
      ...getColumnSearchProps('action_done'),
      filteredValue: filteredInfo.action_done || null,
    },
    {
      title: 'Data Source',
      dataIndex: 'data_source',
      key: 'data_source',
      width: 200,
      ...getColumnSearchProps('data_source'),
      filteredValue: filteredInfo.data_source || null,
      sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 200,
      ...getColumnSearchProps('email'),
      filteredValue: filteredInfo.email || null,
      sorter: true,
    },
    {
      title: 'Fields Of Operation',
      dataIndex: 'fields_of_operation',
      key: 'fields_of_operation',
      width: 200,
      ...getColumnSearchProps('fields_of_operation'),
      filteredValue: filteredInfo.fields_of_operation || null,
      sorter: true,
    },
    {
      title: 'Fund ID',
      dataIndex: 'fund_id',
      key: 'fund_id',
      width: 200,
      ...getColumnSearchProps('fund_id'),
      filteredValue: filteredInfo.fund_id || null,
      sorter: true,
    },

    {
      title: 'Is WWW',
      dataIndex: 'is_www',
      key: 'is_www',
      width: 200,
      ...getColumnSearchProps('is_www'),
      filteredValue: filteredInfo.is_www || null,
      sorter: true,
      render: (_, record) => (
        <span>
          {record.is_www === true && 'Yes'}
          {record.is_www === false && 'No'}
        </span>
      ),
    },
    {
      title: 'New 1',
      dataIndex: 'new1',
      key: 'new1',
      width: 200,
      ...getColumnSearchProps('new1'),
      filteredValue: filteredInfo.new1 || null,
      sorter: true,
    },
    {
      title: 'New 2',
      dataIndex: 'new2',
      key: 'new2',
      width: 200,
      ...getColumnSearchProps('new2'),
      filteredValue: filteredInfo.new2 || null,
      sorter: true,
    },
    {
      title: 'New 3',
      dataIndex: 'new3',
      key: 'new3',
      width: 200,
      ...getColumnSearchProps('new3'),
      filteredValue: filteredInfo.new3 || null,
      sorter: true,
    },
    {
      title: 'New 4',
      dataIndex: 'new4',
      key: 'new4',
      width: 200,
      ...getColumnSearchProps('new4'),
      filteredValue: filteredInfo.new4 || null,
      sorter: true,
    },
    {
      title: 'New 5',
      dataIndex: 'new5',
      key: 'new5',
      width: 200,
      ...getColumnSearchProps('new5'),
      filteredValue: filteredInfo.new5 || null,
      sorter: true,
    },
    {
      title: 'New 6',
      dataIndex: 'new6',
      key: 'new6',
      width: 200,
      ...getColumnSearchProps('new6'),
      filteredValue: filteredInfo.new6 || null,
      sorter: true,
    },
    {
      title: 'New 7',
      dataIndex: 'new7',
      key: 'new7',
      width: 200,
      ...getColumnSearchProps('new7'),
      filteredValue: filteredInfo.new7 || null,
      sorter: true,
    },
    {
      title: 'New 8',
      dataIndex: 'new8',
      key: 'new8',
      width: 200,
      ...getColumnSearchProps('new8'),
      filteredValue: filteredInfo.new8 || null,
      sorter: true,
    },
    {
      title: 'New 9',
      dataIndex: 'new9',
      key: 'new9',
      width: 200,
      ...getColumnSearchProps('new9'),
      filteredValue: filteredInfo.new9 || null,
      sorter: true,
    },
    {
      title: 'New 10',
      dataIndex: 'new10',
      key: 'new10',
      width: 200,
      ...getColumnSearchProps('new10'),
      filteredValue: filteredInfo.new10 || null,
      sorter: true,
    },
    {
      title: 'Old 1',
      dataIndex: 'old1',
      key: 'old1',
      width: 200,
      ...getColumnSearchProps('old1'),
      filteredValue: filteredInfo.old1 || null,
      sorter: true,
    },
    {
      title: 'Old 2',
      dataIndex: 'old2',
      key: 'old2',
      width: 200,
      ...getColumnSearchProps('old2'),
      filteredValue: filteredInfo.old2 || null,
      sorter: true,
    },
    {
      title: 'Old 3',
      dataIndex: 'old3',
      key: 'old3',
      width: 200,
      ...getColumnSearchProps('old3'),
      filteredValue: filteredInfo.old3 || null,
      sorter: true,
    },
    {
      title: 'Old 4',
      dataIndex: 'old4',
      key: 'old4',
      width: 200,
      ...getColumnSearchProps('old4'),
      filteredValue: filteredInfo.old4 || null,
      sorter: true,
    },
    {
      title: 'Old 5',
      dataIndex: 'old5',
      key: 'old5',
      width: 200,
      ...getColumnSearchProps('old5'),
      filteredValue: filteredInfo.old5 || null,
      sorter: true,
    },
    {
      title: 'Old 6',
      dataIndex: 'old6',
      key: 'old6',
      width: 200,
      ...getColumnSearchProps('old6'),
      filteredValue: filteredInfo.old6 || null,
      sorter: true,
    },
    {
      title: 'Old 7',
      dataIndex: 'old7',
      key: 'old7',
      width: 200,
      ...getColumnSearchProps('old7'),
      filteredValue: filteredInfo.old7 || null,
      sorter: true,
    },
    {
      title: 'Old 8',
      dataIndex: 'old8',
      key: 'old8',
      width: 200,
      ...getColumnSearchProps('old8'),
      filteredValue: filteredInfo.old8 || null,
      sorter: true,
    },
    {
      title: 'Old 9',
      dataIndex: 'old9',
      key: 'old9',
      width: 200,
      ...getColumnSearchProps('old9'),
      filteredValue: filteredInfo.old9 || null,
      sorter: true,
    },
    {
      title: 'Old 10',
      dataIndex: 'old10',
      key: 'old10',
      width: 200,
      ...getColumnSearchProps('old10'),
      filteredValue: filteredInfo.old10 || null,
      sorter: true,
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      key: 'remarks',
      width: 200,
      ...getColumnSearchProps('remarks'),
      filteredValue: filteredInfo.remarks || null,
      sorter: true,
    },
    {
      title: 'Screen Name',
      dataIndex: 'screen_name',
      key: 'screen_name',
      width: 200,
      ...getColumnSearchProps('screen_name'),
      filteredValue: filteredInfo.screen_name || null,
      sorter: true,
    },
    {
      title: 'User Name',
      dataIndex: 'username',
      key: 'username',
      width: 200,
      ...getColumnSearchProps('username'),
      filteredValue: filteredInfo.username || null,
      sorter: true,
    },
    {
      title: 'Action',
      key: 'action',
      width: 135,
      fixed: 'right',
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            type="primary"
            style={{ padding: '4px 8px' }}
            onClick={() => {
              setShowModal(true)
              setSelectedLegacyLog(record)
            }}
          >
            Details
          </Button>
        </div>
      ),
    },
  ]

  return (
    <div style={{ background: Colors.white }}>
      <div style={{ paddingLeft: 18, minHeight: 32 }}>
        {Object.keys(legacyParams).length > 0 && 'Filters: '}
        {Object.keys(filteredInfo)
          .filter((i) => filteredInfo[i as keyof LegacyLogFilter])
          .map((item) => {
            const value = filteredInfo[item as keyof LegacyLogFilter]
            if (value && value.length > 0) {
              return (
                <Tag
                  key={item}
                  closable
                  onClose={(e: any) => {
                    e.stopPropagation()
                    setFilteredInfo({ ...filteredInfo, [item]: null })
                  }}
                >
                  <strong>{item}</strong>:{value?.join(', ')}
                </Tag>
              )
            }
          })}
        {Object.keys(legacyParams).length > 0 && (
          <Button type="link" onClick={handleClearAllFilter}>
            Clear All
          </Button>
        )}
      </div>
      <Table
        rowKey="fund_id"
        dataSource={dataLegacy?.results}
        loading={loading}
        pagination={false}
        onChange={(_pagination, filter: any, sorter: any) => {
          handleTableChange(sorter, filter)
        }}
        style={{ width: '100%' }}
        scroll={{ y: 'calc(100vh - 360px)', x: 'min-content' }}
        size="small"
        footer={(record) =>
          record.length > 0 ? (
            <Footer>
              <Pagination
                showSizeChanger
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                onChange={(currentPage) => setPage(currentPage)}
                total={dataLegacy ? dataLegacy.total : 0}
                current={page}
                pageSize={pageSize}
                pageSizeOptions={['10', '25', '50', '100']}
                onShowSizeChange={handlePageSizeChange}
              />
            </Footer>
          ) : null
        }
        columns={columns}
      />
      {showModal && selectedLegacyLog?.diff && (
        <LegacyLogModal showModal={showModal} setShowModal={setShowModal} selectedLogDiff={selectedLegacyLog.diff} />
      )}
    </div>
  )
}
