import React from 'react'
import Modal from 'antd/lib/modal/Modal'
import { Form, Input, notification } from 'antd'
import { AnalysisType } from '../../shared/api/models/Analysis'
import APIService from '../../shared/api'
import { AnalysisGroupListContext } from '../../shared/api/context/AnalysisGroupListContext'

type Props = {
  isOpen: boolean
  onRequestClose: () => void
  selectedGroup: AnalysisType
}

const DuplicateGroupModal: React.FC<Props> = ({ isOpen, onRequestClose, selectedGroup }) => {
  const [name, setName] = React.useState<string>()
  const [submitting, setSubmitting] = React.useState<boolean>(false)

  const { refetchGroup, setAddedRow } = React.useContext(AnalysisGroupListContext)

  const handleDuplicate = () => {
    if (!selectedGroup) {
      return
    }
    if (!name) {
      notification.warn({ message: 'Name is required!' })
      return
    }
    setSubmitting(true)
    APIService.analysisService
      .createGroup(name, selectedGroup.parent_group_code, selectedGroup.group_code)
      .then((res) => {
        refetchGroup()
        notification.success({ message: 'Duplicate Group Successfully!' })
        onRequestClose()
        setAddedRow && setAddedRow(res.data.group_code)
      })
      .catch((err) => {
        console.error(err)
        notification.error({ message: 'Failed To Duplicate Selected Group!' })
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <Modal
      width={500}
      visible={isOpen}
      title={<div>Duplicate {selectedGroup.group_name}</div>}
      onCancel={onRequestClose}
      onOk={handleDuplicate}
      confirmLoading={submitting}
    >
      <Form>
        <Form.Item label="Name" name="name" noStyle>
          <Input placeholder="Enter New Group Name" value={name} onChange={(e) => setName(e.target.value)} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default DuplicateGroupModal
