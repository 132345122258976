import React from 'react'
import { default as Plotly, PlotData } from 'plotly.js'
import PanelDetailGraph from './PanelDetailGraph'
import { DateValueRecordList, DateValueRecord } from '../../shared/api/models/ResponsesTypes'

interface OwnProps {
  vami: DateValueRecordList | undefined
  loading?: boolean
}

/**
 * This will render VAMI graph component
 * @param vami - VAMI graph data
 * @param loading - whether to show loading icon or not
 * @constructor
 */
const VamiGraph: React.FC<OwnProps> = ({ vami, loading }) => {
  const getHoverText = (item: DateValueRecord) => {
    return `<i>Value: </i>: ${item.value}<br><i>Date</i>: ${item.date}<br><extra></extra>`
  }

  const getVAMIGraphData = () => {
    const graphData: Partial<PlotData>[] = []
    const datesArray: string[] = []
    if (vami) {
      for (const key in vami) {
        const x: string[] = []
        const y: number[] = []
        const hovertemplate: string[] = []

        vami[key as keyof DateValueRecordList].forEach((item) => {
          x.push(item.date)
          y.push(item.value)
          datesArray.push(item.date)
          hovertemplate.push(getHoverText(item))
        })
        graphData.push({
          x,
          y,
          hovertemplate,
          name: key[0].toUpperCase() + key.slice(1),
        })
      }
    }

    const graphLayout: Partial<Plotly.Layout> = {
      autosize: true,
      xaxis: {
        showgrid: false,
        showline: false,
        tickformat: '%Y-%m',
        categoryorder: 'array',
        categoryarray: [...new Set(datesArray)],
      },
      yaxis: {},
      margin: {
        l: 50,
        r: 50,
        b: 100,
        t: 100,
        pad: 4,
      },
      title: 'VAMI - Growth of 1000',
    }
    return {
      header: 'Value Added Money Index',
      graphData: graphData,
      graphLayout: graphLayout,
      loading,
    }
  }

  return <PanelDetailGraph {...getVAMIGraphData()} />
}

export default React.memo(VamiGraph)
