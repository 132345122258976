import { Col, Form, Modal, notification, Row, Spin } from 'antd'
import React from 'react'
import APIService from '../../../../shared/api'
import { DueDiligenceScoreCard, DueDiligenceScoreCardUpdate } from '../../../../shared/api/models/FundDetails'
import { FormItemInput, FormItemTextArea } from '../../../../shared/components/FormItemComponents'

type Props = {
  selectedScorecard: DueDiligenceScoreCard
  showModal: boolean
  setShowModal: (value: boolean) => void
  setSelectedScorecard: (value: DueDiligenceScoreCard | undefined) => void
  updateScorecard: () => void
  fundId: string
}
export const ScorecardModal: React.FC<Props> = ({
  selectedScorecard,
  showModal,
  setShowModal,
  setSelectedScorecard,
  updateScorecard,
  fundId,
}) => {
  const [form] = Form.useForm()
  const [loadingSubmit, setLoadingSubmit] = React.useState(false)

  React.useEffect(() => {
    if (selectedScorecard) {
      form.setFieldsValue(selectedScorecard)
    } else {
      form.resetFields()
    }
  }, [form, selectedScorecard])

  const handleSubmit = () => {
    form.validateFields(['weight', 'score', 'comment']).then(() => {
      setLoadingSubmit(true)
      const submitData: DueDiligenceScoreCardUpdate[] = [
        {
          identifier: [fundId, selectedScorecard.category],
          weight: form.getFieldValue('weight'),
          score: form.getFieldValue('score'),
          comment: form.getFieldValue('comment'),
        },
      ]
      APIService.fundDetailService
        .updateDueDiligenceScoreCard(fundId, { updates: submitData })
        .then(() => {
          notification.success({ message: 'Score Card Updated!' })
          updateScorecard()
        })
        .catch((err) => {
          console.error({ err })
          notification.error({ message: 'Score Card Update Failed!' })
        })
        .finally(() => {
          setLoadingSubmit(false)
          setShowModal(false)
          setSelectedScorecard(undefined)
          form.resetFields()
        })
    })
  }
  return (
    <Modal
      title="Score Card Due Diligence"
      visible={showModal}
      okText={
        loadingSubmit ? (
          <>
            <Spin style={{ marginRight: 4 }} />
          </>
        ) : (
          'OK'
        )
      }
      onOk={() => handleSubmit()}
      onCancel={() => {
        setShowModal(false)
        setSelectedScorecard(undefined)
      }}
    >
      <div>
        <Form layout="vertical" form={form}>
          <Row gutter={16}>
            <Col span={12}>
              <FormItemInput
                name="weight"
                label="Weight"
                typeField="number"
                normalize="float"
                rules={[{ required: true, message: 'Weight is required' }]}
              />
            </Col>
            <Col span={12}>
              <FormItemInput
                name="score"
                label="Score"
                typeField="number"
                normalize="float"
                rules={[
                  { required: true, message: 'Score is required' },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value > 10 || value < 0) {
                        return Promise.reject('Score Max is 10 and Min is 0!')
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              />
            </Col>
            <Col span={24}>
              <FormItemTextArea
                name="comment"
                label="Comment"
                rules={[{ required: true, message: 'Comment is required' }]}
                row={4}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
