import { Tabs } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'
import { FundCCMCResponse } from '../../../../shared/api/models/FundDetails'
import CCMC from '../CCMC/CCMC'
import CMIC from '../CMIC/CMIC'

const { TabPane } = Tabs

const StyledTabs = styled(Tabs)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 05;
`

type Props = {
  data?: FundCCMCResponse[]
  form: FormInstance
}

const ExecutiveOrders: React.FC<Props> = ({ data, form }) => {
  return (
    <StyledTabs hideAdd type="editable-card" defaultActiveKey="cmic">
      <TabPane tab={'CMIC'} key="cmic" closable={false}>
        <CMIC form={form} data={data} />
      </TabPane>
      <TabPane tab={'CCMC'} key="ccmc" closable={false}>
        <CCMC form={form} data={data} />
      </TabPane>
    </StyledTabs>
  )
}

export default ExecutiveOrders
