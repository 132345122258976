import { ColumnProps } from 'antd/lib/table'
import {
  FirmDetails,
  FirmFundDescriptionRecord,
  FundDetailFirmContact,
  FundDetailsPreviewType,
} from '../../api/models/FundDetails'
import { AUMRecord, KeyValueRecord, RORRecord } from '../../api/models/ResponsesTypes'
import GenUtil from '../../utils/gen-util'
import RenderTableCell from './RenderTableCell'

interface FundStatsTableData {
  key: string
  category: string
  b1: number | undefined
  b2: number | undefined
  b3: number | undefined
  fund: number | undefined
  fund_si: number | undefined
}

interface RORAndAUMTableData {
  [key: string]: number | string | undefined
}

export default class FundDetailsUtils {
  /**
   *
   * @param b1
   * @param b2
   * @param b3
   * @param fund
   */
  static getFundStatsTableData = (
    b1: { [key: string]: number },
    b2: { [key: string]: number },
    b3: { [key: string]: number },
    fund: { [key: string]: number },
    fund_si: { [key: string]: number },
  ): {
    header: string
    tableData: FundStatsTableData[]
    columns: ColumnProps<FundStatsTableData>[]
  } => {
    const fundStatsTableData: FundStatsTableData[] = []
    const fundStatsTableOrderedKeys: Array<{ key: string; value: string }> = [
      { key: 'geo_avg_mo_ror', value: 'Geo Avg Mo ROR' },
      { key: 'std_dev', value: 'Std Dev' },
      { key: 'high_mo_ror', value: 'High Mo ROR' },
      { key: 'low_mo_ror', value: 'Low Mo ROR' },
      { key: 'ann_ror', value: 'Ann ROR' },
      { key: 'ann_stdev', value: 'Ann Stdev' },
      { key: 'risk_free_rate', value: 'Risk Free Rate' },
      { key: 'sharpe_ratio', value: 'Sharpe Ratio' },
      { key: 'winning_mo', value: 'Winning Mo (%)' },
      { key: 'max_drawdown', value: 'Max Drawdown' },
      { key: '1_month_ror', value: '1 Month ROR' },
      { key: '3_month_ror', value: '3 Month ROR' },
      { key: '6_month_ror', value: '6 Month ROR' },
      { key: '1_year_ann_ror', value: '1 Year Ann ROR' },
      { key: '2_year_ann_ror', value: '2 Year Ann ROR' },
      { key: '3_year_ann_ror', value: '3 Year Ann ROR' },
      { key: '5_year_ann_ror', value: '5 Year Ann ROR' },
      { key: '7_year_ann_ror', value: '7 Year Ann ROR' },
      { key: '10_year_ann_ror', value: '10 Year Ann ROR' },
      { key: 'since_inception_ann_ror', value: 'Since Inception Ann ROR' },
      { key: 'alpha', value: 'Alpha' },
      { key: 'beta', value: 'Beta' },
      { key: 'r_square', value: 'R-Squared' },
      { key: 'correlation', value: 'Correlation' },
      { key: 'up_alpha', value: 'Up Alpha' },
      { key: 'up_beta', value: 'Up Beta' },
      { key: 'up_r_square', value: 'Up R-Squared' },
      { key: 'down_alpha', value: 'Down Alpha' },
      { key: 'down_beta', value: 'Down Beta' },
      { key: 'down_r_square', value: 'Down R-Squared' },
    ]
    if (b1 && b2 && b3 && fund) {
      fundStatsTableOrderedKeys.forEach((tableCellObj: { key: string; value: string }) => {
        const { key, value } = tableCellObj
        fundStatsTableData.push({
          key: key,
          category: value,
          b1: b1[key],
          b2: b2[key],
          b3: b3[key],
          fund: fund[key],
          fund_si: fund_si ? fund_si[key] : undefined,
        })
      })
    }

    const fundStatsTableColumns: ColumnProps<FundStatsTableData>[] = [
      { title: 'Category', dataIndex: 'category', key: 'category' },
      {
        title: 'Fund *',
        dataIndex: 'fund',
        key: 'fund',
        align: 'right',
        render: RenderTableCell,
      },
      {
        title: 'B1 *',
        dataIndex: 'b1',
        key: 'b1',
        align: 'right',
        render: RenderTableCell,
      },
      {
        title: 'B2 *',
        dataIndex: 'b2',
        key: 'b2',
        align: 'right',
        render: RenderTableCell,
      },
      {
        title: 'B3 *',
        dataIndex: 'b3',
        key: 'b3',
        align: 'right',
        render: RenderTableCell,
      },
    ]

    return {
      header: 'Fund Stats',
      tableData: fundStatsTableData,
      columns: fund_si
        ? [
            ...fundStatsTableColumns.slice(0, 1),
            {
              title: 'Fund SI **',
              dataIndex: 'fund_si',
              key: 'fund_si',
              align: 'right',
              render: RenderTableCell,
            },
            ...fundStatsTableColumns.slice(1, fundStatsTableColumns.length),
          ]
        : fundStatsTableColumns,
    }
  }

  // takes two argument , one for months and other(optional) for 'total' column
  static getFormattedTableDataForRORAndAUM = (
    fetchedData: AUMRecord[] | RORRecord[],
    fetchedAnnualData: KeyValueRecord = {} as KeyValueRecord,
  ): Array<RORAndAUMTableData> => {
    const tableData: { [key: string]: KeyValueRecord } = {}
    fetchedData.forEach((ele: RORRecord | AUMRecord) => {
      if (tableData[ele.year]) {
        tableData[ele.year][ele.month] = GenUtil.digitWithMaxPrecision(ele.cur_value)
      } else {
        tableData[ele.year] = { [ele.month]: GenUtil.digitWithMaxPrecision(ele.cur_value) }
      }
    })

    const years = Object.keys(tableData).sort()
    return years.map((year) => {
      return {
        key: year,
        year,
        total: fetchedAnnualData[year],
        ...tableData[year],
      }
    })
  }

  /**
   * Returns a range of YYYY-MM between fromDate and toDate
   * @param fromDate
   * @param toDate
   */
  static getDatesRangeList = (fromDate: string, toDate: string): string[] => {
    const datesRangesArr: string[] = []
    const [fy, fm] = fromDate.split('-')
    const [ty, tm] = toDate.split('-')
    const [fromMonth, fromYear, toMonth, toYear] = [Number(fm), Number(fy), Number(tm), Number(ty)]
    for (let i = fromMonth; i <= 12; i++) {
      datesRangesArr.push(`${fromYear}-${i <= 9 ? '0' + i : i}`)
    }
    for (let y = fromYear + 1; y < toYear; y++) {
      for (let m = 1; m <= 12; m++) {
        datesRangesArr.push(`${y}-${m <= 9 ? '0' + m : m}`)
      }
    }
    for (let i = 1; i <= toMonth; i++) {
      datesRangesArr.push(`${toYear}-${i <= 9 ? '0' + i : i}`)
    }
    return datesRangesArr
  }
  static fillBooleanField = (value: boolean | undefined) => {
    if (value === undefined) {
      return '-'
    } else if (value) {
      return 'Yes'
    } else {
      return 'No'
    }
  }
  /**
   * Returns the data for Header-Columns in firm-profile page
   * @param firmData
   */
  static getFirmDescription = (firmData: FirmDetails & FundDetailFirmContact): Array<FirmFundDescriptionRecord[]> => {
    return [
      [
        {
          key: 'Firm ID',
          value: firmData?.firm_id || '-',
          show: true,
        },
        {
          key: 'Principals',
          value: firmData?.principals || '-',
          show: true,
        },
        {
          key: 'Firm Assets',
          value:
            firmData?.firm_assets_date && firmData?.firm_assets_currency && firmData?.firm_assets
              ? `${firmData.firm_assets_currency} ${GenUtil.getformattedNumber(
                  firmData.firm_assets,
                )} M (as of ${GenUtil.getFormattedYearAndMonth(firmData.firm_assets_date)})`
              : '-',
          show: true,
        },
        {
          key: 'IARD CRD Number',
          value: firmData?.iard_crd_number || '-',
          show: true,
        },
        {
          key: 'Sec Number',
          value: firmData?.sec_number || '-',
          show: true,
        },
        {
          key: 'Sec Registered',
          value: FundDetailsUtils.fillBooleanField(firmData?.sec_registered || undefined),
          show: true,
        },
        {
          key: 'Is Diversity Firm',
          value: FundDetailsUtils.fillBooleanField(firmData?.is_diversity_firm || undefined),
          show: true,
        },
      ],
      [
        {
          key: 'Address',
          value: [
            firmData?.address || '',
            firmData?.city || '',
            firmData?.state || '' + firmData?.postal_code || '',
            firmData?.country || '',
          ],
          show: true,
        },
        {
          key: 'Contact',
          value: firmData?.first_contact || '-',
          show: (firmData?.second_contact || '').trim() === '',
        },
        {
          key: 'First Contact',
          value: firmData?.first_contact || '-',
          show: (firmData?.second_contact || '').trim() !== '',
        },
        {
          key: 'Second Contact',
          value: firmData?.second_contact || '',
          show: (firmData?.second_contact || '').trim() !== '',
        },
        {
          key: 'Phone',
          value: firmData?.phone || '-',
          show: true,
        },
        {
          key: 'Email',
          value: firmData?.email || '-',
          show: true,
        },
        // {
        //   key: 'Fax',
        //   value: firmData?.facsimile || '-',
        //   show: true,
        // },
      ],
    ]
  }
  /**
   * Returns the data for Header-Columns in fund-profile page
   * @param fundData
   */
  static getFundDescription = (fundData: FundDetailsPreviewType): Array<FirmFundDescriptionRecord[]> => {
    return [
      [
        {
          key: 'Manager',
          value: fundData?.firm.firm_name || '-',
          show: true,
        },
        {
          key: 'Strategy',
          value: fundData?.strategy.strategy_name || '-',
          show: true,
        },
        {
          key: 'Sub-Strategy',
          value: fundData?.sub_strategy.sub_strategy_name || '-',
          show: true,
        },
        {
          key: 'Regional Focus',
          value: fundData?.regional_focus || '-',
          show: true,
        },
        {
          key: 'Inception',
          value: fundData?.fund_info.inception_date
            ? GenUtil.getFormattedYearMonthAndDay(fundData.fund_info.inception_date)
            : '-',
          show: true,
        },
        {
          key: 'Mgmt Fees %',
          value: fundData?.fund_fees.mgmt_fee || '-',
          show: true,
        },
        {
          key: 'Max 12B1 Fee %',
          value: fundData?.fund_fees.max_12b1_fee || '-',
          show: fundData?.fund_info.liquid_alt_product || false,
        },
        {
          key: 'Max Front Fee %',
          value: fundData?.fund_fees.max_front_fee || '-',
          show: fundData?.fund_info.liquid_alt_product || false,
        },
        {
          key: 'Max Deferred Fee %',
          value: fundData?.fund_fees.max_deferred_fee || '-',
          show: fundData?.fund_info.liquid_alt_product || false,
        },
        {
          key: 'Incentive Fees %',
          value: fundData?.fund_fees.incentive_fee || '-',
          show: !fundData?.fund_info.liquid_alt_product || false,
        },
        {
          key: 'UCITS Compliant',
          value: FundDetailsUtils.fillBooleanField(fundData?.ucits_compliant),
          show: true,
        },
        {
          key: 'Liquid Alt Product',
          value: FundDetailsUtils.fillBooleanField(fundData?.fund_info.liquid_alt_product),
          show: true,
        },
      ],
      [
        {
          key: 'Address',
          value: [
            fundData?.firm_contact.address || '',
            fundData?.firm_contact.city || '',
            fundData?.firm_contact.state || '' + ' ' + fundData?.firm_contact.postal_code || '',
            fundData?.firm_contact.country || '',
          ],
          show: true,
        },
        {
          key: 'Contact',
          value: fundData?.firm_contact.first_contact || '-',
          show: fundData?.firm_contact.second_contact?.trim() === '',
        },
        {
          key: 'First Contact',
          value: fundData?.firm_contact.first_contact || '-',
          show: fundData?.firm_contact.second_contact?.trim() !== '',
        },
        {
          key: 'Second Contact',
          value: fundData?.firm_contact.second_contact || '',
          show: fundData?.firm_contact.second_contact?.trim() !== '',
        },
        {
          key: 'Phone',
          value: fundData?.firm_contact.phone || '-',
          show: true,
        },
        {
          key: 'Email',
          value: fundData?.firm_contact?.email || '-',
          show: true,
        },
        // {
        //   key: 'Fax',
        //   value: fundData?.firm_contact.facsimile || '-',
        //   show: true,
        // },
        {
          key: 'High Watermark',
          value: FundDetailsUtils.fillBooleanField(fundData?.fund_fees.high_watermark),
          show: true,
        },
        {
          key: 'Hurdle Rate',
          value: fundData?.fund_fees.hurdle_rate || 'No',
          show: true,
        },
        {
          key: 'Leverage',
          value: fundData?.leverage || '-',
          show: true,
        },
      ],
      [
        {
          key: 'Product Class',
          value: fundData?.fund_info.product_class ? `Class ${fundData.fund_info.product_class}` : '-',
          show: true,
        },
        {
          key: 'Registration',
          value: fundData?.liquid_alt_region || '-',
          show: fundData?.fund_info.liquid_alt_product || false,
        },
        {
          key: 'Liquid Alt Type',
          value: fundData?.liquid_alt_type || '-',
          show: fundData?.fund_info.liquid_alt_product || false,
        },
        {
          key: 'Domicile',
          value: fundData?.domicile || '-',
          show: !fundData?.fund_info.liquid_alt_product || false,
        },
        {
          key: 'Structure',
          value: fundData?.structure || '-',
          show: !fundData?.fund_info.liquid_alt_product || false,
        },
        {
          key: 'Fund Assets',
          value:
            fundData.fund_assets_rep_date && fundData.fund_assets_currency && fundData.fund_assets
              ? `${fundData.fund_assets_currency} ${GenUtil.getformattedNumber(
                  fundData.fund_assets,
                )} M (as of ${GenUtil.getFormattedYearAndMonth(fundData.fund_assets_rep_date)})`
              : '-',
          show: true,
        },
        {
          key: 'Firm Assets',
          value:
            fundData?.firm.firm_assets_date && fundData?.firm.firm_assets_currency && fundData?.firm.firm_assets
              ? `${fundData.firm.firm_assets_currency} ${GenUtil.getformattedNumber(
                  fundData.firm.firm_assets,
                )} M (as of ${GenUtil.getFormattedYearAndMonth(fundData.firm.firm_assets_date)})`
              : '-',
          show: true,
        },
        {
          key: 'Min. Investment',
          value: fundData?.fund_info.minimum_investment
            ? `${fundData.min_investment_curr} ${GenUtil.getformattedNumber(fundData.fund_info.minimum_investment)}`
            : '-',
          show: true,
        },
        {
          key: 'Subscriptions',
          value: fundData?.subscriptions || '-',
          show: true,
        },
        {
          key: 'Redemptions',
          value: fundData?.redemptions || '-',
          show: true,
        },
        {
          key: 'Notice',
          value: fundData?.fund_info.advance_notice ? `${fundData.fund_info.advance_notice} Days` : '-',
          show: true,
        },
        {
          key: 'Lockup',
          value: fundData?.fund_info.lockup ? `${fundData.fund_info.lockup} Months` : 'No',
          show: true,
        },
      ],
    ]
  }
}
