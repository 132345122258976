import { Button, Col, DatePicker, Row, Select, Space } from 'antd'
import moment from 'moment'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../../../../app/rootReducer'
import APIService from '../../../../../shared/api'
import {
  FundDetailDataContext,
  PERFORMANCE_REQUEST_ENUM,
} from '../../../../../shared/api/context/FundDetailDataContext'
import DataSheet, { DataSheetType } from '../../../../../shared/components/DataSheet'
import { DividerForm } from '../../../../../shared/components/DividerForm'
import { FormItemCheckBox } from '../../../../../shared/components/FormItemComponents'
import { RenderLabelForm } from '../../../../../shared/components/RenderLabelForm'
import { useServiceState } from '../../../../../shared/hooks/useServiceState'
import { resetNav } from '../../../../../slice/appEventSlice'
import { updateNavDate } from '../../../../../slice/appSettingsSlice'
import { DATE_FORMAT } from '../../FundROR/PerformanceUpdate'
import HistoricalData from '../../HistoricalData'
import { ModalCalculateNAV } from './ModalCalculateNAV'

const Flex = styled.div`
  display: flex;
  flex: 1;
  padding: 0 1rem;
`

type Props = {
  setIsUpdateFund: (isUpdate: boolean) => void
}
export const NAVPerUnit: React.FC<Props> = ({ setIsUpdateFund }) => {
  const {
    dataNAV,
    dataNAVLoading,
    setNAVDataChange,
    navDataChange,
    getPerformanceRequest,
    dataFundDetail,
  } = React.useContext(FundDetailDataContext)

  const { fundNavUpdated } = useSelector((state: RootState) => state.appEvent)
  const dispatch = useDispatch()

  const [selectedHistorical, setSelectedHistorical] = React.useState<number>()
  const [selectedDate, setSelectedDate] = React.useState<string>()

  const { invoke: getLogEvent, data: dataLogEvent } = useServiceState(APIService.activityLogService.fetchDataEvent)

  React.useEffect(() => {
    getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.NAV)
  }, [getPerformanceRequest])

  // refetch events when flag `fundNavUpdated` is true, triggered by successfully update Fund ROR or Latest Performance
  React.useEffect(() => {
    if (!dataFundDetail?.fund_id) {
      return
    }
    if (fundNavUpdated) {
      getLogEvent({
        where: {
          code: 'UPDATE_FUND_NAVS',
          fund_id: { equals: dataFundDetail.fund_id },
        },
        limit: 100,
        page: 1,
        order_by: 'DESC',
        sort_by: 'created_date',
        show_json_data: true,
      })
      dispatch(resetNav())
    }
  }, [dataFundDetail?.fund_id, dispatch, fundNavUpdated, getLogEvent])

  React.useEffect(() => {
    if (!dataFundDetail?.fund_id) {
      return
    }
    getLogEvent({
      where: {
        code: 'UPDATE_FUND_NAVS',
        fund_id: { equals: dataFundDetail.fund_id },
      },
      limit: 100,
      page: 1,
      order_by: 'DESC',
      sort_by: 'created_date',
      show_json_data: true,
    })
  }, [dataFundDetail?.fund_id, getLogEvent])

  const { performanceNavDate } = useSelector((state: RootState) => state.appSettings)

  const [fromDate, setFromDate] = React.useState<string | undefined>(performanceNavDate?.navStartDate)
  const [toDate, setToDate] = React.useState<string | undefined>(performanceNavDate?.navToDate)
  const [isShowModal, setIsShowModal] = React.useState(false)

  React.useEffect(() => {
    if (performanceNavDate) {
      setFromDate(performanceNavDate.navStartDate)
      setToDate(performanceNavDate.navToDate)
    }
  }, [performanceNavDate])

  return (
    <Flex style={{ flexDirection: 'column' }}>
      <Row style={{ display: 'flex', flex: 1, marginBottom: '1rem', marginTop: '0.5rem' }}>
        <Col span={6}>
          <FormItemCheckBox name="is_nav_distributed" text={<RenderLabelForm value="Is NAV Distributed ?" />} />
        </Col>
        <Col span={18}>
          <Space size={18}>
            <Col style={{ display: 'inline-block' }}>
              From:{' '}
              <DatePicker
                value={fromDate ? moment(fromDate) : undefined}
                picker="month"
                onChange={(e) => {
                  if (e) {
                    setFromDate(moment(e).format('YYYY-MM'))
                  } else {
                    setFromDate(undefined)
                  }
                }}
              />
            </Col>
            <Col style={{ display: 'inline-block' }}>
              To:{' '}
              <DatePicker
                value={toDate ? moment(toDate) : undefined}
                picker="month"
                onChange={(e) => {
                  if (e) {
                    setToDate(moment(e).format('YYYY-MM'))
                  } else {
                    setToDate(undefined)
                  }
                }}
              />
            </Col>
            <Button
              type="primary"
              onClick={() => {
                fromDate && toDate && dispatch(updateNavDate({ fromDate: fromDate, toDate: toDate }))
                setIsShowModal(true)
              }}
              disabled={!fromDate || !toDate}
            >
              Calculate
            </Button>
          </Space>
        </Col>
        {fromDate && toDate && isShowModal && (
          <ModalCalculateNAV
            isOpen={isShowModal}
            onRequestClose={() => setIsShowModal(false)}
            fromDate={fromDate}
            toDate={toDate}
            setIsUpdateFund={setIsUpdateFund}
          />
        )}
      </Row>
      <Flex style={{ flexDirection: 'column' }}>
        <DividerForm text="NAV" notMargin />
      </Flex>
      <Row style={{ margin: '8px 0' }}>
        <Col span={8}>
          <Select
            style={{ width: '100%' }}
            placeholder="Select Historical Snapshot Data"
            value={selectedHistorical}
            onChange={(data) => {
              setSelectedHistorical(data ? +data : undefined)
            }}
            allowClear
          >
            {dataLogEvent?.events.map((event) => (
              <Select.Option key={event.id} value={event.id}>
                <b>{event.user__username}</b> - Updated at {event.created_date}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={2}>
          {selectedHistorical && (
            <Button type="link" onClick={() => setSelectedHistorical(undefined)}>
              Show current
            </Button>
          )}
        </Col>
      </Row>
      {selectedHistorical ? (
        <DataSheet
          currencyValue={dataFundDetail?.denomination ? dataFundDetail.denomination : undefined}
          type={DataSheetType.NAV}
          isReadOnly
          initialData={dataLogEvent?.events.find((item) => item.id === selectedHistorical)?.json_data.values}
        />
      ) : (
        <DataSheet
          currencyValue={dataFundDetail?.denomination ? dataFundDetail.denomination : undefined}
          minDate={
            dataFundDetail?.inception_date
              ? moment(dataFundDetail.inception_date).subtract(1, 'month').format(DATE_FORMAT)
              : undefined
          }
          type={DataSheetType.NAV}
          draft={navDataChange.values}
          initialData={dataNAV}
          canAdd
          loading={dataNAVLoading}
          setRORDataChange={setNAVDataChange}
          onCellSelect={setSelectedDate}
        />
      )}
      {dataFundDetail?.fund_id && (
        <Row>
          <Col span={24}>
            <HistoricalData
              fundId={dataFundDetail.fund_id}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              type={PERFORMANCE_REQUEST_ENUM.NAV}
            />
          </Col>
        </Row>
      )}
    </Flex>
  )
}
