import { notification } from 'antd'
import moment from 'moment'
import { PerformanceUpdateType } from '../../../shared/api/context/FundDetailDataContext'
import { LatestPerformanceResponse } from '../../../shared/api/services/fund.service'
import { getMonthRange } from '../../../shared/utils/datetimeUtils'
import { DATE_FORMAT } from '../../Dashboard/FundDetail/FundROR/PerformanceUpdate'

export const validateLatestPerformance = (
  performanceDataChange: PerformanceUpdateType | undefined,
  latestPerformance: LatestPerformanceResponse | undefined,
) => {
  if (performanceDataChange && latestPerformance && Object.keys(performanceDataChange).length > 0) {
    // get the latest updated date (actual updated one, not the latest data from backend)
    const rorChanges = performanceDataChange.rorChanges?.filter((item) => !item.isReadOnly)
    const assetChanges = performanceDataChange.assetChanges?.filter((item) => !item.isReadOnly)
    const navChanges = performanceDataChange.navChanges?.filter((item) => !item.isReadOnly)

    const latestRORData =
      rorChanges && rorChanges.length > 0
        ? moment(rorChanges[rorChanges.length - 1].yyyy_mm, DATE_FORMAT)
        : moment(new Date(1971))
    const latestNAVData =
      navChanges && navChanges.length > 0
        ? moment(navChanges[navChanges.length - 1].yyyy_mm, DATE_FORMAT)
        : moment(new Date(1971))
    const latestAssetData =
      assetChanges && assetChanges.length > 0
        ? moment(assetChanges[assetChanges.length - 1].yyyy_mm, DATE_FORMAT)
        : moment(new Date(1971))
    const latestUpdatedDate = moment.max([latestRORData, latestNAVData, latestAssetData])
    let isDataValid = true

    latestPerformance.latest_ror &&
      getMonthRange(
        moment(latestPerformance.latest_ror, DATE_FORMAT).add(1, 'month').format(DATE_FORMAT),
        latestUpdatedDate.format(DATE_FORMAT),
      ).forEach((month) => {
        if (!performanceDataChange.rorChanges?.some((item) => item.yyyy_mm === month)) {
          isDataValid = false
          return
        }
      })

    latestPerformance.latest_asset &&
      getMonthRange(
        moment(latestPerformance.latest_asset, DATE_FORMAT).add(1, 'month').format(DATE_FORMAT),
        latestUpdatedDate.format(DATE_FORMAT),
      ).forEach((month) => {
        if (!performanceDataChange.assetChanges?.some((item) => item.yyyy_mm === month)) {
          isDataValid = false
          return
        }
      })

    latestPerformance.latest_nav &&
      getMonthRange(
        moment(latestPerformance.latest_nav, DATE_FORMAT).add(1, 'month').format(DATE_FORMAT),
        latestUpdatedDate.format(DATE_FORMAT),
      ).forEach((month) => {
        if (!performanceDataChange.navChanges?.some((item) => item.yyyy_mm === month)) {
          isDataValid = false
          return
        }
      })

    if (!isDataValid) {
      notification.warn({ message: 'Please fill in the missing performance data!' })
      return false
    }
  }
  return true
}
