import { Col, Row, Table } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { ColumnProps } from 'antd/lib/table'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FundDetailDropdownContext } from '../../../../shared/api/context/FundDetailDropdownContext'
import { FundCCMCResponse } from '../../../../shared/api/models/FundDetails'
import {
  FormItemCheckBox,
  FormItemRadio,
  FormItemSelect,
  FormItemTextArea,
} from '../../../../shared/components/FormItemComponents'
import { RenderLabelFormFirm } from '../../../../shared/components/RenderLabelFormFirm'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem;

  th.ant-table-cell {
    vertical-align: top;
  }
  td.ant-table-cell {
    vertical-align: top;
  }
  .ant-form-item {
    margin-bottom: 0 !important;
  }
  .ant-radio-wrapper {
    white-space: normal;
  }
`

type Props = {
  data?: FundCCMCResponse[]
  form: FormInstance
}

const CMIC: React.FC<Props> = ({ data, form }) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)

  const hasSecurities = form.getFieldValue('has_securities_14032')

  useEffect(() => {
    if (hasSecurities === 2) {
      form.setFieldsValue({
        separate_share_classes_14032: undefined,
        pre_trade_compliance_restrictions_14032: undefined,
        other_measures_14032: undefined,
      })
    }
    if (hasSecurities !== 1) {
      form.setFieldsValue({ manager_confirms_14032: undefined })
    }
  }, [form, hasSecurities])

  const columns: ColumnProps<any>[] = [
    {
      title: (
        <div>
          1(a). Does the fund currently hold any investments whether directly or indirectly (e.g.,{' '}
          <i>publicly traded stocks, bonds, derivatives, warrants, ADRs, GDRs, ETFs, index funds and mutual funds</i>)
          related to the securities identified in{' '}
          <span style={{ textDecoration: 'underline', fontWeight: 600 }}>E.O. 14032</span>?
        </div>
      ),
      key: 'has_securities_14032',
      render: () => {
        return (
          <FormItemRadio
            name="has_securities_14032"
            options={[
              { value: 1, title: 'Yes' },
              { value: 0, title: 'No' },
              { value: 2, title: 'Does Not Apply to Fund' },
            ]}
          />
        )
      },
      width: '30%',
    },
    {
      title: (
        <div>
          1(b). <span style={{ textDecoration: 'underline', color: 'red' }}>If Yes to 1(a)</span>, confirm your plan to
          divest of these assets by the U.S. deadline of{' '}
          <span style={{ textDecoration: 'underline', fontWeight: 600 }}>June 23, 2022</span>, and that you will not add
          to such positions in the meantime.
        </div>
      ),
      key: 'manager_confirms_14032',
      render: () => {
        return (
          <FormItemRadio
            disabled={form.getFieldValue('has_securities_14032') !== 1}
            name="manager_confirms_14032"
            options={[
              { value: true, title: 'Manager Confirms' },
              { value: false, title: 'Manager Does Not Confirm' },
            ]}
          />
        )
      },
      width: '20%',
    },
    {
      title: (
        <>
          <p>
            2. Identify or describe any measures you have or will be implementing to prevent the intentional or
            inadvertent purchase of investments related to the prohibited securities on an ongoing basis.
          </p>
          <i>
            Below is a link to the complete list of prohibited securities available on the U.S. Department of Treasury
            website:
          </i>
          <p>
            <span>PDF: </span>
            <a
              href="https://www.treasury.gov/ofac/downloads/ccmc/nscmiclist.pdf"
              download="nscmiclist.pdf"
              target="_blank"
              rel="noreferrer"
            >
              https://www.treasury.gov/ofac/downloads/ccmc/nscmiclist.pdf
            </a>
          </p>
          <p>
            <span>TXT: </span>
            <a
              href="https://www.treasury.gov/ofac/downloads/ccmc/nscmiclist.txt"
              download="nscmiclist.txt"
              target="_blank"
              rel="noreferrer"
            >
              https://www.treasury.gov/ofac/downloads/ccmc/nscmiclist.txt
            </a>
          </p>
        </>
      ),
      render: () => {
        return (
          <>
            <Row>
              <Col span={24}>
                <FormItemCheckBox
                  disable={form.getFieldValue('has_securities_14032') === 2}
                  name="separate_share_classes_14032"
                  text={<RenderLabelFormFirm value="Separate Share Classes" />}
                />
                <FormItemCheckBox
                  disable={form.getFieldValue('has_securities_14032') === 2}
                  name="pre_trade_compliance_restrictions_14032"
                  text={<RenderLabelFormFirm value="Pre-trade Compliance Restrictions" />}
                />
                <FormItemTextArea
                  disable={form.getFieldValue('has_securities_14032') === 2}
                  isVertical
                  row={3}
                  name="other_measures_14032"
                  label="Other (Describe)"
                />
              </Col>
            </Row>
          </>
        )
      },
    },
    {
      title: '3. Subscription Advanced Notice (# days)',
      render: () => {
        return (
          <FormItemSelect
            name="subscription_advance_notice"
            allowClear={false}
            options={
              fundDataDropdown?.subscription_advance_notice && fundDataDropdown?.subscription_advance_notice.length > 0
                ? fundDataDropdown?.subscription_advance_notice.map((item) => ({
                    value: item.code_code,
                    text: item.code_description,
                  }))
                : []
            }
          />
        )
      },
    },
  ]

  return (
    <Wrapper>
      <Table
        rowKey="fund_id"
        size="small"
        columns={columns}
        dataSource={
          data && data.length > 0
            ? data
            : [
                {
                  fund_id: '',
                  fund_name: '',
                  has_securities_14032: null,
                  manager_confirms_14032: null,
                  separate_share_classes_14032: null,
                  pre_trade_compliance_restrictions_14032: null,
                  other_measures_14032: null,
                  subscription_notice: '',
                },
              ]
        }
        pagination={{ hideOnSinglePage: true }}
      />
    </Wrapper>
  )
}

export default CMIC
