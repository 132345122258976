import { Col, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import { FundDetailDropdownContext } from '../../../../../shared/api/context/FundDetailDropdownContext'
import {
  FormItemAutoComplete,
  FormItemCheckBox,
  FormItemDatePicker,
  FormItemSelect,
} from '../../../../../shared/components/FormItemComponents'
import { RenderLabelForm } from '../../../../../shared/components/RenderLabelForm'
import { FundDetailDataContext } from '../../../../../shared/api/context/FundDetailDataContext'

type Props = {
  form: FormInstance
}

const OPTION_USE_OF_LEVERAGE = [
  {
    value: 'Yes',
    text: 'Yes',
  },
  {
    value: 'No',
    text: 'No',
  },
]
const Structure: React.FC<Props> = ({ form }) => {
  const { userOptions } = React.useContext(FundDetailDataContext)
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)
  const liquidAltProduct = form.getFieldValue('liquid_alt_product')
  const SELECT_GROUP = React.useMemo(() => {
    return [
      {
        name: 'domicile',
        label: 'Domicile',
        isAutoComplete: true,
        options:
          fundDataDropdown && fundDataDropdown.domicile && fundDataDropdown.domicile.length > 0
            ? fundDataDropdown.domicile.map((item) => ({ value: item, text: item }))
            : [],
      },
      {
        name: 'min_invest',
        label: 'Min. Investment',
        options:
          fundDataDropdown && fundDataDropdown.min_invest && fundDataDropdown.min_invest.length > 0
            ? fundDataDropdown.min_invest.map((item) => ({ value: item, text: item }))
            : [],
        isAutoComplete: true,
        // normalize: 'float',
      },
      {
        name: 'min_invest_indv',
        label: 'Ind. Account Min.',
        options:
          fundDataDropdown && fundDataDropdown.min_invest_indv && fundDataDropdown.min_invest_indv.length > 0
            ? fundDataDropdown.min_invest_indv.map((item) => ({ value: item, text: item }))
            : [],
        isAutoComplete: true,
        // normalize: 'float',
      },
      {
        name: 'structure',
        label: 'Structure',
        options:
          fundDataDropdown && fundDataDropdown.structure && fundDataDropdown.structure.length > 0
            ? fundDataDropdown.structure.map((item) => ({ value: item, text: item }))
            : [],
        maxLength: 30,
        isAutoComplete: true,
        isKeepOptions: true,
      },
      {
        name: 'add_invest',
        label: 'Add Investment',
        options:
          fundDataDropdown && fundDataDropdown.add_invest && fundDataDropdown.add_invest.length > 0
            ? fundDataDropdown.add_invest.map((item) => ({ value: item, text: item }))
            : [],
        isAutoComplete: true,
        // normalize: 'float',
      },
      {
        name: 'accepts_mgd_accts',
        label: 'Accepts Mng. Accts?',
        options:
          fundDataDropdown && fundDataDropdown.accepts_mgd_accts && fundDataDropdown.accepts_mgd_accts.length > 0
            ? fundDataDropdown.accepts_mgd_accts.map((item) => ({ value: item.code_code, text: item.code_description }))
            : [],
        allowClear: true,
      },
      {
        name: 'registration_code',
        label: 'Registration',
        options:
          fundDataDropdown && fundDataDropdown.registration_code && fundDataDropdown.registration_code.length > 0
            ? fundDataDropdown.registration_code.map((item) => ({ value: item, text: item }))
            : [],
        allowClear: true,
      },
      {
        name: 'woman_managed',
        label: 'Woman Managed',
        options:
          fundDataDropdown && fundDataDropdown.woman_managed && fundDataDropdown.woman_managed.length > 0
            ? fundDataDropdown.woman_managed.map((item) => ({ value: item.code_code, text: item.code_description }))
            : [],
        allowClear: true,
      },
      {
        name: 'run_sep_mgd_accts',
        label: 'Run Sep Mng Accts?',
        options:
          fundDataDropdown && fundDataDropdown.run_sep_mgd_accts && fundDataDropdown.run_sep_mgd_accts.length > 0
            ? fundDataDropdown.run_sep_mgd_accts.map((item) => ({ value: item.code_code, text: item.code_description }))
            : [],
        allowClear: true,
      },
      {
        name: 'ucitsiii',
        label: 'UCITSIII',
        options:
          fundDataDropdown && fundDataDropdown.ucitsiii && fundDataDropdown.ucitsiii.length > 0
            ? fundDataDropdown.ucitsiii.map((item) => ({ value: item, text: item }))
            : [],
        allowClear: true,
      },
      {
        name: 'use_of_leverage',
        label: 'Use of Leverage',
        maxLength: 30,
        options: OPTION_USE_OF_LEVERAGE,
        isAutoComplete: true,
      },
      {
        name: 'use_of_leverage_type',
        label: 'Use of Leverage Type',
        options:
          fundDataDropdown && fundDataDropdown.use_of_leverage_type && fundDataDropdown.use_of_leverage_type.length > 0
            ? fundDataDropdown.use_of_leverage_type.map((item) => ({
                value: item.code_code,
                text: item.code_description,
              }))
            : [],
        allowClear: true,
      },
      {
        name: 'liquid_alt_product',
        label: 'Liquid Alt Product',
        isCheckbox: true,
      },
      {
        name: 'liquid_alt_type',
        label: 'Liquid Alt Type',
        disabled: !liquidAltProduct,
        allowClear: true,
        options:
          fundDataDropdown && fundDataDropdown.liquid_alt_type && fundDataDropdown.liquid_alt_type.length > 0
            ? fundDataDropdown.liquid_alt_type.map((item) => ({ value: item.code_code, text: item.code_description }))
            : [],
      },
      {
        name: 'liquid_alt_region',
        label: 'Liquid Alt Regions',
        disabled: !liquidAltProduct,
        allowClear: true,
        options:
          fundDataDropdown && fundDataDropdown.liquid_alt_region && fundDataDropdown.liquid_alt_region.length > 0
            ? fundDataDropdown.liquid_alt_region.map((item) => ({ value: item.code_code, text: item.code_description }))
            : [],
      },
      {
        label: 'Long Only Product',
        name: 'long_only_product',
        isCheckbox: true,
      },

      {
        name: 'aifmd_compliant',
        label: 'AIFMD',
        options:
          fundDataDropdown && fundDataDropdown.aifmd_compliant && fundDataDropdown.aifmd_compliant.length > 0
            ? fundDataDropdown.aifmd_compliant.map((item) => ({
                value: item.code_code,
                text: item.code_description,
              }))
            : [],
        allowClear: true,
      },
      {
        name: 'multi_manager_platform',
        label: 'Multi-Manager Platform',
        options:
          fundDataDropdown &&
          fundDataDropdown.sma_weeklyliquid_10mm &&
          fundDataDropdown.sma_weeklyliquid_10mm.length > 0
            ? fundDataDropdown.sma_weeklyliquid_10mm.map((item) => ({
                value: item.code_code,
                text: item.code_description,
              }))
            : [],
        allowClear: true,
      },
      {
        name: 'sma_weeklyliquid_10mm',
        label: 'SMA Weekly $10MM',
        options:
          fundDataDropdown &&
          fundDataDropdown.sma_weeklyliquid_10mm &&
          fundDataDropdown.sma_weeklyliquid_10mm.length > 0
            ? fundDataDropdown.sma_weeklyliquid_10mm.map((item) => ({
                value: item.code_code,
                text: item.code_description,
              }))
            : [],
        allowClear: true,
      },
      {
        name: 'sma_monthlyliquid_20mm',
        label: 'SMA Monthly $20MM',
        options:
          fundDataDropdown &&
          fundDataDropdown.sma_monthlyliquid_20mm &&
          fundDataDropdown.sma_monthlyliquid_20mm.length > 0
            ? fundDataDropdown.sma_monthlyliquid_20mm.map((item) => ({
                value: item.code_code,
                text: item.code_description,
              }))
            : [],
        allowClear: true,
      },
      {
        name: 'multi_manager_platform_reviewed_date',
        label: 'MM Platform Reviewed Date',
        isDate: true,
        allowClear: true,
      },
      {
        name: 'blank',
        label: 'black',
        isEmpty: true,
      },
      {
        name: 'sma_monthlyliquid_50mm',
        label: 'SMA Monthly $50MM',
        options:
          fundDataDropdown &&
          fundDataDropdown.sma_monthlyliquid_50mm &&
          fundDataDropdown.sma_monthlyliquid_50mm.length > 0
            ? fundDataDropdown.sma_monthlyliquid_50mm.map((item) => ({
                value: item.code_code,
                text: item.code_description,
              }))
            : [],
        allowClear: true,
      },
      {
        name: 'multi_manager_platform_reviewed_by',
        label: 'MM Platform Reviewed By',
        options: userOptions || [],
      },
    ]
  }, [fundDataDropdown, liquidAltProduct, userOptions])
  return (
    <Row>
      {SELECT_GROUP.map((item) => {
        if (item.name === 'blank_cell') {
          return <Col span={14} offset={10} key={item.name} />
        }
        return (
          <Col span={8} xl={8} md={12} xs={24} key={item.name} style={{ marginBottom: '0.5rem' }}>
            {item.isEmpty ? (
              <Col span={14} offset={10}></Col>
            ) : item.isCheckbox ? (
              <Row>
                <Col span={14} offset={10}>
                  <FormItemCheckBox name={item.name} text={<RenderLabelForm value={item.label} />} />
                </Col>
              </Row>
            ) : item.isAutoComplete ? (
              <FormItemAutoComplete
                name={item.name}
                options={item.options}
                label={<RenderLabelForm value={item.label} />}
                labelCol={10}
                wrapperCol={14}
                isKeepOptions={item.isKeepOptions}
              />
            ) : item.isDate ? (
              <FormItemDatePicker
                picker="date"
                name={item.name}
                label={<RenderLabelForm value={item.label} />}
                labelCol={10}
                wrapperCol={14}
                allowClear={item.allowClear}
              />
            ) : (
              <FormItemSelect
                name={item.name}
                label={<RenderLabelForm value={item.label} />}
                allowClear={item.allowClear}
                labelCol={10}
                wrapperCol={14}
                options={item.options ? item.options : []}
                disabled={item.disabled}
              />
            )}
          </Col>
        )
      })}
    </Row>
  )
}
export default Structure
