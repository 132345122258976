import { Col, Row } from 'antd'
import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { FundDetailDataContext } from '../../../../../shared/api/context/FundDetailDataContext'
import { FundDetailDropdownContext } from '../../../../../shared/api/context/FundDetailDropdownContext'
import { Colors } from '../../../../../shared/colors'
import { DividerForm } from '../../../../../shared/components/DividerForm'
import {
  FormItemCheckBox,
  FormItemDatePicker,
  FormItemInput,
  FormItemSelect,
} from '../../../../../shared/components/FormItemComponents'
import { RenderLabelForm } from '../../../../../shared/components/RenderLabelForm'
import { LeftNavFirmEnum } from '../../../../../shared/SearchFirmLabel'
import { LeftNavFundEnum } from '../../../../../shared/SearchFundLabel'
import { addTabs, FundFirmTypeEnum } from '../../../../../slice/appSettingsSlice'

const Flex = styled.div`
  display: flex;
  flex: 1;
`

const CHECK_BOX_GROUP = [
  {
    name: 'is_offshore',
    text: 'Is Offshore ?',
  },
  {
    name: 'is_feeder_fund',
    text: 'Feeder Fund ?',
  },
  {
    name: 'is_hfr_funded',
    text: 'Is HFR Fund ?',
  },
  {
    name: 'is_minority_fund',
    text: 'Is Minority Managed?',
  },
]
type Props = {}
const Information: React.FC = () => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)
  const dispatch = useDispatch()
  const { dataFundDetail } = useContext(FundDetailDataContext)
  const handleFundAssetClick = () => {
    if (dataFundDetail) {
      dispatch(
        addTabs({
          name: dataFundDetail.fund_name,
          id: dataFundDetail.fund_id,
          type: FundFirmTypeEnum.FUNDS,
          path: LeftNavFundEnum.PERFORMANCE,
        }),
      )
    }
  }

  const handleFirmAssetClick = () => {
    if (dataFundDetail) {
      dispatch(
        addTabs({
          name: dataFundDetail.firm_name,
          id: dataFundDetail.firm_id,
          type: FundFirmTypeEnum.FIRMS_IN_FUNDS,
          path: LeftNavFirmEnum.FIRM_ASSETS,
        }),
      )
    }
  }

  return (
    <Flex style={{ flexDirection: 'column', padding: '0 1rem' }}>
      <Row>
        <Col span={8} xl={8} md={12} xs={24} style={{ marginBottom: '0.5rem' }}>
          <FormItemInput
            name="fund_assets"
            label={
              <RenderLabelForm
                style={{ color: Colors.primary, textDecoration: 'underline', cursor: 'pointer' }}
                onClick={handleFundAssetClick}
                value="Fund Assets"
              />
            }
            prefix={dataFundDetail?.denomination}
            suffix="MM"
            labelCol={12}
            wrapperCol={12}
            disabled
          />
        </Col>
        <Col span={8} xl={8} md={12} xs={24} style={{ marginBottom: '0.5rem' }}>
          <FormItemDatePicker
            picker="month"
            name="fund_assets_date"
            label={<RenderLabelForm value="Fund Asset Date" />}
            labelCol={12}
            wrapperCol={12}
            disabled
          />
        </Col>
        <Col span={8} xl={8} md={12} xs={24} style={{ marginBottom: '0.5rem' }}>
          <FormItemDatePicker
            name="om_date"
            label={<RenderLabelForm value="OM Date" />}
            labelCol={12}
            wrapperCol={12}
            allowClear
          />
        </Col>
        <Col span={8} xl={8} md={12} xs={24}>
          <FormItemInput
            name="firm_assets"
            label={
              <RenderLabelForm
                onClick={handleFirmAssetClick}
                style={{ color: Colors.primary, textDecoration: 'underline', cursor: 'pointer' }}
                value="Firm Assets"
              />
            }
            prefix={dataFundDetail?.firm_denomination}
            suffix="MM"
            labelCol={12}
            wrapperCol={12}
            disabled
          />
        </Col>
        <Col span={8} xl={8} md={12} xs={24}>
          <FormItemDatePicker
            picker="month"
            name="firm_assets_date"
            label={<RenderLabelForm value="Firm Asset Date" />}
            labelCol={12}
            wrapperCol={12}
            disabled
          />
        </Col>
        <Col span={8} xl={8} md={12} xs={24}>
          <FormItemDatePicker
            name="inception_date"
            label={<RenderLabelForm value="Inception Date" />}
            labelCol={12}
            wrapperCol={12}
            allowClear
          />
        </Col>
      </Row>
      <DividerForm />
      <Flex style={{ flexDirection: 'column' }}>
        <Row style={{ paddingBottom: '1rem' }}>
          {CHECK_BOX_GROUP.map((item) => {
            return (
              <Col span={4} xl={4} md={12} sm={24} xs={24} key={item.name}>
                <FormItemCheckBox name={item.name} text={<RenderLabelForm value={item.text} />} />
              </Col>
            )
          })}
        </Row>

        <Row>
          <Col span={12} md={12} xs={24} style={{ marginBottom: '0.5rem' }}>
            <FormItemSelect
              name="contact_status"
              label={<RenderLabelForm value="Contact Status" />}
              labelCol={12}
              wrapperCol={12}
              options={
                fundDataDropdown?.contact_status && fundDataDropdown.contact_status.length > 0
                  ? fundDataDropdown.contact_status.map((item) => ({
                      value: item.code_code,
                      text: item.code_description,
                    }))
                  : []
              }
              allowClear
            />
          </Col>
          <Col span={12} md={12} xs={24} style={{ marginBottom: '0.5rem' }}>
            <FormItemSelect
              name="minority_manage_type"
              label={<RenderLabelForm value="Minority Manage Type" />}
              labelCol={12}
              wrapperCol={12}
              options={
                fundDataDropdown?.minority_manage_type && fundDataDropdown.minority_manage_type.length > 0
                  ? fundDataDropdown.minority_manage_type.map((item) => ({
                      value: item.code_code,
                      text: item.code_description,
                    }))
                  : []
              }
              allowClear
            />
          </Col>
        </Row>
        <Row>
          <Col span={12} md={12} xs={24}>
            <FormItemSelect
              name="liquidity"
              label={<RenderLabelForm value="Liquidity" />}
              labelCol={12}
              wrapperCol={12}
              options={
                fundDataDropdown?.liquidity && fundDataDropdown.liquidity.length > 0
                  ? fundDataDropdown.liquidity.map((item) => ({
                      value: item.code_code,
                      text: item.code_description,
                    }))
                  : []
              }
              allowClear
            />
          </Col>
          <Col span={12} md={12} xs={24}>
            <FormItemSelect
              name="minority_manage_level"
              label={<RenderLabelForm value="Minority Manage Level" />}
              labelCol={12}
              wrapperCol={12}
              options={
                fundDataDropdown?.minority_manage_level && fundDataDropdown.minority_manage_level.length > 0
                  ? fundDataDropdown.minority_manage_level.map((item) => ({
                      value: item.code_code,
                      text: item.code_description,
                    }))
                  : []
              }
              allowClear
            />
          </Col>
        </Row>
      </Flex>
    </Flex>
  )
}
export default Information
