import React from 'react'
import styled from 'styled-components'
import { Result, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import { FallbackProps } from 'react-error-boundary'

const Wrapper = styled('div')`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

type Props = {}

const FallbackUI: React.FC<Props & FallbackProps> = ({ resetErrorBoundary }) => {
  const history = useHistory()
  return (
    <Wrapper>
      <Result
        status="error"
        title="Something went wrong!"
        subTitle="We are currently trying to fix the problem. In the meantime, you can:"
        extra={
          <>
            <Button
              onClick={() => {
                // though you could accomplish this with a combination
                // of the FallbackCallback and onReset props as well.
                resetErrorBoundary()
              }}
            >
              Try again
            </Button>
            <Button type="primary" onClick={() => history.push('/')}>
              Back Home
            </Button>
          </>
        }
      />
    </Wrapper>
  )
}

export default FallbackUI
