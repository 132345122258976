import React from 'react'
import styled from 'styled-components'
import { FormItemTextArea } from '../../../../shared/components/FormItemComponents'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  margin: 1rem 3rem;
`

export const HFRArchiveNotes: React.FC = () => {
  return (
    <Wrapper>
      <FormItemTextArea name="market_commentary" row={7} />
    </Wrapper>
  )
}
