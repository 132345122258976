import { InfoCircleTwoTone } from '@ant-design/icons'
import { Button, Col, Collapse, Modal, notification, Row } from 'antd'
import React, { CSSProperties, useEffect, useMemo } from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableLocation,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../../app/rootReducer'
import { ExFundDetails } from '../../../shared/api/models/ExFundDetails'
import { Colors } from '../../../shared/colors'
import { updateExFundColumns, updateFundsOfFirmColumns } from '../../../slice/appSettingsSlice'
import { FundSearchColumnContext } from '../../Dashboard/DashboardTab/ColumnBuilder/FundSearchColumnContext'
import { allColumns, fundFamilyColumns, ColumnPropsExtended, ColumnSource, defaultColumns } from './columnSource'

const InfoText = styled.span`
  margin-left: 10px;
`

const Wrapper = styled.div`
  max-height: 90vh;
`

enum DroppableIds {
  TargetColumns = 'targetColumns',
  SourceColumns = 'sourceColumns',
}

const { Panel } = Collapse

const grid = 8

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  borderRadius: '5px',
  padding: grid,
  minHeight: '100%',
})

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
): CSSProperties => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,

  // change background colour if dragging
  background: isDragging ? Colors.primary : 'white',
  border: `1px solid ${Colors.primary}`,
  borderRadius: '5px',
  marginBottom: '3px',

  // styles we need to apply on draggables
  ...draggableStyle,
})

const reorder = (list: ColumnPropsExtended[] | undefined, startIndex: number, endIndex: number) => {
  if (!list || !list.length) {
    return []
  }
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const remove = (target: ColumnPropsExtended[], startIndex: number) => {
  const results = Array.from(target)
  results.splice(startIndex, 1)
  return results
}

const move = (
  source: ColumnSource[],
  destination: unknown[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation,
) => {
  const sourceClone = source.reduce<ColumnPropsExtended[]>((s, i) => (i.columns ? [...s, ...i.columns] : s), [])

  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index - 1, 1)
  destClone.splice(droppableDestination.index, 0, removed)

  return {
    [droppableSource.droppableId]: sourceClone,
    [droppableDestination.droppableId]: destClone,
  }
}

interface HeaderGroupProps {
  groupName: string
  onAddAll: () => void
}

const Flex = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const HeaderGroup: React.FC<HeaderGroupProps> = ({ groupName, onAddAll }) => {
  return (
    <Flex>
      <span>{groupName}</span>
      <Button type="link" size="small" onClick={onAddAll}>
        Add all
      </Button>
    </Flex>
  )
}

interface Props {
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
  isInternal?: boolean
}

const ColumnBuilder: React.FC<Props> = ({ isOpen, setOpen, isInternal = false }) => {
  const [targetColumns, setTargetColumns] = React.useState<ColumnPropsExtended[]>(defaultColumns)

  const { exFundTableColumns: fundTableColumns, fundsOfFirmColumns } = useSelector(
    (state: RootState) => state.appSettings,
  )
  const dispatch = useDispatch()
  const { dataAllColumns } = React.useContext(FundSearchColumnContext)

  const finalAllColumns: ColumnSource[] = useMemo(() => {
    if (!isInternal) {
      return allColumns
    }
    const indexFamilyColumns: ColumnPropsExtended[] =
      dataAllColumns && Object.keys(dataAllColumns).length > 0
        ? Object.keys(dataAllColumns)
            .filter((key) => key.includes('index_family_'))
            .map((indexFamilyKey) => ({
              title: dataAllColumns[indexFamilyKey].human_readable_name,
              dataIndex: indexFamilyKey,
              key: indexFamilyKey as keyof ExFundDetails,
              sorter: true,
            }))
        : []

    return [
      ...allColumns,
      {
        groupName: 'HFR Index Family',
        columns: indexFamilyColumns,
      },
      ...fundFamilyColumns,
    ]
  }, [dataAllColumns, isInternal])

  useEffect(() => {
    const flattenAllColumns = finalAllColumns.flatMap((item) => item.columns)

    const storedColumns = isInternal ? fundsOfFirmColumns : fundTableColumns

    const fundColumns = storedColumns
      ? storedColumns.reduce((acc, currKey) => {
          const foundColumn = flattenAllColumns.find((item) => item.key === currKey)
          if (foundColumn) {
            return [...acc, foundColumn]
          }
          return acc
        }, [] as ColumnPropsExtended[])
      : defaultColumns
    if (fundColumns.length > 0) {
      setTargetColumns(fundColumns)
    }
  }, [dataAllColumns, finalAllColumns, fundTableColumns, fundsOfFirmColumns, isInternal])

  const sourceColumns = useMemo(() => {
    return finalAllColumns.map((i) => ({
      ...i,
      columns: i.columns.filter((c) => !targetColumns.some((t) => t.key === c.key)),
    }))
  }, [finalAllColumns, targetColumns])

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    if (source.droppableId === DroppableIds.TargetColumns && destination.droppableId !== DroppableIds.TargetColumns) {
      const result = remove(targetColumns, source.index)

      setTargetColumns(result)
      return
    }

    if (source.droppableId === DroppableIds.SourceColumns && destination.droppableId === DroppableIds.TargetColumns) {
      const result = move(sourceColumns, targetColumns, source, destination)

      setTargetColumns(result[DroppableIds.TargetColumns] as ColumnPropsExtended[])
      return
    }

    if (source.droppableId === DroppableIds.TargetColumns && destination.droppableId === DroppableIds.TargetColumns) {
      const result = reorder(targetColumns, source.index, destination.index)

      setTargetColumns(result)
      return
    }
  }

  const uniqueColumns = (columns: ColumnPropsExtended[]) =>
    Array.from(new Set(columns.map((item) => item.key))).map((key) => {
      return columns.find((item) => item.key === key) as ColumnPropsExtended
    })

  const handleAddGroupColumns = (columns: ColumnPropsExtended[]) => {
    setTargetColumns(uniqueColumns([...targetColumns, ...columns]))
  }

  const handleSubmit = () => {
    isInternal
      ? dispatch(updateFundsOfFirmColumns(targetColumns.map((item) => item.key)))
      : dispatch(updateExFundColumns(targetColumns.map((item) => item.key)))
    notification.success({ message: 'Updated columns successfully!', placement: 'bottomLeft' })
    setOpen(false)
  }

  const handleReset = () => {
    setTargetColumns(defaultColumns)
  }

  const handleAddAll = () => {
    const flatenColumns = allColumns.flatMap((item) => item.columns)
    setTargetColumns(uniqueColumns(flatenColumns))
  }

  return (
    <Modal
      title="Update Columns"
      visible={isOpen}
      width={600}
      okText="Submit"
      onOk={handleSubmit}
      bodyStyle={{ overflow: 'auto', maxHeight: 600 }}
      onCancel={() => setOpen(false)}
    >
      <Wrapper>
        <DragDropContext onDragEnd={onDragEnd}>
          <Row>
            <InfoCircleTwoTone />
            <InfoText>
              Drag from <b>All Columns</b> to <b>Displayed Columns</b> to add.
            </InfoText>
          </Row>
          <Row>
            <InfoCircleTwoTone />
            <InfoText>
              Drag from <b>Displayed Columns</b> to <b>All Columns</b> to remove.
            </InfoText>
          </Row>
          <Row>
            <InfoCircleTwoTone />
            <InfoText>
              If it&apos;s no item in <b>Displayed Columns</b>, the default one will be used.
            </InfoText>
          </Row>
          <Row style={{ marginTop: 20 }} gutter={16}>
            <Col span={12}>
              <h3 style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Displayed Columns</span>
                <Button type="link" size="small" onClick={handleReset}>
                  Reset
                </Button>
              </h3>
              <Droppable droppableId={DroppableIds.TargetColumns}>
                {({ innerRef, placeholder }, snapshot) => {
                  return (
                    <div ref={innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                      {targetColumns &&
                        targetColumns.map((targetCol, index) => (
                          <Draggable key={targetCol.key} draggableId={`target_${targetCol.key}`} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                              >
                                {targetCol.title}
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {placeholder}
                    </div>
                  )
                }}
              </Droppable>
            </Col>
            <Col span={12}>
              <h3 style={{ display: 'flex', justifyContent: 'space-between' }}>
                All Columns
                <Button type="link" size="small" onClick={handleAddAll}>
                  Add all
                </Button>
              </h3>
              <Droppable droppableId={DroppableIds.SourceColumns}>
                {({ innerRef, placeholder }, snapshot) => {
                  let draggableIndex = 0
                  return (
                    <div ref={innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                      <Collapse bordered={false} defaultActiveKey={['0']}>
                        {sourceColumns.map((group, groupIndex) => {
                          return (
                            <Panel
                              header={
                                <HeaderGroup
                                  groupName={group.groupName}
                                  onAddAll={() => handleAddGroupColumns(group.columns)}
                                />
                              }
                              key={`${groupIndex}`}
                            >
                              {group.columns &&
                                group.columns.map((col) => {
                                  draggableIndex++
                                  return (
                                    <Draggable key={col.key} draggableId={`source_${col.key}`} index={draggableIndex}>
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.dragHandleProps}
                                          {...provided.draggableProps}
                                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                        >
                                          {col.title}
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                })}
                              {placeholder}
                            </Panel>
                          )
                        })}
                      </Collapse>
                    </div>
                  )
                }}
              </Droppable>
            </Col>
          </Row>
        </DragDropContext>
      </Wrapper>
    </Modal>
  )
}

export default ColumnBuilder
