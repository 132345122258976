import { Tabs } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'
import { FundCCMCResponse } from '../../../../shared/api/models/FundDetails'
import FirmCCMC from '../CCMC/FirmCCMC'
import FirmCMIC from '../CMIC/FirmCMIC'

const { TabPane } = Tabs

const StyledTabs = styled(Tabs)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 05;
`

type Props = {
  initialCCMCData?: FundCCMCResponse[]
  initialCMICData?: FundCCMCResponse[]
  ccmcData?: FundCCMCResponse[]
  cmicData?: FundCCMCResponse[]
  form: FormInstance
}

const FirmExecutiveOrders: React.FC<Props> = ({ ccmcData, cmicData, initialCMICData, initialCCMCData, form }) => {
  return (
    <StyledTabs hideAdd type="editable-card" defaultActiveKey="cmic">
      <TabPane tab={'CMIC'} key="cmic" closable={false}>
        <FirmCMIC form={form} data={cmicData} initialData={initialCMICData} />
      </TabPane>
      <TabPane tab={'CCMC'} key="ccmc" closable={false}>
        <FirmCCMC form={form} data={ccmcData} initialData={initialCCMCData} />
      </TabPane>
    </StyledTabs>
  )
}

export default FirmExecutiveOrders
