import { Spin, Tabs } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { RootState } from '../../app/rootReducer'
import { ExNewFundProvider } from '../../shared/api/context/ExNewFundContext'
import { FirmDetailDataProvider } from '../../shared/api/context/FirmDetailDataContext'
import { FundDetailDataProvider } from '../../shared/api/context/FundDetailDataContext'
import { FundDetailDropdownProvider } from '../../shared/api/context/FundDetailDropdownContext'
import { Colors } from '../../shared/colors'
import ExternalMainLayout from '../../shared/components/ExternalMainLayout'
import { DraftManagerProvider } from '../../shared/DraftManager'
import { history } from '../../shared/history'
import { LeftNavExFirmEnum } from '../../shared/SearchExFirmLabel'
import { LeftNavExFundEnum } from '../../shared/SearchExFundLabel'
import {
  addTabs,
  EX_OVERVIEW_KEY,
  FundFirmTypeEnum,
  PREFIX_EXTERNAL,
  removeTabs,
  updateActiveTabs,
} from '../../slice/appSettingsSlice'
import ExDashboardTab from './ExDashboardTab'
import { ExFundDetailMain, EXFUND_DATE_FIELDS } from './ExFundDetailMain'
import { ExNewFundMain } from './ExNewFund/ExNewFundMain'
import MyFirm, { EXFIRM_DATE_FIELDS } from './MyFirm'

const StyledTabs = styled(Tabs)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;

  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs-content-holder {
    overflow: hidden;
  }

  .ant-tabs-content {
    flex-grow: 1;
    background: white;
    border-left: solid 1px ${Colors.border};
    border-right: solid 1px ${Colors.border};
    border-bottom: solid 1px ${Colors.border};
    height: 100%;

    .ant-tabs-tabpane-active {
      height: 100%;
      display: flex;
    }

    .ant-card-head {
      min-height: auto;
    }
  }
`

type Props = {}

const ExDashboard: React.FC<RouteComponentProps<{
  fundId: string
  firmId: string
  fundEsg?: string
  firmEsg?: string
}>> = ({ match }) => {
  const dispatch = useDispatch()
  const { exTabs } = useSelector((state: RootState) => state.appSettings)
  const { params, path } = match
  const { search } = useLocation()

  const [shouldUpdate, setShouldUpdate] = React.useState(false)
  const [updatedFundPerformance, setUpdatedFundPerformance] = React.useState<string>()

  React.useEffect(() => {
    // effect to resolve tabs from url
    const fundIdFromUrl = params.fundId
    const firmIdFromUrl = params.firmId
    const activeNav = params.fundEsg
    const activeFirmNav = params.firmEsg

    const query = new URLSearchParams(search)
    const isPreview = query.get('preview') === '1'
    const previewTab = query.get('preview_tab') || undefined

    const selectActiveNav = (value: string | undefined, type: FundFirmTypeEnum) => {
      if (type === FundFirmTypeEnum.FUNDS) {
        if (value === 'esg') {
          return LeftNavExFundEnum.FUND_ESG_FACTORS
        }
        if (value === 'fund-family') {
          return LeftNavExFundEnum.FUND_FAMILY
        }
        return LeftNavExFundEnum.FUND_OVERVIEW
      }

      if (type === FundFirmTypeEnum.FIRMS_IN_FUNDS) {
        if (value === 'esg') {
          return LeftNavExFirmEnum.FIRM_ESG_POLICIES
        }
        if (value === 'co-investment') {
          return LeftNavExFirmEnum.CO_INVESTMENT
        }
        if (value === 'firm-ccmc') {
          return LeftNavExFirmEnum.FIRM_CMIC
        }
        if (value === 'firm-diversity-inclusion') {
          return LeftNavExFirmEnum.FIRM_DIVERSITY_INCLUSION
        }
        if (value === 'firm-multi-manager') {
          return LeftNavExFirmEnum.FIRM_MULTI_MANAGER_POD_PLATFORM
        }
        if (value === 'fund-family') {
          return LeftNavExFirmEnum.FUND_FAMILY
        }
        return LeftNavExFirmEnum.ASSETS_UNDER_MANAGEMENT
      }
    }

    if (fundIdFromUrl) {
      dispatch(
        addTabs({
          id: `${PREFIX_EXTERNAL}-${fundIdFromUrl}`,
          type: FundFirmTypeEnum.FUNDS,
          activeNav: selectActiveNav(activeNav, FundFirmTypeEnum.FUNDS),
          isPreview,
          previewTab,
        }),
      )
      history.push('/')
    }
    if (firmIdFromUrl) {
      dispatch(
        addTabs({
          id: `${PREFIX_EXTERNAL}-${firmIdFromUrl}`,
          type: FundFirmTypeEnum.FIRMS_IN_FUNDS,
          activeNav: selectActiveNav(activeFirmNav, FundFirmTypeEnum.FIRMS_IN_FUNDS),
        }),
      )
      history.push('/')
    }
  }, [dispatch, params.fundEsg, params.firmEsg, params.firmId, params.fundId, path, search])

  return (
    <ExternalMainLayout>
      <DraftManagerProvider usage="external" dateFields={[...EXFUND_DATE_FIELDS, ...EXFIRM_DATE_FIELDS]}>
        <FundDetailDropdownProvider isExternal>
          <StyledTabs
            hideAdd
            type="editable-card"
            onChange={(key) => {
              if (key === EX_OVERVIEW_KEY) {
                dispatch(updateActiveTabs(EX_OVERVIEW_KEY))
              }
              dispatch(updateActiveTabs(key))
            }}
            activeKey={exTabs.activeTab}
            onEdit={(targetKey, action) => {
              if (action === 'remove') {
                dispatch(removeTabs(targetKey as string))
              }
            }}
          >
            <Tabs.TabPane tab="Overview" key={EX_OVERVIEW_KEY} closable={false}>
              <ExDashboardTab
                setShouldUpdate={setShouldUpdate}
                shouldUpdate={shouldUpdate}
                setUpdatedFundPerformance={(updatedFund) => setUpdatedFundPerformance(updatedFund)}
              />
            </Tabs.TabPane>
            {exTabs.tabList &&
              exTabs.tabList.map((tab) => (
                <Tabs.TabPane
                  tab={
                    tab.type === FundFirmTypeEnum.FUNDS ? (
                      tab.name || <Spin />
                    ) : tab.name ? (
                      <RenderTitleTabPane name={tab.name} />
                    ) : (
                      <Spin />
                    )
                  }
                  key={tab.id}
                  closable={true}
                >
                  {tab.type === FundFirmTypeEnum.FIRMS_IN_FUNDS || tab.type === FundFirmTypeEnum.FIRMS ? (
                    <FirmDetailDataProvider id={tab.id.split('-')[1]}>
                      <MyFirm setShouldUpdate={setShouldUpdate} selectedTab={tab.activeNav} />
                    </FirmDetailDataProvider>
                  ) : tab.id.split('-')[1] === 'new' ? (
                    <ExNewFundProvider>
                      <ExNewFundMain setShouldUpdate={setShouldUpdate} />
                    </ExNewFundProvider>
                  ) : (
                    <FundDetailDataProvider
                      id={tab.id.split('-')[1]}
                      type={'external'}
                      shouldUpdate={tab.id.split('-')[1] === updatedFundPerformance}
                      onClearUpdatedPerformance={() => setUpdatedFundPerformance(undefined)}
                    >
                      <ExFundDetailMain
                        isPreview={tab.isPreview}
                        previewTab={tab.previewTab}
                        setShouldUpdate={setShouldUpdate}
                        selectedTab={tab.activeNav}
                      />
                    </FundDetailDataProvider>
                  )}
                </Tabs.TabPane>
              ))}
          </StyledTabs>
        </FundDetailDropdownProvider>
      </DraftManagerProvider>
    </ExternalMainLayout>
  )
}

export default ExDashboard

type TitleProps = {
  name: string
}
const RenderTitleTabPane: React.FC<TitleProps> = ({ name }) => {
  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          background: '#1890FF',
          color: '#fff',
          padding: '0 4px',
          marginRight: 2,
          borderRadius: 3,
          fontSize: 12,
          alignSelf: 'center',
        }}
      >
        M
      </div>
      <div>{name}</div>
    </div>
  )
}
