import { Modal, notification, Radio, Spin } from 'antd'
import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import APIService from '../../shared/api'
import { Colors } from '../../shared/colors'
import { useServiceState } from '../../shared/hooks/useServiceState'
type Props = {
  showModal: boolean
  setShowModal: (value: boolean) => void
  refreshUser: () => void
  firmId: string
  firmName: string
  onCancel: () => void
}
export const ModalCodeOfConduct: React.FC<Props> = ({
  showModal,
  setShowModal,
  refreshUser,
  firmId,
  firmName,
  onCancel,
}) => {
  const [signCheck, setSignCheck] = React.useState()
  const [loadingSubmit, setLoadingSubmit] = React.useState(false)
  const { invoke: getCodeOfConduct, data: dataCodeOfConduct } = useServiceState(
    APIService.codeOfConductService.getCodeOfConduct,
  )

  React.useEffect(() => {
    getCodeOfConduct()
  }, [getCodeOfConduct])

  const handleSubmit = () => {
    if (!dataCodeOfConduct?.version) {
      setShowModal(false)
      return
    }

    setLoadingSubmit(true)
    APIService.codeOfConductService
      .updateCodeOfConduct(firmId, dataCodeOfConduct.version, signCheck)
      .then(() => {
        notification.success({ message: 'Code of Conduct Updated!' })
        refreshUser()
        setLoadingSubmit(false)
        onCancel()
      })
      .catch(() => {
        notification.error({ message: 'Failed to sign Code of Conduct!' })
        setLoadingSubmit(false)
        onCancel()
      })
  }
  return (
    <Modal
      title={`Code Of Conduct - ${firmName}`}
      visible={showModal}
      okText={loadingSubmit ? <Spin /> : 'Submit'}
      onOk={handleSubmit}
      onCancel={onCancel}
      width="80vw"
    >
      <div style={{ padding: '0 1rem', height: 600, overflow: 'auto' }}>
        {dataCodeOfConduct ? (
          <div>
            <div>{ReactHtmlParser(dataCodeOfConduct.text)}</div>
            <div>Version: {dataCodeOfConduct.version}</div>
          </div>
        ) : (
          <Spin />
        )}
        <div style={{ marginTop: '1rem', fontWeight: 'bold', background: Colors.border, padding: '1rem' }}>
          <div style={{ marginTop: 4 }}>
            If you do not agree, your products not be eligible for future HFR Index inclusion.
          </div>
          <Radio.Group onChange={(e) => setSignCheck(e.target.value)} value={signCheck}>
            <Radio value={true}>I Agree</Radio>
            <Radio value={false}>I Do Not Agree</Radio>
          </Radio.Group>
        </div>
      </div>
    </Modal>
  )
}
