import { Tabs } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { FundDetailLabelSearchContext } from '../../../../shared/api/context/FundDetailLabelSearchContext'
import { LeftNavFundEnum, RightTabFundEnum } from '../../../../shared/SearchFundLabel'
import { Activities } from './Activities'
import { Checklist } from './Checklist/Checklist'
import { DueDiligenceContacts } from './Contact/DueDiligenceContacts'
import { DueDiligenceDocuments } from './DueDiligenceDocuments'
import { InvestOpinion } from './InvestOpinion'
import { Meetings } from './Meetings'
import { Monthly } from './Monthly'
import { ScoreCard } from './ScoreCard'
import { SepAcct } from './SepAcct/SepAcct'
const { TabPane } = Tabs

const StyledTabs = styled(Tabs)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`

const LIST_TAB_PANE = [
  {
    id: RightTabFundEnum.DOCUMENTS,
  },
  {
    id: RightTabFundEnum.ACTIVITIES,
  },
  {
    id: RightTabFundEnum.SEP_ACCT,
  },
  {
    id: RightTabFundEnum.CHECKLIST,
  },
  {
    id: RightTabFundEnum.INVEST_OPINIONS,
  },
  {
    id: RightTabFundEnum.SCORE_CARD,
  },
  {
    id: RightTabFundEnum.CONTACTS,
  },
  {
    id: RightTabFundEnum.MEETINGS,
  },
  {
    id: RightTabFundEnum.MONTHLY,
  },
]
type Props = {}
export const DueDiligenceMain: React.FC<Props> = () => {
  const { isFiltering, filterArr } = React.useContext(FundDetailLabelSearchContext)

  const listSearch = React.useMemo(() => {
    return filterArr.filter((item) => item.leftNav === LeftNavFundEnum.DUE_DILIGENCE)
  }, [filterArr])

  const renderTab = (id: RightTabFundEnum) => {
    switch (id) {
      case RightTabFundEnum.DOCUMENTS:
        return <DueDiligenceDocuments />
      case RightTabFundEnum.ACTIVITIES:
        return <Activities />
      case RightTabFundEnum.SEP_ACCT:
        return <SepAcct />
      case RightTabFundEnum.CHECKLIST:
        return <Checklist />
      case RightTabFundEnum.INVEST_OPINIONS:
        return <InvestOpinion />
      case RightTabFundEnum.SCORE_CARD:
        return <ScoreCard />
      case RightTabFundEnum.CONTACTS:
        return <DueDiligenceContacts />
      case RightTabFundEnum.MEETINGS:
        return <Meetings />
      case RightTabFundEnum.MONTHLY:
        return <Monthly />
      default:
        break
    }
  }
  return (
    <StyledTabs hideAdd type="editable-card" defaultActiveKey="1">
      {LIST_TAB_PANE.map((item) => {
        const showTabPane = listSearch.findIndex((i) => i.rightTab && i.rightTab === item.id)
        if (showTabPane === -1 && isFiltering) {
          return null
        }
        return (
          <TabPane tab={item.id} key={item.id} closable={false}>
            {renderTab(item.id)}
          </TabPane>
        )
      })}
    </StyledTabs>
  )
}
