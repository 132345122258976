import { PlusOutlined } from '@ant-design/icons'
import { Button, Card } from 'antd'
import React, { DragEventHandler, TouchEventHandler, useRef } from 'react'
import styled from 'styled-components'
import { AnalysisGroupListProvider } from '../../shared/api/context/AnalysisGroupListContext'
import { FundDetailDropdownProvider } from '../../shared/api/context/FundDetailDropdownContext'
import { AnalysisType } from '../../shared/api/models/Analysis'
import MainLayout from '../../shared/components/MainLayout'
import AddGroupModal from './AddGroupModal'
import FundList from './FundList'
import GroupList from './GroupList'

const Wrapper = styled('div')`
  width: 100%;
  background: #fff;
  padding: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex: 1;
  position: relative;

  .ant-table-content {
    min-height: calc(100vh - 280px);
  }
`

const StyledCard = styled(Card)`
  .ant-card-head {
    border-bottom: none;
    font-size: 1rem;
    padding: 0 1rem;
  }

  .ant-card-body {
    padding: 1rem 0 0 0;
  }
`

const LeftContainer = styled.div`
  overflow: hidden;
  display: flex;
`
const RightContainer = styled.div`
  overflow: hidden;
  flex: 1;
  display: flex;
`

const Handler = styled.div`
  width: 5px;
  background: #ccc;
  height: 80px;
  align-self: center;
  border-radius: 10px;
  margin: 0 4px;
  &:hover {
    background: #bbb;
  }
`

const ResizeMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #1890ff02;
  padding: 16px;
  display: flex;
  flex-direction: row;
`
const MaskLeft = styled.div`
  border: dashed 1px #91d5ff;
  background: #91d5ff55;
  flex: 0;
  margin-right: 18px;
`
const MaskRight = styled.div`
  flex: 1;
  background: #91d5ff55;
  border: dashed 1px #91d5ff;
`

type Props = {}
const Analysis: React.FC<Props> = () => {
  const [selectedGroup, setSelectedGroup] = React.useState<AnalysisType>()
  const [isShowAddGroup, setIsShowAddGroup] = React.useState(false)
  const [width, setWidth] = React.useState(500)
  const [startX, setStartX] = React.useState(0)
  const [dragging, setDragging] = React.useState(false)
  const lastX = useRef(0)
  const resizeMaskRef = useRef<HTMLDivElement>(null)

  const handleDragStart: DragEventHandler<HTMLDivElement> = (event) => {
    setStartX(event.clientX)
    setDragging(true)
    if (resizeMaskRef.current) {
      resizeMaskRef.current.style.minWidth = `${width}px`
    }
    return false
  }

  const handleDrag: DragEventHandler<HTMLDivElement> = (event) => {
    if (resizeMaskRef.current && dragging) {
      resizeMaskRef.current.style.minWidth = `${width + event.clientX - startX}px`
    }
  }
  const handleDragEnd: DragEventHandler<HTMLDivElement> = (event) => {
    if (dragging) {
      setWidth(width + event.clientX - startX)
      setDragging(false)
      return true
    }
  }
  const handleTouchStart: TouchEventHandler = (event) => {
    setStartX(event.touches[0].clientX)
    setDragging(true)
    if (resizeMaskRef.current) {
      resizeMaskRef.current.style.minWidth = `${width}px`
      lastX.current = event.touches[0].clientX
    }
    return false
  }
  const handleTouchEnd: TouchEventHandler<HTMLDivElement> = (event) => {
    if (dragging) {
      setWidth(width + lastX.current - startX)
      setDragging(false)
    }
  }
  const handleTouchDrag: TouchEventHandler<HTMLDivElement> = (event) => {
    if (resizeMaskRef.current && dragging) {
      resizeMaskRef.current.style.minWidth = `${width + event.touches[0].clientX - startX}px`
      lastX.current = event.touches[0].clientX
    }
  }

  return (
    <MainLayout>
      <FundDetailDropdownProvider>
        <AnalysisGroupListProvider>
          <Wrapper
            onTouchMove={handleTouchDrag}
            onTouchEnd={handleTouchEnd}
            onMouseUp={handleDragEnd}
            onMouseMove={handleDrag}
          >
            <LeftContainer style={{ width: width }}>
              <StyledCard
                size="small"
                title="Group List"
                style={{
                  overflow: 'hidden',
                  display: dragging ? 'none' : 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
                bodyStyle={{ flex: 1, overflow: 'hidden' }}
                extra={
                  <Button type="primary" onClick={() => setIsShowAddGroup(true)}>
                    <PlusOutlined />
                    Add Group
                  </Button>
                }
              >
                <GroupList selectedGroup={selectedGroup} onSelectGroup={setSelectedGroup} />
                <AddGroupModal isOpen={isShowAddGroup} onRequestClose={() => setIsShowAddGroup(false)} />
              </StyledCard>
            </LeftContainer>
            <Handler
              style={{ opacity: dragging ? 0 : 100 }}
              onMouseDown={handleDragStart}
              onTouchStart={handleTouchStart}
            />
            <RightContainer style={{ display: dragging ? 'none' : 'flex' }}>
              <FundList selectedGroup={selectedGroup} onSelectGroup={setSelectedGroup} />
            </RightContainer>
            <ResizeMask style={{ display: dragging ? 'flex' : 'none' }}>
              <MaskLeft ref={resizeMaskRef} />
              <MaskRight />
            </ResizeMask>
          </Wrapper>
        </AnalysisGroupListProvider>
      </FundDetailDropdownProvider>
    </MainLayout>
  )
}

export default Analysis
