import { Col, Form, notification, Row, Spin } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import { useDispatch } from 'react-redux'
import APIService from '../../../shared/api'
import { FundDetailDropdownContext } from '../../../shared/api/context/FundDetailDropdownContext'
import { ExFirmDetails } from '../../../shared/api/models/FirmDetails'
import { FormItemInput, FormItemSelect } from '../../../shared/components/FormItemComponents'
import { addTabs, FundFirmTypeEnum } from '../../../slice/appSettingsSlice'

type Props = {
  showModal: boolean
  setShowModal: (value: boolean) => void
  typeCreate: FundFirmTypeEnum
}
export const ModalCreateFundFirm: React.FC<Props> = ({ showModal, setShowModal, typeCreate }) => {
  const { dataFirms } = React.useContext(FundDetailDropdownContext)
  const [optionFirm, setOptionFirm] = React.useState<ExFirmDetails[] | undefined>()

  const compareFirm = (a: ExFirmDetails, b: ExFirmDetails) => {
    return a.firm_name && b.firm_name ? a.firm_name.localeCompare(b.firm_name) : 0
  }

  const sortedFirms = dataFirms ? dataFirms.sort(compareFirm) : undefined

  const filterOptions = React.useCallback(
    (val: string) => {
      if (!sortedFirms) {
        return
      }
      if (!val) {
        return sortedFirms.slice(0, 20)
      }
      return sortedFirms
        .filter((item) => item.firm_name && item.firm_name.toLowerCase().includes(val.toLowerCase()))
        .slice(0, 20)
    },
    [sortedFirms],
  )

  React.useEffect(() => {
    setOptionFirm(filterOptions(''))
  }, [filterOptions])

  function onSearch(val: string) {
    setOptionFirm(filterOptions(val))
  }

  const [loadingSubmit, setLoadingSubmit] = React.useState(false)
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const submitCreateFundFirm = () => {
    form.validateFields(['fundName', 'firmName']).then(() => {
      setLoadingSubmit(true)
      const fundName = form.getFieldValue('fundName')
      const firmName = form.getFieldValue('firmName')
      if (typeCreate === FundFirmTypeEnum.FUNDS) {
        return APIService.fundDetailService
          .createFundBasicInfo({ fund_name: fundName, firm_id: firmName })
          .then((res) => {
            notification.success({ message: 'Fund Created!' })
            dispatch(addTabs({ id: res.data.fund_id, type: typeCreate }))
          })
          .catch((err) => {
            console.error({ err })
            notification.error({ message: 'Create Failed!' })
          })
          .finally(() => {
            setLoadingSubmit(false)
            setShowModal(false)
          })
      }
      return APIService.firmDetailService
        .createFirmInfo({ firm_name: firmName })
        .then((res) => {
          notification.success({ message: 'Firm Created!' })
          dispatch(addTabs({ id: res.data.firm_id, type: typeCreate }))
        })
        .catch((err) => {
          console.error({ err })
          notification.error({ message: 'Create Failed!' })
        })
        .finally(() => {
          setLoadingSubmit(false)
          setShowModal(false)
        })
    })
  }

  return (
    <Modal
      visible={showModal}
      onCancel={() => {
        form.resetFields()
        setShowModal(false)
      }}
      title={typeCreate === FundFirmTypeEnum.FUNDS ? 'Create Fund' : 'Create Firm'}
      okText={
        loadingSubmit ? (
          <>
            <Spin style={{ marginRight: 4 }} />
          </>
        ) : (
          'OK'
        )
      }
      onOk={submitCreateFundFirm}
    >
      <div>
        <Form layout="vertical" form={form}>
          <Row>
            {typeCreate === FundFirmTypeEnum.FUNDS && (
              <Col span={24}>
                <FormItemInput
                  name="fundName"
                  label="Fund Name"
                  placeholder="Fund Name"
                  rules={[{ required: true, message: 'Please input your Fund Name!' }]}
                  maxLength={100}
                />
              </Col>
            )}
            <Col span={24}>
              {typeCreate === FundFirmTypeEnum.FUNDS ? (
                <FormItemSelect
                  name="firmName"
                  label="Firm"
                  placeholder="Firm Name"
                  rules={[{ required: true, message: 'Please select your Firm Name!' }]}
                  options={
                    (optionFirm &&
                      optionFirm.map((item) => ({
                        value: item.firm_id,
                        text: item.firm_name,
                      }))) ||
                    []
                  }
                  selectProps={{
                    onSearch,
                  }}
                />
              ) : (
                <FormItemInput
                  name="firmName"
                  label="Firm Name"
                  placeholder="Firm Name"
                  rules={[{ required: true, message: 'Please input your Firm Name!' }]}
                  maxLength={100}
                />
              )}
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
