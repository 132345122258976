import { AxiosInstance, AxiosPromise } from 'axios'
import { FundDetailRegionalFocus, StrategyResponse } from '../models/FundDetails'
import { BenchmarkRecord, CodeRecord, FundListRecord, ValueRecord } from '../models/ResponsesTypes'

export type AppDataService = {
  // funds-api
  fetchStrategies: () => AxiosPromise<StrategyResponse>
  fetchRegionalFocus: () => AxiosPromise<FundDetailRegionalFocus[]>
  fetchLiquidAltTypes: () => AxiosPromise<CodeRecord[]>
  fetchLiquidAltRegions: () => AxiosPromise<CodeRecord[]>
  fetchMax12B1Fees: () => AxiosPromise<ValueRecord[]>
  fetchMaxFrontFees: () => AxiosPromise<ValueRecord[]>
  fetchMaxDeferredFees: () => AxiosPromise<ValueRecord[]>
  fetchProductClasses: () => AxiosPromise<CodeRecord[]>
  fetchBenchmarks: () => AxiosPromise<BenchmarkRecord[]>
  fetchAdvanceNotices: () => AxiosPromise<string[]>
  fetchFundCurrencyCodes: () => AxiosPromise<string[]>
  fetchReportings: () => AxiosPromise<string[]>
  fetchReportingStyles: () => AxiosPromise<string[]>
  fetchRedemptions: () => AxiosPromise<string[]>
  fetchSubscriptions: () => AxiosPromise<string[]>
  fetchFirmAssetCurrencies: () => AxiosPromise<string[]>
  // end funds-api

  // hfr indexes
  fetchStaticIndexes: () => AxiosPromise<FundListRecord[]>
  fetchDynamicIndexes: () => AxiosPromise<FundListRecord[]>
}

export const AppDataService = (request: AxiosInstance): AppDataService => {
  return {
    // funds-api
    fetchStrategies: () => request.get('/manager-link/strategies'),
    fetchRegionalFocus: () => request.get('/funds-api/data/regional-focus'),
    fetchLiquidAltTypes: () => request.get('/funds-api/data/liquid-alt-types'),
    fetchLiquidAltRegions: () => request.get('/funds-api/data/liquid-alt-regions'),
    fetchMax12B1Fees: () => request.get('/funds-api/data/max-12b1-fees'),
    fetchMaxDeferredFees: () => request.get('/funds-api/data/max-deferred-fees'),
    fetchMaxFrontFees: () => request.get('/funds-api/data/max-front-fees'),
    fetchProductClasses: () => request.get('/funds-api/data/product-classes'),
    fetchBenchmarks: () => request.get('/funds-api/data/available-benchmarks'),
    fetchAdvanceNotices: () => request.get('/funds-api/data/advance-notice-days'),
    fetchFundCurrencyCodes: () => request.get('/funds-api/data/fund-currency-codes'),
    fetchReportings: () => request.get('/funds-api/data/reporting'),
    fetchReportingStyles: () => request.get('/funds-api/data/reporting-style'),
    fetchRedemptions: () => request.get('/funds-api/data/redemptions'),
    fetchSubscriptions: () => request.get('/funds-api/data/subscriptions'),
    fetchFirmAssetCurrencies: () => request.get('/funds-api/data/firm-currency-codes'),

    // hfr-indexes
    fetchStaticIndexes: () => request.get('/index/static'),
    fetchDynamicIndexes: () => request.get('/index/dynamic'),
  }
}
