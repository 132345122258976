import { Col, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'
import { DividerForm } from '../../../../../shared/components/DividerForm'
import {
  FormItemAutoComplete,
  FormItemCheckBox,
  FormItemInput,
} from '../../../../../shared/components/FormItemComponents'
import { RenderLabelForm } from '../../../../../shared/components/RenderLabelForm'
const Flex = styled.div`
  display: flex;
  flex: 1;
`
const OPTION_MGMT = [
  {
    text: '1',
    value: '1',
  },
  {
    text: '1.5',
    value: '1.5',
  },
  {
    text: '2',
    value: '2',
  },
]
const OPTION_INCENTIVE_FEE = [
  {
    text: '10',
    value: '10',
  },
  {
    text: '15',
    value: '15',
  },
  {
    text: '20',
    value: '20',
  },
]
const FORM_INPUT_GROUP_FOOTER = [
  {
    name: 'max_12b1_fee',
    text: 'Max 12B-1 Fee %',

    maxLength: 30,
  },
  {
    name: 'max_front_fee',
    text: 'Max Front-End Load Fee %',

    maxLength: 30,
  },
  {
    name: 'max_deferred_fee',
    text: 'Max Deferred Sale Load Fee %',

    maxLength: 30,
  },
]
const FORM_LEFT_GROUP = [
  { name: 'mgmt_fee', text: 'Mgmt. Fee %', options: OPTION_MGMT, maxLength: 30 },
  {
    name: 'administrative_fee',
    text: 'Admin. Fee %',
    maxLength: 30,
  },
  {
    name: 'redemption_fee',
    text: 'Rdm. Fee %',
    maxLength: 30,
  },
  {
    name: 'other_fees',
    text: 'Other Fee %',
    maxLength: 30,
  },
]
const FORM_MIDDLE_GROUP = [
  { name: 'incentive_fee', text: 'Incentive Fee %', options: OPTION_INCENTIVE_FEE, maxLength: 30 },
  {
    name: 'sales_fee',
    text: 'Sales Fee %',
    maxLength: 30,
  },
  { name: 'redemption_fee_months', text: 'If Less Than', normalize: 'number', suffix: 'months' },
]

const LIST_FIELD_EDITED_LIQUID_CHECK = ['administrative_fee', 'incentive_fee', 'sales_fee']
type Props = {
  form: FormInstance
}

const Fees: React.FC<Props> = ({ form }) => {
  const [isCheckHurdleRate, setIsCheckHurdleRate] = React.useState<boolean>(
    form.getFieldValue('is_hurdle_rate') || false,
  )

  const isLiquidCheck = form.getFieldValue('liquid_alt_product') as boolean

  return (
    <Flex style={{ padding: '0 1rem', flexDirection: 'column' }}>
      <Row>
        <Col span={8} xl={8} md={12} xs={24}>
          {FORM_LEFT_GROUP.map((item) => {
            return item.options ? (
              <FormItemAutoComplete
                key={item.name}
                label={<RenderLabelForm value={item.text} />}
                name={item.name}
                labelCol={10}
                wrapperCol={12}
                options={item.options}
                disable={LIST_FIELD_EDITED_LIQUID_CHECK.includes(item.name) && isLiquidCheck}
                rules={
                  item.maxLength
                    ? [
                        {
                          max: item.maxLength,
                          message: `Value should be less than ${item.maxLength} characters`,
                        },
                      ]
                    : undefined
                }
              />
            ) : (
              <FormItemInput
                key={item.name}
                label={<RenderLabelForm value={item.text} />}
                name={item.name}
                labelCol={10}
                wrapperCol={12}
                maxLength={item.maxLength ? item.maxLength : undefined}
                disabled={LIST_FIELD_EDITED_LIQUID_CHECK.includes(item.name) && isLiquidCheck}
              />
            )
          })}
        </Col>
        <Col span={8} xl={8} md={12} xs={24}>
          {FORM_MIDDLE_GROUP.map((item) => {
            return item.options ? (
              <FormItemAutoComplete
                key={item.name}
                label={<RenderLabelForm value={item.text} />}
                name={item.name}
                labelCol={10}
                wrapperCol={12}
                maxLength={item.maxLength}
                options={item.options}
                disable={LIST_FIELD_EDITED_LIQUID_CHECK.includes(item.name) && isLiquidCheck}
              />
            ) : (
              <FormItemInput
                key={item.name}
                label={<RenderLabelForm value={item.text} />}
                name={item.name}
                labelCol={10}
                wrapperCol={12}
                normalize={item.normalize}
                maxLength={item.maxLength ? item.maxLength : undefined}
                suffix={item.suffix}
                disabled={LIST_FIELD_EDITED_LIQUID_CHECK.includes(item.name) && isLiquidCheck}
              />
            )
          })}
        </Col>
        <Col span={8} xl={8} md={12} xs={24}>
          <FormItemCheckBox
            name="is_high_watermark"
            text={<RenderLabelForm value="High Watermark" />}
            disable={isLiquidCheck}
          />
          <FormItemInput
            name="hurdle_rate"
            labelCol={6}
            wrapperCol={12}
            maxLength={30}
            labelAlign="left"
            disabled={!isCheckHurdleRate || isLiquidCheck}
            label={
              <>
                <div style={{ marginTop: '0.5rem', marginRight: '0.5rem' }}>
                  <FormItemCheckBox
                    name="is_hurdle_rate"
                    disable={isLiquidCheck}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator() {
                          setIsCheckHurdleRate(getFieldValue('is_hurdle_rate'))
                          return Promise.resolve()
                        },
                      }),
                    ]}
                  />
                </div>
                <RenderLabelForm value="Hurdle Rate" />
              </>
            }
          />
        </Col>
      </Row>
      <DividerForm />
      <Row>
        {FORM_INPUT_GROUP_FOOTER.map((item) => {
          return (
            <Col span={8} xl={8} md={12} xs={24} key={item.name}>
              <FormItemInput
                name={item.name}
                label={<RenderLabelForm value={item.text} />}
                labelCol={10}
                wrapperCol={12}
                maxLength={item.maxLength ? item.maxLength : undefined}
                disabled={!isLiquidCheck}
              />
            </Col>
          )
        })}
      </Row>
    </Flex>
  )
}
export default Fees
