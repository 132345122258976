import { ColumnProps } from 'antd/lib/table'
import { ExFundDetails } from '../../../shared/api/models/ExFundDetails'
import GenUtil from '../../../shared/utils/gen-util'

export interface ColumnPropsExtended extends ColumnProps<ExFundDetails> {
  key: keyof ExFundDetails
}

export interface ColumnSource {
  groupName: string
  columns: ColumnPropsExtended[]
}

export const defaultColumns: ColumnPropsExtended[] = [
  {
    title: 'Fund ID',
    dataIndex: 'fund_id',
    key: 'fund_id',
    sorter: true,
  },
  {
    title: 'Fund Name',
    dataIndex: 'fund_name',
    key: 'fund_name',
    sorter: true,
    fixed: 'left',
  },
  {
    title: 'Fund Status',
    dataIndex: 'fund_status',
    key: 'fund_status',
    sorter: true,
  },
  {
    title: 'Latest ROR',
    dataIndex: 'latest_ror',
    key: 'latest_ror',
    sorter: true,
  },
  {
    title: 'Latest ROR Date',
    dataIndex: 'latest_ror_date',
    key: 'latest_ror_date',
    render: (date: string | undefined) => {
      return date ? GenUtil.getFormattedYearAndMonth(date) : null
    },
    sorter: true,
  },
  {
    title: 'Latest Asset',
    dataIndex: 'latest_asset',
    key: 'latest_asset',
    sorter: true,
  },
  {
    title: 'Latest Asset Date',
    dataIndex: 'latest_asset_date',
    key: 'latest_asset_date',
    render: (date: string | undefined) => {
      return date ? GenUtil.getFormattedYearAndMonth(date) : null
    },
    sorter: true,
  },
  {
    title: 'Fund ESG',
    dataIndex: 'is_fund_esg',
    key: 'is_fund_esg',
    sorter: true,
  },
]

export const allColumns: ColumnSource[] = [
  {
    groupName: 'General',
    columns: [
      ...defaultColumns,
      {
        title: 'Firm ID',
        dataIndex: 'firm_id',
        key: 'firm_id',
        sorter: true,
      },
      {
        title: 'Firm Name',
        dataIndex: 'firm_name',
        key: 'firm_name',
        sorter: true,
      },
      {
        title: 'Firm ESG',
        dataIndex: 'is_firm_esg',
        key: 'is_firm_esg',
        sorter: true,
      },
      {
        title: 'Inception Date',
        dataIndex: 'inception_date',
        key: 'inception_date',
        sorter: true,
      },
      {
        title: 'Latest Firm Asset',
        dataIndex: 'latest_firm_asset',
        key: 'latest_firm_asset',
        sorter: true,
      },
      {
        title: 'Latest Firm Asset Date',
        dataIndex: 'latest_firm_asset_date',
        key: 'latest_firm_asset_date',
        sorter: true,
        render: (date: string | undefined) => {
          return date ? GenUtil.getFormattedYearAndMonth(date) : null
        },
      },
      {
        title: 'Latest NAV',
        dataIndex: 'latest_nav',
        key: 'latest_nav',
        sorter: true,
      },
      {
        title: 'Latest NAV Date',
        dataIndex: 'latest_nav_date',
        key: 'latest_nav_date',
        sorter: true,
        render: (date: string | undefined) => {
          return date ? GenUtil.getFormattedYearAndMonth(date) : null
        },
      },
      {
        title: 'Regional Focus',
        dataIndex: 'regional_focus',
        key: 'regional_focus',
        sorter: true,
      },
      {
        title: 'Main Strategy',
        dataIndex: 'main_strategy',
        key: 'main_strategy',
        sorter: true,
      },
      {
        title: 'Sub Strategy',
        dataIndex: 'sub_strategy',
        key: 'sub_strategy',
        sorter: true,
      },
      {
        title: 'Fund CMIC',
        key: 'fund_cmic',
        dataIndex: 'fund_cmic',
        sorter: true,
      },
      {
        title: 'ISIN',
        key: 'isin',
        dataIndex: 'isin',
        sorter: true,
      },
      {
        title: 'Bloomberg Ticker',
        key: 'bloomberg_ticker',
        dataIndex: 'bloomberg_ticker',
        sorter: true,
      },
    ],
  },
]

export const fundFamilyColumns: ColumnSource[] = [
  {
    groupName: 'Fund Family',
    columns: [
      {
        title: 'Fund Family Code',
        dataIndex: 'fund_family_code',
        key: 'fund_family_code',
        sorter: true,
      },
      {
        title: 'Fund Family Id',
        dataIndex: 'fund_family_id',
        key: 'fund_family_id',
        sorter: true,
      },
    ],
  },
]

export const allExColumns: ColumnSource[] = [
  {
    groupName: 'General',
    columns: [
      ...defaultColumns,
      {
        title: 'Firm ID',
        dataIndex: 'firm_id',
        key: 'firm_id',
        sorter: true,
      },
      {
        title: 'Firm Name',
        dataIndex: 'firm_name',
        key: 'firm_name',
        sorter: true,
      },
      {
        title: 'Firm ESG',
        dataIndex: 'is_firm_esg',
        key: 'is_firm_esg',
        sorter: true,
      },
      {
        title: 'Inception Date',
        dataIndex: 'inception_date',
        key: 'inception_date',
        sorter: true,
      },
      {
        title: 'Latest Firm Asset',
        dataIndex: 'latest_firm_asset',
        key: 'latest_firm_asset',
        sorter: true,
      },
      {
        title: 'Latest Firm Asset Date',
        dataIndex: 'latest_firm_asset_date',
        key: 'latest_firm_asset_date',
        sorter: true,
        render: (date: string | undefined) => {
          return date ? GenUtil.getFormattedYearAndMonth(date) : null
        },
      },
      {
        title: 'Latest NAV',
        dataIndex: 'latest_nav',
        key: 'latest_nav',
        sorter: true,
      },
      {
        title: 'Latest NAV Date',
        dataIndex: 'latest_nav_date',
        key: 'latest_nav_date',
        sorter: true,
        render: (date: string | undefined) => {
          return date ? GenUtil.getFormattedYearAndMonth(date) : null
        },
      },
      {
        title: 'Regional Focus',
        dataIndex: 'regional_focus',
        key: 'regional_focus',
        sorter: true,
      },
      {
        title: 'Main Strategy',
        dataIndex: 'main_strategy',
        key: 'main_strategy',
        sorter: true,
      },
      {
        title: 'Sub Strategy',
        dataIndex: 'sub_strategy',
        key: 'sub_strategy',
        sorter: true,
      },
      {
        title: 'Fund CMIC',
        key: 'fund_cmic',
        dataIndex: 'fund_cmic',
        sorter: true,
      },
    ],
  },
]
