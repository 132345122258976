import { Button, Modal, notification, Popconfirm } from 'antd'
import React from 'react'
import APIService from '../../../../../shared/api'
import {
  FundDetailDataContext,
  PERFORMANCE_REQUEST_ENUM,
} from '../../../../../shared/api/context/FundDetailDataContext'
import DataSheet, { DataSheetType, RORResponseCalculate } from '../../../../../shared/components/DataSheet'
import { DividerForm } from '../../../../../shared/components/DividerForm'
import { useServiceState } from '../../../../../shared/hooks/useServiceState'
type Props = {
  isOpen: boolean
  onRequestClose: () => void
  fromDate: string
  toDate: string
  setIsUpdateFund: (value: boolean) => void
}
export const ModalCalculateNAV: React.FC<Props> = ({ isOpen, onRequestClose, fromDate, toDate, setIsUpdateFund }) => {
  const {
    dataROR,
    dataFundDetail,
    dataFundDetailChange,
    getPerformanceRequest,
    setRORDataChange,
    clearSnapshot,
  } = React.useContext(FundDetailDataContext)
  const [loadingSubmit, setLoadingSubmit] = React.useState(false)

  const { invoke: getCalculate, data: dataCalculate, loading } = useServiceState(
    APIService.fundDetailService.fetchCalculateNAV,
  )

  const dataChangeRef = dataFundDetailChange?.current

  const dataSheet = React.useMemo(() => {
    return dataCalculate ? dataCalculate.map((item) => ({ value: item.value, yyyy_mm: item.date })) : []
  }, [dataCalculate])

  React.useEffect(() => {
    const fundId = dataFundDetail?.fund_id
    fundId && getCalculate({ fundId, fromDate, toDate })
  }, [dataFundDetail, fromDate, getCalculate, toDate])

  React.useEffect(() => {
    if (!dataROR) {
      getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.ROR)
    }
  }, [dataROR, getPerformanceRequest])

  const submitUpdate = () => {
    if (!dataFundDetail || !dataFundDetail.fund_id) {
      return
    }
    setLoadingSubmit(true)
    APIService.fundDetailService
      .fetchCalculateNAV({ fundId: dataFundDetail.fund_id, fromDate, toDate, saveToDb: true })
      .then(() => {
        notification.success({ message: 'ROR Updated' })
        clearSnapshot()

        setRORDataChange({ values: undefined })

        // change save button when user Only edit ROR table
        if (dataChangeRef && Object.keys(dataChangeRef).filter((item) => item !== '_RORDataChange').length === 0) {
          setIsUpdateFund(false)
        }
        getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.ROR)
        getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.LATEST_PERFORMANCE)
      })
      .catch((err) => {
        console.error({ err })
        notification.error({ message: 'ROR Update Failed' })
      })
      .finally(() => {
        setLoadingSubmit(false)
        onRequestClose()
      })
  }

  //const draft = dataROR

  const draft =
    dataSheet.length > 0 && dataROR
      ? dataROR.map((item) => {
          const index = dataSheet.findIndex((i) => i.yyyy_mm === item.yyyy_mm)
          if (index > -1) {
            return { value: dataSheet[index].value, yyyy_mm: item.yyyy_mm }
          }
          return { value: item.value, yyyy_mm: item.yyyy_mm }
        })
      : undefined

  return (
    <Modal
      width="80%"
      visible={isOpen}
      title="Calculate"
      onCancel={onRequestClose}
      footer={
        <Button type="link" loading={loadingSubmit} onClick={onRequestClose}>
          Cancel
        </Button>
      }
    >
      <DataSheet
        initialData={dataSheet}
        isReadOnly
        canAdd
        loading={loading}
        currencyValue={dataFundDetail?.denomination ? dataFundDetail.denomination : undefined}
      />
      <Popconfirm
        title={<div>ROR values will be updated/replaced with the data shown. Please confirm to proceed!</div>}
        onConfirm={submitUpdate}
      >
        <Button type="primary" style={{ marginTop: '1rem' }} loading={loadingSubmit}>
          Save To ROR Table
        </Button>
      </Popconfirm>
      <DividerForm text="Current ROR" />
      {dataROR && dataCalculate && (
        <DataSheet
          currencyValue={dataFundDetail?.denomination ? dataFundDetail.denomination : undefined}
          minDate={dataFundDetail?.inception_date ? dataFundDetail.inception_date : undefined}
          isReadOnly
          type={DataSheetType.ROR}
          draft={dataROR}
          initialData={draft}
          canAdd
        />
      )}
    </Modal>
  )
}
