import { DownloadOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, DatePicker, Input, notification, Pagination, Row, Select, Space, Tabs, Tag } from 'antd'
import Table, { ColumnProps } from 'antd/lib/table'
import { SorterResult } from 'antd/lib/table/interface'
import axios from 'axios'
import JsFileDownload from 'js-file-download'
import moment from 'moment'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useDebounce } from 'use-debounce/lib'
import { RootState } from '../../app/rootReducer'
import APIService from '../../shared/api'
import { FirmDetailDataProvider } from '../../shared/api/context/FirmDetailDataContext'
import { FundDetailDataProvider } from '../../shared/api/context/FundDetailDataContext'
import { ActivitiesFiltered, ActivityLogType } from '../../shared/api/models/ActivityLog'
import { Colors } from '../../shared/colors'
import { useServiceState } from '../../shared/hooks/useServiceState'
import { FundFirmTypeEnum } from '../../slice/appSettingsSlice'
import { RenderTitleTabPane } from '../Dashboard/Dashboard'
import { updateActivityLogParams } from '../Dashboard/DashboardTab/SearchForm/redux/searchParamsSlice'
import { FirmDetailMain } from '../Dashboard/FirmDetail/FirmDetailMain'
import { FundDetailMain } from '../Dashboard/FundDetail/FundDetailMain'
import { DrawerActivityLogInfo } from './DrawerActivityLogInfo'
import { LegacyActivityLog } from './LegacyActivityLog'

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledTabs = styled(Tabs)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;

  .ant-tabs-nav {
    margin-bottom: 0%;
  }

  .ant-tabs-content-holder {
    overflow: hidden;
  }

  .ant-tabs-content {
    flex-grow: 1;
    background: white;
    border-left: solid 1px ${Colors.border};
    border-right: solid 1px ${Colors.border};
    border-bottom: solid 1px ${Colors.border};
    height: 100%;

    .ant-tabs-tabpane-active {
      height: 100%;
      display: flex;
    }

    .ant-card-head {
      min-height: auto;
    }
  }
`
type ListTabType = {
  id: string
  type: FundFirmTypeEnum
}

const SOURCE_OPTIONS = ['MANAGER_LINK_INTERNAL', 'MANAGER_LINK_EXTERNAL', 'SYSTEM_SOURCE']
type Props = {}
export const ActivityLog: React.FC<Props> = () => {
  const { invoke: getLogEvent, data: dataLogEvent, loading } = useServiceState(
    APIService.activityLogService.fetchDataEvent,
  )

  // const { invoke: getListUser, data: dataUser } = useServiceState<ListUserActivities>(
  //   APIService.activityLogService.fetchListUser,
  // )

  const dispatch = useDispatch()

  const { invoke: getListUserByText, data: dataUserSearch, loading: loadingUser } = useServiceState(
    APIService.activityLogService.fetchListUserByText,
  )

  const [searchText, setSearchText] = React.useState<string>('')

  const [debouncedText] = useDebounce(searchText, 500)

  React.useEffect(() => {
    getListUserByText({ text: debouncedText })
  }, [debouncedText, getListUserByText])

  const { invoke: getListCode, data: dataCode } = useServiceState<string[], undefined>(
    APIService.activityLogService.fetchListCode,
  )

  const [page, setPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(25)
  const [sortBy, setSortBy] = React.useState<string | undefined>()
  const [orderBy, setOrderBy] = React.useState<string | undefined>()

  const [showDrawer, setShowDrawer] = React.useState(false)
  const [selectedEvent, setSelectedEvent] = React.useState<ActivityLogType>()
  const searchInput = React.useRef<Input>(null)
  const [activeTab, setActiveTab] = React.useState('activity-log')
  const [listTab, setListTab] = React.useState<ListTabType[]>([])

  const { activityLogParams } = useSelector((state: RootState) => state.searchParams)
  const [filteredInfo, setFilteredInfo] = React.useState<ActivitiesFiltered>(activityLogParams)
  const [loadingExport, setLoadingExport] = React.useState(false)

  React.useEffect(() => {
    //getListUser()
    getListCode()
  }, [getListCode])

  React.useEffect(() => {
    const params = Object.keys(filteredInfo).reduce((acc, key) => {
      if (filteredInfo[key as keyof ActivitiesFiltered]) {
        return { ...acc, [key]: filteredInfo[key as keyof ActivitiesFiltered] }
      }
      return acc
    }, {})
    dispatch(updateActivityLogParams(params))
  }, [dispatch, filteredInfo])

  const handlePageSizeChange = (current: number, size: number) => {
    setPageSize(size)
    if (current !== 1) {
      setPage(1)
    }
  }

  const activityParams = React.useMemo(() => {
    setPage(1)
    return Object.keys(filteredInfo).reduce((acc, key) => {
      const valueFilter = filteredInfo[key as keyof ActivitiesFiltered]
      if (valueFilter && valueFilter.length > 0) {
        if (key === 'fund_id') {
          const type = valueFilter?.[0].toString().split('-')[0]
          const value = valueFilter?.[0].toString().split('-')[1]
          return type === '[Equals]' ? { ...acc, fund_id: { equals: value } } : { ...acc, fund_id: { contains: value } }
        }
        if (key === 'user__username' || key === 'code' || key === 'source') {
          return { ...acc, [key]: valueFilter }
        }
        if (key === 'created_date') {
          const startDate = valueFilter?.[0].toString().split(',')[0]
          const endDate = valueFilter?.[0].toString().split(',')[1]
          return { ...acc, [key]: { gte: startDate?.trim(), lte: endDate?.trim() } }
        }

        return { ...acc, [key]: valueFilter?.[0] }
      }
      return acc
    }, {})
  }, [filteredInfo])

  React.useEffect(() => {
    getLogEvent({
      where: Object.keys(activityParams).length > 0 ? activityParams : undefined,
      page,
      limit: pageSize,
      sort_by: sortBy || 'created_date',
      order_by: orderBy || 'DESC',
    })
  }, [page, pageSize, sortBy, orderBy, activityParams, getLogEvent])

  const handleTableChange = (sorter: SorterResult<any>, filter: Record<string, string | null>) => {
    setSortBy(sorter.order ? sorter.columnKey?.toString() : undefined)
    setOrderBy(sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : undefined)
    setFilteredInfo(filter as ActivitiesFiltered)
  }

  const exportActivityLog = async () => {
    setLoadingExport(true)
    try {
      const res = await APIService.fundService.postDownloadFile({
        file_type: 'MANAGER_LINK_INTERNAL_EVENT_EXPORT',
        data: {
          where: Object.keys(activityParams).length > 0 ? activityParams : undefined,
        },
      })

      // const resFile = await APIService.fundService.downloadFileByUrl(res.data.url)
      const resFile = await axios.get(`${process.env.REACT_APP_AUTH_API_URL}/manager-link/files/${res.data.url}`)
      JsFileDownload(resFile.data, 'activity-results.csv', 'application/csv')
    } catch (error) {
      console.log(error.response)
      notification.error({ message: 'Failed to export Activity Log!' })
    } finally {
      setLoadingExport(false)
    }
  }

  const handleClearAllFilter = () => {
    setFilteredInfo({})
  }

  const getColumnSearchProps = (dataIndex: string): ColumnProps<ActivityLogType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      if (dataIndex === 'fund_id') {
        return (
          <Row style={{ padding: 8 }}>
            <Space>
              <Input
                ref={searchInput}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0] ? selectedKeys[0].toString().split('-')[1] : undefined}
                allowClear
                onChange={(e) => {
                  setSelectedKeys(
                    e.target.value
                      ? [
                          selectedKeys[0]
                            ? `${selectedKeys[0].toString().split('-')[0]}-${e.target.value}`
                            : `-${e.target.value}`,
                        ]
                      : [],
                  )
                }}
              />
              <Button
                type="primary"
                onClick={() => {
                  if (selectedKeys[0]) {
                    setSelectedKeys([`[Equals]-${selectedKeys[0].toString().split('-')[1]}`])
                    return confirm && confirm()
                  }
                  return clearFilters && clearFilters()
                }}
              >
                Exact
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  if (selectedKeys[0]) {
                    setSelectedKeys([`[Contains]-${selectedKeys[0].toString().split('-')[1]}`])
                    return confirm && confirm()
                  }
                  return clearFilters && clearFilters()
                }}
              >
                Partial
              </Button>
              <Button onClick={() => clearFilters && clearFilters()} icon={<ReloadOutlined />} />
            </Space>
          </Row>
        )
      }

      if (dataIndex === 'created_date') {
        return (
          <div style={{ padding: 8 }}>
            <Row>
              <DatePicker.RangePicker
                value={
                  selectedKeys[0]
                    ? [
                        moment((selectedKeys[0] as string).split(',')[0]),
                        moment((selectedKeys[0] as string).split(',')[1]),
                      ]
                    : undefined
                }
                onChange={(e) => {
                  if (e) {
                    setSelectedKeys([`${moment(e[0]).format('YYYY-MM-DD')}, ${moment(e[1]).format('YYYY-MM-DD')}`])
                  }
                }}
                style={{ width: 250, marginBottom: 8 }}
              />
            </Row>
            <Row>
              <Space>
                <Button
                  type="primary"
                  onClick={() => confirm && confirm()}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button onClick={() => clearFilters && clearFilters()} size="small" style={{ width: 90 }}>
                  Reset
                </Button>
              </Space>
            </Row>
          </div>
        )
      }
      if (dataIndex === 'user__username') {
        //const options = dataUser ? dataUser.internal_users.map((item) => ({ value: item, label: item })) : []

        return (
          <Row style={{ padding: 8 }}>
            <Space>
              <Select
                value={selectedKeys}
                showSearch
                placeholder="Type to search for user"
                style={{ width: 300 }}
                mode="multiple"
                loading={loadingUser}
                allowClear
                filterOption={false}
                onSearch={(e) => {
                  setSearchText(e)
                }}
                onChange={(e) => setSelectedKeys(e as string[])}
                onBlur={() => setSearchText('')}
                options={dataUserSearch?.map((item) => ({ value: item.username, label: item.username }))}
              />
              <Button type="primary" onClick={() => confirm && confirm()} icon={<SearchOutlined />}></Button>
              <Button onClick={() => clearFilters && clearFilters()} icon={<ReloadOutlined />}></Button>
            </Space>
          </Row>
        )
      }

      if (dataIndex === 'code' || dataIndex === 'source') {
        const optionsCode = dataCode ? dataCode.map((item) => ({ value: item, label: item })) : []
        const optionsSource = SOURCE_OPTIONS.map((item) => ({ value: item, label: item }))
        const options = dataIndex === 'code' ? optionsCode : optionsSource
        return (
          <Row style={{ padding: 8 }}>
            <Space>
              <Select
                value={selectedKeys}
                style={{ width: 300 }}
                mode="multiple"
                allowClear
                onChange={(e) => setSelectedKeys(e as string[])}
                options={options}
              />
              <Button type="primary" onClick={() => confirm && confirm()} icon={<SearchOutlined />}></Button>
              <Button onClick={() => clearFilters && clearFilters()} icon={<ReloadOutlined />}></Button>
            </Space>
          </Row>
        )
      }
      return (
        <Row style={{ padding: 8 }}>
          <Space>
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              allowClear
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => {
                if (selectedKeys[0] !== '') {
                  confirm && confirm()
                } else clearFilters && clearFilters()
              }}
            />
            <Button
              type="primary"
              onClick={() => {
                if (selectedKeys[0] !== '') {
                  confirm && confirm()
                } else clearFilters && clearFilters()
              }}
              icon={<SearchOutlined />}
            />
            <Button onClick={() => clearFilters && clearFilters()} icon={<ReloadOutlined />} />
          </Space>
        </Row>
      )
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select())
      }
    },
  })

  const columns: ColumnProps<ActivityLogType>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 135,
      sorter: true,
    },
    {
      title: 'Created Date',
      dataIndex: 'created_date',
      key: 'created_date',
      defaultSortOrder: 'descend',
      width: 250,
      ...getColumnSearchProps('created_date'),
      filteredValue: filteredInfo.created_date || null,
      render: (text) => <div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>,
      sorter: true,
    },
    {
      title: 'User',
      dataIndex: 'user__username',
      key: 'user__username',
      width: 250,
      sorter: true,
      ...getColumnSearchProps('user__username'),
      filteredValue: filteredInfo.user__username || null,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: 350,
      ...getColumnSearchProps('code'),
      filteredValue: filteredInfo.code || null,
      sorter: true,
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      width: 350,
      ...getColumnSearchProps('source'),
      filteredValue: filteredInfo.source || null,
      sorter: true,
    },
    {
      title: 'Firm ID',
      dataIndex: 'firm_id',
      key: 'firm_id',
      width: 135,
      ...getColumnSearchProps('firm_id'),
      filteredValue: filteredInfo.firm_id || null,
      render: (_, record) => (
        <Button
          type="link"
          style={{ padding: '4px 8px' }}
          onClick={() => {
            if (!listTab.find((e) => e.id.split('-')[1] === record.firm_id)) {
              setListTab([...listTab, { id: 'firms-' + record.firm_id, type: FundFirmTypeEnum.FIRMS_IN_FUNDS }])
            }
            setActiveTab('firms-' + record.firm_id)
          }}
        >
          {record.firm_id}
        </Button>
      ),
      sorter: true,
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      width: 350,
      ...getColumnSearchProps('url'),
      filteredValue: filteredInfo.url || null,
      sorter: true,
    },
    {
      title: 'Fund ID',
      dataIndex: 'fund_id',
      key: 'fund_id',
      width: 135,
      ...getColumnSearchProps('fund_id'),
      filteredValue: filteredInfo.fund_id || null,
      render: (_, record) => (
        <Button
          type="link"
          style={{ padding: '4px 8px' }}
          onClick={() => {
            if (!listTab.find((e) => e.id.split('-')[1] === record.fund_id)) {
              setListTab([...listTab, { id: 'funds-' + record.fund_id, type: FundFirmTypeEnum.FUNDS }])
            }
            setActiveTab('funds-' + record.fund_id)
          }}
        >
          {record.fund_id}
        </Button>
      ),
      sorter: true,
    },

    {
      title: 'Action',
      key: 'action',
      width: 135,
      render: (_, record) => (
        <>
          <Button
            type="primary"
            style={{ padding: '4px 8px' }}
            onClick={() => {
              setShowDrawer(true)
              setSelectedEvent(record)
            }}
          >
            Details
          </Button>
        </>
      ),
    },
  ]

  return (
    <StyledTabs
      hideAdd
      type="editable-card"
      activeKey={activeTab}
      onChange={(key) => {
        setActiveTab(key)
      }}
      onEdit={(targetKey, action) => {
        if (action === 'remove') {
          setListTab(listTab.filter((item) => item.id !== targetKey))
          if (targetKey === activeTab) {
            setActiveTab('activity-log')
          }
        }
      }}
    >
      <Tabs.TabPane
        tab="Activity Log"
        closable={false}
        key="activity-log"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div style={{ paddingLeft: 18, minHeight: 32 }}>
          {Object.keys(activityParams).length > 0 && 'Filters: '}
          {Object.keys(filteredInfo).map((item) => {
            if (filteredInfo[item as keyof ActivitiesFiltered]) {
              return (
                <Tag
                  key={item}
                  closable
                  onClose={(e: any) => {
                    e.stopPropagation()
                    setFilteredInfo({ ...filteredInfo, [item]: null })
                  }}
                >
                  <strong>{item}</strong>:{filteredInfo[item as keyof ActivitiesFiltered]?.join(', ')}
                </Tag>
              )
            }
          })}
          {Object.keys(activityParams).length > 0 && (
            <Button type="link" onClick={handleClearAllFilter}>
              Clear All
            </Button>
          )}
        </div>
        <Table
          rowKey="id"
          dataSource={dataLogEvent?.events}
          columns={columns}
          bordered
          loading={loading}
          style={{ width: '100%' }}
          scroll={{ y: 'calc(100vh - 320px)', x: 'min-content' }}
          size="small"
          pagination={false}
          onChange={(_pagination, filter: any, sorter: any) => {
            handleTableChange(sorter, filter)
          }}
          footer={(record) =>
            record.length > 0 ? (
              <Footer>
                <Button type="primary" onClick={exportActivityLog} loading={loadingExport}>
                  <DownloadOutlined />
                  Export
                </Button>
                <Pagination
                  showSizeChanger
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                  onChange={(currentPage) => setPage(currentPage)}
                  total={dataLogEvent ? dataLogEvent.total : 0}
                  current={page}
                  pageSize={pageSize}
                  pageSizeOptions={['10', '25', '50', '100']}
                  onShowSizeChange={handlePageSizeChange}
                />
              </Footer>
            ) : null
          }
        />
        {selectedEvent && (
          <DrawerActivityLogInfo data={selectedEvent} showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
        )}
      </Tabs.TabPane>
      {listTab.map((item) => {
        if (item.type === FundFirmTypeEnum.FUNDS) {
          return (
            <Tabs.TabPane tab={`Funds - ${item.id.split('-')[1]}`} closable={true} key={item.id}>
              <FundDetailDataProvider type={'internal'} id={item.id.split('-')[1]}>
                <FundDetailMain />
              </FundDetailDataProvider>
            </Tabs.TabPane>
          )
        }
        return (
          <Tabs.TabPane
            tab={
              <RenderTitleTabPane name={`Firms - ${item.id.split('-')[1]}`} type={FundFirmTypeEnum.FIRMS_IN_FUNDS} />
            }
            closable={true}
            key={item.id}
          >
            <FirmDetailDataProvider id={item.id.split('-')[1]}>
              <FirmDetailMain />
            </FirmDetailDataProvider>
          </Tabs.TabPane>
        )
      })}
      <Tabs.TabPane
        tab="Legacy Activity Log"
        closable={false}
        key="legacy-log"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <LegacyActivityLog />
      </Tabs.TabPane>
    </StyledTabs>
  )
}
