import { CheckCircleTwoTone, SearchOutlined } from '@ant-design/icons'
import { AutoComplete, Button, Card, Col, Empty, Form, Input, notification, Popconfirm, Row, Space } from 'antd'
import { useForm } from 'antd/lib/form/util'
import Text from 'antd/lib/typography/Text'
import moment from 'moment'
import React, { SetStateAction, useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import APIService from '../../shared/api'
import { ExFirmDetailLabelSearchProvider } from '../../shared/api/context/ExFirmDetailLabelSearchContext'
import { FirmDetailDataContext } from '../../shared/api/context/FirmDetailDataContext'
import { FirmDetails, FirmMoreField, MultiManagerPlatformType } from '../../shared/api/models/FirmDetails'
import { FundCCMCResponse } from '../../shared/api/models/FundDetails'
import { Colors } from '../../shared/colors'
import { UpdateRORType } from '../../shared/components/DataSheet'
import FFPerfectScrollbar from '../../shared/components/FFPerfectScrollbar'
import { MODE_ESG_VIEW } from '../../shared/components/FundESGSection'
import { LoadingDetails } from '../../shared/components/LoadingDetails'
import { TabContentWrapper } from '../../shared/components/TabContentWrapper'
import { useDraft } from '../../shared/DraftManager'
import { useServiceState } from '../../shared/hooks/useServiceState'
import { LeftNavExFirmEnum, renderSearchListExFirm } from '../../shared/SearchExFirmLabel'
import { LeftNavExFundEnum } from '../../shared/SearchExFundLabel'
import { PREFIX_EXTERNAL, updateSelectedNav, updateTabTitle } from '../../slice/appSettingsSlice'
import FirmCMIC from '../Dashboard/FirmDetail/CMIC/FirmCMIC'
import AUM from './ExFirmDetail/AUM'
import ContactInformation from './ExFirmDetail/ContactInformation'
import ExFirmDI from './ExFirmDetail/ExFirmDI'
import FirmCoInvestments from './ExFirmDetail/FirmCoInvestments'
import { FirmESGPolicies } from './ExFirmDetail/FirmESGPolicies'
import FirmOwnership from './ExFirmDetail/FirmOwnership'
import FirmRegistration from './ExFirmDetail/FirmRegistration'
import { TAB_STATUS_ENUM } from './ExFundDetailMain'
import { FirmMultiManagerPodPlatform } from '../Dashboard/FirmDetail/FirmMultiManagerPodPlatform/FirmMultiManagerPodPlatform'
import { ExFirmFundFamily } from './ExFirmDetail/ExFirmFundFamily'

const StyledCard = styled(Card)`
  overflow: hidden;
  .ant-card-head {
    padding: 0 1rem;
  }
  .ant-card-head-title {
    padding: 0;
  }
  .ant-card-body {
    padding: 0;
    overflow: hidden;
  }
  .ant-card-head {
    border-bottom: 2px solid #f0f0f0 !important;
  }
`
const StyledForm = styled(Form)`
  flex: 1;
  overflow: hidden;
  height: 100%;
  .ant-form-item {
    margin-bottom: 8px;
  }
`
const TextID = styled(Text)`
  font-size: 14px;
`

const StyledTabList = styled.div<{ active?: boolean }>`
  background: ${(props) => (props.active ? '#EDF6FF' : '#fff')};
  font-weight: ${(props) => (props.active ? 'bold' : 'none')};
  display: flex;
  flex: 1;
  align-items: center;
  border-left: solid 1px ${Colors.border};
  border-right: solid 1px ${Colors.border};
  border-bottom: solid 1px ${Colors.border};
  border-top: none;
  padding: 1rem 0 1rem 3rem;
  :hover {
    cursor: pointer;
  }
`

const TAB_LIST = [
  {
    id: LeftNavExFirmEnum.ASSETS_UNDER_MANAGEMENT,
    status: TAB_STATUS_ENUM.DEFAULT,
  },
  {
    id: LeftNavExFirmEnum.REGISTRATION,
    status: TAB_STATUS_ENUM.DEFAULT,
  },
  {
    id: LeftNavExFirmEnum.CONTACT_INFORMATION,
    status: TAB_STATUS_ENUM.DEFAULT,
  },
  {
    id: LeftNavExFirmEnum.OWNERSHIP,
    status: TAB_STATUS_ENUM.DEFAULT,
  },
  {
    id: LeftNavExFirmEnum.CO_INVESTMENT,
    status: TAB_STATUS_ENUM.DEFAULT,
  },
  {
    id: LeftNavExFirmEnum.FIRM_ESG_POLICIES,
    status: TAB_STATUS_ENUM.DEFAULT,
  },
  {
    id: LeftNavExFirmEnum.FIRM_DIVERSITY_INCLUSION,
    status: TAB_STATUS_ENUM.DEFAULT,
  },
  {
    id: LeftNavExFirmEnum.FIRM_CMIC,
    status: TAB_STATUS_ENUM.DEFAULT,
  },
  {
    id: LeftNavExFirmEnum.FIRM_MULTI_MANAGER_POD_PLATFORM,
    status: TAB_STATUS_ENUM.DEFAULT,
  },
  {
    id: LeftNavExFirmEnum.FUND_FAMILY,
    status: TAB_STATUS_ENUM.DEFAULT,
  },
]
export const EXFIRM_DATE_FIELDS = ['firm_asset_date']

type Props = { setShouldUpdate: (value: boolean) => void; selectedTab: LeftNavExFirmEnum | LeftNavExFundEnum }

const MyFirm: React.FC<Props> = ({ setShouldUpdate, selectedTab }) => {
  const {
    dataFirmDetail,
    loading,
    refetch,
    id: firmId,
    dataFirmDetailSubmitRef,
    fundDataDropdown,
    dataChanged,
    setDataChanged,
  } = React.useContext(FirmDetailDataContext)

  const [optionFilter, setOptionFilter] = React.useState(renderSearchListExFirm)
  const [textFilterSearch, settextFilterSearch] = React.useState('')
  const [optionShow, setOptionShow] = React.useState(renderSearchListExFirm)

  const [loadingSubmitForm, setLoadingSubmitForm] = React.useState(false)
  const [firmAssetsDataChange, setFirmAssetsDataChange] = React.useState<UpdateRORType>({ values: undefined })
  const [firmAssetsHFDataChange, setFirmAssetsHFDataChange] = React.useState<UpdateRORType>({ values: undefined })
  const [firmCMICDataChange, setFirmCMICDataChange] = React.useState<FundCCMCResponse[]>()
  const [multiManagerPlatformChange, setMultiManagerPlatformChange] = React.useState<MultiManagerPlatformType[]>()

  const [submittingFirmAsset, setSubmittingFirmAsset] = React.useState(false)
  const [submittingFirmAssetHF, setSubmittingFirmAssetHF] = React.useState(false)
  const [isUpdated, setIsUpdated] = React.useState(false)
  //
  // Fetch Data
  const { invoke: getFirmAssets, data: dataFirmAsset, loading: loadingFirmAsset } = useServiceState(
    APIService.firmDetailService.fetchFirmAssets,
  )
  const { invoke: getFirmAssetsHF, data: dataFirmAssetHF, loading: loadingFirmAssetHF } = useServiceState(
    APIService.firmDetailService.fetchFirmAssetsHedgeFund,
  )

  const { invoke: getFirmCMIC, data: firmCMICData, loading: loadingCMICData } = useServiceState(
    APIService.firmDetailService.fetchFirmCMIC,
  )
  // Minority Types
  const { invoke: getAllMinorityTypes, data: minorityTypes } = useServiceState(
    APIService.minorityService.fetchMinorityTypes,
  )
  // Firm Minority
  const { invoke: getFirmMinority, data: dataFirmMinority } = useServiceState(
    APIService.minorityService.fetchFirmMinorityStatuses,
  )
  // Multi Manager Platform
  const {
    invoke: getMultiManagerPlatform,
    data: multiManagerPlatform,
    loading: loadingMultiManagerPlatform,
  } = useServiceState(APIService.firmDetailService.fetchMultiManagerPlatform)
  //
  //
  const [tabList, setTabList] = React.useState(TAB_LIST)

  const [form] = useForm()
  const dispatch = useDispatch()
  const { clearSnapshot, loadSnapshot, saveSnapshot } = useDraft()

  // call api
  React.useEffect(() => {
    const countryCode = dataFirmDetail?.country
    if (!countryCode) {
      return
    }
    getAllMinorityTypes(countryCode)
  }, [dataFirmDetail?.country, getAllMinorityTypes])

  React.useEffect(() => {
    if (!dataFirmDetail) {
      return
    }
    getFirmMinority(dataFirmDetail.firm_id)
  }, [dataFirmDetail, getFirmMinority])

  React.useEffect(() => {
    const firmMinorityStatuses = dataFirmMinority?.firm_minority_statuses
    if (firmMinorityStatuses?.length) {
      firmMinorityStatuses?.map((item) => form.setFieldsValue({ [`minority_type_${item.minority_type}`]: item.status }))
    }
  }, [dataFirmMinority, form])

  React.useEffect(() => {
    if (!dataFirmDetail) {
      return
    }
    getFirmAssets(dataFirmDetail.firm_id)
  }, [dataFirmDetail, getFirmAssets])

  React.useEffect(() => {
    if (!dataFirmDetail) {
      return
    }
    getFirmAssetsHF(dataFirmDetail.firm_id)
  }, [dataFirmDetail, getFirmAssetsHF])

  React.useEffect(() => {
    if (!dataFirmDetail) {
      return
    }
    getFirmCMIC(dataFirmDetail.firm_id)
  }, [dataFirmDetail, getFirmCMIC])

  React.useEffect(() => {
    if (!dataFirmDetail) {
      return
    }
    getMultiManagerPlatform(dataFirmDetail.firm_id)
  }, [dataFirmDetail, getMultiManagerPlatform])

  // set is updated state
  React.useEffect(() => {
    if (firmAssetsDataChange.values) {
      setIsUpdated(true)
    }
  }, [firmAssetsDataChange])

  React.useEffect(() => {
    if (firmAssetsHFDataChange.values) {
      setIsUpdated(true)
    }
  }, [firmAssetsHFDataChange])

  React.useEffect(() => {
    if (firmCMICDataChange && firmCMICDataChange.length > 0) {
      setIsUpdated(true)
    }
  }, [firmCMICDataChange])

  React.useEffect(() => {
    if (firmCMICDataChange && firmCMICDataChange.length > 0) {
      setIsUpdated(true)
    }
  }, [firmCMICDataChange])

  React.useEffect(() => {
    if (multiManagerPlatformChange && multiManagerPlatformChange.length > 0) {
      setIsUpdated(true)
    }
  }, [multiManagerPlatformChange])
  //
  React.useEffect(() => {
    const draft = loadSnapshot('exFirmDraft', firmId)

    if (form && draft) {
      const parsedCMICData = draft._firmCMICDataChange?.reduce((acc: any, curr: any) => {
        return {
          ...acc,
          [curr.fund_id]: {
            ...curr,
          },
        }
      }, {})
      form.setFieldsValue({ ...draft, _firmCMICDataChange: parsedCMICData })
      // @ts-ignore
      form.draft = draft
      setIsUpdated(true)
      setFirmAssetsDataChange(draft._firmAssetsDataChange || { values: undefined })
      setFirmAssetsHFDataChange(draft._firmAssetsHFDataChange || { values: undefined })
      setFirmCMICDataChange(draft._firmCMICDataChange || undefined)
      setMultiManagerPlatformChange(draft._multiManagerPlatformChange || undefined)
    }
  }, [firmId, form, loadSnapshot, setIsUpdated])

  React.useEffect(() => {
    if (dataFirmDetail) {
      dispatch(
        updateTabTitle({ tabName: dataFirmDetail.firm_name, id: `${PREFIX_EXTERNAL}-${dataFirmDetail.firm_id}` }),
      )
    }
  }, [dataFirmDetail, dispatch, loadSnapshot])

  const firmCountry = fundDataDropdown?.firm_contact_country.find((item) => item.code_code === dataFirmDetail?.country)

  const {
    denomination,
    registration,
    sec_registered,
    iard_crd_number,
    sec_number,
    non_sec_country,
    non_sec_reg_auth,
    non_sec_reg_auth_type,
    form_non_sec_country,
    non_sec_reg_num,
    contact_name1,
    contact_address1,
    contact_city,
    contact_state,
    contact_postal_code,
    country,
    contact_phone,
    contact_fax,
    contact_email,
    contact_web,
    contact_description,
    founded,
    owned_by_employees,
    employees_with_ownership_stake,
    minority_own_type,
    minority_own_level,
    is_minority_firm,
    offer_coinvestments,
    is_firm_esg,
    future,
  } = form.getFieldsValue([
    'denomination',
    'registration',
    'sec_registered',
    'iard_crd_number',
    'sec_number',
    'non_sec_country',
    'non_sec_reg_auth',
    'non_sec_reg_auth_type',
    'form_non_sec_country',
    'non_sec_reg_num',
    'contact_name1',
    'contact_address1',
    'contact_city',
    'contact_state',
    'contact_postal_code',
    'country',
    'contact_phone',
    'contact_fax',
    'contact_email',
    'contact_web',
    'contact_description',
    'founded',
    'owned_by_employees',
    'employees_with_ownership_stake',
    'minority_own_type',
    'minority_own_level',
    'is_minority_firm',
    'offer_coinvestments',
    'is_firm_esg',
    'future',
  ])

  const checkFields = useCallback(() => {
    setTabList((prev) => {
      return prev.map((tabItem) => {
        switch (tabItem.id) {
          case LeftNavExFirmEnum.ASSETS_UNDER_MANAGEMENT:
            return {
              ...tabItem,
              status: denomination != null ? TAB_STATUS_ENUM.SUCCESS : TAB_STATUS_ENUM.DEFAULT,
            }
          case LeftNavExFirmEnum.REGISTRATION:
            return {
              ...tabItem,
              status:
                registration != null ||
                sec_registered != null ||
                iard_crd_number != null ||
                sec_number != null ||
                form_non_sec_country != null ||
                non_sec_reg_auth != null ||
                non_sec_reg_auth_type != null ||
                non_sec_reg_num != null
                  ? TAB_STATUS_ENUM.SUCCESS
                  : TAB_STATUS_ENUM.DEFAULT,
            }
          case LeftNavExFirmEnum.CONTACT_INFORMATION:
            return {
              ...tabItem,
              status:
                contact_name1 != null ||
                contact_address1 != null ||
                contact_city != null ||
                contact_state != null ||
                contact_postal_code != null ||
                country != null ||
                contact_phone != null ||
                contact_fax != null ||
                contact_email != null ||
                contact_web != null ||
                contact_description != null
                  ? TAB_STATUS_ENUM.SUCCESS
                  : TAB_STATUS_ENUM.DEFAULT,
            }
          case LeftNavExFirmEnum.OWNERSHIP:
            return {
              ...tabItem,
              status:
                founded != null ||
                owned_by_employees != null ||
                employees_with_ownership_stake != null ||
                minority_own_type != null ||
                minority_own_level != null ||
                is_minority_firm != null
                  ? TAB_STATUS_ENUM.SUCCESS
                  : TAB_STATUS_ENUM.DEFAULT,
            }
          case LeftNavExFirmEnum.FIRM_DIVERSITY_INCLUSION:
            const isMinorityOwnedFirm = form.getFieldValue('is_minority_or_woman_owned_firm')
            return {
              ...tabItem,
              status: isMinorityOwnedFirm !== null ? TAB_STATUS_ENUM.SUCCESS : TAB_STATUS_ENUM.DEFAULT,
            }
          case LeftNavExFirmEnum.CO_INVESTMENT:
            return {
              ...tabItem,
              status: offer_coinvestments != null ? TAB_STATUS_ENUM.SUCCESS : TAB_STATUS_ENUM.DEFAULT,
            }
          case LeftNavExFirmEnum.FIRM_ESG_POLICIES:
            return {
              ...tabItem,
              status: is_firm_esg != null || future != null ? TAB_STATUS_ENUM.SUCCESS : TAB_STATUS_ENUM.DEFAULT,
            }
          case LeftNavExFirmEnum.FIRM_CMIC:
            return {
              ...tabItem,
              status:
                firmCMICData && firmCMICData?.responses.length > 0 ? TAB_STATUS_ENUM.SUCCESS : TAB_STATUS_ENUM.DEFAULT,
            }
          case LeftNavExFirmEnum.FIRM_MULTI_MANAGER_POD_PLATFORM:
            return {
              ...tabItem,
              status: TAB_STATUS_ENUM.SUCCESS, // TODO: update status
            }
          default:
            return tabItem
        }
      })
    })
  }, [
    denomination,
    registration,
    sec_registered,
    iard_crd_number,
    sec_number,
    form_non_sec_country,
    non_sec_reg_auth,
    non_sec_reg_auth_type,
    non_sec_reg_num,
    contact_name1,
    contact_address1,
    contact_city,
    contact_state,
    contact_postal_code,
    country,
    contact_phone,
    contact_fax,
    contact_email,
    contact_web,
    contact_description,
    founded,
    owned_by_employees,
    employees_with_ownership_stake,
    minority_own_type,
    minority_own_level,
    is_minority_firm,
    dataFirmDetailSubmitRef,
    dataFirmMinority?.firm_minority_statuses,
    offer_coinvestments,
    is_firm_esg,
    future,
    firmCMICData,
    dataFirmDetail,
    dataChanged,
  ])

  useEffect(() => {
    checkFields()
  }, [checkFields])

  const renderTabContent = (selectedTab: LeftNavExFirmEnum | LeftNavExFundEnum) => {
    switch (selectedTab) {
      case LeftNavExFirmEnum.ASSETS_UNDER_MANAGEMENT:
        return (
          <AUM
            dataFirmAsset={dataFirmAsset}
            firmAssetsDataChange={firmAssetsDataChange}
            loadingFirmAsset={loadingFirmAsset}
            submittingFirmAsset={submittingFirmAsset}
            onUpdateRORData={setFirmAssetsDataChange}
            dataFirmAssetHF={dataFirmAssetHF}
            loadingFirmAssetHF={loadingFirmAssetHF}
            firmAssetsHFDataChange={firmAssetsHFDataChange}
            submittingFirmAssetHF={submittingFirmAssetHF}
            onUpdateHFData={setFirmAssetsHFDataChange}
          />
        )
      case LeftNavExFirmEnum.CONTACT_INFORMATION:
        return <ContactInformation />
      case LeftNavExFirmEnum.CO_INVESTMENT:
        return <FirmCoInvestments form={form} />
      case LeftNavExFirmEnum.OWNERSHIP:
        return <FirmOwnership form={form} />
      case LeftNavExFirmEnum.REGISTRATION:
        return <FirmRegistration form={form} />
      case LeftNavExFirmEnum.FIRM_ESG_POLICIES:
        return <FirmESGPolicies form={form} modeView={MODE_ESG_VIEW.EXTERNAL} />
      case LeftNavExFirmEnum.FIRM_DIVERSITY_INCLUSION:
        return (
          <ExFirmDI
            setDataFirmDetailSubmit={setDataFirmDetailSubmit}
            form={form}
            minorityTypes={minorityTypes}
            firmCountry={firmCountry}
          />
        )
      case LeftNavExFirmEnum.FIRM_CMIC:
        return <FirmCMIC form={form} initialData={firmCMICData?.responses} data={firmCMICDataChange} />
      case LeftNavExFirmEnum.FIRM_MULTI_MANAGER_POD_PLATFORM:
        return (
          <FirmMultiManagerPodPlatform
            isFirmUpdated={isUpdated}
            form={form}
            onChangeAll={setMultiManagerPlatformChange}
            firmCMICData={firmCMICData}
            multiManagerPlatform={multiManagerPlatform}
            loadingCMICData={loadingCMICData}
            loadingMultiManagerPlatform={loadingMultiManagerPlatform}
          />
        )
      case LeftNavExFirmEnum.FUND_FAMILY:
        return <ExFirmFundFamily />
      default:
        break
    }
  }

  const debouceRef = useRef<any>(null)
  const setDataFirmDetailSubmit = useCallback(
    (
      action: SetStateAction<
        Partial<
          FirmDetails &
            FirmMoreField & {
              _firmAssetsDataChange: UpdateRORType
              _firmAssetsHFDataChange: UpdateRORType
              _firmCMICDataChange: FundCCMCResponse[]
            }
        >
      >,
    ) => {
      if (dataFirmDetail && dataFirmDetailSubmitRef?.current) {
        dataFirmDetailSubmitRef.current = typeof action === 'object' ? action : action(dataFirmDetailSubmitRef?.current)

        Object.keys(dataFirmDetailSubmitRef.current).forEach((key) => {
          if (dataFirmDetailSubmitRef.current[key as keyof Partial<FirmDetails>] === undefined) {
            delete dataFirmDetailSubmitRef.current[key as keyof Partial<FirmDetails>]
          }
        })

        if (debouceRef.current) {
          clearTimeout(debouceRef.current)
        }

        debouceRef.current = setTimeout(() => {
          dataFirmDetail &&
            saveSnapshot('exFirmDraft', {
              id: dataFirmDetail.firm_id,
              name: dataFirmDetail.firm_name,
              data:
                Object.keys(dataFirmDetailSubmitRef?.current).length > 0 ? dataFirmDetailSubmitRef?.current : undefined,
            })
          setDataChanged((prev) => prev + 1)
        }, 300)
      }
    },
    [dataFirmDetail, dataFirmDetailSubmitRef, saveSnapshot],
  )

  React.useEffect(() => {
    setDataFirmDetailSubmit((prevState) => ({
      ...prevState,
      _firmAssetsDataChange: firmAssetsDataChange.values ? firmAssetsDataChange : undefined,
      _firmAssetsHFDataChange: firmAssetsHFDataChange.values ? firmAssetsHFDataChange : undefined,
      _firmCMICDataChange: firmCMICDataChange && firmCMICDataChange.length > 0 ? firmCMICDataChange : undefined,
      _multiManagerPlatformChange:
        multiManagerPlatformChange && multiManagerPlatformChange.length > 0 ? multiManagerPlatformChange : undefined,
    }))
  }, [
    firmAssetsDataChange,
    firmAssetsHFDataChange,
    firmCMICDataChange,
    multiManagerPlatformChange,
    setDataFirmDetailSubmit,
  ])

  const initialDataForm = dataFirmDetail && {
    ...dataFirmDetail,
    firm_asset_date: dataFirmDetail.firm_asset_date ? moment(dataFirmDetail.firm_asset_date) : null,
  }

  const handleSearch = (value: string) => {
    settextFilterSearch(value)
    const newOption = renderSearchListExFirm.filter(
      (item) => item.name.toUpperCase().indexOf(value.toUpperCase()) !== -1,
    )
    setOptionFilter(newOption)
    setOptionShow(newOption)
    if (newOption.findIndex((item) => item.leftNav === selectedTab) === -1 && newOption.length > 0) {
      dataFirmDetail &&
        dispatch(
          updateSelectedNav({ id: `${PREFIX_EXTERNAL}-${dataFirmDetail.firm_id}`, activeNav: newOption[0].leftNav }),
        )
    }
  }

  const handleCancel = () => {
    if (dataFirmDetail) {
      refetch()
      setFirmAssetsDataChange({ values: undefined })
      setFirmAssetsHFDataChange({ values: undefined })
      setFirmCMICDataChange(undefined)
      setMultiManagerPlatformChange(undefined)
      form.resetFields()
      setDataFirmDetailSubmit({})
      setIsUpdated(false)
      clearSnapshot('exFirmDraft', dataFirmDetail.firm_id)
      setDataChanged(1)
    }
  }

  const handleSubmit = async () => {
    if (dataFirmDetail && dataFirmDetailSubmitRef?.current) {
      if (firmAssetsDataChange.values) {
        try {
          setSubmittingFirmAsset(true)
          await APIService.firmDetailService.updateFirmAssets(
            dataFirmDetail.firm_id,
            JSON.parse(JSON.stringify(firmAssetsDataChange)),
          )
          notification.success({ message: 'Total Firm Assets Updated' })
          getFirmAssets(dataFirmDetail.firm_id)
          setFirmAssetsDataChange({ values: undefined })
          setIsUpdated(false)
        } catch (error) {
          notification.error({ message: error.response.statusText })
        } finally {
          setSubmittingFirmAsset(false)
        }
      }

      if (firmAssetsHFDataChange.values) {
        try {
          setSubmittingFirmAssetHF(true)
          await APIService.firmDetailService.updateFirmAssetsHedgeFund(
            dataFirmDetail.firm_id,
            JSON.parse(JSON.stringify(firmAssetsHFDataChange)),
          )
          notification.success({ message: 'Total Firm Assets Dedicated To Hedge Fund Updated' })
          getFirmAssetsHF(dataFirmDetail.firm_id)
          setFirmAssetsHFDataChange({ values: undefined })
          setIsUpdated(false)
        } catch (error) {
          notification.error({ message: error.response.statusText })
        } finally {
          setSubmittingFirmAssetHF(false)
        }
      }
      if (firmCMICDataChange && firmCMICDataChange.length > 0) {
        try {
          await APIService.firmDetailService.updateFirmCMIC(
            dataFirmDetail.firm_id,
            firmCMICDataChange.map((item) => ({ ...item, other_measures: item.other_measures || '' })),
          )
          notification.success({ message: 'Firm CMIC Updated' })
          getFirmCMIC(dataFirmDetail.firm_id)
          setFirmCMICDataChange(undefined)
          setIsUpdated(false)
        } catch (error) {
          notification.error({ message: error.response.statusText })
        } finally {
        }
      }
      if (multiManagerPlatformChange && multiManagerPlatformChange.length > 0) {
        try {
          await APIService.firmDetailService.updateMultiManagerPlatform(dataFirmDetail.firm_id, {
            updates: multiManagerPlatformChange,
          })
          notification.success({ message: 'Multi Manager Platform Updated' })
          getMultiManagerPlatform(dataFirmDetail.firm_id)
          setMultiManagerPlatformChange(undefined)
          setIsUpdated(false)
        } catch (error) {
          notification.error({ message: error.response.statusText })
        } finally {
        }
      }

      const fieldChange: Partial<FirmDetails & FirmMoreField> = Object.keys(dataFirmDetailSubmitRef?.current)
        .filter(
          (key) =>
            dataFirmDetailSubmitRef?.current[key as keyof FirmDetails] !== dataFirmDetail[key as keyof FirmDetails],
        )
        .reduce((accum, key) => {
          const value = dataFirmDetailSubmitRef?.current[key as keyof FirmDetails]
          if (EXFIRM_DATE_FIELDS.includes(key) && value) {
            return { ...accum, [key]: moment(value as string).format('YYYY-MM-DD') }
          }

          // is_minority_firm === false -> update minority_own_type, minority_own_level to null
          // actually reset value to sumbit to the server
          if (key === 'is_minority_firm' && !value) {
            return {
              ...accum,
              is_minority_firm: false,
              minority_own_type: null,
              minority_own_level: null,
            }
          }

          return {
            ...accum,
            [key]: dataFirmDetailSubmitRef?.current[key as keyof FirmDetails],
          }
        }, {})

      // Update minority types
      const dataMinority = Object.entries(fieldChange).filter((item) => item[0].includes('minority_type_'))

      // extract minority types out of field changes
      const dataChange = Object.entries(fieldChange)
        .filter((item) => !item[0].includes('minority_type_'))
        .reduce((acc, item) => {
          return {
            ...acc,
            [item[0]]: item[1],
          }
        }, {})

      const minorityChange = {
        updates: dataMinority.map((item) => {
          return {
            minority_type: +item[0].replace('minority_type_', ''),
            status: item[1] as boolean,
          }
        }),
      }

      if (minorityChange && minorityChange.updates.length > 0 && dataFirmDetail) {
        APIService.minorityService
          .updateFirmMinorityStatuses(dataFirmDetail.firm_id, minorityChange)
          .then(() => {
            notification.success({ message: 'Diversity & Inclusion Updated!' })
            getFirmMinority(dataFirmDetail.firm_id)
          })
          .catch((err) => {
            console.error({ err })
            notification.error({ message: 'Update Failed!' })
          })
          .finally(() => {
            setLoadingSubmitForm(false)
          })
      }

      if (Object.keys(dataChange).length > 0 && dataFirmDetail.firm_id) {
        setLoadingSubmitForm(true)
        APIService.firmDetailService
          .updateFirmInfo(dataFirmDetail.firm_id, dataChange)
          .then(() => {
            notification.success({ message: 'Firm Updated!' })
            refetch()
            setDataFirmDetailSubmit({})
            setShouldUpdate(true)
            setIsUpdated(false)
            clearSnapshot('exFirmDraft', dataFirmDetail.firm_id)
          })
          .catch((err) => {
            console.error({ err })
            notification.error({ message: 'Update Failed!' })
          })
          .finally(() => {
            setLoadingSubmitForm(false)
          })
      } else {
        setIsUpdated(false)
        clearSnapshot('exFirmDraft', dataFirmDetail.firm_id)
      }
    }
  }

  return (
    <TabContentWrapper style={{ position: 'relative', background: 'white', height: '100%' }}>
      {dataFirmDetail && firmCMICData && multiManagerPlatform && (
        <StyledForm
          form={form}
          initialValues={{
            ...initialDataForm,
            _firmCMICDataChange: firmCMICData.responses.reduce((acc, curr) => {
              return {
                ...acc,
                [curr.fund_id]: {
                  has_securities: curr.has_securities,
                  manager_confirms: curr.manager_confirms,
                  separate_share_classes: curr.separate_share_classes,
                  pre_trade_compliance_restrictions: curr.pre_trade_compliance_restrictions,
                  other_measures: curr.other_measures,
                  subscription_notice: curr.subscription_notice,
                },
              }
            }, {}),
            _multiManagerPlatformChange: multiManagerPlatform.funds.reduce((prev, cur) => {
              return {
                ...prev,
                [cur.fund_id]: cur,
              }
            }, {} as Record<string, MultiManagerPlatformType>),
          }}
          // prevent enter submitting the form
          onKeyPress={(event) => event.which === 13 && event.preventDefault()}
          onFinish={handleSubmit}
          onFieldsChange={() => setIsUpdated(true)}
          onValuesChange={(changedValues, allValues) => {
            if (!changedValues._firmCMICDataChange) {
              const convertObject = Object.keys(changedValues).reduce((acc, key) => {
                //format special field
                return { ...acc, [key]: changedValues[key] !== undefined ? changedValues[key] : null }
              }, {})
              setDataFirmDetailSubmit({ ...dataFirmDetailSubmitRef?.current, ...convertObject })
            }

            const cmicChanges = allValues._firmCMICDataChange

            // Logic to remove manager_confirms data if the corresponding has_securities is No/Does Not Apply
            changedValues._firmCMICDataChange &&
              Object.keys(changedValues._firmCMICDataChange).map((item) => {
                const firmCMICDataChange = {
                  ...changedValues._firmCMICDataChange,
                  [item]: {
                    ...changedValues._firmCMICDataChange[item],
                    manager_confirms:
                      cmicChanges[item].has_securities !== 1 ? null : cmicChanges[item].manager_confirms,
                    separate_share_classes:
                      cmicChanges[item].has_securities === 2 ? null : cmicChanges[item].separate_share_classes,
                    pre_trade_compliance_restrictions:
                      cmicChanges[item].has_securities === 2
                        ? null
                        : cmicChanges[item].pre_trade_compliance_restrictions,
                    other_measures: cmicChanges[item].has_securities === 2 ? null : cmicChanges[item].other_measures,
                  },
                }
                form.setFieldsValue({
                  _firmCMICDataChange: firmCMICDataChange,
                })
              })

            changedValues._firmCCMCDataChange &&
              Object.keys(cmicChanges).length > 0 &&
              setFirmCMICDataChange(
                Object.keys(cmicChanges).map((fundId) => {
                  return {
                    fund_id: fundId,
                    ...cmicChanges[fundId],
                    manager_confirms: !cmicChanges[fundId].has_securities ? null : cmicChanges[fundId].manager_confirms,
                  }
                }),
              )
            // Multi Manager Platform
            const MMPChange = allValues._multiManagerPlatformChange
            if (changedValues._multiManagerPlatformChange) {
              const multiManagerPlatformChangeKeys = Object.keys(MMPChange)
              setMultiManagerPlatformChange(
                multiManagerPlatformChangeKeys.map((fundId) => {
                  return {
                    fund_id: fundId,
                    multi_manager_platform: MMPChange[fundId].multi_manager_platform,
                  } as MultiManagerPlatformType
                }),
              )
            }
          }}
        >
          <StyledCard
            title={
              <Row style={{ marginTop: 8, flexWrap: 'wrap' }} justify="space-between" align="middle">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <TextID>Firm ID:{dataFirmDetail.firm_id}</TextID>
                  <Text
                    style={{
                      fontSize: 18,
                      fontWeight: 'bold',
                      color: '#484848',
                      whiteSpace: 'normal',
                    }}
                  >
                    {isUpdated ? (
                      <Text style={{ fontSize: 18, color: '#b58742', fontWeight: 'bold' }}>(Edited)</Text>
                    ) : (
                      ''
                    )}
                    {dataFirmDetail.firm_name}
                    {/* MontLake Q Brands Premiere Palm-Tree Alpha Equity UCITS Fund - CHF Core Class Pooled Accumulating */}
                  </Text>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flex: 1,
                    alignItems: 'center',
                    margin: '8px 0',
                  }}
                >
                  <Space>
                    <AutoComplete
                      onSelect={(value, options) => {
                        if (options.label) {
                          const text = options.label.toLocaleString()
                          settextFilterSearch(text)
                          const newOption = renderSearchListExFirm.filter(
                            (item) => item.name.toUpperCase().indexOf(text.toUpperCase()) !== -1,
                          )
                          setOptionShow(newOption)
                          const saveOption = newOption.filter((item) => item.key === value)
                          setOptionFilter(saveOption)
                          if (
                            saveOption.findIndex((item) => item.leftNav === selectedTab) === -1 &&
                            newOption.length > 0
                          ) {
                            dispatch(
                              updateSelectedNav({
                                id: `${PREFIX_EXTERNAL}-${dataFirmDetail.firm_id}`,
                                activeNav: saveOption[0].leftNav,
                              }),
                            )
                          }
                        }
                      }}
                      onSearch={handleSearch}
                      options={optionShow.map((item) => ({ value: item.key, label: item.name }))}
                      allowClear
                      value={textFilterSearch}
                      style={{ width: 200, height: 32, marginRight: 8 }}
                    >
                      <Input placeholder="Go to Field" prefix={<SearchOutlined />} />
                    </AutoComplete>
                    {isUpdated ? (
                      <Popconfirm
                        placement="bottomLeft"
                        title={'You have unsaved changes, are you sure you want to cancel?'}
                        onConfirm={handleCancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button style={{ marginRight: 8 }}>Cancel</Button>
                      </Popconfirm>
                    ) : (
                      <Button onClick={handleCancel} style={{ marginRight: 8 }}>
                        Cancel
                      </Button>
                    )}
                    <Button type="primary" htmlType="submit" loading={loadingSubmitForm} disabled={!isUpdated}>
                      Save
                    </Button>
                  </Space>
                </div>
              </Row>
            }
            size="default"
            style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <Row style={{ height: '100%' }}>
              <Col span={4} style={{ height: '100%' }}>
                <FFPerfectScrollbar>
                  {tabList.map((item) => {
                    const existTab = optionFilter.findIndex((i) => i.leftNav === item.id)
                    if (existTab === -1 && textFilterSearch !== '') {
                      return null
                    }
                    return (
                      <StyledTabList
                        key={item.id}
                        active={selectedTab === item.id}
                        onClick={() => {
                          dispatch(
                            updateSelectedNav({
                              id: `${PREFIX_EXTERNAL}-${dataFirmDetail.firm_id}`,
                              activeNav: item.id,
                            }),
                          )
                        }}
                      >
                        <div style={{ position: 'absolute', left: '1.5rem' }}>
                          {item.status === TAB_STATUS_ENUM.SUCCESS && <CheckCircleTwoTone twoToneColor="#52c41a" />}
                        </div>
                        {item.id}
                      </StyledTabList>
                    )
                  })}
                </FFPerfectScrollbar>
              </Col>
              <Col span={20} style={{ height: '100%' }}>
                <FFPerfectScrollbar>
                  <ExFirmDetailLabelSearchProvider text={textFilterSearch} filterArr={optionFilter}>
                    {optionFilter.length === 0 ? (
                      <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                        <Empty />
                      </div>
                    ) : (
                      renderTabContent(selectedTab)
                    )}
                  </ExFirmDetailLabelSearchProvider>
                </FFPerfectScrollbar>
              </Col>
            </Row>
          </StyledCard>
        </StyledForm>
      )}
      {loading && <LoadingDetails name="Loading Firm" />}
    </TabContentWrapper>
  )
}

export default MyFirm
