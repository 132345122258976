import { Button, Col, Collapse, notification, Row, Select } from 'antd'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../../../../shared/api'
import { FundDetailDataContext } from '../../../../../shared/api/context/FundDetailDataContext'
import { NextBackButton } from '../../../../../shared/components/NextBackButton'
import { useServiceState } from '../../../../../shared/hooks/useServiceState'
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  margin: 1rem;
  flex-direction: column;
`

type Props = {
  dataDropdown: string[]
}

export const FundInfoElectronicTrading: React.FC<Props> = ({ dataDropdown }) => {
  const { dataFundDetail } = React.useContext(FundDetailDataContext)

  const [isChangePlatform, setIsChangePlatform] = React.useState(false)

  const { invoke: fetchListPlatform, data: dataListPlatform, loading } = useServiceState(
    APIService.fundDetailService.fetchListPlatform,
  )

  const [listPlatform, setListPlatform] = React.useState<string[]>([])
  const [updateLoading, setUpdateLoading] = React.useState(false)

  React.useEffect(() => {
    if (!dataListPlatform) {
      return
    }
    dataListPlatform.platforms && setListPlatform(dataListPlatform.platforms)
  }, [dataListPlatform])

  React.useEffect(() => {
    if (!dataFundDetail) {
      return
    }
    dataFundDetail.fund_id && fetchListPlatform(dataFundDetail.fund_id)
  }, [dataFundDetail, fetchListPlatform])

  const handleUpdatePlatform = () => {
    if (dataFundDetail?.fund_id) {
      setUpdateLoading(true)
      APIService.fundDetailService
        .updateListPlatform(dataFundDetail?.fund_id, listPlatform)
        .then(() => {
          notification.success({ message: 'List Platform Updated!' })
          setIsChangePlatform(false)
        })
        .catch((err) => {
          console.error({ err })
          notification.error({ message: 'Update Fail!' })
        })
        .finally(() => {
          setUpdateLoading(false)
        })
    }
  }

  const handleReset = () => {
    if (dataListPlatform?.platforms) {
      setListPlatform(dataListPlatform.platforms)
      setIsChangePlatform(false)
    }
  }

  return (
    <Wrapper>
      <Collapse style={{ margin: '0 0.5rem' }} defaultActiveKey="1">
        <Collapse.Panel header="Electronic Trading Platform" key="1">
          <Row>
            <Col span={16} xl={16} lg={16} xs={24} offset={3} style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                <div style={{ display: 'flex' }}>Electronic Trading Platform :</div>
                <div>
                  <Button
                    style={{ marginRight: '0.5rem' }}
                    type="primary"
                    onClick={handleUpdatePlatform}
                    loading={updateLoading}
                    disabled={!isChangePlatform}
                  >
                    Update
                  </Button>

                  <Button onClick={handleReset} type="link" disabled={!isChangePlatform}>
                    Reset
                  </Button>
                </div>
              </div>

              <Select
                mode="multiple"
                loading={loading}
                style={{ flex: 1 }}
                value={listPlatform}
                onChange={(e) => {
                  setListPlatform(e)
                  setIsChangePlatform(true)
                }}
              >
                {dataDropdown.map((item) => {
                  return (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  )
                })}
              </Select>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
      <NextBackButton />
    </Wrapper>
  )
}
