import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FundDetailDataContext } from '../../../../shared/api/context/FundDetailDataContext'
import { FundFamilyTable, FundFamilyType } from '../../../../shared/components/FundFamilyTable'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../app/rootReducer'
import APIService from '../../../../shared/api'
import { useServiceState } from '../../../../shared/hooks/useServiceState'

const Flex = styled.div`
  flex: 1;
  display: flex;
`

type Props = {}
export const FundFamily: React.FC<Props> = () => {
  const { dataFundDetail, loading, getFundDetail, fundDataDropdown } = React.useContext(FundDetailDataContext)

  const { strategies: strategyOptions, subStrategies: subStrategyOptions } = useSelector(
    (state: RootState) => state.appData,
  )
  const { invoke: getFundOfFirm, data: dataFundsFirm, loading: loadingFundOfFirms } = useServiceState(
    APIService.firmDetailService.fetchFundsOfFirm,
  )
  //
  useEffect(() => {
    if (!dataFundDetail) {
      return
    }
    getFundOfFirm(dataFundDetail.firm_id)
  }, [dataFundDetail])
  ///////////////////////
  // map fund family data
  ///////////////////////
  const fundFamilyData = React.useMemo<FundFamilyType[]>(() => {
    if (!dataFundDetail || !dataFundsFirm || !fundDataDropdown || !strategyOptions || !subStrategyOptions) {
      return []
    }
    // get fund with the same fund family id
    const sameFamilyFunds = dataFundsFirm.filter((f) => {
      if (!dataFundDetail.fund_family_id) {
        return false
      }
      return (
        `${f.fund_id}` !== `${dataFundDetail.fund_id}` && `${f.fund_family_id}` === `${dataFundDetail.fund_family_id}`
      )
    })
    //
    const results = sameFamilyFunds.map((d) => {
      //
      const stategy_op = d.strategy_code ? strategyOptions.find((s) => s.strategy_code === d.strategy_code) : undefined
      const stategy_code = stategy_op?.strategy_name || ''
      //
      const sub_stategy_op = d.sub_strategy_code
        ? subStrategyOptions.find((s) => s.sub_strategy_code === d.sub_strategy_code)
        : undefined
      const sub_stategy_code = sub_stategy_op?.sub_strategy_name || ''
      //
      const region_inv_focus_op = d.region_inv_focus
        ? fundDataDropdown.region_inv_focus.find((s) => s.code_code === `${d.region_inv_focus}`)
        : undefined
      const region_inv_focus = region_inv_focus_op?.code_description || ''
      //
      const region_inv_focus_country_op = d.region_inv_focus_country
        ? fundDataDropdown.region_inv_focus_country.find((s) => s.code_code === `${d.region_inv_focus_country}`)
        : undefined
      const region_inv_focus_country = region_inv_focus_country_op?.code_description || ''
      //
      return {
        fund_id: d.fund_id,
        fund_name: d.fund_name,
        firm_id: d.firm_id,
        firm_name: d.firm_name,
        fund_family_id: d.fund_family_id,
        fund_family_code: d.fund_family_code || '',
        strategy_code: stategy_code,
        sub_strategy_code: sub_stategy_code,
        region_inv_focus,
        region_inv_focus_country,
      } as FundFamilyType
    })
    // get data from data fund detail
    const stategy_op = dataFundDetail.strategy_code_new
      ? strategyOptions.find((s) => s.strategy_code === dataFundDetail.strategy_code_new)
      : null
    const stategy_code = stategy_op?.strategy_name
    //
    const sub_stategy_op = dataFundDetail.sub_strategy_code_new
      ? subStrategyOptions.find((s) => s.sub_strategy_code === dataFundDetail.sub_strategy_code_new)
      : null
    const sub_stategy_code = sub_stategy_op?.sub_strategy_name
    //
    const region_inv_focus_op = dataFundDetail.region_inv_focus
      ? fundDataDropdown.region_inv_focus.find((s) => s.code_code === dataFundDetail.region_inv_focus)
      : null
    const region_inv_focus = region_inv_focus_op?.code_description
    //
    const region_inv_focus_country_op = dataFundDetail.region_inv_focus_country
      ? fundDataDropdown.region_inv_focus_country.find((s) => s.code_code === dataFundDetail.region_inv_focus_country)
      : null
    const region_inv_focus_country = region_inv_focus_country_op?.code_description
    //
    return [
      {
        fund_id: dataFundDetail.fund_id,
        fund_name: dataFundDetail.fund_name,
        firm_id: dataFundDetail.firm_id,
        firm_name: dataFundDetail.firm_name,
        fund_family_id: dataFundDetail.fund_family_id,
        fund_family_code: dataFundDetail.fund_family_code,
        strategy_code: stategy_code,
        sub_strategy_code: sub_stategy_code,
        region_inv_focus,
        region_inv_focus_country,
      } as FundFamilyType,
      ...results,
    ]
  }, [dataFundDetail, dataFundsFirm, strategyOptions, subStrategyOptions, fundDataDropdown])
  ///////////////////

  const handleRefetch = () => {
    getFundDetail()
  }

  return (
    <Flex style={{ flexDirection: 'column' }}>
      <FundFamilyTable
        variant="fund"
        editable
        isLoading={loading || loadingFundOfFirms}
        fundFamilyData={fundFamilyData}
        onRefecth={handleRefetch}
      />
    </Flex>
  )
}
