import React, { Dispatch, SetStateAction } from 'react'
import APIService from '..'
import { UpdateRORType } from '../../components/DataSheet'
import { useServiceState } from '../../hooks/useServiceState'
import { FundDropdownType } from '../models/FundDetails'
export enum DUE_DILIGENCE_REQUEST_ENUM {
  DUE_DILIGENCE_MAIN,
  DUE_DILIGENCE_LEGAL_CHECKLIST,
  DUE_DILIGENCE_OLD_CHECKLIST,
  DUE_DILIGENCE_INVESTOR,
}

export enum PERFORMANCE_REQUEST_ENUM {
  ROR,
  ASSET,
  NAV,
}

export type ExNewFundContextType = {
  //ROR
  setRORDataChange: Dispatch<SetStateAction<UpdateRORType>>
  rorDataChange: UpdateRORType
  //Asset
  setAssetDataChange: Dispatch<SetStateAction<UpdateRORType>>
  assetDataChange: UpdateRORType
  //Nav
  setNAVDataChange: Dispatch<SetStateAction<UpdateRORType>>
  navDataChange: UpdateRORType
  fundDataDropdown?: FundDropdownType
}

const defaultValue: ExNewFundContextType = {
  //ROR
  setRORDataChange: () => undefined,
  rorDataChange: { values: undefined },
  //Asset
  setAssetDataChange: () => undefined,
  assetDataChange: { values: undefined },
  //NAV
  setNAVDataChange: () => undefined,
  navDataChange: { values: undefined },
  fundDataDropdown: undefined,
}

export const ExNewFundContext = React.createContext<ExNewFundContextType>(defaultValue)
export const ExNewFundProvider: React.FC = ({ children }) => {
  const [rorDataChange, setRORDataChange] = React.useState<UpdateRORType>({ values: undefined })
  const [assetDataChange, setAssetDataChange] = React.useState<UpdateRORType>({ values: undefined })
  const [navDataChange, setNAVDataChange] = React.useState<UpdateRORType>({ values: undefined })

  const { invoke: getDataDropdown, data: fundDataDropdown } = useServiceState(
    APIService.fundDetailService.fetchDataDropdown,
  )

  React.useEffect(() => {
    getDataDropdown()
  }, [getDataDropdown])

  const value = {
    //ROR

    rorDataChange,
    setRORDataChange,
    //Asset
    setAssetDataChange,
    assetDataChange,

    //Nav
    setNAVDataChange,
    navDataChange,
    fundDataDropdown,
  }
  return <ExNewFundContext.Provider value={value}>{children}</ExNewFundContext.Provider>
}
