import { AxiosInstance, AxiosPromise } from 'axios'
import { FirmParams, FirmSearchColumnResponse, FirmSearchResponse } from '../models/FirmDetails'

export interface FirmService {
  searchFirms: (params: Partial<FirmParams>) => AxiosPromise<FirmSearchResponse>
  fetchAllColumns: () => AxiosPromise<FirmSearchColumnResponse>
}

export const FirmService = (request: AxiosInstance): FirmService => {
  return {
    searchFirms: (params) => request.post(`/manager-link/firms/search`, params),
    fetchAllColumns: () => request.get(`/manager-link/firms/search/fields-metadata`),
  }
}
