import { Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import { FundDetailDataContext, PERFORMANCE_REQUEST_ENUM } from '../../../../shared/api/context/FundDetailDataContext'
import DataSheet, { DataSheetType } from '../../../../shared/components/DataSheet'
type Props = {
  form: FormInstance
}

export const ExPerformanceAssetsBTC: React.FC<Props> = ({ form }) => {
  const {
    setAssetDataChange,
    dataAssetLoading,
    dataAsset,
    getPerformanceRequest,
    assetDataChange,
    dataFundDetail,
  } = React.useContext(FundDetailDataContext)

  React.useEffect(() => {
    getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.ASSET)
  }, [getPerformanceRequest])
  const isFundActive = form.getFieldValue('fund_status_code') === '2'

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <DataSheet
        currencyValue={dataFundDetail?.denomination ? dataFundDetail.denomination : undefined}
        // isReadOnly={fundStatusField ? true : undefined}
        minDate={dataFundDetail?.inception_date ? dataFundDetail.inception_date : undefined}
        type={DataSheetType.ASSET}
        draft={assetDataChange.values}
        initialData={dataAsset}
        canAdd
        loading={dataAssetLoading}
        setRORDataChange={setAssetDataChange}
        isExternal
      />
      {isFundActive && (
        <Row style={{ marginTop: 8 }}>
          <div style={{ fontStyle: 'italic' }}>
            <b>Note:</b> Contact HFR at <a href="mailto:performance@hfr.com">performance@hfr.com</a> if any questions.
          </div>
        </Row>
      )}
    </div>
  )
}
