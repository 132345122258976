import { Col, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { FirmDetailDataContext } from '../../../shared/api/context/FirmDetailDataContext'
import { FundDetailDropdownContext } from '../../../shared/api/context/FundDetailDropdownContext'
import DataSheet, { DataSheetType, RORResponseType, UpdateRORType } from '../../../shared/components/DataSheet'
import { DividerForm } from '../../../shared/components/DividerForm'
import { FormItemSelect } from '../../../shared/components/FormItemComponents'
import { RenderLabelExFirm } from '../../../shared/components/RenderLabelExFirm'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 0 1rem;
  margin-top: 1rem;
  flex-direction: column;
`
type Props = {
  dataFirmAsset?: RORResponseType[]
  loadingFirmAsset: boolean
  firmAssetsDataChange: UpdateRORType
  submittingFirmAsset: boolean
  onUpdateRORData?: (value: UpdateRORType) => void

  dataFirmAssetHF?: RORResponseType[]
  loadingFirmAssetHF: boolean
  firmAssetsHFDataChange: UpdateRORType
  submittingFirmAssetHF: boolean
  onUpdateHFData?: (value: UpdateRORType) => void
}

const AUM: React.FC<Props> = ({
  dataFirmAsset,
  loadingFirmAsset,
  submittingFirmAsset,
  onUpdateRORData,
  firmAssetsDataChange,
  dataFirmAssetHF,
  loadingFirmAssetHF,
  firmAssetsHFDataChange,
  submittingFirmAssetHF,
  onUpdateHFData,
}) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)
  const { dataFirmDetail } = React.useContext(FirmDetailDataContext)

  return (
    <Wrapper>
      <Row>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemSelect
            name="denomination"
            labelCol={12}
            wrapperCol={12}
            label={<RenderLabelExFirm value="Denomination" />}
            options={
              fundDataDropdown?.denomination && fundDataDropdown.denomination.length > 0
                ? fundDataDropdown.denomination.map((item) => ({
                    value: item.code_code,
                    text: item.code_description,
                  }))
                : []
            }
            disabled
          />
        </Col>
      </Row>
      <DividerForm text="Total Firm Assets (MM)" />
      <Row style={{ padding: '1rem', display: 'flex', flexDirection: 'column' }}>
        <DataSheet
          currencyValue={dataFirmDetail?.denomination ? dataFirmDetail.denomination : undefined}
          type={DataSheetType.FIRM_ASSETS}
          draft={firmAssetsDataChange.values}
          initialData={dataFirmAsset}
          loading={loadingFirmAsset || submittingFirmAsset}
          minDate={dataFirmDetail?.founded ? `${dataFirmDetail.founded}-01` : '2016-01'}
          canAdd
          setRORDataChange={onUpdateRORData}
          isExternal={true}
        />
      </Row>
      <DividerForm text="Total Firm Assets Dedicated To Hedge Funds (MM)" />
      <Row style={{ padding: '1rem', display: 'flex', flexDirection: 'column' }}>
        <DataSheet
          currencyValue={dataFirmDetail?.denomination ? dataFirmDetail.denomination : undefined}
          type={DataSheetType.FIRM_ASSETS_HF}
          draft={firmAssetsHFDataChange.values}
          initialData={dataFirmAssetHF}
          loading={loadingFirmAssetHF || submittingFirmAssetHF}
          minDate={dataFirmDetail?.founded ? `${dataFirmDetail.founded}-01` : '2016-01'}
          canAdd
          setRORDataChange={onUpdateHFData}
          isExternal={true}
        />
      </Row>
    </Wrapper>
  )
}

export default AUM
