import { MailOutlined, NumberOutlined } from '@ant-design/icons'
import { Button, Divider, Form, Input, notification } from 'antd'
import { Store } from 'antd/lib/form/interface'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import APIService from '../../../shared/api'

const SignUpContainer = styled.div`
  background: white;
  width: 30%;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: safe center;
  align-items: center;
  padding: 0 40px;
  overflow-y: auto;
  form {
    margin: auto;
    padding: 40px 0;
    width: 100%;
  }
`

const Inline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div {
    flex-grow: 1;
    margin-right: 10px;
  }
`

type Props = {
  nextStep: () => void
  setVerificationCode: (code: string) => void
  setEmail: (email?: string) => void
}

export const PreSignUpForm: React.FC<Props> = ({ nextStep, setVerificationCode, setEmail }) => {
  const [verifyingCode, setVerifyingCode] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const handleSubmit = (e: Store) => {
    // e.preventDefault()
    setLoading(true)
    if (!verifyingCode) {
      APIService.authService
        .preSignUp(e.email)
        .then(() => {
          notification.success({ message: 'Check your email for registration code!' })
          setEmail(e.email)
          setVerifyingCode(true)
        })
        .catch((err) => {
          console.error(err)
          if (err.response.data.error_code === 'EMAIL_ALREADY_EXISTS') {
            notification.info({ message: `${err.response.data.message} Please go back to login to continue!` })
            return
          }
          notification.error({ message: err.response.data.message })
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      APIService.authService
        .checkCode(e.email, e.code.trim())
        .then(() => {
          setVerificationCode(e.code.trim())
          nextStep()
          setVerifyingCode(false)
        })
        .catch((err) => {
          console.error(err)
          notification.error({ message: err.response.data.message })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <SignUpContainer>
      <Form onFinish={handleSubmit} className="login-form" layout={'horizontal'}>
        <Divider orientation={'left'}>Email to receive registration code</Divider>
        <Inline>
          <Form.Item
            rules={[
              { required: true, message: 'Email is required' },
              { type: 'email', message: 'Email must be valid' },
            ]}
            name="email"
          >
            <Input
              formNoValidate={true}
              prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="email"
              placeholder="Email"
            />
          </Form.Item>
        </Inline>
        {verifyingCode && (
          <Inline>
            <Form.Item rules={[{ required: true, message: 'Verification Code is required' }]} name="code">
              <Input
                formNoValidate={true}
                prefix={<NumberOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Verification Code"
              />
            </Form.Item>
          </Inline>
        )}
        <Inline>
          <Button loading={loading} type="primary" htmlType="submit" className="login-form-button" style={{ flex: 2 }}>
            Submit
          </Button>
          <Link to="/auth/log-in" style={{ flex: 1 }}>
            <Button type="link" style={{ width: '100%' }}>
              Back to Login
            </Button>
          </Link>
        </Inline>
      </Form>
    </SignUpContainer>
  )
}
