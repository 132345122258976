import { Button, Col, Collapse, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import moment from 'moment'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../../app/rootReducer'
import { FundDetailDataContext, PERFORMANCE_REQUEST_ENUM } from '../../../shared/api/context/FundDetailDataContext'
import { FundDetailDropdownContext } from '../../../shared/api/context/FundDetailDropdownContext'
import { Colors } from '../../../shared/colors'
import DataSheet, { DataSheetType } from '../../../shared/components/DataSheet'
import { DividerForm } from '../../../shared/components/DividerForm'
import { IndustryTable } from '../../../shared/components/IndustryTable'
import { InstrumentsTable } from '../../../shared/components/InstrumentsTable'
import { InvestorTypeTable } from '../../../shared/components/InvestorTypeTable'
import { RegionsTable } from '../../../shared/components/RegionsTable'
import { LeftNavExFirmEnum } from '../../../shared/SearchExFirmLabel'
import { LeftNavExFundEnum } from '../../../shared/SearchExFundLabel'
import { addTabs, FundFirmTypeEnum, PREFIX_EXTERNAL, updateSelectedNav } from '../../../slice/appSettingsSlice'
import { DATE_FORMAT } from '../../Dashboard/FundDetail/FundROR/PerformanceUpdate'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  margin-top: 0.5rem;
  flex-direction: column;
  padding: 0 2rem;
`
type ListOverViewType = {
  indexTab: LeftNavExFundEnum
  listField: {
    value: string
    label: string
    isDescription?: boolean
  }[]
}

type Props = { form: FormInstance; fundId: string }

export const ExFundOverview: React.FC<Props> = ({ form, fundId }) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)
  const {
    dataNAV,
    dataNAVLoading,
    setNAVDataChange,
    getPerformanceRequest,
    dataROR,
    dataRORLoading,
    setAssetDataChange,
    dataAssetLoading,
    dataAsset,
  } = React.useContext(FundDetailDataContext)
  const { dataFundDetail } = React.useContext(FundDetailDataContext)
  const appData = useSelector((state: RootState) => state.appData)

  const dispatch = useDispatch()

  const TextFieldYesNo = (value: string | undefined) => {
    if (value) {
      return value === '1' ? 'Yes' : 'No'
    }
    return 'N/A'
  }

  const LIST_OVER_VIEW: ListOverViewType[] = [
    {
      indexTab: LeftNavExFundEnum.FUND_DETAIL,
      listField: [
        // {
        //   value: form.getFieldValue('inception_date') || 'N/A',
        //   label: 'Fund Inception Date',
        // },
        // {
        //   value: form.getFieldValue('fund_assets') || 'N/A',
        //   label: 'Fund Assets',
        // },
        {
          value: form.getFieldValue('liquid_alt_type')
            ? fundDataDropdown?.liquid_alt_type.find((item) => item.code_code === form.getFieldValue('liquid_alt_type'))
                ?.code_description
            : 'N/A',
          label: 'Liquid Alternative Type',
        },
        {
          value: form.getFieldValue('liquid_alt_region')
            ? fundDataDropdown?.liquid_alt_region.find(
                (item) => item.code_code === form.getFieldValue('liquid_alt_region'),
              )?.code_description
            : 'N/A',
          label: 'Country Of Registration',
        },
        {
          value: form.getFieldValue('fund_assets') || 'N/A',
          label: 'Fund Assets',
        },
        {
          value: form.getFieldValue('fund_assets_date')
            ? moment(form.getFieldValue('fund_assets_date')).format('YYYY-MM')
            : 'N/A',
          label: 'Fund Asset As Of',
        },
        {
          value: form.getFieldValue('firm_assets') || 'N/A',
          label: 'Firm Assets',
        },
        {
          value: form.getFieldValue('firm_assets_date')
            ? moment(form.getFieldValue('firm_assets_date')).format('YYYY-MM')
            : 'N/A',
          label: 'Firm Asset As Of',
        },
        {
          value: form.getFieldValue('inception_date')
            ? moment(form.getFieldValue('inception_date')).format('YYYY-MM-DD')
            : 'N/A',
          label: 'Inception Date',
        },
        {
          value: form.getFieldValue('min_invest')
            ? form.getFieldValue('min_invest') === 'Other'
              ? form.getFieldValue('min_invest_input')
              : form.getFieldValue('min_invest')
            : 'N/A',
          label: 'Minimum Investment',
        },
        {
          value: form.getFieldValue('product_class')
            ? fundDataDropdown?.product_class.find((item) => item.code_code === form.getFieldValue('product_class'))
                ?.code_description
            : 'N/A',
          label: 'Fund Share Class',
        },
        {
          value: form.getFieldValue('add_invest')
            ? form.getFieldValue('add_invest') === 'Other'
              ? form.getFieldValue('add_invest_input')
              : form.getFieldValue('add_invest')
            : 'N/A',
          label: 'Additional Investments',
        },
        {
          value: form.getFieldValue('ucitsiii') || 'N/A',
          label: 'UCITS Complaint',
        },
        {
          value: form.getFieldValue('accepts_mgd_accts')
            ? form.getFieldValue('accepts_mgd_accts') === '1'
              ? 'Yes'
              : 'No'
            : 'N/A',
          label: 'Accepts Managed Accounts?',
        },
        {
          value:
            form.getFieldValue('accepts_new_investment') === true
              ? 'Yes'
              : form.getFieldValue('accepts_new_investment') === false
              ? 'No'
              : 'N/A',
          label: 'Open To New Investments?',
        },
        {
          value: form.getFieldValue('min_invest_indv')
            ? form.getFieldValue('min_invest_indv') === 'Other'
              ? form.getFieldValue('min_invest_indv_input')
              : form.getFieldValue('min_invest_indv')
            : 'N/A',
          label: 'Managed Account Minimum',
        },
      ],
    },
    {
      indexTab: LeftNavExFundEnum.INVESTMENT_STRATEGY,
      listField: [
        {
          value: form.getFieldValue('strategy_code_new')
            ? appData?.strategies?.find((item) => item.strategy_code === form.getFieldValue('strategy_code_new'))
                ?.strategy_name || 'N/A'
            : 'N/A',
          label: 'Investment Strategy',
        },
        {
          value: form.getFieldValue('sub_strategy_code_new')
            ? appData?.subStrategies?.find(
                (item) => item.sub_strategy_code === form.getFieldValue('sub_strategy_code_new'),
              )?.sub_strategy_name || 'N/A'
            : 'N/A',
          label: 'Sub-Strategy',
        },
        {
          value: form.getFieldValue('region_inv_focus')
            ? fundDataDropdown?.region_inv_focus.find(
                (item) => item.code_code === form.getFieldValue('region_inv_focus'),
              )?.code_description || 'N/A'
            : 'N/A',
          label: 'Regional Investment Focus',
        },
        {
          value: form.getFieldValue('region_inv_focus_country')
            ? fundDataDropdown?.region_inv_focus_country.find(
                (item) => item.code_code === form.getFieldValue('region_inv_focus_country'),
              )?.code_description || 'N/A'
            : 'N/A',
          label: 'Sub-Region Focus',
        },
        {
          value: form.getFieldValue('strategy_description')
            ? `${(form.getFieldValue('strategy_description') as string).slice(0, 200)}...`
            : 'N/A',
          label: 'Strategy Description',
          isDescription: true,
        },
      ],
    },
    {
      indexTab: LeftNavExFundEnum.FEE_SCHEDULE,
      listField: [
        {
          value: form.getFieldValue('mgmt_fee') || 'N/A',
          label: 'Management Fee %',
        },
        {
          value: form.getFieldValue('max_12b1_fee') || 'N/A',
          label: 'Maximum 12B1 Fee %',
        },
        {
          value: form.getFieldValue('redemption_fee') || 'N/A',
          label: 'Redemption Fee %',
        },
        {
          value: form.getFieldValue('max_front_fee') || 'N/A',
          label: 'Max Front Fee %',
        },
        {
          value: form.getFieldValue('redemption_fee_months') || 'N/A',
          label: 'If Less Than (Number Of Months)',
        },
        {
          value: form.getFieldValue('max_deferred_fee') || 'N/A',
          label: 'Maximum Deferred Fee %',
        },
        {
          value: form.getFieldValue('administrative_fee') || 'N/A',
          label: 'Administrative Fee %',
        },
        {
          value:
            form.getFieldValue('is_high_watermark') === true
              ? 'Yes'
              : form.getFieldValue('is_high_watermark') === false
              ? 'No'
              : 'N/A',
          label: 'High Watermark',
        },
        {
          value: form.getFieldValue('sales_fee') || 'N/A',
          label: 'Sales Fee %',
        },
        {
          value: form.getFieldValue('hurdle_rate') || 'N/A',
          label: 'Hurdle Rate',
        },
        {
          value: form.getFieldValue('incentive_fee') || 'N/A',
          label: 'Incentive Fee %',
        },
      ],
    },
    {
      indexTab: LeftNavExFundEnum.SERVICE_PROVIDERS,
      listField: [
        {
          value:
            form.getFieldValue('is_annual_performance_audit') === true
              ? 'Yes'
              : form.getFieldValue('is_annual_performance_audit') === false
              ? 'No'
              : 'N/A',
          label: 'Annual Performance Audit?',
        },
        {
          value: form.getFieldValue('prime_broker')
            ? form.getFieldValue('prime_broker') === 'Other'
              ? form.getFieldValue('prime_broker_input')
              : form.getFieldValue('prime_broker')
            : 'N/A',
          label: 'Prime Broker',
        },
        {
          value: form.getFieldValue('last_audit_date')
            ? moment(form.getFieldValue('last_audit_date')).format('YYYY-MM-DD')
            : 'N/A',
          label: 'Last Audit Date',
        },
        {
          value: form.getFieldValue('administrator')
            ? form.getFieldValue('administrator') === 'Other'
              ? form.getFieldValue('administrator_input')
              : form.getFieldValue('administrator')
            : 'N/A',
          label: 'Administrator',
        },
        {
          value: form.getFieldValue('auditor_name')
            ? form.getFieldValue('auditor_name') === 'Other'
              ? form.getFieldValue('auditor_name_input')
              : form.getFieldValue('auditor_name')
            : 'N/A',
          label: 'Auditor',
        },
        {
          value: form.getFieldValue('banking_agent') || 'N/A',
          label: 'Banking Agent',
        },
        {
          value: form.getFieldValue('legal_adviser')
            ? form.getFieldValue('legal_adviser') === 'Other'
              ? form.getFieldValue('legal_adviser_input')
              : form.getFieldValue('legal_adviser')
            : 'N/A',
          label: 'Legal Adviser',
        },
        {
          value: form.getFieldValue('custodian') || 'N/A',
          label: 'Custodian',
        },
        {
          value: form.getFieldValue('placement_agent') || 'N/A',
          label: 'Placement Agent',
        },
        {
          value: form.getFieldValue('consultant') || 'N/A',
          label: 'Consultant',
        },
      ],
    },
    {
      indexTab: LeftNavExFundEnum.FUND_STRUCTURE,
      listField: [
        {
          value:
            form.getFieldValue('is_offshore') === true
              ? 'Yes'
              : form.getFieldValue('is_offshore') === false
              ? 'No'
              : 'N/A',
          label: 'Is Offshore',
        },
        {
          value: form.getFieldValue('domicile') || 'N/A',
          label: 'Domicile',
        },
        {
          value: form.getFieldValue('structure') || 'N/A',
          label: 'Structure',
        },
        {
          value: form.getFieldValue('registration_code') || 'N/A',
          label: 'Fund Registration',
        },
        {
          value:
            (form.getFieldValue('multi_manager_platform') &&
              fundDataDropdown?.gate_fund &&
              fundDataDropdown.gate_fund.length > 0 &&
              fundDataDropdown.gate_fund.find((item) => item.code_code === form.getFieldValue('multi_manager_platform'))
                ?.code_description) ||
            'N/A', //form.getFieldValue('registration_code'),
          label: 'Multi-Manager Platform',
        },
        {
          value: form.getFieldValue('equity_subscription')
            ? fundDataDropdown?.equity_subscription.find(
                (item) => item.code_code === form.getFieldValue('equity_subscription'),
              )?.code_description
            : 'N/A',
          label: 'Subscription Frequency',
        },
        {
          value: form.getFieldValue('equity_redemption')
            ? fundDataDropdown?.equity_redemption.find(
                (item) => item.code_code === form.getFieldValue('equity_redemption'),
              )?.code_description
            : 'N/A',
          label: 'Redemption Frequency',
        },
        {
          value: form.getFieldValue('subscription_advance_notice')
            ? fundDataDropdown?.subscription_advance_notice.find(
                (item) => item.code_code === form.getFieldValue('subscription_advance_notice'),
              )?.code_description
            : 'N/A',
          label: 'Subscription Advanced Notice (Days)',
        },
        {
          value: form.getFieldValue('redemption_advance_notice') || 'N/A',
          label: 'Redemption Advanced Notice (Days)',
        },
        {
          value: form.getFieldValue('subscription_cutoff_time')
            ? fundDataDropdown?.subscription_cutoff_time.find(
                (item) => item.code_code === form.getFieldValue('subscription_cutoff_time'),
              )?.code_description
            : 'N/A',
          label: 'Subscription Cutoff By (Time)',
        },
        {
          value: form.getFieldValue('redemption_return_percent') || 'N/A',
          label: 'Initial % Of Redemption Returned',
        },
        {
          value: form.getFieldValue('subscription_cutoff_timezone')
            ? fundDataDropdown?.subscription_cutoff_timezone.find(
                (item) => item.code_code === form.getFieldValue('subscription_cutoff_timezone'),
              )?.code_description
            : 'N/A',
          label: 'Subscription Cut Off By (Time Zone)',
        },
        {
          value: form.getFieldValue('redemption_return_days') || 'N/A',
          label: 'Initial Redemption Returned Within (Days)',
        },
        {
          value: form.getFieldValue('subscription_settlement_days')
            ? fundDataDropdown?.subscription_settlement_days.find(
                (item) => item.code_code === form.getFieldValue('subscription_settlement_days'),
              )?.code_description
            : 'N/A',
          label: 'Subscription Settlement (Days)',
        },
        {
          value: form.getFieldValue('balance_redemption_return_days') || 'N/A',
          label: 'Redemption Balance Returned Within (Days)',
        },
        {
          value: form.getFieldValue('is_lockup')
            ? fundDataDropdown?.is_lockup.find((item) => item.code_code === form.getFieldValue('is_lockup'))
                ?.code_description
            : 'N/A',
          label: 'Lockup',
        },
        {
          value: form.getFieldValue('investor_type') || 'N/A',
          label: 'Open To Investor Type',
        },
        {
          value: form.getFieldValue('lockup_type')
            ? fundDataDropdown?.lockup_type.find((item) => item.code_code === form.getFieldValue('lockup_type'))
                ?.code_description
            : 'N/A',
          label: 'Lockup Type',
        },
        {
          value: form.getFieldValue('use_of_leverage_type')
            ? fundDataDropdown?.use_of_leverage_type.find(
                (item) => item.code_code === form.getFieldValue('use_of_leverage_type'),
              )?.code_description
            : 'N/A',
          label: 'Leverage Type',
        },
        {
          value: form.getFieldValue('lockup_period')
            ? fundDataDropdown?.lockup_period.find((item) => item.code_code === form.getFieldValue('lockup_period'))
                ?.code_description
            : 'N/A',
          label: 'Lockup Period',
        },
        {
          value: form.getFieldValue('performance_reporting') || 'N/A',
          label: 'Performance Reporting Interval',
        },
        {
          value: form.getFieldValue('gate_fund')
            ? fundDataDropdown?.gate_fund.find((item) => item.code_code === form.getFieldValue('gate_fund'))
                ?.code_description
            : 'N/A',
          label: 'Gate:Fund Level',
        },
        { value: form.getFieldValue('bloomberg_ticker') || 'N/A', label: 'Ticker' },
        {
          value: form.getFieldValue('gate_investor')
            ? fundDataDropdown?.gate_investor.find((item) => item.code_code === form.getFieldValue('gate_investor'))
                ?.code_description
            : 'N/A',
          label: 'Investor Level',
        },
        { value: form.getFieldValue('isin') || 'N/A', label: 'ISIN' },
      ],
    },
    {
      indexTab: LeftNavExFundEnum.FUND_ESG_FACTORS,
      listField: [
        {
          value: TextFieldYesNo(form.getFieldValue('is_fund_esg')),
          label: 'Does Fund Consider ESG Factors?',
        },
        {
          value: form.getFieldValue('esg_strategy')
            ? appData?.esgStrategies?.find((item) => item.code === form.getFieldValue('esg_strategy'))?.name || 'N/A'
            : 'N/A',
          label: 'ESG Strategy',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('app_positivescreen')),
          label: 'Positive/best-in-class Screening',
        },
        {
          value: form.getFieldValue('esg_sub_strategy')
            ? appData?.esgSubStrategies?.find((item) => item.code === form.getFieldValue('esg_sub_strategy'))?.name ||
              'N/A'
            : 'N/A',
          label: 'ESG Sub-Strategy',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('app_negativescreen')),
          label: 'Negative/Exclusionary Screening',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('app_esgintegration')),
          label: 'ESG Integration',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('app_impactinvest')),
          label: 'Impact Investing',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('app_sustaininvest')),
          label: 'Sustainability-themed Investing',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('env_greenbuilding')),
          label: 'Green Building/ Smart Growth',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('env_climatechange')),
          label: 'Climate Change/ Carbon',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('env_cleantech')),
          label: 'Clean Technology',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('env_pollutiontaxes')),
          label: 'Pollution / Toxics',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('env_sustainable')),
          label: 'Sustainable Natural Resources / Agriculture',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('env_waterconservation')),
          label: 'Water Use & Conservation',
        },
        {
          value: form.getFieldValue('env_description'),
          label: 'Additional Description Of How ENVIRONMENTAL Is Considered When Investing',
          isDescription: true,
        },
        {
          value: TextFieldYesNo(form.getFieldValue('soc_workplacesafety')),

          label: 'Workplace Safety',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('soc_laborrelations')),
          label: 'Labor Relations',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('soc_workplacebenefits')),
          label: 'Workplace Benefits',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('soc_diversityantibias')),
          label: 'Diversity & Anti-Bias Issues',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('soc_communitydev')),
          label: 'Community Development',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('soc_avoidtobacco')),
          label: 'Avoidance Of Tobacco Or Other Harmful Products',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('soc_humanrights')),
          label: 'Human Rights',
        },
        {
          value: form.getFieldValue('soc_description') || 'N/A',
          label: 'Additional Description Of How SOCIAL Is Considered When Investing',
          isDescription: true,
        },
        {
          value: TextFieldYesNo(form.getFieldValue('gov_corppolcontrib')),
          label: 'Corporate Political Contributions',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('gov_executivecomp')),
          label: 'Executive Compensation',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('gov_boarddiversity')),
          label: 'Board Diversity',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('gov_anticorruption')),
          label: 'Anti-Corruption Pilicies',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('gov_boardindependence')),
          label: 'Board Independence',
        },
        {
          value: form.getFieldValue('gov_description') || 'N/A',
          label: 'Additional Description Of How GOVERNANCE Is Considered When Investing',
          isDescription: true,
        },
        {
          value: TextFieldYesNo('team_engagepcm') || 'N/A',
          label:
            "Does This Fund's Investment Team Engage With The Management Teams Of Companies In This Fund's Underlying Poritfolio, Re:ESG Or Diversity Issues?",
          isDescription: true,
        },
        {
          value: TextFieldYesNo(form.getFieldValue('data_msci')),
          label: 'MSCI',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('data_sustainalytics')),
          label: 'Sustainalytics',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('data_bloomberg')),
          label: 'Bloomberg',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('data_factset')),
          label: 'FactSet',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('data_internal')),
          label: 'Proprietary Or Internally Developed Metrics',
        },
        {
          value: form.getFieldValue('data_other') || 'N/A',
          label: 'Other',
          isDescription: true,
        },
        {
          value:
            form.getFieldValue('firmwideESG') === true
              ? 'Yes'
              : form.getFieldValue('firmwideESG') === false
              ? 'No'
              : 'N/A',

          label: 'Does Your Firm Have A Formal Firmwide ESG Policy?',
          isDescription: true,
        },
        {
          value: TextFieldYesNo(form.getFieldValue('applied_firmculture')),
          label: 'Firm Culture',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('applied_investmentprocess')),
          label: 'Investment Process',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('applied_portfolioconstruction')),
          label: 'Portfolio Construction',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('applied_esgproducts')),
          label: 'ESG Products',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('applied_esgintegration')),
          label: 'ESG Integration',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('applied_esgactivist')),
          label: 'Activist / Engaged Ownership',
        },
        {
          value: TextFieldYesNo(form.getFieldValue('applied_diversityinclusion')),
          label: 'Diversity And Inclusion',
        },
        {
          value: form.getFieldValue('identify') || 'N/A',
          label: 'Identify The Third-Party Company',
        },
        {
          value:
            form.getFieldValue('incorporate') === true
              ? 'Yes'
              : form.getFieldValue('incorporate') === false
              ? 'No'
              : 'N/A',
          label: 'Do You Have Plans To Incorporate ESG In The Future?',
        },
      ],
    },
    {
      indexTab: LeftNavExFundEnum.FUND_CONTACT,
      listField: [
        {
          value: form.getFieldValue('contact_name') || 'N/A',
          label: 'Name',
        },
        {
          value: form.getFieldValue('contact_company') || 'N/A',
          label: 'Company',
        },
        {
          value: form.getFieldValue('contact_country')
            ? fundDataDropdown?.contact_country.find((item) => item.code_code === form.getFieldValue('contact_country'))
                ?.code_description
            : 'N/A',
          label: 'Country',
        },
        {
          value: form.getFieldValue('contact_address1') || 'N/A',
          label: 'Address 1',
        },
        { value: form.getFieldValue('contact_address2') || 'N/A', label: 'Address 2' },
        { value: form.getFieldValue('contact_city') || 'N/A', label: 'City' },
        {
          value: form.getFieldValue('contact_state')
            ? fundDataDropdown?.contact_state.find((item) => item.code_code === form.getFieldValue('contact_state'))
                ?.code_description
            : 'N/A',
          label: 'State',
        },
        { value: form.getFieldValue('contact_postal_code') || 'N/A', label: 'Postal Code' },
        { value: form.getFieldValue('contact_phone') || 'N/A', label: 'Phone' },
        { value: form.getFieldValue('contact_fax') || 'N/A', label: 'Fax' },
        { value: form.getFieldValue('contact_email') || 'N/A', label: 'Email' },
        { value: form.getFieldValue('contact_web') || 'N/A', label: 'Website' },
      ],
    },
  ]

  React.useEffect(() => {
    getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.NAV)
    getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.ASSET)
    getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.ROR)
  }, [getPerformanceRequest])

  return (
    <Wrapper>
      <Row style={{ marginBottom: 15 }}>
        Open Firm Co-Investment:
        <span
          onClick={() =>
            dataFundDetail &&
            dispatch(
              addTabs({
                name: dataFundDetail?.firm_name,
                id: `${PREFIX_EXTERNAL}-${dataFundDetail?.firm_id}`,
                type: FundFirmTypeEnum.FIRMS_IN_FUNDS,
                activeNav: LeftNavExFirmEnum.CO_INVESTMENT,
              }),
            )
          }
          style={{ cursor: 'pointer', color: Colors.primary, marginLeft: 5 }}
        >
          {dataFundDetail?.firm_name}
        </span>
      </Row>
      {LIST_OVER_VIEW.map((item) => {
        return (
          <div key={item.indexTab} style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h3>{item.indexTab}</h3>
              <Button
                type="link"
                onClick={() =>
                  dispatch(updateSelectedNav({ id: `${PREFIX_EXTERNAL}-${fundId}`, activeNav: item.indexTab }))
                }
              >
                Update/Edit
              </Button>
            </div>
            <Row gutter={[8, 8]}>
              {item.listField.map((field, index) => {
                return (
                  <Col span={field.isDescription ? 24 : 12} key={`${field.value}-${index}`} style={{ display: 'flex' }}>
                    {field.isDescription ? (
                      <div style={{ padding: '0 0.5rem' }}>
                        <div>{field.label}</div>
                        <div style={{ fontWeight: 'bold' }}>{field.value || 'N/A'}</div>
                      </div>
                    ) : (
                      <>
                        <div style={{ flex: 1, padding: '0 0.5rem' }}>{field.label}</div>
                        <div style={{ flex: 1, fontWeight: 'bold', padding: '0 0.5rem' }}>{field.value || 'N/A'}</div>
                      </>
                    )}
                  </Col>
                )
              })}
            </Row>
            <DividerForm />
          </div>
        )
      })}
      <Collapse style={{ marginTop: '0.5rem' }}>
        <Collapse.Panel header="ROR" key="ROR">
          <Row style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <span>
                  <strong>Reporting Style: </strong>
                  {dataFundDetail?.reporting_style}
                </span>
                <span style={{ marginLeft: '1rem' }}>
                  <strong>Denom: </strong>
                  {dataFundDetail?.denomination}
                </span>
              </div>

              <Button
                type="link"
                onClick={() =>
                  dispatch(
                    updateSelectedNav({
                      id: `${PREFIX_EXTERNAL}-${fundId}`,
                      activeNav: LeftNavExFundEnum.FUND_PERFORMANCE,
                    }),
                  )
                }
              >
                Update/Edit
              </Button>
            </div>
            <div>
              <DataSheet
                currencyValue={dataFundDetail?.denomination ? dataFundDetail.denomination : undefined}
                type={DataSheetType.ROR}
                initialData={dataROR}
                loading={dataRORLoading}
                isReadOnly
                minDate={dataFundDetail?.inception_date ? dataFundDetail.inception_date : undefined}
              />
            </div>
          </Row>
        </Collapse.Panel>
      </Collapse>
      <Collapse style={{ marginTop: '0.5rem' }}>
        <Collapse.Panel header={`Assets ${dataFundDetail?.denomination} (MM)`} key="Assets">
          <Row style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="link"
                onClick={() =>
                  dispatch(
                    updateSelectedNav({
                      id: `${PREFIX_EXTERNAL}-${fundId}`,
                      activeNav: LeftNavExFundEnum.FUND_PERFORMANCE,
                    }),
                  )
                }
              >
                Update/Edit
              </Button>
            </div>
            <div>
              <DataSheet
                currencyValue={dataFundDetail?.denomination ? dataFundDetail.denomination : undefined}
                type={DataSheetType.ASSET}
                initialData={dataAsset}
                isReadOnly
                loading={dataAssetLoading}
                setRORDataChange={setAssetDataChange}
                minDate={dataFundDetail?.inception_date ? dataFundDetail.inception_date : undefined}
              />
            </div>
          </Row>
        </Collapse.Panel>
      </Collapse>
      <Collapse style={{ marginTop: '0.5rem' }}>
        <Collapse.Panel header={`NAV Per Share ${dataFundDetail?.denomination}`} key="NAV Per Share">
          <Row style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="link"
                onClick={() =>
                  dispatch(
                    updateSelectedNav({
                      id: `${PREFIX_EXTERNAL}-${fundId}`,
                      activeNav: LeftNavExFundEnum.FUND_PERFORMANCE,
                    }),
                  )
                }
              >
                Update/Edit
              </Button>
            </div>
            <div>
              <DataSheet
                currencyValue={dataFundDetail?.denomination ? dataFundDetail.denomination : undefined}
                type={DataSheetType.NAV}
                initialData={dataNAV}
                loading={dataNAVLoading}
                setRORDataChange={setNAVDataChange}
                isReadOnly
                minDate={
                  dataFundDetail?.inception_date
                    ? moment(dataFundDetail.inception_date).subtract(1, 'month').format(DATE_FORMAT)
                    : undefined
                }
              />
            </div>
          </Row>
        </Collapse.Panel>
      </Collapse>
      <Collapse style={{ marginTop: '0.5rem' }}>
        <Collapse.Panel header="Instruments" key="Instruments">
          <Row style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="link"
                onClick={() =>
                  dispatch(
                    updateSelectedNav({ id: `${PREFIX_EXTERNAL}-${fundId}`, activeNav: LeftNavExFundEnum.INSTRUMENTS }),
                  )
                }
              >
                Update/Edit
              </Button>
            </div>
            <div>
              <InstrumentsTable isOverview />
            </div>
          </Row>
        </Collapse.Panel>
      </Collapse>
      <Collapse style={{ marginTop: '0.5rem' }}>
        <Collapse.Panel header="Regions" key="Regions">
          <Row style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="link"
                onClick={() =>
                  dispatch(
                    updateSelectedNav({ id: `${PREFIX_EXTERNAL}-${fundId}`, activeNav: LeftNavExFundEnum.REGIONS }),
                  )
                }
              >
                Update/Edit
              </Button>
            </div>
            <div>
              <RegionsTable isOverview />
            </div>
          </Row>
        </Collapse.Panel>
      </Collapse>
      <Collapse style={{ marginTop: '0.5rem' }}>
        <Collapse.Panel header="Industry" key="Industry">
          <Row style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="link"
                onClick={() =>
                  dispatch(
                    updateSelectedNav({ id: `${PREFIX_EXTERNAL}-${fundId}`, activeNav: LeftNavExFundEnum.INDUSTRY }),
                  )
                }
              >
                Update/Edit
              </Button>
            </div>
            <div>
              <IndustryTable isOverview />
            </div>
          </Row>
        </Collapse.Panel>
      </Collapse>
      <Collapse style={{ marginTop: '0.5rem' }}>
        <Collapse.Panel header="Investor Type" key="Investor Type">
          <Row style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="link"
                onClick={() =>
                  dispatch(
                    updateSelectedNav({
                      id: `${PREFIX_EXTERNAL}-${fundId}`,
                      activeNav: LeftNavExFundEnum.INVESTOR_TYPE,
                    }),
                  )
                }
              >
                Update/Edit
              </Button>
            </div>
            <div>
              <InvestorTypeTable isOverview />
            </div>
          </Row>
        </Collapse.Panel>
      </Collapse>
    </Wrapper>
  )
}
