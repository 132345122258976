import { DownloadOutlined } from '@ant-design/icons'
import { Anchor, Button, Col, notification, Row, Select, Tabs } from 'antd'
import JsFileDownload from 'js-file-download'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../../app/rootReducer'
import VamiGraph from '../../../routes/Analysis/VamiGraph'
import APIService from '../../api'
import { TrackingEventSource } from '../../api/services/event.service'
import { FetchFundPdfParams } from '../../api/services/fund.service'
import { useServiceState } from '../../hooks/useServiceState'
import GenUtil from '../../utils/gen-util'
import { LoadingDetails } from '../LoadingDetails'
import DistributionGraph from './DistributionGraph'
import DrawdownGraph from './DrawdownGraph'
import FirmFundDescription from './FirmFundDescription'
import FundDetailsUtils from './fund-details-utils'
import FundStrategyDescription from './FundStrategyDescription'
import PeerGroupForm from './PeerGroupForm'
import UpDownCaptureGraph from './UpDownCaptureGraph'
import YearlyMonthlyDatatable from './YearlyMonthlyDatatable'

const { Option } = Select
const { Link } = Anchor

const FixedHeader = styled('div')`
  background: white;
  z-index: 99;
  display: flex;
  justify-content: flex-end;
  padding: 16px;

  .ant-anchor {
    display: flex;
  }

  .ant-anchor-ink {
    display: none;
  }

  .ant-anchor-link {
    padding-left: 0;
  }
`

const Container = styled(PerfectScrollbar)`
  width: 100%;
  background: white;
  flex: 1;
  padding: 16px;

  h2 {
    text-align: center;
  }

  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
  }

  .ant-tabs-content {
    padding-top: 16px;
    border: none !important;
    margin-top: 0 !important;
  }
`

const StyledDownloadBtn = styled(Button)`
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  left: 0;
  top: 4px;
  z-index: 1;
`

interface Props {
  source: TrackingEventSource
  fundId: string
  isShowButtonFactsheet: boolean
  previewTab?: string
}

enum ActiveTabEnum {
  Profile = 'Profile',
  PeerGroup = 'Peer Group',
}

const FundDetailsPreview: React.FC<Props> = ({ source, fundId, isShowButtonFactsheet, previewTab }) => {
  const [activeTab, setActiveTab] = React.useState(previewTab || ActiveTabEnum.Profile)
  const { data: fundData, invoke: invokeFundDetails, loading: loadingFundDetails } = useServiceState(
    APIService.fundService.fetchFundDetails,
  )

  useEffect(() => {
    setActiveTab(previewTab || activeTab)
  }, [previewTab])
  const { user } = useSelector((state: RootState) => state.user)

  const { data: rorData, invoke: invokeRORData } = useServiceState(APIService.fundService.fetchRORDetails)

  const { data: aumData, invoke: invokeAUMData } = useServiceState(APIService.fundService.fetchAUMDetails)

  const { data: profileStatsData, invoke: invokeProfileStats } = useServiceState(
    APIService.fundService.fetchProfileStatsDetail,
  )

  const { benchmarks: benchmarkOptions } = useSelector((state: RootState) => state.appData)

  const [fromDate, setFromDate] = useState<string>('')
  const [toDate, setToDate] = useState<string>('')
  const [datesRangeArray, setDatesRangeArray] = useState<string[]>([])
  const [benchmark1, setBenchmark1] = useState<string>('2899')
  const [benchmark2, setBenchmark2] = useState<string>('2898')
  const [benchmark3, setBenchmark3] = useState<string>('50141')
  const [isDateRangeUpdated, setIsDateRangeUpdated] = useState<boolean>(false)
  const [loadingDownloadFactsheet, setLoadingDownloadFactsheet] = React.useState(false)

  useEffect(() => {
    invokeFundDetails(fundId)
  }, [fundId, invokeFundDetails])

  useEffect(() => {
    invokeRORData({ fundId })
    invokeAUMData({ fundId })
  }, [fundId, invokeRORData, invokeAUMData])

  useEffect(() => {
    if (fundId && benchmark1 && benchmark2 && benchmark3) {
      invokeProfileStats({ fundId, benchmark1, benchmark2, benchmark3 })
    }
  }, [benchmark1, benchmark2, benchmark3, fundId, invokeProfileStats])

  useEffect(() => {
    if (rorData && !isDateRangeUpdated) {
      const endDate = Math.max(...rorData.rors.map((rorItem) => +rorItem.yyyymmm))
      const startDate = Math.min(...rorData.rors.map((rorItem) => +rorItem.yyyymmm))
      const datesRange = FundDetailsUtils.getDatesRangeList(
        GenUtil.getFormattedYearAndMonth(String(startDate), 'YYYY0MM'),
        GenUtil.getFormattedYearAndMonth(String(endDate), 'YYYY0MM'),
      )
      setFromDate(GenUtil.getFormattedYearAndMonth(String(startDate), 'YYYY0MM'))
      setToDate(GenUtil.getFormattedYearAndMonth(String(endDate), 'YYYY0MM'))
      setDatesRangeArray(datesRange)
      setIsDateRangeUpdated(true)
    }
  }, [rorData, isDateRangeUpdated])

  const generatePdfReport = async () => {
    const data: FetchFundPdfParams = {
      benchmark1,
      benchmark2,
      benchmark3,
      start_date: fromDate,
      end_date: toDate,
    }
    try {
      const res = await APIService.fundService.fetchFundPdfReport({ fundId, data })
      JsFileDownload(
        res.data,
        `${fundData?.fund_name.replace('-', ' ').replace(',', ' ')} Profile.pdf`,
        'application/pdf',
      )
    } catch (error) {
      notification.error({ message: error.response.statusText })
    }
  }

  const downloadFactsheet = async () => {
    setLoadingDownloadFactsheet(true)
    try {
      const res = await APIService.fundService.postDownloadFile({
        file_type: 'INDEX_FACTSHEET',
        data: { fund_id: fundId },
      })

      window.open(`${process.env.REACT_APP_AUTH_API_URL}/manager-link/files/${res.data.url}`)

      // const resDownload = await axios.get(`${process.env.REACT_APP_AUTH_API_URL}/manager-link/files/${res.data.url}`)
      // JsFileDownload(resDownload.data, 'index_factsheet.pdf', 'application/pdf')
    } catch (error) {
      console.log(error.response)
      notification.error({ message: error.response.statusText })
    } finally {
      setLoadingDownloadFactsheet(false)
    }
  }

  const fetchRorAumProfileStats = (startDate: string, endDate: string) => {
    const fromDate = startDate
    const toDate = endDate
    invokeRORData({ fundId, fromDate, toDate })
    invokeAUMData({ fundId, fromDate, toDate })
    invokeProfileStats({
      fundId,
      benchmark1,
      benchmark2,
      benchmark3,
      startDate: fromDate,
      endDate: toDate,
    })
  }

  const renderBenchmarkOptions = () => {
    return benchmarkOptions
      ? benchmarkOptions.map((option) => {
          return (
            <Option key={option.id} value={option.id}>
              {option.name}
            </Option>
          )
        })
      : []
  }

  const handleTabChange = (activeKey: ActiveTabEnum) => {
    setActiveTab(activeKey)
  }

  if (loadingFundDetails) {
    return <LoadingDetails name="Loading Fund" />
  }

  if (!fundData) {
    return null
  }

  return (
    <div
      id="fund-detail-cy"
      style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100%', height: '100%' }}
    >
      <FixedHeader id="fund-detail-header">
        <Anchor affix={false}>
          {activeTab === ActiveTabEnum.Profile && (
            <Select
              placeholder="Select quick navigation"
              style={{ width: 250 }}
              defaultActiveFirstOption
              defaultValue={'top'}
            >
              <Option key={'top'} value={'top'}>
                <Link href="#info" title="Top" />
              </Option>
              <Option key={'strategy-description'} value={'strategy-description'}>
                <Link href="#strategy-description" title="Strategy Description" />
              </Option>
              <Option value={'monthly-ror'} key={'monthly-ror'}>
                <Link href="#monthly-ror" title="Monthly ROR" />
              </Option>
              <Option value={'monthly-aum'} key={'monthly-aum'}>
                <Link href="#monthly-aum" title="Monthly AUM" />
              </Option>
              <Option value={'vami'} key={'vami'}>
                <Link href="#vami" title="Value Added Money Index" />
              </Option>
              <Option value={'fund-stats'} key={'fund-stats'}>
                <Link href="#fund-stats" title="Fund Stats" />
              </Option>
              <Option value={'distribution'} key={'distribution'}>
                <Link href="#distribution" title="Distribution &amp; Up/down capture" />
              </Option>
              <Option value={'peak-to'} key={'peak-to'}>
                <Link href="#peak-to" title="Peak to Trough Drawdown" />
              </Option>
            </Select>
          )}
          {activeTab === ActiveTabEnum.PeerGroup && (
            <Select placeholder="Select quick navigation" style={{ width: 250 }} defaultActiveFirstOption>
              <Option key={'top'} value={'top'}>
                <Link href="#info" title="Top" />
              </Option>

              <Option key={'fund-and-benchmark'} value={'fund-and-benchmark'}>
                <Link href="#fund-and-benchmark" title="Fund and Benchmark" />
              </Option>

              <Option key={'universe-statistics'} value={'universe-statistics'}>
                <Link href="#universe-statistics" title="Universe Stats" />
              </Option>

              <Option key={'performance-universe'} value={'performance-universe'}>
                <Link href="#performance-universe" title="Performance vs Universe" />
              </Option>
            </Select>
          )}
        </Anchor>
      </FixedHeader>
      <Container>
        <span id="info" />
        <Tabs
          activeKey={activeTab}
          type="card"
          tabBarGutter={8}
          onChange={(activeKey) => handleTabChange(activeKey as ActiveTabEnum)}
        >
          <Tabs.TabPane tab="Profile" key={ActiveTabEnum.Profile} style={{ flexDirection: 'column' }}>
            <Row style={{ marginBottom: 16, justifyContent: 'center', position: 'relative' }}>
              <StyledDownloadBtn
                type="primary"
                disabled={
                  user?.hfrdb_trial || !(fundId && toDate && fromDate && benchmark3 && benchmark2 && benchmark1)
                }
                onClick={generatePdfReport}
              >
                <DownloadOutlined /> Fund PDF Report
              </StyledDownloadBtn>
              {isShowButtonFactsheet && (
                <Button
                  style={{ fontSize: 13, fontWeight: 500, position: 'absolute', left: 165, top: 4, zIndex: 1 }}
                  loading={loadingDownloadFactsheet}
                  onClick={downloadFactsheet}
                >
                  <DownloadOutlined />
                  Index Factsheet PDF Report
                </Button>
              )}
              <>
                <Select
                  value={fromDate}
                  style={{ width: 120 }}
                  placeholder="Select a Start date"
                  optionFilterProp="children"
                  onChange={(value: any) => {
                    setFromDate(value)
                    fetchRorAumProfileStats(value, toDate)
                  }}
                >
                  {datesRangeArray.map((item: string) => {
                    return (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    )
                  })}
                </Select>
                <Select
                  value={toDate}
                  style={{ marginLeft: 4, width: 120 }}
                  placeholder="Select a End date"
                  onChange={(value: any) => {
                    setToDate(value)
                    fetchRorAumProfileStats(fromDate, value)
                  }}
                >
                  {datesRangeArray
                    .slice()
                    .reverse()
                    .filter((d) => moment(d, 'YYYY-MM') > moment(fromDate, 'YYYY-MM'))
                    .map((item: string) => {
                      return (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      )
                    })}
                </Select>
              </>
            </Row>
            <FirmFundDescription description={FundDetailsUtils.getFundDescription(fundData)} />
            <Row id="strategy-description" style={{ marginTop: '20px' }}>
              <Col span={24}>
                <FundStrategyDescription fundStrategyDescription={fundData.fund_info.strategy_description || ''} />
              </Col>
            </Row>

            {(rorData?.rors || []).length > 0 ? (
              <Row id="monthly-ror" style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <YearlyMonthlyDatatable
                    header={`Monthly ROR (${fundData.reporting_style} in ${fundData.returns_currency})`}
                    tableData={FundDetailsUtils.getFormattedTableDataForRORAndAUM(
                      rorData?.rors || [],
                      rorData?.annualized_rors || {},
                    )}
                    showTotal={true}
                  />
                </Col>
              </Row>
            ) : null}

            {(aumData?.items || []).length > 0 && (
              <Row id="monthly-aum" style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <YearlyMonthlyDatatable
                    header={`Monthly AUM (M in ${fundData.fund_assets_currency})`}
                    tableData={FundDetailsUtils.getFormattedTableDataForRORAndAUM(aumData?.items || [])}
                  />
                </Col>
              </Row>
            )}

            <Row gutter={16} style={{ marginTop: '20px' }}>
              <Col span={8}>
                Benchmark 1
                <br />
                <Select
                  id="benchmark-1"
                  value={benchmark1}
                  style={{ width: 280 }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.props &&
                    option.props.children &&
                    (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                  onChange={(val: string) => {
                    setBenchmark1(val)
                    // fetchRORAndAUMCallback();
                  }}
                >
                  {renderBenchmarkOptions()}
                </Select>
              </Col>
              <Col span={8}>
                Benchmark 2
                <br />
                <Select
                  id="benchmark-2"
                  value={benchmark2}
                  style={{ width: 280 }}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.props &&
                    option.props.children &&
                    (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(val: string) => {
                    setBenchmark2(val)
                    // fetchRORAndAUMCallback();
                  }}
                >
                  {renderBenchmarkOptions()}
                </Select>
              </Col>
              <Col span={8}>
                Benchmark 3
                <br />
                <Select
                  id="benchmark-3"
                  value={benchmark3}
                  style={{ width: 280 }}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.props &&
                    option.props.children &&
                    (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(val: string) => {
                    setBenchmark3(val)
                    // fetchRORAndAUMCallback();
                  }}
                >
                  {renderBenchmarkOptions()}
                </Select>
              </Col>
            </Row>

            {profileStatsData && (
              <Row style={{ marginTop: 30 }}>
                <Col span={24}>
                  <VamiGraph vami={profileStatsData.vami} />
                </Col>
              </Row>
            )}
            <Row id="fund-stats" style={{ marginTop: '30px', position: 'relative' }}>
              <Col span={24}>
                {profileStatsData && (
                  <YearlyMonthlyDatatable
                    extra={
                      <div style={{ fontWeight: 550 }}>
                        {profileStatsData && profileStatsData.fund_si && (
                          <span style={{ marginRight: 16 }}>
                            **Date: {GenUtil.getFormattedYearAndMonth(profileStatsData.fund_si_start_date, 'YYYY0MM')}
                            {' - '}
                            {GenUtil.getFormattedYearAndMonth(profileStatsData.fund_si_end_date, 'YYYY0MM')}
                          </span>
                        )}
                        *Date: {profileStatsData?.start_date || ''} - {profileStatsData?.end_date || ''}
                      </div>
                    }
                    {...FundDetailsUtils.getFundStatsTableData(
                      profileStatsData.b1,
                      profileStatsData.b2,
                      profileStatsData.b3,
                      profileStatsData.fund,
                      profileStatsData.fund_si,
                    )}
                  />
                )}
              </Col>
            </Row>
            <Row id="distribution" style={{ marginTop: '30px' }} gutter={16}>
              <Col span={12}>
                {profileStatsData ? <DistributionGraph distribution={profileStatsData.distribution} /> : null}
              </Col>
              <Col span={12}>
                {profileStatsData ? <UpDownCaptureGraph up_and_down={profileStatsData.up_and_down} /> : null}
              </Col>
            </Row>
            <Row id="peak-to" style={{ marginTop: '30px' }}>
              <Col span={24}>{profileStatsData ? <DrawdownGraph drawdown={profileStatsData.drawdown} /> : null}</Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Peer Group" key={ActiveTabEnum.PeerGroup}>
            <PeerGroupForm
              source={source}
              fundId={fundId}
              fundData={fundData}
              endDateOptions={datesRangeArray.slice().reverse() || []}
              fundAndBenchmarkService={APIService.peerGroupAnalysisService.fetchFundAndBenchmark}
              universeStatsService={APIService.peerGroupAnalysisService.fetchUniverseStats}
            />
          </Tabs.TabPane>
        </Tabs>
      </Container>
    </div>
  )
}

export default FundDetailsPreview
