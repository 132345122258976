import React from 'react'
import { ListSearchFundType } from '../../SearchFundLabel'

const defaultValue = {
  boldText: '',
  filterArr: [] as ListSearchFundType[],
  isFiltering: false,
}
type Props = {
  text: string
  filterArr: ListSearchFundType[]
}
export const FundDetailLabelSearchContext = React.createContext(defaultValue)
export const FundDetailLabelSearchProvider: React.FC<Props> = ({ children, text, filterArr }) => {
  const isFiltering = text === '' ? false : true
  const value = {
    boldText: text,
    filterArr,
    isFiltering,
  }
  return <FundDetailLabelSearchContext.Provider value={value}>{children}</FundDetailLabelSearchContext.Provider>
}
