import { Card, Divider } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { ExFundDetails } from '../../../../shared/api/models/ExFundDetails'
import ExFundPerformanceItem from '../../../ExternalDashboard/ExFundDetail/ExFundPerformanceItem'

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-head {
    border-bottom: none;
    font-size: 1rem;
    padding: 0 1rem;
  }
  .ant-card-body {
    padding: 0;
    flex: 1;
  }
`

type Props = {
  shouldUpdate?: boolean
  setShouldUpdate?: (value: boolean) => void
  setUpdatedFundPerformance?: (updatedFund?: string) => void
  dataFundsFirm: ExFundDetails[]
}

export const FUND_STATUS_SHOW_PERFORMANCE = ['Active', 'Web Pending', 'Web Activation Request']

const FirmLatestPerformance: React.FC<Props> = ({
  shouldUpdate,
  dataFundsFirm,
  setShouldUpdate,
  setUpdatedFundPerformance,
}) => {
  return (
    <StyledCard size="small" title={'Latest Performance (Active, Web Activation Request and Web Pending Funds Only)'}>
      {dataFundsFirm &&
        dataFundsFirm.length > 0 &&
        dataFundsFirm.map((fund, index) => {
          if (FUND_STATUS_SHOW_PERFORMANCE.includes(fund.fund_status)) {
            return (
              <div key={fund.fund_id} style={index === 0 ? { marginTop: 20 } : {}}>
                <ExFundPerformanceItem
                  fund={fund}
                  shouldUpdate={shouldUpdate}
                  setShouldUpdate={setShouldUpdate}
                  setUpdatedFundPerformance={setUpdatedFundPerformance}
                />
                <Divider />
              </div>
            )
          }
          return null
        })}
    </StyledCard>
  )
}

export default FirmLatestPerformance
