import { Col, Form, Radio, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'
import { FundDetailDropdownContext } from '../../../shared/api/context/FundDetailDropdownContext'
import { FormItemInput, FormItemSelect } from '../../../shared/components/FormItemComponents'
import { RenderLabelExFirm } from '../../../shared/components/RenderLabelExFirm'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 0 1rem;
  margin-top: 1rem;
  flex-direction: column;
`
type Props = {
  form: FormInstance
}

const FirmOwnership: React.FC<Props> = ({ form }) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)
  const LIST_INPUT_FORM = [
    {
      name: 'founded',
      label: 'Year Founded',
      type: 'number',
    },
    {
      name: 'owned_by_employees',
      label: '% of firm owned by employees',
      type: 'float',
    },
    {
      name: 'employees_with_ownership_stake',
      label: '# of employees with ownership stake',
      type: 'number',
    },
  ]

  const isMinorityFirm = form.getFieldValue('is_minority_firm')

  React.useEffect(() => {
    if (!isMinorityFirm) {
      // is_minority_firm === false -> update minority_own_type, minority_own_level to null
      // visually update on the firm
      form.setFieldsValue({ minority_own_type: undefined, minority_own_level: undefined })
    }
  }, [form, isMinorityFirm])

  return (
    <Wrapper>
      {LIST_INPUT_FORM.map((item) => {
        return (
          <Row key={item.name}>
            <Col span={12} xl={12} md={16} xs={24} sm={24}>
              <FormItemInput
                name={item.name}
                label={<RenderLabelExFirm value={item.label} />}
                labelCol={12}
                wrapperCol={12}
                typeField={item.type ? 'number' : undefined}
                normalize={item.type ? item.type : undefined}
              />
            </Col>
          </Row>
        )
      })}
      <Row>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <Form.Item
            name="is_minority_firm"
            label={<RenderLabelExFirm value="Is this a minority-owned firm?" />}
            {...{ labelCol: { span: 12 }, wrapperCol: { span: 12 } }}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemSelect
            name={'minority_own_type'}
            disabled={!form.getFieldValue('is_minority_firm')}
            label={<RenderLabelExFirm value={'Minority ownership type'} />}
            options={
              fundDataDropdown?.minority_own_type && fundDataDropdown.minority_own_type.length > 0
                ? fundDataDropdown.minority_own_type.map((item) => ({
                    value: item.code_code,
                    text: item.code_description,
                  }))
                : []
            }
            labelCol={12}
            wrapperCol={12}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemSelect
            name={'minority_own_level'}
            disabled={!form.getFieldValue('is_minority_firm')}
            label={<RenderLabelExFirm value={'Minority ownership level'} />}
            options={
              fundDataDropdown?.minority_own_level && fundDataDropdown.minority_own_level.length > 0
                ? fundDataDropdown.minority_own_level.map((item) => ({
                    value: item.code_code,
                    text: item.code_description,
                  }))
                : []
            }
            labelCol={12}
            wrapperCol={12}
          />
        </Col>
      </Row>
    </Wrapper>
  )
}

export default FirmOwnership
