import { DeleteTwoTone, EditOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Tooltip } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import Table, { ColumnProps } from 'antd/lib/table'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../../../shared/api'
import { FundDetailDataContext } from '../../../../shared/api/context/FundDetailDataContext'
import { FirmDueDiligenceActivities } from '../../../../shared/api/models/FirmDetails'
import { Colors } from '../../../../shared/colors'
import { useServiceState } from '../../../../shared/hooks/useServiceState'
import { ModalFundDueDiligence } from './ModalFundDueDiligence'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 1rem 0;
  flex-direction: column;
`

export const Activities: React.FC = () => {
  const { dataFundDetail } = React.useContext(FundDetailDataContext)
  const [isShowModal, setIsShowModal] = React.useState(false)
  const [selectedActivity, setSelectedActivity] = React.useState<FirmDueDiligenceActivities | undefined>()

  const [selectedComment, setSelectedComment] = React.useState('')
  const [showComment, setShowComment] = React.useState(false)

  const { invoke: getListDueDiligenceAct, data: dataListDueDiligenceAct, loading: dataLoading } = useServiceState(
    APIService.firmDetailService.getListDueDiligenceAct,
  )

  React.useEffect(() => {
    if (dataFundDetail) {
      getListDueDiligenceAct(dataFundDetail.firm_id)
    }
  }, [dataFundDetail, getListDueDiligenceAct])

  const updateDueDiligence = () => {
    getListDueDiligenceAct(dataFundDetail?.firm_id)
  }

  const columns: ColumnProps<FirmDueDiligenceActivities>[] = [
    {
      title: 'Operator ID',
      dataIndex: 'oper_id',
      key: 'oper_id',
    },
    {
      title: 'Date & Time',
      dataIndex: 'date_time',
      key: 'date_time',
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      render: (_, record) => (
        <>
          <Button
            type="link"
            onClick={() => {
              setShowComment(true)
              setSelectedComment(record.comment)
            }}
          >
            See Comment
          </Button>
        </>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      fixed: 'right',
      width: 100,
      render: (_, record) => (
        <>
          <Button
            type="link"
            style={{ padding: '4px 8px' }}
            onClick={() => {
              setSelectedActivity(record)
              setIsShowModal(true)
            }}
          >
            <Tooltip title="Edit">
              <EditOutlined />
            </Tooltip>
          </Button>

          <Popconfirm title="Are you sure you want to delete?" onConfirm={() => console.log('delete:', record.oper_id)}>
            <Button type="link" style={{ padding: '4px 8px' }}>
              <Tooltip title="Delete">
                <DeleteTwoTone twoToneColor={Colors.danger} />
              </Tooltip>
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ]

  return (
    <Wrapper>
      <div style={{ marginRight: '0.5rem', display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="primary" style={{ marginBottom: '1rem' }} onClick={() => setIsShowModal(true)}>
          Add New Row
        </Button>
      </div>
      <Table
        rowKey="oper_id"
        loading={dataLoading}
        dataSource={dataListDueDiligenceAct}
        columns={columns}
        style={{ flex: 1 }}
        bordered
      />

      {dataFundDetail?.firm_id && (
        <ModalFundDueDiligence
          firmId={dataFundDetail.firm_id}
          isShowModal={isShowModal}
          setIsShowModal={setIsShowModal}
          selectedActivity={selectedActivity}
          setSelectedActivity={setSelectedActivity}
          updateDueDiligence={updateDueDiligence}
        />
      )}
      <Modal
        visible={showComment}
        title="Comment"
        cancelText="Close"
        onCancel={() => setShowComment(false)}
        footer={[
          <Button key="back" onClick={() => setShowComment(false)}>
            Close
          </Button>,
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>{selectedComment}</div>
        </div>
      </Modal>
    </Wrapper>
  )
}
