import { Col, Divider, Form, Radio, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'
import { FundDetailDropdownContext } from '../../../shared/api/context/FundDetailDropdownContext'
import { FormItemAutoComplete, FormItemInput, FormItemSelect } from '../../../shared/components/FormItemComponents'
import { RenderLabelExFirm } from '../../../shared/components/RenderLabelExFirm'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 0 1rem;
  margin-top: 1rem;
  flex-direction: column;
`

type Props = {
  form: FormInstance
}
const REGISTRATION_OPTIONS = ['Investment Advisor', 'Broker-Dealer', 'CPO/CTA', 'None']

const FirmRegistration: React.FC<Props> = ({ form }) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)
  const [stateDisabled, setStateDisabled] = React.useState(false)

  const secCountry = form.getFieldValue('sec_country')

  React.useEffect(() => {
    if (!secCountry || secCountry !== '205') {
      setStateDisabled(true)
      form.setFieldsValue({ sec_state: undefined })
    } else {
      setStateDisabled(false)
    }
  }, [form, secCountry])

  return (
    <Wrapper>
      <Row>
        <Col span={12} xl={16} md={24} xs={24} sm={24}>
          <FormItemAutoComplete
            name="registration"
            label={<RenderLabelExFirm value="Firm Registration" />}
            labelCol={12}
            wrapperCol={12}
            options={REGISTRATION_OPTIONS.map((item) => ({ text: item, value: item }))}
            isKeepOptions
          />
        </Col>
      </Row>
      <Divider style={{ fontSize: '14px' }}>SEC Info</Divider>
      <Row>
        <Col span={12} xl={16} md={24} xs={24} sm={24}>
          <Form.Item
            name="sec_registered"
            label={<RenderLabelExFirm value="Is Firm registered as an Investment Advisor with the SEC?" />}
            {...{ labelCol: { span: 12 }, wrapperCol: { span: 12 } }}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12} xl={16} md={24} xs={24} sm={24}>
          <FormItemInput
            name="sec_number"
            labelCol={12}
            wrapperCol={12}
            label={<RenderLabelExFirm value="Firm SEC Number" />}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12} xl={16} md={24} xs={24} sm={24}>
          <FormItemInput
            name="iard_crd_number"
            labelCol={12}
            wrapperCol={12}
            label={<RenderLabelExFirm value="Firm IARD / CRD Number" />}
            maxLength={6}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12} xl={16} md={24} xs={24} sm={24}>
          <FormItemSelect
            name="organization_type"
            label={<RenderLabelExFirm value="Organization Type" />}
            labelCol={12}
            wrapperCol={12}
            options={
              fundDataDropdown?.organization_type && fundDataDropdown.organization_type.length > 0
                ? fundDataDropdown.organization_type.map((item) => ({
                    value: item.code_code,
                    text: item.code_description,
                  }))
                : []
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={12} xl={16} md={24} xs={24} sm={24}>
          <FormItemSelect
            name="sec_country"
            labelCol={12}
            wrapperCol={12}
            label={<RenderLabelExFirm value="Under the laws of which Country is Firm organized?" />}
            options={
              fundDataDropdown?.sec_country && fundDataDropdown.sec_country.length > 0
                ? fundDataDropdown.sec_country.map((item) => ({
                    value: item.code_code,
                    text: item.code_description,
                  }))
                : []
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={12} xl={16} md={24} xs={24} sm={24}>
          <FormItemSelect
            name="sec_state"
            label={<RenderLabelExFirm value="If 'USA', under the laws of which State is Firm organized?" />}
            labelCol={12}
            wrapperCol={12}
            disabled={stateDisabled}
            options={
              fundDataDropdown?.sec_state && fundDataDropdown.sec_state.length > 0
                ? fundDataDropdown.sec_state.map((item) => ({
                    value: item.code_code,
                    text: item.code_description,
                  }))
                : []
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={12} xl={16} md={24} xs={24} sm={24}>
          <FormItemSelect
            name="sec_exemption_reason_type"
            label={<RenderLabelExFirm value='If Exempt Reporting Advisor ("ERA"), exemption reason' />}
            labelCol={12}
            wrapperCol={12}
            options={
              fundDataDropdown?.sec_exemption_reason_type && fundDataDropdown.sec_exemption_reason_type.length > 0
                ? fundDataDropdown.sec_exemption_reason_type.map((item) => ({
                    value: item.code_code,
                    text: item.code_description,
                  }))
                : []
            }
          />
        </Col>
      </Row>
      <Divider style={{ fontSize: '14px' }}>Non-SEC Info</Divider>
      <Row>
        <Col span={12} xl={16} md={24} xs={24} sm={24}>
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <h4 style={{ margin: '0.5rem 0' }}>If you are registered with another government entity</h4>
          </div>
          <FormItemSelect
            name="non_sec_country"
            labelCol={12}
            wrapperCol={12}
            label={<RenderLabelExFirm value="Country" />}
            options={
              fundDataDropdown?.non_sec_country && fundDataDropdown.non_sec_country.length > 0
                ? fundDataDropdown.non_sec_country.map((item) => ({
                    value: item.code_code,
                    text: item.code_description,
                  }))
                : []
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={12} xl={16} md={24} xs={24} sm={24}>
          <FormItemSelect
            name="non_sec_reg_auth"
            labelCol={12}
            wrapperCol={12}
            label={<RenderLabelExFirm value="Non-SEC Regulatory Authority" />}
            options={
              fundDataDropdown?.non_sec_reg_auth && fundDataDropdown.non_sec_reg_auth.length > 0
                ? fundDataDropdown.non_sec_reg_auth.map((item) => ({
                    value: item.code_code,
                    text: item.code_description,
                  }))
                : []
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={12} xl={16} md={24} xs={24} sm={24}>
          <FormItemInput
            name="non_sec_reg_auth_type"
            labelCol={12}
            wrapperCol={12}
            label={<RenderLabelExFirm value="Non-SEC Regulatory Authority Type" />}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12} xl={16} md={24} xs={24} sm={24}>
          <FormItemInput
            name="non_sec_reg_num"
            labelCol={12}
            wrapperCol={12}
            label={<RenderLabelExFirm value="Non-SEC Regulatory Authority Number" />}
            maxLength={30}
          />
        </Col>
      </Row>
    </Wrapper>
  )
}

export default FirmRegistration
