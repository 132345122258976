import { AxiosInstance, AxiosPromise } from 'axios'
import { DisclaimerResponse, DisclaimerPrompt } from '../models/ResponsesTypes'

export interface CommonService {
  fetchAllCountries: () => AxiosPromise<string[]>
  fetchDisclaimer: (placement: 'universe' | 'model' | 'profile' | 'ranking') => AxiosPromise<DisclaimerResponse>
  fetchDisclaimerPrompt: () => AxiosPromise<DisclaimerPrompt>
  approveDisclaimerPrompt: () => AxiosPromise<void>
}

export const CommonService = (request: AxiosInstance): CommonService => {
  return {
    fetchAllCountries: () => request.get('/country'),
    fetchDisclaimer: (placement: 'universe' | 'model' | 'profile' | 'ranking') =>
      request.get(`/disclaimer/${placement}`),
    fetchDisclaimerPrompt: () => request.get('/disclaimer/prompt/hfrml'),
    approveDisclaimerPrompt: () => request.post('/disclaimer/prompt/hfrml/approve'),
  }
}
