import React from 'react'
import styled from 'styled-components'
import { FundDetailDropdownContext } from '../../../../shared/api/context/FundDetailDropdownContext'
import { DividerForm } from '../../../../shared/components/DividerForm'
import { FormItemInput, FormItemSelect, FormItemTextArea } from '../../../../shared/components/FormItemComponents'
import { RenderLabelFormFirm } from '../../../../shared/components/RenderLabelFormFirm'
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 0.5rem 3rem;
  flex-direction: column;
`

export const FirmNonSecInfo: React.FC = () => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)
  return (
    <Wrapper>
      <FormItemSelect
        name="non_sec_country"
        label={<RenderLabelFormFirm value="Country" />}
        labelCol={5}
        wrapperCol={10}
        allowClear
        options={
          fundDataDropdown?.non_sec_country && fundDataDropdown.non_sec_country.length > 0
            ? fundDataDropdown.non_sec_country.map((item) => ({
                value: item.code_code,
                text: item.code_description,
              }))
            : []
        }
      />
      <FormItemSelect
        name="non_sec_reg_auth"
        label={<RenderLabelFormFirm value="Reg Authority" />}
        allowClear
        labelCol={5}
        wrapperCol={10}
        options={
          fundDataDropdown?.non_sec_reg_auth && fundDataDropdown.non_sec_reg_auth.length > 0
            ? fundDataDropdown.non_sec_reg_auth.map((item) => ({
                value: item.code_code,
                text: item.code_description,
              }))
            : []
        }
      />
      <FormItemInput
        name="non_sec_reg_num"
        label={<RenderLabelFormFirm value="Reg Auth Type No." />}
        labelCol={5}
        wrapperCol={10}
        maxLength={30}
      />
      <DividerForm />
      <div style={{ padding: '1rem 0' }}>Reg Auth Type</div>
      <FormItemTextArea name="non_sec_reg_auth_type" row={5} maxLength={300} />
    </Wrapper>
  )
}
