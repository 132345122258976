import { ColumnProps } from 'antd/lib/table'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/rootReducer'
import APIService from '../../../shared/api'
import { FirmSearchColumnResponse } from '../../../shared/api/models/FirmDetails'
import { FilterTypeColumnTable } from '../../../shared/api/models/Funds'
import { useServiceState } from '../../../shared/hooks/useServiceState'
import { updateFirmSearchColumns } from '../../../slice/appSettingsSlice'

export interface FirmSearchColumnPropsExtended extends ColumnProps<FirmSearchColumnResponse> {
  key: string
  filter_type: FilterTypeColumnTable
}

export type FirmSearchAllColumnContextType = {
  dataAllColumns: FirmSearchColumnResponse | undefined
  defaultColumns: FirmSearchColumnPropsExtended[]
  loading: boolean
}

const defaultValue: FirmSearchAllColumnContextType = {
  dataAllColumns: undefined,
  defaultColumns: [],
  loading: true,
}

const DEFAULT_COLUMNS = ['firm_id', 'firm_name', 'founded']

export const FirmSearchColumnContext = React.createContext<FirmSearchAllColumnContextType>(defaultValue)

export const FirmSearchColumnProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch()
  const { invoke: getAllColumns, data: dataAllColumns, loading: allColumnLoading } = useServiceState(
    APIService.firmService.fetchAllColumns,
  )

  const { firmSearchColumns } = useSelector((state: RootState) => state.appSettings)

  React.useEffect(() => {
    getAllColumns()
  }, [getAllColumns])

  const defaultColumns: FirmSearchColumnPropsExtended[] | undefined = React.useMemo(() => {
    if (!dataAllColumns) {
      return []
    }

    return DEFAULT_COLUMNS.map((defaultKey) => ({
      ...dataAllColumns[defaultKey],
      key: defaultKey,
      title: dataAllColumns[defaultKey].human_readable_name,
      sorter: true,
      dataIndex: defaultKey,
    }))
  }, [dataAllColumns])

  useEffect(() => {
    if (firmSearchColumns.length > 0) {
      return
    }
    if (defaultColumns.length) {
      dispatch(updateFirmSearchColumns(defaultColumns.map((item) => item.key)))
    }
  }, [defaultColumns, dispatch, firmSearchColumns.length])

  const value = {
    dataAllColumns,
    loading: allColumnLoading,
    defaultColumns,
  }
  return <FirmSearchColumnContext.Provider value={value}>{children}</FirmSearchColumnContext.Provider>
}
