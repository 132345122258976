export const isWarningAssetData = (prev?: number | null, next?: number | null): boolean => {
  if (next === null || next === undefined || prev === null || prev === undefined) {
    return false
  }
  const percentageDifference = ((next - prev) / prev) * 100

  if (prev <= 20 && (percentageDifference >= 1000 || percentageDifference <= -75)) {
    return true
  }
  if (prev <= 100 && prev > 20 && (percentageDifference >= 500 || percentageDifference <= -75)) {
    return true
  }
  if (prev <= 1000 && prev > 100 && (percentageDifference >= 100 || percentageDifference <= -50)) {
    return true
  }
  if (prev > 1000 && (percentageDifference >= 50 || percentageDifference <= -25)) {
    return true
  }
  return false
}
