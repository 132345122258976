import { AxiosInstance, AxiosPromise } from 'axios'
import { RORResponseCalculate, RORResponseType, UpdateRORType } from '../../components/DataSheet'
import {
  APGLResponse,
  AsiStrategyResponse,
  DueDiligenceDocumentsResponse,
  DueDiligenceInvestor,
  DueDiligenceInvestorUpdate,
  DueDiligenceMonthly,
  DueDiligenceMonthlyUpdate,
  DueDiligenceScoreCardResponse,
  DueDiligenceScoreCardUpdate,
  FundActivationParams,
  FundBenchmark,
  FundDetails,
  FundDetailsUniverses,
  FundDropdownType,
  FundESGStrategies,
  FundIndexEligibilityDetails,
  FundIndexEligibilityDetailsResponse,
  FundIndicesUpdate,
  FundPropertiesParams,
  FundPropertiesResponse,
  FundTableDropDown,
  IndustryGroupsType,
  InstrumentCreateRequestType,
  InstrumentResponseType,
  InstrumentUpdateRequestType,
  InvestorCreateRequestType,
  InvestorType,
  InvestorUpdateRequestType,
  LegacyStrategyResponse,
  LeverageCreateRequestType,
  LeverageResponseType,
  LeverageUpdateRequestType,
  ListPlatformResponse,
  PrincipalsResponse,
  PrincipalsUpdateParams,
  RegionWeightType,
  UniversesUpdate,
} from '../models/FundDetails'

export type FlaggedDataType = [[string, number]]

export interface FundDetailService {
  fetchFundBasicInfo: (fundId: string) => AxiosPromise<FundDetails>
  fetchFundROR: (fundId: string) => AxiosPromise<RORResponseType[]>
  fetchNAVAssets: (fundId: string) => AxiosPromise<RORResponseType[]>
  fetchNAVNavs: (fundId: string) => AxiosPromise<RORResponseType[]>
  fetchFundDistributions: (fundId: string) => AxiosPromise<RORResponseType[]>
  fetchFundLeverages: (fundId: string) => AxiosPromise<LeverageResponseType[]>
  fetchFundInstruments: (fundId: string) => AxiosPromise<InstrumentResponseType[]>
  fetchFundIndustryGroups: (fundId: string) => AxiosPromise<IndustryGroupsType[]>
  fetchFundInvestorTypes: (fundId: string) => AxiosPromise<InvestorType[]>
  fetchFundRegionWeights: (fundId: string) => AxiosPromise<RegionWeightType[]>
  fetchFundUniverses: (fundId: string) => AxiosPromise<FundDetailsUniverses[]>
  fetchFundBenchmark: () => AxiosPromise<FundBenchmark[]>
  //NAV per unit calculate
  fetchCalculateNAV: ({
    fundId,
    fromDate,
    toDate,
    saveToDb,
  }: {
    fundId: string
    fromDate: string
    toDate: string
    saveToDb?: boolean
  }) => AxiosPromise<RORResponseCalculate[]>

  fetchDataDropdown: () => AxiosPromise<FundDropdownType>

  validateFundROR: ({
    fundId,
    data,
  }: {
    fundId: string
    data: UpdateRORType
  }) => AxiosPromise<{ flagged_rors: FlaggedDataType }>
  validateFundAsset: ({
    fundId,
    data,
  }: {
    fundId: string
    data: UpdateRORType
  }) => AxiosPromise<{ flagged_assets: FlaggedDataType }>

  updateFundROR: (fundId: string, data: UpdateRORType) => AxiosPromise<void>
  updateNAVROR: (fundId: string, data: UpdateRORType) => AxiosPromise<void>
  updateAssetROR: (fundId: string, data: UpdateRORType) => AxiosPromise<void>
  updateFundBasicInfo: (fundId: string, param: Partial<FundDetails>) => AxiosPromise<void>
  createFundBasicInfo: (param: Partial<FundDetails>) => AxiosPromise<{ fund_id: string; message: string }>
  updateFundDistributions: (fundId: string, data: UpdateRORType) => AxiosPromise<void>
  updateFundUniverses: (fundId: string, param: UniversesUpdate) => AxiosPromise<void>
  updateFundIndices: (fundId: string, param: FundIndicesUpdate) => AxiosPromise<void>
  fetchLegacyStrategy: () => AxiosPromise<LegacyStrategyResponse>
  fetchAsiStrategy: () => AxiosPromise<AsiStrategyResponse>

  // fund > Information
  createFundLeverage: (fundId: string, data: LeverageCreateRequestType) => AxiosPromise<void>
  updateFundLeverage: (fundId: string, data: LeverageUpdateRequestType) => AxiosPromise<void>
  deleteFundLeverage: (fundId: string, date: string) => AxiosPromise<void>

  createFundInstrument: (fundId: string, data: InstrumentCreateRequestType) => AxiosPromise<void>
  updateFundInstrument: (fundId: string, data: InstrumentUpdateRequestType) => AxiosPromise<void>
  deleteFundInstrument: (fundId: string, date: string, name: string) => AxiosPromise<void>

  createFundIndustry: (fundId: string, data: InstrumentCreateRequestType) => AxiosPromise<void>
  updateFundIndustry: (fundId: string, data: InstrumentUpdateRequestType) => AxiosPromise<void>
  deleteFundIndustry: (fundId: string, date: string, name: string) => AxiosPromise<void>

  createFundInvestorType: (fundId: string, data: InvestorCreateRequestType) => AxiosPromise<void>
  updateFundInvestorType: (fundId: string, data: InvestorUpdateRequestType) => AxiosPromise<void>
  deleteFundInvestorType: (fundId: string, date: string, name: string) => AxiosPromise<void>

  createFundRegionWeight: (fundId: string, data: InstrumentCreateRequestType) => AxiosPromise<void>
  updateFundRegionWeight: (fundId: string, data: InstrumentUpdateRequestType) => AxiosPromise<void>
  deleteFundRegionWeight: (fundId: string, date: string, name: string) => AxiosPromise<void>
  //Due Diligence Investor Opinion
  fetchDueDiligenceInvestor: (fundId: string) => AxiosPromise<DueDiligenceInvestor>
  updateDueDiligenceInvestor: (fundId: string, params: Partial<DueDiligenceInvestorUpdate>) => AxiosPromise<void>
  //Due Diligence Scorecard
  fetchDueDiligenceScoreCard: (fundId: string) => AxiosPromise<DueDiligenceScoreCardResponse>
  resetDueDiligenceScoreCard: (fundId: string) => AxiosPromise<void>
  updateDueDiligenceScoreCard: (
    fundId: string,
    params: { updates: DueDiligenceScoreCardUpdate[] },
  ) => AxiosPromise<void>
  //Due Diligence Monthly
  fetchDueDiligenceMonthly: (firmId: string) => AxiosPromise<DueDiligenceMonthly[]>
  updateDueDiligenceMonthly: (firmId: string, params: Partial<DueDiligenceMonthlyUpdate>) => AxiosPromise<void>
  createDueDiligenceMonthly: (firmId: string, params: Partial<DueDiligenceMonthlyUpdate>) => AxiosPromise<void>
  deleteDueDiligenceMonthly: (firmId: string, params: { date: string }) => AxiosPromise<void>
  //Due Diligence Documents
  fetchDueDiligenceDocuments: (firmId: string) => AxiosPromise<DueDiligenceDocumentsResponse>
  //Principals
  fetchFundPrincipals: (fundId: string) => AxiosPromise<PrincipalsResponse[]>
  createFundPrincipals: (fundId: string, params: Partial<PrincipalsResponse>) => AxiosPromise<void>
  updateFundPrincipals: (fundId: string, params: Partial<PrincipalsUpdateParams>) => AxiosPromise<void>
  deleteFundPrincipals: (fundId: string, params: Partial<PrincipalsUpdateParams>) => AxiosPromise<void>
  //APGL
  fetchFundAPGL: (fundId: string) => AxiosPromise<APGLResponse>
  //Fund Properties
  fetchFundProperties: (fundId: string) => AxiosPromise<FundPropertiesResponse>
  updateFundProperties: (fundId: string, params: FundPropertiesParams) => AxiosPromise<void>
  createFundProperties: (fundId: string, params: FundPropertiesParams) => AxiosPromise<void>
  deleteFundProperties: (fundId: string, prodId: number) => AxiosPromise<void>
  //Fund Table Dropdown
  fetchFundTableDropdown: () => AxiosPromise<FundTableDropDown>
  //Fund Status Activate
  updateFundStatusActivate: (fundId: string, params: FundActivationParams) => AxiosPromise

  cloneFund: (fundId: string, fundName: string) => AxiosPromise<{ fund_id: string }>
  //Electronic Platform
  fetchListPlatform: (fundId: string) => AxiosPromise<ListPlatformResponse>
  updateListPlatform: (fundId: string, platforms: string[]) => AxiosPromise<void>
  previewDeleteFund: (fundId: string) => AxiosPromise<{ [key: string]: number }>
  deleteFund: (fundId: string) => AxiosPromise<void>
  // Fund ESG Strategies
  fetchESGStrategies: () => AxiosPromise<FundESGStrategies>
  // Fund Index Eligibility Details
  fetchFundIndexEligibilityDetails: (params: {
    fundId: string
    indexFamilyId: number
  }) => AxiosPromise<FundIndexEligibilityDetailsResponse>
  updateFundIndexEligibilityDetails: (params: {
    fundId: string
    indexFamilyId: number
    body: FundIndexEligibilityDetails
  }) => AxiosPromise<{ message: string }>
}

export const FundDetailService = (request: AxiosInstance): FundDetailService => {
  return {
    fetchFundBasicInfo: (fundId) => request.get(`/manager-link/funds/${fundId.trim()}`),
    fetchFundROR: (fundId) => request.get(`/manager-link/funds/${fundId.trim()}/rors`),
    fetchNAVAssets: (fundId) => request.get(`/manager-link/funds/${fundId.trim()}/assets`),
    fetchNAVNavs: (fundId) => request.get(`/manager-link/funds/${fundId.trim()}/navs`),
    fetchFundDistributions: (fundId) => request.get(`/manager-link/funds/${fundId.trim()}/distributions`),
    fetchFundLeverages: (fundId) => request.get(`/manager-link/funds/${fundId.trim()}/leverages`),
    fetchFundInstruments: (fundId) => request.get(`/manager-link/funds/${fundId.trim()}/instruments`),
    fetchFundIndustryGroups: (fundId) => request.get(`/manager-link/funds/${fundId.trim()}/industry-groups`),
    fetchFundInvestorTypes: (fundId) => request.get(`/manager-link/funds/${fundId.trim()}/investor-types`),
    fetchFundRegionWeights: (fundId) => request.get(`/manager-link/funds/${fundId.trim()}/region-weights`),
    fetchDataDropdown: () => request.get('/manager-link/dropdown-values'),
    fetchFundUniverses: (fundId) => request.get(`/manager-link/legacy-universe-list/funds/${fundId.trim()}`),
    fetchFundBenchmark: () => request.get(`/manager-link/benchmark-funds`),
    //NAV per unit calculate
    // ({ fundId, benchmark1, benchmark2, benchmark3, startDate, endDate })
    fetchCalculateNAV: ({ fundId, fromDate, toDate, saveToDb }) => {
      return request.post(`/manager-link/funds/${fundId.trim()}/navs/calculate_rors`, {
        start_date: fromDate,
        end_date: toDate,
        save_to_db: saveToDb,
      })
    },
    fetchLegacyStrategy: () => request.get(`/manager-link/legacy-strategies`),
    fetchAsiStrategy: () => request.get(`/manager-link/asi-strategies`),

    // ror/aum data validating
    validateFundROR: ({ fundId, data }) => request.post(`/manager-link/funds/${fundId.trim()}/flag-rors`, data),
    validateFundAsset: ({ fundId, data }) => request.post(`/manager-link/funds/${fundId.trim()}/flag-assets`, data),

    // update
    updateFundROR: (fundId, data) => request.put(`/manager-link/funds/${fundId.trim()}/rors`, data),
    updateNAVROR: (fundId, data) => request.put(`/manager-link/funds/${fundId.trim()}/navs`, data),
    updateAssetROR: (fundId, data) => request.put(`/manager-link/funds/${fundId.trim()}/assets`, data),
    updateFundBasicInfo: (fundId, param) => request.put(`/manager-link/funds/${fundId.trim()}`, param),
    updateFundUniverses: (fundId, data) =>
      request.put(`/manager-link/legacy-universe-list/funds/${fundId.trim()}`, data),
    createFundBasicInfo: (param) => request.post(`/manager-link/funds`, param),
    updateFundDistributions: (fundId, param) =>
      request.put(`/manager-link/funds/${fundId.trim()}/distributions`, param),
    updateFundIndices: (fundId, param) => request.put(`/manager-link/funds/${fundId}/hfr-indices`, param),

    createFundLeverage: (fundId, data) => request.post(`/manager-link/funds/${fundId.trim()}/leverages`, data),
    updateFundLeverage: (fundId, data) => request.put(`/manager-link/funds/${fundId.trim()}/leverages`, data),
    deleteFundLeverage: (fundId, date) =>
      request.delete(`/manager-link/funds/${fundId.trim()}/leverages`, {
        data: {
          work_date: date,
        },
      }),

    createFundInstrument: (fundId, data) => request.post(`/manager-link/funds/${fundId.trim()}/instruments`, data),
    updateFundInstrument: (fundId, data) => request.put(`/manager-link/funds/${fundId.trim()}/instruments`, data),
    deleteFundInstrument: (fundId, date, name) =>
      request.delete(`/manager-link/funds/${fundId.trim()}/instruments`, {
        data: {
          work_date: date,
          name,
        },
      }),

    createFundIndustry: (fundId, data) => request.post(`/manager-link/funds/${fundId.trim()}/industry-groups`, data),
    updateFundIndustry: (fundId, data) => request.put(`/manager-link/funds/${fundId.trim()}/industry-groups`, data),
    deleteFundIndustry: (fundId, date, name) =>
      request.delete(`/manager-link/funds/${fundId.trim()}/industry-groups`, {
        data: {
          work_date: date,
          name,
        },
      }),

    createFundInvestorType: (fundId, data) => request.post(`/manager-link/funds/${fundId.trim()}/investor-types`, data),
    updateFundInvestorType: (fundId, data) => request.put(`/manager-link/funds/${fundId.trim()}/investor-types`, data),
    deleteFundInvestorType: (fundId, date, name) =>
      request.delete(`/manager-link/funds/${fundId.trim()}/investor-types`, {
        data: {
          work_date: date,
          name,
        },
      }),

    createFundRegionWeight: (fundId, data) => request.post(`/manager-link/funds/${fundId.trim()}/region-weights`, data),
    updateFundRegionWeight: (fundId, data) => request.put(`/manager-link/funds/${fundId.trim()}/region-weights`, data),
    deleteFundRegionWeight: (fundId, date, name) =>
      request.delete(`/manager-link/funds/${fundId.trim()}/region-weights`, {
        data: {
          work_date: date,
          name,
        },
      }),
    //Due diligence Investor
    fetchDueDiligenceInvestor: (fundId) => request.get(`/manager-link/due-diligence/investor-opinion/${fundId.trim()}`),
    updateDueDiligenceInvestor: (fundId, params) =>
      request.put(`/manager-link/due-diligence/investor-opinion/${fundId.trim()}`, params),
    //Due Diligence Scorecard
    fetchDueDiligenceScoreCard: (fundId) => request.get(`/manager-link/due-diligence/scorecard/${fundId.trim()}`),
    resetDueDiligenceScoreCard: (fundId) => request.delete(`/manager-link/due-diligence/scorecard/${fundId.trim()}`),
    updateDueDiligenceScoreCard: (fundId, params) =>
      request.put(`/manager-link/due-diligence/scorecard/${fundId.trim()}`, params),
    //Due Diligence Monthly
    fetchDueDiligenceMonthly: (fundId) => request.get(`/manager-link/due-diligence/monthly/${fundId.trim()}`),
    updateDueDiligenceMonthly: (fundId, params) =>
      request.put(`/manager-link/due-diligence/monthly/${fundId.trim()}`, params),
    createDueDiligenceMonthly: (fundId, params) =>
      request.post(`/manager-link/due-diligence/monthly/${fundId.trim()}`, params),
    deleteDueDiligenceMonthly: (fundId, params) =>
      request.delete(`/manager-link/due-diligence/monthly/${fundId.trim()}`, { data: params }),
    //Due Diligence Documents
    fetchDueDiligenceDocuments: (firmId) => request.get(`/manager-link/firms/${firmId.trim()}/sharepoint_urls`),
    // Principals
    fetchFundPrincipals: (fundId) => request.get(`/manager-link/fund-principals/${fundId.trim()}`),
    updateFundPrincipals: (fundId, params) => request.put(`/manager-link/fund-principals/${fundId.trim()}`, params),
    createFundPrincipals: (fundId, params) => request.post(`/manager-link/fund-principals/${fundId.trim()}`, params),
    deleteFundPrincipals: (fundId, params) =>
      request.delete(`/manager-link/fund-principals/${fundId.trim()}`, { data: params }),
    //APGL
    fetchFundAPGL: (fundId) => request.get(`/manager-link/funds/${fundId.trim()}/apgl`),
    //Fund Properties
    fetchFundProperties: (fundId) => request.get(`/manager-link/fund-properties/${fundId.trim()}`),
    updateFundProperties: (fundId, params) => request.put(`/manager-link/fund-properties/${fundId}`, params),
    createFundProperties: (fundId, params) => request.post(`/manager-link/fund-properties/${fundId}`, params),
    deleteFundProperties: (fundId, prodId) =>
      request.delete(`/manager-link/fund-properties/${fundId}`, { data: { prop_id: prodId } }),
    //Fund Table Dropdown
    fetchFundTableDropdown: () => request.get(`/manager-link/table-dropdowns`),
    //Fund Status Activate
    updateFundStatusActivate: (fundId, params) => request.post(`/manager-link/funds/${fundId.trim()}/activate`, params),
    cloneFund: (fundId, fundName) =>
      request.post(`/manager-link/funds/${fundId.trim()}/clone`, {
        fund_name: fundName,
      }),
    //Electronic Platform
    fetchListPlatform: (fundId) => request.get(`/manager-link/funds/${fundId.trim()}/electronic-trading-platforms`),
    updateListPlatform: (fundId, platforms) =>
      request.put(`/manager-link/funds/${fundId.trim()}/electronic-trading-platforms`, { platforms: platforms }),
    previewDeleteFund: (fundId) => request.get(`/manager-link/funds/${fundId.trim()}/preview-delete`),
    deleteFund: (fundId) => request.delete(`/manager-link/funds/${fundId.trim()}`),
    // Fund ESG Strategies
    fetchESGStrategies: () => request.get('/manager-link/esg-strategies'),
    // Fund Index Eligibility Details
    fetchFundIndexEligibilityDetails: ({ fundId, indexFamilyId }) =>
      request.get(`/manager-link/funds/${fundId}/index-families/${indexFamilyId}/eligibility-details`),
    updateFundIndexEligibilityDetails: ({ fundId, indexFamilyId, body }) =>
      request.post(`/manager-link/funds/${fundId}/index-families/${indexFamilyId}/eligibility-details`, body),
  }
}
