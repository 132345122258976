/**
 * Helper to check valid Strings: not null, not undefined, not blank
 * @returns true if the string is Valid, otherwise false
 * @param str
 */
import { snakeCase } from 'snake-case'

export const isValidString = (str) => {
  if (str === undefined || str === null) {
    return false
  }
  return str !== ''
}

export const sanatizeToSnakeCase = (map) => {
  const entries = Object.entries(map)
  const newMap = {}
  entries.forEach(([key, value]) => {
    if (isValidString(value)) {
      newMap[snakeCase(key)] = value
    }
  })
  return newMap
}
