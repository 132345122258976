import React from 'react'
import styled from 'styled-components'
import Principals from '../../Dashboard/FundDetail/FundInformation/FundInfo/Principals'
const Flex = styled.div`
  display: flex;
  flex: 1;
  margin-top: 0.5rem;
  padding: 0 2rem;
  flex-direction: column;
`
export const ExFundPrincipals: React.FC = () => {
  return (
    <Flex>
      <Principals />
    </Flex>
  )
}
