import { DeleteTwoTone } from '@ant-design/icons'
import { Button, Checkbox, notification, Popconfirm, Table, Tooltip } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../shared/api'
import { FundInUniverseDetails } from '../../shared/api/models/UniverseManager'
import { Colors } from '../../shared/colors'
import { useServiceState } from '../../shared/hooks/useServiceState'
import { UniverseManagerModalAddFund } from './UniverseManagerModalAddFund'

const TextID = styled(Text)`
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
`
const Flex = styled.div`
  display: flex;
  flex: 1;
`

type ListTabType = {
  id: number
  name: string
  type: 'universe' | 'funds'
}

type Props = {
  name: string
  universeId: number
  listTab: ListTabType[]
  setListTab: (value: ListTabType[]) => void
  setActiveTab: (value: string) => void
}
export const UniverseManagerDetails: React.FC<Props> = ({ name, universeId, listTab, setListTab, setActiveTab }) => {
  const { invoke: getUniverseManagerDetail, data: dataDetail, loading: dataLoading } = useServiceState(
    APIService.universeManagerService.getUniverseManagerDetail,
  )

  const [showModal, setShowModal] = React.useState(false)
  const [loadingDelete, setLoadingDelete] = React.useState(false)
  const [loadingMultiDelete, setLoadingMultiDelete] = React.useState(false)
  const [listFundDelete, setListFundDelete] = React.useState<string[]>([])
  const [hasSelected, setHasSelected] = React.useState(false)

  React.useEffect(() => {
    getUniverseManagerDetail(universeId)
  }, [getUniverseManagerDetail, universeId])

  const updateUniverseManagerDetail = () => {
    getUniverseManagerDetail(universeId)
  }

  const deleteFunds = () => {
    setLoadingMultiDelete(true)
    APIService.universeManagerService
      .updateUniverseManager(universeId, { delete_fund_ids: listFundDelete })
      .then(() => {
        notification.success({ message: 'Deleted Fund' })
        setListTab(listTab.filter((item) => item.type !== 'funds'))
        getUniverseManagerDetail(universeId)
        setHasSelected(false)
        setListFundDelete([])
      })
      .catch((err) => {
        console.error({ err })
        notification.error({ message: 'Delete Failed!' })
      })
      .finally(() => {
        setLoadingMultiDelete(false)
      })
  }
  const deleteOneFunds = (fund_id: string) => {
    setLoadingDelete(true)
    APIService.universeManagerService
      .updateUniverseManager(universeId, { delete_fund_ids: [fund_id] })
      .then(() => {
        notification.success({ message: 'Deleted Fund' })
        setListTab(listTab.filter((item) => item.type !== 'funds'))
        getUniverseManagerDetail(universeId)
      })
      .catch((err) => {
        console.error({ err })
        notification.error({ message: 'Delete Failed!' })
      })
      .finally(() => {
        setLoadingDelete(false)
      })
  }

  const columns: ColumnProps<FundInUniverseDetails>[] = [
    {
      title: 'Fund ID',
      dataIndex: 'fund_id',
      key: 'fund_id',
      width: 150,
      fixed: 'left',
      sorter: (a, b) => {
        if (a.fund_id && b.fund_id) {
          return a.fund_id.localeCompare(b.fund_id)
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Fund Name',
      dataIndex: 'fund_name',
      key: 'fund_name',
      width: 300,
      fixed: 'left',
      sorter: (a, b) => {
        if (a.fund_name && b.fund_name) {
          return a.fund_name.localeCompare(b.fund_name)
        }
        return 0
      },
      render: (_, record) => (
        <Button
          type="link"
          style={{ padding: '4px 8px' }}
          onClick={() => {
            if (!listTab.find((e) => e.id.toString() === record.fund_id)) {
              setListTab([
                ...listTab,
                // FIXME: check logic here?!
                { id: parseInt(record.fund_id), name: record.fund_name, type: 'funds' },
                // { id: parseInt(record.fund_id), name: record.fund_name, type: FundFirmTypeEnum.FUNDS },
              ])
            }
            setActiveTab(record.fund_id)
          }}
        >
          <span style={{ whiteSpace: 'normal', textAlign: 'left' }}>{record.fund_name}</span>
        </Button>
      ),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Firm ID',
      dataIndex: 'firm_id',
      key: 'firm_id',
      width: 150,
    },
    {
      title: 'Firm Name',
      dataIndex: 'firm_name',
      key: 'firm_name',
      width: 300,
      render: (_, record) => <span style={{ whiteSpace: 'normal' }}>{record.firm_name}</span>,
    },
    {
      title: 'Is Active in Universe?',
      dataIndex: 'is_active_in_universe',
      key: 'is_active_in_universe',
      width: 250,
      render: (_, record) => <Checkbox checked={record.is_active_in_universe ? true : false}></Checkbox>,
    },
    {
      title: 'Fund Status Code in Universe',
      dataIndex: 'fund_status_code',
      key: 'fund_status_code',
      width: 250,
      // render: (_, record) => <Checkbox checked={record.fund_status_code ? true : false}></Checkbox>,
    },
    {
      title: 'Fund Status in Universe',
      dataIndex: 'fund_status_in_universe',
      key: 'fund_status_in_universe',
      width: 250,
    },
    {
      title: 'Startegy',
      dataIndex: 'strategy_new_name',
      key: 'strategy_new_name',
      width: 250,
    },
    {
      title: 'Sub Strategy',
      dataIndex: 'sub_strategy_new_name',
      key: 'sub_strategy_new_name',
      width: 250,
    },
    {
      title: 'Fund Status Code',
      dataIndex: 'fund_status_code',
      key: 'fund_status_code',
      width: 250,
    },
    {
      title: 'Currency Code',
      dataIndex: 'curr_code',
      key: 'curr_code',
      width: 250,
    },
    {
      title: 'Reporting Style',
      dataIndex: 'reporting_style',
      key: 'reporting_style',
      width: 250,
    },
    {
      title: 'Does Accept New Investment?',
      dataIndex: 'does_accept_new_invest',
      key: 'does_accept_new_invest',
      width: 250,
      render: (_, record) => <Checkbox checked={record.does_accept_new_invest ? true : false}></Checkbox>,
    },
    {
      title: 'Does Not Report AUM?',
      dataIndex: 'is_aum_distributed',
      key: 'is_aum_distributed',
      width: 250,
      render: (_, record) => <Checkbox checked={record.is_aum_distributed ? true : false}></Checkbox>,
    },
    {
      title: 'Is Denom. not USD?',
      dataIndex: 'is_denom_not_usd',
      key: 'is_denom_not_usd',
      width: 250,
      // render: (_, record) => <Checkbox checked={record.is_denom_not_usd ? true : false}></Checkbox>,
    },
    {
      title: 'Is HFRX Fund?',
      dataIndex: 'is_hfrx_fund',
      key: 'is_hfrx_fund',
      width: 250,
      render: (_, record) => <Checkbox checked={record.is_hfrx_fund ? true : false}></Checkbox>,
    },
    {
      title: 'Is Index Fund?',
      dataIndex: 'is_index_fund',
      key: 'is_index_fund',
      width: 250,
      render: (_, record) => <Checkbox checked={record.is_index_fund ? true : false}></Checkbox>,
    },
    {
      title: 'Is Net Of All Fees?',
      dataIndex: 'is_net_of_all_fees',
      key: 'is_net_of_all_fees',
      width: 250,
      // render: (_, record) => <Checkbox checked={record.is_net_of_all_fees ? true : false}></Checkbox>,
    },
    {
      title: 'Is NOT Net Of All Fees?',
      dataIndex: 'is_not_not_of_all_fees',
      key: 'is_not_not_of_all_fees',
      width: 250,
      // render: (_, record) => <Checkbox checked={record.is_not_not_of_all_fees ? true : false}></Checkbox>,
    },
    {
      title: 'Similar/Related Fund ID',
      dataIndex: 'related_fund_id',
      key: 'related_fund_id',
      width: 250,
    },
    {
      title: 'Similar/Related Fund Name',
      dataIndex: 'related_fund_name',
      key: 'related_fund_name',
      width: 250,
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      width: 250,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      fixed: 'right',
      width: 100,
      render: (_, record) => (
        <>
          <Popconfirm title="Are you sure you want to remove?" onConfirm={() => deleteOneFunds(record.fund_id)}>
            <Tooltip title="Remove">
              <Button type="link" style={{ padding: '4px 8px' }} loading={loadingDelete}>
                <DeleteTwoTone twoToneColor={Colors.danger} />
              </Button>
            </Tooltip>
          </Popconfirm>
        </>
      ),
    },
  ]

  const onSelectChange = (selectedRowKeys: string[]) => {
    setListFundDelete(selectedRowKeys)
    setHasSelected(selectedRowKeys.length > 0)
  }

  return (
    <div style={{ padding: '1rem' }}>
      <div>
        <TextID>Universe Manager #{universeId}</TextID>
        <Flex style={{ marginBottom: 10, alignItems: 'baseline' }}>
          <Flex>
            <Text style={{ fontSize: 24, fontWeight: 'bold', color: '#484848' }}>{name}</Text>
          </Flex>
          <Flex style={{ justifyContent: 'flex-end' }}>
            <Popconfirm title="Are you sure you want to delete?" onConfirm={deleteFunds} disabled={!hasSelected}>
              <Button
                danger
                type="primary"
                style={{ marginRight: '0.5rem' }}
                disabled={!hasSelected}
                loading={loadingMultiDelete}
              >
                Remove{listFundDelete.length > 0 ? ` ` + listFundDelete.length : ''} Funds
              </Button>
            </Popconfirm>
            <Button type="primary" onClick={() => setShowModal(true)}>
              Add New Fund
            </Button>
          </Flex>
        </Flex>
        <UniverseManagerModalAddFund
          indexId={universeId}
          showModal={showModal}
          setShowModal={setShowModal}
          updateUniverseManagerDetail={updateUniverseManagerDetail}
        />
      </div>
      <Table
        rowKey="fund_id"
        rowSelection={{
          onChange: (selectedRowKeys) => {
            onSelectChange(selectedRowKeys as string[])
          },
        }}
        dataSource={dataDetail?.funds}
        columns={columns}
        scroll={{ x: 'min-content' }}
        loading={dataLoading}
      />
    </div>
  )
}
