import { Col, Form, Row, Spin } from 'antd'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { DUE_DILIGENCE_REQUEST_ENUM, FundDetailDataContext } from '../../../../shared/api/context/FundDetailDataContext'
import { DividerForm } from '../../../../shared/components/DividerForm'
import { FormItemDatePicker, FormItemInput, FormItemTextArea } from '../../../../shared/components/FormItemComponents'
import { NextBackButton } from '../../../../shared/components/NextBackButton'
import { RenderLabelForm } from '../../../../shared/components/RenderLabelForm'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5rem;
  padding-right: 3rem;
  flex: 1;
  margin-top: 1rem;
`
const LIST_FORM_TEXTAREA = [
  {
    text: 'Investment Rationale',
    name: 'rationale',
  },
  {
    text: 'Firm',
    name: 'firm',
  },
  {
    text: 'Strategy',
    name: 'strategy',
  },
]
type Props = {}
export const InvestOpinion: React.FC<Props> = () => {
  const [form] = Form.useForm()
  const {
    getDueDiligenceRequest,
    dataInvestorOpinion,
    loadingInvestorOpinion,
    setDataChangeInvestor,
  } = React.useContext(FundDetailDataContext)

  React.useEffect(() => {
    getDueDiligenceRequest(DUE_DILIGENCE_REQUEST_ENUM.DUE_DILIGENCE_INVESTOR)
  }, [getDueDiligenceRequest])

  React.useEffect(() => {
    if (dataInvestorOpinion) {
      const initialValue = dataInvestorOpinion && {
        ...dataInvestorOpinion,
        date_time: dataInvestorOpinion.date_time ? moment(dataInvestorOpinion.date_time) : null,
      }
      form.setFieldsValue(initialValue)
    } else {
      form.resetFields()
    }
  }, [dataInvestorOpinion, form])

  return (
    <Wrapper>
      {loadingInvestorOpinion ? (
        <Spin />
      ) : (
        <Form
          form={form}
          onValuesChange={(data) => {
            setDataChangeInvestor((prev: any) => ({ ...prev, ...data }))
          }}
        >
          <Row>
            <Col span={8}>
              <FormItemDatePicker
                name="date_time"
                label={<RenderLabelForm value="Last Update On" />}
                labelCol={12}
                wrapperCol={12}
                disabled
              />
            </Col>
            <Col span={8}>
              <FormItemInput
                name="oper_id"
                label={<RenderLabelForm value="by" />}
                labelCol={12}
                wrapperCol={12}
                disabled
              />
            </Col>
          </Row>
          {LIST_FORM_TEXTAREA.map((item) => {
            return (
              <div key={item.name}>
                <DividerForm text={<RenderLabelForm value={item.text} />} />
                <FormItemTextArea name={item.name} row={7} />
              </div>
            )
          })}
          <NextBackButton />
        </Form>
      )}
    </Wrapper>
  )
}
