import React from 'react'
import { FirmDetailLabelSearchContext } from '../api/context/FirmDetailLabelSearchContext'
import { Colors } from '../colors'
type Props = {
  value: string
  boldText?: string
}
export const RenderLabelFormFirm: React.FC<Props> = ({ value }) => {
  const { boldText } = React.useContext(FirmDetailLabelSearchContext)
  const index = value.toUpperCase().indexOf(boldText.toUpperCase())
  return (
    <span>
      {boldText === '' || index === -1 ? (
        <span style={{ whiteSpace: 'normal' }}>{value}</span>
      ) : (
        <span style={{ whiteSpace: 'normal' }}>
          <span>{value.slice(0, index)}</span>
          <span style={{ fontWeight: 'bold', background: `${Colors.border}` }}>
            {value.slice(index, index + boldText.length)}
          </span>
          <span>{value.slice(index + boldText.length, value.length)}</span>
        </span>
      )}
    </span>
  )
}
