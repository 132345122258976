import { DeleteTwoTone, EditOutlined } from '@ant-design/icons'
import { Button, notification, Popconfirm, Tooltip } from 'antd'
import Table, { ColumnProps } from 'antd/lib/table'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../../../shared/api'
import { FundDetailDataContext } from '../../../../shared/api/context/FundDetailDataContext'
import { DueDiligenceMonthly } from '../../../../shared/api/models/FundDetails'
import { Colors } from '../../../../shared/colors'
import { NextBackButton } from '../../../../shared/components/NextBackButton'
import { useServiceState } from '../../../../shared/hooks/useServiceState'
import { ModalEditTableMonthly } from './ModalEditTableMonthly'
const Flex = styled.div`
  display: flex;
  flex: 1;
`

export const Monthly: React.FC = () => {
  const { dataFundDetail } = React.useContext(FundDetailDataContext)
  const [selectedMonthly, setSelectedMonthly] = React.useState<DueDiligenceMonthly | undefined>()
  const [showModal, setShowModal] = React.useState(false)
  const [loadingDelete, setLoadingDelete] = React.useState(false)

  const { invoke: getDataMonthly, data: dataMonthly, loading } = useServiceState(
    APIService.fundDetailService.fetchDueDiligenceMonthly,
  )

  React.useEffect(() => {
    dataFundDetail?.fund_id && getDataMonthly(dataFundDetail.fund_id)
  }, [dataFundDetail, getDataMonthly])

  const updateMonthly = () => {
    getDataMonthly(dataFundDetail?.fund_id)
  }

  const deleteMonthly = (date: string) => {
    if (dataFundDetail?.fund_id) {
      const paramsSubmit = {
        date: date,
      }
      setLoadingDelete(true)
      APIService.fundDetailService
        .deleteDueDiligenceMonthly(dataFundDetail?.fund_id, paramsSubmit)
        .then(() => {
          notification.success({ message: 'Monthly Delete!' })
          updateMonthly()
        })
        .catch((err) => {
          console.error({ err })
          notification.error({ message: 'Monthly Delete Failed!' })
        })
        .finally(() => {
          setLoadingDelete(false)
        })
    }
  }

  const columns: ColumnProps<DueDiligenceMonthly>[] = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: '10%',
    },
    {
      title: 'Operator ID',
      dataIndex: 'oper_id',
      key: 'oper_id',
      width: '20%',
    },
    {
      title: 'Monthly',
      dataIndex: 'monthly',
      key: 'monthly',
      width: '20%',
      ellipsis: true,
    },
    {
      title: 'Slippage',
      dataIndex: 'slippage',
      key: 'slippage',
      width: '20%',
      ellipsis: true,
    },
    {
      title: 'Internal',
      dataIndex: 'internal',
      key: 'internal',
      width: '20%',
      ellipsis: true,
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: '10%',
      render: (_, record) => (
        <>
          <Button
            type="link"
            style={{ padding: '4px 8px' }}
            onClick={() => {
              setShowModal(true)
              setSelectedMonthly(record)
            }}
          >
            <Tooltip title="Edit">
              <EditOutlined />
            </Tooltip>
          </Button>

          <Popconfirm title="Are you sure you want to delete?" onConfirm={() => deleteMonthly(record.date)}>
            <Button type="link" style={{ padding: '4px 8px' }} loading={loadingDelete}>
              <Tooltip title="Delete">
                <DeleteTwoTone twoToneColor={Colors.danger} />
              </Tooltip>
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ]
  return (
    <Flex style={{ flexDirection: 'column' }}>
      <div style={{ marginTop: '0.5rem', display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="primary" style={{ marginBottom: 8, marginRight: 8 }} onClick={() => setShowModal(true)}>
          Create New Monthly
        </Button>
      </div>
      <Table
        rowKey="date"
        dataSource={dataMonthly}
        columns={columns}
        loading={loading}
        pagination={dataMonthly && dataMonthly.length > 10 ? undefined : false}
        bordered
      />
      {dataFundDetail?.fund_id && (
        <ModalEditTableMonthly
          showModal={showModal}
          selectedMonthly={selectedMonthly}
          setShowModal={setShowModal}
          setSelectedMonthly={setSelectedMonthly}
          updateMonthly={updateMonthly}
          fundId={dataFundDetail.fund_id}
        />
      )}

      <NextBackButton />
    </Flex>
  )
}
