import { Col, Form, Radio, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'
import { FundDetailDropdownContext } from '../../../shared/api/context/FundDetailDropdownContext'
import { FormItemInput, FormItemRadio, FormItemSelect } from '../../../shared/components/FormItemComponents'
import { RenderLabelExNewFund } from '../../../shared/components/RenderLabelExNewFund'
import { LeftNavExNewFundEnum } from '../../../shared/SearchExNewFundLabel'
import { NextBackNewFundTab } from './NextBackExNewFundTab'
const Flex = styled.div`
  display: flex;
  flex: 1;
  margin-top: 1rem;
  flex-direction: column;
  padding: 0 2rem;
`
type Props = {
  setStepTab: (value: LeftNavExNewFundEnum) => void
  setActiveTab: (value: LeftNavExNewFundEnum) => void
  activeTab: LeftNavExNewFundEnum
  stepTab: LeftNavExNewFundEnum
  form: FormInstance
}
const ARRAY_FIELD_VALIDATE: string[] = [
  'investor_type',
  'equity_subscription',
  'subscription_advance_notice',
  'subscription_cutoff_time',
  'subscription_cutoff_timezone',
  'subscription_settlement_days',
  'equity_redemption',
  'redemption_return_percent',
  'redemption_return_days',
  'gate_fund',
  'gate_investor',
  'use_of_leverage_type',
  'is_lockup',
  'ucitsiii',
  'redemption_advance_notice',
  'balance_redemption_return_days',
]

export const ExNewFundStructure: React.FC<Props> = ({ form, setStepTab, setActiveTab, stepTab, activeTab }) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)

  const lockupField = form.getFieldValue('is_lockup') === '1'
  const isLiquidCheck = form.getFieldValue('liquid_alt_product') as boolean
  const domicileField = form.getFieldValue('domicile')

  React.useEffect(() => {
    if (domicileField !== 'Other') {
      form.setFieldsValue({ domicile_input: null })
    }
  }, [domicileField, form])

  const ArrayValidate = React.useMemo(() => {
    const array = domicileField === 'Other' ? [...ARRAY_FIELD_VALIDATE, 'domicile_input'] : ARRAY_FIELD_VALIDATE
    if (isLiquidCheck) {
      return lockupField ? [...array, 'lockup_type', 'lockup_period'] : array
    } else {
      const arrayField = [...array, 'domicile', 'structure', 'registration_code']
      return lockupField ? [...arrayField, 'lockup_type', 'lockup_period'] : arrayField
    }
  }, [domicileField, isLiquidCheck, lockupField])

  const LIST_FORM = [
    {
      name: 'investor_type',
      label: 'Open To Investor Type',
      options:
        fundDataDropdown?.investor_type && fundDataDropdown.investor_type.length > 0
          ? fundDataDropdown.investor_type.map((item) => ({ value: item, text: item }))
          : [],
      rules: [{ required: true, message: 'Open To Investor Type is required!' }],
    },
    {
      name: 'equity_subscription',
      label: 'Subscription Frequency',
      options:
        fundDataDropdown?.equity_subscription && fundDataDropdown.equity_subscription.length > 0
          ? fundDataDropdown.equity_subscription.map((item) => ({ value: item.code_code, text: item.code_description }))
          : [],
      rules: [{ required: true, message: 'Subscription Frequency is required!' }],
    },
    {
      name: 'subscription_advance_notice',
      label: 'Subscription Advanced Notice (Days)',
      options:
        fundDataDropdown?.subscription_advance_notice && fundDataDropdown.subscription_advance_notice.length > 0
          ? fundDataDropdown.subscription_advance_notice.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
      rules: [{ required: true, message: 'Subscription Advanced Notice (Days) is required!' }],
    },
    {
      name: 'subscription_cutoff_time',
      label: 'Subscription Cut Off By (Time)',
      options:
        fundDataDropdown?.subscription_cutoff_time && fundDataDropdown.subscription_cutoff_time.length > 0
          ? fundDataDropdown.subscription_cutoff_time.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
      rules: [{ required: true, message: 'Subscription Cut Off By (Time) is required!' }],
    },
    {
      name: 'subscription_cutoff_timezone',
      label: 'Subscription Cut Off By (Time Zone)',
      options:
        fundDataDropdown?.subscription_cutoff_timezone && fundDataDropdown.subscription_cutoff_timezone.length > 0
          ? fundDataDropdown.subscription_cutoff_timezone.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
      rules: [{ required: true, message: 'Subscription Cut Off By (Time Zone) is required!' }],
    },
    {
      name: 'subscription_settlement_days',
      label: 'Subscription Settlement (Days)',
      options:
        fundDataDropdown?.subscription_settlement_days && fundDataDropdown.subscription_settlement_days.length > 0
          ? fundDataDropdown.subscription_settlement_days.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
      rules: [{ required: true, message: 'Subscription Settlement (Days) is required!' }],
    },
    {
      name: 'equity_redemption',
      label: 'Redemption Frequency',
      options:
        fundDataDropdown?.equity_redemption && fundDataDropdown.equity_redemption.length > 0
          ? fundDataDropdown.equity_redemption.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
      rules: [{ required: true, message: 'Redemption Frequency is required!' }],
    },
    {
      name: 'redemption_advance_notice',
      label: 'Redemption Advanced Notice (Days)',
      typeField: 'number',
      normalize: 'number',
      rules: [{ required: true, message: 'Redemption Advanced Notice (Days) is required!' }],
    },
    {
      name: 'redemption_return_percent',
      label: 'Initial % Of Redemption Returned',
      rules: [{ required: true, message: 'Initial % Of Redemption Returned is required!' }],
      typeField: 'number',
      normalize: 'number',
    },
    {
      name: 'redemption_return_days',
      label: 'Initial Redemption Returned Within (Days)',
      rules: [{ required: true, message: 'Initial Redemption Returned Within (Days) is required!' }],
      typeField: 'number',
      normalize: 'number',
    },
    {
      name: 'balance_redemption_return_days',
      label: 'Redemption Balance Returned Within (Days)',
      typeField: 'number',
      normalize: 'number',
      rules: [{ required: true, message: 'Redemption Balance Returned Within (Days) is required!' }],
    },
    {
      name: 'is_lockup',
      label: 'Lockup',
      isRadio: true,
      options:
        fundDataDropdown?.is_lockup && fundDataDropdown.is_lockup.length > 0
          ? fundDataDropdown.is_lockup
              .filter((i) => i.code_code !== '0')
              .map((item) => ({
                value: item.code_code,
                text: item.code_description,
              }))
          : [],
      rules: [{ required: true, message: 'Lockup is required!' }],
    },
  ]
  const LIST_FORM_LOCKUP = [
    {
      name: 'lockup_type',
      label: 'Lockup Type',
      disabled: !lockupField,
      options:
        fundDataDropdown?.lockup_type && fundDataDropdown.lockup_type.length > 0
          ? fundDataDropdown.lockup_type
              .filter((i) => i.code_code !== '0')
              .map((item) => ({
                value: item.code_code,
                text: item.code_description,
              }))
          : [],
      rules: [{ required: true, message: 'Lockup Type is required!' }],
    },
    {
      name: 'lockup_period',
      label: 'Lockup Period',
      disabled: !lockupField,
      options:
        fundDataDropdown?.lockup_period && fundDataDropdown.lockup_period.length > 0
          ? fundDataDropdown.lockup_period.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
      rules: [{ required: true, message: 'Lockup Period is required!' }],
    },
  ]
  const LIST_FORM_GATE = [
    {
      name: 'gate_fund',
      label: 'Gate: Fund-Level',
      options:
        fundDataDropdown?.gate_fund && fundDataDropdown.gate_fund.length > 0
          ? fundDataDropdown.gate_fund
              .filter((i) => i.code_code !== '0')
              .map((item) => ({
                value: item.code_code,
                text: item.code_description,
              }))
          : [],
      rules: [{ required: true, message: 'Gate:Fund Level is required!' }],
    },
    {
      name: 'gate_investor',
      label: 'Gate: Investor-Level',
      options:
        fundDataDropdown?.gate_investor && fundDataDropdown.gate_investor.length > 0
          ? fundDataDropdown.gate_investor
              .filter((i) => i.code_code !== '0')
              .map((item) => ({
                value: item.code_code,
                text: item.code_description,
              }))
          : [],
      rules: [{ required: true, message: 'Gate:Investor Level is required!' }],
    },
    {
      name: 'performance_reporting',
      label: 'Performance Reporting Interval to Investors',
      options:
        fundDataDropdown?.performance_reporting && fundDataDropdown.performance_reporting.length > 0
          ? fundDataDropdown.performance_reporting.map((item) => ({
              value: item,
              text: item,
            }))
          : [],
    },
    {
      name: 'use_of_leverage_type',
      label: 'Leverage Type',
      options:
        fundDataDropdown?.use_of_leverage_type && fundDataDropdown.use_of_leverage_type.length > 0
          ? fundDataDropdown.use_of_leverage_type
              .filter((i) => i.code_code !== '5')
              .map((item) => ({
                value: item.code_code,
                text: item.code_description,
              }))
          : [],
      rules: [{ required: true, message: 'Leverage Type is required!' }],
    },
  ]
  const LIST_FORM_FOOTER = [
    {
      name: 'isin',
      label: 'ISIN',
      maxLength: 20,
    },
    {
      name: 'bloomberg_ticker',
      label: 'Ticker',
      maxLength: 20,
    },
  ]

  return (
    <Flex>
      {!isLiquidCheck && (
        <Row>
          <Col span={12} xl={12} md={16} xs={24} sm={24}>
            <Form.Item
              name="is_offshore"
              label={<RenderLabelExNewFund value="Please Choose One" />}
              {...{ wrapperCol: { span: 16 } }}
            >
              <Radio.Group>
                <Radio value={false}>U.S.</Radio>
                <Radio value={true}>Offshore</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12} xl={12} md={16} xs={24} sm={24}>
            <FormItemSelect
              name="registration_code"
              rules={[{ required: true, message: 'Fund Registration is required!' }]}
              label={<RenderLabelExNewFund value="Fund Registration" />}
              wrapperCol={16}
              options={
                fundDataDropdown?.registration_code && fundDataDropdown.registration_code.length > 0
                  ? fundDataDropdown.registration_code.map((item) => ({ value: item, text: item }))
                  : []
              }
            />
          </Col>
          <Col span={12} xl={12} md={16} xs={24} sm={24}>
            <FormItemSelect
              name="domicile"
              rules={[{ required: true, message: 'Domicile is required!' }]}
              label={<RenderLabelExNewFund value="Domicile Of The Fund" />}
              wrapperCol={16}
              options={
                fundDataDropdown?.domicile && fundDataDropdown.domicile.length > 0
                  ? fundDataDropdown.domicile.map((item) => ({ value: item, text: item }))
                  : []
              }
            />
          </Col>
          <Col span={12} xl={12} md={16} xs={24} sm={24} style={{ fontStyle: 'italic' }}>
            <FormItemInput
              name="domicile_input"
              label={<RenderLabelExNewFund value="If Other, Specify Domicile" />}
              wrapperCol={16}
              maxLength={30}
              rules={
                domicileField === 'Other'
                  ? [
                      {
                        required: true,
                        message: 'Domicile is required!',
                      },
                    ]
                  : undefined
              }
              disabled={domicileField !== 'Other'}
            />
          </Col>
          <Col span={12} xl={12} md={16} xs={24} sm={24}>
            <FormItemSelect
              name="structure"
              rules={[{ required: true, message: 'Structure is required!' }]}
              label={<RenderLabelExNewFund value="Structure " />}
              wrapperCol={16}
              options={
                fundDataDropdown?.structure && fundDataDropdown.structure.length > 0
                  ? fundDataDropdown.structure.map((item) => ({ value: item, text: item }))
                  : []
              }
            />
          </Col>
          <Col span={12} xl={12} md={16} xs={24} sm={24}>
            <FormItemSelect
              name="multi_manager_platform"
              rules={[{ required: true, message: 'Decision is required!' }]}
              label={
                <RenderLabelExNewFund value='Does fund utilize an internal Multi-Manager Platform (aka, "Pods")?' />
              }
              wrapperCol={16}
              options={
                fundDataDropdown?.gate_fund && fundDataDropdown.gate_fund.length > 0
                  ? fundDataDropdown.gate_fund
                      .filter((i) => i.code_code !== '0')
                      .map((item) => ({
                        value: item.code_code,
                        text: item.code_description,
                      }))
                  : []
              }
            />
          </Col>
        </Row>
      )}
      <Row>
        {LIST_FORM.map((item) => {
          if (item.name === 'redemption_return_percent' || item.name === 'redemption_return_days') {
            return (
              <Col span={12} xl={12} md={16} xs={24} sm={24} key={item.name}>
                <FormItemInput
                  name={item.name}
                  label={<RenderLabelExNewFund value={item.label} />}
                  wrapperCol={16}
                  normalize={item.normalize}
                  typeField={item.typeField}
                  rules={[
                    ...item.rules,
                    item.name === 'redemption_return_percent'
                      ? () => ({
                          validator(rule, value) {
                            if ((value >= 0 && value <= 100) || value === null) {
                              return Promise.resolve()
                            }
                            return Promise.reject('Value must be between 0 and 100!')
                          },
                        })
                      : () => ({
                          validator(rule, value) {
                            if (value >= 0 || value === null) {
                              return Promise.resolve()
                            }
                            return Promise.reject('Value must be greater than or equal to 0!')
                          },
                        }),
                  ]}
                />
              </Col>
            )
          }
          return (
            <Col span={12} xl={12} md={16} xs={24} sm={24} key={item.name}>
              {item.isRadio ? (
                <FormItemRadio
                  name={item.name}
                  label={<RenderLabelExNewFund value={item.label} />}
                  rules={item.rules}
                  options={item.options.map((item) => ({ value: item.value, title: item.text }))}
                />
              ) : item.options ? (
                <FormItemSelect
                  name={item.name}
                  label={<RenderLabelExNewFund value={item.label} />}
                  wrapperCol={16}
                  options={item.options}
                  rules={item.rules}
                />
              ) : (
                <FormItemInput
                  name={item.name}
                  label={<RenderLabelExNewFund value={item.label} />}
                  wrapperCol={16}
                  rules={item.rules}
                  typeField={item.typeField}
                  normalize={item.normalize}
                  min={0}
                />
              )}
            </Col>
          )
        })}
      </Row>
      {lockupField && (
        <Row>
          {LIST_FORM_LOCKUP.map((item) => {
            return (
              <Col span={12} xl={12} md={16} xs={24} sm={24} key={item.name}>
                <FormItemSelect
                  name={item.name}
                  label={<RenderLabelExNewFund value={item.label} />}
                  wrapperCol={16}
                  options={item.options}
                  rules={item.rules}
                  disabled={item.disabled}
                />
              </Col>
            )
          })}
        </Row>
      )}
      <Row>
        {LIST_FORM_GATE.map((item) => {
          return (
            <Col span={12} xl={12} md={16} xs={24} sm={24} key={item.name}>
              <FormItemSelect
                name={item.name}
                label={<RenderLabelExNewFund value={item.label} />}
                wrapperCol={16}
                options={item.options}
                rules={item.rules}
              />
            </Col>
          )
        })}
      </Row>
      <Row>
        <Col span={24}>
          <FormItemRadio
            name="ucitsiii"
            label={
              <RenderLabelExNewFund value='If The Fund Vehicle follows UCITS Guidelines And Is Approved By An Authorized European Regulator As UCITS Compliant, Please Indicate "Yes", If Not, Check "No"' />
            }
            rules={[{ required: true, message: 'This Field is required!' }]}
            {...{ wrapperCol: { span: 16 } }}
            options={
              fundDataDropdown?.ucitsiii && fundDataDropdown.ucitsiii.length > 0
                ? fundDataDropdown.ucitsiii
                    .filter((i) => i !== 'Not Responded')
                    .map((item) => ({ value: item, title: item }))
                : []
            }
          />
        </Col>
      </Row>
      <Row>
        {LIST_FORM_FOOTER.map((item) => {
          return (
            <Col span={12} xl={12} md={16} xs={24} sm={24} key={item.name}>
              <FormItemInput
                name={item.name}
                label={<RenderLabelExNewFund value={item.label} />}
                wrapperCol={16}
                maxLength={item.maxLength}
              />
            </Col>
          )
        })}
      </Row>
      <NextBackNewFundTab
        setStepTab={setStepTab}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        stepTab={stepTab}
        ArrayValidateField={ArrayValidate}
        form={form}
      />
    </Flex>
  )
}
