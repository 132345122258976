import { Col, Row } from 'antd'
import React from 'react'
import { FormItemDatePicker, FormItemSelect } from '../../../../shared/components/FormItemComponents'
import { RenderLabelForm } from '../../../../shared/components/RenderLabelForm'
import {
  FundIndexEligibilityDetails,
  FundIndexEligibilityDetailsKeys,
  FundIndexEligibilityDetailsResponse,
  INDEX_ELIGIBILITY_ENUM,
  WAIVE_ENUM,
} from '../../../../shared/api/models/FundDetails'
import moment from 'moment'
import { DividerForm } from '../../../../shared/components/DividerForm'
import { FundDetailDataContext } from '../../../../shared/api/context/FundDetailDataContext'

type CommonProps = {
  prefixName?: string
  name?: string
  label?: string
  children?: React.ReactNode
}

export const IndexEligibilitySelect: React.FC<CommonProps> = ({ prefixName, name, label = 'Index Eligibility' }) => {
  return (
    <Col span={6}>
      <FormItemSelect
        name={name || `${prefixName}_index_eligibility`}
        label={<RenderLabelForm value={label} />}
        labelAlign="left"
        labelCol={23}
        wrapperCol={23}
        options={IndexEligibilityOptions}
        allowClear
      />
    </Col>
  )
}

export const ConfirmedInfo: React.FC<CommonProps> = ({ prefixName, name, children }) => {
  const { userOptions } = React.useContext(FundDetailDataContext)

  return (
    <Row>
      {children}
      <IndexEligibilitySelect prefixName={prefixName} />
      <Col span={6}>
        <FormItemDatePicker
          label={<RenderLabelForm value="Last Confirmed Date" />}
          labelAlign="left"
          name={name || `${prefixName}_last_confirmed_date`}
          labelCol={23}
          wrapperCol={23}
          picker="date"
          allowClear
        />
      </Col>
      <Col span={6}>
        <FormItemSelect
          name={name || `${prefixName}_confirmed_by`}
          label={<RenderLabelForm value="Confirmed by" />}
          labelAlign="left"
          labelCol={23}
          wrapperCol={23}
          options={userOptions}
          allowClear
        />
      </Col>
    </Row>
  )
}

export const FUND_INFO_NO_DATA_LABEL = '(Fund Info: No Data)'
type WaiveCriteriaFormProps = {
  name: string
  label: string
  fundInfo?: string
}
export const WaiveCriteriaForm: React.FC<WaiveCriteriaFormProps> = ({ name, label, fundInfo }) => {
  return (
    <>
      <DividerForm text={`${label} ${fundInfo ? `(Fund Info: ${fundInfo})` : FUND_INFO_NO_DATA_LABEL}`} />
      <ConfirmedInfo prefixName={name}>
        <Col span={6}>
          <FormItemSelect
            name={name}
            label="Response to HFR Index Admin"
            labelAlign="left"
            placeholder="no or willing to waive"
            labelCol={23}
            wrapperCol={23}
            options={WaiveOptions}
            allowClear
          />
        </Col>
      </ConfirmedInfo>
    </>
  )
}

export const ReviewerInfo: React.FC<CommonProps> = ({ name, prefixName, children }) => {
  const { userOptions } = React.useContext(FundDetailDataContext)

  return (
    <Row>
      {children}
      <Col span={8}>
        <FormItemDatePicker
          label={<RenderLabelForm value="Reviewed Date" />}
          name={name || `${prefixName}_reviewed_date`}
          labelCol={10}
          wrapperCol={13}
          picker="date"
          allowClear
        />
      </Col>
      <Col span={8}>
        <FormItemSelect
          name={name || `${prefixName}_reviewed_by`}
          label={<RenderLabelForm value="Reviewed by" />}
          labelCol={10}
          wrapperCol={13}
          options={userOptions}
          allowClear
        />
      </Col>
    </Row>
  )
}

const optionsToDic = (
  options: {
    value: string | number
    text: string
    disabled?: boolean | undefined
  }[],
) => {
  return options.reduce((prev, cur) => {
    return {
      ...prev,
      [cur.value]: cur.text,
    }
  }, {} as Record<string, string | number>)
}

export const IndexEligibilityOptions = [
  {
    value: INDEX_ELIGIBILITY_ENUM.ELIGIBLE,
    text: 'Eligible',
  },
  {
    value: INDEX_ELIGIBILITY_ENUM.NOT_ELIGIBLE,
    text: 'Not Eligible',
  },
  {
    value: INDEX_ELIGIBILITY_ENUM.UNDER_REVIEW,
    text: 'Under Review',
  },
]
export const IndexEligibilityOptionsDic = optionsToDic(IndexEligibilityOptions)

export const MonthlyOptions = [
  {
    value: 'Daily',
    text: 'Daily',
  },
  {
    value: 'Weekly',
    text: 'Weekly',
  },
  {
    value: 'Semi-Monthly',
    text: 'Semi-Monthly',
  },
  {
    value: 'Monthly',
    text: 'Monthly',
  },
]
export const MonthlyOptionsDic = optionsToDic(MonthlyOptions)

export const QuarterlyOptions = [
  ...MonthlyOptions,
  {
    value: 'Bi-Monthly',
    text: 'Bi-Monthly',
  },
  {
    value: 'Quarterly',
    text: 'Quarterly',
  },
]
export const QuarterlyOptionsDic = optionsToDic(QuarterlyOptions)

export const WaiveOptions = [
  {
    value: WAIVE_ENUM.WILLING_TO_WAIVE,
    text: 'Willing to Waive',
  },
  {
    value: WAIVE_ENUM.NOT_WILLING_TO_WAIVE,
    text: 'Not Willing to Waive',
  },
  {
    value: WAIVE_ENUM.NOT_RESPONDED,
    text: 'Not Responded',
  },
]
export const WaiveOptionsDic = optionsToDic(WaiveOptions)

export const ExclusionReasonOptions = [
  {
    value: 'Due Diligence',
    text: 'Due Diligence',
  },
  {
    value: 'Firm concentration limit',
    text: 'Firm concentration limit',
  },
  {
    value: 'Redundant fund',
    text: 'Redundant fund',
  },
  {
    value: 'Other',
    text: 'Other',
  },
]
export const ExclusionReasonOptionsDic = optionsToDic(ExclusionReasonOptions)

export const SubscriptionDayTypeDic: Record<string, string> = {
  '0': '',
  '1': 'Business',
  '2': 'Calendar',
}

export const transformFundIndexEligibilityDetailDataToSubmit = (input: any) => {
  const output = {
    fund_id: input.fund_id,
    index_family_id: input.index_family_id,
  } as FundIndexEligibilityDetails
  // fill undefined with null
  FundIndexEligibilityDetailsKeys.map((key) => {
    if (typeof input[key] === 'undefined') {
      // @ts-ignore
      output[key] = undefined
      return
    }
    if (input[key] === null || input[key] === '') {
      // @ts-ignore
      output[key] = null
      return
    }
    let value = input[key]
    if (key === 'accepting_new_investments') {
      value = value === 'yes' ? true : false
    } else if (key === 'performance_added_to_family_as_of' || key === 'removed_from_family_after') {
      value = moment(value as string).format('YYYY-MM')
    } else if (key.includes('_last_confirmed_date') || key.includes('_reviewed_date')) {
      value = moment(value as string).format('YYYY-MM-DD')
    } else if (key === 'subscriptions_advanced' || key === 'redemptions_advanced') {
      value = Number(value)
    }
    // @ts-ignore
    output[key] = value
  })

  return output
}

export const transformResponseToDataForm = (input: FundIndexEligibilityDetailsResponse) => {
  const output: Record<string, any> = {
    fund_id: input.fund_id,
    index_family_id: input.index_family_id,
  }
  // skip
  FundIndexEligibilityDetailsKeys.map((key) => {
    if (input[key] === null || typeof input[key] === 'undefined') {
      // @ts-ignore
      output[key] = undefined
      return
    }
    let value: any = input[key]
    if (key === 'accepting_new_investments') {
      value = value === true ? 'yes' : 'no'
    } else if (key === 'performance_added_to_family_as_of' || key === 'removed_from_family_after') {
      value = moment(parsedDateFormat(value as string), 'YYYY-MM')
    } else if (key.includes('_last_confirmed_date') || key.includes('_reviewed_date')) {
      value = moment(value as string)
    }
    // @ts-ignore
    output[key] = value
  })
  return output
}

export const parsedDateFormat = (dateString: string) => {
  if (dateString.length === 7 && dateString[4] === '0') {
    return `${dateString.slice(0, 4)}-${dateString.slice(5, 7)}`
  }
  return dateString
}

export const displayFundIndexEligibilitySelectedOption = (key: string, value?: string | number | boolean | null) => {
  if (key === 'accepting_new_investments') {
    return value === true ? 'Yes' : 'No'
  }
  if (key === 'subscriptions_monthly' && typeof value === 'string') {
    return MonthlyOptionsDic[value]
  }
  if (key === 'redemptions_quarterly' && typeof value === 'string') {
    return QuarterlyOptionsDic[value]
  }
  if ((key === 'lockup' || key === 'gate_investor' || key === 'gate_fund') && typeof value === 'string') {
    return WaiveOptionsDic[value]
  }
  if ((key === 'eligibility_status' || key.includes('_index_eligibility')) && typeof value === 'string') {
    return IndexEligibilityOptionsDic[value]
  }
  if (key === 'exclusion_reason' && typeof value === 'string') {
    return ExclusionReasonOptionsDic[value]
  }
  return value
}
