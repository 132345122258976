import { CheckOutlined, CloseOutlined, DeleteTwoTone, EditOutlined } from '@ant-design/icons'
import { AutoComplete, Button, Col, Form, Modal, notification, Popconfirm, Row, Spin, Table, Tooltip } from 'antd'
import { Store } from 'antd/lib/form/interface'
import { ColumnProps } from 'antd/lib/table'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import APIService from '../api'
import { FundDetailDataContext } from '../api/context/FundDetailDataContext'
import { InstrumentResponseType } from '../api/models/FundDetails'
import { Colors } from '../colors'
import { useServiceState } from '../hooks/useServiceState'
import EditableCell from './EditableTable/EditableCell'
import EditableFormRow from './EditableTable/EditableFormRow'
import { FormItemDatePicker, FormItemInput } from './FormItemComponents'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  margin: 0 2rem;
  margin-top: 1rem;
  flex-direction: column;
  .ant-table-cell {
    padding: 0px 8px;
  }
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 0 12px;
  }
`
type Props = {
  isOverview?: boolean
  dataDropdown?: string[]
}
export const InstrumentsTable: React.FC<Props> = ({ isOverview, dataDropdown }) => {
  const { dataFundDetail } = React.useContext(FundDetailDataContext)
  const { invoke: getInstruments, data: instrumentData, loading: dataLoading } = useServiceState(
    APIService.fundDetailService.fetchFundInstruments,
  )
  React.useEffect(() => {
    if (!dataFundDetail) {
      return
    }
    getInstruments(dataFundDetail.fund_id)
  }, [getInstruments, dataFundDetail])

  const [localInstrument, setLocalInstrument] = React.useState<(InstrumentResponseType & { key: string })[]>()
  const [editingRow, setEditingRow] = React.useState<InstrumentResponseType & { key: string }>()
  const [submitting, setSubmitting] = React.useState(false)
  const [showAddNew, setShowAddNew] = React.useState(false)

  const [form] = Form.useForm()

  React.useEffect(() => {
    if (!instrumentData) {
      return
    }
    setLocalInstrument(instrumentData.map((item) => ({ ...item, key: `${item.date}_${item.name}` })))
  }, [instrumentData])

  const handleAddRow = async (value: Store) => {
    if (!value || !dataFundDetail) {
      return
    }
    try {
      setSubmitting(true)
      await APIService.fundDetailService.createFundInstrument(dataFundDetail.fund_id, {
        work_date: moment(value.date).format('YYYY-MM-DD'),
        name: value.name,
        long_value: value.long_value ? parseFloat(value.long_value.toString()) : undefined,
        short: value.short ? parseFloat(value.short.toString()) : undefined,
        net: value.net ? parseFloat(value.net.toString()) : undefined,
      })
      form.resetFields()
      getInstruments(dataFundDetail.fund_id)
      notification.success({ message: 'Row created successfully!' })
      setShowAddNew(false)
    } catch (error) {
      console.log(error)
      notification.error({ message: `Fail to create new row! ${error.response.data.message || ''}` })
    } finally {
      setSubmitting(false)
      setEditingRow(undefined)
    }
  }

  const handleAddNewRow = () => {
    if (!showAddNew) {
      setShowAddNew(true)
    }
  }

  const handleUpdateRow = async () => {
    if (!editingRow || !dataFundDetail) {
      return
    }
    try {
      setSubmitting(true)
      await APIService.fundDetailService.updateFundInstrument(dataFundDetail.fund_id, {
        work_date: editingRow.key.split('_')[0],
        name: editingRow.key.split('_')[1],
        updates: {
          work_date: editingRow.date,
          name: editingRow.name,
          long_value: editingRow.long_value ? parseFloat(editingRow.long_value.toString()) : undefined,
          short: editingRow.short ? parseFloat(editingRow.short.toString()) : undefined,
          net: editingRow.net ? parseFloat(editingRow.net.toString()) : undefined,
        },
      })
      getInstruments(dataFundDetail.fund_id)
      notification.success({ message: 'Row updated successfully!' })
    } catch (err) {
      console.log(err)
      notification.error({ message: 'Failed to update row!' })
    } finally {
      setSubmitting(false)
      setEditingRow(undefined)
    }
  }

  const handleDeleteRow = async (date: string, name: string) => {
    if (!dataFundDetail) {
      return
    }
    if (date === '') {
      // newly added row
      setLocalInstrument((prev) => prev?.filter((item) => item.date !== ''))
      setEditingRow(undefined)
      return
    }
    try {
      setSubmitting(true)
      await APIService.fundDetailService.deleteFundInstrument(dataFundDetail.fund_id, date, name)
      getInstruments(dataFundDetail.fund_id)
      notification.success({ message: 'Row deleted successfully!' })
    } catch (error) {
      console.log(error)
      notification.error({ message: 'Fail to delete row!' })
    } finally {
      setSubmitting(false)
      setEditingRow(undefined)
    }
  }

  const columns: ColumnProps<InstrumentResponseType & { key: string }>[] = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => {
        return a.date.localeCompare(b.date)
      },
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
      // @ts-ignore
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'date',
        editingRow,
        setEditingRow,
      }),
      width: '20%',
    },
    {
      title: 'Instrument',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => {
        return a.name.localeCompare(b.name)
      },
      sortDirections: ['descend', 'ascend'],
      // @ts-ignore
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'name',
        editingRow,
        setEditingRow,
        options: dataDropdown ? dataDropdown : [],
      }),
      width: '20%',
    },
    {
      title: 'Long (%)',
      dataIndex: 'long_value',
      key: 'long',
      sorter: (a, b) => {
        return (a.long_value || 0) - (b.long_value || 0)
      },
      sortDirections: ['descend', 'ascend'],
      // @ts-ignore
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'long_value',
        editingRow,
        setEditingRow,
      }),
      width: '15%',
    },
    {
      title: 'Short (%)',
      dataIndex: 'short',
      key: 'short',
      sorter: (a, b) => {
        return (a.short || 0) - (b.short || 0)
      },
      sortDirections: ['descend', 'ascend'],
      // @ts-ignore
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'short',
        editingRow,
        setEditingRow,
      }),
      width: '15%',
    },
    {
      title: 'Net (%)',
      dataIndex: 'net',
      key: 'net',
      sorter: (a, b) => {
        return (a.net || 0) - (b.net || 0)
      },
      sortDirections: ['descend', 'ascend'],
      // @ts-ignore
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'net',
        editingRow,
        setEditingRow,
      }),
      width: '15%',
    },
    ...(!isOverview
      ? [
          {
            title: 'Action',
            dataIndex: 'action',
            width: '10%',
            render: (_: any, record: any) => (
              <>
                {!submitting && !editingRow && (
                  <Button
                    type="link"
                    style={{ padding: '4px 8px' }}
                    onClick={() => setEditingRow({ ...record, key: record.key })}
                  >
                    <Tooltip title="Edit">
                      <EditOutlined />
                    </Tooltip>
                  </Button>
                )}
                {editingRow && editingRow.key === record.key && !submitting && (
                  <>
                    <Button type="link" style={{ padding: '4px 8px' }} onClick={() => handleUpdateRow()}>
                      <Tooltip title="Save">
                        <CheckOutlined />
                      </Tooltip>
                    </Button>
                    <Button
                      type="link"
                      style={{ color: Colors.gray, padding: '4px 8px' }}
                      onClick={() => {
                        setEditingRow(undefined)
                        localInstrument && setLocalInstrument(localInstrument?.filter((item) => item.key !== 'new-row'))
                      }}
                    >
                      <Tooltip title="Cancel">
                        <CloseOutlined />
                      </Tooltip>
                    </Button>
                  </>
                )}
                {editingRow && editingRow.key === record.key && submitting && <Spin />}
                {!(editingRow && editingRow.key === record.key) && (
                  <Popconfirm
                    title="Are you sure you want to delete?"
                    onConfirm={() => {
                      handleDeleteRow(record.date, record.name)
                    }}
                  >
                    <Button type="link" style={{ padding: '4px 8px' }}>
                      <Tooltip title="Delete">
                        <DeleteTwoTone twoToneColor={Colors.danger} />
                      </Tooltip>
                    </Button>
                  </Popconfirm>
                )}
              </>
            ),
          },
        ]
      : []),
  ]

  const components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell,
    },
  }
  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <Wrapper>
        {!isOverview && (
          <div>
            <Button type="primary" style={{ marginBottom: 8 }} onClick={handleAddNewRow}>
              Add New Row
            </Button>
          </div>
        )}
        <Table
          rowKey="key"
          rowClassName={() => 'editable-row'}
          components={components}
          loading={dataLoading}
          dataSource={localInstrument}
          columns={columns}
          scroll={{ y: 'calc(100vh - 478px)' }}
          pagination={{
            hideOnSinglePage: true,
            showSizeChanger: false,
            defaultPageSize: 50,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
          style={{ flex: 1 }}
          bordered
        />
        <Modal
          title="Add New Instrument"
          visible={showAddNew}
          onCancel={() => {
            setShowAddNew(false)
            form.resetFields()
          }}
          onOk={form.submit}
        >
          <Form form={form} layout="vertical" onFinish={handleAddRow}>
            <FormItemDatePicker name="date" label="Date" rules={[{ required: true, message: 'Date is required' }]} />
            <Form.Item label="Instrument" name={'name'} rules={[{ required: true, message: 'Instrument is required' }]}>
              <AutoComplete
                style={{
                  width: '100%',
                }}
                options={dataDropdown?.map((item) => ({ text: item, value: item })) || []}
                allowClear
                filterOption={(inputValue, option) => {
                  return option?.value.toString().toUpperCase().indexOf(inputValue.toString().toUpperCase()) !== -1
                }}
              />
            </Form.Item>
            <Row gutter={8}>
              <Col span={8}>
                <FormItemInput
                  name="long_value"
                  label="Long (%)"
                  rules={[{ required: true, message: 'Long is required' }]}
                  typeField="number"
                />
              </Col>
              <Col span={8}>
                <FormItemInput
                  name="short"
                  label="Short (%)"
                  rules={[{ required: true, message: 'Short is required' }]}
                  typeField="number"
                />
              </Col>
              <Col span={8}>
                <FormItemInput
                  name="net"
                  label="Net (%)"
                  rules={[{ required: true, message: 'Net is required' }]}
                  typeField="number"
                />
              </Col>
            </Row>
          </Form>
        </Modal>
      </Wrapper>
    </div>
  )
}
