import { Button } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import { LegacyLogTableDiff } from './LegacyLogTableDiff'
type Props = {
  showModal: boolean
  setShowModal: (value: boolean) => void
  selectedLogDiff: string[][]
}
export const LegacyLogModal: React.FC<Props> = ({ showModal, setShowModal, selectedLogDiff }) => {
  return (
    <Modal
      visible={showModal}
      title="Legacy Log Diff"
      onCancel={() => setShowModal(false)}
      footer={
        <Button type="link" onClick={() => setShowModal(false)}>
          Cancel
        </Button>
      }
    >
      <LegacyLogTableDiff selectedLogDiff={selectedLogDiff} />
    </Modal>
  )
}
