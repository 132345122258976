import { Col, Row, Spin } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../../../app/rootReducer'
import { FundDetailDataContext } from '../../../../shared/api/context/FundDetailDataContext'
import { FundDetailDropdownContext } from '../../../../shared/api/context/FundDetailDropdownContext'
import { ExFirmDetails } from '../../../../shared/api/models/FirmDetails'
import { IndexManagerType } from '../../../../shared/api/models/IndexManager'
import { DividerForm } from '../../../../shared/components/DividerForm'
import {
  FormItemCheckBox,
  FormItemDatePicker,
  FormItemInput,
  FormItemSelect,
  FormItemTextArea,
} from '../../../../shared/components/FormItemComponents'
import { NextBackButton } from '../../../../shared/components/NextBackButton'
import { RenderLabelForm } from '../../../../shared/components/RenderLabelForm'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 1rem 50px;
  flex-direction: column;
`

type Props = {
  form: FormInstance
  dataListIndices: IndexManagerType[] | undefined
}
export const FundBasicInformation: React.FC<Props> = ({ form, dataListIndices }) => {
  const { fundDataDropdown, dataFirms } = React.useContext(FundDetailDropdownContext)

  const GlobaldataDropdown = useSelector((state: RootState) => state.appData)
  const { dataFundDetail, setDataFundDetailChange, dataFundDetailChange } = React.useContext(FundDetailDataContext)
  const [optionFirm, setOptionFirm] = React.useState<{ firm_id: string; firm_name: string }[] | undefined>()

  const selectedStrategy = form.getFieldValue('strategy_code_new')

  const compareFirm = (a: ExFirmDetails, b: ExFirmDetails) => {
    return a.firm_name && b.firm_name ? a.firm_name.localeCompare(b.firm_name) : 0
  }

  const compareIndices = (a: IndexManagerType, b: IndexManagerType) => {
    return a.name && b.name ? a.name.localeCompare(b.name) : 0
  }

  const sortedFirms = dataFirms ? dataFirms.sort(compareFirm) : undefined
  const sortedListIndices = dataListIndices ? dataListIndices.sort(compareIndices) : undefined

  const filterOptions = React.useCallback(
    (val: string) => {
      if (!sortedFirms || !dataFundDetail) {
        return
      }

      if (!val) {
        const firmId = form.getFieldValue('firm_id')
        const firmName = sortedFirms?.find((item) => item.firm_id === firmId)?.firm_name || ''
        const slicedFirms = sortedFirms
          .slice(0, 20)
          .map((item) => ({ firm_id: item.firm_id, firm_name: item.firm_name }))

        return slicedFirms.some((item) => item.firm_id === firmId)
          ? slicedFirms
          : [...slicedFirms, { firm_id: firmId, firm_name: firmName }]
      }
      return sortedFirms
        .filter(
          (item) =>
            (item.firm_name && item.firm_name.toLowerCase().includes(val.toLowerCase())) ||
            item.firm_id.toLowerCase().includes(val.toLowerCase()),
        )
        .slice(0, 20)
        .map((item) => ({ firm_id: item.firm_id, firm_name: item.firm_name }))
    },
    [dataFundDetail, form, sortedFirms],
  )

  React.useEffect(() => {
    setOptionFirm(filterOptions(''))
  }, [filterOptions])

  function onSearch(val: string) {
    setOptionFirm(filterOptions(val))
  }

  const FORMITEM_GROUP_MIDDLE = [
    {
      name: 'denomination',
      label: 'Denom.',
      options:
        fundDataDropdown?.denomination && fundDataDropdown.denomination.length > 0
          ? fundDataDropdown.denomination.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
      allowClear: true,
    },

    {
      name: 'product_class',
      label: 'Product Class',
      options:
        fundDataDropdown?.product_class && fundDataDropdown?.product_class.length > 0
          ? fundDataDropdown.product_class.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
      allowClear: true,
    },
    {
      name: 'bloomberg_ticker',
      label: 'Bloomberg Ticker',
      maxLength: 20,
    },
    {
      name: 'isin',
      label: 'ISIN',
      maxLength: 20,
    },
    {
      name: 'sec_pfid_number',
      label: 'SEC-PFID',
      maxLength: 15,
    },
  ]
  const THIRD_GROUP_FORM = [
    {
      name: 'fund_status_code',
      label: 'Fund Status',
      options:
        fundDataDropdown?.fund_status_code && fundDataDropdown?.fund_status_code.length > 0
          ? fundDataDropdown.fund_status_code.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
    },
    {
      name: 'liquidated_at',
      label: 'Liquidated at',
      isDatePicker: true,
      options: [],
      allowClear: true,
    },
    {
      name: 'added_to_db',
      label: 'Added To DB',
      isDatePicker: true,
      options: [],
      allowClear: true,
    },
    {
      name: 'data_source',
      label: 'Data Source',
      disable: true,
      options:
        fundDataDropdown?.data_source && fundDataDropdown?.data_source.length > 0
          ? fundDataDropdown.data_source.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
    },
    {
      name: 'hfr_analyst',
      label: 'HFR Analyst',
      options:
        fundDataDropdown?.hfr_analyst && fundDataDropdown?.hfr_analyst.length > 0
          ? fundDataDropdown.hfr_analyst.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
    },
  ]

  const optionSubstrategy = React.useMemo(() => {
    const newSubStrategyOptions = selectedStrategy
      ? GlobaldataDropdown && GlobaldataDropdown.subStrategies && GlobaldataDropdown.subStrategies.length > 0
        ? GlobaldataDropdown.subStrategies
            .filter((i) => i.strategy_code === selectedStrategy)
            .map((item) => ({
              value: item.sub_strategy_code,
              text: item.sub_strategy_name,
            }))
        : []
      : []

    return newSubStrategyOptions
  }, [GlobaldataDropdown, selectedStrategy])

  React.useEffect(() => {
    if (dataFundDetailChange?.current && Object.keys(dataFundDetailChange?.current).includes('strategy_code_new')) {
      if (
        (selectedStrategy &&
          form.getFieldValue('sub_strategy_code_new') &&
          optionSubstrategy.length > 0 &&
          optionSubstrategy.findIndex((item) => item.value === form.getFieldValue('sub_strategy_code_new')) === -1) ||
        (!selectedStrategy && form.getFieldValue('sub_strategy_code_new'))
      ) {
        form.setFieldsValue({ sub_strategy_code_new: null })
        setDataFundDetailChange((prev) => ({ ...prev, sub_strategy_code_new: null }))
      }
    }
  }, [form, selectedStrategy, optionSubstrategy, setDataFundDetailChange, dataFundDetailChange])

  return (
    <Wrapper>
      <Row gutter={16}>
        <Col span={8} md={8} xs={24} sm={24}>
          <FormItemInput
            name="fund_name"
            label={<RenderLabelForm value="Fund Name" />}
            labelCol={8}
            wrapperCol={16}
            rules={[{ required: true, message: 'Please input your Fund Name!' }]}
            maxLength={100}
          />

          <FormItemSelect
            name="firm_id"
            options={
              (optionFirm &&
                optionFirm.map((item) => ({
                  value: item.firm_id,
                  text: `(${item.firm_id}) - ${item.firm_name}`,
                }))) ||
              []
            }
            labelCol={8}
            wrapperCol={16}
            rules={[{ required: true, message: 'Please input your Firm!' }]}
            label={<RenderLabelForm value="Firm Name" />}
            selectProps={{
              onSearch,
            }}
          />

          <FormItemSelect
            name="strategy_code_new"
            label={<RenderLabelForm value="Strategy" />}
            labelCol={8}
            wrapperCol={16}
            options={
              GlobaldataDropdown?.strategies && GlobaldataDropdown?.strategies.length > 0
                ? GlobaldataDropdown.strategies.map((item) => ({
                    value: item.strategy_code,
                    text: item.strategy_name,
                  }))
                : []
            }
          />

          <FormItemSelect
            name="sub_strategy_code_new"
            label={<RenderLabelForm value="Sub-Strategy" />}
            labelCol={8}
            wrapperCol={16}
            disabled={!selectedStrategy}
            options={optionSubstrategy}
          />
          <FormItemSelect
            label={<RenderLabelForm value="Allocation" />}
            name="allocation"
            labelCol={8}
            wrapperCol={16}
            options={
              fundDataDropdown?.allocation && fundDataDropdown.allocation.length > 0
                ? fundDataDropdown.allocation.map((item) => ({
                    value: item.code_code,
                    text: item.code_description,
                  }))
                : []
            }
            allowClear
          />
          <FormItemSelect
            name="style"
            label={<RenderLabelForm value="Style" />}
            labelCol={8}
            wrapperCol={16}
            options={
              fundDataDropdown?.style && fundDataDropdown.style.length > 0
                ? fundDataDropdown.style.map((item) => ({
                    value: item.code_code,
                    text: item.code_description,
                  }))
                : []
            }
            allowClear
          />
        </Col>
        <Col span={8} md={8} xs={24} sm={24}>
          {FORMITEM_GROUP_MIDDLE.map((item) => {
            return item.options ? (
              <FormItemSelect
                key={item.name}
                label={<RenderLabelForm value={item.label} />}
                name={item.name}
                labelCol={8}
                wrapperCol={16}
                options={item.options}
                allowClear={item.allowClear}
              />
            ) : (
              <FormItemInput
                key={item.name}
                name={item.name}
                label={<RenderLabelForm value={item.label} />}
                maxLength={item.maxLength}
                labelCol={8}
                wrapperCol={16}
                allowClear={item.allowClear}
              />
            )
          })}
        </Col>
        <Col span={8} md={8} xs={24} sm={24}>
          <Row>
            {THIRD_GROUP_FORM.map((item) => {
              if (item.name === 'liquidated_at') {
                return (
                  <Col span={24} key={item.name}>
                    <FormItemDatePicker
                      disabled={!form.getFieldValue('is_liquidated')}
                      name={item.name}
                      label={
                        <>
                          <div style={{ marginTop: '0.5rem', marginRight: '0.5rem' }}>
                            <FormItemCheckBox name="is_liquidated" />
                          </div>
                          <RenderLabelForm value={item.label} />
                        </>
                      }
                      labelCol={8}
                      wrapperCol={16}
                      allowClear={item.allowClear}
                    />
                  </Col>
                )
              }
              return item.isDatePicker ? (
                <Col span={24} key={item.name}>
                  <FormItemDatePicker
                    name={item.name}
                    label={<RenderLabelForm value={item.label} />}
                    labelCol={8}
                    wrapperCol={16}
                    allowClear={item.allowClear}
                  />
                </Col>
              ) : (
                <Col span={24} key={item.name}>
                  <FormItemSelect
                    label={<RenderLabelForm value={item.label} />}
                    name={item.name}
                    labelCol={8}
                    wrapperCol={16}
                    options={item.options}
                    disabled={item.disable}
                    allowClear={item.allowClear}
                  />
                </Col>
              )
            })}
          </Row>
        </Col>
      </Row>
      <DividerForm text="Index Families" />
      <Row>
        {sortedListIndices ? (
          sortedListIndices.map((item) => {
            return (
              <Col key={item.id} lg={6} md={8} sm={12} xs={12}>
                <FormItemCheckBox name={item.id.toString()} text={<RenderLabelForm value={item.name} />} />
              </Col>
            )
          })
        ) : (
          <Spin />
        )}
      </Row>
      <DividerForm />
      <Row>
        <Col span={24} style={{ flexDirection: 'row', alignItems: 'baseline' }}>
          <FormItemTextArea
            name="notes"
            labelCol={1}
            wrapperCol={23}
            label={<RenderLabelForm value="Note" />}
            row={15}
          />
        </Col>
      </Row>
      <NextBackButton />
    </Wrapper>
  )
}
