import { Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React from 'react'
type Props = {
  selectedLogDiff: string[][]
}
export const LegacyLogTableDiff: React.FC<Props> = ({ selectedLogDiff }) => {
  type TableType = {
    fieldName: string
    oldValue: string
    newValue: string
  }

  const dataDiff = React.useMemo(() => {
    return selectedLogDiff.map((item) => ({ fieldName: item[0], oldValue: item[1], newValue: item[2] }))
  }, [selectedLogDiff])

  const columns: ColumnProps<TableType>[] = [
    {
      title: 'Field Name',
      dataIndex: 'fieldName',
      key: 'fieldName',
    },
    {
      title: 'Old Value',
      dataIndex: 'oldValue',
      key: 'oldValue',
    },
    {
      title: 'New Value',
      dataIndex: 'newValue',
      key: 'newValue',
    },
  ]
  return (
    <Table
      rowKey="fieldName"
      columns={columns}
      dataSource={dataDiff}
      pagination={dataDiff.length < 10 ? false : undefined}
    />
  )
}
