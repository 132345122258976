import { List } from 'antd'
import Text from 'antd/lib/typography/Text'
import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../app/rootReducer'
import { addTabs, FundFirmTypeEnum, PREFIX_EXTERNAL } from '../../slice/appSettingsSlice'

const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  max-height: 500px;
  overflow: auto;
`
const TextID = styled(Text)`
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
`
const StyledList = styled(List)`
  flex: 1;
  padding: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  .ant-list-item {
    padding: 4px;
  }
`
const Flex = styled.div`
  display: flex;
  flex: 1;
`

export const ExDraftTab: FC = () => {
  const { exFundDraft, exFirmDraft } = useSelector((state: RootState) => state.appSettings)
  const { user } = useSelector((state: RootState) => state.user)
  const myDrafts = [
    ...exFundDraft.filter((value) => value.userId === user?.email),
    ...exFirmDraft.filter((value) => value.userId === user?.email),
  ]
  const dispatch = useDispatch()
  return (
    <Wrapper>
      <StyledList
        bordered
        dataSource={myDrafts}
        renderItem={(item: any) => (
          <List.Item style={{ width: '100%' }}>
            <div>
              <TextID>
                {item.type === 'fundDraft' ? 'Fund' : 'Firm'} #{item.id}
              </TextID>
              <Flex style={{ alignItems: 'baseline' }}>
                <div>
                  <span
                    style={{ fontSize: 12, fontWeight: 'bold', color: '#7598E6', padding: 0, cursor: 'pointer' }}
                    onClick={() =>
                      dispatch(
                        addTabs({
                          name: item.name,
                          id: `${PREFIX_EXTERNAL}-${item.id}`,
                          type: item.type === 'exFundDraft' ? FundFirmTypeEnum.FUNDS : FundFirmTypeEnum.FIRMS_IN_FUNDS,
                        }),
                      )
                    }
                  >
                    {item.name}
                  </span>
                </div>
              </Flex>
            </div>
          </List.Item>
        )}
      />
    </Wrapper>
  )
}
