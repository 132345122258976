import { Tabs } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'
import { FirmDetailLabelSearchContext } from '../../../../shared/api/context/FirmDetailLabelSearchContext'
import { LeftNavFirmEnum, RightTabFirmEnum } from '../../../../shared/SearchFirmLabel'
import { FirmContactInfo } from './FirmContactInfo'
import { FirmOwnershipDescription } from './FirmOwnershipDescription'
import { ManagerFirmInfo } from './ManagerFirmInfo'
const { TabPane } = Tabs

const StyledTabs = styled(Tabs)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`
const RIGHT_TAB_PANE = [
  {
    id: RightTabFirmEnum.FIRM_INFO,
  },
  {
    id: RightTabFirmEnum.CONTACT_INFORMATION,
  },
  {
    id: RightTabFirmEnum.OWNERSHIP_DESCRIPTION,
  },
]
type Props = {
  form: FormInstance
}
export const FirmMainInformation: React.FC<Props> = ({ form }) => {
  const { isFiltering, filterArr } = React.useContext(FirmDetailLabelSearchContext)

  const listSearch = React.useMemo(() => {
    return filterArr.filter((item) => item.leftNav === LeftNavFirmEnum.MAIN_INFORMATION)
  }, [filterArr])

  const renderTab = (index: RightTabFirmEnum) => {
    switch (index) {
      case RightTabFirmEnum.FIRM_INFO:
        return <ManagerFirmInfo form={form} />
      case RightTabFirmEnum.CONTACT_INFORMATION:
        return <FirmContactInfo />
      case RightTabFirmEnum.OWNERSHIP_DESCRIPTION:
        return <FirmOwnershipDescription />
      default:
        break
    }
  }
  return (
    <StyledTabs hideAdd type="editable-card">
      {RIGHT_TAB_PANE.map((item) => {
        const showTabPane = listSearch.findIndex((i) => i.rightTab && i.rightTab === item.id)
        if (showTabPane === -1 && isFiltering) {
          return null
        }
        return (
          <TabPane tab={item.id} key={item.id} closable={false}>
            {renderTab(item.id)}
          </TabPane>
        )
      })}
    </StyledTabs>
  )
}
