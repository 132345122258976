import React from 'react'
import { useSafeState } from './useSafeState'

type States<T = undefined> = {
  data?: T
  loading?: boolean
  error?: string
}

type Actions<T> = {
  setLoading(loading: boolean): void
  setError(error: string): void
  setData(data?: T): void
}

type UseFetch<T> = [States<T>, Actions<T>]

const defaultInitialState: States = {
  data: undefined,
  loading: false,
  error: '',
}

export const useFetchState = <T = undefined>(
  initialStates: States<T | undefined> = defaultInitialState,
): UseFetch<T> => {
  const [data, setData] = useSafeState<T>(initialStates.data)
  const [loading, setLoading] = useSafeState(initialStates.loading)
  const [error, setError] = useSafeState(initialStates.error)

  const actions = React.useMemo(() => ({ setData, setLoading, setError }), [setData, setLoading, setError])

  return [{ data, loading, error }, actions]
}
