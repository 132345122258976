import { AutoComplete, Col, Form, Radio, Row } from 'antd'
import { FormInstance, Rule } from 'antd/lib/form'
import React from 'react'
import { FundDetailDropdownContext } from '../../../shared/api/context/FundDetailDropdownContext'
import {
  FormItemAutoComplete,
  FormItemCheckBox,
  FormItemDatePicker,
  FormItemSelect,
} from '../../../shared/components/FormItemComponents'
import { RenderLabelExFund } from '../../../shared/components/RenderLabelExFund'
import { useFieldValidate } from '../../../shared/hooks/useEffectFieldValidate'
import { LeftNavExFundEnum } from '../../../shared/SearchExFundLabel'
import { FormFieldType } from '../ExFundDetailMain'
import { RequiredDataHelper } from '../RequiredDataHelper'

type Props = {
  isDirty: boolean
  form: FormInstance
  onUpdateTabDirty: (id: LeftNavExFundEnum, isDirty: boolean) => void
}

// const ARRAY_FIELD_VALIDATE = ['firm_assets_aud', 'minimum_investment_reason']

type FundDetailFieldsType = Pick<
  FormFieldType,
  | 'liquid_alt_type'
  | 'liquid_alt_region'
  | 'product_class'
  | 'ucitsiii'
  | 'fund_assets'
  | 'firm_assets'
  | 'fund_assets_date'
  | 'firm_assets_date'
  | 'inception_date'
  | 'accepts_new_investment'
  | 'min_invest'
  | 'add_invest'
  | 'min_invest_indv'
  | 'accepts_mgd_accts'
  | 'multi_manager_platform'
  | 'run_sep_mgd_accts'
>

const FIELDS: FundDetailFieldsType = {
  liquid_alt_type: {
    key: 'liquid_alt_type',
    name: 'Liquid Alternative Product Type',
    isRequired: true,
  },
  liquid_alt_region: {
    key: 'liquid_alt_region',
    name: 'Country of Registration',
    isRequired: true,
  },
  product_class: {
    key: 'product_class',
    name: 'Fund Share Class',
  },
  ucitsiii: {
    key: 'ucitsiii',
    name: 'UCITS Compliant?',
    isRequired: true,
  },
  fund_assets: {
    key: 'fund_assets',
    name: 'Fund Assets',
  },
  firm_assets: {
    key: 'firm_assets',
    name: 'Firm Assets AUD (MM)',
  },
  fund_assets_date: {
    key: 'fund_assets_date',
    name: 'Fund Assets Date',
  },
  firm_assets_date: {
    key: 'firm_assets_date',
    name: 'Firm Assets Date',
  },
  inception_date: {
    key: 'inception_date',
    name: 'Inception Date',
    isRequired: true,
  },
  accepts_new_investment: {
    key: 'accepts_new_investment',
    name: 'Open to New Investment?',
    isRequired: true,
  },
  min_invest: {
    key: 'min_invest',
    name: 'Minimum Investment',
    isRequired: true,
  },
  add_invest: {
    key: 'add_invest',
    name: 'Additional Investments',
    isRequired: true,
  },
  min_invest_indv: {
    key: 'min_invest_indv',
    name: 'Managed Account Minimum Asset Size',
    isRequired: true,
  },
  accepts_mgd_accts: {
    key: 'accepts_mgd_accts',
    name: 'Is manager open to running a separately managed account pari-passu to this fund?',
    isRequired: true,
  },
  multi_manager_platform: {
    key: 'multi_manager_platform',
    name: 'Does fund utilize an internal Multi-Manager Platform (aka, "Pods")?',
    isRequired: true,
  },
  run_sep_mgd_accts: {
    key: 'run_sep_mgd_accts',
    name: 'Currently Running a Separately Managed Account?',
    isRequired: true,
  },
}

export const ExFundDetail: React.FC<Props> = ({ isDirty, form, onUpdateTabDirty }) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)

  const fundStatusField = form.getFieldValue('fund_status_code')

  const acceptMgdAcct = form.getFieldValue('accepts_mgd_accts')

  React.useEffect(() => {
    if (acceptMgdAcct === '2') {
      RequiredDataHelper.default().removeRequiredFields({
        'Fund Detail': ['min_invest_indv', 'run_sep_mgd_accts'],
      })
    } else {
      RequiredDataHelper.default().addRequiredFields({
        'Fund Detail': ['min_invest_indv', 'run_sep_mgd_accts'],
      })
    }
  }, [acceptMgdAcct])

  const liquidAltProduct = form.getFieldValue('liquid_alt_product')
  const isHurdleRate = form.getFieldValue('is_hurdle_rate')

  React.useEffect(() => {
    if (liquidAltProduct) {
      RequiredDataHelper.default().addRequiredFields({
        'Fund Detail': ['liquid_alt_type', 'liquid_alt_region'],
        'Fee Schedule': ['max_12b1_fee', 'max_front_fee', 'max_deferred_fee'],
      })
      RequiredDataHelper.default().removeRequiredFields({
        'Fund Structure': ['domicile', 'structure', 'registration_code'],
        'Fee Schedule': ['incentive_fee', 'is_high_watermark', 'hurdle_rate'],
      })
    } else {
      RequiredDataHelper.default().removeRequiredFields({
        'Fund Detail': ['liquid_alt_type', 'liquid_alt_region'],
        'Fee Schedule': ['max_12b1_fee', 'max_front_fee', 'max_deferred_fee'],
      })

      RequiredDataHelper.default().addRequiredFields({
        'Fund Structure': ['domicile', 'structure', 'registration_code'],
        'Fee Schedule': isHurdleRate
          ? ['incentive_fee', 'is_high_watermark', 'hurdle_rate']
          : ['incentive_fee', 'is_high_watermark'],
      })
    }
  }, [isHurdleRate, liquidAltProduct])

  const LIST_SELECT_FORM: {
    name: keyof typeof FIELDS
    label: string
    options: any[]
    rules?: Rule[]
  }[] = [
    {
      name: 'liquid_alt_type',
      label: FIELDS['liquid_alt_type']?.name || '',
      options:
        fundDataDropdown?.liquid_alt_type && fundDataDropdown.liquid_alt_type.length > 0
          ? fundDataDropdown.liquid_alt_type.map((item) => ({ value: item.code_code, text: item.code_description }))
          : [],
      rules: RequiredDataHelper.default().isValidateField(LeftNavExFundEnum.FUND_DETAIL, FIELDS['liquid_alt_type']?.key)
        ? [{ required: true, message: 'This field is required!' }]
        : [],
    },
    {
      name: 'liquid_alt_region',
      label: FIELDS['liquid_alt_region']?.name || '',
      options:
        fundDataDropdown?.liquid_alt_region && fundDataDropdown.liquid_alt_region.length > 0
          ? fundDataDropdown.liquid_alt_region.map((item) => ({ value: item.code_code, text: item.code_description }))
          : [],
      rules: RequiredDataHelper.default().isValidateField(
        LeftNavExFundEnum.FUND_DETAIL,
        FIELDS['liquid_alt_region'].key,
      )
        ? [{ required: true, message: 'This field is required!' }]
        : [],
    },
    {
      name: 'product_class',
      label: FIELDS['product_class']?.name || '',
      options:
        fundDataDropdown?.product_class && fundDataDropdown.product_class.length > 0
          ? fundDataDropdown.product_class.map((item) => ({ value: item.code_code, text: item.code_description }))
          : [],
      rules: RequiredDataHelper.default().isValidateField(LeftNavExFundEnum.FUND_DETAIL, FIELDS['product_class'].key)
        ? [{ required: true, message: 'This field is required!' }]
        : [],
    },
  ]

  const optionsMinInvest =
    fundDataDropdown?.min_invest && fundDataDropdown.min_invest.length > 0
      ? fundDataDropdown.min_invest.map((item) => ({ value: item, text: item }))
      : []
  const optionsAddInvest =
    fundDataDropdown?.add_invest && fundDataDropdown.add_invest.length > 0
      ? fundDataDropdown.add_invest.map((item) => ({ value: item, text: item }))
      : []

  const optionsAssetSize =
    fundDataDropdown?.min_invest_indv && fundDataDropdown.min_invest_indv.length > 0
      ? fundDataDropdown.min_invest_indv.map((item) => ({ value: item, text: item }))
      : []

  useFieldValidate(
    Object.keys(FIELDS).filter((item) => FIELDS[item as keyof FundDetailFieldsType].isRequired),
    isDirty,
    form,
    onUpdateTabDirty,
    LeftNavExFundEnum.FUND_DETAIL,
  )

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: '1rem', padding: '0 2rem' }}>
      <Row>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemCheckBox
            disable={fundStatusField === '2'}
            name="liquid_alt_product"
            text={<RenderLabelExFund value="Liquid Alt Product" />}
          />
        </Col>
      </Row>
      <Row>
        {LIST_SELECT_FORM.map((item) => {
          if (
            !form.getFieldValue('liquid_alt_product') &&
            (item.name === 'liquid_alt_type' || item.name === 'liquid_alt_region')
          ) {
            return null
          }
          if (form.getFieldValue('liquid_alt_product') && item.name === 'liquid_alt_region') {
            form.setFieldsValue({ liquid_alt_region: '1' })
            return (
              <Col span={12} xl={12} md={16} xs={24} sm={24} key={item.name}>
                <FormItemSelect
                  disabled={fundStatusField === '2'}
                  name={item.name}
                  label={<RenderLabelExFund value={item.label} />}
                  options={item.options}
                  wrapperCol={16}
                  rules={item.rules}
                />
              </Col>
            )
          }
          return (
            <Col span={12} xl={12} md={16} xs={24} sm={24} key={item.name}>
              <FormItemSelect
                disabled={fundStatusField === '2'}
                name={item.name}
                label={<RenderLabelExFund value={item.label} />}
                options={item.options}
                wrapperCol={16}
                rules={item.rules}
              />
            </Col>
          )
        })}
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemSelect
            name={FIELDS['ucitsiii'].key}
            label={<RenderLabelExFund value={FIELDS['ucitsiii'].name} />}
            wrapperCol={16}
            options={[
              { value: 'Yes', text: 'Yes' },
              { value: 'No', text: 'No' },
            ]}
            rules={
              RequiredDataHelper.default().isValidateField(LeftNavExFundEnum.FUND_DETAIL, FIELDS['ucitsiii'].key)
                ? [{ required: true, message: 'This field is required!' }]
                : []
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={12} xl={12} md={16} xs={24}>
          <FormItemDatePicker
            name={FIELDS['inception_date'].key}
            label={<RenderLabelExFund value={FIELDS['inception_date'].name} />}
            wrapperCol={16}
            rules={
              RequiredDataHelper.default().isValidateField(LeftNavExFundEnum.FUND_DETAIL, FIELDS['inception_date'].key)
                ? [{ required: true, message: 'This field is required!' }]
                : []
            }
            disabled={fundStatusField === '2'}
          />
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <Form.Item
            name={FIELDS['accepts_new_investment'].key}
            label={<RenderLabelExFund value={FIELDS['accepts_new_investment'].name} />}
            {...{ wrapperCol: { span: 16 } }}
            rules={
              RequiredDataHelper.default().isValidateField(
                LeftNavExFundEnum.FUND_DETAIL,
                FIELDS['accepts_new_investment'].key,
              )
                ? [{ required: true, message: 'This field is required!' }]
                : []
            }
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemAutoComplete
            name={FIELDS['min_invest'].key}
            label={<RenderLabelExFund value={FIELDS['min_invest'].name} />}
            wrapperCol={16}
            options={optionsMinInvest.map((item) => {
              return {
                value: item.value,
                text: item.text,
              }
            })}
            rules={
              RequiredDataHelper.default().isValidateField(LeftNavExFundEnum.FUND_DETAIL, FIELDS['min_invest'].key)
                ? [{ required: true, message: 'This field is required!' }]
                : []
            }
          />
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemAutoComplete
            name={FIELDS['add_invest'].key}
            label={<RenderLabelExFund value={FIELDS['add_invest'].name} />}
            wrapperCol={16}
            options={optionsAddInvest.map((item) => ({
              value: item.value,
              text: item.text,
            }))}
            rules={
              RequiredDataHelper.default().isValidateField(LeftNavExFundEnum.FUND_DETAIL, FIELDS['add_invest'].key)
                ? [{ required: true, message: 'This field is required!' }]
                : []
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <Form.Item
            name={FIELDS['accepts_mgd_accts'].key}
            label={<RenderLabelExFund value={FIELDS['accepts_mgd_accts'].name} />}
            {...{ wrapperCol: { span: 16 } }}
            rules={
              RequiredDataHelper.default().isValidateField(
                LeftNavExFundEnum.FUND_DETAIL,
                FIELDS['accepts_mgd_accts'].key,
              )
                ? [{ required: true, message: 'This field is required!' }]
                : []
            }
          >
            <Radio.Group>
              <Radio value="1">Yes</Radio>
              <Radio value="2">No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemSelect
            name={FIELDS['multi_manager_platform'].key}
            label={<RenderLabelExFund value={FIELDS['multi_manager_platform'].name} />}
            wrapperCol={16}
            options={
              fundDataDropdown?.gate_fund && fundDataDropdown.gate_fund.length > 0
                ? fundDataDropdown.gate_fund
                    .filter((i) => i.code_code !== '0')
                    .map((item) => ({
                      value: item.code_code,
                      text: item.code_description,
                    }))
                : []
            }
            rules={
              RequiredDataHelper.default().isValidateField(
                LeftNavExFundEnum.FUND_DETAIL,
                FIELDS['multi_manager_platform'].key,
              )
                ? [{ required: true, message: 'This field is required!' }]
                : []
            }
          />
        </Col>
      </Row>
      {form.getFieldValue('accepts_mgd_accts') === '1' && (
        <>
          <Row>
            <Col span={12} xl={12} md={16} xs={24} sm={24}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.accepts_mgd_accts !== currentValues.accepts_mgd_accts
                }
              >
                {({ getFieldValue, setFieldsValue }) => {
                  if (getFieldValue('accepts_mgd_accts') === '2') {
                    setFieldsValue({ min_invest_indv: null })
                  }
                  return (
                    <Form.Item
                      name={FIELDS['min_invest_indv'].key}
                      label={<RenderLabelExFund value={FIELDS['min_invest_indv'].name} />}
                      {...{ wrapperCol: { span: 16 } }}
                      rules={
                        RequiredDataHelper.default().isValidateField(
                          LeftNavExFundEnum.FUND_DETAIL,
                          FIELDS['min_invest_indv'].key,
                        )
                          ? [{ required: true, message: 'This field is required!' }]
                          : []
                      }
                    >
                      <AutoComplete
                        disabled={getFieldValue('accepts_mgd_accts') === '2'}
                        style={{
                          width: '100%',
                        }}
                        options={optionsAssetSize.map((item) => ({ value: item.value, label: item.text }))}
                        allowClear
                        filterOption={(inputValue, option) => {
                          return (
                            option?.value.toString().toUpperCase().indexOf(inputValue.toString().toUpperCase()) !== -1
                          )
                        }}
                      />
                    </Form.Item>
                  )
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12} xl={12} md={16} xs={24} sm={24}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.accepts_mgd_accts !== currentValues.accepts_mgd_accts
                }
              >
                {({ getFieldValue, setFieldsValue }) => {
                  if (getFieldValue('accepts_mgd_accts') === '2') {
                    setFieldsValue({ run_sep_mgd_accts: null })
                  }
                  return (
                    <Form.Item
                      name={FIELDS['run_sep_mgd_accts'].key}
                      label={<RenderLabelExFund value={FIELDS['run_sep_mgd_accts'].name} />}
                      {...{ wrapperCol: { span: 16 } }}
                      rules={
                        RequiredDataHelper.default().isValidateField(
                          LeftNavExFundEnum.FUND_DETAIL,
                          FIELDS['run_sep_mgd_accts'].key,
                        )
                          ? [{ required: true, message: 'This field is required!' }]
                          : []
                      }
                    >
                      <Radio.Group disabled={getFieldValue('accepts_mgd_accts') === '2'}>
                        <Radio value="1">Yes</Radio>
                        <Radio value="2">No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  )
                }}
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </div>
  )
}

RequiredDataHelper.default().registerRequirement(
  LeftNavExFundEnum.FUND_DETAIL,
  Object.keys(FIELDS).filter((item) => FIELDS[item as keyof FundDetailFieldsType].isRequired),
)
