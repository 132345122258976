import { List, notification, Row } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import APIService from '../../../shared/api'

type Props = {
  onChangeFavorited: () => void
  isOpen: boolean
  onRequestClose: () => void
  selectedFundsAddFavorited: { fund_id: string; fund_name: string }[]
}

const AddToFavoritedModal: React.FC<Props> = ({
  onChangeFavorited,
  isOpen,
  onRequestClose,
  selectedFundsAddFavorited,
}) => {
  const [submitting, setSubmitting] = React.useState(false)

  const handleSubmit = () => {
    setSubmitting(true)
    APIService.fundService
      .updateFavorited({ add: selectedFundsAddFavorited.map((item) => item.fund_id) })
      .then(() => {
        notification.success({
          message: `Added ${selectedFundsAddFavorited.length} funds to Favorited Funds successfully!`,
        })
        onChangeFavorited()
        onRequestClose()
      })
      .catch((err) => {
        notification.error({ message: `Failed to add funds to Favorited Funds!` })
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <Modal
      title="Add To Favorited"
      visible={isOpen}
      onCancel={() => onRequestClose()}
      onOk={handleSubmit}
      confirmLoading={submitting}
    >
      <Row>
        <b>{selectedFundsAddFavorited.length} Funds Selected</b>
      </Row>
      <List
        style={{ padding: 0, flex: 1, justifyContent: 'center' }}
        bordered
        dataSource={selectedFundsAddFavorited}
        renderItem={(item: any) => (
          <List.Item>
            <div>
              <Text style={{ fontSize: 12 }}>
                Fund #{item.fund_id} - {item.fund_name}
              </Text>
            </div>
          </List.Item>
        )}
      />
    </Modal>
  )
}

export default AddToFavoritedModal
