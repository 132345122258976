import { Col, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'
import { FundDetailDropdownContext } from '../../../../../shared/api/context/FundDetailDropdownContext'
import { DividerForm } from '../../../../../shared/components/DividerForm'
import {
  FormItemAutoComplete,
  FormItemCheckBox,
  FormItemDatePicker,
  FormItemInput,
  FormItemSelect,
} from '../../../../../shared/components/FormItemComponents'
import { RenderLabelForm } from '../../../../../shared/components/RenderLabelForm'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

type Props = { form: FormInstance }
export const InvestmentInfo: React.FC<Props> = ({ form }) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)

  const isLockup = form.getFieldValue('is_lockup') === '1' || form.getFieldValue('is_lockup') === true

  const RIGHT_TOP_GROUP_FORMITEM = [
    {
      name: 'accepts_new_investment',
      text: 'Accepting New Investment',
      isCheckBox: true,
    },
    {
      name: 'investor_type',
      text: 'Accred. Investor Type',
      options:
        fundDataDropdown?.investor_type && fundDataDropdown?.investor_type.length > 0
          ? fundDataDropdown?.investor_type.map((item) => ({ value: item, text: item }))
          : [],
    },
    {
      name: 'performance_reporting',
      text: 'Perf. Rept. to Investors',
      options:
        fundDataDropdown?.performance_reporting && fundDataDropdown?.performance_reporting.length > 0
          ? fundDataDropdown?.performance_reporting.map((item) => ({ value: item, text: item }))
          : [],
    },
    {
      name: 'prime_broker',
      text: 'Prime Broker',
      maxLength: 60,
      options:
        fundDataDropdown?.prime_broker && fundDataDropdown?.prime_broker.length > 0
          ? fundDataDropdown?.prime_broker.map((item) => ({ value: item, text: item }))
          : [],
      isAutoComplete: true,
    },
    {
      name: 'administrator',
      text: 'Administrator',
      options:
        fundDataDropdown?.administrator && fundDataDropdown?.administrator.length > 0
          ? fundDataDropdown?.administrator.map((item) => ({ value: item, text: item }))
          : [],
      isAutoComplete: true,
    },

    {
      name: 'legal_adviser',
      text: 'Legal Adviser',
      options:
        fundDataDropdown?.legal_adviser && fundDataDropdown?.legal_adviser.length > 0
          ? fundDataDropdown?.legal_adviser.map((item) => ({ value: item, text: item }))
          : [],
      isAutoComplete: true,
    },
    {
      name: 'auditor_name',
      text: 'Auditor',
      options:
        fundDataDropdown?.auditor_name && fundDataDropdown?.auditor_name.length > 0
          ? fundDataDropdown?.auditor_name.map((item) => ({ value: item, text: item }))
          : [],
      isAutoComplete: true,
    },
  ]
  const LEFT_BOT_GROUP_FORMITEM = [
    {
      name: 'placement_agent',
      text: 'Placement Agent',
    },
    {
      name: 'banking_agent',
      text: 'Banking Agent',
    },
    {
      name: 'consultant',
      text: 'Consultant',
    },
    {
      name: 'custodian',
      text: 'Custodian',
      maxLength: 60,
    },
    {
      name: 'constituent_count',
      text: '# of Constituents',
      type: 'number',
    },
    {
      name: 'sub_strategy_desc',
      text: 'Sub-Strategy Desc',
      maxLength: 60,
    },
  ]
  const RIGHT_BOT_GROUP_FORMITEM = React.useMemo(() => {
    return [
      {
        name: 'is_lockup',
        text: 'Is Lockup',
        isCheckBox: true,
      },
      {
        name: 'lockup_period',
        text: 'Lockup Period',
        disabled: !isLockup,
        allowClear: true,
        options:
          fundDataDropdown && fundDataDropdown.lockup_period && fundDataDropdown.lockup_period.length > 0
            ? fundDataDropdown.lockup_period.map((item) => ({ value: item.code_code, text: item.code_description }))
            : [],
      },
      {
        name: 'lockup_type',
        text: 'Lockup Type',
        allowClear: true,
        options:
          fundDataDropdown && fundDataDropdown.lockup_type && fundDataDropdown.lockup_type.length > 0
            ? fundDataDropdown.lockup_type.map((item) => ({ value: item.code_code, text: item.code_description }))
            : [],
        disabled: !isLockup,
      },
      {
        name: 'gate_fund',
        text: 'Gate: Fund-Level',
        options:
          fundDataDropdown && fundDataDropdown.gate_fund && fundDataDropdown.gate_fund.length > 0
            ? fundDataDropdown.gate_fund.map((item) => ({ value: item.code_code, text: item.code_description }))
            : [],
      },
      {
        name: 'gate_investor',
        text: 'Gate: Investor-Level',
        options:
          fundDataDropdown && fundDataDropdown.gate_investor && fundDataDropdown.gate_investor.length > 0
            ? fundDataDropdown.gate_investor.map((item) => ({ value: item.code_code, text: item.code_description }))
            : [],
      },
    ]
  }, [fundDataDropdown, isLockup])
  return (
    <Wrapper>
      <Row gutter={16}>
        <Col span={12} xl={12} lg={12} xs={24}>
          <FormItemSelect
            name="equity_subscription"
            label={<RenderLabelForm value="Equity Subscriptions" />}
            labelCol={12}
            wrapperCol={12}
            options={
              fundDataDropdown?.equity_subscription && fundDataDropdown?.equity_subscription.length > 0
                ? fundDataDropdown?.equity_subscription.map((item) => ({
                    value: item.code_code,
                    text: item.code_description,
                  }))
                : []
            }
          />
          <FormItemSelect
            name="subscription_advance_notice"
            label={<RenderLabelForm value="Subscription Advanced Notice (days)" />}
            labelCol={12}
            wrapperCol={12}
            allowClear
            options={
              fundDataDropdown?.subscription_advance_notice && fundDataDropdown?.subscription_advance_notice.length > 0
                ? fundDataDropdown?.subscription_advance_notice.map((item) => ({
                    value: item.code_code,
                    text: item.code_description,
                  }))
                : []
            }
          />
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 3 }}>
              <FormItemSelect
                name="subscription_cutoff_time"
                label={<RenderLabelForm value="Subscription Cutoff by" />}
                labelCol={16}
                wrapperCol={8}
                allowClear
                options={
                  fundDataDropdown?.subscription_cutoff_time && fundDataDropdown?.subscription_cutoff_time.length > 0
                    ? fundDataDropdown?.subscription_cutoff_time.map((item) => ({
                        value: item.code_code,
                        text: item.code_description,
                      }))
                    : []
                }
              />
            </div>
            <div style={{ flex: 1 }}>
              <FormItemSelect
                name="subscription_cutoff_timezone"
                labelCol={0}
                wrapperCol={24}
                allowClear
                options={
                  fundDataDropdown?.subscription_cutoff_timezone &&
                  fundDataDropdown?.subscription_cutoff_timezone.length > 0
                    ? fundDataDropdown?.subscription_cutoff_timezone.map((item) => ({
                        value: item.code_code,
                        text: item.code_description,
                      }))
                    : []
                }
              />
            </div>
          </div>
          <FormItemSelect
            name="subscription_settlement_days"
            label={<RenderLabelForm value="Subscription Settlement (days)" />}
            labelCol={12}
            wrapperCol={12}
            allowClear
            options={
              fundDataDropdown?.subscription_settlement_days &&
              fundDataDropdown?.subscription_settlement_days.length > 0
                ? fundDataDropdown?.subscription_settlement_days.map((item) => ({
                    value: item.code_code,
                    text: item.code_description,
                  }))
                : []
            }
          />
          <FormItemSelect
            name="equity_redemption"
            label={<RenderLabelForm value="Equity Redemptions" />}
            labelCol={12}
            wrapperCol={12}
            options={
              fundDataDropdown?.equity_redemption && fundDataDropdown?.equity_redemption.length > 0
                ? fundDataDropdown?.equity_redemption.map((item) => ({
                    value: item.code_code,
                    text: item.code_description,
                  }))
                : []
            }
          />
          <FormItemInput
            name="redemption_advance_notice"
            label={<RenderLabelForm value="Redemption Advance Notice (days)" />}
            labelCol={12}
            wrapperCol={12}
            normalize="number"
            typeField="number"
          />
          <Row>
            <Col span={6}>
              <FormItemInput
                name="redemption_return_percent"
                labelCol={0}
                wrapperCol={24}
                normalize="number"
                typeField="number"
              />
            </Col>
            <Col span={18} style={{ display: 'flex', alignItems: 'baseline' }}>
              <div style={{ padding: '0 1rem' }}>
                <RenderLabelForm value="% Redemption Returned within (days)" />
              </div>
              <div style={{ flex: 1 }}>
                <FormItemInput name="redemption_return_days" wrapperCol={24} normalize="number" typeField="number" />
              </div>
            </Col>
          </Row>

          <FormItemInput
            name="balance_redemption_return_days"
            label={<RenderLabelForm value="Redemption Balance Returned within (days)" />}
            labelCol={12}
            wrapperCol={12}
            normalize="number"
            typeField="number"
          />
        </Col>
        <Col span={12} xl={12} lg={12} xs={24}>
          {RIGHT_TOP_GROUP_FORMITEM.map((item) => {
            return item.isAutoComplete ? (
              <FormItemAutoComplete
                key={item.name}
                name={item.name}
                label={<RenderLabelForm value={item.text} />}
                labelCol={12}
                wrapperCol={12}
                options={item.options}
                rules={
                  item.maxLength && item.name === 'prime_broker'
                    ? [
                        {
                          max: 60,
                          message: 'Value should be less than 60 characters',
                        },
                      ]
                    : undefined
                }
              />
            ) : item.isCheckBox ? (
              <Row key={item.name} justify="center">
                <Col span={12} xl={12} lg={12} xs={24} offset={12}>
                  <FormItemCheckBox name={item.name} text={<RenderLabelForm value={item.text} />} />
                </Col>
              </Row>
            ) : item.options ? (
              <FormItemSelect
                key={item.name}
                name={item.name}
                label={<RenderLabelForm value={item.text} />}
                labelCol={12}
                wrapperCol={12}
                options={item.options}
              />
            ) : (
              <FormItemInput
                name={item.name}
                label={<RenderLabelForm value={item.text} />}
                maxLength={60}
                placeholder="Banking Agent..."
                labelCol={12}
                wrapperCol={12}
              />
            )
          })}

          <FormItemDatePicker
            label={
              <>
                <div style={{ marginTop: '0.5rem', marginRight: '0.5rem' }}>
                  <FormItemCheckBox name="is_annual_performance_audit" />
                </div>
                <RenderLabelForm value="Annual Performance Audit at" />
              </>
            }
            name="last_audit_date"
            wrapperCol={12}
            labelCol={12}
            allowClear
          />
        </Col>
      </Row>
      <DividerForm />

      <Row gutter={16}>
        <Col span={12} xl={12} lg={12} xs={24}>
          {LEFT_BOT_GROUP_FORMITEM.map((item) => {
            return (
              <FormItemInput
                name={item.name}
                key={item.name}
                label={<RenderLabelForm value={item.text} />}
                labelCol={12}
                wrapperCol={12}
                normalize={item.type}
                typeField={item.type}
                maxLength={item.maxLength}
              />
            )
          })}
        </Col>
        <Col span={12} xl={12} lg={12} xs={24}>
          {RIGHT_BOT_GROUP_FORMITEM.map((item) => {
            return item.isCheckBox ? (
              <Row key={item.name} justify="center">
                <Col span={12} xl={12} lg={12} xs={24} offset={12}>
                  <FormItemCheckBox name={item.name} text={<RenderLabelForm value={item.text} />} />
                </Col>
              </Row>
            ) : (
              <FormItemSelect
                disabled={item.disabled}
                key={item.name}
                name={item.name}
                label={<RenderLabelForm value={item.text} />}
                allowClear={item.allowClear}
                labelCol={12}
                wrapperCol={12}
                options={item.options || []}
              />
            )
          })}
        </Col>
      </Row>
    </Wrapper>
  )
}
