import { Button, Col, Row, Select } from 'antd'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../../../../app/rootReducer'
import APIService from '../../../../../shared/api'
import {
  FundDetailDataContext,
  PERFORMANCE_REQUEST_ENUM,
} from '../../../../../shared/api/context/FundDetailDataContext'
import DataSheet, { DataSheetType } from '../../../../../shared/components/DataSheet'
import { DividerForm } from '../../../../../shared/components/DividerForm'
import { FormItemCheckBox } from '../../../../../shared/components/FormItemComponents'
import { RenderLabelForm } from '../../../../../shared/components/RenderLabelForm'
import { useServiceState } from '../../../../../shared/hooks/useServiceState'
import { resetAsset } from '../../../../../slice/appEventSlice'
import HistoricalData from '../../HistoricalData'

const Flex = styled.div`
  display: flex;
  flex: 1;
  padding: 0.5rem 1rem;
`

type Props = {}
export const MilionsManaged: React.FC<Props> = () => {
  const {
    setAssetDataChange,
    assetDataChange,
    dataAssetLoading,
    dataAsset,
    getPerformanceRequest,
    dataFundDetail,
  } = React.useContext(FundDetailDataContext)

  const { fundAssetUpdated } = useSelector((state: RootState) => state.appEvent)
  const dispatch = useDispatch()

  const [selectedHistorical, setSelectedHistorical] = React.useState<number>()
  const [selectedDate, setSelectedDate] = React.useState<string>()

  const { invoke: getLogEvent, data: dataLogEvent } = useServiceState(APIService.activityLogService.fetchDataEvent)

  // refetch events when flag `fundAssetUpdated` is true, triggered by successfully update Fund Assets or Latest Performance
  React.useEffect(() => {
    if (!dataFundDetail?.fund_id) {
      return
    }
    if (fundAssetUpdated) {
      getLogEvent({
        where: {
          code: 'UPDATE_FUND_ASSETS',
          fund_id: { equals: dataFundDetail.fund_id },
        },
        limit: 100,
        page: 1,
        order_by: 'DESC',
        sort_by: 'created_date',
        show_json_data: true,
      })
      dispatch(resetAsset())
    }
  }, [dataFundDetail?.fund_id, dispatch, fundAssetUpdated, getLogEvent])

  React.useEffect(() => {
    if (!dataFundDetail?.fund_id) {
      return
    }
    getLogEvent({
      where: {
        code: 'UPDATE_FUND_ASSETS',
        fund_id: { equals: dataFundDetail.fund_id },
      },
      limit: 100,
      page: 1,
      order_by: 'DESC',
      sort_by: 'created_date',
      show_json_data: true,
    })
  }, [dataFundDetail?.fund_id, getLogEvent])

  React.useEffect(() => {
    getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.ASSET)
  }, [getPerformanceRequest])

  return (
    <Flex style={{ flexDirection: 'column' }}>
      <Row style={{ display: 'flex', flex: 1 }}>
        <Col span={6}>
          <FormItemCheckBox name="is_aum_distributed" text={<RenderLabelForm value="Is AUM Distributed ?" />} />
        </Col>
      </Row>
      <Flex style={{ flexDirection: 'column' }}>
        <DividerForm text="Asset" notMargin />
      </Flex>
      <Row style={{ margin: '8px 0' }}>
        <Col span={8}>
          <Select
            style={{ width: '100%' }}
            placeholder="Select Historical Snapshot Data"
            value={selectedHistorical}
            onChange={(data) => {
              setSelectedHistorical(data ? +data : undefined)
            }}
            allowClear
          >
            {dataLogEvent?.events.map((event) => (
              <Select.Option key={event.id} value={event.id}>
                <b>{event.user__username}</b> - Updated at {event.created_date}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={2}>
          {selectedHistorical && (
            <Button type="link" onClick={() => setSelectedHistorical(undefined)}>
              Show current
            </Button>
          )}
        </Col>
      </Row>
      {selectedHistorical ? (
        <DataSheet
          currencyValue={dataFundDetail?.denomination ? dataFundDetail.denomination : undefined}
          type={DataSheetType.ASSET}
          isReadOnly
          initialData={dataLogEvent?.events.find((item) => item.id === selectedHistorical)?.json_data.values}
        />
      ) : (
        <DataSheet
          currencyValue={dataFundDetail?.denomination ? dataFundDetail.denomination : undefined}
          minDate={dataFundDetail?.inception_date ? dataFundDetail.inception_date : undefined}
          type={DataSheetType.ASSET}
          draft={assetDataChange.values}
          initialData={dataAsset}
          canAdd
          loading={dataAssetLoading}
          setRORDataChange={setAssetDataChange}
          onCellSelect={setSelectedDate}
        />
      )}
      {dataFundDetail?.fund_id && (
        <Row>
          <Col span={24}>
            <HistoricalData
              fundId={dataFundDetail.fund_id}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              type={PERFORMANCE_REQUEST_ENUM.ASSET}
            />
          </Col>
        </Row>
      )}
    </Flex>
  )
}
