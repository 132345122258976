import { SearchOutlined } from '@ant-design/icons'
import { AutoComplete, Button, Input, Popconfirm } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import styled from 'styled-components'
import {
  LeftNavExNewFundEnum,
  ListSearchExNewFundType,
  renderSearchListExNewFund,
} from '../../../shared/SearchExNewFundLabel'

const Flex = styled.div`
  display: flex;
  flex: 1;
`

type PropsTitle = {
  optionFillter: ListSearchExNewFundType[]
  textFilterSearch: string
  setOptionFilter: (value: ListSearchExNewFundType[]) => void
  settextFilterSearch: (value: string) => void
  setActiveTab: (index: LeftNavExNewFundEnum) => void
  activeTab: LeftNavExNewFundEnum
  isUpdateFund?: boolean
  onCancel?: () => void
}
export const ExNewFundTitle: React.FC<PropsTitle> = ({
  setOptionFilter,
  settextFilterSearch,
  textFilterSearch,
  setActiveTab,
  onCancel,
  isUpdateFund,
  activeTab,
}) => {
  const [optionShow, setOptionShow] = React.useState(renderSearchListExNewFund)

  const handleSearch = (value: any) => {
    settextFilterSearch(value)
    const newOption = renderSearchListExNewFund.filter(
      (item) => item.name.toUpperCase().indexOf(value.toUpperCase()) !== -1,
    )
    setOptionFilter(newOption)
    setOptionShow(newOption)
    if (newOption.findIndex((item) => item.leftNav === activeTab) === -1 && newOption.length > 0) {
      setActiveTab(newOption[0].leftNav)
    }
  }

  return (
    <Flex style={{ flexDirection: 'column' }}>
      <Flex style={{ margin: '10px 0', alignItems: 'baseline' }}>
        <div>
          <Text style={{ fontSize: 24, fontWeight: 'bold', color: '#484848' }}>Create New Fund</Text>
        </div>
        <Flex style={{ justifyContent: 'flex-end' }}>
          <AutoComplete
            onSelect={(value, options) => {
              if (options.label) {
                const text = options.label.toLocaleString()
                settextFilterSearch(text)
                const newOption = renderSearchListExNewFund.filter(
                  (item) => item.name.toUpperCase().indexOf(text.toUpperCase()) !== -1,
                )
                setOptionShow(newOption)
                const saveOption = newOption.filter((item) => item.key === value)
                setOptionFilter(saveOption)
                if (saveOption.findIndex((item) => item.leftNav === activeTab) === -1 && newOption.length > 0) {
                  setActiveTab(saveOption[0].leftNav)
                }
              }
            }}
            onSearch={handleSearch}
            options={optionShow.map((item) => ({ value: item.key, label: item.name }))}
            allowClear
            value={textFilterSearch}
            style={{ width: 200, height: 32, marginRight: 8 }}
          >
            <Input placeholder="Go to Field" prefix={<SearchOutlined />} />
          </AutoComplete>
          {isUpdateFund ? (
            <Popconfirm
              placement="bottomLeft"
              title={'You have unsaved changes, are you sure you want to cancel?'}
              onConfirm={onCancel}
              okText="Yes"
              cancelText="No"
            >
              <Button style={{ marginRight: 8 }}>Discard Changes</Button>
            </Popconfirm>
          ) : (
            <Button onClick={onCancel} style={{ marginRight: 8 }}>
              Discard Changes
            </Button>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
