import {
  HomeOutlined,
  LockOutlined,
  MailOutlined,
  NumberOutlined,
  PhoneOutlined,
  ProjectOutlined,
  QuestionCircleFilled,
  QuestionCircleOutlined,
  ShopOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Button, Checkbox, Divider, Form, Input, notification, Select } from 'antd'
import { Store } from 'antd/lib/form/interface'
import { useForm } from 'antd/lib/form/util'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import APIService from '../../../shared/api'
import { SignUpParams } from '../../../shared/api/services/auth.service'
import { useServiceState } from '../../../shared/hooks/useServiceState'
import { States } from '../../../shared/states'

const SignUpContainer = styled.div`
  background: white;
  width: 30%;
  min-width: 500px;
  flex-direction: column;
  justify-content: safe center;
  align-items: center;
  padding: 0 40px;
  overflow-y: auto;
  form {
    margin: auto;
    padding: 40px 0;
    width: 100%;
  }
`

const Inline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div {
    flex-grow: 1;
    margin-right: 10px;
    &:last-child {
      margin: 0;
    }
  }
`

type Props = {
  verificationCode: string
  email: string | undefined
  nextStep: () => void
}
export const SignUpForm: React.FC<Props> = ({ email, verificationCode, nextStep }) => {
  const [form] = useForm()
  const [loading, setLoading] = React.useState(false)

  const [fundOrVehicleName, setFundOrVehicleName] = React.useState('')
  const [heardAboutHfrFrom, setHeardAboutHfrFrom] = React.useState('')

  const { data: countriesData, loading: countriesLoading, invoke: invokeFetchCountries } = useServiceState<
    string[],
    undefined
  >(APIService.commonService.fetchAllCountries)

  React.useEffect(() => {
    invokeFetchCountries()
  }, [invokeFetchCountries])

  const handleSubmit = (e: Store) => {
    // e.preventDefault()
    setLoading(true)
    delete e.passwordConfirm
    APIService.authService
      .signUp({ ...(e as SignUpParams), code: verificationCode, manager_link: true })
      .then(() => {
        // APIService.eventService.sendSignupEvent()
        notification.success({ message: 'Account has been created!' })
        nextStep()
      })
      .catch((err) => {
        console.error(err)
        notification.error({ message: 'Something went wrong!' })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getCountryOptions = () => {
    if (!countriesData) {
      return
    } else {
      const countryCodes = Object.keys(countriesData).filter((code) => !['US', 'GB', 'CA'].includes(code))
      countryCodes.unshift('US', 'GB', 'CA')
      return countryCodes.map((countryKey: any) => {
        return (
          <Select.Option key={countryKey} value={countryKey}>
            {countriesData[countryKey]}
          </Select.Option>
        )
      })
    }
  }

  const getStateOptions = () => {
    const statesOptions = []
    for (const stateKey in States) {
      statesOptions.push(
        <Select.Option key={stateKey} value={States[stateKey]}>
          {States[stateKey]}
        </Select.Option>,
      )
    }
    return statesOptions
  }

  return (
    <SignUpContainer>
      <Form onFinish={handleSubmit} className="login-form" layout={'horizontal'} form={form}>
        {/* FirstName */}
        <Divider orientation={'left'}>Personal Information</Divider>
        <Inline>
          <Form.Item name="firstName" rules={[{ required: true, message: 'First Name is required' }]}>
            <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="First Name" />
          </Form.Item>
          {/* LastName */}
          <Form.Item name="lastName" rules={[{ required: true, message: 'Last Name is required' }]}>
            <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="text" placeholder="Last Name" />
          </Form.Item>
        </Inline>
        {/* Email */}
        <Form.Item
          name="email"
          rules={[
            { required: true, message: 'Email is required' },
            { type: 'email', message: 'Email must be valid' },
          ]}
          initialValue={email}
        >
          <Input
            disabled
            formNoValidate={true}
            prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="email"
            placeholder="Email"
          />
        </Form.Item>
        {/* Password */}
        <Inline>
          <Form.Item
            hasFeedback
            name="password"
            rules={[
              { required: true, message: 'Password is required' },
              {
                pattern: RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'),
                message: (
                  <>
                    <QuestionCircleFilled style={{ marginRight: 5 }} />
                    <span>Password is invalid</span>
                  </>
                ),
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Choose Password"
            />
          </Form.Item>
          {/* Password */}
          <Form.Item
            hasFeedback
            name="passwordConfirm"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Confirm password is required' },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject('The two passwords that you entered do not match!')
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Confirm Password"
            />
          </Form.Item>
        </Inline>
        {!!form.getFieldError('password') && (
          <Inline>
            <span>
              Make sure it&apos;s at least 8 characters including 1 number, 1 lowercase, 1 uppercase letter and 1
              special character.
            </span>
          </Inline>
        )}
        {/* Company */}
        <Divider orientation={'left'}>Firm Information</Divider>
        <Form.Item name="company" rules={[{ required: true, message: 'Firm Name is required' }]}>
          <Input
            prefix={<ShopOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="text"
            placeholder="Firm Name (Please Provide Legally-Registered Name)"
          />
        </Form.Item>
        {/* JobTitle */}
        <Form.Item name="jobTitle" rules={[{ required: true, message: 'Job Title is required' }]}>
          <Input
            prefix={<ProjectOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="text"
            placeholder="Job Title"
          />
        </Form.Item>
        {/* Business Phone */}
        <Form.Item name="businessPhone" rules={[{ required: true, message: 'Business Phone is required' }]}>
          <Input
            prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="text"
            placeholder="Business Phone"
          />
        </Form.Item>
        {/* Address1 */}
        <Form.Item name="address" rules={[{ required: true, message: 'Company address is required' }]}>
          <Input prefix={<HomeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="text" placeholder="Address" />
        </Form.Item>
        {/* Address2 */}
        <Form.Item name="address2" rules={[]}>
          <Input
            prefix={<HomeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="text"
            placeholder="Address 2 (Optional)"
          />
        </Form.Item>
        {/* City */}
        <Inline>
          <Form.Item name="city" rules={[{ required: true, message: 'City is required' }]}>
            <Input prefix={<HomeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="text" placeholder="City" />
          </Form.Item>
          <Form.Item name="state">
            {form.getFieldValue('country') === 'US' ? (
              <Select
                placeholder="State (USA Only)"
                showSearch
                style={{ width: 160 }}
                filterOption={(input, option) => {
                  if (!option || !option.props || !option.props.children) {
                    return false
                  }
                  return (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }}
                allowClear
              >
                {getStateOptions()}
              </Select>
            ) : (
              <Input
                prefix={<HomeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="text"
                placeholder="State/Province"
              />
            )}
          </Form.Item>
          {/* US/Canada Postal Code */}
          <Form.Item name="postalCode" rules={[]}>
            <Input
              prefix={<HomeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="text"
              placeholder="US/Canada Postal Code"
            />
          </Form.Item>
        </Inline>
        {/* Country */}
        <Form.Item name="country" rules={[{ required: true, message: 'Country is required' }]}>
          <Select
            loading={countriesLoading}
            // prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Country"
            showSearch
            filterOption={(input, option) => {
              if (!option || !option.props || !option.props.children) {
                return false
              }
              return (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
            allowClear
          >
            {getCountryOptions()}
          </Select>
        </Form.Item>
        <Form.Item
          name="fund_or_vehicle_name"
          rules={[{ required: true, message: 'Fund or vehicle name is required' }]}
          extra={
            fundOrVehicleName.length >= 300 ? (
              <Inline>
                <span>Max length reached (300 characters)</span>
              </Inline>
            ) : null
          }
        >
          <Input
            prefix={<ShopOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="text"
            placeholder="Fund Name / Vehicle Name (max 300 characters)"
            maxLength={300}
            value={fundOrVehicleName}
            onChange={(e) => {
              setFundOrVehicleName(e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item
          name="heard_about_hfr_from"
          extra={
            heardAboutHfrFrom.length >= 100 ? (
              <Inline>
                <span>Max length reached (100 characters)</span>
              </Inline>
            ) : null
          }
        >
          <Input
            prefix={<QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="text"
            placeholder="Please tell us how you heard about HFR (max 100 characters)"
            maxLength={100}
            value={heardAboutHfrFrom}
            onChange={(e) => {
              setHeardAboutHfrFrom(e.target.value)
            }}
          />
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }} name="reports_fund_performance_to_hfr" valuePropName="checked">
          <Checkbox>Check if you currently report fund performance to HFR</Checkbox>
        </Form.Item>
        <Form.Item name="honeypot" style={{ visibility: 'hidden' }}>
          <Input
            prefix={<NumberOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="text"
            placeholder="Honey Pot?"
          />
        </Form.Item>
        <Inline>
          <Button loading={loading} type="primary" htmlType="submit" className="login-form-button" style={{ flex: 2 }}>
            Sign up
          </Button>
          <Link to="/auth/log-in" style={{ flex: 1 }}>
            <Button type="link" style={{ width: '100%' }}>
              Cancel
            </Button>
          </Link>
        </Inline>
      </Form>
    </SignUpContainer>
  )
}
