import { Col, Form, Input, notification, Row, Spin } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import APIService from '../../../../../shared/api'
import { DueDiligenceSentSepActCreate } from '../../../../../shared/api/models/FirmDetails'

type Props = {
  isShowModal: boolean
  setIsShowModal: (value: boolean) => void
  updateSentSepAct: () => void
  firmId: string
  fundId: string
}
export const SentSepActModal: React.FC<Props> = ({ isShowModal, setIsShowModal, updateSentSepAct, firmId, fundId }) => {
  const [loadingSubmit, setLoadingSubmit] = React.useState(false)

  const [form] = Form.useForm()
  React.useEffect(() => {
    form.resetFields()
  }, [form])

  const submitCreateSentMess = () => {
    const submitData: DueDiligenceSentSepActCreate = {
      fund_id: fundId,
      sent_to: form.getFieldValue('sent_to'),
    }

    form.validateFields(['sent_to']).then(() => {
      setLoadingSubmit(true)
      APIService.firmDetailService
        .createDueDiligenceSentMess(firmId, submitData)
        .then(() => {
          notification.success({ message: 'Send Message Created!' })
          updateSentSepAct()
        })
        .catch((err) => {
          console.error({ err })
          notification.error({ message: 'Send Message Create Failed!' })
        })
        .finally(() => {
          setLoadingSubmit(false)
          setIsShowModal(false)
          form.resetFields()
        })
    })
  }
  return (
    <Modal
      visible={isShowModal}
      title={'Send To'}
      okText={
        loadingSubmit ? (
          <>
            <Spin style={{ marginRight: 4 }} />
          </>
        ) : (
          'OK'
        )
      }
      onCancel={() => {
        setIsShowModal(false)
      }}
      onOk={submitCreateSentMess}
    >
      <div>
        <Form layout="vertical" form={form}>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Send To"
                name="sent_to"
                rules={[{ required: true, message: 'Send Message is required' }]}
              >
                <Input.TextArea placeholder="Send To." maxLength={40} rows={3} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
