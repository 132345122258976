import { Checkbox, Col, Form, Radio, Row, Select } from 'antd'
import { FormInstance } from 'antd/lib/form'
import TextArea from 'antd/lib/input/TextArea'
import Table, { ColumnProps } from 'antd/lib/table'
import React from 'react'
import styled from 'styled-components'
import { FirmDetailDataContext } from '../../../../shared/api/context/FirmDetailDataContext'
import { FundCCMCResponse } from '../../../../shared/api/models/FundDetails'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem;

  th.ant-table-cell {
    vertical-align: top;
  }

  td.ant-table-cell {
    vertical-align: top;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-radio-wrapper {
    white-space: normal;
  }
`

type Props = {
  initialData?: FundCCMCResponse[]
  data?: FundCCMCResponse[]
  form: FormInstance
}

const FirmCMIC: React.FC<Props> = ({ initialData, data, form }) => {
  const { fundDataDropdown } = React.useContext(FirmDetailDataContext)

  const columns: ColumnProps<FundCCMCResponse>[] = [
    {
      title: 'Fund Name',
      key: 'fund_name',
      dataIndex: 'fund_name',
      render: (value) => <b>{value}</b>,
      width: '30%',
    },
    {
      title: (
        <div>
          1(a). Does the fund currently hold any investments whether directly or indirectly (e.g.,{' '}
          <i>publicly traded stocks, bonds, derivatives, warrants, ADRs, GDRs, ETFs, index funds and mutual funds</i>)
          related to the securities identified in{' '}
          <span style={{ textDecoration: 'underline', fontWeight: 600 }}>E.O. 14032</span>?
        </div>
      ),
      key: 'has_securities',
      render: (_, rowData) => {
        const isEdited =
          !initialData || initialData.length === 0
            ? false
            : rowData.has_securities !== initialData.find((item) => item.fund_id === rowData.fund_id)?.has_securities
        return (
          <Form.Item name={['_firmCMICDataChange', rowData.fund_id, 'has_securities']}>
            <Radio.Group
              style={{ backgroundColor: isEdited ? '#fffbe6' : undefined, display: 'flex', flexDirection: 'column' }}
            >
              <Radio value={1}>Yes</Radio>
              <Radio value={0}>No</Radio>
              <Radio value={2}>Does Not Apply to Fund</Radio>
            </Radio.Group>
          </Form.Item>
        )
      },
      width: '20%',
    },
    {
      title: (
        <div>
          1(b). <span style={{ textDecoration: 'underline', color: 'red' }}>If Yes to 1(a)</span>, confirm your plan to
          divest of these assets by the U.S. deadline of{' '}
          <span style={{ textDecoration: 'underline', fontWeight: 600 }}>June 23, 2022</span>, and that you will not add
          to such positions in the meantime.
        </div>
      ),
      key: 'manager_confirms',
      render: (_, rowData) => {
        const isEdited =
          !initialData || initialData.length === 0
            ? false
            : rowData.manager_confirms !==
              initialData.find((item) => item.fund_id === rowData.fund_id)?.manager_confirms
        return (
          <Form.Item name={['_firmCMICDataChange', rowData.fund_id, 'manager_confirms']}>
            <Radio.Group
              disabled={form.getFieldValue(['_firmCMICDataChange', rowData.fund_id, 'has_securities']) !== 1}
              style={{ backgroundColor: isEdited ? '#fffbe6' : undefined }}
            >
              <Radio value={true}>Manager Confirms</Radio>
              <Radio value={false}>Manager Does Not Confirm</Radio>
            </Radio.Group>
          </Form.Item>
        )
      },
      width: '20%',
    },
    {
      title: (
        <>
          <p>
            2. Identify or describe any measures you have or will be implementing to prevent the intentional or
            inadvertent purchase of investments related to the prohibited securities on an ongoing basis.
          </p>
          <i>
            Below is a link to the complete list of prohibited securities available on the U.S. Department of Treasury
            website:
          </i>
          <p>
            <span>PDF: </span>
            <a
              href="https://www.treasury.gov/ofac/downloads/ccmc/nscmiclist.pdf"
              download="nscmiclist.pdf"
              target="_blank"
              rel="noreferrer"
            >
              https://www.treasury.gov/ofac/downloads/ccmc/nscmiclist.pdf
            </a>
          </p>
          <p>
            <span>TXT: </span>
            <a
              href="https://www.treasury.gov/ofac/downloads/ccmc/nscmiclist.txt"
              download="nscmiclist.txt"
              target="_blank"
              rel="noreferrer"
            >
              https://www.treasury.gov/ofac/downloads/ccmc/nscmiclist.txt
            </a>
          </p>
        </>
      ),
      width: '30%',
      render: (_, rowData) => {
        const isSeparateShareClassesEdited =
          !initialData || initialData.length === 0
            ? false
            : rowData.separate_share_classes !==
              initialData.find((item) => item.fund_id === rowData.fund_id)?.separate_share_classes
        const isPreTradeEdited =
          !initialData || initialData.length === 0
            ? false
            : rowData.pre_trade_compliance_restrictions !==
              initialData.find((item) => item.fund_id === rowData.fund_id)?.pre_trade_compliance_restrictions
        const isOtherEdited =
          !initialData || initialData.length === 0
            ? false
            : rowData.other_measures !== initialData.find((item) => item.fund_id === rowData.fund_id)?.other_measures
        return (
          <>
            <Row>
              <Col span={24}>
                <Form.Item
                  name={['_firmCMICDataChange', rowData.fund_id, 'separate_share_classes']}
                  valuePropName="checked"
                  style={{ marginBottom: 0 }}
                >
                  <Checkbox
                    style={{ backgroundColor: isSeparateShareClassesEdited ? '#fffbe6' : undefined }}
                    disabled={form.getFieldValue(['_firmCMICDataChange', rowData.fund_id, 'has_securities']) === 2}
                  >
                    Separate Share Classes
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  name={['_firmCMICDataChange', rowData.fund_id, 'pre_trade_compliance_restrictions']}
                  valuePropName="checked"
                  style={{ marginBottom: 0 }}
                >
                  <Checkbox
                    style={{ backgroundColor: isPreTradeEdited ? '#fffbe6' : undefined }}
                    disabled={form.getFieldValue(['_firmCMICDataChange', rowData.fund_id, 'has_securities']) === 2}
                  >
                    Pre-trade Compliance Restrictions
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  name={['_firmCMICDataChange', rowData.fund_id, 'other_measures']}
                  style={{ display: 'flex', flexDirection: 'column' }}
                  label="Other (Describe)"
                  labelAlign="left"
                  validateStatus={isOtherEdited ? 'warning' : undefined}
                >
                  <TextArea
                    rows={3}
                    style={
                      form.getFieldValue(['_firmCMICDataChange', rowData.fund_id, 'has_securities']) === 2
                        ? { backgroundColor: '#f5f5f5' }
                        : undefined
                    }
                    disabled={form.getFieldValue(['_firmCMICDataChange', rowData.fund_id, 'has_securities']) === 2}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )
      },
    },
    {
      title: '3. Subscription Advanced Notice (# days)',
      width: 120,
      render: (_, rowData) => {
        const isEdited =
          !initialData || initialData.length === 0
            ? false
            : rowData.subscription_notice !==
              initialData.find((item) => item.fund_id === rowData.fund_id)?.subscription_notice
        return (
          <Form.Item
            name={['_firmCMICDataChange', rowData.fund_id, 'subscription_notice']}
            validateStatus={isEdited ? 'warning' : undefined}
          >
            <Select
              options={
                fundDataDropdown?.subscription_advance_notice &&
                fundDataDropdown?.subscription_advance_notice.length > 0
                  ? fundDataDropdown?.subscription_advance_notice.map((item) => ({
                      value: item.code_code,
                      text: item.code_description,
                    }))
                  : []
              }
            />
          </Form.Item>
        )
      },
    },
  ]

  const finalData = React.useMemo(
    () =>
      (data || initialData || [])
        .sort((a, b) => (a.fund_name > b.fund_name ? 1 : -1))
        .map((item) => ({
          ...item,
          fund_name: initialData?.find((i) => i.fund_id === item.fund_id)?.fund_name || '',
        })),
    [data, initialData],
  )

  return (
    <Wrapper>
      <Table
        rowKey="fund_id"
        size="small"
        columns={columns}
        dataSource={finalData.sort((a, b) => (a.fund_name > b.fund_name ? 1 : -1))}
        pagination={{ hideOnSinglePage: true }}
        scroll={{ y: 'calc(100% - 400px)' }}
      />
    </Wrapper>
  )
}

export default FirmCMIC
