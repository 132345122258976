import { Button, Col, Collapse, Divider, List, Row } from 'antd'
import React, { ReactFragment } from 'react'
import styled from 'styled-components'
import { LeftNavExNewFundEnum } from '../../../shared/SearchExNewFundLabel'
import { TAB_LIST_CREATE_NEW_FUND } from './ExNewFundMain'
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  margin-top: 1rem;
  flex-direction: column;
  padding: 0 2rem;
`
const { Panel } = Collapse
const LIST_NOTE: ReactFragment[] = [
  <div key={1}>
    1. All funds must report performance on a monthly basis in order to use ManagerLink.
    <br />
    Please contact HFR at <a href="tel:+1-312-658-0955">(312) 658-0955</a> or via email{' '}
    <a href="mail:performance@hfr.com">performance@hfr.com</a> if your new fund does not report monthly performance.
  </div>,
  <div key={2}>
    2. The process of adding a new fund requires that you enter your new fund information in sequence.
    <br />
    However, after you have completed the full 12-step process, you may add/edit your Fund Profile in any manner you
    wish.
  </div>,
  <div key={3}>
    3. Creating a new fund via ManagerLink DOES NOT immediately add the fund to the HFR Database.
    <br />
    ALL new funds must be reviewed and approved by HFR before being activated.
    <br />
    When you are ready to add your fund to HFR Database please click the gold &quot;activate fund&quot; link located on
    the left side of the screen.
  </div>,
]
const LIST_FIELD = [
  {
    title: 'Contact Information',
    field: [
      'Performance Contact',
      'Address Info',
      'City',
      'State',
      'Country',
      'Postal Code',
      'Phone',
      'Fax',
      'Email',
      'Website',
    ],
  },
  {
    title: 'Fund Principals',
    field: ['Name', 'Title'],
  },
  {
    title: 'General Information',
    field: [
      'Investment Strategy',
      'Investment Sub-Strategy',
      'Regional Investment Focus',
      'Minimum Investment',
      'Additional Investment',
      'Individual Account Minimum Investment',
      'Open To New Investors',
      'Domicile',
      'Structure',
      'Fund Registration',
      'Open To Investor Type',
      'Subscriptions',
      'Redemptions',
      'Advanced Notice Period',
      'Lockup Period',
      'Performance Reported To Investors Interval',
      'Leverage Type',
      'ISIN',
      'UCITSIII compliance',
      'Ticker',
    ],
  },
  {
    title: 'Fee Schedule',
    field: [
      'Management Fee',
      'Incentive Fee',
      'High Watermark',
      'Hurdle Rate',
      'Redemption Fee',
      'Administrative Fee',
      'Sales Fee',
      'Other Fee',
    ],
  },
  {
    title: 'Fund Administration',
    field: [
      'Last Audit Date',
      'Auditor Name',
      'Prime Broker',
      'Banking Agent',
      'Administrator',
      'Legal Adviser',
      'Custodian',
      'Consultant',
    ],
  },
  {
    title: 'Fund Investment Style',
    field: [
      'Describe Your Investment Process',
      'Hedging Techniques Used',
      'Has Your Strategy Or Investment Process Change Over time?',
      'What Are The Risks Inherent To Your Approach/methodology?',
      'What Principles Are Used To Control Risk?',
      'Do You Invest In Hot Issues?',
      'Is This Fund Intended To Be Tax Efficient?(Domestic Funds Only)',
      'Asset Level Where New Investment Will Not Be Accepted',
      'What Factors Do You Consider When Determining The Maximum Capacity Of The Strategy?',
      'Which Managers Do You Consider To Be Similar In Style To Your Approach?',
    ],
  },
  {
    title: 'Instruments',
    field: ['Date', 'Instrument', 'Long %', 'Short %', 'Net %'],
  },
  {
    title: 'Industry Group',
    field: ['Date', 'Industry Group', 'Long %', 'Short %', 'Net %'],
  },
  {
    title: 'Region Weight',
    field: ['Date', 'Region', 'Long %', 'Short %', 'Net %'],
  },
  {
    title: 'Leverage',
    field: ['Date', 'Minimum %', 'Maximum %', 'Average %'],
  },
  {
    title: 'Investor Type',
    field: ['Date', 'Investor Type', '% of Total Assets', '% of All Investors'],
  },
]
type Props = {
  setStepTab: (value: LeftNavExNewFundEnum) => void
  setActiveTab: (value: LeftNavExNewFundEnum) => void
}
export const ExNewFundInstruction: React.FC<Props> = ({ setStepTab, setActiveTab }) => {
  const handleNext = () => {
    setStepTab(TAB_LIST_CREATE_NEW_FUND[1].id)

    setActiveTab(TAB_LIST_CREATE_NEW_FUND[1].id)
  }

  return (
    <Wrapper>
      <Row>
        <Collapse style={{ width: '100%' }}>
          <Panel header="New Fund Information Checklist" key="1">
            <List
              size="large"
              dataSource={LIST_FIELD}
              renderItem={(item) => (
                <List.Item>
                  <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Divider orientation="left">{item.title}</Divider>
                    <Row gutter={[8, 8]}>
                      {item.field.map((field) => {
                        return (
                          <Col span={6} xl={6} md={12} xs={24} key={field}>
                            {field}
                          </Col>
                        )
                      })}
                    </Row>
                  </div>
                </List.Item>
              )}
              style={{ flex: 1 }}
            />
          </Panel>
        </Collapse>
      </Row>
      <Row style={{ marginTop: '1rem' }}>
        <List
          size="large"
          header={<h2>Please Note</h2>}
          bordered
          dataSource={LIST_NOTE}
          renderItem={(item) => <List.Item>{item}</List.Item>}
          style={{ flex: 1 }}
        />
      </Row>
      <Row style={{ justifyContent: 'center', margin: '1rem 0' }}>
        <Button type="primary" onClick={handleNext}>
          Proceed
        </Button>
      </Row>
    </Wrapper>
  )
}
