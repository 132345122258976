import { Col, Form, List, Radio, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { RootState } from '../../app/rootReducer'
import { addTabs, FundFirmTypeEnum, PREFIX_EXTERNAL } from '../../slice/appSettingsSlice'
import { FundDetailDataContext } from '../api/context/FundDetailDataContext'
import { FundDetailDropdownContext } from '../api/context/FundDetailDropdownContext'
import { FundDropdownCodeType, FundDropdownType } from '../api/models/FundDetails'
import { Colors } from '../colors'
import { LeftNavExFirmEnum } from '../SearchExFirmLabel'
import { LeftNavFirmEnum } from '../SearchFirmLabel'
import { FormItemRadio, FormItemSelect, FormItemTextArea } from './FormItemComponents'
import { RenderLabelExFund } from './RenderLabelExFund'
import { RenderLabelForm } from './RenderLabelForm'
import { updateFieldValue } from '../utils/formUtils'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  margin-top: 1rem;
  flex-direction: column;
  padding: 0 2rem;
`
const ListItem = styled(List.Item)`
  padding: 0 24px;
`

export enum MODE_ESG_VIEW {
  INTERNAL,
  EXTERNAL,
}

type Props = {
  form: FormInstance
  modeView: MODE_ESG_VIEW
}

// const ARRAY_FIELD_VALIDATE: string[] = []

export const FundESGSection: React.FC<Props> = ({ form, modeView }) => {
  const { dataFundDetail } = React.useContext(FundDetailDataContext)
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)
  const isFundESG = form.getFieldValue('is_fund_esg')
  const isConsiderESG = isFundESG === '1'
  const esgStrategyCode = form.getFieldValue('esg_strategy')
  const esgSubStrategyCode = form.getFieldValue('esg_sub_strategy')

  const { esgStrategies, esgSubStrategies, esgSubStrategyDropdown } = useSelector((state: RootState) => state.appData)

  const dispatch = useDispatch()

  const { selectableESGStrategies, selectableESGSubStrategies } = React.useMemo(() => {
    if (!dataFundDetail || !dataFundDetail.sub_strategy_code_new || !esgSubStrategyDropdown) {
      return { selectableESGStrategies: undefined, selectableESGSubStrategies: undefined }
    }
    const availableESGSubStrategyCodes = esgSubStrategyDropdown[dataFundDetail.sub_strategy_code_new]
    // set selectable options to undefined if sub_strategy_code_new is not match any Sub Strategy Dropdown
    if (!availableESGSubStrategyCodes) {
      return { selectableESGStrategies: undefined, selectableESGSubStrategies: undefined }
    }
    //
    const availableESGSubStrategies = esgSubStrategies
      ? esgSubStrategies.filter((item) => availableESGSubStrategyCodes.includes(item.code))
      : undefined
    return {
      selectableESGStrategies: esgStrategies
        ? esgStrategies.filter((item) => availableESGSubStrategies?.some((i) => i.esg_strategy === item.code))
        : undefined,
      selectableESGSubStrategies: availableESGSubStrategies,
    }
  }, [dataFundDetail, esgStrategies, esgSubStrategies, esgSubStrategyDropdown])

  // Reset Esg value in is_fund_esg change to No
  React.useEffect(() => {
    if (isFundESG === '2') {
      const currentEsgStrategyCode = form.getFieldValue('esg_strategy')
      !!currentEsgStrategyCode && updateFieldValue(form, 'esg_strategy', null)
      const currentEsgSubStrategyCode = form.getFieldValue('esg_sub_strategy')
      !!currentEsgSubStrategyCode && updateFieldValue(form, 'esg_sub_strategy', null)
      const currentTeamEngagepcm = form.getFieldValue('team_engagepcm')
      currentTeamEngagepcm !== '2' && updateFieldValue(form, 'team_engagepcm', '2')

      //
      const allFields = [
        ...DESCRIBE_BEST_DESCRIBE,
        ...DESCRIBE_ESG_CRITERIA,
        ...DESCRIBE_ESG_SOCIAL,
        ...DESCRIBE_GOVERNANCE,
        ...ESG_DATA_SOURCES,
      ]
      allFields.map((f) => {
        const currentValue = form.getFieldValue(f.name)
        if (f.isInput) {
          !!currentValue && updateFieldValue(form, f.name, null)
        } else {
          currentValue !== '2' && updateFieldValue(form, f.name, '2')
        }
        return null
      })
    }
  }, [form, isFundESG])

  // Reset sub-strategy whenever esg is being changed
  React.useEffect(() => {
    const currentESGSubStrategy = esgSubStrategies?.find((item) => item.code === esgSubStrategyCode)
    if (esgStrategyCode !== currentESGSubStrategy?.esg_strategy) {
      const currentEsgSubStrategyCode = form.getFieldValue('esg_sub_strategy')
      !!currentEsgSubStrategyCode && updateFieldValue(form, 'esg_sub_strategy', null)
    }
  }, [form, esgStrategyCode, esgSubStrategyCode, esgSubStrategies])

  const optionEsg = React.useMemo(() => {
    return fundDataDropdown?.is_fund_esg && fundDataDropdown.is_fund_esg.length > 0
      ? fundDataDropdown.is_fund_esg
          .map((item) => ({
            value: item.code_code,
            title: item.code_description,
          }))
          .filter((item) => modeView === MODE_ESG_VIEW.INTERNAL || item.value !== '0')
      : []
  }, [fundDataDropdown?.is_fund_esg, modeView])

  const findOptionsRadio = (name: string) => {
    return fundDataDropdown &&
      fundDataDropdown[name as keyof FundDropdownType] &&
      fundDataDropdown[name as keyof FundDropdownType].length > 0
      ? (fundDataDropdown[name as keyof FundDropdownType] as Array<FundDropdownCodeType | string>)
          .map((item) =>
            typeof item === 'string'
              ? { value: item, title: item }
              : {
                  value: item.code_code,
                  title: item.code_description,
                },
          )
          .filter(
            (item) =>
              modeView === MODE_ESG_VIEW.INTERNAL || (typeof item === 'string' ? item !== '0' : item.value !== '0'),
          )
      : []
  }

  const renderLabelField = (value: string, subtitle?: React.ReactElement) => {
    return modeView === MODE_ESG_VIEW.EXTERNAL ? (
      <RenderLabelExFund value={value} subtitle={subtitle} />
    ) : (
      <RenderLabelForm value={value} />
    )
  }

  const LabelComponent = () => (
    <span>
      <i style={{ fontSize: '12px' }}>
        &nbsp; Note: ESG Strategy/Sub-Strategy options dependent on Main Strategy / Sub-strategy classifications in HFR
        Database (‘Investment Strategy’ module)
      </i>
    </span>
  )

  return (
    <Wrapper>
      <Row style={{ marginBottom: 15 }}>
        Open Firm ESG:
        <span
          onClick={() => {
            if (dataFundDetail && modeView === MODE_ESG_VIEW.INTERNAL) {
              dispatch(
                addTabs({
                  name: dataFundDetail?.firm_name,
                  id: dataFundDetail.firm_id,
                  type: FundFirmTypeEnum.FIRMS_IN_FUNDS,
                  path: LeftNavFirmEnum.FIRM_ESG,
                }),
              )
            }
            if (dataFundDetail && modeView === MODE_ESG_VIEW.EXTERNAL) {
              dispatch(
                addTabs({
                  name: dataFundDetail?.firm_name,
                  id: `${PREFIX_EXTERNAL}-${dataFundDetail?.firm_id}`,
                  type: FundFirmTypeEnum.FIRMS_IN_FUNDS,
                  activeNav: LeftNavExFirmEnum.FIRM_ESG_POLICIES,
                }),
              )
            }
          }}
          style={{ cursor: 'pointer', color: Colors.primary, marginLeft: 5 }}
        >
          {dataFundDetail?.firm_name || dataFundDetail?.firm_id}
        </span>
      </Row>
      <Row>
        <Col span={24}>
          <FormItemRadio
            name="is_fund_esg"
            label={renderLabelField('Does Fund Consider ESG Factors?')}
            options={optionEsg}
          />
        </Col>
      </Row>
      <Row style={{ flexDirection: 'column' }}>
        <div style={{ fontStyle: 'italic' }}>If Yes, please answer questions:</div>
        <Col span={24} style={{ position: 'relative' }}>
          <FormItemSelect
            name={'esg_strategy'}
            label={renderLabelField('ESG Strategy', LabelComponent())}
            wrapperCol={10}
            labelCol={24}
            disabled={!isConsiderESG}
            allowClear
            options={
              selectableESGStrategies && selectableESGStrategies.length > 0
                ? selectableESGStrategies.map((item) => ({ value: item.code, text: item.name }))
                : []
            }
          />
          <Col span={12} style={{ position: 'absolute', top: '50%', left: '45%' }}>
            ESG Strategy Definitions available here: &nbsp;
            <Link to="/ESG_Strategy_Definitions.pdf" target="_blank">
              HFR ESG Definitions PDF
            </Link>
          </Col>
        </Col>

        <Col span={24}>
          <FormItemSelect
            name={'esg_sub_strategy'}
            label={renderLabelField('ESG Sub-Strategy')}
            wrapperCol={10}
            labelCol={24}
            allowClear
            disabled={!isConsiderESG || !form.getFieldValue('esg_strategy')}
            options={
              selectableESGSubStrategies && selectableESGSubStrategies.length > 0
                ? selectableESGSubStrategies
                    .filter((item) => item.esg_strategy === form.getFieldValue('esg_strategy'))
                    .map((item) => ({ value: item.code, text: item.name }))
                : []
            }
          />
        </Col>
        <div style={{ fontWeight: 'bold', marginTop: '1rem' }}>
          DESCRIBE WHICH BEST DESCRIBES THE FUND&apos;S APPLICATION OF ESG
        </div>
        <div style={{ fontStyle: 'italic' }}>Select &quot;Yes&quot; or &quot;No&quot; foreach:</div>

        <List
          bordered
          dataSource={DESCRIBE_BEST_DESCRIBE}
          style={{ width: '100%' }}
          renderItem={(item) => (
            <ListItem>
              <div style={{ width: '100%' }}>
                <FormItemRadio
                  name={item.name}
                  label={renderLabelField(item.label)}
                  {...{ labelCol: { span: 18 }, wrapperCol: { span: 6 } }}
                  labelAlign="left"
                  style={{ flexDirection: 'row', alignItems: 'baseline', marginBottom: 0 }}
                  disabled={!isConsiderESG}
                  options={findOptionsRadio(item.name)}
                />
              </div>
            </ListItem>
          )}
        />
      </Row>
      <Row style={{ flexDirection: 'column', marginTop: '1rem' }}>
        <div style={{ fontStyle: 'italic' }}>Select &quot;Yes&quot; or &quot;No&quot; foreach:</div>
        <List
          header={<h4 style={{ margin: 0 }}>ENVIRONMENTAL</h4>}
          bordered
          dataSource={DESCRIBE_ESG_CRITERIA}
          style={{ width: '100%' }}
          renderItem={(item) => (
            <ListItem>
              <div style={{ width: '100%' }}>
                {item.isInput ? (
                  <FormItemTextArea
                    name={item.name}
                    label={renderLabelField(item.label)}
                    row={3}
                    disable={!isConsiderESG}
                    isVertical
                  />
                ) : (
                  <FormItemRadio
                    name={item.name}
                    label={renderLabelField(item.label)}
                    {...{ labelCol: { span: 18 }, wrapperCol: { span: 6 } }}
                    style={{ flexDirection: 'row', alignItems: 'baseline', marginBottom: 0 }}
                    disabled={!isConsiderESG}
                    options={findOptionsRadio(item.name)}
                    labelAlign="left"
                  />
                )}
              </div>
            </ListItem>
          )}
        />
      </Row>
      <Row style={{ flexDirection: 'column', marginTop: '1rem' }}>
        <div style={{ fontWeight: 'bold' }}>DESCRIBE ESG CRITERIA</div>
        <div style={{ fontStyle: 'italic' }}>Select &quot;Yes&quot; or &quot;No&quot; foreach:</div>
        <List
          header={<h4 style={{ margin: 0 }}>SOCIAL</h4>}
          bordered
          dataSource={DESCRIBE_ESG_SOCIAL}
          style={{ width: '100%' }}
          renderItem={(item) => (
            <ListItem>
              <div style={{ width: '100%' }}>
                {item.isInput ? (
                  <FormItemTextArea
                    name={item.name}
                    label={renderLabelField(item.label)}
                    row={3}
                    disable={!isConsiderESG}
                    isVertical
                  />
                ) : (
                  <FormItemRadio
                    name={item.name}
                    label={renderLabelField(item.label)}
                    {...{ labelCol: { span: 18 }, wrapperCol: { span: 6 } }}
                    style={{ flexDirection: 'row', alignItems: 'baseline', marginBottom: 0 }}
                    disabled={!isConsiderESG}
                    options={findOptionsRadio(item.name)}
                    labelAlign="left"
                  />
                )}
              </div>
            </ListItem>
          )}
        />
      </Row>

      <Row style={{ flexDirection: 'column', marginTop: '1rem' }}>
        <div style={{ fontStyle: 'italic' }}>Select &quot;Yes&quot; or &quot;No&quot; foreach:</div>
        <List
          header={<h4 style={{ margin: 0 }}>GOVERNANCE</h4>}
          bordered
          dataSource={DESCRIBE_GOVERNANCE}
          style={{ width: '100%' }}
          renderItem={(item) => (
            <ListItem>
              <div style={{ width: '100%' }}>
                {item.isInput ? (
                  <FormItemTextArea
                    name={item.name}
                    label={renderLabelField(item.label)}
                    row={3}
                    disable={!isConsiderESG}
                    isVertical
                  />
                ) : (
                  <FormItemRadio
                    name={item.name}
                    label={renderLabelField(item.label)}
                    {...{ labelCol: { span: 18 }, wrapperCol: { span: 6 } }}
                    style={{ flexDirection: 'row', alignItems: 'baseline', marginBottom: 0 }}
                    disabled={!isConsiderESG}
                    options={findOptionsRadio(item.name)}
                    labelAlign="left"
                  />
                )}
              </div>
            </ListItem>
          )}
        />
      </Row>
      <Row style={{ marginTop: '1rem' }}>
        <Col span={24}>
          <FormItemRadio
            disabled={!isConsiderESG}
            name="team_engagepcm"
            label={renderLabelField(
              "Does This Fund's Investment Team Engage With The Management Teams Of Companies In This Fund's Underlying Poritfolio, Re:ESG Or Diversity Issues?",
            )}
            options={optionEsg}
          />
        </Col>
      </Row>
      <Row style={{ flexDirection: 'column', marginTop: '1rem' }}>
        <div style={{ fontWeight: 'bold' }}>WHAT ESG DATA SOURCES ARE USED IN YOUR PROCESS</div>
        <div style={{ fontStyle: 'italic' }}>Select &quot;Yes&quot; or &quot;No&quot; foreach:</div>
        <List
          bordered
          dataSource={ESG_DATA_SOURCES}
          style={{ width: '100%' }}
          renderItem={(item) => (
            <ListItem>
              <div style={{ width: '100%' }}>
                {item.isInput ? (
                  <FormItemTextArea
                    name={item.name}
                    label={renderLabelField(item.label)}
                    row={3}
                    disable={!isConsiderESG}
                    isVertical
                  />
                ) : (
                  <FormItemRadio
                    name={item.name}
                    label={renderLabelField(item.label)}
                    {...{ labelCol: { span: 18 }, wrapperCol: { span: 6 } }}
                    style={{ flexDirection: 'row', alignItems: 'baseline', marginBottom: 0 }}
                    disabled={!isConsiderESG}
                    options={findOptionsRadio(item.name)}
                    labelAlign="left"
                  />
                )}
              </div>
            </ListItem>
          )}
        />
      </Row>
    </Wrapper>
  )
}
//
type FieldType = {
  name: string
  label: string
  isInput?: boolean
}
const DESCRIBE_BEST_DESCRIBE: FieldType[] = [
  {
    name: 'app_positivescreen',
    label: 'Positive/best-in-class Screening',
  },
  {
    name: 'app_negativescreen',
    label: 'Negative/Exclusionary Screening',
  },
  {
    name: 'app_esgintegration',
    label: 'ESG Integration',
  },
  {
    name: 'app_impactinvest',
    label: 'Impact Investing',
  },
  {
    name: 'app_sustaininvest',
    label: 'Sustainability-themed Investing',
  },
]
const DESCRIBE_ESG_CRITERIA: FieldType[] = [
  {
    name: 'env_greenbuilding',
    label: 'Green Building/ Smart Growth',
  },
  {
    name: 'env_climatechange',
    label: 'Climate Change/ Carbon',
  },
  {
    name: 'env_cleantech',
    label: 'Clean Technology',
  },
  {
    name: 'env_pollutiontaxes',
    label: 'Pollution / Toxics',
  },
  {
    name: 'env_sustainable',
    label: 'Sustainable Natural Resources / Agriculture',
  },
  {
    name: 'env_waterconservation',
    label: 'Water Use & Conservation',
  },
  {
    name: 'env_description',
    label: 'Additional Description Of How ENVIRONMENTAL Is Considered When Investing',
    isInput: true,
  },
]
const DESCRIBE_ESG_SOCIAL: FieldType[] = [
  {
    name: 'soc_workplacesafety',
    label: 'Workplace Safety',
  },
  {
    name: 'soc_laborrelations',
    label: 'Labor Relations',
  },
  {
    name: 'soc_workplacebenefits',
    label: 'Workplace Benefits',
  },
  {
    name: 'soc_diversityantibias',
    label: 'Diversity & Anti-Bias Issues',
  },
  {
    name: 'soc_communitydev',
    label: 'Community Development',
  },
  {
    name: 'soc_avoidtobacco',
    label: 'Avoidance Of Tobacco Or Other Harmful Products',
  },
  {
    name: 'soc_humanrights',
    label: 'Human Rights',
  },
  {
    name: 'soc_description',
    label: 'Additional Description Of How SOCIAL Is Considered When Investing',
    isInput: true,
  },
]
const DESCRIBE_GOVERNANCE: FieldType[] = [
  {
    name: 'gov_corppolcontrib',
    label: 'Corporate Political Contributions',
  },
  {
    name: 'gov_executivecomp',
    label: 'Executive Compensation',
  },
  {
    name: 'gov_boarddiversity',
    label: 'Board Diversity',
  },
  {
    name: 'gov_anticorruption',
    label: 'Anti-Corruption Pilicies',
  },
  {
    name: 'gov_boardindependence',
    label: 'Board Independence',
  },
  {
    name: 'gov_description',
    label: 'Additional Description Of How GOVERNANCE Is Considered When Investing',
    isInput: true,
  },
]
const ESG_DATA_SOURCES: FieldType[] = [
  {
    name: 'data_msci',
    label: 'MSCI',
  },
  {
    name: 'data_sustainalytics',
    label: 'Sustainalytics',
  },
  {
    name: 'data_bloomberg',
    label: 'Bloomberg',
  },
  {
    name: 'data_factset',
    label: 'FactSet',
  },
  {
    name: 'data_internal',
    label: 'Proprietary Or Internally Developed Metrics',
  },
  {
    name: 'data_other',
    label: 'Other',
    isInput: true,
  },
]
