import { DeleteTwoTone, EditOutlined } from '@ant-design/icons'
import { Button, notification, Popconfirm, Tooltip } from 'antd'
import Table, { ColumnProps } from 'antd/lib/table'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../../../shared/api'
import { FundDetailDataContext } from '../../../../shared/api/context/FundDetailDataContext'
import { DueDiligenceMeetingResponse } from '../../../../shared/api/models/FirmDetails'
import { Colors } from '../../../../shared/colors'
import { NextBackButton } from '../../../../shared/components/NextBackButton'
import { useServiceState } from '../../../../shared/hooks/useServiceState'
import { MEETING_OPTIONS_TYPE, ModalEditTableMeeting } from './ModalEditTableMeeting'
const Flex = styled.div`
  display: flex;
  flex: 1;
`
type Props = {}
export const Meetings: React.FC<Props> = () => {
  const { dataFundDetail } = React.useContext(FundDetailDataContext)
  const [selectedMeeting, setSelectedMeeting] = React.useState<DueDiligenceMeetingResponse | undefined>()
  const [showModal, setShowModal] = React.useState(false)
  const [loadingDelete, setLoadingDelete] = React.useState(false)

  const { invoke: getDataMeeting, data: dataMeeting, loading } = useServiceState(
    APIService.firmDetailService.fetchDueDiligenceMeeting,
  )

  React.useEffect(() => {
    dataFundDetail?.firm_id && getDataMeeting(dataFundDetail.firm_id)
  }, [dataFundDetail, getDataMeeting])

  const updateMeeting = () => {
    getDataMeeting(dataFundDetail?.firm_id)
  }

  const deleteMeeting = (deleteMeeting: DueDiligenceMeetingResponse) => {
    if (dataFundDetail?.firm_id) {
      const paramsSubmit = {
        identifier: [
          dataFundDetail.firm_id,
          deleteMeeting.meeting_type,
          moment(deleteMeeting.meeting_date).format('YYYY-MM-DD'),
        ],
      }
      setLoadingDelete(true)
      APIService.firmDetailService
        .deleteDueDiligenceMeeting(dataFundDetail?.firm_id, paramsSubmit)
        .then(() => {
          notification.success({ message: 'Meeting Delete!' })
          updateMeeting()
        })
        .catch((err) => {
          console.error({ err })
          notification.error({ message: 'Meeting Delete Failed!' })
        })
        .finally(() => {
          setLoadingDelete(false)
        })
    }
  }

  const columns: ColumnProps<DueDiligenceMeetingResponse>[] = [
    {
      title: 'Meeting Type Description',
      dataIndex: 'meeting_type',
      key: 'meeting_type',
      render: (_, record) => <div>{MEETING_OPTIONS_TYPE.find((item) => item.value === record.meeting_type)?.text}</div>,
    },
    {
      title: 'Date',
      dataIndex: 'meeting_date',
      key: 'meeting_date',
    },
    {
      title: 'Operator ID',
      dataIndex: 'oper_id',
      key: 'oper_id',
    },
    {
      title: 'Attendee',
      dataIndex: 'attendee',
      key: 'attendee',
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      key: 'comments',
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (_, record) => (
        <>
          <Button
            type="link"
            style={{ padding: '4px 8px' }}
            onClick={() => {
              setShowModal(true)
              setSelectedMeeting(record)
            }}
          >
            <Tooltip title="Edit">
              <EditOutlined />
            </Tooltip>
          </Button>

          <Popconfirm title="Are you sure you want to delete?" onConfirm={() => deleteMeeting(record)}>
            <Button type="link" style={{ padding: '4px 8px' }} loading={loadingDelete}>
              <Tooltip title="Delete">
                <DeleteTwoTone twoToneColor={Colors.danger} />
              </Tooltip>
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ]
  return (
    <Flex style={{ flexDirection: 'column' }}>
      <div style={{ marginTop: '0.5rem', display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="primary" style={{ marginBottom: 8, marginRight: 8 }} onClick={() => setShowModal(true)}>
          Create New Meeting
        </Button>
      </div>
      <Table
        rowKey="meeting_type"
        dataSource={dataMeeting}
        columns={columns}
        loading={loading}
        pagination={dataMeeting && dataMeeting.length > 10 ? undefined : false}
        bordered
      />
      {dataFundDetail?.firm_id && (
        <ModalEditTableMeeting
          showModal={showModal}
          selectedMeeting={selectedMeeting}
          setShowModal={setShowModal}
          setSelectedMeeting={setSelectedMeeting}
          updateMeeting={updateMeeting}
          firmId={dataFundDetail.firm_id}
        />
      )}
      <NextBackButton />
    </Flex>
  )
}
