import { DeleteTwoTone, EditOutlined } from '@ant-design/icons'
import { Button, notification, Popconfirm, Table, Tooltip } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../../../../shared/api'
import { FundDetailDataContext } from '../../../../../shared/api/context/FundDetailDataContext'
import { PrincipalsResponse } from '../../../../../shared/api/models/FundDetails'
import { Colors } from '../../../../../shared/colors'
import { useServiceState } from '../../../../../shared/hooks/useServiceState'
import { ModalEditPrincipals } from './ModalEditPrincipals'
const Flex = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const Principals: React.FC = () => {
  const { dataFundDetail } = React.useContext(FundDetailDataContext)
  const [selectedPrincipals, setSelectedPrincipals] = React.useState<PrincipalsResponse | undefined>()
  const [showModal, setShowModal] = React.useState(false)
  const [loadingDelete, setLoadingDelete] = React.useState(false)

  const { invoke: getPrincipals, data: dataPrincipals, loading } = useServiceState(
    APIService.fundDetailService.fetchFundPrincipals,
  )

  React.useEffect(() => {
    dataFundDetail?.fund_id && getPrincipals(dataFundDetail.fund_id)
  }, [dataFundDetail, getPrincipals])

  const updatePrincipals = () => {
    getPrincipals(dataFundDetail?.fund_id)
  }

  const deletePrincipals = (itemPrincipals: PrincipalsResponse) => {
    if (dataFundDetail?.fund_id) {
      const paramsSubmit = {
        identifier: [dataFundDetail.fund_id, itemPrincipals.rank, itemPrincipals.name],
      }
      setLoadingDelete(true)
      APIService.fundDetailService
        .deleteFundPrincipals(dataFundDetail?.fund_id, paramsSubmit)
        .then(() => {
          notification.success({ message: 'Principals Delete!' })
          updatePrincipals()
        })
        .catch((err) => {
          console.error({ err })
          notification.error({ message: 'Principals Delete Failed!' })
        })
        .finally(() => {
          setLoadingDelete(false)
        })
    }
  }

  const columns: ColumnProps<PrincipalsResponse>[] = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
      sorter: (a, b) => {
        return a.rank - b.rank
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => {
        return a.name.localeCompare(b.name)
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => {
        return a.title.localeCompare(b.title)
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (_, record) => (
        <>
          <Button
            type="link"
            style={{ padding: '4px 8px' }}
            onClick={() => {
              setShowModal(true)
              setSelectedPrincipals(record)
            }}
          >
            <Tooltip title="Edit">
              <EditOutlined />
            </Tooltip>
          </Button>

          <Popconfirm title="Are you sure you want to delete?" onConfirm={() => deletePrincipals(record)}>
            <Button type="link" style={{ padding: '4px 8px' }} loading={loadingDelete}>
              <Tooltip title="Delete">
                <DeleteTwoTone twoToneColor={Colors.danger} />
              </Tooltip>
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ]
  return (
    <Flex>
      <div style={{ marginTop: '0.5rem', display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="primary" style={{ marginBottom: 8, marginRight: 8 }} onClick={() => setShowModal(true)}>
          Create New Principals
        </Button>
      </div>
      <Table
        loading={loading}
        dataSource={dataPrincipals}
        columns={columns}
        style={{ flex: 1 }}
        pagination={dataPrincipals && dataPrincipals.length > 10 ? undefined : false}
      />
      {dataFundDetail?.fund_id && (
        <ModalEditPrincipals
          showModal={showModal}
          selectedPrincipals={selectedPrincipals}
          setShowModal={setShowModal}
          setSelectedPrincipals={setSelectedPrincipals}
          updatePrincipals={updatePrincipals}
          fundId={dataFundDetail.fund_id}
        />
      )}
    </Flex>
  )
}
export default Principals
