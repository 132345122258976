import { DeleteTwoTone, DownloadOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Input, notification, Popconfirm, Space, Tabs, Tooltip } from 'antd'
import Table, { ColumnProps } from 'antd/lib/table'
import JsFileDownload from 'js-file-download'
import Papa from 'papaparse'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../shared/api'
import { FirmDetailDataProvider } from '../../shared/api/context/FirmDetailDataContext'
import { FundDetailDataProvider } from '../../shared/api/context/FundDetailDataContext'
import { BenchmarkType } from '../../shared/api/models/Benchmark'
import { Colors } from '../../shared/colors'
import { useServiceState } from '../../shared/hooks/useServiceState'
import { FundFirmTypeEnum } from '../../slice/appSettingsSlice'
import { RenderTitleTabPane } from '../Dashboard/Dashboard'
import { FirmDetailMain } from '../Dashboard/FirmDetail/FirmDetailMain'
import { FundDetailMain } from '../Dashboard/FundDetail/FundDetailMain'
import BenchmarkFormModal from './BenchmarkFormModal'
import { ListOfFundBenchmark } from './ListOfFundBenchmark'

const StyledTabs = styled(Tabs)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;

  .ant-tabs-nav {
    margin-bottom: 0%;
  }

  .ant-tabs-content-holder {
    overflow: hidden;
  }

  .ant-tabs-content {
    flex-grow: 1;
    background: white;
    border-left: solid 1px ${Colors.border};
    border-right: solid 1px ${Colors.border};
    border-bottom: solid 1px ${Colors.border};
    height: 100%;

    .ant-tabs-tabpane-active {
      height: 100%;
      display: flex;
    }

    .ant-card-head {
      min-height: auto;
    }
  }
`
type ListTabType = {
  id: string
  name: string
  type: FundFirmTypeEnum | 'list-funds'
}
type Props = {}

const Benchmarks: React.FC<Props> = () => {
  const [showFormModal, setShowFormModal] = React.useState(false)
  const [selectedBenchmark, setSelectedBenchmark] = React.useState<BenchmarkType>()
  // const [searchText, setSearchText] = React.useState<string | number>()
  // const [searchedColumn, setSearchedColumn] = React.useState<string>()

  const [loadingDeleteBenchmark, setLoadingDeleteBenchmark] = React.useState(false)

  const searchInput = React.useRef<Input>(null)
  const [activeTab, setActiveTab] = React.useState('benchmark-list')
  const [listTab, setListTab] = React.useState<ListTabType[]>([])
  const [currentSource, setCurrentSource] = React.useState<BenchmarkType[]>([])

  const { invoke: getAllBenchmark, data: dataBenchmark, loading: loadingBenchmark } = useServiceState(
    APIService.benchmarkService.getAllBenchmark,
  )

  React.useEffect(() => {
    getAllBenchmark()
  }, [getAllBenchmark])

  React.useEffect(() => {
    if (dataBenchmark) {
      setCurrentSource(dataBenchmark)
    }
  }, [dataBenchmark])

  const handleExportBenchmark = () => {
    JsFileDownload(Papa.unparse(currentSource), `benchmark-results.csv`, 'application/csv')
  }

  const updateBenchmark = () => {
    getAllBenchmark()
  }

  const handleSearch = (selectedKeys: React.ReactText[], confirm: (() => void) | undefined, _dataIndex: string) => {
    confirm && confirm()
    // setSearchText(selectedKeys[0])
    // setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters: (() => void) | undefined) => {
    clearFilters && clearFilters()
    // setSearchText(undefined)
  }

  const deleteBenchmark = (fundId: string) => {
    setLoadingDeleteBenchmark(true)
    APIService.benchmarkService
      .deleteBenchmark(fundId)
      .then(() => {
        notification.success({ message: 'Benchmark Delete!' })
        updateBenchmark()
      })
      .catch((err) => {
        console.error({ err })
        notification.error({ message: 'Benchmark Delete Failed!' })
      })
      .finally(() => {
        setLoadingDeleteBenchmark(false)
      })
  }

  const getColumnSearchProps = (dataIndex: string): ColumnProps<BenchmarkType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex as keyof BenchmarkType]
        ? record[dataIndex as keyof BenchmarkType].toString().toLowerCase().includes(value.toString().toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select())
      }
    },
  })

  const columns: ColumnProps<BenchmarkType>[] = [
    {
      title: 'Fund ID',
      dataIndex: 'fund_id',
      key: 'fund_id',
      width: 135,
      fixed: 'left',
      sorter: (a, b) => {
        if (a.fund_id && b.fund_id) {
          return a.fund_id.localeCompare(b.fund_id)
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('fund_id'),
    },
    {
      title: 'Fund Name',
      dataIndex: 'fund_name',
      key: 'fund_name',
      width: 350,
      fixed: 'left',
      ellipsis: true,
      render: (_, record) => (
        <Button
          type="link"
          style={{ padding: '4px 8px' }}
          onClick={() => {
            if (!listTab.find((e) => e.id.split('-')[1] === record.fund_id)) {
              setListTab([
                ...listTab,
                { id: 'funds-' + record.fund_id, name: record.fund_name, type: FundFirmTypeEnum.FUNDS },
              ])
            }
            setActiveTab('funds-' + record.fund_id)
          }}
        >
          {record.fund_name}
        </Button>
      ),
      sorter: (a, b) => {
        if (a.fund_name && b.fund_name) {
          return a.fund_name.localeCompare(b.fund_name)
        }
        return 0
      },

      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('fund_name'),
    },
    {
      title: 'Firm ID',
      dataIndex: 'firm_id',
      key: 'firm_id',
      width: 135,
      sorter: (a, b) => {
        if (a.firm_id && b.firm_id) {
          return a.firm_id.localeCompare(b.firm_id)
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('firm_id'),
    },
    {
      title: 'Firm Name',
      dataIndex: 'firm_name',
      key: 'firm_name',
      width: 350,
      render: (_, record) => (
        <Button
          type="link"
          style={{ padding: '4px 8px' }}
          onClick={() => {
            if (!listTab.find((e) => e.id.split('-')[1] === record.firm_id)) {
              setListTab([
                ...listTab,
                { id: 'firms-' + record.firm_id, name: record.firm_name, type: FundFirmTypeEnum.FIRMS_IN_FUNDS },
              ])
            }
            setActiveTab('firms-' + record.firm_id)
          }}
        >
          {record.firm_name}
        </Button>
      ),
      sorter: (a, b) => {
        if (a.firm_name && b.firm_name) {
          return a.firm_name.localeCompare(b.firm_name)
        }
        return 0
      },

      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('firm_name'),
    },
    {
      title: 'Strategy',
      dataIndex: 'strategy_name',
      key: 'strategy_name',
      width: 200,
      sorter: (a, b) => {
        if (a.strategy_name && b.strategy_name) {
          return a.strategy_name.localeCompare(b.strategy_name)
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Sub-Strategy',
      dataIndex: 'sub_strategy_name',
      key: 'sub_strategy_name',
      width: 200,
      sorter: (a, b) => {
        if (a.sub_strategy_name && b.sub_strategy_name) {
          return a.sub_strategy_name.localeCompare(b.sub_strategy_name)
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Strategy(new)',
      dataIndex: 'strategy_new_name',
      key: 'strategy_new_name',
      width: 200,
      sorter: (a, b) => {
        if (a.strategy_new_name && b.strategy_new_name) {
          return a.strategy_new_name.localeCompare(b.strategy_new_name)
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Sub-strategy(new)',
      dataIndex: 'sub_strategy_new_name',
      key: 'sub_strategy_new_name',
      width: 200,
      sorter: (a, b) => {
        if (a.sub_strategy_new_name && b.sub_strategy_new_name) {
          return a.sub_strategy_new_name.localeCompare(b.sub_strategy_new_name)
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Benchmark Disclaimer',
      dataIndex: 'benchmark_disclaimer',
      key: 'benchmark_disclaimer',
      width: 500,
      ellipsis: true,
      sorter: (a, b) => {
        if (a.benchmark_disclaimer && b.benchmark_disclaimer) {
          return a.benchmark_disclaimer.localeCompare(b.benchmark_disclaimer)
        }
        return 0
      },

      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Used As a Benchmark #',
      dataIndex: 'count',
      key: 'count',
      width: 200,
      render: (_, record) => (
        <Button
          type="link"
          style={{ padding: '4px 8px' }}
          onClick={() => {
            if (!listTab.find((e) => e.id.split('-')[1] === record.fund_id && e.id.split('-')[0] === 'listfund')) {
              setListTab([...listTab, { id: 'listfund-' + record.fund_id, name: record.fund_name, type: 'list-funds' }])
            }
            setActiveTab('listfund-' + record.fund_id)
          }}
        >
          {record.count}
        </Button>
      ),
      sorter: (a, b) => {
        return (a.count || 0) - (b.count || 0)
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action',
      dataIndex: 'action',
      fixed: 'right',
      width: 100,
      render: (_, record) => (
        <>
          <Button
            type="link"
            style={{ padding: '4px 8px' }}
            onClick={() => {
              setSelectedBenchmark(record)
              setShowFormModal(true)
            }}
          >
            <Tooltip title="Edit">
              <EditOutlined />
            </Tooltip>
          </Button>

          <Popconfirm title="Are you sure you want to delete?" onConfirm={() => deleteBenchmark(record.fund_id)}>
            <Button type="link" style={{ padding: '4px 8px' }} loading={loadingDeleteBenchmark}>
              <Tooltip title="Delete">
                <DeleteTwoTone twoToneColor={Colors.danger} />
              </Tooltip>
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ]
  return (
    <StyledTabs
      hideAdd
      type="editable-card"
      activeKey={activeTab}
      onChange={(key) => {
        setActiveTab(key)
      }}
      onEdit={(targetKey, action) => {
        if (action === 'remove') {
          setListTab(listTab.filter((item) => item.id !== targetKey))
          if (targetKey === activeTab) {
            setActiveTab('benchmark-list')
          }
        }
      }}
    >
      <Tabs.TabPane
        tab="Benchmark List"
        closable={false}
        key="benchmark-list"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="primary" style={{ margin: 8 }} onClick={() => setShowFormModal(true)}>
            Add New
          </Button>
        </div>

        <Table
          rowKey="fund_id"
          dataSource={dataBenchmark}
          size="small"
          columns={columns}
          scroll={{ y: 'calc(100vh - 380px)', x: 'min-content' }}
          loading={loadingBenchmark}
          pagination={{ defaultPageSize: 20 }}
          onChange={(_pagination, _filter: any, _sorter: any, extra) => {
            setCurrentSource(extra.currentDataSource)
          }}
        />
        <BenchmarkFormModal
          selectedBenchmark={selectedBenchmark}
          showFormModal={showFormModal}
          setShowFormModal={setShowFormModal}
          setSelectedBenchmark={setSelectedBenchmark}
          updateBenchmark={updateBenchmark}
        />
        <Button
          type="primary"
          style={{ position: 'absolute', bottom: 110, left: 36 }}
          onClick={handleExportBenchmark}
          disabled={currentSource.length === 0}
        >
          <DownloadOutlined />
          Export
        </Button>
      </Tabs.TabPane>
      {listTab.map((item) => {
        if (item.type === FundFirmTypeEnum.FUNDS) {
          return (
            <Tabs.TabPane tab={item.name} closable={true} key={item.id}>
              <FundDetailDataProvider id={item.id.split('-')[1]} type={'internal'}>
                <FundDetailMain />
              </FundDetailDataProvider>
            </Tabs.TabPane>
          )
        }
        if (item.type === FundFirmTypeEnum.FIRMS_IN_FUNDS)
          return (
            <Tabs.TabPane tab={<RenderTitleTabPane name={item.name} type={item.type} />} closable={true} key={item.id}>
              <FirmDetailDataProvider id={item.id.split('-')[1]}>
                <FirmDetailMain />
              </FirmDetailDataProvider>
            </Tabs.TabPane>
          )
        return (
          <Tabs.TabPane tab="List Of Funds" closable={true} key={item.id}>
            <ListOfFundBenchmark
              fundId={item.id.split('-')[1]}
              listTab={listTab}
              setActiveTab={setActiveTab}
              setListTab={setListTab}
            />
          </Tabs.TabPane>
        )
      })}
    </StyledTabs>
  )
}

export default Benchmarks
