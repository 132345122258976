import { CheckCircleTwoTone, InboxOutlined } from '@ant-design/icons'
import { Button, Col, notification, Row } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import APIService from '../../../shared/api'
import { PerformanceUpdateType } from '../../../shared/api/context/FundDetailDataContext'
import { ExFundDetails } from '../../../shared/api/models/ExFundDetails'
import { Colors } from '../../../shared/colors'
import { useServiceState } from '../../../shared/hooks/useServiceState'
import { getMonthRange } from '../../../shared/utils/datetimeUtils'
import PerformanceDataSheet, { DATE_FORMAT } from '../../Dashboard/FundDetail/FundROR/PerformanceDataSheet'
import DataConfirmationModal from '../DataConfirmationModal'
import { validateLatestPerformance } from '../utils/validateData'
import { Link } from 'react-router-dom'

const Wrapper = styled('div')``

type Props = {
  fund: ExFundDetails
  shouldUpdate?: boolean
  setShouldUpdate?: (value: boolean) => void
  setUpdatedFundPerformance?: (updatedFund?: string) => void
}

const ExFundPerformanceItem: React.FC<Props> = ({ fund, shouldUpdate, setShouldUpdate, setUpdatedFundPerformance }) => {
  const [performanceDataChange, setPerformanceDataChange] = React.useState<PerformanceUpdateType>()
  const [submitting, setSubmitting] = useState(false)

  const [showDiscardDataSheet, setShowDiscardDataSheet] = React.useState(false)
  const [flaggedRors, setFlaggedRors] = useState<{ date: string; value: number }[]>()
  const [flaggedAssets, setFlaggedAssets] = useState<{ date: string; value: number }[]>()

  const handleOk = () => {
    setShowDiscardDataSheet(false)
    handleSubmitPerformance()
  }

  const handleCancel = () => {
    setShowDiscardDataSheet(false)
    setFlaggedAssets(undefined)
    setFlaggedRors(undefined)
  }

  const handleSubmitPerformance = async () => {
    if (!performanceDataChange || Object.keys(performanceDataChange).length <= 0 || !fund) {
      return
    }
    try {
      setSubmitting(true)
      await APIService.fundService.updateLatestPerformance(fund.fund_id, {
        rors: performanceDataChange.rorChanges,
        navs: performanceDataChange.navChanges,
        assets: performanceDataChange.assetChanges,
      })
      notification.success({ message: 'Latest Performance Updated' })
      fetchLatestPerformance(fund.fund_id)
      setUpdatedFundPerformance && setUpdatedFundPerformance(fund.fund_id)
    } catch (error) {
      notification.error({ message: error.response.statusText })
    } finally {
      setSubmitting(false)
    }
  }

  const validatePerformanceData = async () => {
    if (
      !fund.fund_id ||
      fund.fund_status !== 'Active' ||
      !performanceDataChange ||
      (!performanceDataChange.assetChanges && !performanceDataChange.rorChanges && !performanceDataChange.navChanges)
    ) {
      return true
    }
    const flaggedData = await APIService.fundService.validateLatestPerformance(fund.fund_id, {
      rors:
        performanceDataChange.rorChanges && performanceDataChange.rorChanges.length > 0
          ? performanceDataChange.rorChanges.slice(1, performanceDataChange.rorChanges.length).map((item) => ({
              yyyy_mm: item.yyyy_mm,
              value: item.value,
            }))
          : undefined,
      navs:
        performanceDataChange.navChanges && performanceDataChange.navChanges.length > 0
          ? performanceDataChange.navChanges.slice(1, performanceDataChange.navChanges.length).map((item) => ({
              yyyy_mm: item.yyyy_mm,
              value: item.value,
            }))
          : undefined,
      assets:
        performanceDataChange.assetChanges && performanceDataChange.assetChanges.length > 0
          ? performanceDataChange.assetChanges.slice(1, performanceDataChange.assetChanges.length).map((item) => ({
              yyyy_mm: item.yyyy_mm,
              value: item.value,
            }))
          : undefined,
    })
    setFlaggedRors(flaggedData.data.flagged_rors.map((item) => ({ date: item[0], value: item[1] })))
    setFlaggedAssets(flaggedData.data.flagged_assets.map((item) => ({ date: item[0], value: item[1] })))

    return flaggedData.data.flagged_rors.length <= 0 && flaggedData.data.flagged_assets.length <= 0
  }

  const {
    data: latestPerformance,
    invoke: fetchLatestPerformance,
    loading: latestPerformanceLoading,
  } = useServiceState(APIService.fundService.fetchLatestPerformance)

  useEffect(() => {
    fund.fund_id && fetchLatestPerformance(fund.fund_id)
  }, [fetchLatestPerformance, fund.fund_id])

  useEffect(() => {
    if (shouldUpdate) {
      fund.fund_id && fetchLatestPerformance(fund.fund_id)
      setShouldUpdate && setShouldUpdate(false)
    }
  }, [shouldUpdate, setShouldUpdate, fund.fund_id, fetchLatestPerformance])

  const updateLatestPerformance = async () => {
    if (!performanceDataChange || Object.keys(performanceDataChange).length <= 0 || !fund) {
      return
    }
    // check for missing data
    if (!validateLatestPerformance(performanceDataChange, latestPerformance)) {
      return
    }
    // validating the data (ROR/AUM)
    if (!(await validatePerformanceData())) {
      setShowDiscardDataSheet(true)
      return
    }
    handleSubmitPerformance()
  }

  const isPerformanceUpToDate = React.useMemo(() => {
    if (!latestPerformance) {
      return false
    }

    const hasNoPerformance =
      !latestPerformance.latest_asset && !latestPerformance.latest_nav && !latestPerformance.latest_ror

    const latestROR = latestPerformance.latest_ror
    const latestNav = latestPerformance.latest_nav
    const latestAsset = latestPerformance.latest_asset

    // get the min date of latest data
    const startDate = moment.min([
      latestROR ? moment(latestROR, DATE_FORMAT) : moment(),
      latestNav ? moment(latestNav, DATE_FORMAT) : moment(),
      latestAsset ? moment(latestAsset, DATE_FORMAT) : moment(),
    ])

    // Prepare the available row as remaining months till now
    const dateList = getMonthRange(
      hasNoPerformance
        ? moment(fund.inception_date).format(DATE_FORMAT)
        : startDate.add(1, 'month').format(DATE_FORMAT),
      moment().subtract(1, 'month').format(DATE_FORMAT),
    )

    return dateList.length > 0 ? false : true
  }, [fund.inception_date, latestPerformance])

  return (
    <Wrapper>
      <Row justify="space-between" style={{ padding: '0 1rem' }}>
        <Col>
          <h4>
            {fund.fund_name} (ISIN: {fund.isin || 'N/A'}, Bloomberg: {fund.bloomberg_ticker || 'N/A'})
            {isPerformanceUpToDate && (
              <span style={{ textAlign: 'center' }}>
                {' '}
                - Performance is up-to-date <CheckCircleTwoTone color={Colors.success} />
              </span>
            )}
          </h4>
          {fund && (
            <span>
              <InboxOutlined /> <Link to={`/funds/${fund.fund_id}?preview=1&preview_tab=Profile`}>Fund Profile</Link> |{' '}
              <Link to={`/funds/${fund.fund_id}?preview=1&preview_tab=Peer Group`}>Peer Group Analysis </Link>
            </span>
          )}
        </Col>
        {!isPerformanceUpToDate && (
          <Button type="primary" loading={submitting} onClick={updateLatestPerformance}>
            Update
          </Button>
        )}
      </Row>
      {!isPerformanceUpToDate && (
        <PerformanceDataSheet
          dataLatestPerformance={latestPerformance}
          loading={latestPerformanceLoading || submitting}
          data={performanceDataChange}
          onDataChange={setPerformanceDataChange}
          inceptionDate={fund.inception_date}
          currency={latestPerformance?.curr_code || ''}
        />
      )}
      {showDiscardDataSheet && (
        <DataConfirmationModal
          onOk={handleOk}
          onCancel={handleCancel}
          isOpen={showDiscardDataSheet}
          flaggedAssets={flaggedAssets}
          flaggedRors={flaggedRors}
        />
      )}
    </Wrapper>
  )
}

export default ExFundPerformanceItem
