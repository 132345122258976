import { AxiosInstance, AxiosPromise } from 'axios'
import { BenchmarkType, FundBenchmarkResponse } from '../models/Benchmark'

export interface BenchmarkService {
  getAllBenchmark: () => AxiosPromise<BenchmarkType[]>
  createBenchmark: (param: Partial<BenchmarkType>) => AxiosPromise<void>
  updateBenchmark: (fundId: string, param: Partial<BenchmarkType>) => AxiosPromise<void>
  deleteBenchmark: (fundId: string) => AxiosPromise<void>
  getBenchmark: (fundId: string) => AxiosPromise<BenchmarkType>
  getFundBenchmark: (fundId: string) => AxiosPromise<FundBenchmarkResponse[]>
}
export const BenchmarkService = (request: AxiosInstance): BenchmarkService => {
  return {
    getAllBenchmark: () => request.get(`/manager-link/benchmark-manager`),
    createBenchmark: (param) => request.post(`/manager-link/benchmark-manager`, param),
    updateBenchmark: (fundId, param) => request.put(`/manager-link/benchmark-manager/${fundId.trim()}`, param),
    deleteBenchmark: (fundId) => request.delete(`/manager-link/benchmark-manager/${fundId.trim()}`),
    getBenchmark: (fundId) => request.get(`/manager-link/benchmark-manager/${fundId.trim()}`),
    getFundBenchmark: (fundId) => request.get(`/manager-link/benchmarks/${fundId.trim()}/funds`),
  }
}
