import { LeftOutlined, PlusSquareOutlined, RightOutlined, TableOutlined } from '@ant-design/icons'
import { Button, Col, Dropdown, Menu, Row } from 'antd'
import { ClickParam } from 'antd/lib/menu'
import React from 'react'
import styled from 'styled-components'
import { FundFirmTypeEnum } from '../../../slice/appSettingsSlice'
import FundSearchColumnBuilder from './ColumnBuilder/FundSearchColumnBuilder'
import { FundQuerySearch } from './FundQuerySearch'
import { ModalCreateFundFirm } from './ModalCreateFundFirm'
import PeerGroupSearch from './PeerGroupSearch'

const RightTitle = styled.div`
  display: flex;
  margin-left: 8px;
  flex: 1;
`

type TableHeaderProps = {
  onShowSideBar: () => void
  showSidebar: boolean
}

const FundTableHeader: React.FC<TableHeaderProps> = ({ onShowSideBar, showSidebar }) => {
  const [showColumnBuilder, setShowColumnBuilder] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)
  const [typeCreate, setTypeCreate] = React.useState<FundFirmTypeEnum>(FundFirmTypeEnum.FUNDS)

  const handleMenuClick = (e: ClickParam) => {
    if (e.key === '1') {
      setShowModal(true)
      setTypeCreate(FundFirmTypeEnum.FUNDS)

      return
    }
    if (e.key === '2') {
      setShowModal(true)
      setTypeCreate(FundFirmTypeEnum.FIRMS_IN_FUNDS)
      return
    }
  }

  const renderAddNewList = () => (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">Add New Fund</Menu.Item>
      <Menu.Item key="2">Add New Firm</Menu.Item>
    </Menu>
  )

  return (
    <Row>
      <Col lg={3}>
        <Row align="middle">
          <Button onClick={onShowSideBar} style={{ marginRight: 16 }}>
            {showSidebar ? <LeftOutlined /> : <RightOutlined />}
          </Button>
          All Funds
        </Row>
      </Col>
      <Col lg={21} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <RightTitle>
          <PeerGroupSearch />
          <FundQuerySearch />
          <Dropdown trigger={['click']} overlay={renderAddNewList}>
            <Button type="primary" style={{ marginLeft: '2rem' }}>
              <PlusSquareOutlined />
              Add New Fund/Firm
            </Button>
          </Dropdown>

          <Button type="default" onClick={() => setShowColumnBuilder(true)} style={{ marginLeft: 6 }}>
            <TableOutlined />
            Columns
          </Button>
        </RightTitle>
      </Col>
      <ModalCreateFundFirm showModal={showModal} setShowModal={setShowModal} typeCreate={typeCreate} />
      {showColumnBuilder && <FundSearchColumnBuilder isOpen={showColumnBuilder} setOpen={setShowColumnBuilder} />}
    </Row>
  )
}

export default FundTableHeader
