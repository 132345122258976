import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input, Modal, notification, Row } from 'antd'
import { FormProps } from 'antd/lib/form'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Logo from '../../assets/hfr-logo-white.png'
import APIService from '../../shared/api'
import { Colors } from '../../shared/colors'
import { useFetchState } from '../../shared/hooks/useFetchState'
import { loginFailure, saveToken } from '../../slice/authSlice'
import AuthLayout from './AuthLayout'
import CheckCodeLogin from './CheckCodeLogin'
import DbToMlForm from './signup/DbToMlForm'
type Props = FormProps
enum LoginSteps {
  LOGIN,
  CHECK_CODE,
}
// Styled
const LoginContainer = styled.div`
  background: white;
  width: 30%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  form {
    max-width: 300px;
    width: 100%;
  }
`

const LogoImg = styled.img`
  width: 150px;
`

const ErrorMessage = styled.div`
  color: ${Colors.danger};
`

// Main Component
export const LoginView: React.FC<Props> = () => {
  const [step, setStep] = useState(LoginSteps.LOGIN)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const [{ loading, error }, { setLoading }] = useFetchState()
  const [showVerifyForm, setShowVerifyForm] = React.useState(false)
  const [isDbToMl, setIsDbToMl] = useState(false)

  const dispatch = useDispatch()

  const handleSubmit = () => {
    setLoading(true)
    APIService.authService
      .preLogin(email, password)
      .then(async (res) => {
        if (res.data.disable_2fa) {
          try {
            const loginRes = await APIService.authService.login(email, password, '', rememberMe)
            dispatch(saveToken(loginRes.data.token))
          } catch (err) {
            dispatch(loginFailure(err.response.status))
            notification.error({ message: err.response.data.message })
          }
          // dispatch(login(email, password, '', rememberMe))
          return
        }
        setStep(LoginSteps.CHECK_CODE)
        notification.success({
          message: `A verification code has been sent to email: ${email}!`,
        })
      })
      .catch((e) => {
        if (e.response.data.error_code === 'EXISTING_USER_WITHOUT_ML_ACCESS') {
          setShowVerifyForm(true)
          return
        }
        notification.error({ message: e.response ? e.response.data.message : 'Some thing went wrong!' })
      })
      .finally(() => setLoading(false))
  }

  const handleVerify = async () => {
    setIsDbToMl(true)
    setShowVerifyForm(false)
  }

  return (
    <AuthLayout title="HFR ManagerLink">
      {!isDbToMl ? (
        <LoginContainer>
          <Row style={{ marginBottom: 50, flexDirection: 'column' }} justify="center" align="middle">
            <LogoImg src={Logo} />
            <span style={{ fontSize: 20, fontWeight: 500 }}>ManagerLink</span>
          </Row>
          {step === LoginSteps.LOGIN && (
            <Form className="login-form" onFinish={handleSubmit}>
              <Form.Item
                validateStatus={error ? 'error' : undefined}
                name="email"
                rules={[{ required: true, message: 'Email is required' }]}
              >
                <Input
                  name={'email'}
                  onChange={(e) => setEmail(e.target.value)}
                  prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Email"
                />
              </Form.Item>
              <Form.Item
                validateStatus={error ? 'error' : undefined}
                name="password"
                rules={[{ required: true, message: 'Password is required' }]}
              >
                <Input
                  name={'password'}
                  onChange={(e) => setPassword(e.target.value)}
                  prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <ErrorMessage>{error}</ErrorMessage>
              <Form.Item>
                <Checkbox checked={rememberMe} onChange={() => setRememberMe(!rememberMe)}>
                  Remember me
                </Checkbox>
                <Link to={'/auth/forgot-password'} style={{ float: 'right' }}>
                  Forgot password
                </Link>
              </Form.Item>

              <Form.Item>
                <Button loading={loading} type="primary" htmlType="submit" disabled={loading} style={{ width: '100%' }}>
                  Log in
                </Button>
                Or <Link to={'/auth/sign-up'}>Register now!</Link>
              </Form.Item>
            </Form>
          )}
          {step === LoginSteps.CHECK_CODE && (
            <CheckCodeLogin email={email} password={password} rememberMe={rememberMe} />
          )}
          <Modal
            visible={showVerifyForm}
            title="Accessing Manager Link"
            onOk={handleVerify}
            onCancel={() => setShowVerifyForm(false)}
            okText="Yes"
          >
            In order to access HFR Manager Link, we will need to verify your information internally. Please select Yes
            to initiate that process or Cancel to go back.
          </Modal>
        </LoginContainer>
      ) : (
        <DbToMlForm email={email} password={password} setIsDbToMl={setIsDbToMl} />
      )}
    </AuthLayout>
  )
}
