import { AxiosPromise, AxiosInstance } from 'axios'
import {
  Benchmarks,
  DateValueRecordList,
  PortfolioModelFundRecord,
  PortfolioModelRecord,
  PortfolioModelStats,
} from '../models/ResponsesTypes'

export interface UpdatePortfolioModelParam {
  update?: Array<{ fund_id: string; weight: number } | { user_fund_id: number; weight: number }>
  add?: Array<{ fund_id: string; weight: number } | { user_fund_id: number; weight: number }>
  delete?: Array<string>
  user_fund_delete?: Array<number>
  name?: string
}

export interface PortfolioModelService {
  fetchPortfolioModels: () => AxiosPromise<PortfolioModelRecord[]>
  fetchPortfolioModelFunds: (modelId: string) => AxiosPromise<PortfolioModelFundRecord[]>
  fetchPortfolioModelStats: (modelId: string) => AxiosPromise<PortfolioModelStats>
  fetchPortfolioModelVami: ({
    modelId,
    params,
  }: {
    modelId: string
    params: Benchmarks
  }) => AxiosPromise<DateValueRecordList>
  createNewModel: (
    name: string,
    funds: Array<{ fund_id: string; weight: number } | { user_fund_id: number; weight: number }>,
  ) => AxiosPromise<void>
  updatePortfolioModel: ({ modelId, data }: { modelId: string; data: UpdatePortfolioModelParam }) => AxiosPromise<void>
  deleteModel: (modelId: string) => Promise<void>
  sharePortfolioModel: (id: number, shareTo: number[], isShareAll: boolean) => Promise<void>
}

export const PortfolioModelService = (request: AxiosInstance): PortfolioModelService => {
  return {
    fetchPortfolioModels: () => request.get('/portfolio-model'),
    fetchPortfolioModelFunds: (modelId: string) => request.get(`/portfolio-model/${modelId}`),
    fetchPortfolioModelStats: (modelId: string) => request.get(`/portfolio-model/${modelId}/stats`),
    fetchPortfolioModelVami: ({ modelId, params }: { modelId: string; params: Benchmarks }) =>
      request.get(`/portfolio-model/${modelId}/vami`, { params }),
    createNewModel: (name, funds) => request.post('/portfolio-model', { name, funds }),
    updatePortfolioModel: ({ modelId, data }) => request.put(`/portfolio-model/${modelId}`, data),
    deleteModel: (modelId: string) => request.delete(`/portfolio-model/${modelId}`),
    sharePortfolioModel: (id, shareTo, isShareAll) =>
      request.post(`/portfolio-model/${id}/share`, {
        user_ids: shareTo,
        all: isShareAll,
      }),
  }
}
