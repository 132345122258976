import { SearchOutlined } from '@ant-design/icons'
import { Button, Input, Space, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React, { useMemo } from 'react'
import styled, { keyframes } from 'styled-components'
import { AnalysisGroupListContext } from '../../shared/api/context/AnalysisGroupListContext'
import { AnalysisType } from '../../shared/api/models/Analysis'
import { Resizable, ResizeCallbackData } from 'react-resizable'

const highLightAnim = keyframes`
  0% {
    background: transparent;
  }
  50% {
    background: #e6f7ff;
  }
  100% {
    background: transparent;
  }
`

const Wrapper = styled('div')`
  width: 100%;
  .highlight {
    animation: ${highLightAnim} 3s linear 0s 1;
  }
  .react-resizable {
    position: relative;
    background-clip: padding-box;
    user-select: none;
  }
  .react-resizable-handle {
    position: absolute;
    right: -2px;
    bottom: 0;
    z-index: 1;
    width: 4px;
    height: 100%;
    cursor: col-resize;
    background: #ccc;
  }
`
type ResizableTitleProps = {
  onResize: () => void
  width: number
}
const ResizableTitle: React.FC<ResizableTitleProps> = (props) => {
  const { onResize, width, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation()
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  )
}

type AnalysisGroupRowType = AnalysisType & { children?: AnalysisGroupRowType[] }

const transformData = (dataGroup: AnalysisType[]) => {
  const hashTable = Object.create(null) as AnalysisGroupRowType[]
  dataGroup.forEach((aData: AnalysisType) => (hashTable[aData.group_code] = { ...aData }))
  const dataTree: AnalysisGroupRowType[] = []
  dataGroup
    .sort((a, b) => {
      if (a.group_name.toLowerCase().trim() > b.group_name.toLowerCase().trim()) {
        return 1
      }
      if (a.group_name.toLowerCase().trim() < b.group_name.toLowerCase().trim()) {
        return -1
      }
      return 0
    })
    .forEach((aData: AnalysisType) => {
      if (aData.parent_group_code !== -1) {
        const parentGroup = hashTable[aData.parent_group_code]
        if (parentGroup) {
          if (!parentGroup.children || parentGroup.children?.length === 0) {
            parentGroup.children = []
          }
          parentGroup.children.push(hashTable[aData.group_code])
        }
      } else {
        dataTree.push(hashTable[aData.group_code])
      }
    })
  return dataTree
}

type Props = {
  selectedGroup?: AnalysisType
  onSelectGroup: (groupId: AnalysisType) => void
  isShownInModal?: boolean
  expandedRowKeys?: number[]
  onExpand?: (expanded: boolean, record: AnalysisType) => void
}

const GroupTable: React.FC<Props> = ({
  selectedGroup,
  onSelectGroup,
  isShownInModal = false,
  expandedRowKeys,
  onExpand,
}) => {
  const { data, loading, addedRowTree } = React.useContext(AnalysisGroupListContext)

  const [searchText, setSearchText] = React.useState<string>()
  const [searchedColumn, setSearchedColumn] = React.useState<keyof AnalysisType>('group_code')
  const [columnsWidth, setColumnsWidth] = React.useState<number[]>([200, 60, 40])

  const searchInput = React.useRef<Input>(null)

  const handleSearch = (selectedKeys: React.ReactText[], confirm: (() => void) | undefined, dataIndex: string) => {
    confirm && confirm()
    setSearchText(selectedKeys[0] as string)
    setSearchedColumn(dataIndex as keyof AnalysisType)
  }

  const handleReset = (clearFilters: (() => void) | undefined) => {
    clearFilters && clearFilters()
    setSearchText(undefined)
  }

  const getColumnSearchProps = (dataIndex: keyof AnalysisType): ColumnProps<AnalysisGroupRowType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
        />
        <Space style={{ marginTop: 8 }}>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    // onFilter: (value, record) => {
    //   return record[dataIndex]
    //     ? record[dataIndex].toString().toLowerCase().includes(value.toString().toLowerCase())
    //     : false
    // },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select())
      }
    },
  })

  const columns = useMemo<ColumnProps<AnalysisGroupRowType>[]>(
    () => [
      {
        title: 'Group Name',
        key: 'group_name',
        dataIndex: 'group_name',
        // fixed: true,
        ellipsis: true,
        width: columnsWidth[0],
        // @ts-ignore
        onHeaderCell: (column) => ({
          width: columnsWidth[0],
          onResize: handleResize(0),
        }),
        ...getColumnSearchProps('group_name'),
      },
      {
        title: 'User',
        key: 'username',
        dataIndex: 'username',
        ellipsis: true,
        width: columnsWidth[1],
        // @ts-ignore
        onHeaderCell: (column) => ({
          width: columnsWidth[1],
          onResize: handleResize(1),
        }),
        ...getColumnSearchProps('username'),
      },
      {
        title: 'GC',
        key: 'gc',
        dataIndex: 'group_code',
        width: columnsWidth[2],
        // @ts-ignore
        onHeaderCell: (column) => ({
          width: columnsWidth[2],
          onResize: handleResize(2),
        }),
        ...getColumnSearchProps('group_code'),
      },
    ],
    [columnsWidth],
  )

  const fundGroupData = React.useMemo(() => {
    if (data) {
      if (!searchText) {
        return transformData(data)
      }
      const filteredData = data.filter((item) => {
        if (!item[searchedColumn]) {
          return false
        }
        return item[searchedColumn].toString().toLowerCase().includes(searchText.toLowerCase())
      })
      return filteredData
    }
  }, [data, searchText, searchedColumn])

  const handleResize = (index: number): ((e: React.SyntheticEvent, data: ResizeCallbackData) => void) => (
    _,
    { size },
  ) => {
    const newState = [...columnsWidth]
    newState[index] = size.width
    setColumnsWidth(newState)
  }

  return (
    <Wrapper>
      <Table
        expandable={{
          expandedRowKeys,
          onExpand,
        }}
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        size="small"
        rowKey="group_code"
        columns={columns}
        rowClassName={(record) =>
          addedRowTree && addedRowTree[0] === record.group_code ? `gc-${record.group_code} highlight` : ''
        }
        dataSource={fundGroupData}
        pagination={false}
        loading={loading}
        scroll={{
          y: isShownInModal ? '500px' : selectedGroup ? 'calc(100vh - 355px)' : 'calc(100vh - 295px)',
          x: 'min-content',
        }}
        rowSelection={{
          type: 'radio',
          fixed: true,
          columnWidth: 30,
          selectedRowKeys: selectedGroup ? [selectedGroup.group_code] : undefined,
          onChange: (_, selectedRows) => {
            onSelectGroup(selectedRows[0])
          },
        }}
      />
    </Wrapper>
  )
}

export default GroupTable
