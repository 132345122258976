import { AxiosInstance, AxiosPromise } from 'axios'
import browser from 'browser-detect'

export interface EventParams {
  source: string
  user_agent: string
  code: EventCodes
  os: string
  // screen_x: string;
  // screen_y: string;
  client_created_date: string
  fund_id: string
  url: string
  json_data: string
}

export enum EventCodes {
  // Session EVENTS
  LOG_IN_EVENT = 'LOG_IN',
  SIGN_UP_EVENT = 'SIGN_UP',
  FORGOT_PASSWORD_EVENT = 'FORGOT_PASSWORD',
  LOG_OUT_EVENT = 'LOG_OUT',
  ACCEPT_DISCLAIMER_EVENT = 'ACCEPT_DISCLAIMER',
  // Access EVENTS
  SEARCH_FUNDS_ACCESS_EVENT = 'SEARCH_FUNDS_ACCESS',
  ADVANCE_SEARCH_ACCESS_EVENT = 'ADVANCE_SEARCH_ACCESS',
  UPLOAD_FUNDS_ACCESS_EVENT = 'UPLOAD_FUNDS_ACCESS',
  DOWNLOAD_FILE_ACCESS_EVENT = 'DOWNLOAD_FILE_ACCESS',
  // Search UI EVENTS
  CHART_VIEW_EVENT = 'CHART_VIEW',
  SEARCH_VIEW_EVENT = 'SEARCH_VIEW',
  FUND_SEARCH_STATS_CHART_VIEW_EVENT = 'FUND_SEARCH_STATS_CHART_VIEW',
  // Funds Events
  VIEW_FUND_PROFILE_EVENT = 'VIEW_FUND_PROFILE',
  VIEW_PGA_EVENT = 'VIEW_PGA',
  // My Data Events
  MY_LIST_ACCESS_EVENT = 'MY_LIST_ACCESS',
  MY_MODELS_ACCESS_EVENT = 'MY_MODELS_ACCESS',
  MY_FUNDS_ACCESS_EVENT = 'MY_FUNDS_ACCESS',
  // User Created List Events
  VIEW_UCL_EVENT = 'VIEW_UCL',
  // Models Events
  VIEW_PORTFOLIO_MODEL_EVENT = 'VIEW_PORTFOLIO_MODEL',
  // My Funds Events
  VIEW_MY_FUNDS_EVENT = 'VIEW_MY_FUNDS',
  VIEW_MY_FUND_EVENT = 'VIEW_MY_FUND',
  // Rankings
  VIEW_STRATEGY_RANKING = 'VIEW_STRATEGY_RANKING',
  VIEW_FUND_RANKING = 'VIEW_FUND_RANKING',
  VIEW_RISK_PREMIA_RANKING = 'VIEW_RISK_PREMIA_RANKING',
  VIEW_INDEX_FAMILY = 'VIEW_INDEX_FAMILY',
}

export enum TrackingEventSource {
  MANAGER_LINK_EXTERNAL = 'MANAGER_LINK_EXTERNAL',
  MANAGER_LINK_INTERNAL = 'MANAGER_LINK_INTERNAL',
  SYSTEM_SOURCE = 'SYSTEM_SOURCE',
}

export interface EventService {
  sendViewPgaEvent: (type: TrackingEventSource, data: string) => AxiosPromise<void>
}

const sendEvent = (request: AxiosInstance, source: TrackingEventSource, data: Partial<EventParams>) => {
  const platform = browser()
  return request.post('/event', {
    ...data,
    os: platform.os,
    source: source,
    user_agent: navigator.userAgent,
    url: window.location.href,
  })
}

export const EventService = (request: AxiosInstance): EventService => {
  return {
    sendViewPgaEvent: (source, data) =>
      sendEvent(request, source, { code: EventCodes.VIEW_PGA_EVENT, json_data: data }),
  }
}
