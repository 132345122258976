import { Col, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'
import { FundDetailDropdownContext } from '../../../../shared/api/context/FundDetailDropdownContext'
import { DividerForm } from '../../../../shared/components/DividerForm'
import {
  FormItemAutoComplete,
  FormItemCheckBox,
  FormItemDatePicker,
  FormItemInput,
  FormItemSelect,
} from '../../../../shared/components/FormItemComponents'
import { RenderLabelFormFirm } from '../../../../shared/components/RenderLabelFormFirm'
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 1rem 2rem;
  flex-direction: column;
`
const REGISTRATION_OPTIONS = [
  {
    text: 'Investment Advisor',
    value: 'Investment Advisor',
  },
  {
    text: 'Broker-Dealer',
    value: 'Broker-Dealer',
  },
  {
    text: 'CPO/CTO',
    value: 'CPO/CTO',
  },
]
type Props = {
  form: FormInstance
}
export const ManagerFirmInfo: React.FC<Props> = ({ form }) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)

  const isMinorityFirm = form.getFieldValue('is_minority_firm')

  React.useEffect(() => {
    if (!isMinorityFirm) {
      // is_minority_firm === false -> update minority_own_type, minority_own_level to null
      // visually update on the firm
      form.setFieldsValue({ minority_own_type: undefined, minority_own_level: undefined })
    }
  }, [form, isMinorityFirm])

  return (
    <Wrapper>
      <Row>
        <Col span={24} lg={{ span: 18, push: 2 }} xl={{ span: 16, push: 4 }} xxl={{ span: 16, push: 4 }}>
          <Row>
            <FormItemInput
              name="firm_name"
              label={<RenderLabelFormFirm value="Firm Name" />}
              labelCol={4}
              wrapperCol={20}
            />
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <FormItemInput
                name="founded"
                label={<RenderLabelFormFirm value="Year Founded" />}
                labelCol={8}
                wrapperCol={16}
                normalize="number"
                typeField="number"
              />
            </Col>
            <Col span={12}>
              <FormItemInput
                name="owned_by_employees"
                label={<RenderLabelFormFirm value="% of owned by employees" />}
                normalize="number"
                typeField="number"
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={8}>
              <FormItemInput
                disabled
                name="firm_asset"
                label={<RenderLabelFormFirm value="Firm Assets" />}
                labelCol={12}
                wrapperCol={12}
                typeField="number"
                normalize="float"
                suffix="MM"
              />
            </Col>
            <Col span={6}>
              <FormItemDatePicker
                picker="month"
                name="firm_asset_date"
                labelCol={0}
                label={'as of:'}
                wrapperCol={24}
                disabled
              />
            </Col>
            <Col span={10}>
              <FormItemSelect
                name="denomination"
                label={<RenderLabelFormFirm value="Denom." />}
                labelCol={6}
                wrapperCol={18}
                options={
                  fundDataDropdown?.denomination && fundDataDropdown.denomination.length > 0
                    ? fundDataDropdown.denomination.map((item) => ({
                        value: item.code_code,
                        text: item.code_description,
                      }))
                    : []
                }
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormItemAutoComplete
                name="registration"
                options={REGISTRATION_OPTIONS}
                label={<RenderLabelFormFirm value="Registration" />}
                labelCol={4}
                wrapperCol={20}
                isKeepOptions
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <FormItemInput
                name="analyst_number"
                label={<RenderLabelFormFirm value="of Analyst" />}
                labelCol={8}
                wrapperCol={16}
                normalize="number"
                typeField="number"
              />
            </Col>
            <Col span={12}>
              <FormItemInput
                name="manager_gained"
                labelCol={12}
                wrapperCol={12}
                label={<RenderLabelFormFirm value="# of Managers Gained" />}
                typeField="number"
                normalize="number"
              />
            </Col>
            <Col span={12}>
              <FormItemSelect
                name="hfr_analyst"
                label={<RenderLabelFormFirm value="HFR Analyst" />}
                labelCol={8}
                wrapperCol={16}
                options={
                  fundDataDropdown?.hfr_analyst && fundDataDropdown.hfr_analyst.length > 0
                    ? fundDataDropdown.hfr_analyst.map((item) => ({
                        value: item.code_code,
                        text: item.code_description,
                      }))
                    : []
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <DividerForm />
      <Row>
        <Col span={24} lg={{ span: 18, push: 2 }} xl={{ span: 18, push: 2 }} xxl={{ span: 18, push: 2 }}>
          <Row>
            <FormItemInput
              name="employees_with_ownership_stake"
              label={<RenderLabelFormFirm value="# of employees w/ ownership stake" />}
              labelCol={6}
              wrapperCol={18}
              typeField="number"
              normalize="number"
            />
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <FormItemInput
                name="firm_assets_dedicated_hf"
                label={<RenderLabelFormFirm value="Firm AUM Dedicated to HF" />}
                labelCol={12}
                disabled
                typeField="number"
                normalize="float"
                suffix="MM"
                rules={[
                  () => ({
                    validator(rule, value) {
                      if ((value && value > 0) || value === null) {
                        return Promise.resolve()
                      }
                      return Promise.reject('Value must be greater than 0!')
                    },
                  }),
                ]}
              />
            </Col>
            <Col span={12}>
              <FormItemDatePicker
                disabled
                name="firm_assets_date_hf"
                picker="month"
                label={<RenderLabelFormFirm value="as of" />}
                labelCol={0}
                wrapperCol={24}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <FormItemInput
                name="firm_assets_dedicated_fof"
                label={<RenderLabelFormFirm value="Firm AUM Dedicated to FOF" />}
                labelCol={12}
                typeField="number"
                normalize="float"
                suffix="MM"
                rules={[
                  () => ({
                    validator(rule, value) {
                      if ((value && value > 0) || value === null) {
                        return Promise.resolve()
                      }
                      return Promise.reject('Value must be greater than 0!')
                    },
                  }),
                ]}
              />
            </Col>
            <Col span={12}>
              <FormItemDatePicker
                name="firm_assets_date_fof"
                label={<RenderLabelFormFirm value="as of" />}
                labelCol={0}
                wrapperCol={24}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <FormItemInput
                name="managers_lost"
                label={<RenderLabelFormFirm value="# of Managers Lost" />}
                labelCol={12}
                wrapperCol={12}
                typeField="number"
                normalize="number"
              />
            </Col>
            <Col span={12}>
              <FormItemInput
                name="portfolio_managers"
                label={<RenderLabelFormFirm value="Ptf.Mgr." />}
                labelCol={12}
                wrapperCol={12}
                normalize="number"
                typeField="number"
              />
            </Col>
          </Row>
          <DividerForm />
          <Row>
            <Col span={6} offset={6}>
              <FormItemCheckBox name="is_minority_firm" text={<RenderLabelFormFirm value="Is Minority Firm" />} />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <FormItemSelect
                name="minority_own_type"
                label={<RenderLabelFormFirm value="Minority Own Type" />}
                disabled={!form.getFieldValue('is_minority_firm')}
                labelCol={12}
                wrapperCol={12}
                options={
                  fundDataDropdown?.minority_own_type && fundDataDropdown.minority_own_type.length > 0
                    ? fundDataDropdown.minority_own_type.map((item) => ({
                        value: item.code_code,
                        text: item.code_description,
                      }))
                    : []
                }
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <FormItemSelect
                name="minority_own_level"
                label={<RenderLabelFormFirm value="Minority Own Level" />}
                disabled={!form.getFieldValue('is_minority_firm')}
                labelCol={12}
                wrapperCol={12}
                options={
                  fundDataDropdown?.minority_own_level && fundDataDropdown.minority_own_level.length > 0
                    ? fundDataDropdown.minority_own_level.map((item) => ({
                        value: item.code_code,
                        text: item.code_description,
                      }))
                    : []
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  )
}
