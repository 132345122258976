import { AxiosInstance, AxiosPromise } from 'axios'
import { RORResponseType, UpdateRORType } from '../../components/DataSheet'
import { ExFundDetails } from '../models/ExFundDetails'
import {
  ChecklistLegalDoc,
  ChecklistMainType,
  ChecklistMainUpdate,
  CodeOfConductResponse,
  DueDiligenceMeetingCreate,
  DueDiligenceMeetingDelete,
  DueDiligenceMeetingResponse,
  DueDiligenceMeetingUpdate,
  DueDiligenceReceiveSepAct,
  DueDiligenceReceiveSepActUpdate,
  DueDiligenceSentSepAct,
  DueDiligenceSentSepActCreate,
  ExFirmDetails,
  FirmDetails,
  FirmDueDiligenceActivities,
  FirmDueDiligenceActivitiesUpdate,
  FirmDueDiligenceDetails,
  FirmDueDiligenceParams,
  FirmMoreField,
  LegalChecklistUpdate,
  MultiManagerPlatformType,
  MultiManagerPlatformUpdate,
} from '../models/FirmDetails'
import { DueDiligenceContact, FundCCMCResponse } from '../models/FundDetails'

export interface FirmDetailService {
  fetchFirmInfo: (firmId: string) => AxiosPromise<FirmDetails>
  fetchFirmAssets: (firmId: string) => AxiosPromise<RORResponseType[]>
  fetchFirmAssetsHedgeFund: (firmId: string) => AxiosPromise<RORResponseType[]>
  fetchFirmCCMC: (firmId: string) => AxiosPromise<{ responses: FundCCMCResponse[] }>
  fetchFirmCMIC: (firmId: string) => AxiosPromise<{ responses: FundCCMCResponse[] }>
  fetchFundsOfFirm: (firmId: string) => AxiosPromise<ExFundDetails[]>
  fetchAllFirms: () => AxiosPromise<ExFirmDetails[]>

  updateFirmInfo: (fundId: string, param: Partial<FirmDetails & FirmMoreField>) => AxiosPromise<void>
  updateFirmAssets: (firmId: string, data: UpdateRORType) => AxiosPromise<void>
  updateFirmAssetsHedgeFund: (firmId: string, data: UpdateRORType) => AxiosPromise<void>
  updateFirmCCMC: (firmId: string, data: FundCCMCResponse[]) => AxiosPromise<void>
  updateFirmCMIC: (firmId: string, data: FundCCMCResponse[]) => AxiosPromise<void>

  createFirmInfo: (param: Partial<FirmDetails>) => AxiosPromise<{ firm_id: string; message: string }>
  getFileDueDiligence: (param: FirmDueDiligenceParams) => AxiosPromise<FirmDueDiligenceDetails>

  //Due Diligence Activities
  getListDueDiligenceAct: (firmId: string) => AxiosPromise<FirmDueDiligenceActivities[]>
  createDueDiligenceAct: (firmId: string, params: Partial<FirmDueDiligenceActivities>) => AxiosPromise<void>
  updateDueDiligenceAct: (firmId: string, params: Partial<FirmDueDiligenceActivitiesUpdate>) => AxiosPromise<void>
  //Due Diligence Sent Message
  getDueDiligenceSentMess: (firmId: string) => AxiosPromise<DueDiligenceSentSepAct[]>
  createDueDiligenceSentMess: (firmId: string, params: DueDiligenceSentSepActCreate) => AxiosPromise<void>
  deleteDueDiligenceSentMess: (firmId: string, params: { fund_id: string }) => AxiosPromise<void>

  //Due Diligence Receive Message
  getDueDiligenceReceiveMess: (firmId: string) => AxiosPromise<DueDiligenceReceiveSepAct[]>
  createDueDiligenceReceiveMess: (
    firmId: string,
    params: Partial<DueDiligenceReceiveSepActUpdate>,
  ) => AxiosPromise<void>
  updateDueDiligenceReceiveMess: (
    firmId: string,
    params: Partial<DueDiligenceReceiveSepActUpdate>,
  ) => AxiosPromise<void>
  deleteDueDiligenceReceiveMess: (
    firmId: string,
    params: Partial<DueDiligenceReceiveSepActUpdate>,
  ) => AxiosPromise<void>
  // Checklist
  getChecklistMain: (firmId: string) => AxiosPromise<ChecklistMainType[]>
  getLegalChecklist: (firmId: string) => AxiosPromise<ChecklistLegalDoc[]>
  getOldBackgroundChecklist: (firmId: string) => AxiosPromise<ChecklistLegalDoc[]>
  updateChecklistMain: (firmId: string, params: { updates: ChecklistMainUpdate[] }) => AxiosPromise<void>
  updateLegalChecklist: (firmId: string, params: { updates: LegalChecklistUpdate[] }) => AxiosPromise<void>
  updateOldChecklist: (firmId: string, params: { updates: LegalChecklistUpdate[] }) => AxiosPromise<void>
  //Due Diligence Contact
  fetchDueDiligenceContact: (firmId: string) => AxiosPromise<DueDiligenceContact[]>
  updateDueDiligenceContact: (firmId: string, params: Partial<DueDiligenceContact>) => AxiosPromise<void>
  deleteDueDiligenceContact: (firmId: string, contactName: string) => AxiosPromise<void>
  createDueDiligenceContact: (
    firmId: string,
    params: Partial<DueDiligenceContact & { old_contact_name: string; new_contact_name: string }>,
  ) => AxiosPromise<void>
  //Due Diligence Meeting
  fetchDueDiligenceMeeting: (firmId: string) => AxiosPromise<DueDiligenceMeetingResponse[]>
  updateDueDiligenceMeeting: (firmId: string, params: Partial<DueDiligenceMeetingUpdate>) => AxiosPromise<void>
  createDueDiligenceMeeting: (firmId: string, params: Partial<DueDiligenceMeetingCreate>) => AxiosPromise<void>
  deleteDueDiligenceMeeting: (firmId: string, params: DueDiligenceMeetingDelete) => AxiosPromise<void>
  //Code of Conduct
  fetchCodeOfConduct: (firmId: string) => AxiosPromise<CodeOfConductResponse[]>
  updateCodeOfConduct: (
    firmId: string,
    params: Partial<CodeOfConductResponse & { original_agreement_version: string; original_code_of_conduct: string }>,
  ) => AxiosPromise<void>
  createCodeOfConduct: (firmId: string, params: Partial<CodeOfConductResponse>) => AxiosPromise<void>
  deleteCodeOfConduct: (
    firmId: string,
    params: Partial<CodeOfConductResponse & { agreement_version: string; code_of_conduct: string }>,
  ) => AxiosPromise<void>
  previewDeleteFirm: (firmId: string) => AxiosPromise<{ [key: string]: number }>
  deleteFirm: (firmId: string) => AxiosPromise<void>
  fetchFirmUser: (firmId: string) => AxiosPromise<any>
  // Multi Manager Platform
  fetchMultiManagerPlatform: (firmId: string) => AxiosPromise<{ funds: MultiManagerPlatformType[] }>
  updateMultiManagerPlatform: (
    firmId: string,
    params: MultiManagerPlatformUpdate,
  ) => AxiosPromise<{ funds: MultiManagerPlatformType[] }>
}

export const FirmDetailService = (request: AxiosInstance): FirmDetailService => {
  return {
    fetchFirmInfo: (firmId) => request.get(`/manager-link/firms/${firmId.trim()}`),
    fetchFirmAssets: (firmId) => request.get(`manager-link/firms/${firmId.trim()}/assets`),
    fetchFirmAssetsHedgeFund: (firmId) => request.get(`manager-link/firms/${firmId.trim()}/assets-hf`),
    fetchFirmCCMC: (firmId) => request.get(`/manager-link/firms/${firmId}/executive-order-13959`),
    fetchFirmCMIC: (firmId) => request.get(`/manager-link/firms/${firmId}/executive-order-14032`),
    fetchFundsOfFirm: (firmId) => request.get(`manager-link/firms/${firmId.trim()}/funds`),
    fetchAllFirms: () => request.get(`/manager-link/firms`),

    updateFirmInfo: (firmId, param) => request.put(`/manager-link/firms/${firmId.trim()}`, param),
    updateFirmAssets: (firmId, param) => request.put(`/manager-link/firms/${firmId.trim()}/assets`, param),
    updateFirmAssetsHedgeFund: (firmId, param) => request.put(`/manager-link/firms/${firmId.trim()}/assets-hf`, param),
    updateFirmCCMC: (firmId, param) =>
      request.put(`/manager-link/firms/${firmId}/executive-order-13959`, {
        updates: param,
      }),
    updateFirmCMIC: (firmId, param) =>
      request.put(`/manager-link/firms/${firmId}/executive-order-14032`, {
        updates: param,
      }),

    createFirmInfo: (param) => request.post(`/manager-link/firms`, param),
    getFileDueDiligence: (param) => request.post(`/manager-link/due-diligence/list-files`, param),

    //Due Diligence Activities
    getListDueDiligenceAct: (firmId) => request.get(`/manager-link/due-diligence/activities/${firmId.trim()}`),
    createDueDiligenceAct: (firmId, params) =>
      request.post(`/manager-link/due-diligence/activities/${firmId.trim()}`, params),
    updateDueDiligenceAct: (firmId, params) =>
      request.put(`/manager-link/due-diligence/activities/${firmId.trim()}`, params),
    //Due Diligence Sent Message
    getDueDiligenceSentMess: (firmId) => request.get(`/manager-link/due-diligence/sent-messages/${firmId.trim()}`),
    createDueDiligenceSentMess: (firmId, params) =>
      request.post(`/manager-link/due-diligence/sent-messages/${firmId.trim()}`, params),
    deleteDueDiligenceSentMess: (firmId, params) =>
      request.delete(`/manager-link/due-diligence/sent-messages/${firmId.trim()}`, { data: params }),
    //Due Diligence Receive Message
    getDueDiligenceReceiveMess: (firmId: string) =>
      request.get(`/manager-link/due-diligence/received-messages/${firmId.trim()}`),
    createDueDiligenceReceiveMess: (firmId, params) =>
      request.post(`/manager-link/due-diligence/received-messages/${firmId.trim()}`, params),
    updateDueDiligenceReceiveMess: (firmId, params) =>
      request.put(`/manager-link/due-diligence/received-messages/${firmId.trim()}`, params),
    deleteDueDiligenceReceiveMess: (firmId, params) =>
      request.delete(`/manager-link/due-diligence/received-messages/${firmId.trim()}`, { data: params }),
    //Checklist
    getChecklistMain: (firmId) => request.get(`/manager-link/due-diligence/main-checklist/${firmId.trim()}`),
    getLegalChecklist: (firmId) => request.get(`/manager-link/due-diligence/legal-checklist/${firmId.trim()}`),
    getOldBackgroundChecklist: (firmId) =>
      request.get(`/manager-link/due-diligence/old-background-check-checklist/${firmId}`),
    updateChecklistMain: (firmId, params) =>
      request.put(`/manager-link/due-diligence/main-checklist/${firmId.trim()}`, params),
    updateLegalChecklist: (firmId, params) =>
      request.put(`/manager-link/due-diligence/legal-checklist/${firmId.trim()}`, params),
    updateOldChecklist: (firmId, params) =>
      request.put(`/manager-link/due-diligence/old-background-check-checklist/${firmId.trim()}`, params),
    //Due Diligence Contact
    fetchDueDiligenceContact: (firmId) => request.get(`/manager-link/due-diligence/contact/${firmId.trim()}`),
    updateDueDiligenceContact: (firmId, params) =>
      request.put(`/manager-link/due-diligence/contact/${firmId.trim()}`, params),
    deleteDueDiligenceContact: (firmId, contactName) =>
      request.delete(`/manager-link/due-diligence/contact/${firmId.trim()}`, { data: { contact_name: contactName } }),
    createDueDiligenceContact: (firmId, params) =>
      request.post(`/manager-link/due-diligence/contact/${firmId}`, params),
    //Due Diligence Meeting
    fetchDueDiligenceMeeting: (firmId) => request.get(`/manager-link/due-diligence/meeting/${firmId.trim()}`),
    createDueDiligenceMeeting: (firmId, params) =>
      request.post(`/manager-link/due-diligence/meeting/${firmId.trim()}`, params),
    updateDueDiligenceMeeting: (firmId, params) =>
      request.put(`/manager-link/due-diligence/meeting/${firmId.trim()}`, params),
    deleteDueDiligenceMeeting: (firmId, params) =>
      request.delete(`/manager-link/due-diligence/meeting/${firmId.trim()}`, { data: params }),
    //Code Of Conduct
    fetchCodeOfConduct: (firmId) => request.get(`/manager-link/firms/${firmId}/code-of-conducts`),
    updateCodeOfConduct: (firmId, params) =>
      request.put(`/manager-link/firms/${firmId.trim()}/code-of-conducts`, params),
    createCodeOfConduct: (firmId, params) =>
      request.post(`/manager-link/firms/${firmId.trim()}/code-of-conducts`, params),
    deleteCodeOfConduct: (firmId, params) =>
      request.delete(`/manager-link/firms/${firmId}/code-of-conducts`, { data: params }),
    previewDeleteFirm: (firmId) => request.get(`/manager-link/firms/${firmId.trim()}/preview-delete`),
    deleteFirm: (firmId) => request.delete(`/manager-link/firms/${firmId.trim()}`),
    fetchFirmUser: (firmId) => request.get(`/manager-link/firms/${firmId.trim()}/users`),
    // Multi Manager Platform
    fetchMultiManagerPlatform: (firmId) => request.get(`/manager-link/firms/${firmId}/multi-manager-platform`),
    updateMultiManagerPlatform: (firmId, params) =>
      request.patch(`/manager-link/firms/${firmId}/multi-manager-platform`, params),
  }
}
