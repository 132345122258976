import React, { Dispatch, SetStateAction } from 'react'
import APIService from '..'
import { UpdateRORType } from '../../components/DataSheet'
import { useServiceState } from '../../hooks/useServiceState'
import { FirmDetails, FirmMoreField } from '../models/FirmDetails'
import { FundDropdownType } from '../models/FundDetails'
export type FirmDetailDataContextType = {
  dataFirmDetail?: FirmDetails
  loading: boolean
  id: string
  refetch: () => void
  dataFirmDetailSubmitRef: React.MutableRefObject<
    Partial<FirmDetails & FirmMoreField & { _firmAssetsDataChange: UpdateRORType }>
  > | null
  fundDataDropdown?: FundDropdownType
  dataChanged: number
  setDataChanged: Dispatch<SetStateAction<number>>
}

const defaultValue: FirmDetailDataContextType = {
  dataFirmDetail: undefined,
  id: '',
  loading: true,
  refetch: () => undefined,
  dataFirmDetailSubmitRef: null,
  fundDataDropdown: undefined,
  dataChanged: 1,
  setDataChanged: () => undefined,
}
type Props = {
  id: string
}
export const FirmDetailDataContext = React.createContext<FirmDetailDataContextType>(defaultValue)
export const FirmDetailDataProvider: React.FC<Props> = ({ children, id }) => {
  const [dataChanged, setDataChanged] = React.useState(1)
  const { invoke: getFirmDetail, data: dataFirmDetail, loading } = useServiceState(
    APIService.firmDetailService.fetchFirmInfo,
  )
  const { invoke: getDataDropdown, data: fundDataDropdown } = useServiceState(
    APIService.fundDetailService.fetchDataDropdown,
  )

  const dataFirmDetailSubmitRef = React.useRef<
    Partial<FirmDetails & FirmMoreField & { _firmAssetsDataChange: UpdateRORType }>
  >({})

  React.useEffect(() => {
    getFirmDetail(id)
  }, [getFirmDetail, id])

  React.useEffect(() => {
    getDataDropdown()
  }, [getDataDropdown])

  const refetch = () => {
    getFirmDetail(id)
  }

  const value = {
    dataFirmDetail,
    loading,
    refetch,
    id,
    dataFirmDetailSubmitRef,
    fundDataDropdown,
    dataChanged,
    setDataChanged,
  }
  return <FirmDetailDataContext.Provider value={value}>{children}</FirmDetailDataContext.Provider>
}
