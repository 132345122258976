import { Button, Col, notification, Radio, Row } from 'antd'
import React, { Dispatch, SetStateAction } from 'react'
import APIService from '../../shared/api'
import { ExFirmDetails } from '../../shared/api/models/FirmDetails'
import { Colors } from '../../shared/colors'
import { useServiceState } from '../../shared/hooks/useServiceState'

type Props = {
  record: ExFirmDetails
  refreshUser: () => void
  setShowModel: Dispatch<SetStateAction<boolean>>
  setSelectedFirm: Dispatch<SetStateAction<ExFirmDetails | undefined>>
}

const ExpandedRowRender: React.FC<Props> = ({ record, refreshUser, setShowModel, setSelectedFirm }) => {
  const [signCheck, setSignCheck] = React.useState<boolean | undefined>()
  const [loading, setLoading] = React.useState(false)

  const handleUpdateCOC = () => {
    if (!dataCodeOfConduct?.version) {
      return
    }
    setLoading(true)
    APIService.codeOfConductService
      .updateCodeOfConduct(record.firm_id, dataCodeOfConduct.version, signCheck)
      .then(() => {
        notification.success({ message: 'Code of Conduct Updated! ' })
        refreshUser()
        setLoading(false)
        setShowModel(false)
      })
      .catch(() => {
        notification.error({ message: 'Failed to sign Code of Conduct!' })
        setLoading(false)
        setShowModel(false)
      })
  }

  const { invoke: getCodeOfConduct, data: dataCodeOfConduct } = useServiceState(
    APIService.codeOfConductService.getCodeOfConduct,
  )

  React.useEffect(() => {
    getCodeOfConduct()
  }, [getCodeOfConduct])

  const handleClick = () => {
    setSelectedFirm(record)
    setShowModel(true)
  }

  return (
    <div style={{ background: Colors.border, padding: '1rem' }}>
      <div style={{ display: 'inline-block' }}>
        <h4>
          <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleClick}>
            HFR Submitter Code of Conduct:
          </span>
          <span style={{ fontWeight: 'bold', color: Colors.danger }}> Annual Opt-In Required by June 30, 2024</span>
        </h4>
      </div>
      <Row justify="space-between" align="middle">
        <Col>
          <div>
            <b>Important</b>: Firms that do not agree to the SCOC by <b>June 30, 2024</b>, will not be eligible for HFR
            Index inclusion.
          </div>
          <Radio.Group
            value={signCheck}
            onChange={(e) => {
              setSignCheck(e.target.value)
            }}
          >
            <Radio value={true}>I Agree</Radio>
            <Radio value={false}>I Do Not Agree</Radio>
          </Radio.Group>
        </Col>
        <Col>
          <Button type="primary" disabled={signCheck === undefined} onClick={handleUpdateCOC} loading={loading}>
            Submit
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default ExpandedRowRender
