import { DeleteTwoTone, EditOutlined } from '@ant-design/icons'
import { Button, notification, Popconfirm, Tooltip } from 'antd'
import Table, { ColumnProps } from 'antd/lib/table'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../../../../shared/api'
import { FundDetailDataContext } from '../../../../../shared/api/context/FundDetailDataContext'
import { DueDiligenceContact } from '../../../../../shared/api/models/FundDetails'
import { Colors } from '../../../../../shared/colors'
import { NextBackButton } from '../../../../../shared/components/NextBackButton'
import { useServiceState } from '../../../../../shared/hooks/useServiceState'
import { ModalEditTableContact } from './ModalEditTableContact'
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-x: auto;
`
type Props = {}
export const DueDiligenceContacts: React.FC<Props> = () => {
  const [showModal, setShowModal] = React.useState(false)
  const { dataFundDetail } = React.useContext(FundDetailDataContext)
  const [selectedContact, setSelectedContact] = React.useState<DueDiligenceContact | undefined>()
  const { invoke: getDataContact, data: dataContact, loading } = useServiceState(
    APIService.firmDetailService.fetchDueDiligenceContact,
  )

  const [loadingDelete, setLoadingDelete] = React.useState(false)

  React.useEffect(() => {
    dataFundDetail?.firm_id && getDataContact(dataFundDetail.firm_id)
  }, [dataFundDetail, getDataContact])

  const updateContact = () => {
    getDataContact(dataFundDetail?.firm_id)
  }

  const deleteContact = (contactName: string) => {
    if (dataFundDetail?.firm_id) {
      setLoadingDelete(true)
      APIService.firmDetailService
        .deleteDueDiligenceContact(dataFundDetail?.firm_id, contactName)
        .then(() => {
          notification.success({ message: 'Contact Delete!' })
          updateContact()
        })
        .catch((err) => {
          console.error({ err })
          notification.error({ message: 'Contact Delete Failed!' })
        })
        .finally(() => {
          setLoadingDelete(false)
        })
    }
  }

  const columns: ColumnProps<DueDiligenceContact>[] = [
    {
      title: 'Contact Name',
      dataIndex: 'contact_name',
      key: 'contact_name',
      fixed: 'left',
      width: 150,
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      width: 150,
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      width: 150,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 150,
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      width: 150,
    },
    {
      title: 'Address 1',
      dataIndex: 'address1',
      key: 'address1',
      width: 150,
    },
    {
      title: 'Address2',
      dataIndex: 'address2',
      key: 'address2',
      width: 150,
    },
    {
      title: 'Address3',
      dataIndex: 'address3',
      key: 'address3',
      width: 150,
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: 150,
    },
    {
      title: 'State Code',
      dataIndex: 'state_code',
      key: 'state_code',
      width: 150,
    },
    {
      title: 'Zip',
      dataIndex: 'zip',
      key: 'zip',
      width: 150,
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      width: 150,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: 150,
      sorter: (a, b) => {
        return a.phone.localeCompare(b.phone)
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Fax',
      dataIndex: 'fax',
      key: 'fax',
      width: 150,
      sorter: (a, b) => {
        return a.fax.localeCompare(b.fax)
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      width: 150,
      sorter: (a, b) => {
        return a.email.localeCompare(b.email)
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Web',
      dataIndex: 'web',
      key: 'web',
      width: 150,
      sorter: (a, b) => {
        return a.web.localeCompare(b.web)
      },
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 150,
      sorter: (a, b) => {
        return a.description.localeCompare(b.description)
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (_, record) => (
        <>
          <Button
            type="link"
            style={{ padding: '4px 8px' }}
            onClick={() => {
              setShowModal(true)
              setSelectedContact(record)
            }}
          >
            <Tooltip title="Edit">
              <EditOutlined />
            </Tooltip>
          </Button>

          <Popconfirm title="Are you sure you want to delete?" onConfirm={() => deleteContact(record.contact_name)}>
            <Button type="link" style={{ padding: '4px 8px' }} loading={loadingDelete}>
              <Tooltip title="Delete">
                <DeleteTwoTone twoToneColor={Colors.danger} />
              </Tooltip>
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ]
  return (
    <Wrapper>
      <div style={{ marginTop: '0.5rem', display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="primary" style={{ marginBottom: 8, marginRight: 8 }} onClick={() => setShowModal(true)}>
          Create New Contact
        </Button>
      </div>
      <Table
        rowKey="contact_name"
        dataSource={dataContact}
        pagination={dataContact && dataContact.length > 10 ? undefined : false}
        columns={columns}
        bordered
        loading={loading}
        scroll={{ x: 'min-content' }}
      />

      {dataFundDetail?.firm_id && (
        <ModalEditTableContact
          showModal={showModal}
          selectedContact={selectedContact}
          setShowModal={setShowModal}
          setSelectedContact={setSelectedContact}
          updateContact={updateContact}
          firmId={dataFundDetail.firm_id}
        />
      )}

      <NextBackButton />
    </Wrapper>
  )
}
