import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Card, Col, Row, Switch, Tabs } from 'antd'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../../shared/api'
import { TabContentWrapper } from '../../../shared/components/TabContentWrapper'
import { useServiceState } from '../../../shared/hooks/useServiceState'
import ActivityBlock from './ActivityBlock'
import { FundSearchColumnProvider } from './ColumnBuilder/FundSearchColumnContext'
import { CreatedUpdatedFunds } from './CreatedUpdatedFunds'
import { DraftTab } from './DraftTab'
import { FavoritedTab } from './FavoritedTab'
import { FundSearch } from './FundSearch'
import FundTableHeader from './FundTableHeader'
import { RecentlyEditedTab } from './RecentlyEditedTab'

const { TabPane } = Tabs

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;

  .ant-card-head {
    border-bottom: none;
    font-size: 1rem;
    padding: 0 1rem;
  }
  .ant-card-body {
    padding: 0 0.5rem 0.5rem 0.5rem;
    flex: 1;
  }
`

const StyledMyFundTab = styled(Tabs)`
  display: flex;
  flex-direction: column;
  .ant-card-body {
    flex: 1;
  }
`

// type ColumnsProps = {
//   fundName: string
//   firm: string
//   strategy: string
//   subStrategy: string
// }

const DashboardTab: React.FC = () => {
  const [typeSelectActivity, setTypeSelectActivity] = React.useState<string | undefined>(undefined)
  const [activeTab, setActiveTab] = React.useState('Recently Edited')
  const [isAllUser, setIsAllUser] = React.useState(false)
  const [showSidebar, setShowSidebar] = React.useState(true)

  const { invoke: getListCreated, data: listCreateFund, loading: loadingListCreate } = useServiceState(
    APIService.fundService.fetchListCreatedFund,
  )

  const { invoke: getListUpdated, data: listUpdateFund, loading: loadingListUpdate } = useServiceState(
    APIService.fundService.fetchListUpdateFund,
  )

  React.useEffect(() => {
    getListCreated(isAllUser ? { all_users: true } : undefined)
    getListUpdated(isAllUser ? { all_users: true } : undefined)
  }, [getListCreated, getListUpdated, isAllUser])

  const { invoke: getFavorited, data: dataFavorited, loading: loadingFavorited } = useServiceState(
    APIService.fundService.fetchFavorited,
  )

  React.useEffect(() => {
    getFavorited()
  }, [getFavorited])

  const handleRefetchFavorited = () => {
    getFavorited()
  }

  const renderSidebar = () => {
    return (
      <div style={{ position: 'relative', flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
        {/* <SearchForm isOpen={showSearch} /> */}

        <StyledCard
          size="small"
          title="Activities"
          extra={
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Switch
                onChange={(e) => setIsAllUser(e)}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                style={{ marginRight: 8 }}
              />
              All Users
            </div>
          }
        >
          <ActivityBlock
            setTypeSelectActivity={setTypeSelectActivity}
            setActiveTab={setActiveTab}
            loading={loadingListCreate || loadingListUpdate}
            countCreated={listCreateFund?.count}
            countUpdated={listUpdateFund?.count}
          />
        </StyledCard>
        <StyledCard size="small" title="My Funds" style={{ flex: 1, marginTop: 16, overflow: 'hidden' }}>
          <StyledMyFundTab
            hideAdd
            onChange={(key) => {
              setActiveTab(key)
            }}
            activeKey={activeTab}
          >
            {typeSelectActivity && (
              <TabPane
                tab={typeSelectActivity === 'updated' ? 'Updated Fund' : 'Created Fund'}
                closable={false}
                key="InforFund"
              >
                <CreatedUpdatedFunds
                  data={typeSelectActivity === 'updated' ? listUpdateFund : listCreateFund}
                  loading={typeSelectActivity === 'updated' ? loadingListUpdate : loadingListCreate}
                />
              </TabPane>
            )}
            <TabPane tab="Recently Edited" closable={false} key="Recently Edited">
              <RecentlyEditedTab />
            </TabPane>
            <TabPane tab="In Progress" closable={false} key="Draft">
              <DraftTab />
            </TabPane>
            <TabPane tab="Favorited" closable={false} key="Favorited">
              <FavoritedTab
                dataFavorited={dataFavorited}
                loading={loadingFavorited}
                onChangeFavorited={handleRefetchFavorited}
              />
            </TabPane>
          </StyledMyFundTab>
        </StyledCard>
      </div>
    )
  }

  return (
    <TabContentWrapper style={{ padding: '1rem' }}>
      <Row gutter={14} style={{ height: '100%' }}>
        {showSidebar && (
          <Col md={8} lg={6} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            {renderSidebar()}
          </Col>
        )}
        <Col md={showSidebar ? 16 : 24} lg={showSidebar ? 18 : 24}>
          <FundSearchColumnProvider>
            <StyledCard
              style={{ height: '100%' }}
              size="small"
              title={<FundTableHeader onShowSideBar={() => setShowSidebar(!showSidebar)} showSidebar={showSidebar} />}
            >
              <FundSearch onChangeFavorited={handleRefetchFavorited} />
            </StyledCard>
          </FundSearchColumnProvider>
        </Col>
      </Row>
    </TabContentWrapper>
  )
}

export default DashboardTab
