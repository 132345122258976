import { Col, Form, notification, Row, Spin } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/rootReducer'
import APIService from '../../../shared/api'
import { FundDetailDropdownContext } from '../../../shared/api/context/FundDetailDropdownContext'
import { FormItemSelect } from '../../../shared/components/FormItemComponents'
import { useServiceState } from '../../../shared/hooks/useServiceState'

type Props = {
  isOpen: boolean
  onRequestClose: () => void
  selectedFunds: string[]
}
export const UpdateBenchmarkModal: React.FC<Props> = ({ isOpen, onRequestClose, selectedFunds }) => {
  const { invoke: getFundDetail, data: dataFundDetail } = useServiceState(
    APIService.fundDetailService.fetchFundBasicInfo,
  )

  const appData = useSelector((state: RootState) => state.appData)

  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)
  const { dataBenchmark } = React.useContext(FundDetailDropdownContext)

  const LIST_FORM = [
    {
      name: 'strategy_code_new',
      label: 'Strategy',
      options:
        appData?.strategies && appData?.strategies.length > 0
          ? appData.strategies.map((item) => ({
              value: item.strategy_code,
              text: item.strategy_name,
            }))
          : [],
    },
    {
      name: 'sub_strategy_code_new',
      label: 'Sub-Strategy',
      options:
        appData?.subStrategies && appData?.subStrategies.length > 0
          ? appData.subStrategies.map((item) => ({
              value: item.sub_strategy_code,
              text: item.sub_strategy_name,
            }))
          : [],
    },
    {
      name: 'region_inv_focus',
      label: 'Regional Focus',
      options:
        fundDataDropdown?.region_inv_focus && fundDataDropdown?.region_inv_focus.length > 0
          ? fundDataDropdown.region_inv_focus.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
    },
    {
      name: 'fund_status_code',
      label: 'Fund Status Code',
      options:
        fundDataDropdown?.fund_status_code && fundDataDropdown?.fund_status_code.length > 0
          ? fundDataDropdown.fund_status_code.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
    },
  ]

  const [submitting, setSubmitting] = React.useState(false)
  const [form] = Form.useForm()

  React.useEffect(() => {
    if (selectedFunds.length === 1) {
      getFundDetail(selectedFunds[0])
    }
  }, [getFundDetail, selectedFunds])

  React.useEffect(() => {
    if (selectedFunds.length === 1 && dataFundDetail) {
      const initialForm = {
        b1: dataFundDetail.benchmark1,
        b2: dataFundDetail.benchmark2,
        b3: dataFundDetail.benchmark3,
        strategy_code_new: dataFundDetail.strategy_code_new,
        sub_strategy_code_new: dataFundDetail.sub_strategy_code_new,
        region_inv_focus: dataFundDetail.region_inv_focus,
        fund_status_code: dataFundDetail.fund_status_code,
      }
      form.setFieldsValue(initialForm)
    } else {
      form.resetFields()
    }
  }, [dataFundDetail, form, selectedFunds])

  const handleSubmit = () => {
    setSubmitting(true)
    APIService.fundService
      .bulkUpdateFund({
        fund_ids: selectedFunds,
        b1: form.getFieldValue('b1'),
        b2: form.getFieldValue('b2'),
        b3: form.getFieldValue('b3'),
        strategy_code_new: +form.getFieldValue('strategy_code_new') || undefined,
        sub_strategy_code_new: +form.getFieldValue('sub_strategy_code_new') || undefined,
        region_inv_focus: +form.getFieldValue('region_inv_focus') || undefined,
        fund_status_code: form.getFieldValue('fund_status_code') || undefined,
      })
      .then(() => {
        notification.success({
          message: `${selectedFunds.length} funds updated!`,
        })
        onRequestClose()
      })
      .catch(() => {
        notification.error({ message: `Update Failed!` })
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <Modal
      width="70vw"
      title="Bulk Update"
      visible={isOpen}
      onCancel={() => onRequestClose()}
      onOk={handleSubmit}
      confirmLoading={submitting}
    >
      <Row style={{ marginBottom: 16 }}>
        <b>{selectedFunds.length} funds selected</b>
      </Row>
      <Form layout="vertical" form={form}>
        {dataBenchmark ? (
          <>
            <Row gutter={8}>
              <Col span={8}>
                <FormItemSelect
                  name="b1"
                  label="Benchmark 1"
                  placeholder="Select Benchmark 1"
                  options={dataBenchmark.map((item) => ({ text: item.fund_name, value: item.fund_id }))}
                  loading={selectedFunds.length === 1 && !dataFundDetail}
                />
              </Col>
              <Col span={8}>
                <FormItemSelect
                  name="b2"
                  label="Benchmark 2"
                  placeholder="Select Benchmark 2"
                  options={dataBenchmark.map((item) => ({ text: item.fund_name, value: item.fund_id }))}
                  loading={selectedFunds.length === 1 && !dataFundDetail}
                />
              </Col>
              <Col span={8}>
                <FormItemSelect
                  name="b3"
                  label="Benchmark 3"
                  placeholder="Select Benchmark 3"
                  options={dataBenchmark.map((item) => ({ text: item.fund_name, value: item.fund_id }))}
                  loading={selectedFunds.length === 1 && !dataFundDetail}
                />
              </Col>
            </Row>
            <Row gutter={8}>
              {LIST_FORM.map((item) => {
                return (
                  <Col span={8} key={item.name}>
                    <FormItemSelect
                      name={item.name}
                      label={item.label}
                      placeholder={`Select ${item.label}`}
                      options={item.options}
                      loading={selectedFunds.length === 1 && !dataFundDetail}
                    />
                  </Col>
                )
              })}
            </Row>
          </>
        ) : (
          <Spin />
        )}
      </Form>
    </Modal>
  )
}
