import { EditOutlined } from '@ant-design/icons'
import { Button, Col, Form, notification, Popconfirm, Row, Tooltip } from 'antd'
import Table, { ColumnProps } from 'antd/lib/table'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../../../shared/api'
import { FundDetailDataContext } from '../../../../shared/api/context/FundDetailDataContext'
import { DueDiligenceScoreCard } from '../../../../shared/api/models/FundDetails'
import { FormItemDatePicker, FormItemInput } from '../../../../shared/components/FormItemComponents'
import { NextBackButton } from '../../../../shared/components/NextBackButton'
import { useServiceState } from '../../../../shared/hooks/useServiceState'
import { ScorecardModal } from './ScorecardModal'
const Flex = styled.div`
  display: flex;
  flex: 1;
`
type Props = {}
export const ScoreCard: React.FC<Props> = () => {
  const { dataFundDetail } = React.useContext(FundDetailDataContext)

  const [form] = Form.useForm()

  const { invoke: getDataScorecard, data: dataScorecard, loading } = useServiceState(
    APIService.fundDetailService.fetchDueDiligenceScoreCard,
  )

  const [showModal, setShowModal] = React.useState(false)
  const [loadingDelete, setLoadingDelete] = React.useState(false)
  const [selectedScorecard, setSelectedScorecard] = React.useState<DueDiligenceScoreCard | undefined>(undefined)

  React.useEffect(() => {
    dataFundDetail?.fund_id && getDataScorecard(dataFundDetail.fund_id)
  }, [dataFundDetail, getDataScorecard])

  React.useEffect(() => {
    if (dataScorecard) {
      const initialValue = {
        ...dataScorecard,
        last_update: dataScorecard.last_update ? moment(dataScorecard.last_update) : null,
      }
      form.setFieldsValue(initialValue)
    } else {
      form.resetFields()
    }
  }, [dataScorecard, form])

  const resetScoreCard = () => {
    if (dataFundDetail?.fund_id) {
      setLoadingDelete(true)
      APIService.fundDetailService
        .resetDueDiligenceScoreCard(dataFundDetail.fund_id)
        .then(() => {
          notification.success({ message: 'Reset Score Card!' })
          getDataScorecard(dataFundDetail?.fund_id)
        })
        .catch((err) => {
          console.error({ err })
          notification.error({ message: 'Reset Score Card Failed!' })
        })
        .finally(() => {
          setLoadingDelete(false)
        })
    }
  }

  const updateScorecard = () => {
    getDataScorecard(dataFundDetail?.fund_id)
  }

  const columns: ColumnProps<DueDiligenceScoreCard>[] = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Operator ID',
      dataIndex: 'oper_id',
      key: 'oper_id',
    },
    {
      title: 'Date & Time',
      dataIndex: 'date_time',
      key: 'date_time',
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      key: 'weight',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (_, record) => (
        <>
          <Button
            type="link"
            style={{ padding: '4px 8px' }}
            onClick={() => {
              setShowModal(true)
              setSelectedScorecard(record)
            }}
          >
            <Tooltip title="Edit">
              <EditOutlined />
            </Tooltip>
          </Button>
        </>
      ),
    },
  ]
  return (
    <Flex style={{ flexDirection: 'column', marginTop: '1rem' }}>
      <Form form={form}>
        <Row>
          <Col span={10}>
            <FormItemDatePicker name="last_update" label="Last Update" labelCol={8} wrapperCol={12} disabled />
          </Col>
          <Col span={10}>
            <FormItemInput name="last_oper_id" label="By" labelCol={8} wrapperCol={12} disabled />
          </Col>
          <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Popconfirm title="Are you sure you want to reset ?" onConfirm={resetScoreCard}>
              <Button type="primary" style={{ marginBottom: 8, marginRight: 8 }} loading={loadingDelete}>
                Reset
              </Button>
            </Popconfirm>
          </Col>
        </Row>
        <Table
          rowKey="oper_id"
          dataSource={dataScorecard?.scorecards}
          bordered
          columns={columns}
          loading={loading}
          pagination={dataScorecard?.scorecards && dataScorecard?.scorecards.length > 10 ? undefined : false}
        />
        {selectedScorecard && dataFundDetail?.fund_id && (
          <ScorecardModal
            fundId={dataFundDetail?.fund_id}
            updateScorecard={updateScorecard}
            selectedScorecard={selectedScorecard}
            setSelectedScorecard={setSelectedScorecard}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        )}
        <NextBackButton />
      </Form>
    </Flex>
  )
}
