import { Col, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'
import { FundDetailDataContext } from '../../../../../shared/api/context/FundDetailDataContext'
import { FundDetailDropdownContext } from '../../../../../shared/api/context/FundDetailDropdownContext'
import { DividerForm } from '../../../../../shared/components/DividerForm'
import { FormItemCheckBox, FormItemSelect } from '../../../../../shared/components/FormItemComponents'
import { RegionsTable } from '../../../../../shared/components/RegionsTable'
import { RenderLabelForm } from '../../../../../shared/components/RenderLabelForm'
const Wrapper = styled.div`
  display: 'flex';
  flex: 1;
`

export const EMERGING_MARKET_SET = ['10', '3', '7', '13', '5', '15']
type Props = { data: string[]; form: FormInstance }
export const FundInfoRegion: React.FC<Props> = ({ data, form }) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)
  const { setDataFundDetailChange, dataFundDetailChange } = React.useContext(FundDetailDataContext)
  const selectedRegion = form.getFieldValue('region_inv_focus')
  const regionCountryOptions = React.useMemo(() => {
    if (!fundDataDropdown?.region_inv_focus_country || fundDataDropdown?.region_inv_focus_country.length === 0) {
      return []
    }

    const options = selectedRegion
      ? fundDataDropdown.region_inv_focus_country.filter(
          (item) => item.code_code.slice(0, selectedRegion.length) === selectedRegion,
        )
      : fundDataDropdown.region_inv_focus_country
    return options.map((item) => ({ value: item.code_code, text: item.code_description }))
  }, [fundDataDropdown?.region_inv_focus_country, selectedRegion])

  React.useEffect(() => {
    if (dataFundDetailChange?.current && Object.keys(dataFundDetailChange?.current).includes('region_inv_focus')) {
      if (
        (selectedRegion &&
          form.getFieldValue('region_inv_focus_country') &&
          regionCountryOptions.length > 0 &&
          regionCountryOptions.findIndex((item) => item.value === form.getFieldValue('region_inv_focus_country')) ===
            -1) ||
        (!selectedRegion && form.getFieldValue('region_inv_focus_country'))
      ) {
        form.setFieldsValue({ region_inv_focus_country: null })
        setDataFundDetailChange((prev) => ({ ...prev, region_inv_focus_country: null }))
      }
    }
  }, [dataFundDetailChange, form, regionCountryOptions, selectedRegion, setDataFundDetailChange])

  const LIST_FIELD = [
    {
      name: 'region_inv_focus',
      label: 'Regional Investment Focus',
      options:
        fundDataDropdown?.region_inv_focus && fundDataDropdown?.region_inv_focus.length > 0
          ? fundDataDropdown.region_inv_focus.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
    },
    {
      name: 'region_inv_focus_country',
      label: 'Sub-Region',
      options: regionCountryOptions,
      disable: !selectedRegion,
    },
    {
      name: 'is_emerging_markets_fund',
      label: 'Is Emerging Markets',
      isCheckBox: true,
    },
  ]

  const regionValue = form.getFieldValue('region_inv_focus')

  React.useEffect(() => {
    if (dataFundDetailChange?.current && Object.keys(dataFundDetailChange?.current).includes('region_inv_focus')) {
      if (EMERGING_MARKET_SET.includes(regionValue)) {
        form.setFieldsValue({ is_emerging_markets_fund: true })
        setDataFundDetailChange((prev) => ({ ...prev, is_emerging_markets_fund: true }))
      } else {
        setDataFundDetailChange((prev) => ({ ...prev, is_emerging_markets_fund: false }))
        form.setFieldsValue({ is_emerging_markets_fund: false })
      }
    }
  }, [dataFundDetailChange, form, regionValue, setDataFundDetailChange])

  return (
    <Wrapper>
      <Row style={{ margin: '0 2rem', marginTop: '1rem' }} gutter={8}>
        {LIST_FIELD.map((item) => {
          return item.isCheckBox ? (
            <Col span={8} xl={8} md={12} xs={24} key={item.name}>
              <Row justify="center">
                <Col span={12}>
                  <FormItemCheckBox name={item.name} text={<RenderLabelForm value={item.label} />} disable />
                </Col>
              </Row>
            </Col>
          ) : (
            <Col span={8} xl={8} md={12} xs={24} key={item.name}>
              <FormItemSelect
                name={item.name}
                label={<RenderLabelForm value={item.label} />}
                options={item.options || []}
                labelCol={12}
                wrapperCol={12}
                disabled={item.disable}
              />
            </Col>
          )
        })}
      </Row>
      <DividerForm />

      <RegionsTable dataDropdown={data} />
    </Wrapper>
  )
}
