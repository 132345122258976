import { Button, Col, Row, Select } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../../../app/rootReducer'
import APIService from '../../../../shared/api'
import { FundDetailDataContext, PERFORMANCE_REQUEST_ENUM } from '../../../../shared/api/context/FundDetailDataContext'
import { FundDetailDropdownContext } from '../../../../shared/api/context/FundDetailDropdownContext'
import DataSheet, { DataSheetType } from '../../../../shared/components/DataSheet'
import { DividerForm } from '../../../../shared/components/DividerForm'
import { FormItemSelect } from '../../../../shared/components/FormItemComponents'
import { RenderLabelForm } from '../../../../shared/components/RenderLabelForm'
import { useServiceState } from '../../../../shared/hooks/useServiceState'
import { resetROR } from '../../../../slice/appEventSlice'
import HistoricalData from '../HistoricalData'

const Flex = styled.div`
  display: flex;
  flex: 1;
`
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 1rem;
  flex-direction: column;
`

export interface UpdateRORObjectType {
  [key: string]: {
    value: string
    orginalValue: string
  }
}

type Props = {}

export const FundROR: React.FC<Props> = () => {
  const { fundDataDropdown, dataBenchmark } = React.useContext(FundDetailDropdownContext)
  const { dataFundDetail } = React.useContext(FundDetailDataContext)
  const { dataROR, rorDataChange, dataRORLoading, setRORDataChange, getPerformanceRequest } = React.useContext(
    FundDetailDataContext,
  )
  const [selectedHistorical, setSelectedHistorical] = React.useState<number>()
  const [selectedDate, setSelectedDate] = React.useState<string>()

  const { invoke: getLogEvent, data: dataLogEvent } = useServiceState(APIService.activityLogService.fetchDataEvent)

  const { fundRorUpdated } = useSelector((state: RootState) => state.appEvent)
  const dispatch = useDispatch()

  React.useEffect(() => {
    getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.ROR)
  }, [getPerformanceRequest])

  // refetch events when flag `fundRorUpdated` is true, triggered by successfully update Fund ROR or Latest Performance
  React.useEffect(() => {
    if (!dataFundDetail || !dataFundDetail.fund_id) {
      return
    }
    if (fundRorUpdated) {
      getLogEvent({
        where: {
          code: 'UPDATE_FUND_RORS',
          fund_id: { equals: dataFundDetail.fund_id },
        },
        limit: 100,
        page: 1,
        order_by: 'DESC',
        sort_by: 'created_date',
        show_json_data: true,
      })
      dispatch(resetROR())
    }
  }, [dataFundDetail, dispatch, fundRorUpdated, getLogEvent])

  React.useEffect(() => {
    if (!dataFundDetail?.fund_id) {
      return
    }
    getLogEvent({
      where: {
        code: 'UPDATE_FUND_RORS',
        fund_id: { equals: dataFundDetail.fund_id },
      },
      limit: 100,
      page: 1,
      order_by: 'DESC',
      sort_by: 'created_date',
      show_json_data: true,
    })
  }, [dataFundDetail?.fund_id, getLogEvent])

  return (
    <Wrapper>
      <Flex style={{ flexDirection: 'column' }}>
        <DividerForm text="Benchmarks" notMargin />
        <Row>
          <Col span={8} xl={8} md={12} xs={24}>
            <FormItemSelect
              name="benchmark1"
              labelCol={0}
              wrapperCol={22}
              placeholder="Select Benchmark 1"
              options={
                dataBenchmark && dataBenchmark.length > 0
                  ? dataBenchmark.map((item) => ({ value: item.fund_id, text: item.fund_name }))
                  : []
              }
            />
          </Col>
          <Col span={8} xl={8} md={12} xs={24}>
            <FormItemSelect
              name="benchmark2"
              labelCol={0}
              wrapperCol={22}
              placeholder="Select Benchmark 2"
              options={
                dataBenchmark && dataBenchmark.length > 0
                  ? dataBenchmark.map((item) => ({ value: item.fund_id, text: item.fund_name }))
                  : []
              }
            />
          </Col>
          <Col span={8} xl={8} md={12} xs={24}>
            <FormItemSelect
              name="benchmark3"
              labelCol={0}
              wrapperCol={22}
              placeholder="Select Benchmark 3"
              options={
                dataBenchmark && dataBenchmark.length > 0
                  ? dataBenchmark.map((item) => ({ value: item.fund_id, text: item.fund_name }))
                  : []
              }
            />
          </Col>
        </Row>

        <DividerForm text="Reporting" notMargin />

        <Row>
          <Col span={8} xl={12} md={12} xs={24}>
            <FormItemSelect
              label={<RenderLabelForm value="Reporting Style" />}
              name="reporting_style"
              labelCol={8}
              wrapperCol={16}
              options={
                fundDataDropdown?.reporting_style && fundDataDropdown.reporting_style.length > 0
                  ? fundDataDropdown.reporting_style.map((item) => ({
                      value: item,
                      text: item,
                    }))
                  : []
              }
            />
          </Col>
          <Col span={8} xl={12} md={12} xs={24}>
            <FormItemSelect
              name="reporting_frequency"
              label={<RenderLabelForm value="Req. Freq" />}
              labelCol={8}
              wrapperCol={16}
              options={
                fundDataDropdown?.reporting_frequency && fundDataDropdown.reporting_frequency.length > 0
                  ? fundDataDropdown.reporting_frequency.map((item) => ({
                      value: item.code_code,
                      text: item.code_description,
                    }))
                  : []
              }
            />
          </Col>
        </Row>

        <Flex style={{ flexDirection: 'column' }}>
          <DividerForm text="ROR List" notMargin />
        </Flex>

        <Row style={{ margin: '8px 0' }}>
          <Col span={8}>
            <Select
              style={{ width: '100%' }}
              placeholder="Select Historical Snapshot Data"
              value={selectedHistorical}
              onChange={(data) => {
                setSelectedHistorical(data ? +data : undefined)
              }}
              allowClear
            >
              {dataLogEvent?.events.map((event) => (
                <Select.Option key={event.id} value={event.id}>
                  <b>{event.user__username}</b> - Updated at {event.created_date}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={2}>
            {selectedHistorical && (
              <Button type="link" onClick={() => setSelectedHistorical(undefined)}>
                Show current
              </Button>
            )}
          </Col>
        </Row>

        <Row style={{ marginTop: 8, position: 'relative' }}>
          {selectedHistorical ? (
            <DataSheet
              currencyValue={dataFundDetail?.denomination ? dataFundDetail.denomination : undefined}
              type={DataSheetType.ROR}
              isReadOnly
              initialData={dataLogEvent?.events.find((item) => item.id === selectedHistorical)?.json_data.values}
            />
          ) : (
            <DataSheet
              currencyValue={dataFundDetail?.denomination ? dataFundDetail.denomination : undefined}
              minDate={dataFundDetail?.inception_date ? dataFundDetail.inception_date : undefined}
              type={DataSheetType.ROR}
              draft={rorDataChange.values}
              initialData={dataROR}
              loading={dataRORLoading}
              canAdd
              setRORDataChange={setRORDataChange}
              onCellSelect={setSelectedDate}
            />
          )}
        </Row>
        {dataFundDetail?.fund_id && (
          <Row>
            <Col span={24}>
              <HistoricalData
                fundId={dataFundDetail.fund_id}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                type={PERFORMANCE_REQUEST_ENUM.ROR}
              />
            </Col>
          </Row>
        )}
      </Flex>
    </Wrapper>
  )
}
