import { Col, Form, Modal, notification, Row, Space, Spin } from 'antd'
import React from 'react'
import APIService from '../../shared/api'
import { useServiceState } from '../../shared/hooks/useServiceState'
import styled from 'styled-components'
import { FormItemCheckBox, FormItemRadio } from '../../shared/components/FormItemComponents'
import { RenderLabelExFirm } from '../../shared/components/RenderLabelExFirm'
import { FundDropdownType } from '../../shared/api/models/FundDetails'
import { ExFirmDetails } from '../../shared/api/models/FirmDetails'
import { useForm } from 'antd/lib/form/util'
import axios from 'axios'

const WrapperMinority = styled.div`
  padding: 1rem;
`

type Props = {
  showModal: boolean
  onCancel: () => void
  fundDataDropdown?: FundDropdownType
  selectedFirm: ExFirmDetails
}
export const ModalDiversityInclusion: React.FC<Props> = ({ showModal, onCancel, fundDataDropdown, selectedFirm }) => {
  const [form] = useForm()

  const [loadingSubmit, setLoadingSubmit] = React.useState(false)
  const [isMinorityOwnedFirm, setIsMinorityOwnedFirm] = React.useState<boolean>()
  // Minority Types
  const { invoke: getAllMinorityTypes, data: minorityTypes, loading: loadingMinorityTypes } = useServiceState(
    APIService.minorityService.fetchMinorityTypes,
  )

  React.useEffect(() => {
    const countryCode = selectedFirm.country
    if (!countryCode) {
      return
    }
    getAllMinorityTypes(countryCode)
  }, [selectedFirm.country, getAllMinorityTypes])

  // Update all minority_type_ when isMinorityOwnedFirm changed
  React.useEffect(() => {
    if (!minorityTypes || !minorityTypes.length) {
      return
    }
    if (isMinorityOwnedFirm === false) {
      minorityTypes?.map((item) => {
        form.setFieldsValue({ [`minority_type_${item.id}`]: false })
      })
    }
  }, [form, isMinorityOwnedFirm, minorityTypes])

  const { firm_name: firmName } = selectedFirm

  const firmCountry = fundDataDropdown?.firm_contact_country.find(
    (item) => item.code_code === `${selectedFirm.country}`,
  )

  const handleSubmit = () => {
    if (!minorityTypes) {
      return
    }
    const formData = form.getFieldsValue()
    //
    const { is_minority_or_woman_owned_firm, ...dataMinority } = formData
    const minorityChange = {
      updates: minorityTypes.map((item) => {
        return {
          minority_type: +item.id,
          status: !!dataMinority[`minority_type_${item.id}`],
        }
      }),
    }
    const { firm_id } = selectedFirm
    //
    setLoadingSubmit(true)
    Promise.all([
      APIService.firmDetailService.updateFirmInfo(firm_id, {
        is_minority_or_woman_owned_firm,
      }),
      APIService.minorityService.updateFirmMinorityStatuses(firm_id, minorityChange),
    ])
      .then(() => {
        notification.success({ message: 'Diversity & Inclusion Updated!' })
        onCancel()
      })
      .catch((err) => {
        notification.error({
          message: `Update Failed! ${axios.isAxiosError(err) ? err.response && err.response.statusText : err}`,
        })
      })
      .finally(() => {
        setLoadingSubmit(false)
      })
  }

  const isLoading = loadingMinorityTypes || !fundDataDropdown
  return (
    <Form form={form}>
      <Modal
        title={`Firm Diversity & Inclusion - ${firmName}`}
        visible={showModal}
        okText={loadingSubmit ? <Spin /> : 'Submit'}
        okButtonProps={{
          disabled: !form.isFieldsTouched(),
        }}
        onOk={handleSubmit}
        onCancel={onCancel}
        width="80vw"
      >
        <Spin spinning={isLoading}>
          <div style={{ padding: '0 1rem', height: 600, overflow: 'auto' }}>
            <Space direction="vertical">
              <Row>
                <Col span={24}>
                  <h3>Minority- or Woman-Owned Management Company</h3>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <i>
                    Applicable only to the management firm that is physically headquartered in{' '}
                    {firmCountry === undefined ? '[Firm_Country]' : firmCountry.code_description}
                  </i>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span>
                    Minority- or Woman-Owned: A hedge fund management company is deemed to be a minority- or woman-owned
                    business if: Greater than 50% of the management company is owned by one or more minorities or women,
                    or in the case of a publicly owned business, greater than 50% of all issued stock is owned by one or
                    more minorities or women; and whose management and daily business operations are controlled by one
                    or more such individuals.
                  </span>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormItemRadio
                    name="is_minority_or_woman_owned_firm"
                    label={
                      <RenderLabelExFirm value="Is the management company owned, operated, or controlled by one or more minorities or women?" />
                    }
                    options={[
                      {
                        value: true,
                        title: 'Yes',
                      },
                      {
                        value: false,
                        title: 'No',
                      },
                    ]}
                    getValueFromEvent={(val) => {
                      const newVal = val.target.value
                      setIsMinorityOwnedFirm(newVal)
                      return newVal
                    }}
                  />
                </Col>
              </Row>
              <Row>
                {isMinorityOwnedFirm ? (
                  <Col span={24}>
                    <span>
                      <i>If ‘Yes’, please check all that apply:</i>
                    </span>
                  </Col>
                ) : null}
              </Row>
              {isMinorityOwnedFirm ? (
                <WrapperMinority>
                  <Row>
                    <Col span={10}>
                      <b>Type</b>
                    </Col>
                    <Col span={12}>
                      <b>Description</b>
                    </Col>
                  </Row>
                  {minorityTypes?.map((item, index) => {
                    return (
                      <Row key={index} style={{ margin: '5px 0' }}>
                        <Col span={10}>
                          <FormItemCheckBox
                            disable={!isMinorityOwnedFirm}
                            name={`minority_type_${item.id}`}
                            text={item.minority_type}
                          />
                        </Col>
                        <Col span={12}>{item.minority_description}</Col>
                      </Row>
                    )
                  })}
                </WrapperMinority>
              ) : null}
            </Space>
          </div>
        </Spin>
      </Modal>
    </Form>
  )
}
