import { Col, Divider, Modal, Row, Table, Typography } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import ReactPerfectScrollbar from 'react-perfect-scrollbar'
import styled from 'styled-components'
import APIService from '../../shared/api'
import { Benchmarks, YearTableRowRecord } from '../../shared/api/models/ResponsesTypes'
import { LoadingDetails } from '../../shared/components/LoadingDetails'
import { useServiceState } from '../../shared/hooks/useServiceState'
import BenchmarkOptions from './BenchmarkOptions'
import VamiGraph from './VamiGraph'

const { Title } = Typography

const StyledModal = styled(Modal)`
  width: 90vh;
  .ant-modal-content {
    max-height: 85vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .ant-modal-body {
    overflow-y: auto;
    flex: 1;
  }
`

const Header = styled(Title)`
  position: relative;
  text-align: center;
  height: auto;
  margin: 0.5rem 0 1.5rem 0;
  display: block;
`

const TableWrapper = styled('div')`
  td,
  th {
    padding: 16px 4px !important;
  }
`

const StyledRow = styled(Row)`
  width: 100%;
  height: 50px;
  padding: 10px 5px;
`

const StyledCol = styled(Col)`
  font-weight: 200;
  padding-top: 12px;
`

/**
 * This component renders table cells based on its value ('-' for undefined and red for negative value)
 * @param val
 * @constructor
 */
const RenderTableCell = (val: number) => {
  if (val === null || val === undefined) {
    return <span> - </span>
  }
  return val < 0 ? (
    <span style={{ color: 'red' }}> {val.toLocaleString(undefined, { minimumFractionDigits: 2 })}</span>
  ) : (
    <span> {val.toLocaleString(undefined, { minimumFractionDigits: 2 })} </span>
  )
}

type Props = {
  isOpen: boolean
  onRequestClose: () => void
  groupCode: number
}

const CalculateModal: React.FC<Props> = ({ isOpen, onRequestClose, groupCode }) => {
  const { invoke: getStats, data: dataStats, loading } = useServiceState(APIService.analysisService.fetchGroupStats)

  const { invoke: getVAMI, data: dataVAMI } = useServiceState(APIService.analysisService.peerGroupVAMI)
  const [benchmarks, setBenchmarks] = React.useState<Benchmarks>({
    benchmark1: '2899',
    benchmark2: '2898',
    benchmark3: '50141',
  })

  React.useEffect(() => {
    getStats(groupCode)
  }, [getStats, groupCode])

  React.useEffect(() => {
    if (benchmarks.benchmark1 !== '' && benchmarks.benchmark2 !== '' && benchmarks.benchmark3 !== '') {
      getVAMI({ groupCode, b1: benchmarks.benchmark1, b2: benchmarks.benchmark2, b3: benchmarks.benchmark3 })
    }
  }, [benchmarks, getVAMI, groupCode])

  const columns: ColumnProps<any>[] = [
    { title: 'Year', dataIndex: 'year', key: 'year', width: 100 },
    {
      title: 'Jan',
      dataIndex: '001',
      key: '001',
      width: 100,
      render: RenderTableCell,
    },
    {
      title: 'Feb',
      dataIndex: '002',
      key: '002',
      width: 100,
      render: RenderTableCell,
    },
    {
      title: 'Mar',
      dataIndex: '003',
      key: '003',
      width: 100,
      render: RenderTableCell,
    },
    {
      title: 'Apr',
      dataIndex: '004',
      key: '004',
      width: 100,
      render: RenderTableCell,
    },
    {
      title: 'May',
      dataIndex: '005',
      key: '005',
      width: 100,
      render: RenderTableCell,
    },
    {
      title: 'Jun',
      dataIndex: '006',
      key: '006',
      width: 100,
      render: RenderTableCell,
    },
    {
      title: 'Jul',
      dataIndex: '007',
      key: '007',
      width: 100,
      render: RenderTableCell,
    },
    {
      title: 'Aug',
      dataIndex: '008',
      key: '008',
      width: 100,
      render: RenderTableCell,
    },
    {
      title: 'Sep',
      dataIndex: '009',
      key: '009',
      width: 100,
      render: RenderTableCell,
    },
    {
      title: 'Oct',
      dataIndex: '010',
      key: '010',
      width: 100,
      render: RenderTableCell,
    },
    {
      title: 'Nov',
      dataIndex: '011',
      key: '011',
      width: 100,
      render: RenderTableCell,
    },
    {
      title: 'Dec',
      dataIndex: '012',
      key: '012',
      width: 100,
      render: RenderTableCell,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: 100,
      render: RenderTableCell,
    },
  ]
  const dataSource: YearTableRowRecord[] = []

  if (dataStats) {
    for (const yearlyRecord of dataStats.hypothetical_performance_history) {
      const record: YearTableRowRecord = {} as YearTableRowRecord
      record.year = `${yearlyRecord.year}`
      record.total = `${yearlyRecord.rors
        .reduce((s, ror) => s + ror.ror, 0)
        .toLocaleString(undefined, { maximumFractionDigits: 2 })}`
      for (const ror of yearlyRecord.rors) {
        record[String(ror.month).padStart(3, '0')] = ror.ror.toFixed(2)
      }
      dataSource.push(record)
    }
  }

  return (
    <StyledModal
      width="90vw"
      style={{ width: '90vw', maxHeight: '85vh', overflow: 'hidden' }}
      title="Parameters"
      visible={isOpen}
      onCancel={onRequestClose}
      onOk={onRequestClose}
    >
      {dataStats && (
        <ReactPerfectScrollbar>
          <Row style={{ display: 'flex', flexDirection: 'column' }}>
            <Header level={4}>Hypothetical Performance History </Header>
            <TableWrapper>
              <Table dataSource={dataSource} columns={columns} pagination={false} />
            </TableWrapper>
            <Header id="analytics" level={4}>
              Analytics
            </Header>
            <Row>
              <Col span={12} style={{ padding: '8px' }} className="headerCol">
                <Row>
                  <StyledCol span={8}>Annualized Ror</StyledCol>
                  <Col span={1} style={{ padding: '10px 0px' }}>
                    <Divider style={{ height: 20, backgroundColor: '#ece9e9' }} type="vertical" />
                  </Col>
                  <Col span={15}>
                    <StyledRow> {dataStats.annualized_ror} % </StyledRow>
                  </Col>
                </Row>
                <Row>
                  <StyledCol span={8}>Annualized Standard Deviation</StyledCol>
                  <Col span={1} style={{ padding: '10px 0px' }}>
                    <Divider style={{ height: 20, backgroundColor: '#ece9e9' }} type="vertical" />
                  </Col>
                  <Col span={15}>
                    <StyledRow> {dataStats.annualized_standard_deviation} %</StyledRow>
                  </Col>
                </Row>
                <Row>
                  <StyledCol span={8}>Annualized Monthly Ror</StyledCol>
                  <Col span={1} style={{ padding: '10px 0px' }}>
                    <Divider style={{ height: 20, backgroundColor: '#ece9e9' }} type="vertical" />
                  </Col>
                  <Col span={15}>
                    <StyledRow> {dataStats.average_monthly_ror} %</StyledRow>
                  </Col>
                </Row>
                <Row>
                  <StyledCol span={8}>Standard Deviation</StyledCol>
                  <Col span={1} style={{ padding: '10px 0px' }}>
                    <Divider style={{ height: 20, backgroundColor: '#ece9e9' }} type="vertical" />
                  </Col>
                  <Col span={15}>
                    <StyledRow> {dataStats.standard_deviation} %</StyledRow>
                  </Col>
                </Row>
              </Col>
              <Col span={12} style={{ padding: '8px' }} className="headerCol">
                <Row>
                  <StyledCol span={8}>Winning Months</StyledCol>
                  <Col span={1} style={{ padding: '10px 0px' }}>
                    <Divider style={{ height: 20, backgroundColor: '#ece9e9' }} type="vertical" />
                  </Col>
                  <Col span={15}>
                    <StyledRow> {dataStats.winning_months} %</StyledRow>
                  </Col>
                </Row>
                <Row>
                  <StyledCol span={8}>High Month</StyledCol>
                  <Col span={1} style={{ padding: '10px 0px' }}>
                    <Divider style={{ height: 20, backgroundColor: '#ece9e9' }} type="vertical" />
                  </Col>
                  <Col span={15}>
                    <StyledRow> {dataStats.high_month} %</StyledRow>
                  </Col>
                </Row>
                <Row>
                  <StyledCol span={8}>Low Month</StyledCol>
                  <Col span={1} style={{ padding: '10px 0px' }}>
                    <Divider style={{ height: 20, backgroundColor: '#ece9e9' }} type="vertical" />
                  </Col>
                  <Col span={15}>
                    <StyledRow> {dataStats.low_month} %</StyledRow>
                  </Col>
                </Row>
                <Row>
                  <StyledCol span={8}>Sharpe Ratio</StyledCol>
                  <Col span={1} style={{ padding: '10px 0px' }}>
                    <Divider style={{ height: 20, backgroundColor: '#ece9e9' }} type="vertical" />
                  </Col>
                  <Col span={15}>
                    <StyledRow> {dataStats.sharpe_ratio} %</StyledRow>
                  </Col>
                </Row>
              </Col>
            </Row>
            <BenchmarkOptions benchmarks={benchmarks} handleChange={setBenchmarks} />
            <VamiGraph vami={dataVAMI ? dataVAMI : { fund: [], b1: [], b2: [], b3: [] }} loading={false} />
          </Row>
        </ReactPerfectScrollbar>
      )}
      {loading && <LoadingDetails name="Loading Stats" />}
    </StyledModal>
  )
}

export default CalculateModal
