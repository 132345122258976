import { AxiosInstance, AxiosPromise } from 'axios'

export interface AdminService {
  openAdmin: () => AxiosPromise<any>
}

export const AdminService = (request: AxiosInstance): AdminService => ({
  openAdmin: () =>
    request.get('/manager-link/admin').then((res) => {
      window.location = res.request.responseURL
      return res
    }),
})
