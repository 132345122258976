import { Col, Collapse, Form, Row, Select, Spin } from 'antd'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { useDebounce } from 'use-debounce/lib'
import APIService from '../../../../../shared/api'
import { FundDetailDataContext } from '../../../../../shared/api/context/FundDetailDataContext'
import { NextBackButton } from '../../../../../shared/components/NextBackButton'
import { RenderLabelForm } from '../../../../../shared/components/RenderLabelForm'
import { useServiceState } from '../../../../../shared/hooks/useServiceState'

const { Panel } = Collapse
const Flex = styled.div`
  display: flex;
  flex: 1;
`
type Props = {}
export const OffshoreFundInfo: React.FC<Props> = () => {
  const [searchText, setSearchText] = React.useState<string>('')

  const [debouncedText] = useDebounce(searchText, 500)
  const { data: funds, invoke: invokeSearch, loading } = useServiceState(APIService.fundService.fetchFundName)
  const { data: offshoreFund, invoke: fetchFundDetail } = useServiceState(
    APIService.fundDetailService.fetchFundBasicInfo,
  )
  const { dataFundDetail } = useContext(FundDetailDataContext)

  React.useEffect(() => {
    if (!dataFundDetail || !dataFundDetail.offshore_id) {
      return
    }
    fetchFundDetail(dataFundDetail.offshore_id)
  }, [dataFundDetail, fetchFundDetail])

  React.useEffect(() => {
    invokeSearch({ text: debouncedText })
  }, [debouncedText, invokeSearch])

  return (
    <Flex style={{ flexDirection: 'column' }}>
      <Collapse style={{ margin: '0 0.5rem' }} defaultActiveKey="1">
        <Panel header="Offshore Fund Information" key="1">
          <Row>
            <Col span={12} xl={12} lg={12} xs={24} offset={6} key={'offshore_id'}>
              <Form.Item name={'offshore_id'} label={<RenderLabelForm value={'Offshore Fund'} />}>
                <Select
                  showSearch
                  allowClear
                  loading={loading}
                  notFoundContent={loading ? <Spin size="small" /> : null}
                  placeholder="Type Fund Name To Search"
                  filterOption={false}
                  onSearch={(e) => {
                    setSearchText(e)
                  }}
                >
                  {funds?.map((item) => {
                    return (
                      <Select.Option value={item.fund_id} key={item.fund_id}>
                        Fund #{item.fund_id} - {item.fund_name}
                      </Select.Option>
                    )
                  })}
                  {!funds?.some((i) => i.fund_id === offshoreFund?.fund_id) && offshoreFund && (
                    <Select.Option value={offshoreFund.fund_id} key={offshoreFund.fund_id}>
                      Fund #{offshoreFund.fund_id} - {offshoreFund.fund_name}
                    </Select.Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      <NextBackButton />
    </Flex>
  )
}
