import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../app/store'
import APIService from '../shared/api'
import { FundDetailStrategy, FundDetailSubStrategy, FundESGStrategies } from '../shared/api/models/FundDetails'
import { BenchmarkRecord } from '../shared/api/models/ResponsesTypes'

interface AppDataState {
  strategies: FundDetailStrategy[]
  subStrategies: FundDetailSubStrategy[]
  benchmarks: BenchmarkRecord[]
  esgStrategies: FundESGStrategies['esg_strategies']
  esgSubStrategies: FundESGStrategies['esg_sub_strategies']
  esgSubStrategyDropdown: FundESGStrategies['esg_sub_strategy_dropdown']
  // regionalFocus: FundDetailRegionalFocus[]
  // fundList: ExtendFundListRecord[]
  // universeList: ExtendFundListRecord[]
  // hfrStaticIndexes: FundListRecord[]
  // hfrDynamicIndexes: FundListRecord[]
  // portfolioModels: PortfolioModelRecord[]
}

const initialState: Partial<AppDataState> = {}

const appData = createSlice({
  name: 'appData',
  initialState,
  reducers: {
    fetchAppDataSuccess: (state, action: PayloadAction<{ key: keyof AppDataState; data: any }>) => {
      return { ...state, [action.payload.key]: action.payload.data }
    },
  },
})

const { reducer, actions } = appData

export const { fetchAppDataSuccess } = actions
export default reducer

export const fetchAppData = (): AppThunk => async (dispatch) => {
  const {
    fetchStrategies,
    fetchBenchmarks,
    // fetchRegionalFocus,
    // fetchDynamicIndexes,
    // fetchStaticIndexes,
  } = APIService.appDataService
  // const { fetchPortfolioModels } = APIService.portfolioModelService
  // const { fetchFundList, fetchUniverseList } = APIService.fundUniverseListService
  const { fetchESGStrategies } = APIService.fundDetailService

  try {
    fetchStrategies().then(({ data }) => {
      dispatch(fetchAppDataSuccess({ key: 'strategies', data: data.strategies }))
      dispatch(fetchAppDataSuccess({ key: 'subStrategies', data: data.sub_strategies }))
      fetchBenchmarks().then(({ data }) => dispatch(fetchAppDataSuccess({ key: 'benchmarks', data })))
      // fetchRegionalFocus().then(({ data }) => dispatch(fetchAppDataSuccess({ key: 'regionalFocus', data })))
      // fetchDynamicIndexes().then(({ data }) => dispatch(fetchAppDataSuccess({ key: 'hfrDynamicIndexes', data })))
      // fetchStaticIndexes().then(({ data }) => dispatch(fetchAppDataSuccess({ key: 'hfrStaticIndexes', data })))
      // fetchPortfolioModels().then(({ data }) => dispatch(fetchAppDataSuccess({ key: 'portfolioModels', data })))
      // // fundList, universeList
      // fetchFundList().then(({ data }) => dispatch(fetchAppDataSuccess({ key: 'fundList', data })))
      // fetchUniverseList().then(({ data }) => dispatch(fetchAppDataSuccess({ key: 'universeList', data })))
    })

    fetchESGStrategies().then(({ data }) => {
      dispatch(fetchAppDataSuccess({ key: 'esgStrategies', data: data.esg_strategies }))
      dispatch(fetchAppDataSuccess({ key: 'esgSubStrategies', data: data.esg_sub_strategies }))
      dispatch(fetchAppDataSuccess({ key: 'esgSubStrategyDropdown', data: data.esg_sub_strategy_dropdown }))
    })
  } catch (err) {
    console.info(err.toString())
  }
}
