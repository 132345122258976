import { Checkbox, Col, Divider, Form, Input, notification, Row, Spin } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../shared/api'
import { IndexManagerType } from '../../shared/api/models/IndexManager'

const Wrapper = styled('div')``

type Props = {
  selectedIndexManager?: IndexManagerType
  showFormModal: boolean
  setShowFormModal: (value: boolean) => void
  setSelectedIndexManager: (value: IndexManagerType | undefined) => void
  updateIndexManager: () => void
}

const IndexManagerFormModal: React.FC<Props> = ({
  selectedIndexManager,
  showFormModal,
  setShowFormModal,
  setSelectedIndexManager,
  updateIndexManager,
}) => {
  const [loadingSubmit, setLoadingSubmit] = React.useState(false)

  const [form] = Form.useForm()

  React.useEffect(() => {
    if (selectedIndexManager) {
      form.setFieldsValue(selectedIndexManager)
    } else {
      form.resetFields()
    }
  }, [selectedIndexManager, form])

  const submitFormIndexManager = () => {
    form.validateFields(['name', 'description']).then(() => {
      setLoadingSubmit(true)
      if (selectedIndexManager) {
        APIService.indexManagerService
          .updateIndexManager(
            selectedIndexManager.id,
            form.getFieldValue('name'),
            form.getFieldValue('description'),
            form.getFieldValue('index_eligibility'),
          )
          .then(() => {
            notification.success({ message: 'Index Manager Updated!' })
            updateIndexManager()
          })
          .catch((err: any) => {
            console.error({ err })
            notification.error({ message: 'Index Manager Update Failed!' })
          })
          .finally(() => {
            form.resetFields()
            setLoadingSubmit(false)
            setShowFormModal(false)
            setSelectedIndexManager(undefined)
          })
        return
      }

      APIService.indexManagerService
        .createIndexManager(
          form.getFieldValue('name'),
          form.getFieldValue('description'),
          form.getFieldValue('index_eligibility'),
        )
        .then(() => {
          notification.success({ message: 'Index Manager Created!' })
          updateIndexManager()
        })
        .catch((err: any) => {
          console.error({ err })
          notification.error({ message: 'Index Manager Create Failed!' })
        })
        .finally(() => {
          form.resetFields()
          setLoadingSubmit(false)
          setShowFormModal(false)
          setSelectedIndexManager(undefined)
        })
    })
  }

  return (
    <Modal
      width={700}
      visible={showFormModal}
      title={selectedIndexManager ? 'Update Index Manager' : 'Add New Index Manager'}
      okText={
        loadingSubmit ? (
          <>
            <Spin style={{ marginRight: 4 }} />
          </>
        ) : (
          'OK'
        )
      }
      onOk={submitFormIndexManager}
      onCancel={() => {
        setShowFormModal(false)
        setSelectedIndexManager(undefined)
      }}
    >
      <Wrapper>
        <Form layout="vertical" form={form}>
          <Divider orientation="left">Information</Divider>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Name is required' }]}
                style={{ display: 'flex', flex: 1 }}
              >
                <Input placeholder="Name..." />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Description"
                rules={[{ required: true, message: 'Description is required' }]}
                style={{ display: 'flex', flex: 1 }}
              >
                <Input placeholder="Description..." />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="index_eligibility" valuePropName="checked" style={{ display: 'flex', flex: 1 }}>
                <Checkbox>Index Eligibility</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Wrapper>
    </Modal>
  )
}

export default IndexManagerFormModal
