import { Button, Result, Spin } from 'antd'
import 'antd/dist/antd.css'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from '../../app/rootReducer'
import APIService from '../../shared/api'
import { LoadingDetails } from '../../shared/components/LoadingDetails'

export const Admin: React.FC = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useSelector((state: RootState) => state.user)

  React.useEffect(() => {
    const showAdmin = async () => {
      try {
        setIsLoading(true)
        await APIService.adminService.openAdmin()
      } finally {
        setIsLoading(false)
      }
    }
    if (user?.is_staff) {
      showAdmin()
    }
  }, [user])

  if (isLoading) {
    return <LoadingDetails name="Redirecting to Admin" />
  }

  if (!user?.is_staff) {
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button onClick={() => history.push('/')} type="primary">
            Back Home
          </Button>
        }
      />
    )
  }
  return <Spin spinning={true} />
}
