import React from 'react'
import { ListSearchFirmType } from '../../SearchFirmLabel'

const defaultValue = {
  boldText: '',
  filterArr: [] as ListSearchFirmType[],
  isFiltering: false,
}
type Props = {
  text: string
  filterArr: ListSearchFirmType[]
}
export const FirmDetailLabelSearchContext = React.createContext(defaultValue)
export const FirmDetailLabelSearchProvider: React.FC<Props> = ({ children, text, filterArr }) => {
  const isFiltering = text === '' ? false : true
  const value = {
    boldText: text,
    filterArr,
    isFiltering,
  }
  return <FirmDetailLabelSearchContext.Provider value={value}>{children}</FirmDetailLabelSearchContext.Provider>
}
