import { AxiosPromise } from 'axios'
import * as React from 'react'

type InvokeType<T, Args> = Args extends undefined ? () => void : (params?: Args) => void

type ServiceState<T, Args> = {
  invoke: InvokeType<T, Args>
  data?: T
  error: string
  loading: boolean
}

type FuncType<T, Args> = Args extends undefined ? () => AxiosPromise<T> : (params: Args) => AxiosPromise<T>

export const useServiceState = <T, Args>(serviceFunction: FuncType<T, Args>): ServiceState<T, Args> => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string>('')
  const [data, setData] = React.useState<T>()

  const invoke = React.useCallback<InvokeType<T, Args>>(
    // @ts-ignore
    (params: Args) => {
      const f = async (params: Args) => {
        setLoading(true)
        let result
        try {
          result = await serviceFunction(params)
          setData(result.data)
        } catch (e) {
          setError(e)
        } finally {
          setLoading(false)
        }
      }
      f(params)
    },
    [serviceFunction],
  )

  return { invoke, data, error, loading }
}
