import { LeftOutlined, RightOutlined, TableOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { addTabs, FundFirmTypeEnum, PREFIX_EXTERNAL } from '../../slice/appSettingsSlice'
import ColumnBuilder from './ExColumnBuilder/ColumnBuilder'

const RightTitle = styled.div`
  margin-left: 8px;
`

type TableHeaderProps = {
  onShowSideBar: () => void
  showSidebar: boolean
}

const ExFundTableHeader: React.FC<TableHeaderProps> = ({ onShowSideBar, showSidebar }) => {
  const dispatch = useDispatch()

  const handleMenuClick = () => {
    dispatch(addTabs({ id: `${PREFIX_EXTERNAL}-new-fund`, name: 'Add New Fund', type: FundFirmTypeEnum.FUNDS }))
  }

  const [showColumnBuilder, setShowColumnBuilder] = React.useState(false)

  return (
    <Row>
      <Col span={3}>
        <Button onClick={onShowSideBar} style={{ marginRight: 16 }}>
          {showSidebar ? <LeftOutlined /> : <RightOutlined />}
        </Button>
        All Funds
      </Col>
      <Col span={21} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <RightTitle>
          <Button type="primary" onClick={handleMenuClick}>
            Add New Fund
          </Button>
          <Button type="default" onClick={() => setShowColumnBuilder(true)} style={{ marginLeft: 6 }}>
            <TableOutlined />
            Columns
          </Button>
        </RightTitle>
      </Col>
      {showColumnBuilder && <ColumnBuilder isOpen={showColumnBuilder} setOpen={setShowColumnBuilder} />}
    </Row>
  )
}

export default ExFundTableHeader
