import { createSlice } from '@reduxjs/toolkit'
import { notification } from 'antd'
import { AppThunk } from '../app/store'
import APIService from '../shared/api'

interface AuthState {
  isLoading: boolean
  accessToken: string | null
  error: string | null
}

const initialState: AuthState = {
  isLoading: false,
  accessToken: null,
  error: null,
}

function startLoading(state: AuthState) {
  state.isLoading = true
  state.error = null
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart: startLoading,
    loginSuccess: (state, action) => {
      state.isLoading = false
      state.accessToken = action.payload
    },
    loginFailure: (state, action) => {
      state.accessToken = null
      state.isLoading = false
      state.error = action.payload
    },
    logoutStart: startLoading,
    logoutSuccess: (state) => {
      state.accessToken = null
      state.isLoading = false
    },
    clearToken: (state) => {
      state.accessToken = null
    },
  },
})

const { reducer, actions } = authSlice

export const { loginStart, loginSuccess, loginFailure, logoutStart, logoutSuccess, clearToken } = actions
export default reducer

export const login = (email: string, password: string, code: string, rememberMe: boolean): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(loginStart())
    const loginRes = await APIService.authService.login(email, password, code, rememberMe)
    dispatch(saveToken(loginRes.data.token))
  } catch (err) {
    if (err.response.status === 403) {
      notification.error({ message: err.response.data.message })
      return
    }
    dispatch(loginFailure(err.response.status))
    notification.error({ message: err.response.data.message })
  }
}

export const saveToken = (token: string): AppThunk => async (dispatch) => {
  APIService.setToken(token)
  dispatch(loginSuccess(token))
}

export const logout = (): AppThunk => async (dispatch) => {
  try {
    dispatch(logoutStart())
    await APIService.authService.logout()
    dispatch(logoutSuccess())
    APIService.clearToken()
  } catch (err) {
    console.error(err.toString())
  }
}
