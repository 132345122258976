import { Form, Modal, notification, Select } from 'antd'
import React from 'react'
import APIService from '../../shared/api'
import { AnalysisGroupListContext } from '../../shared/api/context/AnalysisGroupListContext'
import { AnalysisType } from '../../shared/api/models/Analysis'
import { useServiceState } from '../../shared/hooks/useServiceState'

type Props = {
  isOpen: boolean
  onRequestClose: () => void
  selectedGroup: AnalysisType
}

const AssignUserModal: React.FC<Props> = ({ isOpen, onRequestClose, selectedGroup }) => {
  const { data, loading, invoke: fetchInternalUsers } = useServiceState<{ internal_users: string[] }, undefined>(
    APIService.userService.fetchInternalUsers,
  )
  const [selectedUser, setSelectedUser] = React.useState<string>(selectedGroup.username)
  const { refetchGroup } = React.useContext(AnalysisGroupListContext)
  const [submitting, setSubmitting] = React.useState(false)

  React.useEffect(() => {
    fetchInternalUsers()
  }, [fetchInternalUsers])

  const handleSubmit = () => {
    if (selectedUser === selectedGroup.username) {
      onRequestClose()
      return
    }
    setSubmitting(true)
    APIService.analysisService
      .updateGroup({ groupCode: selectedGroup.group_code, user: selectedUser })
      .then(() => {
        notification.success({ message: `Assign ${selectedGroup.group_name} to ${selectedUser}` })
        onRequestClose()
        refetchGroup()
      })
      .catch((err) => {
        notification.error({ message: `Failed to assign user to ${selectedGroup.group_name}!` })
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <Modal
      width={600}
      visible={isOpen}
      onCancel={onRequestClose}
      onOk={handleSubmit}
      confirmLoading={submitting}
      title={
        <div>
          Assign User to <b>{selectedGroup.group_name}</b>
        </div>
      }
    >
      <Form layout="vertical">
        <Form.Item label="Select User">
          <Select
            loading={loading}
            value={selectedUser}
            allowClear
            onChange={(value) => setSelectedUser(value)}
            options={data?.internal_users.map((item) => ({ value: item, label: item }))}
          ></Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AssignUserModal
