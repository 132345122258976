import { AxiosInstance, AxiosPromise } from 'axios'
import moment from 'moment'
import { PeerGroupFundBenchmarkResponse, PeerGroupUniverseStatsResponse } from '../models/ResponsesTypes'

export interface FetchPeerGroupPdfParams {
  benchmark1: string
  benchmark2: string
  benchmark3: string
  end_date: string
  universe_list_id: number[]
  fund_list_id: number[]
  portfolio_model_id: number[]
  static_index_ids: number[]
  dynamic_index_ids: number[]
  strategy?: number[]
  sub_strategy?: number[]
  regional_focus?: number[]
  in_HFRI?: boolean
  in_emerging_markets?: boolean
}

export interface PeerGroupAnalysisService {
  fetchUniverseStats: ({
    fundId,
    universeListId,
    fundListId,
    portfolioModelId,
    endDate,
    staticIndexIds,
    dynamicIndexIds,
    strategy,
    subStrategy,
    regionalFocus,
    inHFRI,
    inEmergingMarkets,
  }: {
    fundId: string
    universeListId?: string[]
    fundListId?: string[]
    portfolioModelId?: string[]
    endDate: string
    staticIndexIds: string[]
    dynamicIndexIds: string[]
    strategy: string[]
    subStrategy: string[]
    regionalFocus: string[]
    inHFRI: boolean
    inEmergingMarkets: boolean
  }) => AxiosPromise<PeerGroupUniverseStatsResponse>
  fetchFundAndBenchmark: ({
    fundId,
    benchmark1,
    benchmark2,
    benchmark3,
    endDate,
  }: {
    fundId: string
    benchmark1: string
    benchmark2: string
    benchmark3: string
    endDate: string | moment.Moment
  }) => AxiosPromise<PeerGroupFundBenchmarkResponse>
  fetchPeerGroupPdfReport: (fundId: string, data: FetchPeerGroupPdfParams) => AxiosPromise<Blob>
}

export const PeerGroupAnalysisService = (request: AxiosInstance): PeerGroupAnalysisService => {
  return {
    fetchUniverseStats: ({
      fundId,
      universeListId,
      fundListId,
      portfolioModelId,
      endDate,
      staticIndexIds,
      dynamicIndexIds,
      strategy,
      subStrategy,
      regionalFocus,
      inHFRI,
      inEmergingMarkets,
    }) =>
      request.get(`/funds-api/fund/${parseInt(fundId)}/peer-group-analysis/universe-stats`, {
        params: {
          manager_link: true,
          universe_list_id: universeListId,
          fund_list_id: fundListId,
          portfolio_model_id: portfolioModelId,
          end_date: endDate,
          static_index_ids: staticIndexIds,
          dynamic_index_ids: dynamicIndexIds,
          strategy,
          sub_strategy: subStrategy,
          regional_focus: regionalFocus,
          in_HFRI: inHFRI === undefined ? undefined : String(inHFRI),
          in_emerging_markets: inEmergingMarkets === undefined ? undefined : String(inHFRI),
        },
      }),
    fetchFundAndBenchmark: ({ fundId, benchmark1, benchmark2, benchmark3, endDate }) =>
      request.get(`/funds-api/fund/${parseInt(fundId)}/peer-group-analysis/fund-and-benchmark`, {
        params: {
          manager_link: true,
          benchmark1,
          benchmark2,
          benchmark3,
          end_date: endDate,
        },
      }),
    fetchPeerGroupPdfReport: (fundId, data: FetchPeerGroupPdfParams) =>
      request.post(
        `/funds-api/fund/${fundId}/peer-group-analysis/pdf`,
        { ...data, manager_link: true },
        {
          responseType: 'blob',
        },
      ),
  }
}
