import { List } from 'antd'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../../../shared/api'
import { FundDetailDataContext } from '../../../../shared/api/context/FundDetailDataContext'
import { LoadingDetails } from '../../../../shared/components/LoadingDetails'
import { useServiceState } from '../../../../shared/hooks/useServiceState'
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 1rem;
`
export const DueDiligenceDocuments: React.FC = () => {
  const { dataFundDetail } = React.useContext(FundDetailDataContext)
  const { invoke: getDataDocs, data: dataDocs, loading } = useServiceState(
    APIService.fundDetailService.fetchDueDiligenceDocuments,
  )

  React.useEffect(() => {
    dataFundDetail?.firm_id && getDataDocs(dataFundDetail.firm_id)
  }, [dataFundDetail, getDataDocs])

  const dataSource = React.useMemo(() => {
    if (!dataDocs) {
      return []
    }
    return [
      { label: 'Open Firm in Sharepoint', url: dataDocs.firm_url },
      { label: 'Search Firm ID in Sharepoint', url: dataDocs.search_url },
    ]
  }, [dataDocs])
  return (
    <Wrapper>
      {dataDocs && (
        <List
          bordered
          dataSource={dataSource}
          style={{ width: '100%' }}
          renderItem={(item) => (
            <List.Item>
              <a href={item.url} rel="noopener noreferrer" target="_blank">
                {item.label}
              </a>
            </List.Item>
          )}
        />
      )}
      {loading && <LoadingDetails name="Loading Documents" />}
    </Wrapper>
  )
}
