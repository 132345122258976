import moment from 'moment'

export const getMonthRange = (startDate: string, endDate: string) => {
  const dateStart = moment(startDate, 'YYYY-MN')
  const dateEnd = moment(endDate, 'YYYY-MM')
  const tempStart = dateStart.clone()
  const results = []

  while (dateEnd > tempStart || tempStart.format('M') === dateEnd.format('M')) {
    results.push(tempStart.format('YYYY-MM'))
    tempStart.add(1, 'month')
  }
  return results
}
