import { DeleteTwoTone, EditOutlined } from '@ant-design/icons'
import { Button, notification, Popconfirm, Tooltip } from 'antd'
import Table, { ColumnProps } from 'antd/lib/table'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../../../../shared/api'
import { FundDetailDataContext } from '../../../../../shared/api/context/FundDetailDataContext'
import { DueDiligenceReceiveSepAct } from '../../../../../shared/api/models/FirmDetails'
import { Colors } from '../../../../../shared/colors'
import { useServiceState } from '../../../../../shared/hooks/useServiceState'
import { ReceiveSepActModal, RECORD_TYPE_OPTIONS } from './ReceiveSepActModal'
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 1rem;
  flex-direction: column;
  position: relative;
  width: 100%;
  overflow-x: auto;
`

export const ReceivedSepAcct: React.FC = () => {
  const { dataFundDetail } = React.useContext(FundDetailDataContext)
  const [isShowModal, setIsShowModal] = React.useState(false)
  const [selectedReceiveMesseage, setSelectedReceiveMesseage] = React.useState<DueDiligenceReceiveSepAct | undefined>()
  const [loadingDelete, setLoadingDelete] = React.useState(false)
  const { invoke: getListMessage, data: listMesseage, loading: dataLoading } = useServiceState(
    APIService.firmDetailService.getDueDiligenceReceiveMess,
  )

  React.useEffect(() => {
    dataFundDetail?.firm_id && getListMessage(dataFundDetail.firm_id)
  }, [dataFundDetail, getListMessage])

  const updateReceiveSepAct = () => {
    getListMessage(dataFundDetail?.firm_id)
  }

  const deleteMessage = (record: DueDiligenceReceiveSepAct) => {
    setLoadingDelete(true)
    dataFundDetail?.firm_id &&
      APIService.firmDetailService
        .deleteDueDiligenceReceiveMess(dataFundDetail?.firm_id, {
          fund_id: record.fund_id,
          record_type: record.rec_type,
        })
        .then(() => {
          notification.success({ message: 'Receive Message Created!' })
          updateReceiveSepAct()
        })
        .catch((err) => {
          console.error({ err })
          notification.error({ message: 'Receive Message Create Failed!' })
        })
        .finally(() => {
          setLoadingDelete(false)
        })
  }

  const columns: ColumnProps<DueDiligenceReceiveSepAct>[] = [
    {
      title: 'Record Type',
      dataIndex: 'rec_type',
      key: 'rec_type',
      fixed: 'left',
      width: 150,
      render: (_, record) => <div>{RECORD_TYPE_OPTIONS.find((item) => item.value === record.rec_type)?.text}</div>,
      sorter: (a, b) => {
        return a.rec_type.localeCompare(b.rec_type)
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Operator ID',
      dataIndex: 'oper_id',
      key: 'oper_id',
      width: 150,
      sorter: (a, b) => {
        return a.oper_id.localeCompare(b.oper_id)
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Firm ID',
      dataIndex: 'firm_id',
      key: 'firm_id',
      width: 150,
    },
    {
      title: 'Fund ID',
      dataIndex: 'fund_id',
      key: 'fund_id',
      width: 150,
      sorter: (a, b) => {
        return a.fund_id.localeCompare(b.fund_id)
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Received Date',
      dataIndex: 'received_date',
      key: 'received_date',
      width: 300,
    },
    {
      title: 'Prime Broker',
      dataIndex: 'prime_broker',
      key: 'prime_broker',
      width: 150,
    },
    {
      title: 'Minimum',
      dataIndex: 'minimum',
      key: 'minimum',
      width: 150,
    },
    {
      title: 'Target',
      dataIndex: 'target',
      key: 'target',
      width: 150,
    },
    {
      title: 'Maximum',
      dataIndex: 'maximum',
      key: 'maximum',
      width: 150,
    },
    {
      title: 'Reserved Capacity',
      dataIndex: 'reserved_capacity',
      key: 'reserved_capacity',
      width: 150,
    },
    {
      title: 'Management Fee',
      dataIndex: 'mgmt_fee',
      key: 'mgmt_fee',
      width: 150,
    },
    {
      title: 'Incentive',
      dataIndex: 'incentive',
      key: 'incentive',
      width: 150,
    },
    {
      title: 'Hurdle Rate',
      dataIndex: 'hurdle_rate',
      key: 'hurdle_rate',
      width: 150,
    },
    {
      title: 'Liquidity',
      dataIndex: 'liquidity',
      key: 'liquidity',
      width: 150,
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      width: 150,
    },
    {
      title: 'Action',
      key: 'action',
      width: 150,
      fixed: 'right',
      render: (_, record) => (
        <>
          <Button
            type="link"
            style={{ padding: '4px 8px' }}
            onClick={() => {
              setSelectedReceiveMesseage(record)
              setIsShowModal(true)
            }}
          >
            <Tooltip title="Edit">
              <EditOutlined />
            </Tooltip>
          </Button>

          <Popconfirm title="Are you sure you want to delete?" onConfirm={() => deleteMessage(record)}>
            <Button type="link" style={{ padding: '4px 8px' }} loading={loadingDelete}>
              <Tooltip title="Delete">
                <DeleteTwoTone twoToneColor={Colors.danger} />
              </Tooltip>
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ]
  return (
    <Wrapper>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="primary"
          style={{ marginBottom: '1rem' }}
          onClick={() => setIsShowModal(true)}
          disabled={listMesseage?.length === 3}
        >
          Create New Message
        </Button>
      </div>
      <Table
        rowKey="rec_type"
        dataSource={listMesseage}
        columns={columns}
        pagination={listMesseage && listMesseage.length > 10 ? undefined : false}
        bordered
        loading={dataLoading}
        scroll={{ x: 'min-content' }}
      />
      {dataFundDetail?.firm_id && (
        <ReceiveSepActModal
          isShowModal={isShowModal}
          setIsShowModal={setIsShowModal}
          selectedReceiveMesseage={selectedReceiveMesseage}
          setSelectedReceiveMesseage={setSelectedReceiveMesseage}
          firmId={dataFundDetail.firm_id}
          updateReceiveSepAct={updateReceiveSepAct}
          fundId={dataFundDetail.fund_id}
        />
      )}
    </Wrapper>
  )
}
