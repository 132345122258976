import { AxiosInstance, AxiosPromise } from 'axios'
import { CodeTableType } from '../models/CodeTable'

export interface CodeTableService {
  getCodeTableList: () => AxiosPromise<CodeTableType[]>
  createCodeTable: (params: CodeTableType) => AxiosPromise<void>
  updateCodeTable: (params: CodeTableType) => AxiosPromise<void>
  deleteCodeTable: (params: Partial<CodeTableType>) => AxiosPromise<void>
}
export const CodeTableService = (request: AxiosInstance): CodeTableService => {
  return {
    getCodeTableList: () => request.get(`/manager-link/code-table`),
    createCodeTable: (params) => request.post(`/manager-link/code-table`, params),
    updateCodeTable: (params) => request.put(`/manager-link/code-table`, params),
    deleteCodeTable: (params) => request.delete(`/manager-link/code-table`, { data: params }),
  }
}
