import { Modal, notification, Tabs } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../../app/rootReducer'
import { FundDetailDataContext, PERFORMANCE_REQUEST_ENUM } from '../../../shared/api/context/FundDetailDataContext'
import { useDraft } from '../../../shared/DraftManager'
import { useFieldValidate } from '../../../shared/hooks/useEffectFieldValidate'
import { LeftNavExFundEnum } from '../../../shared/SearchExFundLabel'
import { RightTabFundEnum } from '../../../shared/SearchFundLabel'
import PerformanceDataSheet from '../../Dashboard/FundDetail/FundROR/PerformanceDataSheet'
import { FormFieldType } from '../ExFundDetailMain'
import { RequiredDataHelper } from '../RequiredDataHelper'
import { ExPerformanceAssetsBTC } from './ExFundPerformance/ExPerformanceAssetsBTC'
import { ExPerformanceNAV } from './ExFundPerformance/ExPerformanceNAV'
import { ExPerformanceRateOfReturn } from './ExFundPerformance/ExPerformanceRateOfReturn'

const { TabPane } = Tabs

const StyledTabs = styled(Tabs)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`
const StyledTabPane = styled(TabPane)`
  padding: 16px;
`

type Props = {
  setIsUpdateFund: (isUpdate: boolean) => void
  isDirty: boolean
  form: FormInstance
  onUpdateTabDirty: (id: LeftNavExFundEnum, isDirty: boolean) => void
}
type FundPerformanceFieldType = Pick<FormFieldType, 'reporting_style' | 'denomination'>
const FIELDS: FundPerformanceFieldType = {
  reporting_style: {
    key: 'reporting_style',
    name: 'Reporting Style',
    isRequired: true,
  },
  denomination: {
    key: 'denomination',
    name: 'Denom',
    isRequired: true,
  },
}

export const ExFundPerformance: React.FC<Props> = ({ setIsUpdateFund, isDirty, form, onUpdateTabDirty }) => {
  const {
    dataFundDetail,
    dataLatestPerformance,
    performanceDataChange,
    setPerformanceDataChange,
    rorDataChange,
    setRORDataChange,
    navDataChange,
    setNAVDataChange,
    assetDataChange,
    setAssetDataChange,
    clearSnapshot,
    loading,
    getPerformanceRequest,
    dataFundDetailChange,
  } = React.useContext(FundDetailDataContext)

  const dataChangeRef = dataFundDetailChange?.current

  const appSetting = useSelector((state: RootState) => state.appSettings)
  const [showConfirm, setShowConfirm] = React.useState(false)
  const [targetKey, setTargetKey] = React.useState<RightTabFundEnum>()
  const [activeKey, setActiveKey] = React.useState<string>(RightTabFundEnum.PERFORMANCE_UPDATE)

  const { loadSnapshot } = useDraft()

  const hyperlink = React.useMemo(() => {
    if (!dataFundDetail) {
      return
    }
    const fundId = dataFundDetail.fund_id
    const draft = loadSnapshot('exFundDraft', dataFundDetail.fund_id)
    const hyperlinked = appSetting.exTabs.tabList.find((item) => item.id.split('-')[1] === fundId)?.hyperlinkTable

    if (hyperlinked) {
      if (draft && draft._latestPerformanceDataChange) {
        setActiveKey(RightTabFundEnum.PERFORMANCE_UPDATE)
        notification.warn({
          message: 'There are unsaved changes in Performance Update. Please save or discard the changes to continue.',
        })
        return
      }
      return hyperlinked
    }

    if (draft && draft._RORDataChange) {
      return 'latest_ror'
    }
    if (draft && draft._navDataChange) {
      return 'latest_nav'
    }
    if (draft && draft._assetDataChange) {
      return 'latest_asset'
    }
  }, [appSetting.exTabs.tabList, dataFundDetail, loadSnapshot])

  const handleTabChange = (key: string) => {
    // Case that update Latest Performance and switch to one of ROR/Asset/Nav tab
    if (
      performanceDataChange &&
      activeKey === RightTabFundEnum.PERFORMANCE_UPDATE &&
      Object.keys(performanceDataChange).length > 0 &&
      (key === RightTabFundEnum.ROR || key === RightTabFundEnum.ASSET || key === RightTabFundEnum.NAV)
    ) {
      setShowConfirm(true)
      setTargetKey(key)
      return
    }
    // Reversed case
    if (
      key === RightTabFundEnum.PERFORMANCE_UPDATE &&
      (rorDataChange.values || navDataChange.values || assetDataChange.values)
    ) {
      setShowConfirm(true)
      setTargetKey(key)
      return
    }
    setActiveKey(key as RightTabFundEnum)
  }

  useFieldValidate(
    Object.keys(FIELDS).filter((item) => FIELDS[item as keyof FundPerformanceFieldType].isRequired),
    isDirty,
    form,
    onUpdateTabDirty,
    LeftNavExFundEnum.FUND_PERFORMANCE,
  )

  React.useEffect(() => {
    if (hyperlink) {
      if (hyperlink === 'latest_asset') {
        setActiveKey(RightTabFundEnum.ASSET)
      }
      if (hyperlink === 'latest_nav') {
        setActiveKey(RightTabFundEnum.NAV)
      }
      if (hyperlink === 'latest_ror') {
        setActiveKey(RightTabFundEnum.ROR)
      }
    }
  }, [hyperlink])

  const handleOk = () => {
    // discard changes
    if (
      targetKey === RightTabFundEnum.ROR ||
      targetKey === RightTabFundEnum.NAV ||
      targetKey === RightTabFundEnum.ASSET
    ) {
      if (
        dataChangeRef &&
        Object.keys(dataChangeRef).filter((item) => item !== '_latestPerformanceDataChange').length === 0
      ) {
        setIsUpdateFund(false)
      }
      setPerformanceDataChange(undefined)
      // TODO: quick fix for now. Need to clear the specific key instead of the whole snapshot
      clearSnapshot()
      notification.success({ message: `Discard changes in ${activeKey}` })
      setShowConfirm(false)
      setActiveKey(targetKey)
      return
    }
    if (targetKey === RightTabFundEnum.PERFORMANCE_UPDATE) {
      if (
        dataChangeRef &&
        Object.keys(dataChangeRef).filter(
          (item) => item !== '_RORDataChange' && item !== '_assetDataChange' && item !== '_navDataChange',
        ).length === 0
      ) {
        setIsUpdateFund(false)
      }
      notification.success({ message: `Discard changes in ROR/Asset/Nav` })
      setRORDataChange({ values: undefined })
      setNAVDataChange({ values: undefined })
      setAssetDataChange({ values: undefined })
      clearSnapshot()
      setShowConfirm(false)
      setActiveKey(targetKey)
    }
  }

  const handleCancel = () => {
    setShowConfirm(false)
  }

  React.useEffect(() => {
    getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.LATEST_PERFORMANCE)
  }, [getPerformanceRequest])

  return (
    <>
      <StyledTabs hideAdd type="editable-card" activeKey={activeKey} onChange={handleTabChange}>
        <StyledTabPane tab="Performance Update" key={RightTabFundEnum.PERFORMANCE_UPDATE} closable={false}>
          {/* <PerformanceUpdate fundDetails={dataFundDetail} /> */}
          {dataLatestPerformance && dataFundDetail && (
            <PerformanceDataSheet
              currency={dataFundDetail.denomination || undefined}
              dataLatestPerformance={dataLatestPerformance}
              loading={loading}
              data={performanceDataChange}
              onDataChange={setPerformanceDataChange}
              inceptionDate={dataFundDetail.inception_date || undefined}
            />
          )}
        </StyledTabPane>
        <StyledTabPane tab="Rate Of Return (%)" key={RightTabFundEnum.ROR} closable={false}>
          <ExPerformanceRateOfReturn form={form} />
        </StyledTabPane>
        <StyledTabPane
          tab={`Assets ${dataFundDetail?.denomination || ''}(MM)`}
          key={RightTabFundEnum.ASSET}
          closable={false}
        >
          <ExPerformanceAssetsBTC form={form} />
        </StyledTabPane>
        <StyledTabPane
          tab={`NAV per Share ${dataFundDetail?.denomination || ''}`}
          key={RightTabFundEnum.NAV}
          closable={false}
        >
          <ExPerformanceNAV form={form} />
        </StyledTabPane>
      </StyledTabs>
      <Modal title="Discard Changes?" visible={showConfirm} onOk={handleOk} onCancel={handleCancel}>
        <span>
          <p>You have unsaved changes. </p>
          <p>
            Because of the conflict in data between the tables, the changes here should be saved or discarded before
            continue.
          </p>
          Select <b>OK</b> to discard the changes or <b>Cancel</b> to continue.
        </span>
      </Modal>
    </>
  )
}
RequiredDataHelper.default().registerRequirement(
  LeftNavExFundEnum.FUND_PERFORMANCE,
  Object.keys(FIELDS).filter((item) => FIELDS[item as keyof FundPerformanceFieldType].isRequired),
)
