import { notification, Row } from 'antd'
import moment from 'moment'
import React, { useEffect } from 'react'
import ReactDataSheet from 'react-datasheet'
import styled from 'styled-components'
import {
  FundDetailDataContext,
  PerformanceUpdateType,
  PERFORMANCE_REQUEST_ENUM,
} from '../../../../shared/api/context/FundDetailDataContext'
import { FundDetails } from '../../../../shared/api/models/FundDetails'
import { Colors } from '../../../../shared/colors'
import { LoadingDetails } from '../../../../shared/components/LoadingDetails'
import { getMonthRange } from '../../../../shared/utils/datetimeUtils'

const Wrapper = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .data-grid-container .data-grid .cell.read-only {
    background: #f3f3f3;
  }

  padding: 1rem;
`

export const DATE_FORMAT = 'YYYY-MM'

export interface GridElement extends ReactDataSheet.Cell<GridElement> {
  value: string | null
  originalValue?: string | null
  date?: string | null
}

const headerRow = [
  { readOnly: true, value: 'Outstanding performance updates' },
  { readOnly: true, value: 'Returns (%)' },
  { readOnly: true, value: 'Asset (MM)' },
  { readOnly: true, value: 'NAV' },
]

export const PerformanceUpdate: React.FC<{ fundDetails?: FundDetails; inceptionDate?: string }> = ({
  fundDetails,
  inceptionDate,
}) => {
  const [grid, setGrid] = React.useState<GridElement[][]>([headerRow])
  const [isUpdate, setIsUpdate] = React.useState(false)

  const {
    setPerformanceDataChange,
    getPerformanceRequest,
    performanceDataChange,
    dataLatestPerformance,
    latestPerformanceLoading: loading,
  } = React.useContext(FundDetailDataContext)

  const [draftData, setDraftData] = React.useState<PerformanceUpdateType | undefined>(performanceDataChange)

  React.useEffect(() => {
    if (draftData && dataLatestPerformance) {
      setIsUpdate(true)
    }
  }, [draftData, dataLatestPerformance])

  const parseGridData = (data: GridElement[][]): PerformanceUpdateType => {
    const arrayUpdate: PerformanceUpdateType = {}

    data.forEach((row, rowIndex) => {
      if (rowIndex === 0) {
        return
      }

      row.forEach((cell, cellIndex) => {
        if (cellIndex === 0) {
          return
        }

        // ROR
        if (cellIndex === 1 && row[0].value && cell.value && cell.value !== '') {
          arrayUpdate.rorChanges = [
            ...(arrayUpdate.rorChanges || []),
            {
              yyyy_mm: row[0].value,
              value: +cell.value,
            },
          ]
        }
        if (cellIndex === 2 && row[0].value && cell.value && cell.value !== '') {
          arrayUpdate.assetChanges = [
            ...(arrayUpdate.assetChanges || []),
            {
              yyyy_mm: row[0].value,
              value: +cell.value,
            },
          ]
        }
        if (cellIndex === 3 && row[0].value && cell.value && cell.value !== '') {
          arrayUpdate.navChanges = [
            ...(arrayUpdate.navChanges || []),
            {
              yyyy_mm: row[0].value,
              value: +cell.value,
            },
          ]
        }
      })
    })

    return arrayUpdate
  }

  useEffect(() => {
    getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.LATEST_PERFORMANCE)
  }, [getPerformanceRequest])

  const updateGrid = React.useCallback(
    (data?: PerformanceUpdateType) => {
      if (!dataLatestPerformance) {
        return
      }
      const hasNoPerformance =
        !dataLatestPerformance.latest_asset && !dataLatestPerformance.latest_nav && !dataLatestPerformance.latest_ror

      const latestROR = dataLatestPerformance.latest_ror
      const latestNav = dataLatestPerformance.latest_nav
      const latestAsset = dataLatestPerformance.latest_asset

      // get the min date of latest data
      const startDate = moment.min([
        latestROR ? moment(latestROR, DATE_FORMAT) : moment(),
        latestNav ? moment(latestNav, DATE_FORMAT) : moment(),
        latestAsset ? moment(latestAsset, DATE_FORMAT) : moment(),
      ])

      // Prepare the available row as remaining months till now
      const dateList = getMonthRange(
        hasNoPerformance ? moment(inceptionDate).format(DATE_FORMAT) : startDate.format(DATE_FORMAT),
        moment().subtract(1, 'month').format(DATE_FORMAT),
      )

      const grid = [
        headerRow,
        ...dateList.map((dateRow) => {
          const latestRorMoment = moment(latestROR, DATE_FORMAT)
          const latestAssetMoment = moment(latestAsset, DATE_FORMAT)
          const latestNavMoment = moment(latestNav, DATE_FORMAT)
          const dateRowMoment = moment(dateRow, DATE_FORMAT)

          return [
            { readOnly: true, value: dateRow },
            {
              readOnly:
                !hasNoPerformance &&
                (latestRorMoment.isAfter(dateRowMoment, 'month') ||
                  latestRorMoment.isSame(dateRowMoment, 'month') ||
                  !latestRorMoment),
              value:
                data?.rorChanges?.find((item) => item.yyyy_mm === dateRow)?.value?.toString() ||
                (dataLatestPerformance &&
                  dataLatestPerformance.latest_ror === dateRow &&
                  dataLatestPerformance.latest_ror_value?.toFixed(4)) ||
                '',
            },
            {
              readOnly:
                !hasNoPerformance &&
                (latestAssetMoment.isAfter(dateRowMoment) ||
                  latestAssetMoment.isSame(dateRowMoment, 'month') ||
                  !latestAsset),
              value:
                data?.assetChanges?.find((item) => item.yyyy_mm === dateRow)?.value?.toString() ||
                (dataLatestPerformance &&
                  dataLatestPerformance.latest_asset === dateRow &&
                  dataLatestPerformance.latest_asset_value?.toFixed(3)) ||
                '',
            },
            {
              readOnly:
                !hasNoPerformance &&
                (latestNavMoment.isAfter(dateRowMoment) ||
                  latestNavMoment.isSame(dateRowMoment, 'month') ||
                  !latestNav),
              value:
                data?.navChanges?.find((item) => item.yyyy_mm === dateRow)?.value?.toString() ||
                (dataLatestPerformance &&
                  dataLatestPerformance.latest_nav === dateRow &&
                  dataLatestPerformance.latest_nav_value?.toFixed(6)) ||
                '',
            },
          ]
        }),
      ]

      setGrid(grid)
    },
    [dataLatestPerformance, inceptionDate],
  )

  React.useEffect(() => {
    // Clear draft if no draft is given (used for when cancel)
    if (!performanceDataChange || Object.keys(performanceDataChange).length <= 0) {
      setDraftData(undefined)
      updateGrid(undefined)
      setIsUpdate(false)
    }
  }, [performanceDataChange, updateGrid])

  // draw the grid
  React.useEffect(() => {
    updateGrid(draftData)
  }, [draftData, updateGrid])

  React.useEffect(() => {
    if (isUpdate && setPerformanceDataChange) {
      setPerformanceDataChange(parseGridData(grid))
    }
  }, [grid, isUpdate, setPerformanceDataChange])

  return (
    <Wrapper>
      <Row justify="end">
        <span style={{ marginRight: '0.5rem' }}>
          Currency: <b>{fundDetails?.denomination}</b>
        </span>
      </Row>
      <ReactDataSheet<GridElement>
        data={
          grid
            ? grid.map((row) => [
                // First Column
                {
                  ...row[0],
                  valueViewer: (cell) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                      >
                        {cell.value}
                      </div>
                    )
                  },
                },
                // Rest Columns
                ...row.slice(1, row.length).map((item, index) => {
                  const isAsset = index === 1
                  const isNAV = index === 2
                  return {
                    ...item,
                    className: item.value ? 'edited' : '',
                    valueViewer: (props: any) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              color: parseFloat(props.value) < 0 ? Colors.danger : Colors.black,
                            }}
                          >
                            {Number.isNaN(parseFloat(props.value)) && props.value}
                            {!Number.isNaN(parseFloat(props.value)) &&
                              (parseFloat(props.value) < 0
                                ? `(${Math.abs(parseFloat(props.value)).toFixed(isNAV ? 6 : isAsset ? 3 : 4)})`
                                : Math.abs(parseFloat(props.value)).toFixed(isNAV ? 6 : isAsset ? 3 : 4))}
                          </span>
                        </div>
                      )
                    },
                  }
                }),
              ])
            : []
        }
        valueRenderer={(cell) => cell.value}
        isCellNavigable={(cell) => !cell.readOnly}
        onCellsChanged={(changes) => {
          if (changes[0].value && isNaN(+changes[0].value)) {
            notification.warn({ message: 'Input must be Number!' })
            return
          }

          const tempGrid = grid.map((row) => [...row])
          changes.forEach(({ row, col, value }) => {
            // ROR columns
            if (col === 1) {
              if (value && parseFloat(value) < -100) {
                notification.error({ message: 'Value must be greater than or equal to -100' })
                return
              }
              tempGrid[row][col] = {
                ...tempGrid[row][col],
                value: value && !isNaN(+value) ? parseFloat(value).toFixed(4) : null,
              }
            }
            if (col === 2) {
              tempGrid[row][col] = {
                ...tempGrid[row][col],
                value: value && !isNaN(+value) && +value >= 0 ? parseFloat(value).toFixed(3) : null,
              }
              if (value && +value < 0) {
                notification.warn({ message: 'Asset/NAV values should be positive' })
              }
            }
            if (col === 3) {
              tempGrid[row][col] = {
                ...tempGrid[row][col],
                value: value && !isNaN(+value) && +value > 0 ? parseFloat(value).toFixed(6) : null,
              }
              if (value && +value < 0) {
                notification.warn({ message: 'Asset/NAV values should be positive' })
              }
            }
          })
          setGrid(tempGrid)
          setIsUpdate(true)
        }}
      />
      {loading && <LoadingDetails name="Loading performance" />}
    </Wrapper>
  )
}
