import { createSlice } from '@reduxjs/toolkit'

interface AppEventState {
  fundRorUpdated: boolean
  fundNavUpdated: boolean
  fundAssetUpdated: boolean
}

const initialState: AppEventState = {
  fundRorUpdated: false,
  fundNavUpdated: false,
  fundAssetUpdated: false,
}

const appEvent = createSlice({
  name: 'appEvent',
  initialState,
  reducers: {
    fundRORUpdated: (state) => {
      state.fundRorUpdated = true
    },
    fundNavUpdated: (state) => {
      state.fundNavUpdated = true
    },
    fundAssetUpdated: (state) => {
      state.fundAssetUpdated = true
    },
    resetROR: (state) => {
      state.fundRorUpdated = false
    },
    resetNav: (state) => {
      state.fundNavUpdated = false
    },
    resetAsset: (state) => {
      state.fundAssetUpdated = false
    },
  },
})

const { reducer, actions } = appEvent

export const { fundRORUpdated, fundNavUpdated, fundAssetUpdated, resetAsset, resetNav, resetROR } = actions
export default reducer
