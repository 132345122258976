import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Col, Progress, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { Colors } from '../../../shared/colors'
import { LoadingDetails } from '../../../shared/components/LoadingDetails'

type ActivityChartType = {
  id: number
  type: 'updated' | 'created'
  value: number | undefined
}

const StyledIcon = styled.div`
  position: absolute;
  left: calc(50% - 10px);
  top: calc(50% - 10px);
`

const renderActivityProgress = (
  item: ActivityChartType,
  setTypeSelectActivity: (value: string) => void,
  setActiveTab: (value: string) => void,
) => {
  let color
  let icon
  switch (item.type) {
    case 'updated':
      color = '#FFC069'
      icon = <InfoCircleOutlined style={{ fontSize: 20 }} />
      break
    case 'created':
      color = '#95DE64'
      icon = <PlusOutlined style={{ fontSize: 20 }} />
      break
    default:
      break
  }

  return (
    <Col span={12} key={item.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <span style={{ fontSize: 12, color: Colors.gray, textTransform: 'uppercase', marginBottom: 8 }}>{item.type}</span>
      <div
        style={{ position: 'relative', cursor: 'pointer' }}
        onClick={() => {
          setTypeSelectActivity(item.type)
          setActiveTab('InforFund')
        }}
      >
        <Progress type="circle" percent={100} strokeWidth={6} showInfo={false} width={80} strokeColor={color} />
        <StyledIcon>{icon}</StyledIcon>
      </div>
      <span style={{ marginTop: 16, fontSize: 12 }}>{item.value}</span>
    </Col>
  )
}

type Props = {
  countUpdated: number | undefined
  countCreated: number | undefined
  loading: boolean
  setTypeSelectActivity: (value: string) => void
  setActiveTab: (value: string) => void
}
const ActivityBlock: React.FC<Props> = ({
  countUpdated,
  countCreated,
  loading,
  setTypeSelectActivity,
  setActiveTab,
}) => {
  const ACTIVITIES_DATA: ActivityChartType[] = React.useMemo(() => {
    return [
      {
        id: 1,
        type: 'updated',
        value: countUpdated,
      },
      {
        id: 2,
        type: 'created',
        value: countCreated,
      },
    ]
  }, [countCreated, countUpdated])
  return (
    <Row style={{ paddingTop: 8 }}>
      {loading && <LoadingDetails name="Loading" />}
      {ACTIVITIES_DATA.map((item) => renderActivityProgress(item, setTypeSelectActivity, setActiveTab))}
    </Row>
  )
}

export default ActivityBlock
