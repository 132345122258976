import { Col, Radio, Row } from 'antd'
import Form, { FormInstance } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'
import { FormItemCheckBox, FormItemInput } from '../../../shared/components/FormItemComponents'
import { RenderLabelExNewFund } from '../../../shared/components/RenderLabelExNewFund'
import { LeftNavExNewFundEnum } from '../../../shared/SearchExNewFundLabel'
import { NextBackNewFundTab } from './NextBackExNewFundTab'
const Flex = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 1rem;
  padding: 0 2rem;
`

type Props = {
  setStepTab: (value: LeftNavExNewFundEnum) => void
  setActiveTab: (value: LeftNavExNewFundEnum) => void
  activeTab: LeftNavExNewFundEnum
  stepTab: LeftNavExNewFundEnum
  form: FormInstance
}
const ARRAY_FIELD_VALIDATE: string[] = ['mgmt_fee']
export const ExNewFundFeeSchedule: React.FC<Props> = ({ form, setActiveTab, setStepTab, activeTab, stepTab }) => {
  const isLiquidCheck = form.getFieldValue('liquid_alt_product') as boolean

  const arrayValidate = React.useMemo(() => {
    return isLiquidCheck
      ? [...ARRAY_FIELD_VALIDATE, 'max_12b1_fee', 'max_front_fee', 'max_deferred_fee']
      : [...ARRAY_FIELD_VALIDATE, 'incentive_fee', 'administrative_fee', 'hurdle_rate', 'is_high_watermark']
  }, [isLiquidCheck])

  const checkHurdleRate = form.getFieldValue('is_hurdle_rate')

  const LIST_FORM_INPUT = React.useMemo(() => {
    return [
      {
        name: 'incentive_fee',
        label: 'Incentive Fee %',
        maxLength: 30,
        hide: isLiquidCheck,
        rules: !isLiquidCheck ? [{ required: true, message: 'This Field is required!' }] : undefined,
      },
      {
        name: 'other_fees',
        label: 'Other Fees (Explain)',
        maxLength: 30,
      },
      {
        name: 'administrative_fee',
        label: 'Administrative Fee %',
        maxLength: 30,
        rules: [{ required: true, message: 'This Field is required!' }],
      },
    ]
  }, [isLiquidCheck])

  const LIST_FORM_FOOTER = React.useMemo(() => {
    return [
      {
        name: 'max_12b1_fee',
        label: 'Maximum 12B-1 Fee %',
        rules: isLiquidCheck ? [{ required: true, message: 'Maximum 12B-1 Fee % is required!' }] : undefined,
        maxLength: 30,
        hide: !isLiquidCheck,
      },
      {
        name: 'max_front_fee',
        label: 'Maximum Front-End Sales Load Fee %',
        maxLength: 30,
        rules: isLiquidCheck
          ? [{ required: true, message: 'Maximum Front-End Sales Load Fee % is required!' }]
          : undefined,
        hide: !isLiquidCheck,
      },
      {
        name: 'max_deferred_fee',
        label: 'Maximum Deferred Sales Load Fee %',
        maxLength: 30,
        rules: isLiquidCheck
          ? [{ required: true, message: 'Maximum Deferred Sales Load Fee % is required!' }]
          : undefined,
        hide: !isLiquidCheck,
      },
    ]
  }, [isLiquidCheck])

  return (
    <Flex>
      <Row>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemInput
            name="mgmt_fee"
            label={<RenderLabelExNewFund value="Management Fee %" />}
            wrapperCol={16}
            maxLength={30}
            rules={[{ required: true, message: 'Management Fee is required!' }]}
          />
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <Row gutter={16}>
            <Col span={12}>
              <FormItemInput
                name="redemption_fee"
                label={<RenderLabelExNewFund value="Redemption Fee %" />}
                wrapperCol={24}
                typeField="number"
                // rules={[{ required: true, message: 'This Field is required!' }]}
              />
            </Col>
            <Col span={12}>
              <FormItemInput
                name="redemption_fee_months"
                label={<RenderLabelExNewFund value="If less than (number of months)" />}
                wrapperCol={24}
                normalize="number"
                typeField="number"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        {LIST_FORM_INPUT.map((item) => {
          if (item.hide) {
            return null
          }
          return (
            <Col span={12} xl={12} md={16} xs={24} sm={24} key={item.name}>
              <FormItemInput
                name={item.name}
                label={<RenderLabelExNewFund value={item.label} />}
                wrapperCol={16}
                maxLength={item.maxLength ? item.maxLength : undefined}
                rules={item.rules}
              />
            </Col>
          )
        })}
      </Row>
      {!isLiquidCheck && (
        <Row>
          <Col span={12} xl={12} md={16} xs={24} sm={24}>
            <Form.Item
              name="is_high_watermark"
              label={<RenderLabelExNewFund value="High Watermark" />}
              rules={[{ required: true, message: 'High Watermark is required' }]}
              {...{ wrapperCol: { span: 16 } }}
            >
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12} xl={12} md={16} xs={24} sm={24}>
            <FormItemInput
              name="hurdle_rate"
              labelCol={6}
              wrapperCol={12}
              labelAlign="left"
              maxLength={30}
              rules={
                form.getFieldValue('is_hurdle_rate')
                  ? [{ required: true, message: 'Hurdle Rate is required' }]
                  : undefined
              }
              disabled={!checkHurdleRate}
              label={
                <>
                  <div style={{ marginTop: '0.5rem', marginRight: '0.5rem' }}>
                    <FormItemCheckBox name="is_hurdle_rate" />
                  </div>
                  <RenderLabelExNewFund value="Hurdle Rate" />
                </>
              }
            />
          </Col>
        </Row>
      )}
      {isLiquidCheck && (
        <Row style={{ marginTop: '1rem' }}>
          <div style={{ fontStyle: 'italic' }}>
            <b>Note: </b> Fees below applicable only to Liquid Alternative Products
          </div>
        </Row>
      )}
      <Row>
        {LIST_FORM_FOOTER.map((item) => {
          if (item.hide) {
            return null
          }
          return (
            <Col span={12} xl={12} md={16} xs={24} sm={24} key={item.name}>
              <FormItemInput
                name={item.name}
                label={<RenderLabelExNewFund value={item.label} />}
                wrapperCol={16}
                maxLength={item.maxLength ? item.maxLength : undefined}
                rules={item.rules}
              />
            </Col>
          )
        })}
      </Row>

      <NextBackNewFundTab
        setStepTab={setStepTab}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        stepTab={stepTab}
        ArrayValidateField={arrayValidate}
        form={form}
      />
    </Flex>
  )
}
