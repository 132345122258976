import { Button } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React from 'react'
type Props = {
  showModal: boolean
  onRequestClose: () => void
}
export const ContactUsModal: React.FC<Props> = ({ showModal, onRequestClose }) => {
  return (
    <Modal
      visible={showModal}
      onCancel={onRequestClose}
      footer={
        <Button type="link" onClick={onRequestClose}>
          Close
        </Button>
      }
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h3>Contact Us</h3>
        <div>
          If you are having problems with ManagerLink or are unsure about any functionality please contact HFR at
          312-658-0955 or via email <a href="mailto:performance@hfr.com">performance@hfr.com</a>.
        </div>
        <div>
          <br />
          We would be happy to walk you through any issue you may have over the phone.
        </div>
        <div>
          <br />
          We hope to make ManagerLink as user-friendly as possible. Your questions and feedback are very much
          appreciated.
        </div>
      </div>
    </Modal>
  )
}
