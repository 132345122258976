import { AxiosInstance, AxiosPromise } from 'axios'
import {
  ActivityLogDetail,
  ActivityLogParams,
  ActivityLogResponse,
  LegacyLogParams,
  LegacyLogResponse,
  ListUserActivities,
} from '../models/ActivityLog'

export interface ActivityLogService {
  fetchDataEvent: (params: Partial<ActivityLogParams>) => AxiosPromise<ActivityLogResponse>
  fetchEventDetail: (eventId: string) => AxiosPromise<ActivityLogDetail>
  fetchListUser: () => AxiosPromise<ListUserActivities>
  fetchListUserByText: ({ text }: { text: string }) => AxiosPromise<{ username: string }[]>
  fetchListCode: () => AxiosPromise<string[]>
  fetchLegacyLog: (params: Partial<LegacyLogParams>) => AxiosPromise<LegacyLogResponse>
}
export const ActivityLogService = (request: AxiosInstance): ActivityLogService => {
  return {
    fetchDataEvent: (params) => request.post('/manager-link/events', params),
    fetchEventDetail: (eventId) => request.get(`/manager-link/events/${eventId.trim()}`),
    fetchListUser: () => request.get(`/manager-link/users`),
    fetchListCode: () => request.get(`/manager-link/event-codes`),
    fetchLegacyLog: (params) => request.post(`/manager-link/log-activities`, params),
    fetchListUserByText: ({ text }) => request.get(`/manager-link/users/search`, { params: { query: text, page: 1 } }),
  }
}
