import React from 'react'
import styled from 'styled-components'

const Title = styled.span<{ visibleWidth: number }>`
  @media (max-width: ${(props) => props.visibleWidth}px) {
    display: none !important;
  }
`
type ResponsiveTitleProps = {
  visibleWidth: number
}
export const ResponsiveTitle: React.FC<ResponsiveTitleProps> = ({ children, visibleWidth }) => (
  <Title visibleWidth={visibleWidth}>{children}</Title>
)
