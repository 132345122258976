import { Col, Radio, Row } from 'antd'
import Form, { FormInstance } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'
import { FundDetailDropdownContext } from '../../../shared/api/context/FundDetailDropdownContext'
import { FormItemInput, FormItemRadio, FormItemSelect } from '../../../shared/components/FormItemComponents'
import { RenderLabelExFund } from '../../../shared/components/RenderLabelExFund'
import { useFieldValidate } from '../../../shared/hooks/useEffectFieldValidate'
import { LeftNavExFundEnum } from '../../../shared/SearchExFundLabel'
import { FormFieldType } from '../ExFundDetailMain'
import { RequiredDataHelper } from '../RequiredDataHelper'

const Flex = styled.div`
  display: flex;
  flex: 1;
  margin-top: 1rem;
  flex-direction: column;
  padding: 0 2rem;
`

type FundStructureFieldsType = Pick<
  FormFieldType,
  | 'investor_type'
  | 'equity_subscription'
  | 'subscription_advance_notice'
  | 'subscription_cutoff_time'
  | 'subscription_cutoff_timezone'
  | 'subscription_settlement_days'
  | 'equity_redemption'
  | 'redemption_advance_notice'
  | 'is_lockup'
  | 'lockup_type'
  | 'lockup_period'
  | 'redemption_return_percent'
  | 'redemption_return_days'
  | 'balance_redemption_return_days'
  | 'gate_fund'
  | 'gate_investor'
  | 'performance_reporting'
  | 'use_of_leverage_type'
  | 'bloomberg_ticker'
  | 'isin'
  | 'is_offshore'
  | 'domicile'
  | 'structure'
  | 'registration_code'
>

const FIELDS: FundStructureFieldsType = {
  is_offshore: {
    key: 'is_offshore',
    name: 'Please Choose One',
  },
  domicile: {
    key: 'domicile',
    name: 'Domicile Of The Fund',
    isRequired: true,
  },
  structure: {
    key: 'structure',
    name: 'Structure',
    isRequired: true,
  },
  registration_code: {
    key: 'registration_code',
    name: 'Fund Registration',
    isRequired: true,
  },
  investor_type: {
    key: 'investor_type',
    name: 'Open To Investor Type',
    isRequired: true,
  },
  equity_subscription: {
    key: 'equity_subscription',
    name: 'Subscription Frequency',
    isRequired: true,
  },
  subscription_advance_notice: {
    key: 'subscription_advance_notice',
    name: 'Subscription Advanced Notice (Days)',
    isRequired: true,
  },
  subscription_cutoff_time: {
    key: 'subscription_cutoff_time',
    name: 'Subscription Cut Off By (Time)',
    isRequired: true,
  },
  subscription_cutoff_timezone: {
    key: 'subscription_cutoff_timezone',
    name: 'Subscription Cut Off By (Time Zone)',
    isRequired: true,
  },
  subscription_settlement_days: {
    key: 'subscription_settlement_days',
    name: 'Subscription Settlement (Days)',
    isRequired: true,
  },
  equity_redemption: {
    key: 'equity_redemption',
    name: 'Redemption Frequency',
    isRequired: true,
  },
  redemption_advance_notice: {
    key: 'redemption_advance_notice',
    name: 'Redemption Advanced Notice (Days)',
    isRequired: true,
  },
  is_lockup: {
    key: 'is_lockup',
    name: 'Lockup',
    isRequired: true,
  },
  lockup_type: {
    key: 'lockup_type',
    name: 'Lockup Type',
    isRequired: true,
  },
  lockup_period: {
    key: 'lockup_period',
    name: 'Lockup Period',
    isRequired: true,
  },
  redemption_return_percent: {
    key: 'redemption_return_percent',
    name: 'Initial % Of Redemption Returned',
    isRequired: true,
  },
  redemption_return_days: {
    key: 'redemption_return_days',
    name: 'Initial Redemption Returned Within (Days)',
    isRequired: true,
  },
  balance_redemption_return_days: {
    key: 'balance_redemption_return_days',
    name: 'Redemption Balance Returned Within (Days)',
    isRequired: true,
  },
  gate_fund: {
    key: 'gate_fund',
    name: 'Gate: Fund-Level',
    isRequired: true,
  },
  gate_investor: {
    key: 'gate_investor',
    name: 'Gate: Investor-Level',
    isRequired: true,
  },
  performance_reporting: {
    key: 'performance_reporting',
    name: 'Performance Reporting Interval',
  },
  use_of_leverage_type: {
    key: 'use_of_leverage_type',
    name: 'Leverage Type',
    isRequired: true,
  },
  bloomberg_ticker: {
    key: 'bloomberg_ticker',
    name: 'Ticker',
  },
  isin: {
    key: 'isin',
    name: 'ISIN',
  },
}

type Props = {
  isDirty: boolean
  form: FormInstance
  onUpdateTabDirty: (id: LeftNavExFundEnum, isDirty: boolean) => void
}

export const ExFundStructure: React.FC<Props> = ({ isDirty, form, onUpdateTabDirty }) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)

  const fundStatusField = form.getFieldValue('fund_status_code')

  useFieldValidate(
    Object.keys(FIELDS).filter((item) => FIELDS[item as keyof FundStructureFieldsType].isRequired),
    isDirty,
    form,
    onUpdateTabDirty,
    LeftNavExFundEnum.FUND_STRUCTURE,
  )

  const LIST_FORM = [
    {
      name: FIELDS['investor_type'].key,
      label: FIELDS['investor_type'].name,
      options:
        fundDataDropdown?.investor_type && fundDataDropdown.investor_type.length > 0
          ? fundDataDropdown.investor_type.map((item) => ({ value: item, text: item }))
          : [],
      rules: FIELDS['investor_type']?.isRequired ? [{ required: true, message: 'This field is required!' }] : [],
    },
    {
      name: FIELDS['equity_subscription'].key,
      label: FIELDS['equity_subscription'].name,
      options:
        fundDataDropdown?.equity_subscription && fundDataDropdown.equity_subscription.length > 0
          ? fundDataDropdown.equity_subscription.map((item) => ({ value: item.code_code, text: item.code_description }))
          : [],
      rules: FIELDS['equity_subscription']?.isRequired ? [{ required: true, message: 'This field is required!' }] : [],
    },
    {
      name: FIELDS['subscription_advance_notice'].key,
      label: FIELDS['subscription_advance_notice'].name,
      options:
        fundDataDropdown?.subscription_advance_notice && fundDataDropdown.subscription_advance_notice.length > 0
          ? fundDataDropdown.subscription_advance_notice.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
      rules: FIELDS['subscription_advance_notice']?.isRequired
        ? [{ required: true, message: 'This field is required!' }]
        : [],
    },
    {
      name: FIELDS['subscription_cutoff_time'].key,
      label: FIELDS['subscription_cutoff_time'].name,
      options:
        fundDataDropdown?.subscription_cutoff_time && fundDataDropdown.subscription_cutoff_time.length > 0
          ? fundDataDropdown.subscription_cutoff_time.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
      rules: FIELDS['subscription_cutoff_time']?.isRequired
        ? [{ required: true, message: 'This field is required!' }]
        : [],
    },
    {
      name: FIELDS['subscription_cutoff_timezone'].key,
      label: FIELDS['subscription_cutoff_timezone'].name,
      options:
        fundDataDropdown?.subscription_cutoff_timezone && fundDataDropdown.subscription_cutoff_timezone.length > 0
          ? fundDataDropdown.subscription_cutoff_timezone.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
      rules: FIELDS['subscription_cutoff_timezone']?.isRequired
        ? [{ required: true, message: 'This field is required!' }]
        : [],
    },
    {
      name: FIELDS['subscription_settlement_days'].key,
      label: FIELDS['subscription_settlement_days'].name,
      options:
        fundDataDropdown?.subscription_settlement_days && fundDataDropdown.subscription_settlement_days.length > 0
          ? fundDataDropdown.subscription_settlement_days.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
      rules: FIELDS['subscription_settlement_days']?.isRequired
        ? [{ required: true, message: 'This field is required!' }]
        : [],
    },
    {
      name: FIELDS['equity_redemption'].key,
      label: FIELDS['equity_redemption'].name,
      options:
        fundDataDropdown?.equity_redemption && fundDataDropdown.equity_redemption.length > 0
          ? fundDataDropdown.equity_redemption.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
      rules: FIELDS['equity_redemption']?.isRequired ? [{ required: true, message: 'This field is required!' }] : [],
    },
    {
      name: FIELDS['redemption_advance_notice'].key,
      label: FIELDS['redemption_advance_notice'].name,
      typeField: 'number',
      normalize: 'number',
      rules: FIELDS['redemption_advance_notice']?.isRequired
        ? [{ required: true, message: 'This field is required!' }]
        : [],
    },
    {
      name: FIELDS['is_lockup'].key,
      label: FIELDS['is_lockup'].name,
      isRadio: true,
      rules: FIELDS['is_lockup']?.isRequired ? [{ required: true, message: 'This field is required!' }] : [],
    },
    {
      name: FIELDS['lockup_type'].key,
      label: FIELDS['lockup_type'].name,
      options:
        fundDataDropdown?.lockup_type && fundDataDropdown.lockup_type.length > 0
          ? fundDataDropdown.lockup_type
              .filter((i) => i.code_code !== '0')
              .map((item) => ({
                value: item.code_code,
                text: item.code_description,
              }))
          : [],
      rules: RequiredDataHelper.default().isValidateField(LeftNavExFundEnum.FUND_STRUCTURE, FIELDS['lockup_type']?.key)
        ? [{ required: true, message: 'This field is required!' }]
        : [],
    },
    {
      name: FIELDS['lockup_period'].key,
      label: FIELDS['lockup_period'].name,
      options:
        fundDataDropdown?.lockup_period && fundDataDropdown.lockup_period.length > 0
          ? fundDataDropdown.lockup_period.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
      rules: RequiredDataHelper.default().isValidateField(
        LeftNavExFundEnum.FUND_STRUCTURE,
        FIELDS['lockup_period']?.key,
      )
        ? [{ required: true, message: 'This field is required!' }]
        : [],
    },
    {
      name: FIELDS['redemption_return_percent'].key,
      label: FIELDS['redemption_return_percent'].name,
      typeField: 'number',
      normalize: 'number',
      rules: FIELDS['redemption_return_percent']?.isRequired
        ? [{ required: true, message: 'This field is required!' }]
        : [],
    },
    {
      name: FIELDS['redemption_return_days'].key,
      label: FIELDS['redemption_return_days'].name,
      typeField: 'number',
      normalize: 'number',
      rules: FIELDS['redemption_return_days']?.isRequired
        ? [{ required: true, message: 'This field is required!' }]
        : [],
    },
    {
      name: FIELDS['balance_redemption_return_days'].key,
      label: FIELDS['balance_redemption_return_days'].name,
      typeField: 'number',
      normalize: 'number',
      rules: FIELDS['balance_redemption_return_days']?.isRequired
        ? [{ required: true, message: 'This field is required!' }]
        : [],
    },
    {
      name: FIELDS['gate_fund'].key,
      label: FIELDS['gate_fund'].name,
      options:
        fundDataDropdown?.gate_fund && fundDataDropdown.gate_fund.length > 0
          ? fundDataDropdown.gate_fund
              .filter((i) => i.code_code !== '0')
              .map((item) => ({
                value: item.code_code,
                text: item.code_description,
              }))
          : [],
      rules: FIELDS['gate_fund']?.isRequired ? [{ required: true, message: 'This field is required!' }] : [],
    },
    {
      name: FIELDS['gate_investor'].key,
      label: FIELDS['gate_investor'].name,
      options:
        fundDataDropdown?.gate_investor && fundDataDropdown.gate_investor.length > 0
          ? fundDataDropdown.gate_investor
              .filter((i) => i.code_code !== '0')
              .map((item) => ({
                value: item.code_code,
                text: item.code_description,
              }))
          : [],
      rules: FIELDS['gate_investor']?.isRequired ? [{ required: true, message: 'This field is required!' }] : [],
    },
    {
      name: FIELDS['performance_reporting'].key,
      label: FIELDS['performance_reporting'].name,
      options:
        fundDataDropdown?.performance_reporting && fundDataDropdown.performance_reporting.length > 0
          ? fundDataDropdown.performance_reporting.map((item) => ({
              value: item,
              text: item,
            }))
          : [],
      rules: FIELDS['performance_reporting']?.isRequired
        ? [{ required: true, message: 'This field is required!' }]
        : [],
    },
    {
      name: FIELDS['use_of_leverage_type'].key,
      label: FIELDS['use_of_leverage_type'].name,
      options:
        fundDataDropdown?.use_of_leverage_type && fundDataDropdown.use_of_leverage_type.length > 0
          ? fundDataDropdown.use_of_leverage_type.map((item) => ({
              value: item.code_code,
              text: item.code_description,
              disabled: item.code_code === '5',
            }))
          : [],
      rules: FIELDS['use_of_leverage_type']?.isRequired ? [{ required: true, message: 'This field is required!' }] : [],
    },
    {
      name: FIELDS['bloomberg_ticker'].key,
      label: FIELDS['bloomberg_ticker'].name,
      maxLength: 20,
      rules: FIELDS['bloomberg_ticker']?.isRequired ? [{ required: true, message: 'This field is required!' }] : [],
    },
    {
      name: FIELDS['isin'].key,
      label: FIELDS['isin'].name,
      maxLength: 20,
      rules: FIELDS['isin']?.isRequired ? [{ required: true, message: 'This field is required!' }] : [],
    },
  ]
  const lockupField = form.getFieldValue('is_lockup') === '1'
  const isLiquidCheck = form.getFieldValue('liquid_alt_product') as boolean

  React.useEffect(() => {
    if (lockupField) {
      RequiredDataHelper.default().addRequiredFields({
        'Fund Structure': ['lockup_type', 'lockup_period'],
      })
    } else {
      RequiredDataHelper.default().removeRequiredFields({
        'Fund Structure': ['lockup_type', 'lockup_period'],
      })
    }
  }, [lockupField])

  return (
    <Flex>
      {!isLiquidCheck && (
        <Row>
          <Col span={12} xl={12} md={16} xs={24} sm={24}>
            <Form.Item
              name={FIELDS['is_offshore'].key}
              label={<RenderLabelExFund value={FIELDS['is_offshore'].name} />}
              {...{ wrapperCol: { span: 16 } }}
            >
              <Radio.Group disabled={fundStatusField === '2'}>
                <Radio value={false}>U.S.</Radio>
                <Radio value={true}>Offshore</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12} xl={12} md={16} xs={24} sm={24}>
            <FormItemSelect
              disabled={fundStatusField === '2'}
              name={FIELDS['domicile'].key}
              rules={[{ required: true, message: 'Domicile is required!' }]}
              label={<RenderLabelExFund value={FIELDS['domicile'].name} />}
              wrapperCol={16}
              options={
                fundDataDropdown?.domicile && fundDataDropdown.domicile.length > 0
                  ? fundDataDropdown.domicile.map((item) => ({ value: item, text: item }))
                  : []
              }
            />
          </Col>
          <Col span={12} xl={12} md={16} xs={24} sm={24}>
            <FormItemSelect
              disabled={fundStatusField === '2'}
              name={FIELDS['structure'].key}
              rules={[{ required: true, message: 'Structure is required!' }]}
              label={<RenderLabelExFund value={FIELDS['structure'].name} />}
              wrapperCol={16}
              options={
                fundDataDropdown?.structure && fundDataDropdown.structure.length > 0
                  ? fundDataDropdown.structure.map((item) => ({ value: item, text: item }))
                  : []
              }
            />
          </Col>
          <Col span={12} xl={12} md={16} xs={24} sm={24}>
            <FormItemSelect
              name={FIELDS['registration_code'].key}
              rules={[{ required: true, message: 'Fund Registration is required!' }]}
              label={<RenderLabelExFund value={FIELDS['registration_code'].name} />}
              wrapperCol={16}
              options={
                fundDataDropdown?.registration_code && fundDataDropdown.registration_code.length > 0
                  ? fundDataDropdown.registration_code.map((item) => ({ value: item, text: item }))
                  : []
              }
            />
          </Col>
        </Row>
      )}
      <Row>
        {LIST_FORM.map((item) => {
          if (item.name === 'redemption_return_percent' || item.name === 'redemption_return_days') {
            return (
              <Col span={12} xl={12} md={16} xs={24} sm={24} key={item.name}>
                <FormItemInput
                  name={item.name}
                  label={<RenderLabelExFund value={item.label} />}
                  wrapperCol={16}
                  maxLength={item.maxLength ? item.maxLength : undefined}
                  normalize={item.normalize}
                  typeField={item.typeField}
                  rules={[
                    ...item.rules,
                    item.name === 'redemption_return_percent'
                      ? () => ({
                          validator(rule, value) {
                            if ((value >= 0 && value <= 100) || value === null) {
                              return Promise.resolve()
                            }
                            return Promise.reject('Value must be between 0 and 100!')
                          },
                        })
                      : () => ({
                          validator(rule, value) {
                            if (value >= 0 || value === null) {
                              return Promise.resolve()
                            }
                            return Promise.reject('Value must be greater than or equal to 0!')
                          },
                        }),
                  ]}
                />
              </Col>
            )
          }
          if ((item.name === 'lockup_period' || item.name === 'lockup_type') && !lockupField) {
            return null
          }

          return (
            <Col span={12} xl={12} md={16} xs={24} sm={24} key={item.name}>
              {item.isRadio ? (
                <FormItemRadio
                  name={item.name}
                  label={<RenderLabelExFund value={item.label} />}
                  rules={item.rules}
                  options={
                    fundDataDropdown?.is_lockup && fundDataDropdown.is_lockup.length > 0
                      ? fundDataDropdown.is_lockup
                          .filter((i) => i.code_code !== '0')
                          .map((item) => ({ value: item.code_code, title: item.code_description }))
                      : []
                  }
                />
              ) : item.options ? (
                <FormItemSelect
                  name={item.name}
                  label={<RenderLabelExFund value={item.label} />}
                  wrapperCol={16}
                  options={item.options}
                  rules={item.rules}
                  selectProps={{ listHeight: item.name === 'use_of_leverage_type' ? 150 : undefined }}
                />
              ) : (
                <FormItemInput
                  name={item.name}
                  label={<RenderLabelExFund value={item.label} />}
                  wrapperCol={16}
                  maxLength={item.maxLength ? item.maxLength : undefined}
                  normalize={item.normalize}
                  typeField={item.typeField}
                  rules={item.rules}
                />
              )}
            </Col>
          )
        })}
      </Row>
    </Flex>
  )
}

RequiredDataHelper.default().registerRequirement(
  LeftNavExFundEnum.FUND_STRUCTURE,
  Object.keys(FIELDS).filter((item) => FIELDS[item as keyof FundStructureFieldsType].isRequired),
)
