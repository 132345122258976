import React from 'react'
import APIService from '..'
import { useServiceState } from '../../hooks/useServiceState'
import { AnalysisType } from '../models/Analysis'

export type AnalysisGroupListType = {
  data: AnalysisType[] | undefined
  loading: boolean
  refetchGroup: () => void
  addedRowTree?: number[]
  setAddedRow: (addedRow?: number) => void
}

export const AnalysisGroupListContext = React.createContext<AnalysisGroupListType>({
  data: undefined,
  loading: true,
  refetchGroup: () => undefined,
  setAddedRow: () => undefined,
})

export const AnalysisGroupListProvider: React.FC = ({ children }) => {
  const { invoke: getAllGroup, data, loading } = useServiceState(APIService.analysisService.getAllGroups)
  const [addedRow, setAddedRow] = React.useState<number>()

  const addedRowTree = React.useMemo(() => {
    if (!data || !data.length || !addedRow) {
      return
    }
    let parentId = data.find((item) => item.group_code === addedRow)?.parent_group_code
    const results = [addedRow]
    while (parentId && parentId !== -1) {
      results.push(parentId)
      parentId = data.find((item) => item.group_code === parentId)?.parent_group_code
    }
    return results
  }, [addedRow, data])

  const refetchGroup = () => {
    getAllGroup()
  }

  React.useEffect(() => {
    getAllGroup()
  }, [getAllGroup])

  const value = {
    data,
    loading,
    refetchGroup,
    addedRowTree,
    setAddedRow,
  }

  return <AnalysisGroupListContext.Provider value={value}>{children}</AnalysisGroupListContext.Provider>
}
