import { CaretRightOutlined } from '@ant-design/icons'
import { Collapse, Spin, Typography } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../app/rootReducer'
import APIService from '../../shared/api'
import { ArticlesResponse } from '../../shared/api/services/content.service'
import ExternalMainLayout from '../../shared/components/ExternalMainLayout'
import MainLayout from '../../shared/components/MainLayout'
import { useServiceState } from '../../shared/hooks/useServiceState'
const { Title } = Typography
const { Panel } = Collapse
const Container = styled.div`
  display: flex;
  flex-grow: 2;
  height: 100%;
  margin: 20px auto;
  flex-direction: column;
  justify-content: safe center;
  align-items: center;
  padding: 40px;
  padding-top: 20px;
  padding-bottom: 0;
  overflow-y: auto;
  & .ant-collapse-content {
    background: #f7f7f7;
    border-radius: 4px;
    margin-bottom: 25px;
    border: 0;
  }
`
const Wrapper = styled('div')`
  background: white;
  display: flex;
  width: 100%;
  padding: 10px 25px;
  padding-top: 0;
  flex-direction: column;
  height: 100%;
  min-height: -webkit-fill-available;
`
const Header = styled(Title)`
  position: relative;
  text-align: center;
  padding: 10px 0;
  height: auto;
  margin: 0.5rem 0 1.5rem 0;
  display: block;
`
export const HelpAndFaqMain: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.user)
  return user && user.hfrml_internal_user ? (
    <MainLayout>
      <RenderHelpAndFaq />
    </MainLayout>
  ) : (
    <ExternalMainLayout>
      <RenderHelpAndFaq />
    </ExternalMainLayout>
  )
}

export const RenderHelpAndFaq: React.FC = () => {
  const [articleData, setArticleData] = React.useState<{ [key: string]: string }>({})
  const { data: articlesData, invoke: invokeArticlesData, loading: articlesLoading } = useServiceState(
    APIService.contentService.fetchArticles,
  )

  const handleChange = async (key: string | string[]) => {
    if (typeof key === 'string') {
      key = [key]
    }
    key.forEach(async (item: string) => {
      // No api call if panel is previously loaded (opened)
      if (articleData[item] === undefined || articleData[item].trim() === '') {
        const { data } = await APIService.contentService.fetchArticleDetail(Number(item))
        setArticleData((prevArticles: { [key: string]: string }) => {
          return {
            ...prevArticles,
            [item]: data.content,
          }
        })
      }
    })
  }
  React.useEffect(() => {
    invokeArticlesData()
  }, [invokeArticlesData])
  return (
    <Container>
      <Wrapper>
        <Header level={4}>Help And FAQs</Header>
        <Spin spinning={articlesLoading}>
          <Collapse
            onChange={handleChange}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          >
            {articlesData &&
              articlesData.map((article: ArticlesResponse) => {
                return (
                  <Panel header={article.name} key={String(article.id)}>
                    <div dangerouslySetInnerHTML={{ __html: articleData[article.id] }} />
                  </Panel>
                )
              })}
          </Collapse>
        </Spin>
      </Wrapper>
    </Container>
  )
}
