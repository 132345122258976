import { DownloadOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, DatePicker, Input, notification, Pagination, Radio, Row, Select, Space, Table, Tag } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { SorterResult } from 'antd/lib/table/interface'
import axios from 'axios'
import JsFileDownload from 'js-file-download'
import moment from 'moment'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../app/rootReducer'
import APIService from '../../shared/api'
import { FundDetailDropdownContext } from '../../shared/api/context/FundDetailDropdownContext'
import { FirmDetails, FirmSearchColumnResponse } from '../../shared/api/models/FirmDetails'
import { FilterInfoColumn, FundDropdownCodeType, FundDropdownType } from '../../shared/api/models/FundDetails'
import { FilterTypeColumnTable } from '../../shared/api/models/Funds'
import { Colors } from '../../shared/colors'
import { useServiceState } from '../../shared/hooks/useServiceState'
import { LeftNavFirmEnum } from '../../shared/SearchFirmLabel'
import GenUtil from '../../shared/utils/gen-util'
import { addTabs, FundFirmTypeEnum } from '../../slice/appSettingsSlice'
import { updateFirmSearchParams } from '../Dashboard/DashboardTab/SearchForm/redux/searchParamsSlice'
import { FirmSearchColumnContext } from './ColumnBuilder/FirmSearchColumnContext'
import DeleteFirmModal from './DeleteFirmModal'
import FirmTableHeader from './FirmTableHeader'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
`

type Props = {}

const FirmSearch: React.FC<Props> = () => {
  const { fundDataDropdown, dataMinoritySearch } = React.useContext(FundDetailDropdownContext)
  const dispatch = useDispatch()
  const { data, loading, invoke: searchFirm } = useServiceState(APIService.firmService.searchFirms)
  const { firmSearchParams } = useSelector((state: RootState) => state.searchParams)

  const filteredInfo = React.useMemo(() => {
    if (Object.keys(firmSearchParams).length === 0) {
      return {}
    }
    const filtered = Object.keys(firmSearchParams).reduce((acc, key) => {
      if (key === 'is_minority_owned_firm' || key === 'is_minority_managed_fund') {
        return acc
      }
      return { ...acc, [key]: firmSearchParams[key]?.value }
    }, {} as any)

    return filtered
  }, [firmSearchParams])

  const { user } = useSelector((state: RootState) => state.user)
  const appData = useSelector((state: RootState) => state.appData)
  const searchInput = React.useRef<Input>(null)

  const { dataAllColumns } = React.useContext(FirmSearchColumnContext)

  const { firmSearchColumns } = useSelector((state: RootState) => state.appSettings)
  const [page, setPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(25)
  const [sortBy, setSortBy] = React.useState<string | undefined>()
  const [orderBy, setOrderBy] = React.useState<string | undefined>()

  const [selectedFirmIds, setSelectedFirmIds] = React.useState<string[]>()
  const [selectedFirmName, setSelectedFirmName] = React.useState<string>()
  const [showDeleteFirmModal, setShowDeleteFirmModal] = React.useState(false)

  const [loadingExport, setLoadingExport] = React.useState(false)

  //Fetch Legacy-Strategy
  const { invoke: getLegacyStrategy, data: dataLegacy } = useServiceState(
    APIService.fundDetailService.fetchLegacyStrategy,
  )
  React.useEffect(() => {
    getLegacyStrategy()
  }, [getLegacyStrategy])

  const handleTableChange = (sorter: SorterResult<any>) => {
    setSortBy(sorter.order ? sorter.columnKey?.toString() : undefined)
    setOrderBy(sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : undefined)
  }

  const fundSearchParamsResolved = React.useMemo(() => {
    setPage(1)
    if (Object.keys(filteredInfo).length === 0) {
      return {}
    }
    const searchParams: Partial<any> = Object.keys(filteredInfo).reduce((acc, key) => {
      if (filteredInfo[key] && dataAllColumns && dataAllColumns[key].filter_type) {
        switch (dataAllColumns[key].filter_type) {
          case 'boolean':
            return {
              ...acc,
              [key]: filteredInfo[key]?.[0],
            }
          case 'date':
          case 'year_month':
            const lteDate = filteredInfo[key]?.[1]
            const gteDate = filteredInfo[key]?.[0]
            return {
              ...acc,
              [key]: {
                lte: lteDate && lteDate !== '' ? lteDate : undefined,
                gte: gteDate && gteDate !== '' ? gteDate : undefined,
              },
            }
          case 'string_search':
            if (dataAllColumns[key].supports_exact_match === true) {
              const type = firmSearchParams[key].type
              if (type === 'contains') {
                return { ...acc, [key]: { contains: filteredInfo[key]?.[0] } }
              }
              if (type === 'equals') {
                return { ...acc, [key]: { equals: filteredInfo[key]?.[0] } }
              }
              return acc
            }
            return { ...acc, [key]: filteredInfo[key]?.[0] }

          case 'multiselect':
            return { ...acc, [key]: filteredInfo[key] }
          case 'numeric':
            const lte = filteredInfo[key]?.[1]
            const gte = filteredInfo[key]?.[0]
            return {
              ...acc,
              [key]: {
                lte: lte && lte !== '' ? Number(lte) : undefined,
                gte: gte && gte !== '' ? Number(gte) : undefined,
              },
            }
          case 'array_anyof':
            const type = firmSearchParams[key].type
            if (type === 'array_anyof') {
              return { ...acc, [key]: { array_anyof: filteredInfo[key as keyof FilterInfoColumn] } }
            }
          default:
            break
        }
      }
      return acc
    }, {})
    return searchParams
  }, [dataAllColumns, filteredInfo, firmSearchParams])

  const renderFooter = () => {
    return (
      <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Space>
          <Button type="primary" onClick={exportManagerList} loading={loadingExport}>
            <DownloadOutlined />
            Export
          </Button>
          {user?.can_delete_firm && (
            <Button danger onClick={() => setShowDeleteFirmModal(true)} disabled={selectedFirmIds?.length !== 1}>
              Delete
            </Button>
          )}
        </Space>
        <Pagination
          showSizeChanger
          size="small"
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          onChange={(currentPage) => setPage(currentPage)}
          total={data ? data.total : 0}
          current={page}
          pageSize={pageSize}
          pageSizeOptions={['10', '25', '50', '100']}
          onShowSizeChange={(current, size) => {
            setPageSize(size)
            if (current !== 1) {
              setPage(1)
            }
          }}
        />
      </Row>
    )
  }

  React.useEffect(() => {
    searchFirm({
      where: Object.keys(fundSearchParamsResolved).length > 0 ? fundSearchParamsResolved : undefined,
      page,
      limit: pageSize,
      sort_by: sortBy,
      order_by: orderBy,
      columns: firmSearchColumns,
    })
  }, [firmSearchColumns, fundSearchParamsResolved, orderBy, page, pageSize, searchFirm, sortBy])

  const refetch = () => {
    searchFirm({
      where: Object.keys(fundSearchParamsResolved).length > 0 ? fundSearchParamsResolved : undefined,
      page,
      limit: pageSize,
      sort_by: sortBy,
      order_by: orderBy,
      columns: firmSearchColumns,
    })
  }

  const exportManagerList = async () => {
    setLoadingExport(true)
    try {
      const res = await APIService.fundService.postDownloadFile({
        file_type: 'MANAGER_LINK_INTERNAL_FIRM_SEARCH_EXPORT',
        data: {
          where: Object.keys(fundSearchParamsResolved).length > 0 ? fundSearchParamsResolved : undefined,
          columns: firmSearchColumns,
        },
      })

      //const resFile = await APIService.fundService.downloadFileByUrl(res.data.url)
      const resFile = await axios.get(`${process.env.REACT_APP_AUTH_API_URL}/manager-link/files/${res.data.url}`)
      JsFileDownload(resFile.data, 'managerlist-results.csv', 'application/csv')
    } catch (error) {
      console.log(error.response)
      notification.error({ message: 'Failed to export Manager List!' })
    } finally {
      setLoadingExport(false)
    }
  }

  const handleClearAllFilter = () => {
    dispatch(updateFirmSearchParams({}))
  }
  const removeFromFilter = (dataIndex: string, clearFilters?: (() => void) | undefined) => {
    const editedSearch = Object.keys(firmSearchParams).reduce((acc, key) => {
      if (key === dataIndex) {
        return acc
      }
      return { ...acc, [key]: firmSearchParams[key] }
    }, {})
    dispatch(updateFirmSearchParams(editedSearch))
    clearFilters && clearFilters()
  }

  const addToFilter = (dataIndex: string, selectedKeys: React.ReactText[], confirm: (() => void) | undefined) => {
    dispatch(
      updateFirmSearchParams({
        ...firmSearchParams,
        [dataIndex]: { value: selectedKeys as string[] },
      }),
    )
    confirm && confirm()
  }

  const getColumnSearchProps = (
    dataIndex: string,
    filterType: FilterTypeColumnTable,
  ): ColumnProps<{ [key: string]: any }> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      switch (filterType) {
        case 'string_search':
          if (
            dataAllColumns &&
            dataAllColumns[dataIndex as keyof FirmSearchColumnResponse].supports_exact_match === true
          ) {
            return (
              <Row style={{ padding: 8 }}>
                <Space>
                  <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    allowClear
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    //onPressEnter={() => confirm && confirm()}
                  />
                  <Button
                    type="primary"
                    onClick={() => {
                      dispatch(
                        updateFirmSearchParams({
                          ...firmSearchParams,
                          [dataIndex]: { value: selectedKeys as string[], type: 'equals' },
                        }),
                      )

                      confirm && confirm()
                    }}
                  >
                    Exact
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      dispatch(
                        updateFirmSearchParams({
                          ...firmSearchParams,
                          [dataIndex]: { value: selectedKeys as string[], type: 'contains' },
                        }),
                      )
                      confirm && confirm()
                    }}
                  >
                    Partial
                  </Button>
                  <Button onClick={() => removeFromFilter(dataIndex, clearFilters)} icon={<ReloadOutlined />} />
                </Space>
              </Row>
            )
          }
          return (
            <Row style={{ padding: 8 }}>
              <Space>
                <Input
                  ref={searchInput}
                  placeholder={`Search ${dataIndex}`}
                  value={selectedKeys[0]}
                  allowClear
                  onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                  onPressEnter={() => addToFilter(dataIndex, selectedKeys, confirm)}
                />
                <Button
                  type="primary"
                  onClick={() => addToFilter(dataIndex, selectedKeys, confirm)}
                  icon={<SearchOutlined />}
                />
                <Button onClick={() => removeFromFilter(dataIndex, clearFilters)} icon={<ReloadOutlined />} />
              </Space>
            </Row>
          )

        case 'boolean':
          return (
            <Row style={{ padding: 8 }}>
              <Radio.Group
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys([e.target.value])}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
              <Space>
                <Button
                  type="primary"
                  onClick={() => addToFilter(dataIndex, selectedKeys, confirm)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button onClick={() => removeFromFilter(dataIndex, clearFilters)} size="small" style={{ width: 90 }}>
                  Reset
                </Button>
              </Space>
            </Row>
          )
        case 'date':
          return (
            <div style={{ padding: 8 }}>
              <DatePicker
                placeholder="GTE Day"
                value={selectedKeys[0] ? moment(selectedKeys[0].toString()) : undefined}
                onChange={(e) => {
                  setSelectedKeys([e ? moment(e).format('YYYY-MM-DD') : '', selectedKeys[1]])
                }}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <DatePicker
                placeholder="LTE Day"
                value={selectedKeys[1] ? moment(selectedKeys[1]) : undefined}
                onChange={(e) => {
                  setSelectedKeys([selectedKeys[0], e ? moment(e).format('YYYY-MM-DD') : ''])
                }}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <Space>
                <Button
                  type="primary"
                  onClick={() => addToFilter(dataIndex, selectedKeys, confirm)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button onClick={() => removeFromFilter(dataIndex, clearFilters)} size="small" style={{ width: 90 }}>
                  Reset
                </Button>
              </Space>
            </div>
          )
        case 'multiselect':
          let options: { label: string; value: string }[] = []
          const optionsDropdown = (fundDataDropdown && fundDataDropdown[dataIndex as keyof FundDropdownType]) || []
          switch (dataIndex) {
            case 'firm_denomination':
            case 'denomination':
              options = (optionsDropdown as Array<FundDropdownCodeType>).map((item) => ({
                value: item.code_code,
                label: item.code_code,
              }))
              break
            case 'strategy_new':
              if (!appData?.strategies || !appData?.strategies.length) {
                return
              }
              options = appData.strategies.map((item) => ({
                value: item.strategy_name.toString(),
                label: item.strategy_name.toString(),
              }))
              break
            case 'strategy':
              if (!dataLegacy?.legacy_strategies || !dataLegacy?.legacy_strategies?.length) {
                return
              }
              options = dataLegacy.legacy_strategies.map((item) => ({
                value: item.strategy_name.toString(),
                label: item.strategy_name.toString(),
              }))
              break
            case 'sub_strategy':
              if (!dataLegacy?.sub_strategies || !dataLegacy?.sub_strategies?.length) {
                return
              }
              options = dataLegacy.sub_strategies.map((item) => ({
                value: item.sub_strategy_name.toString(),
                label: item.sub_strategy_name.toString(),
              }))
              break
            case 'sub_strategy_new':
              if (!appData?.subStrategies || !appData?.subStrategies.length) {
                return
              }
              options = appData.subStrategies.map((item) => ({
                value: item.sub_strategy_name.toString(),
                label: item.sub_strategy_name.toString(),
              }))
              break
            default:
              options = (optionsDropdown as Array<FundDropdownCodeType | string>).map((item) =>
                typeof item === 'string'
                  ? { value: item, label: item }
                  : { value: item.code_description, label: item.code_description },
              )
          }

          return (
            <Row style={{ padding: 8 }}>
              <Space align="start">
                <Select
                  value={selectedKeys}
                  style={{ width: 200 }}
                  mode="multiple"
                  allowClear
                  onChange={(e) => setSelectedKeys(e as string[])}
                  options={options}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    if (selectedKeys.length > 0) {
                      return addToFilter(dataIndex, selectedKeys, confirm)
                    }
                    return removeFromFilter(dataIndex, clearFilters)
                  }}
                  icon={<SearchOutlined />}
                />
                <Button onClick={() => removeFromFilter(dataIndex, clearFilters)} icon={<ReloadOutlined />} />
              </Space>
            </Row>
          )
        case 'array_anyof':
          const newOptions: { value: any; label: any }[] | undefined = dataMinoritySearch?.map((item) => {
            return { value: item, label: item }
          })
          return (
            <Row style={{ padding: 8 }}>
              <Space align="start">
                <Select
                  value={selectedKeys}
                  style={{ width: 200 }}
                  mode="multiple"
                  allowClear
                  onChange={(e) => setSelectedKeys(e as string[])}
                  options={newOptions}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    if (selectedKeys.length > 0) {
                      dispatch(
                        updateFirmSearchParams({
                          ...firmSearchParams,
                          [dataIndex]: { value: selectedKeys as string[], type: 'array_anyof' },
                        }),
                      )
                      confirm && confirm()
                    } else {
                      removeFromFilter(dataIndex, clearFilters)
                    }
                  }}
                  icon={<SearchOutlined />}
                />
                <Button onClick={() => removeFromFilter(dataIndex, clearFilters)} icon={<ReloadOutlined />} />
              </Space>
            </Row>
          )
        case 'numeric':
          return (
            <div style={{ padding: 8 }} tabIndex={0}>
              <Input
                placeholder={`GTE ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys([e.target.value, selectedKeys[1]])}
                onPressEnter={() => addToFilter(dataIndex, selectedKeys, confirm)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
                type="number"
              />
              <Input
                placeholder={`LTE ${dataIndex}`}
                value={selectedKeys[1]}
                onChange={(e) => setSelectedKeys([selectedKeys[0], e.target.value])}
                onPressEnter={() => addToFilter(dataIndex, selectedKeys, confirm)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
                type="number"
              />
              <Space>
                <Button
                  type="primary"
                  onClick={() => addToFilter(dataIndex, selectedKeys, confirm)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button onClick={() => removeFromFilter(dataIndex, clearFilters)} size="small" style={{ width: 90 }}>
                  Reset
                </Button>
              </Space>
            </div>
          )
        case 'year_month':
          return (
            <div style={{ padding: 8 }}>
              <DatePicker
                picker="month"
                value={selectedKeys[0] ? moment(selectedKeys[0].toString()) : undefined}
                onChange={(e) => {
                  setSelectedKeys([e ? moment(e).format('YYYY-MM') : '', selectedKeys[1]])
                }}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <DatePicker
                picker="month"
                value={selectedKeys[1] ? moment(selectedKeys[1].toString()) : undefined}
                onChange={(e) => {
                  setSelectedKeys([selectedKeys[0], e ? moment(e).format('YYYY-MM') : ''])
                }}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <Space>
                <Button
                  type="primary"
                  onClick={() => addToFilter(dataIndex, selectedKeys, confirm)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                />

                <Button
                  onClick={() => removeFromFilter(dataIndex, clearFilters)}
                  size="small"
                  style={{ width: 90 }}
                  icon={<ReloadOutlined />}
                />
              </Space>
            </div>
          )
        default:
          break
      }
    },

    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select())
      }
    },
  })

  const columns = React.useMemo(() => {
    return dataAllColumns && firmSearchColumns.length
      ? firmSearchColumns.map((column) => {
          if (column === 'firm_name') {
            return {
              title: dataAllColumns[column].human_readable_name,
              dataIndex: column,
              key: column,
              sorter: true,
              render: (text: string) => <div style={{ color: Colors.primary, cursor: 'pointer' }}>{text}</div>,
              filter_type: dataAllColumns[column].filter_type,
              onCell: (record: FirmDetails) => ({
                onClick: () => {
                  dispatch(addTabs({ name: record.firm_name, id: record.firm_id, type: FundFirmTypeEnum.FIRMS }))
                },
              }),
            }
          }
          return {
            title: dataAllColumns[column].human_readable_name,
            dataIndex: column,
            key: column,
            sorter: true,
            filter_type: dataAllColumns[column].filter_type,
          }
        })
      : []
  }, [dataAllColumns, dispatch, firmSearchColumns])

  const renderFilterSection = () => {
    return (
      <Row style={{ marginBottom: 8 }} align="middle">
        {Object.keys(filteredInfo).map((item) => {
          const value = filteredInfo[item]
          if (!value || !dataAllColumns || !dataAllColumns[item]) {
            return
          }
          if (typeof value[0] === 'boolean') {
            return (
              <Tag
                key={item}
                closable
                onClose={(e: any) => {
                  e.stopPropagation()
                  removeFromFilter(item)
                  // dispatch(updateSearchParams({ ...fundSearchParamsRedux, [item]: null }))
                }}
              >
                <strong>{dataAllColumns[item].human_readable_name}: </strong>
                <span>{value[0] === true ? 'Yes' : 'No'}</span>
              </Tag>
            )
          }
          if (dataAllColumns[item].filter_type === 'numeric' || dataAllColumns[item].filter_type === 'date') {
            return (
              <Tag
                key={item}
                closable
                onClose={(e: any) => {
                  e.stopPropagation()
                  removeFromFilter(item)
                  // dispatch(updateSearchParams({ ...fundSearchParamsRedux, [item]: null }))
                }}
              >
                <strong>{dataAllColumns[item].human_readable_name}: </strong>

                <span>{value[0] && value[0] !== '' ? value[0] : 'N/A'}</span>
                <span>{value[1] && value[1] !== '' ? '- ' + value[1] : ' - N/A'}</span>
              </Tag>
            )
          }
          if (dataAllColumns[item].supports_exact_match === true) {
            const type = firmSearchParams[item].type
            return (
              <Tag
                key={item}
                closable
                onClose={(e: any) => {
                  e.stopPropagation()
                  removeFromFilter(item)
                }}
              >
                <strong>{dataAllColumns[item].human_readable_name}: </strong>
                {type && <span>[{type.toUpperCase()}] </span>}
                <span>{value.join(', ') || null}</span>
              </Tag>
            )
          }
          return (
            <Tag
              key={item}
              closable
              onClose={(e: any) => {
                e.stopPropagation()
                removeFromFilter(item)
              }}
            >
              <strong>{dataAllColumns[item].human_readable_name}: </strong>
              <span>{value.join(', ') || null}</span>
            </Tag>
          )
        })}
        {Object.keys(filteredInfo).filter((item) => !!filteredInfo[item]).length > 1 && (
          <Button type="link" onClick={handleClearAllFilter}>
            Clear All
          </Button>
        )}
      </Row>
    )
  }

  return (
    <Wrapper>
      <FirmTableHeader />
      {renderFilterSection()}
      <Table
        bordered
        rowKey="firm_id"
        rowSelection={{
          selectedRowKeys: selectedFirmIds,
          onChange: (_, selectedRows) => {
            setSelectedFirmIds(selectedRows.map((row) => row.firm_id))
            if (selectedRows.length === 1) {
              setSelectedFirmName(selectedRows[0].firm_name)
            }
          },
        }}
        // @ts-ignore
        columns={
          columns && columns.length
            ? columns.map((item, columnIndex) => {
                if (item.key === 'firm_assets') {
                  return {
                    ...item,
                    width: 150,
                    filteredValue: filteredInfo[item.key] || null,
                    ...getColumnSearchProps(item.key, item.filter_type),
                    render: (text: number, record: FirmDetails) => (
                      <div style={{ color: Colors.primary, cursor: 'pointer' }}>
                        {text
                          ? Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: record.denomination || 'USD',
                              maximumFractionDigits: 0,
                              minimumFractionDigits: 0,
                            }).format(text)
                          : ''}
                      </div>
                    ),
                    onCell: (record: FirmDetails) => ({
                      onClick: () => {
                        dispatch(
                          addTabs({
                            name: record.firm_name,
                            id: record.firm_id,
                            type: FundFirmTypeEnum.FIRMS,
                            path: LeftNavFirmEnum.FIRM_ASSETS,
                          }),
                        )
                      },
                    }),
                  }
                }
                if (item.key === 'is_firm_esg') {
                  return {
                    ...item,
                    sorter: true,
                    width: 200,
                    filteredValue: filteredInfo[item.key] || null,
                    ...getColumnSearchProps(item.key, item.filter_type),
                    render: (text: string) => <div style={{ color: Colors.primary, cursor: 'pointer' }}>{text}</div>,
                    onCell: (record: FirmDetails) => ({
                      onClick: () => {
                        dispatch(
                          addTabs({
                            name: record.firm_name,
                            id: record.firm_id,
                            type: FundFirmTypeEnum.FIRMS,
                            path: LeftNavFirmEnum.FIRM_ESG,
                            hyperlinkTable: 'firm_esg',
                          }),
                        )
                      },
                    }),
                  }
                }
                if (item.filter_type === 'boolean') {
                  return {
                    ...item,
                    sorter: true,
                    width: 200,
                    filteredValue: filteredInfo[item.key] || null,
                    ...getColumnSearchProps(item.key, item.filter_type),
                    render: (value: boolean | undefined) => {
                      if (value) {
                        return 'Yes'
                      }
                      if (value === false) {
                        return 'No'
                      }
                      return null
                    },
                  }
                }
                if (item.filter_type === 'year_month') {
                  return {
                    ...item,
                    sorter: true,
                    width: 200,
                    fixed: columnIndex === 0,
                    filteredValue: filteredInfo[item.key] || null,
                    ...getColumnSearchProps(item.key, item.filter_type),
                    render: (date: string | undefined) => {
                      return date ? GenUtil.getFormattedYearAndMonth(date) : null
                    },
                  }
                }
                if (item.filter_type === 'date') {
                  return {
                    ...item,
                    sorter: true,
                    width: 200,
                    fixed: columnIndex === 0,
                    filteredValue: filteredInfo[item.key] || null,
                    ...getColumnSearchProps(item.key, item.filter_type),
                    render: (date: string | undefined) => {
                      return date ? GenUtil.getFormattedYearMonthAndDay(date) : null
                    },
                  }
                }
                return {
                  ...item,
                  filteredValue: filteredInfo[item.key] || null,
                  sorter: item.sorter,
                  width: 200,
                  ...getColumnSearchProps(item.key, item.filter_type),
                }
              })
            : []
        }
        dataSource={data?.firms}
        loading={loading}
        size="small"
        scroll={{ y: 'calc(100vh - 400px)', x: 'max-content' }}
        pagination={false}
        onChange={(_pagination, _filter, sorter: any) => {
          handleTableChange(sorter)
        }}
        footer={(record) => (record.length > 0 ? renderFooter() : null)}
      />
      {showDeleteFirmModal && selectedFirmIds && selectedFirmName && selectedFirmIds.length === 1 && (
        <DeleteFirmModal
          selectedFirmId={selectedFirmIds[0]}
          selectedFirmName={selectedFirmName}
          isOpen={showDeleteFirmModal}
          onRequestClose={() => setShowDeleteFirmModal(false)}
          onSubmitDeleting={() => {
            setSelectedFirmIds(undefined)
            setSelectedFirmName(undefined)
            refetch()
          }}
        />
      )}
    </Wrapper>
  )
}

export default FirmSearch
