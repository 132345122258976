import { AxiosInstance, AxiosPromise } from 'axios'
import { AnalysisStats, AnalysisType, FundListType, VAMIResponse } from '../models/Analysis'

export type HistoricalSourceType = 'peer_group'
export type HistoricalType = 'aum' | 'ror'
export type HistoricalPeriodType = 'monthly' | 'yearly'

export type HistoricalDataParams = {
  sourceType: HistoricalSourceType
  dataType: HistoricalType
  period: HistoricalPeriodType
  entityId: number
}

export interface AnalysisService {
  getAllGroups: () => AxiosPromise<AnalysisType[]>
  getOwnerGroups: () => AxiosPromise<AnalysisType[]>
  getDetailsGroup: (groupCode: number) => AxiosPromise<FundListType>
  createGroup: (
    name: string,
    parentGroupCode: number,
    cloneCode?: number,
  ) => AxiosPromise<{ group_code: number; message: string }>
  updateGroup: ({
    groupCode,
    fundIds,
    parentGroupCode,
    deletedFundIds,
    user,
  }: {
    groupCode: number
    fundIds?: string[]
    parentGroupCode?: number
    deletedFundIds?: string[]
    user?: string
  }) => AxiosPromise<void>
  deleteGroup: (groupCode: number) => AxiosPromise<void>
  fetchGroupStats: (groupCode: number) => AxiosPromise<AnalysisStats>
  peerGroupVAMI: ({
    groupCode,
    b1,
    b2,
    b3,
  }: {
    groupCode: number
    b1: string
    b2: string
    b3: string
  }) => AxiosPromise<VAMIResponse>
  exportHistoricalData: (params: HistoricalDataParams) => AxiosPromise<string>
}
export const AnalysisService = (request: AxiosInstance): AnalysisService => {
  return {
    getAllGroups: () => request.get(`/manager-link/groups`),
    getOwnerGroups: () => request.get(`/manager-link/groups?owned_by_user=true`),
    getDetailsGroup: (groupCode) => request.get(`/manager-link/groups/${groupCode}`),
    createGroup: (name, parentGroupCode, cloneCode) =>
      request.post(`/manager-link/groups`, {
        name,
        parent_group_code: parentGroupCode,
        clone_code: cloneCode,
      }),
    updateGroup: ({ groupCode, fundIds, parentGroupCode, deletedFundIds, user }) =>
      request.put(`/manager-link/groups/${groupCode}`, {
        fund_ids: fundIds,
        parent_group_code: parentGroupCode,
        delete_fund_ids: deletedFundIds,
        username: user,
      }),
    deleteGroup: (groupCode) => request.delete(`/manager-link/groups/${groupCode}`),
    fetchGroupStats: (groupCode) =>
      request.get(`/manager-link/groups/${groupCode}/stats
    `),
    peerGroupVAMI: ({ groupCode, b1, b2, b3 }) =>
      request.post(`/manager-link/groups/${groupCode}/vami`, {
        benchmark1: b1,
        benchmark2: b2,
        benchmark3: b3,
      }),
    exportHistoricalData: (params) =>
      request.post(
        '/historical-data/export',
        {
          source_type: params.sourceType,
          data_type: params.dataType,
          period: params.period,
          entity_id: params.entityId,
        },
        {
          responseType: 'blob',
        },
      ),
  }
}
