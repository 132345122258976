export enum LeftNavExNewFundEnum {
  INSTRUCTION = 'Instruction',
  CREATE_FUND_PROFILE = 'Step 1 - Create Fund Profile',
  INVESTMENT_STRATEGY = 'Step 2 - Investment Strategy',
  INVESTMENT_REQUIREMENTS = 'Step 3 - Investment Requirements',
  CONTACT_INFORMATION = 'Step 4 - Contact Information',
  STRUCTURE = 'Step 5 - Structure',
  FEE_SCHEDULE = 'Step 6 - Fee Schedule',
  SERVICE_PROVIDERS = 'Step 7 - Service Providers',
  FUND_ESG_FACTORS = 'Step 8 - Fund ESG Factors',
  FUND_DIVERSITY_INCLUSION = 'Step 9 - Fund Diversity & Inclustion',
  FUND_PERFORMANCE = 'Step 10 - Fund Performance',
  FUND_CMIC = 'Step 11 - CMIC',
  COMPLETE = 'Step 12 - Complete',
}
export interface ListSearchExNewFundType {
  key: string
  name: string
  leftNav: LeftNavExNewFundEnum
}
export const renderSearchListExNewFund: ListSearchExNewFundType[] = [
  {
    key: 'enter_fund_name',
    name: 'Enter Fund Name',
    leftNav: LeftNavExNewFundEnum.CREATE_FUND_PROFILE,
  },
]
