import { Checkbox, Form, Input, notification } from 'antd'
import { useForm } from 'antd/lib/form/util'
import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import APIService from '../../shared/api'
import { AnalysisGroupListContext } from '../../shared/api/context/AnalysisGroupListContext'
import GroupTable from './GroupTable'

type Props = {
  isOpen: boolean
  onRequestClose: () => void
}

const AddGroupModal: React.FC<Props> = ({ isOpen, onRequestClose }) => {
  const [parentGroup, setParentGroup] = React.useState<number>()
  const [submitting, setSubmitting] = React.useState<boolean>(false)
  const [isAddToSubGroup, setAddToSubGroup] = React.useState(false)

  const { refetchGroup, setAddedRow } = React.useContext(AnalysisGroupListContext)

  const [form] = useForm()

  const handleSubmit = () => {
    form.validateFields(['name']).then(() => {
      const name = form.getFieldValue('name')
      setSubmitting(true)
      APIService.analysisService
        .createGroup(name, parentGroup || -1)
        .then((res) => {
          notification.success({ message: `${name} Group created!` })
          onRequestClose()
          refetchGroup()
          setAddedRow && setAddedRow(res.data.group_code)
        })
        .catch((err) => {
          console.error(err)
          notification.error({ message: 'Failed To Create Group!' })
        })
        .finally(() => {
          setSubmitting(false)
          setAddToSubGroup(false)
          form.resetFields()
        })
    })
  }

  return (
    <Modal
      width={600}
      visible={isOpen}
      title={`Add Group`}
      onCancel={() => {
        onRequestClose()
        setAddToSubGroup(false)
        form.resetFields()
      }}
      onOk={handleSubmit}
      confirmLoading={submitting}
    >
      <Form layout="vertical" form={form}>
        <Form.Item label="Group Name" name="name" required rules={[{ required: true, message: 'Name is required!' }]}>
          <Input placeholder="Enter New Group Name" />
        </Form.Item>
        <Checkbox
          style={{ marginBottom: 8 }}
          checked={isAddToSubGroup}
          onChange={(e) => {
            if (e.target.checked) {
              setParentGroup(undefined)
            }
            setAddToSubGroup(e.target.checked)
          }}
        >
          Add To Sub-Group
        </Checkbox>
        {isAddToSubGroup && (
          <Form.Item>
            <GroupTable isShownInModal onSelectGroup={(group) => setParentGroup(group.group_code)} />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

export default AddGroupModal
