import { Col, Radio, Row } from 'antd'
import Form, { FormInstance } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'
import { FormItemAutoComplete, FormItemCheckBox, FormItemInput } from '../../../shared/components/FormItemComponents'
import { RenderLabelExFund } from '../../../shared/components/RenderLabelExFund'
import { useFieldValidate } from '../../../shared/hooks/useEffectFieldValidate'
import { LeftNavExFundEnum } from '../../../shared/SearchExFundLabel'
import { FormFieldType } from '../ExFundDetailMain'
import { RequiredDataHelper } from '../RequiredDataHelper'

const Flex = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 1rem;
  padding: 0 2rem;
`

const OPTION_INCENTIVE_FEE = [
  {
    text: '10',
    value: '10',
  },
  {
    text: '15',
    value: '15',
  },
  {
    text: '20',
    value: '20',
  },
]

type FundFeeStrategyFieldsType = Pick<
  FormFieldType,
  | 'mgmt_fee'
  | 'redemption_fee'
  | 'administrative_fee'
  | 'redemption_fee_months'
  | 'incentive_fee'
  | 'sales_fee'
  | 'max_12b1_fee'
  | 'max_front_fee'
  | 'max_deferred_fee'
  | 'is_high_watermark'
  | 'hurdle_rate'
>

const FIELDS: FundFeeStrategyFieldsType = {
  mgmt_fee: {
    key: 'mgmt_fee',
    name: 'Management Fee %',
    isRequired: true,
    maxLength: 30,
  },
  redemption_fee: {
    key: 'redemption_fee',
    name: 'Redemption Fee %',
  },
  administrative_fee: {
    key: 'administrative_fee',
    name: 'Administrative Fee %',
    isRequired: true,
  },
  redemption_fee_months: {
    key: 'redemption_fee_months',
    name: 'If less than (number of months)',
  },
  incentive_fee: {
    key: 'incentive_fee',
    name: 'Incentive Fee %',
    isRequired: true,
  },
  sales_fee: {
    key: 'sales_fee',
    name: 'Sales Fee %',
  },
  max_12b1_fee: {
    key: 'max_12b1_fee',
    name: 'Maximum 12B-1 Fee %',
    isRequired: true,
  },
  max_front_fee: {
    key: 'max_front_fee',
    name: 'Maximum Front-End Sales Load Fee %',
    isRequired: true,
  },
  max_deferred_fee: {
    key: 'max_deferred_fee',
    name: 'Maximum Deferred Sales Load Fee %',
    isRequired: true,
  },
  is_high_watermark: {
    key: 'is_high_watermark',
    name: 'High Watermark',
    isRequired: true,
  },
  hurdle_rate: {
    key: 'hurdle_rate',
    name: 'Hurdle Rate',
    maxLength: 30,
  },
}

type Props = {
  isDirty: boolean
  form: FormInstance
  onUpdateTabDirty: (id: LeftNavExFundEnum, isDirty: boolean) => void
}

export const ExFundFeeSchedule: React.FC<Props> = ({ isDirty, form, onUpdateTabDirty }) => {
  useFieldValidate(
    Object.keys(FIELDS).filter((item) => FIELDS[item as keyof FundFeeStrategyFieldsType].isRequired),
    isDirty,
    form,
    onUpdateTabDirty,
    LeftNavExFundEnum.FEE_SCHEDULE,
  )
  const isLiquidCheck = form.getFieldValue('liquid_alt_product') as boolean

  const checkHurdleRate = form.getFieldValue('is_hurdle_rate')

  React.useEffect(() => {
    if (checkHurdleRate) {
      RequiredDataHelper.default().addRequiredFields({
        'Fee Schedule': ['hurdle_rate'],
      })
    } else {
      RequiredDataHelper.default().removeRequiredFields({
        'Fee Schedule': ['hurdle_rate'],
      })
    }
  }, [checkHurdleRate])

  return (
    <Flex>
      <Row>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemInput
            name={FIELDS['mgmt_fee'].key}
            label={<RenderLabelExFund value={FIELDS['mgmt_fee'].name} />}
            wrapperCol={16}
            rules={[
              { required: true, message: 'This field is required!' },
              {
                max: FIELDS['mgmt_fee'].maxLength,
                message: `Value should be less than ${FIELDS['mgmt_fee'].maxLength} characters`,
              },
            ]}
          />
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <Row gutter={16}>
            <Col span={12}>
              <FormItemInput
                name={FIELDS['redemption_fee'].key}
                label={<RenderLabelExFund value={FIELDS['redemption_fee'].name} />}
                wrapperCol={24}
                typeField="number"
                rules={
                  FIELDS['redemption_fee'].isRequired ? [{ required: true, message: 'This field is required!' }] : []
                }
              />
            </Col>
            <Col span={12}>
              <FormItemInput
                name={FIELDS['redemption_fee_months'].key}
                label={<RenderLabelExFund value={FIELDS['redemption_fee_months'].name} />}
                wrapperCol={24}
                normalize="number"
                typeField="number"
                rules={
                  FIELDS['redemption_fee_months'].isRequired
                    ? [{ required: true, message: 'This field is required!' }]
                    : []
                }
              />
            </Col>
          </Row>
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemInput
            name={FIELDS['administrative_fee'].key}
            label={<RenderLabelExFund value={FIELDS['administrative_fee'].name} />}
            wrapperCol={16}
            maxLength={30}
            rules={
              FIELDS['administrative_fee'].isRequired ? [{ required: true, message: 'This field is required!' }] : []
            }
          />
        </Col>
      </Row>

      {!isLiquidCheck && (
        <Row>
          <Col span={12} xl={12} md={16} xs={24} sm={24}>
            <FormItemAutoComplete
              name={FIELDS['incentive_fee'].key}
              label={<RenderLabelExFund value={FIELDS['incentive_fee'].name} />}
              wrapperCol={16}
              isKeepOptions
              maxLength={30}
              options={OPTION_INCENTIVE_FEE}
              rules={FIELDS['incentive_fee'].isRequired ? [{ required: true, message: 'This field is required!' }] : []}
            />
          </Col>

          <Col span={12} xl={12} md={16} xs={24} sm={24}>
            <FormItemInput
              name={FIELDS['sales_fee'].key}
              label={<RenderLabelExFund value={FIELDS['sales_fee'].name} />}
              wrapperCol={16}
              maxLength={30}
              rules={FIELDS['sales_fee'].isRequired ? [{ required: true, message: 'This field is required!' }] : []}
            />
          </Col>
        </Row>
      )}
      {!isLiquidCheck && (
        <Row>
          <Col span={12} xl={12} md={16} xs={24} sm={24}>
            <Form.Item
              name={FIELDS['is_high_watermark'].key}
              label={<RenderLabelExFund value={FIELDS['is_high_watermark'].name} />}
              rules={[{ required: true, message: 'High Watermark is required' }]}
              {...{ wrapperCol: { span: 16 } }}
            >
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12} xl={12} md={16} xs={24} sm={24}>
            {checkHurdleRate ? (
              <FormItemInput
                name={FIELDS['hurdle_rate'].key}
                labelCol={6}
                wrapperCol={12}
                maxLength={30}
                rules={
                  form.getFieldValue('is_hurdle_rate')
                    ? [{ required: true, message: 'Hurdle Rate is required' }]
                    : undefined
                }
                label={
                  <>
                    <div style={{ marginTop: '0.5rem', marginRight: '0.5rem' }}>
                      <FormItemCheckBox name="is_hurdle_rate" />
                    </div>
                    <RenderLabelExFund value="Hurdle Rate" />
                  </>
                }
              />
            ) : (
              <FormItemCheckBox name="is_hurdle_rate" text={<RenderLabelExFund value="Hurdle Rate" />} />
            )}
          </Col>
        </Row>
      )}
      {isLiquidCheck && (
        <Row style={{ marginTop: '1rem' }}>
          <div style={{ fontStyle: 'italic' }}>
            <b>Note: </b> Fees below applicable only to Liquid Alternative Products
          </div>
        </Row>
      )}
      {isLiquidCheck && (
        <Row>
          <Col span={12} xl={12} md={16} xs={24} sm={24}>
            <FormItemInput
              name={FIELDS['max_12b1_fee'].key}
              label={<RenderLabelExFund value={FIELDS['max_12b1_fee'].name} />}
              wrapperCol={16}
              maxLength={30}
              rules={FIELDS['max_12b1_fee'].isRequired ? [{ required: true, message: 'This field is required!' }] : []}
            />
          </Col>
          <Col span={12} xl={12} md={16} xs={24} sm={24}>
            <FormItemInput
              name={FIELDS['max_front_fee'].key}
              label={<RenderLabelExFund value={FIELDS['max_front_fee'].name} />}
              wrapperCol={16}
              maxLength={30}
              rules={FIELDS['max_front_fee'].isRequired ? [{ required: true, message: 'This field is required!' }] : []}
            />
          </Col>
          <Col span={12} xl={12} md={16} xs={24} sm={24}>
            <FormItemInput
              name={FIELDS['max_deferred_fee'].key}
              label={<RenderLabelExFund value={FIELDS['max_deferred_fee'].name} />}
              wrapperCol={16}
              maxLength={30}
              rules={
                FIELDS['max_deferred_fee'].isRequired ? [{ required: true, message: 'This field is required!' }] : []
              }
            />
          </Col>
        </Row>
      )}
    </Flex>
  )
}

RequiredDataHelper.default().registerRequirement(
  LeftNavExFundEnum.FEE_SCHEDULE,
  Object.keys(FIELDS).filter((item) => FIELDS[item as keyof FundFeeStrategyFieldsType].isRequired),
)
