import { Col, Modal, Row, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React from 'react'

type Props = {
  onOk: () => void
  onCancel: () => void
  isOpen: boolean
  flaggedRors?: { date: string; value: number }[]
  flaggedAssets?: { date: string; value: number }[]
}

const DataConfirmationModal: React.FC<Props> = ({ isOpen, onOk, onCancel, flaggedAssets, flaggedRors }) => {
  const rorColumns: ColumnProps<{ date: string; value: number }>[] = [
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
    },
    {
      title: 'Value',
      key: 'value',
      dataIndex: 'value',
      render: (value) => {
        return value.toFixed(4)
      },
    },
  ]
  const assetColumns: ColumnProps<{ date: string; value: number }>[] = [
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
    },
    {
      title: 'Value',
      key: 'value',
      dataIndex: 'value',
      render: (value) => {
        return value.toFixed(3)
      },
    },
  ]

  return (
    <Modal title="Data Confirmation Check" visible={isOpen} onOk={onOk} okText="Submit" onCancel={onCancel}>
      <span>
        <p>Unusual data changed in Fund Return/AUM detected.</p>
        <Row gutter={8} style={{ marginBottom: 16 }}>
          {flaggedRors && flaggedRors.length > 0 && (
            <Col span={flaggedAssets && flaggedAssets.length > 0 ? 12 : 24}>
              <span style={{ fontWeight: 'bold', marginLeft: 8 }}>ROR</span>
              <Table
                bordered
                rowKey="date"
                size="small"
                dataSource={flaggedRors}
                columns={rorColumns}
                pagination={false}
              />
            </Col>
          )}
          {flaggedAssets && flaggedAssets.length > 0 && (
            <Col span={flaggedRors && flaggedRors.length > 0 ? 12 : 24}>
              <span style={{ fontWeight: 'bold', marginLeft: 8 }}>Asset</span>
              <Table
                bordered
                rowKey="date"
                size="small"
                dataSource={flaggedAssets}
                columns={assetColumns}
                pagination={false}
              />
            </Col>
          )}
        </Row>
        <p>
          If the changes are correct, click <b>Submit</b> to submit the update. Or click cancel to continue editing.
        </p>
      </span>
    </Modal>
  )
}

export default DataConfirmationModal
