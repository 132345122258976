import { LockOutlined, MailOutlined, NumberOutlined, QuestionCircleFilled } from '@ant-design/icons'
import { Button, Divider, Form, Input, notification, Row } from 'antd'
import { Store } from 'antd/lib/form/interface'
import { useForm } from 'antd/lib/form/util'
import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import APIService from '../../../shared/api'
import AuthLayout from '../AuthLayout'

const Container = styled.div`
  background: white;
  width: 30%;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: safe center;
  align-items: center;
  padding: 0 40px;
  overflow-y: auto;
  form {
    margin: auto;
    padding: 40px 0;
    width: 100%;
  }
`

const Inline = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div {
    flex-grow: 1;
    margin-right: 10px;
    &:last-child {
      margin: 0;
    }
  }
`

type Props = RouteComponentProps

export const ForgotPassword: React.FC<Props> = ({ history }) => {
  const [form] = useForm()
  const [showPasswordField, setShowPasswordField] = React.useState(false)
  const [confirmDirty, setConfirmDirty] = React.useState(false)

  const [isSubmitting, setSubmitting] = React.useState(false)

  const handleSubmit = (values: Store) => {
    // e.preventDefault()

    setSubmitting(true)
    if (!showPasswordField) {
      APIService.authService
        .requestPasswordReset(values.email)
        .then(() => {
          notification.success({ message: 'Check your email for password reset code!' })
          setShowPasswordField(true)
        })
        .catch((err) => {
          console.error(err)
          notification.error({ message: 'Something went wrong!' })
        })
        .finally(() => {
          setSubmitting(false)
        })
    } else {
      APIService.authService
        .resetPassword(values.email, values.code.trim(), values.password)
        .then(() => {
          notification.success({ message: 'Password updated!' })
          history.push('/auth/log-in')
        })
        .catch((err) => {
          console.error(err)
          notification.error({ message: 'Something went wrong!' })
        })
        .finally(() => {
          setSubmitting(false)
        })
    }
  }

  const handleConfirmBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target
    setConfirmDirty(confirmDirty || !!value)
  }

  return (
    <AuthLayout title="Forgot Password">
      <Container>
        <Form onFinish={handleSubmit} layout="horizontal" form={form}>
          <Divider orientation={'left'}>Enter email to recover your password</Divider>
          <Row>
            <Form.Item
              style={{ width: '100%' }}
              name="email"
              rules={[
                { required: true, message: 'Email is required' },
                { type: 'email', message: 'Email must be valid' },
              ]}
            >
              <Input
                formNoValidate={true}
                prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="email"
                placeholder="Email"
              />
            </Form.Item>
          </Row>
          {showPasswordField && (
            <>
              <Row>
                <Form.Item
                  name="code"
                  style={{ width: '100%' }}
                  rules={[{ required: true, message: 'Verification is required' }]}
                >
                  <Input
                    formNoValidate={true}
                    prefix={<NumberOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Enter verification code"
                  />
                </Form.Item>
              </Row>
              <Row>
                <Inline>
                  <Form.Item
                    hasFeedback
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                      {
                        pattern: RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'),
                        message: (
                          <>
                            <QuestionCircleFilled style={{ marginRight: 5 }} />
                            <span>Password is invalid</span>
                          </>
                        ),
                      },
                      // {
                      //   validator: validateToNextPassword,
                      // },
                    ]}
                  >
                    <Input.Password
                      prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Enter New Password"
                    />
                  </Form.Item>
                  <Form.Item
                    hasFeedback
                    name="confirmPassword"
                    dependencies={['password']}
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve()
                          }
                          return Promise.reject('The two passwords that you entered do not match!')
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      onBlur={handleConfirmBlur}
                      prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Enter Confirm Password"
                    />
                  </Form.Item>
                </Inline>
                {!!form.getFieldError('password') && (
                  <Inline>
                    Make sure it&apos;s at least 8 characters including 1 number, 1 lowercase, 1 uppercase letter and 1
                    special character.
                  </Inline>
                )}
              </Row>
            </>
          )}
          <Row style={{ display: 'flex', marginTop: 16 }}>
            <Button
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ flex: 2 }}
            >
              Submit
            </Button>
            <Link to="/auth/log-in" style={{ flex: 1 }}>
              <Button type="link" style={{ width: '100%' }}>
                Cancel
              </Button>
            </Link>
          </Row>
        </Form>
      </Container>
    </AuthLayout>
  )
}
