import { Col, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'
import { FundDetailDropdownContext } from '../../../shared/api/context/FundDetailDropdownContext'
import {
  FormItemDatePicker,
  FormItemInput,
  FormItemRadio,
  FormItemSelect,
} from '../../../shared/components/FormItemComponents'
import { RenderLabelExFund } from '../../../shared/components/RenderLabelExFund'
import { useFieldValidate } from '../../../shared/hooks/useEffectFieldValidate'
import { LeftNavExFundEnum } from '../../../shared/SearchExFundLabel'
import { FormFieldType } from '../ExFundDetailMain'
import { RequiredDataHelper } from '../RequiredDataHelper'

const Flex = styled.div`
  display: flex;
  flex: 1;
  margin-top: 1rem;
  flex-direction: column;
  padding: 0 2rem;
`

type FundServiceProviderFieldsType = Pick<
  FormFieldType,
  | 'is_annual_performance_audit'
  | 'last_audit_date'
  | 'auditor_name'
  | 'legal_adviser'
  | 'prime_broker'
  | 'administrator'
  | 'placement_agent'
  | 'banking_agent'
  | 'custodian'
  | 'consultant'
>

const FIELDS: FundServiceProviderFieldsType = {
  is_annual_performance_audit: {
    key: 'is_annual_performance_audit',
    name: 'Annual Performance Audit',
    isRequired: true,
  },
  last_audit_date: {
    key: 'last_audit_date',
    name: 'Last Audit Date',
  },
  auditor_name: {
    key: 'auditor_name',
    name: 'Auditor Name',
    isRequired: true,
  },
  legal_adviser: {
    key: 'legal_adviser',
    name: 'Legal Adviser',
    isRequired: true,
  },
  prime_broker: {
    key: 'prime_broker',
    name: 'Prime Broker',
    isRequired: true,
  },
  administrator: {
    key: 'administrator',
    name: 'Administrator',
    isRequired: true,
  },
  placement_agent: {
    key: 'placement_agent',
    name: 'Placement Agent (Enter None, If Not Applicable)',
    isRequired: true,
  },
  banking_agent: {
    key: 'banking_agent',
    name: 'Banking Agent (Enter None, If Not Applicable)',
    isRequired: true,
  },
  custodian: {
    key: 'custodian',
    name: 'Custodian (Enter None, If Not Applicable)',
    isRequired: true,
  },
  consultant: {
    key: 'consultant',
    name: 'Consultant (Enter None, If Not Applicable)',
    isRequired: true,
  },
}

type Props = {
  isDirty: boolean
  form: FormInstance
  onUpdateTabDirty: (id: LeftNavExFundEnum, isDirty: boolean) => void
}

export const ExFundServiceProviders: React.FC<Props> = ({ isDirty, form, onUpdateTabDirty }) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)
  const [isSpecifiedAuditorName, setIsSpecifiedAuditorName] = React.useState(true)
  const [isSpecifiedLegalAdviser, setIsSpecifiedLegalAdviser] = React.useState(true)
  const [isSpecifiedPrimeBroker, setIsSpecifiedPrimeBroker] = React.useState(true)
  const [isSpecifiedAdministrator, setIsSpecifiedAdministrator] = React.useState(true)
  const optionsAuditorName =
    fundDataDropdown?.auditor_name && fundDataDropdown.auditor_name.length > 0
      ? fundDataDropdown.auditor_name.map((item) => ({ value: item, text: item }))
      : []
  const optionsLegalAdviser =
    fundDataDropdown?.legal_adviser && fundDataDropdown.legal_adviser.length > 0
      ? fundDataDropdown.legal_adviser.map((item) => ({ value: item, text: item }))
      : []

  const optionsPrimeBroker =
    fundDataDropdown?.prime_broker && fundDataDropdown.prime_broker.length > 0
      ? fundDataDropdown.prime_broker.map((item) => ({ value: item, text: item }))
      : []

  const optionsAdministrator =
    fundDataDropdown?.administrator && fundDataDropdown.administrator.length > 0
      ? fundDataDropdown.administrator.map((item) => ({ value: item, text: item }))
      : []

  useFieldValidate(
    Object.keys(FIELDS).filter((item) => FIELDS[item as keyof FundServiceProviderFieldsType].isRequired),
    isDirty,
    form,
    onUpdateTabDirty,
    LeftNavExFundEnum.SERVICE_PROVIDERS,
  )

  return (
    <Flex>
      <Row gutter={32}>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemRadio
            name={FIELDS['is_annual_performance_audit'].key}
            label={<RenderLabelExFund value={FIELDS['is_annual_performance_audit'].name} />}
            rules={
              FIELDS['is_annual_performance_audit']?.isRequired
                ? [{ required: true, message: 'This field is required!' }]
                : []
            }
            options={[
              {
                title: 'Yes',
                value: true,
              },
              {
                title: 'No',
                value: false,
              },
            ]}
          />
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemDatePicker
            name={FIELDS['last_audit_date'].key}
            label={<RenderLabelExFund value={FIELDS['last_audit_date'].name} />}
            wrapperCol={16}
            rules={
              FIELDS['last_audit_date']?.isRequired ? [{ required: true, message: 'This field is required!' }] : []
            }
          />
        </Col>
      </Row>

      <Row gutter={32}>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemSelect
            name={FIELDS['auditor_name'].key}
            label={<RenderLabelExFund value={FIELDS['auditor_name'].name} />}
            options={optionsAuditorName}
            wrapperCol={16}
            selectProps={{ showSearch: true, optionFilterProp: 'children', allowClear: true }}
            rules={
              FIELDS['auditor_name']?.isRequired
                ? [
                    { required: true, message: 'This field is required!' },
                    ({ getFieldValue }) => ({
                      validator() {
                        setIsSpecifiedAuditorName(!(getFieldValue('auditor_name') === 'Other'))
                        return Promise.resolve()
                      },
                    }),
                  ]
                : [
                    ({ getFieldValue }) => ({
                      validator() {
                        setIsSpecifiedAuditorName(!(getFieldValue('auditor_name') === 'Other'))
                        return Promise.resolve()
                      },
                    }),
                  ]
            }
          />
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24} style={{ fontStyle: 'italic' }}>
          <FormItemInput
            name="auditor_name_input"
            label={<RenderLabelExFund value="If Other, Specify Auditor Name" />}
            wrapperCol={16}
            disabled={isSpecifiedAuditorName}
            placeholder="Auditor Name"
            rules={
              !isSpecifiedAuditorName
                ? [
                    {
                      required: true,
                      message: 'Auditor Name is required!',
                    },
                  ]
                : undefined
            }
          />
        </Col>
      </Row>

      <Row gutter={32}>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemSelect
            name={FIELDS['legal_adviser'].key}
            label={<RenderLabelExFund value={FIELDS['legal_adviser'].name} />}
            wrapperCol={16}
            rules={
              FIELDS['legal_adviser']?.isRequired
                ? [
                    { required: true, message: 'This field is required!' },
                    ({ getFieldValue }) => ({
                      validator() {
                        setIsSpecifiedLegalAdviser(!(getFieldValue('legal_adviser') === 'Other'))
                        return Promise.resolve()
                      },
                    }),
                  ]
                : [
                    ({ getFieldValue }) => ({
                      validator() {
                        setIsSpecifiedLegalAdviser(!(getFieldValue('legal_adviser') === 'Other'))
                        return Promise.resolve()
                      },
                    }),
                  ]
            }
            selectProps={{ showSearch: true, optionFilterProp: 'children', allowClear: true }}
            options={optionsLegalAdviser}
          />
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24} style={{ fontStyle: 'italic' }}>
          <FormItemInput
            name="legal_adviser_input"
            label={<RenderLabelExFund value="If Other, Specify Legal Adviser" />}
            wrapperCol={16}
            disabled={isSpecifiedLegalAdviser}
            placeholder="Legal Adviser"
            rules={
              !isSpecifiedLegalAdviser
                ? [
                    {
                      required: true,
                      message: 'Legal Adviser is required!',
                    },
                  ]
                : undefined
            }
          />
        </Col>
      </Row>

      <Row gutter={32}>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemSelect
            name={FIELDS['prime_broker'].key}
            label={<RenderLabelExFund value={FIELDS['prime_broker'].name} />}
            wrapperCol={16}
            selectProps={{ showSearch: true, optionFilterProp: 'children', allowClear: true }}
            rules={
              FIELDS['prime_broker']?.isRequired
                ? [
                    { required: true, message: 'This field is required!' },
                    ({ getFieldValue }) => ({
                      validator() {
                        setIsSpecifiedPrimeBroker(!(getFieldValue('prime_broker') === 'Other'))
                        return Promise.resolve()
                      },
                    }),
                  ]
                : [
                    ({ getFieldValue }) => ({
                      validator() {
                        setIsSpecifiedPrimeBroker(!(getFieldValue('prime_broker') === 'Other'))
                        return Promise.resolve()
                      },
                    }),
                  ]
            }
            options={optionsPrimeBroker}
          />
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24} style={{ fontStyle: 'italic' }}>
          <FormItemInput
            name="prime_broker_input"
            label={<RenderLabelExFund value="If Other, Specify Prime Broker" />}
            wrapperCol={16}
            maxLength={60}
            disabled={isSpecifiedPrimeBroker}
            placeholder="Prime Broker"
            rules={
              !isSpecifiedPrimeBroker
                ? [
                    {
                      required: true,
                      message: 'Prime Broker is required!',
                    },
                  ]
                : undefined
            }
          />
        </Col>
      </Row>

      <Row gutter={32}>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemSelect
            name={FIELDS['administrator'].key}
            label={<RenderLabelExFund value={FIELDS['administrator'].name} />}
            wrapperCol={16}
            rules={
              FIELDS['administrator']?.isRequired
                ? [
                    { required: true, message: 'This field is required!' },
                    ({ getFieldValue }) => ({
                      validator() {
                        setIsSpecifiedAdministrator(!(getFieldValue('administrator') === 'Other'))
                        return Promise.resolve()
                      },
                    }),
                  ]
                : [
                    ({ getFieldValue }) => ({
                      validator() {
                        setIsSpecifiedAdministrator(!(getFieldValue('administrator') === 'Other'))
                        return Promise.resolve()
                      },
                    }),
                  ]
            }
            selectProps={{ showSearch: true, optionFilterProp: 'children', allowClear: true }}
            options={optionsAdministrator}
          />
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24} style={{ fontStyle: 'italic' }}>
          <FormItemInput
            name="administrator_input"
            label={<RenderLabelExFund value="If Other, Specify Administrator" />}
            wrapperCol={16}
            disabled={isSpecifiedAdministrator}
            placeholder="Administrator"
            rules={
              !isSpecifiedAdministrator
                ? [
                    {
                      required: true,
                      message: 'Administrator is required!',
                    },
                  ]
                : undefined
            }
          />
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemInput
            name={FIELDS['placement_agent'].key}
            label={<RenderLabelExFund value={FIELDS['placement_agent'].name} />}
            placeholder={FIELDS['placement_agent'].name}
            wrapperCol={16}
            maxLength={60}
            rules={[
              // {
              //   pattern: /(None)|(^\d+$)/,
              //   message: `Value should be Number or 'None'`,
              // },
              {
                pattern: /^.{0,60}$/,
                message: 'Value should be less than 60 character',
              },
              ...(FIELDS['placement_agent']?.isRequired
                ? [{ required: true, message: 'This field is required!' }]
                : []),
            ]}
          />
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemInput
            name={FIELDS['banking_agent'].key}
            label={<RenderLabelExFund value={FIELDS['banking_agent'].name} />}
            placeholder={FIELDS['banking_agent'].name}
            wrapperCol={16}
            maxLength={60}
            rules={[
              {
                pattern: /^.{0,60}$/,
                message: 'Value should be less than 60 character',
              },
              ...(FIELDS['banking_agent']?.isRequired ? [{ required: true, message: 'This field is required!' }] : []),
            ]}
          />
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemInput
            name={FIELDS['custodian'].key}
            label={<RenderLabelExFund value={FIELDS['custodian'].name} />}
            placeholder={FIELDS['custodian'].name}
            wrapperCol={16}
            maxLength={60}
            rules={[
              {
                pattern: /^.{0,60}$/,
                message: 'Value should be less than 60 character',
              },
              ...(FIELDS['custodian']?.isRequired ? [{ required: true, message: 'This field is required!' }] : []),
            ]}
          />
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemInput
            name={FIELDS['consultant'].key}
            label={<RenderLabelExFund value={FIELDS['consultant'].name} />}
            placeholder={FIELDS['consultant'].name}
            wrapperCol={16}
            maxLength={60}
            rules={[
              {
                pattern: /^.{0,60}$/,
                message: 'Value should be less than 60 character',
              },
              ...(FIELDS['consultant']?.isRequired ? [{ required: true, message: 'This field is required!' }] : []),
            ]}
          />
        </Col>
      </Row>
    </Flex>
  )
}

RequiredDataHelper.default().registerRequirement(
  LeftNavExFundEnum.SERVICE_PROVIDERS,
  Object.keys(FIELDS).filter((item) => FIELDS[item as keyof FundServiceProviderFieldsType].isRequired),
)
