import { CalculatorOutlined, DeleteOutlined, DownloadOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Dropdown,
  Empty,
  Menu,
  notification,
  Popconfirm,
  Row,
  Space,
  Tooltip,
} from 'antd'
import { ClickParam } from 'antd/lib/menu'
import Table, { ColumnProps } from 'antd/lib/table'
import JsFileDownload from 'js-file-download'
import Papa from 'papaparse'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../shared/api'
import { AnalysisGroupListContext } from '../../shared/api/context/AnalysisGroupListContext'
import { AnalysisType, FundInFundList, FundListType } from '../../shared/api/models/Analysis'
import { HistoricalPeriodType, HistoricalType } from '../../shared/api/services/analysis.service'
import { useServiceState } from '../../shared/hooks/useServiceState'
import AddFundModal from './AddFundModal'
import CalculateModal from './CalculateModal'

enum ExportTypeEnum {
  List = 'list',
  HistoricalROR = 'historical_ror',
  HistoricalAUM = 'historical_aum',
  HistoricalYearlyROR = 'historical_yearly_ror',
}

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 100%;

  .ant-card-head {
    border-bottom: none;
    font-size: 1rem;
    padding: 0 1rem;
  }
  .ant-card-body {
    padding: 1rem 0 0 0;
  }
`

type Props = {
  selectedGroup?: AnalysisType
  onSelectGroup: (group?: AnalysisType) => void
}

const FundList: React.FC<Props> = ({ selectedGroup, onSelectGroup }) => {
  const { invoke: getFundList, data: dataFundList, loading: loadingFundList } = useServiceState<FundListType, number>(
    APIService.analysisService.getDetailsGroup,
  )
  const { data } = React.useContext(AnalysisGroupListContext)
  React.useEffect(() => {
    if (selectedGroup) {
      getFundList(selectedGroup.group_code)
    }
  }, [getFundList, selectedGroup])

  const resolvedRowTree = React.useMemo<AnalysisType[] | undefined>(() => {
    if (!selectedGroup || !data || !data.length) {
      return
    }
    const selectedRowId = selectedGroup.group_code

    let parent = data.find((item) => item.group_code === selectedRowId)
    const results = []
    while (parent) {
      results.unshift(parent)
      parent = data.find((item) => item.group_code === parent?.parent_group_code)
    }
    return results
  }, [data, selectedGroup])

  // const [data, setData] = React.useState<FundInFundList[]>()
  const [selectedFunds, setSelectedFunds] = React.useState<string[]>([])
  const [showCalculationModal, setShowCalculation] = React.useState(false)
  const [showAddFund, setShowAddFund] = React.useState(false)
  const [deleting, setDeleting] = React.useState(false)

  const handleDeleteFunds = () => {
    if (!selectedGroup || !selectedFunds || !selectedFunds.length) {
      return
    }
    setDeleting(true)
    APIService.analysisService
      .updateGroup({ groupCode: selectedGroup.group_code, deletedFundIds: selectedFunds })
      .then(() => {
        notification.success({ message: `Deleted ${selectedFunds.length} funds from ${selectedGroup.group_name}` })
        getFundList(selectedGroup.group_code)
      })
      .catch((err) => {
        console.error(err)
        notification.error({ message: `Failed to delete funds from ${selectedGroup.group_name}` })
      })
      .finally(() => setDeleting(false))
  }

  const columns: ColumnProps<FundInFundList>[] = [
    {
      title: 'Fund ID',
      key: 'fund_id',
      dataIndex: 'fund_id',
      fixed: 'left',
      width: 100,
    },
    {
      title: 'Fund Name',
      key: 'fund_name',
      dataIndex: 'fund_name',
      fixed: 'left',
      sorter: (a, b) => {
        if (!a.fund_name && !b.fund_name) {
          return 0
        }
        if (!a.fund_name && b.fund_name) {
          return 1
        }
        if (a.fund_name && !b.fund_name) {
          return -1
        }
        if (a.fund_name && b.fund_name) {
          const nameA = a.fund_name.toLowerCase()
          const nameB = b.fund_name.toLowerCase()
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
        }
        return 0
      },
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Fund Rank',
      key: 'fund_rank',
      dataIndex: 'fund_rank',
      width: 120,
    },
    {
      title: 'Geographic',
      key: 'geographic',
      dataIndex: 'geographic',
    },
    {
      title: 'Strategy',
      key: 'strategy_name',
      dataIndex: 'strategy_name',
    },
    {
      title: 'Sub-Strategy (New)',
      key: 'sub_strategy_new_name',
      dataIndex: 'sub_strategy_new_name',
    },
    {
      title: 'Country',
      key: 'country',
      dataIndex: 'country',
    },
    {
      title: 'Is AUM Distributed?',
      key: 'is_aum_distributed',
      dataIndex: 'is_aum_distributed',
      render: (value) => {
        return <Checkbox checked={value === 1 ? true : false} />
      },
    },
    {
      title: 'Strategy (New)',
      key: 'strategy_new_name',
      dataIndex: 'strategy_new_name',
    },
    {
      title: 'Regional Focus',
      key: 'regional_focus',
      dataIndex: 'regional_focus',
    },
    {
      title: 'Equity Redemption',
      key: 'equity_redemption',
      dataIndex: 'equity_redemption',
    },
    {
      title: 'Advance Notice',
      key: 'advance_notice',
      dataIndex: 'advance_notice',
    },
  ]

  const [currentSource, setCurrentSource] = React.useState<FundInFundList[]>([])

  React.useEffect(() => {
    if (dataFundList && dataFundList.funds) {
      setCurrentSource(dataFundList.funds)
    }
  }, [dataFundList])

  const handleExportAnalyst = () => {
    JsFileDownload(Papa.unparse(currentSource), `analyst-results.csv`, 'application/csv')
  }

  const handleExport = async (dataType: HistoricalType, period: HistoricalPeriodType) => {
    if (!selectedGroup) {
      return
    }
    const { data } = await APIService.analysisService.exportHistoricalData({
      sourceType: 'peer_group',
      entityId: selectedGroup?.group_code,
      dataType,
      period,
    })
    JsFileDownload(data, `${selectedGroup.group_name}-${period}-${dataType}.csv`, 'application/csv')
  }

  const handleExportClick = (e: ClickParam) => {
    switch (e.key) {
      case ExportTypeEnum.List:
        handleExportAnalyst()
        break
      case ExportTypeEnum.HistoricalROR:
        handleExport('ror', 'monthly')
        break
      case ExportTypeEnum.HistoricalAUM:
        handleExport('aum', 'monthly')
        break
      case ExportTypeEnum.HistoricalYearlyROR:
        handleExport('ror', 'yearly')
        break
      default:
    }
  }

  return (
    <StyledCard
      size="small"
      title={
        resolvedRowTree ? (
          <Breadcrumb>
            {resolvedRowTree.map((row) => (
              <Breadcrumb.Item key={row.group_code}>
                {row.group_code !== selectedGroup?.group_code ? (
                  <Button type="link" onClick={() => onSelectGroup(row)}>
                    {row.group_name}
                  </Button>
                ) : (
                  row.group_name
                )}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        ) : (
          'Fund List'
        )
      }
      extra={
        <Row justify="space-between">
          <Space>
            <Tooltip title={selectedGroup ? '' : 'Please select a group first!'}>
              <Button type="primary" onClick={() => setShowCalculation(true)} disabled={!selectedGroup}>
                <CalculatorOutlined />
                Calculate
              </Button>
            </Tooltip>
            <Tooltip title={selectedGroup ? '' : 'Please select a group first!'}>
              <Button type="primary" onClick={() => setShowAddFund(true)} disabled={!selectedGroup}>
                <PlusOutlined /> Add Fund
              </Button>
            </Tooltip>
            {selectedGroup && selectedFunds && selectedFunds.length > 0 && (
              <Popconfirm
                title={
                  <div>
                    Are you sure you want to delete these funds from <b>{selectedGroup?.group_name}</b>?
                  </div>
                }
                onConfirm={handleDeleteFunds}
              >
                <Button danger loading={deleting} disabled={deleting}>
                  <DeleteOutlined /> Delete
                </Button>
              </Popconfirm>
            )}
          </Space>
        </Row>
      }
    >
      <Table
        rowKey="fund_id"
        columns={columns}
        size="small"
        loading={loadingFundList}
        dataSource={dataFundList?.funds}
        scroll={{ x: 2000, y: 'calc(100vh - 355px)' }}
        onChange={(_pagination, _filter: any, _sorter: any, extra) => {
          setCurrentSource(extra.currentDataSource)
        }}
        pagination={{
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          defaultPageSize: 20,
        }}
        rowSelection={{
          onChange: (selectedRowKeys) => {
            setSelectedFunds(selectedRowKeys as string[])
          },
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={selectedGroup ? 'No Fund in selected group' : 'Please select group'}
            />
          ),
        }}
      />
      {selectedGroup && currentSource.length > 0 && (
        <Dropdown
          trigger={['click']}
          overlay={() => (
            <Menu onClick={handleExportClick}>
              <Menu.Item key={ExportTypeEnum.List}>List</Menu.Item>
              <Menu.Item key={ExportTypeEnum.HistoricalROR}>Historical ROR</Menu.Item>
              <Menu.Item key={ExportTypeEnum.HistoricalAUM}>Historical AUM</Menu.Item>
              <Menu.Item key={ExportTypeEnum.HistoricalYearlyROR}>Historical Yearly ROR</Menu.Item>
            </Menu>
          )}
        >
          <Button type="primary" style={{ position: 'absolute', bottom: 16, left: 10 }}>
            <DownloadOutlined />
            Export
          </Button>
        </Dropdown>
      )}

      {selectedGroup && showCalculationModal && (
        <CalculateModal
          groupCode={selectedGroup.group_code}
          isOpen={showCalculationModal}
          onRequestClose={() => setShowCalculation(false)}
        />
      )}
      <AddFundModal
        isOpen={showAddFund}
        onRefetch={() => selectedGroup?.group_code && getFundList(selectedGroup.group_code)}
        onRequestClose={() => setShowAddFund(false)}
        selectedGroup={selectedGroup}
      />
    </StyledCard>
  )
}

export default FundList
