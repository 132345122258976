import { Col, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { DividerForm } from '../../../../shared/components/DividerForm'
import { FormItemCheckBox, FormItemTextArea } from '../../../../shared/components/FormItemComponents'
import { NextBackButton } from '../../../../shared/components/NextBackButton'
import { RenderLabelForm } from '../../../../shared/components/RenderLabelForm'
const Flex = styled.div`
  display: flex;
`
const FORM_CHECKBOX_LIST = [
  {
    name: 'hfr_database',
    label: 'HFR Database',
  },
  {
    name: 'dead_fund',
    label: 'HFR Dead Funds Database',
  },
  {
    name: 'hfr_risk_premia',
    label: 'HFR Risk Premia AM Database',
  },
  {
    name: 'idb_hfri_fof_synthetic',
    label: 'Index DB: HFRI FOF Synthetic',
  },
]
const ExportSetup: React.FC = () => {
  return (
    <Flex style={{ padding: '1rem 3rem', flexDirection: 'column', flex: 1 }}>
      <DividerForm text="Distribution Parameter"></DividerForm>
      <Row>
        {FORM_CHECKBOX_LIST.map((item) => {
          return (
            <Col span={12} sm={24} xs={24} md={12} key={item.name}>
              <FormItemCheckBox name={item.name} text={<RenderLabelForm value={item.label} />} />
            </Col>
          )
        })}
      </Row>
      <FormItemTextArea label={'Comment'} name="comment" labelCol={0} wrapperCol={24} row={11} />
      <NextBackButton />
    </Flex>
  )
}
export default ExportSetup
