import { Col, Row } from 'antd'
import { FormInstance, Rule } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'
import { FundDetailDropdownContext } from '../../../shared/api/context/FundDetailDropdownContext'
import { FundDetails } from '../../../shared/api/models/FundDetails'
import { FormItemInput, FormItemSelect } from '../../../shared/components/FormItemComponents'
import { RenderLabelExFund } from '../../../shared/components/RenderLabelExFund'
import { useFieldValidate } from '../../../shared/hooks/useEffectFieldValidate'
import { LeftNavExFundEnum } from '../../../shared/SearchExFundLabel'
import { FormFieldType } from '../ExFundDetailMain'
import { RequiredDataHelper } from '../RequiredDataHelper'
const Flex = styled.div`
  display: flex;
  flex: 1;
  margin-top: 1rem;
  padding: 0 2rem;
  flex-direction: column;
`

type FundContactFieldsType = Pick<
  FormFieldType,
  | 'contact_name'
  | 'contact_company'
  | 'contact_country'
  | 'contact_address1'
  | 'contact_address2'
  | 'contact_city'
  | 'contact_state'
  | 'contact_postal_code'
  | 'contact_phone'
  | 'contact_fax'
  | 'contact_email'
  | 'contact_web'
>

const FIELDS: FundContactFieldsType = {
  contact_name: {
    key: 'contact_name',
    name: 'Name',
    isRequired: true,
  },
  contact_company: {
    key: 'contact_company',
    name: 'Company',
    isRequired: true,
  },
  contact_country: {
    key: 'contact_country',
    name: 'Country',
    isRequired: true,
  },
  contact_address1: {
    key: 'contact_address1',
    name: 'Address 1',
    isRequired: true,
  },
  contact_address2: {
    key: 'contact_address2',
    name: 'Addres 2',
  },
  contact_city: {
    key: 'contact_city',
    name: 'City',
    isRequired: true,
  },
  contact_state: {
    key: 'contact_state',
    name: 'State',
  },
  contact_postal_code: {
    key: 'contact_postal_code',
    name: 'Postal Code',
    isRequired: true,
  },
  contact_phone: {
    key: 'contact_phone',
    name: 'Phone',
    isRequired: true,
  },
  contact_fax: {
    key: 'contact_fax',
    name: 'Fax',
  },
  contact_email: {
    key: 'contact_email',
    name: 'Email',
    isRequired: true,
  },
  contact_web: {
    key: 'contact_web',
    name: 'Website',
  },
}

type Props = {
  isDirty: boolean
  form: FormInstance
  onUpdateTabDirty: (id: LeftNavExFundEnum, isDirty: boolean) => void
}

export const ExFundContact: React.FC<Props> = ({ isDirty, form, onUpdateTabDirty }) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)

  useFieldValidate(
    Object.keys(FIELDS).filter((item) => FIELDS[item as keyof FundContactFieldsType].isRequired),
    isDirty,
    form,
    onUpdateTabDirty,
    LeftNavExFundEnum.FUND_CONTACT,
  )

  const contactCountry = form.getFieldValue('contact_country')

  const LIST_FORM: { name: keyof FundDetails; label: string; maxLength?: number; options?: any[]; rules?: Rule[] }[] = [
    {
      name: FIELDS['contact_name'].key,
      label: FIELDS['contact_name'].name,
      maxLength: 60,
      rules: FIELDS['contact_name']?.isRequired ? [{ required: true, message: 'This field is required!' }] : [],
    },
    {
      name: FIELDS['contact_company'].key,
      label: FIELDS['contact_company'].name,
      maxLength: 60,
      rules: FIELDS['contact_company']?.isRequired ? [{ required: true, message: 'This field is required!' }] : [],
    },
    {
      name: FIELDS['contact_country'].key,
      label: FIELDS['contact_country'].name,
      options:
        fundDataDropdown?.contact_country && fundDataDropdown?.contact_country.length > 0
          ? fundDataDropdown.contact_country.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
      rules: FIELDS['contact_country']?.isRequired ? [{ required: true, message: 'This field is required!' }] : [],
    },
    {
      name: FIELDS['contact_address1'].key,
      label: FIELDS['contact_address1'].name,
      maxLength: 60,
      rules: FIELDS['contact_address1']?.isRequired ? [{ required: true, message: 'This field is required!' }] : [],
    },
    {
      name: FIELDS['contact_address2'].key,
      label: FIELDS['contact_address2'].name,
      maxLength: 60,
    },
    {
      name: FIELDS['contact_city'].key,
      label: FIELDS['contact_city'].name,
      maxLength: 30,
      rules: FIELDS['contact_city']?.isRequired ? [{ required: true, message: 'This field is required!' }] : [],
    },
    {
      name: FIELDS['contact_state'].key,
      label: FIELDS['contact_state'].name,
      options:
        fundDataDropdown?.contact_state && fundDataDropdown?.contact_state.length > 0
          ? fundDataDropdown.contact_state.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
      rules: FIELDS['contact_state']?.isRequired ? [{ required: true, message: 'This field is required!' }] : [],
    },
    {
      name: FIELDS['contact_postal_code'].key,
      label: FIELDS['contact_postal_code'].name,
      maxLength: 10,
      rules: FIELDS['contact_postal_code']?.isRequired ? [{ required: true, message: 'This field is required!' }] : [],
    },
    {
      name: FIELDS['contact_phone'].key,
      label: FIELDS['contact_phone'].name,
      maxLength: 30,
      rules: FIELDS['contact_phone']?.isRequired ? [{ required: true, message: 'This field is required!' }] : [],
    },
    {
      name: FIELDS['contact_fax'].key,
      label: FIELDS['contact_fax'].name,
      maxLength: 30,
      rules: FIELDS['contact_fax']?.isRequired ? [{ required: true, message: 'This field is required!' }] : [],
    },
    {
      name: FIELDS['contact_email'].key,
      label: FIELDS['contact_email'].name,
      maxLength: 255,
      rules: FIELDS['contact_email']?.isRequired ? [{ required: true, message: 'This field is required!' }] : [],
    },
    {
      name: FIELDS['contact_web'].key,
      label: FIELDS['contact_web'].name,
      maxLength: 60,
      rules: FIELDS['contact_web']?.isRequired ? [{ required: true, message: 'This field is required!' }] : [],
    },
  ]
  return (
    <Flex>
      <Row>
        {LIST_FORM.map((item) => {
          return (
            <Col span={12} xl={12} md={16} xs={24} sm={24} key={item.name}>
              {item.options ? (
                <FormItemSelect
                  name={item.name}
                  label={<RenderLabelExFund value={item.label} />}
                  options={item.options}
                  wrapperCol={16}
                  rules={item.rules}
                  disabled={item.name === 'contact_state' && contactCountry !== '205'}
                />
              ) : (
                <FormItemInput
                  name={item.name}
                  label={<RenderLabelExFund value={item.label} />}
                  placeholder={item.label}
                  wrapperCol={16}
                  maxLength={item.maxLength}
                  rules={item.rules}
                />
              )}
            </Col>
          )
        })}
      </Row>
    </Flex>
  )
}

RequiredDataHelper.default().registerRequirement(
  LeftNavExFundEnum.FUND_CONTACT,
  Object.keys(FIELDS).filter((item) => FIELDS[item as keyof FundContactFieldsType].isRequired),
)
