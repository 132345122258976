import { Col, Form, Radio, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'
import { FundDetailDropdownContext } from '../../../shared/api/context/FundDetailDropdownContext'
import { FormItemAutoComplete } from '../../../shared/components/FormItemComponents'
import { RenderLabelExNewFund } from '../../../shared/components/RenderLabelExNewFund'
import { LeftNavExNewFundEnum } from '../../../shared/SearchExNewFundLabel'
import { NextBackNewFundTab } from './NextBackExNewFundTab'
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 1rem;
  padding: 0 2rem;
`
type Props = {
  setStepTab: (value: LeftNavExNewFundEnum) => void
  setActiveTab: (value: LeftNavExNewFundEnum) => void
  activeTab: LeftNavExNewFundEnum
  stepTab: LeftNavExNewFundEnum
  form: FormInstance
}
const ARRAY_FIELD_VALIDATE: string[] = ['accepts_new_investment', 'min_invest', 'add_invest', 'accepts_mgd_accts']
export const ExNewFundRequirement: React.FC<Props> = ({ setStepTab, setActiveTab, activeTab, form, stepTab }) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)
  const [isManager, setIsManager] = React.useState(form.getFieldValue('accepts_mgd_accts') === '1')

  const optionsMinInvest =
    fundDataDropdown?.min_invest && fundDataDropdown.min_invest.length > 0
      ? fundDataDropdown.min_invest.map((item) => ({ value: item, text: item }))
      : []
  const optionsAdditional =
    fundDataDropdown?.add_invest && fundDataDropdown.add_invest.length > 0
      ? fundDataDropdown.add_invest.map((item) => ({ value: item, text: item }))
      : []
  const optionsAssetSize =
    fundDataDropdown?.min_invest_indv && fundDataDropdown.min_invest_indv.length > 0
      ? fundDataDropdown.min_invest_indv.map((item) => ({ value: item, text: item }))
      : []

  const arrayValidate = React.useMemo(() => {
    return isManager ? [...ARRAY_FIELD_VALIDATE, 'min_invest_indv', 'run_sep_mgd_accts'] : ARRAY_FIELD_VALIDATE
  }, [isManager])

  return (
    <Wrapper>
      <Row>
        <Col span={24}>
          <Form.Item
            name="accepts_new_investment"
            label={<RenderLabelExNewFund value="Product Open To New Investment?" />}
            rules={[{ required: true, message: 'Product Open To New Investment is required' }]}
            {...{ wrapperCol: { span: 16 } }}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemAutoComplete
            name="min_invest"
            label={<RenderLabelExNewFund value="Minimum Investment" />}
            labelCol={16}
            wrapperCol={16}
            options={optionsMinInvest}
            rules={[{ required: true, message: 'Minimum Investment is required' }]}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemAutoComplete
            name="add_invest"
            label={<RenderLabelExNewFund value="Additional Investment" />}
            labelCol={16}
            wrapperCol={16}
            options={optionsAdditional}
            rules={[{ required: true, message: 'Additional Investment is required' }]}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            name="accepts_mgd_accts"
            label={
              <RenderLabelExNewFund value="Is Manager Open To Running A Separately Managed Account Pari-Passu To This Fund?" />
            }
            {...{ wrapperCol: { span: 16 } }}
            rules={[
              {
                required: true,
                message: 'This Field is required!',
              },
              ({ getFieldValue, setFieldsValue }) => ({
                validator() {
                  setIsManager(getFieldValue('accepts_mgd_accts') === '1')
                  getFieldValue('accepts_mgd_accts') === '2' &&
                    setFieldsValue({ min_invest_indv: null, run_sep_mgd_accts: null })

                  return Promise.resolve()
                },
              }),
            ]}
          >
            <Radio.Group>
              <Radio value="1">Yes</Radio>
              <Radio value="2">No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      {isManager && (
        <>
          <Row>
            <Col span={12} xl={12} md={16} xs={24} sm={24} style={{ fontStyle: 'italic' }}>
              <FormItemAutoComplete
                disable={!isManager}
                name="min_invest_indv"
                label={<RenderLabelExNewFund value="If Yes, Select The Minimum Asset Size" />}
                labelCol={16}
                wrapperCol={16}
                rules={
                  isManager
                    ? [
                        {
                          required: true,
                          message: 'This Field is required!',
                        },
                      ]
                    : []
                }
                options={optionsAssetSize}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12} xl={12} md={16} xs={24} sm={24}>
              <Form.Item
                name="run_sep_mgd_accts"
                label={<RenderLabelExNewFund value="Currently Running a Separately Managed Account?" />}
                {...{ wrapperCol: { span: 16 } }}
                rules={
                  isManager
                    ? [
                        {
                          required: true,
                          message: 'This Field is required!',
                        },
                      ]
                    : []
                }
              >
                <Radio.Group disabled={!isManager}>
                  <Radio value="1">Yes</Radio>
                  <Radio value="2">No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      <NextBackNewFundTab
        setStepTab={setStepTab}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        stepTab={stepTab}
        ArrayValidateField={arrayValidate}
        form={form}
      />
    </Wrapper>
  )
}
