import { Col, Row, Space } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React, { useState } from 'react'
import styled from 'styled-components'
import { FundDropdownCodeType } from '../../../../shared/api/models/FundDetails'
import { MinorityType } from '../../../../shared/api/services/minority.service'
import { FormItemCheckBox, FormItemRadio } from '../../../../shared/components/FormItemComponents'
import { MODE_ESG_VIEW } from '../../../../shared/components/FundESGSection'
import { RenderLabelExFirm } from '../../../../shared/components/RenderLabelExFirm'
import { RenderLabelFormFirm } from '../../../../shared/components/RenderLabelFormFirm'

const Wrapper = styled.div`
  padding: 1rem;
`

const WrapperMinority = styled.div`
  padding: 1rem;
`

type Props = {
  modeView: number
  form: FormInstance
  minorityTypes?: MinorityType[]
  setDataFirmDetailSubmit: any
  firmCountry: FundDropdownCodeType | undefined
}

const FirmDI: React.FC<Props> = ({ modeView, form, minorityTypes, setDataFirmDetailSubmit, firmCountry }) => {
  const isMinorityOwnedFirm = form.getFieldValue('is_minority_or_woman_owned_firm')

  React.useEffect(() => {
    if (!minorityTypes || !minorityTypes.length) {
      return
    }
    if (isMinorityOwnedFirm === false) {
      minorityTypes?.map((item) => {
        const currentValue = form.getFieldValue(`minority_type_${item.id}`)
        if (!currentValue) {
          return
        }
        setDataFirmDetailSubmit((prevState: any) => ({
          ...prevState,
          [`minority_type_${item.id}`]: false,
        }))
        form.setFieldsValue({ [`minority_type_${item.id}`]: false })
      })
    }
  }, [form, isMinorityOwnedFirm, minorityTypes, setDataFirmDetailSubmit])

  const renderLabelField = (value: string) => {
    return modeView === MODE_ESG_VIEW.EXTERNAL ? (
      <RenderLabelExFirm value={value} />
    ) : (
      <RenderLabelFormFirm value={value} />
    )
  }

  return (
    <Wrapper>
      <Space direction="vertical">
        <Row>
          <Col span={24}>
            <h3>Minority- or Woman-Owned Management Company</h3>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <i>
              Applicable only to the management firm that is physically headquartered in{' '}
              {firmCountry === undefined ? '[Firm_country]' : firmCountry.code_description}
            </i>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <span>
              Minority- or Woman-Owned: A hedge fund management company is deemed to be a minority- or woman-owned
              business if: Greater than 50% of the management company is owned by one or more minorities or women, or in
              the case of a publicly owned business, greater than 50% of all issued stock is owned by one or more
              minorities or women; and whose management and daily business operations are controlled by one or more such
              individuals.
            </span>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItemRadio
              name="is_minority_or_woman_owned_firm"
              label={renderLabelField(
                'Is the management company owned, operated, or controlled by one or more minorities or women?',
              )}
              options={[
                {
                  value: true,
                  title: 'Yes',
                },
                {
                  value: false,
                  title: 'No',
                },
              ]}
            />
          </Col>
        </Row>
        <Row>
          {isMinorityOwnedFirm ? (
            <Col span={24}>
              <span>
                <i>If ‘Yes’, please check all that apply:</i>
              </span>
            </Col>
          ) : null}
        </Row>
        {isMinorityOwnedFirm ? (
          <WrapperMinority>
            <Row>
              <Col span={10}>
                <b>Type</b>
              </Col>
              <Col span={12}>
                <b>Description</b>
              </Col>
            </Row>
            {minorityTypes?.map((item, index) => {
              return (
                <Row key={index} style={{ margin: '5px 0' }}>
                  <Col span={10}>
                    <FormItemCheckBox
                      disable={!isMinorityOwnedFirm}
                      name={`minority_type_${item.id}`}
                      text={item.minority_type}
                    />
                  </Col>
                  <Col span={12}>{item.minority_description}</Col>
                </Row>
              )
            })}
          </WrapperMinority>
        ) : null}
      </Space>
    </Wrapper>
  )
}

export default FirmDI
