import { ContainerOutlined, InboxOutlined, SearchOutlined } from '@ant-design/icons'
import { AutoComplete, Button, Input, Popconfirm, Select } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../app/rootReducer'
import { ViewModeType } from '../../shared/DraftManager'
import { LeftNavExFirmEnum } from '../../shared/SearchExFirmLabel'
import { LeftNavExFundEnum, ListSearchExFundType, SEARCH_FIELD_INDEX } from '../../shared/SearchExFundLabel'
import { addTabs, FundFirmTypeEnum, PREFIX_EXTERNAL } from '../../slice/appSettingsSlice'
import { Link } from 'react-router-dom'

const Flex = styled.div`
  display: flex;
  flex: 1;
`
const TextID = styled(Text)`
  font-size: 14px;
`

type PropsCardTitle = {
  id?: string | undefined
  name?: string | undefined
  firmName?: string | undefined
  firmId?: string | undefined
  optionFilter: ListSearchExFundType[]
  textFilterSearch: string
  setOptionFilter: (value: ListSearchExFundType[]) => void
  setTextFilterSearch: (value: string) => void
  loadingSubmitForm: boolean
  isUpdateFund: boolean
  selectedTab: LeftNavExFirmEnum | LeftNavExFundEnum
  onCancel?: () => void
  setViewMode?: (value: ViewModeType) => void
  setActiveTab: (value: LeftNavExFundEnum | LeftNavExFirmEnum) => void
  onPreview: () => void
  isPreviewing: boolean
}
export const ExFundCardTitle: React.FC<PropsCardTitle> = ({
  id,
  name,
  firmName,
  firmId,
  setOptionFilter,
  setTextFilterSearch,
  textFilterSearch,
  loadingSubmitForm,
  isUpdateFund,
  selectedTab,
  onCancel,
  setViewMode,
  setActiveTab,
  onPreview,
  isPreviewing,
}) => {
  const [optionShow, setOptionShow] = React.useState(SEARCH_FIELD_INDEX)
  const dispatch = useDispatch()

  const handleSearch = (value: any) => {
    setTextFilterSearch(value)
    const newOption = SEARCH_FIELD_INDEX.filter((item) => item.name.toUpperCase().indexOf(value.toUpperCase()) !== -1)
    setOptionFilter(newOption)
    setOptionShow(newOption)
    if (newOption.findIndex((item) => item.leftNav === selectedTab) === -1 && newOption.length > 0) {
      setActiveTab(newOption[0].leftNav)
    }
  }

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
  }

  const appSetting = useSelector((state: RootState) => state.appSettings)

  const isEdited = appSetting.fundDraft.some((item) => item.id === id)

  return (
    <Flex style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
      <div style={{ display: 'flex' }}>
        <div>
          <Flex style={{ alignItems: 'center' }}>
            <TextID>Fund ID: {id}</TextID>
            <Flex style={{ alignItems: 'center', marginLeft: '1rem' }}>
              <TextID>Firm ID: {firmId}</TextID>
              <Flex style={{ alignItems: 'center', marginLeft: '1rem' }}>
                {firmName && <ContainerOutlined style={{ color: '#7598E6' }} />}
                {firmId && firmName && (
                  <Text style={{ fontSize: 14 }}>
                    <Button
                      style={{ color: '#7598E6', border: 'none' }}
                      onClick={() => {
                        dispatch(
                          addTabs(
                            setViewMode
                              ? { name: firmName, id: firmId, type: FundFirmTypeEnum.FIRMS_IN_FUNDS }
                              : {
                                  name: firmName,
                                  id: `${PREFIX_EXTERNAL}-${firmId}`,
                                  type: FundFirmTypeEnum.FIRMS_IN_FUNDS,
                                },
                          ),
                        )
                      }}
                    >
                      {firmName}
                    </Button>
                  </Text>
                )}
              </Flex>
            </Flex>
          </Flex>

          <Flex style={{ alignItems: 'baseline', flexWrap: 'wrap' }}>
            <div>
              <Text style={{ fontSize: 18, fontWeight: 'bold', color: '#484848', whiteSpace: 'normal' }}>
                {isUpdateFund ? <Text style={{ color: '#b58742' }}>(Edited)</Text> : ''}
                {name}
                {/* MontLake Q Brands Premiere Palm-Tree Alpha Equity */}
              </Text>
            </div>
            <div style={{ marginLeft: '1rem' }}>
              <InboxOutlined /> <Link to={`/funds/${id}?preview=1&preview_tab=Profile`}>Fund Profile</Link> |{' '}
              <Link to={`/funds/${id}?preview=1&preview_tab=Peer Group`}>Peer Group Analysis </Link>
            </div>
          </Flex>
        </div>
      </div>

      <Flex style={{ justifyContent: 'flex-end', alignItems: 'center', margin: '8px 0' }}>
        {!isPreviewing && (
          <>
            <AutoComplete
              onSelect={(value, options) => {
                if (options.label) {
                  const text = options.label.toLocaleString()
                  setTextFilterSearch(text)
                  const newOption = SEARCH_FIELD_INDEX.filter(
                    (item) => item.name.toUpperCase().indexOf(text.toUpperCase()) !== -1,
                  )
                  setOptionShow(newOption)
                  const saveOption = newOption.filter((item) => item.key === value)
                  setOptionFilter(saveOption)
                  if (saveOption.findIndex((item) => item.leftNav === selectedTab) === -1 && newOption.length > 0) {
                    setActiveTab(saveOption[0].leftNav)
                  }
                }
              }}
              onSearch={handleSearch}
              options={optionShow.map((item) => ({ value: item.key, label: item.name }))}
              allowClear
              value={textFilterSearch}
              style={{ width: 200, height: 32, marginRight: 8 }}
            >
              <Input placeholder="Go to Field" prefix={<SearchOutlined />} />
            </AutoComplete>
            {isUpdateFund ? (
              <Popconfirm
                placement="bottomLeft"
                title={'You have unsaved changes, are you sure you want to cancel?'}
                onConfirm={handleCancel}
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ marginRight: 8 }}>Cancel</Button>
              </Popconfirm>
            ) : (
              <Button onClick={handleCancel} style={{ marginRight: 8 }}>
                Cancel
              </Button>
            )}

            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: 8 }}
              loading={loadingSubmitForm}
              disabled={!isUpdateFund}
            >
              Save
            </Button>

            {setViewMode && (
              <Select
                disabled={isEdited}
                style={{ marginRight: 8, width: 160 }}
                defaultValue="external"
                onChange={(e) => {
                  setViewMode && setViewMode(e as ViewModeType)
                  id &&
                    name &&
                    dispatch(addTabs({ id: id, name: name, viewMode: 'internal', type: FundFirmTypeEnum.FUNDS }))
                }}
              >
                <Select.Option value="internal">Internal View</Select.Option>
                <Select.Option value="external" disabled>
                  External View
                </Select.Option>
              </Select>
            )}
          </>
        )}
        <Button onClick={onPreview}>{isPreviewing ? 'Close' : 'Preview'}</Button>
      </Flex>
    </Flex>
  )
}
