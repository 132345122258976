import { AutoComplete, DatePicker, Form, Input, Popover, Select, Tooltip } from 'antd'
import { FormInstance } from 'antd/lib/form'
import moment from 'moment'
import React, { PropsWithChildren } from 'react'
import EditableContext from './EditableContext'
import { InputProps } from 'antd/lib/input'
import { CustomNumberInput } from '../CustomNumberInput'

type Props<T> = {
  dataIndex: keyof T
  editable: boolean
  record: T
  editingRow: T
  setEditingRow: (row: T) => void
  options: string[]
  optionSelect?: { value: string; label: string }[]
  isDirty?: boolean
}

const EditableCell = <T extends { key: string }>({
  dataIndex,
  editable,
  children,
  record,
  editingRow,
  setEditingRow,
  options,
  optionSelect,
  isDirty,
  ...restProps
}: PropsWithChildren<Props<T>>) => {
  const inputRef = React.useRef<Input>(null)
  const form = React.useContext(EditableContext) as FormInstance
  const [showTooltip, setShowTooltip] = React.useState(false)

  React.useEffect(() => {
    if (!editingRow) {
      return
    }
    form.setFieldsValue({
      [dataIndex]: editingRow[dataIndex],
    })
  }, [dataIndex, form, editingRow])

  const toggleEdit = () => {
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    })
  }

  const save = async () => {
    setShowTooltip(false)
    //@ts-ignore
    const formValue = form.getFieldValue(dataIndex)
    if (formValue === editingRow[dataIndex]) {
      return
    }
    try {
      const values = await form.validateFields()
      if (
        dataIndex === 'fund_family_code' &&
        values['fund_family_code']?.length !== 4 &&
        values['fund_family_code']?.length !== 0
      ) {
        form.setFieldsValue({
          fund_family_code: null,
        })
        return
      }
      toggleEdit()
      setEditingRow({ ...editingRow, ...values, key: record.key })
    } catch (err) {
      console.log('failed', err)
    }
  }

  let childNode = children

  if (editable) {
    childNode =
      editingRow && editingRow.key === record.key ? (
        <>
          {dataIndex === 'name' && optionSelect ? (
            <Select
              allowClear
              style={{ margin: 0, width: '100%' }}
              value={editingRow.key !== 'new-row' ? String(editingRow[dataIndex]) : undefined}
              onChange={(e) => setEditingRow({ ...editingRow, name: e, key: record.key })}
            >
              {optionSelect.map((item) => {
                return (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                )
              })}
            </Select>
          ) : dataIndex === 'date' ? (
            <DatePicker
              style={{ margin: 0, width: '100%' }}
              picker={'date'}
              value={editingRow.key !== 'new-row' ? moment(editingRow[dataIndex]) : undefined}
              onChange={(date) => setEditingRow({ ...record, date: date?.format('YYYY-MM-DD'), key: record.key })}
            />
          ) : dataIndex === 'resolvedQuarter' ? (
            <DatePicker
              picker="quarter"
              value={editingRow.key !== 'new-row' ? moment(editingRow[dataIndex]) : undefined}
              onChange={(date) => {
                if (!date) {
                  setEditingRow({
                    ...record,
                    date: undefined,
                    key: record.key,
                    resolvedQuarter: undefined,
                  })
                  return
                }
                const endDateOfQuarter = moment(date).quarter(date.quarter()).endOf('quarter').format('YYYY-MM-DD')
                setEditingRow({
                  ...record,
                  date: endDateOfQuarter,
                  key: record.key,
                  resolvedQuarter: endDateOfQuarter,
                })
              }}
            />
          ) : dataIndex === 'name' ? (
            <AutoComplete
              style={{ margin: 0, width: '100%' }}
              value={editingRow.key !== 'new-row' ? String(editingRow[dataIndex]) : undefined}
              onChange={(e) => setEditingRow({ ...editingRow, name: e, key: record.key })}
              filterOption={(inputValue, option) => {
                return option?.value.toString().toUpperCase().indexOf(inputValue.toString().toUpperCase()) !== -1
              }}
            >
              {options &&
                options.map((item) => (
                  <AutoComplete.Option key={item} value={item}>
                    {item}
                  </AutoComplete.Option>
                ))}
            </AutoComplete>
          ) : dataIndex === 'fund_family_code' ? (
            <Tooltip color="red" title="4 digits required" visible={showTooltip}>
              <Form.Item style={{ margin: 0 }} name={dataIndex.toString()} validateTrigger="onBlur">
                <CustomNumberInput
                  style={{
                    borderColor: isDirty ? '#faad14' : undefined,
                  }}
                  onPressEnter={save}
                  onBlur={save}
                  onChange={(e) => {
                    const val = e.target.value || ''
                    if (val.length !== 4 && val.length !== 0) {
                      setShowTooltip(true)
                    } else {
                      setShowTooltip(false)
                    }
                  }}
                  precision={0}
                  max={10000}
                  maxLength={4}
                  minLength={4}
                />
              </Form.Item>
            </Tooltip>
          ) : (
            <Form.Item style={{ margin: 0 }} name={dataIndex.toString()}>
              <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
          )}
        </>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{ paddingRight: 24, minHeight: 20, display: 'flex', alignItems: 'center' }}
        >
          {
            // @ts-ignore
            dataIndex === 'resolvedQuarter' ? `${record.year}-Q${record.quarter}` : children
          }
        </div>
      )
  }

  return <td {...restProps}>{childNode}</td>
}

export default EditableCell
