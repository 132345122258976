import { AxiosPromise, AxiosInstance } from 'axios'

export interface UserService {
  fetchInternalUsers: () => AxiosPromise<{ internal_users: string[] }>
}

export const UserService = (request: AxiosInstance): UserService => {
  return {
    fetchInternalUsers: () => request.get(`/manager-link/users`),
  }
}
