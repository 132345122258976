import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { FundDetailDropdownProvider } from '../../shared/api/context/FundDetailDropdownContext'
import MainLayout from '../../shared/components/MainLayout'
import { ActivityLog } from './ActivityLog'
import Benchmarks from './Benchmarks'
import CodeTable from './CodeTable'
import IndexManager from './IndexManager'
import { SearchQueryManage } from './SearchQueryManage'
import UniverseManager from './UniverseManager'

type Props = {}

const System: React.FC<Props> = () => {
  const { path } = useRouteMatch()
  return (
    <MainLayout>
      <FundDetailDropdownProvider>
        <Switch>
          <Route path={`${path}/benchmarks`} component={Benchmarks} />
          <Route path={`${path}/activity-log`} component={ActivityLog} />
          <Route path={`${path}/index-manager`} component={IndexManager} />
          <Route path={`${path}/universe-manager`} component={UniverseManager} />
          <Route path={`${path}/search-query`} component={SearchQueryManage} />
          <Route path={`${path}/code-table`} component={CodeTable} />
          <Redirect to={`${path}/benchmarks`} />
        </Switch>
      </FundDetailDropdownProvider>
    </MainLayout>
  )
}

export default System
