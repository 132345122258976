import { notification, Row, Input, Col, Button, Space, Collapse } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React, { CSSProperties, useMemo } from 'react'
import {
  DropResult,
  DragDropContext,
  Droppable,
  Draggable,
  DraggableLocation,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../../app/rootReducer'
import { Colors } from '../../../shared/colors'
import { LoadingDetails } from '../../../shared/components/LoadingDetails'
import { updateFirmSearchColumns } from '../../../slice/appSettingsSlice'
import { FirmSearchColumnContext, FirmSearchColumnPropsExtended } from './FirmSearchColumnContext'

type FirmSearchColumnSource = {
  groupName: string
  columns: FirmSearchColumnPropsExtended[]
}

const Wrapper = styled.div`
  max-height: 90vh;
`

enum DroppableIds {
  TargetColumns = 'targetColumns',
  SourceColumns = 'sourceColumns',
}

const { Panel } = Collapse

const grid = 8

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  borderRadius: '5px',
  padding: grid,
  minHeight: '100%',
})

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
): CSSProperties => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,

  // change background colour if dragging
  background: isDragging ? Colors.primary : 'white',
  border: `1px solid ${Colors.primary}`,
  borderRadius: '5px',
  marginBottom: '3px',

  // styles we need to apply on draggables
  ...draggableStyle,
})

const reorder = (list: FirmSearchColumnPropsExtended[] | undefined, startIndex: number, endIndex: number) => {
  if (!list || !list.length) {
    return []
  }
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const remove = (target: FirmSearchColumnPropsExtended[], startIndex: number) => {
  const results = Array.from(target)
  results.splice(startIndex, 1)
  return results
}

const move = (
  source: FirmSearchColumnSource[],
  destination: unknown[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation,
) => {
  const sourceClone = source.reduce<FirmSearchColumnPropsExtended[]>(
    (s, i) => (i.columns ? [...s, ...i.columns] : s),
    [],
  )

  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index - 1, 1)
  destClone.splice(droppableDestination.index, 0, removed)

  return {
    [droppableSource.droppableId]: sourceClone,
    [droppableDestination.droppableId]: destClone,
  }
}

interface HeaderGroupProps {
  groupName: string
  onAddAll: () => void
}

const Flex = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const HeaderGroup: React.FC<HeaderGroupProps> = ({ groupName, onAddAll }) => {
  return (
    <Flex>
      <span>{groupName}</span>
      <Button type="link" size="small" onClick={onAddAll}>
        Add all
      </Button>
    </Flex>
  )
}

type Props = {
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
}

const FirmSearchColumnBuilder: React.FC<Props> = ({ isOpen, setOpen }) => {
  const { dataAllColumns, loading, defaultColumns } = React.useContext(FirmSearchColumnContext)
  const { firmSearchColumns } = useSelector((state: RootState) => state.appSettings)
  const [textColumnSearch, setTextColumnSearch] = React.useState<string>('')
  const dispatch = useDispatch()
  const [activePanels, setActivePanels] = React.useState<string[]>([])

  const firmSearchAllColumns: FirmSearchColumnSource[] = React.useMemo(() => {
    if (!dataAllColumns) {
      return []
    }
    return Object.keys(dataAllColumns).reduce((acc, curr) => {
      const currentColumn = dataAllColumns[curr]
      const foundIndex = acc.findIndex((item) => item.groupName === currentColumn.section)
      if (foundIndex === -1) {
        return [
          ...acc,
          {
            groupName: currentColumn.section,
            columns: [
              {
                title: currentColumn.human_readable_name,
                dataIndex: curr,
                key: curr,
                filter_type: currentColumn.filter_type,
                sorter: true,
              },
            ],
          },
        ]
      }

      return [
        ...acc.slice(0, foundIndex),
        {
          ...acc[foundIndex],
          columns: [
            ...acc[foundIndex].columns,
            {
              title: currentColumn.human_readable_name,
              dataIndex: curr,
              key: curr,
              filter_type: currentColumn.filter_type,
              sorter: true,
            },
          ],
        },
        ...acc.slice(foundIndex + 1, acc.length),
      ]
    }, [] as FirmSearchColumnSource[])
  }, [dataAllColumns])

  const flattenAllColumns = firmSearchAllColumns && firmSearchAllColumns.flatMap((item) => item.columns)
  const firmColumns = firmSearchColumns
    ? firmSearchColumns.reduce((acc, currKey) => {
        const foundColumn = flattenAllColumns.find((item) => item.key === currKey)
        if (foundColumn) {
          return [...acc, foundColumn]
        }
        return acc
      }, [] as FirmSearchColumnPropsExtended[])
    : defaultColumns

  console.log({ firmSearchAllColumns })

  const [targetColumns, setTargetColumns] = React.useState<FirmSearchColumnPropsExtended[]>(
    firmColumns.length ? firmColumns : defaultColumns,
  )

  const sourceColumns = useMemo(() => {
    if (textColumnSearch !== '') {
      return firmSearchAllColumns.map((i) => ({
        ...i,
        columns: i.columns.filter(
          (c) =>
            c.title?.toString().toLowerCase().includes(textColumnSearch.toLowerCase()) &&
            !targetColumns.some((t) => t.key === c.key),
        ),
      }))
    }
    return firmSearchAllColumns.map((i) => ({
      ...i,
      columns: i.columns.filter((c) => !targetColumns.some((t) => t.key === c.key)),
    }))
  }, [firmSearchAllColumns, targetColumns, textColumnSearch])

  React.useEffect(() => {
    textColumnSearch && setActivePanels(sourceColumns.map((item) => item.groupName))
  }, [sourceColumns, textColumnSearch])

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    if (source.droppableId === DroppableIds.TargetColumns && destination.droppableId !== DroppableIds.TargetColumns) {
      const result = remove(targetColumns, source.index)

      setTargetColumns(result)
      return
    }

    if (source.droppableId === DroppableIds.SourceColumns && destination.droppableId === DroppableIds.TargetColumns) {
      const result = move(sourceColumns, targetColumns, source, destination)

      setTargetColumns(result[DroppableIds.TargetColumns] as FirmSearchColumnPropsExtended[])
      return
    }

    if (source.droppableId === DroppableIds.TargetColumns && destination.droppableId === DroppableIds.TargetColumns) {
      const result = reorder(targetColumns, source.index, destination.index)

      setTargetColumns(result)
      return
    }
  }

  const uniqueColumns = (columns: FirmSearchColumnPropsExtended[]) =>
    Array.from(new Set(columns.map((item) => item.key))).map((key) => {
      return columns.find((item) => item.key === key) as FirmSearchColumnPropsExtended
    })

  const handleAddGroupColumns = (columns: FirmSearchColumnPropsExtended[]) => {
    setTargetColumns(uniqueColumns([...targetColumns, ...columns]))
  }

  const handleSubmit = () => {
    dispatch(updateFirmSearchColumns(targetColumns.map((item) => item.key)))
    notification.success({ message: 'Updated columns successfully!', placement: 'bottomLeft' })
    setOpen(false)
  }

  const handleReset = () => {
    setTargetColumns(defaultColumns)
  }

  const handleAddAll = () => {
    const flatenColumns = firmSearchAllColumns.flatMap((item) => item.columns)
    setTargetColumns(uniqueColumns(flatenColumns))
  }

  return (
    <Modal
      title="Update Columns"
      visible={isOpen}
      width={800}
      okText="Submit"
      onOk={handleSubmit}
      bodyStyle={{ overflow: 'auto', maxHeight: '65vh' }}
      onCancel={() => setOpen(false)}
    >
      {!loading && (
        <Wrapper>
          <DragDropContext onDragEnd={onDragEnd}>
            <Row style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline' }}>
              <div>
                <Input placeholder="Search Columns..." onChange={(e) => setTextColumnSearch(e.target.value)} />
              </div>
            </Row>
            <Row style={{ marginTop: 20 }} gutter={16}>
              <Col span={12}>
                <h3 style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>Displayed Columns</span>
                  <Button type="link" size="small" onClick={handleReset}>
                    Reset
                  </Button>
                </h3>
                <Droppable droppableId={DroppableIds.TargetColumns}>
                  {({ innerRef, placeholder }, snapshot) => {
                    return (
                      <div ref={innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                        {targetColumns &&
                          targetColumns.map((targetCol, index) => (
                            <Draggable key={targetCol.key} draggableId={`target_${targetCol.key}`} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                  style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                >
                                  {targetCol.title}
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {placeholder}
                      </div>
                    )
                  }}
                </Droppable>
              </Col>
              <Col span={12}>
                <h3 style={{ display: 'flex', justifyContent: 'space-between' }}>
                  All Columns
                  <Space>
                    <Button
                      type="link"
                      size="small"
                      onClick={() =>
                        activePanels.length > 0
                          ? setActivePanels([])
                          : setActivePanels(sourceColumns.map((source) => source.groupName))
                      }
                    >
                      {activePanels.length > 0 ? 'Collapse all' : 'Expand all'}
                    </Button>
                    <Button type="link" size="small" onClick={handleAddAll}>
                      Add all
                    </Button>
                  </Space>
                </h3>
                <Droppable droppableId={DroppableIds.SourceColumns}>
                  {({ innerRef, placeholder }, snapshot) => {
                    let draggableIndex = 0

                    return (
                      <div ref={innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                        <Collapse
                          bordered={false}
                          defaultActiveKey={[]}
                          activeKey={activePanels}
                          onChange={(key) => {
                            setActivePanels(key as string[])
                          }}
                        >
                          {sourceColumns.map((group) => {
                            if (textColumnSearch !== '' && group.columns.length === 0) {
                              return null
                            }
                            return (
                              <Panel
                                header={
                                  <HeaderGroup
                                    groupName={group.groupName}
                                    onAddAll={() => handleAddGroupColumns(group.columns)}
                                  />
                                }
                                key={`${group.groupName}`}
                              >
                                {group.columns &&
                                  group.columns.map((col) => {
                                    draggableIndex++
                                    return (
                                      <Draggable key={col.key} draggableId={`source_${col.key}`} index={draggableIndex}>
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                          >
                                            {col.title}
                                          </div>
                                        )}
                                      </Draggable>
                                    )
                                  })}
                                {placeholder}
                              </Panel>
                            )
                          })}
                        </Collapse>
                      </div>
                    )
                  }}
                </Droppable>
              </Col>
            </Row>
          </DragDropContext>
        </Wrapper>
      )}
      {loading && <LoadingDetails name="Loading Column" />}
    </Modal>
  )
}

export default FirmSearchColumnBuilder
