import React from 'react'
import styled from 'styled-components'
import { FundDetailDropdownContext } from '../../../../shared/api/context/FundDetailDropdownContext'
import { Colors } from '../../../../shared/colors'
import { DividerForm } from '../../../../shared/components/DividerForm'
import { FormItemRadio, FormItemSelect } from '../../../../shared/components/FormItemComponents'
import { RenderLabelFormFirm } from '../../../../shared/components/RenderLabelFormFirm'
import { FormInstance } from 'antd/lib/form'
import { updateFieldValue } from '../../../../shared/utils/formUtils'
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
const Flex = styled.div`
  display: flex;
  flex: 1;
`

type Props = { form: FormInstance }
export const FirmCoInvestment: React.FC<Props> = ({ form }) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)
  const offer_coinvestments = form.getFieldValue('offer_coinvestments')
  const hasOfferCoinvestments = offer_coinvestments === '1'

  React.useEffect(() => {
    const { getFieldValue } = form
    if (offer_coinvestments === '1') {
      return
    }
    !!getFieldValue('typical_size') && updateFieldValue(form, 'typical_size', null)
    !!getFieldValue('typical_liquidity') && updateFieldValue(form, 'typical_liquidity', null)
    !!getFieldValue('coinvestment_number') && updateFieldValue(form, 'coinvestment_number', null)
    //
    !!getFieldValue('private_equity') && updateFieldValue(form, 'private_equity', null)
    !!getFieldValue('public_equity') && updateFieldValue(form, 'public_equity', null)
    !!getFieldValue('private_debt') && updateFieldValue(form, 'private_debt', null)
    !!getFieldValue('public_debt') && updateFieldValue(form, 'public_debt', null)
    !!getFieldValue('other_instrument') && updateFieldValue(form, 'other_instrument', null)
    //
    !!getFieldValue('vehicle_commingled_fund') && updateFieldValue(form, 'vehicle_commingled_fund', null)
    !!getFieldValue('vehicle_fund_of_one') && updateFieldValue(form, 'vehicle_fund_of_one', null)
    !!getFieldValue('vehicle_managed_account') && updateFieldValue(form, 'vehicle_managed_account', null)
    !!getFieldValue('other_vehicle') && updateFieldValue(form, 'other_vehicle', null)
  }, [offer_coinvestments])

  console.log(fundDataDropdown?.private_equity)

  return (
    <Wrapper>
      <div
        style={{
          padding: '0.5rem 1.5rem',
          background: 'rgb(250, 250, 250)',
          borderBottom: `solid 1px ${Colors.border}`,
          fontWeight: 'bold',
        }}
      >
        Firm Co-Investments
      </div>

      <Flex style={{ flexDirection: 'column', padding: '2rem' }}>
        <FormItemRadio
          name="offer_coinvestments"
          {...{ labelCol: { span: 6 }, wrapperCol: { span: 6 } }}
          label={<RenderLabelFormFirm value="Offer Co-Investments" />}
          options={[
            { value: '0', title: 'Not Responded' },
            { value: '1', title: 'Yes' },
            { value: '2', title: 'No' },
          ]}
        />
        <FormItemSelect
          name="typical_size"
          label={<RenderLabelFormFirm value="Typical Deal Size" />}
          labelCol={6}
          wrapperCol={6}
          disabled={!hasOfferCoinvestments}
          allowClear
          options={
            fundDataDropdown?.typical_size && fundDataDropdown.typical_size.length > 0
              ? fundDataDropdown.typical_size.map((item) => ({
                  value: item.code_code,
                  text: item.code_description,
                }))
              : []
          }
        />
        <FormItemSelect
          name="typical_liquidity"
          label={<RenderLabelFormFirm value="Typical Liquidity" />}
          labelCol={6}
          wrapperCol={6}
          disabled={!hasOfferCoinvestments}
          allowClear
          options={
            fundDataDropdown?.typical_liquidity && fundDataDropdown.typical_liquidity.length > 0
              ? fundDataDropdown.typical_liquidity.map((item) => ({
                  value: item.code_code,
                  text: item.code_description,
                }))
              : []
          }
        />
        <FormItemSelect
          name="coinvestment_number"
          label={<RenderLabelFormFirm value="Number Deals per Year" />}
          labelCol={6}
          wrapperCol={6}
          disabled={!hasOfferCoinvestments}
          allowClear
          options={
            fundDataDropdown?.coinvestment_number && fundDataDropdown.coinvestment_number.length > 0
              ? fundDataDropdown.coinvestment_number.map((item) => ({
                  value: item.code_code,
                  text: item.code_description,
                }))
              : []
          }
        />
        <DividerForm text="Instrument Types" />
        <FormItemSelect
          name="private_equity"
          label={<RenderLabelFormFirm value="Private Equity" />}
          labelCol={6}
          wrapperCol={6}
          disabled={!hasOfferCoinvestments}
          allowClear
          options={
            fundDataDropdown?.private_equity && fundDataDropdown.private_equity.length > 0
              ? fundDataDropdown.private_equity.map((item) => ({
                  value: item.code_code,
                  text: item.code_description,
                }))
              : []
          }
        />
        <FormItemSelect
          name="public_equity"
          label={<RenderLabelFormFirm value="Public Equity" />}
          labelCol={6}
          wrapperCol={6}
          disabled={!hasOfferCoinvestments}
          allowClear
          options={
            fundDataDropdown?.public_equity && fundDataDropdown.public_equity.length > 0
              ? fundDataDropdown.public_equity.map((item) => ({
                  value: item.code_code,
                  text: item.code_description,
                }))
              : []
          }
        />
        <FormItemSelect
          name="private_debt"
          label={<RenderLabelFormFirm value="Private Debt" />}
          labelCol={6}
          wrapperCol={6}
          disabled={!hasOfferCoinvestments}
          allowClear
          options={
            fundDataDropdown?.private_debt && fundDataDropdown.private_debt.length > 0
              ? fundDataDropdown.private_debt.map((item) => ({
                  value: item.code_code,
                  text: item.code_description,
                }))
              : []
          }
        />
        <FormItemSelect
          name="public_debt"
          label={<RenderLabelFormFirm value="Public Debt" />}
          labelCol={6}
          wrapperCol={6}
          disabled={!hasOfferCoinvestments}
          allowClear
          options={
            fundDataDropdown?.public_debt && fundDataDropdown.public_debt.length > 0
              ? fundDataDropdown.public_debt.map((item) => ({
                  value: item.code_code,
                  text: item.code_description,
                }))
              : []
          }
        />
        <FormItemSelect
          name="other_instrument"
          label={<RenderLabelFormFirm value="Other" />}
          labelCol={6}
          wrapperCol={6}
          disabled={!hasOfferCoinvestments}
          allowClear
          options={
            fundDataDropdown?.other_instrument && fundDataDropdown.other_instrument.length > 0
              ? fundDataDropdown.other_instrument.map((item) => ({
                  value: item.code_code,
                  text: item.code_description,
                }))
              : []
          }
        />
        <DividerForm text="Investment Vehicles" />
        <FormItemSelect
          name="vehicle_commingled_fund"
          label={<RenderLabelFormFirm value="Commingled Fund" />}
          labelCol={6}
          wrapperCol={6}
          disabled={!hasOfferCoinvestments}
          allowClear
          options={
            fundDataDropdown?.vehicle_commingled_fund && fundDataDropdown.vehicle_commingled_fund.length > 0
              ? fundDataDropdown.vehicle_commingled_fund.map((item) => ({
                  value: item.code_code,
                  text: item.code_description,
                }))
              : []
          }
        />
        <FormItemSelect
          name="vehicle_fund_of_one"
          label={<RenderLabelFormFirm value="Fund of One" />}
          labelCol={6}
          wrapperCol={6}
          disabled={!hasOfferCoinvestments}
          allowClear
          options={
            fundDataDropdown?.vehicle_fund_of_one && fundDataDropdown.vehicle_fund_of_one.length > 0
              ? fundDataDropdown.vehicle_fund_of_one.map((item) => ({
                  value: item.code_code,
                  text: item.code_description,
                }))
              : []
          }
        />
        <FormItemSelect
          name="vehicle_managed_account"
          label={<RenderLabelFormFirm value="Separate Managed Acct" />}
          labelCol={6}
          wrapperCol={6}
          disabled={!hasOfferCoinvestments}
          allowClear
          options={
            fundDataDropdown?.vehicle_managed_account && fundDataDropdown.vehicle_managed_account.length > 0
              ? fundDataDropdown.vehicle_managed_account.map((item) => ({
                  value: item.code_code,
                  text: item.code_description,
                }))
              : []
          }
        />
        <FormItemSelect
          name="other_vehicle"
          label={<RenderLabelFormFirm value="Other" />}
          labelCol={6}
          wrapperCol={6}
          disabled={!hasOfferCoinvestments}
          allowClear
          options={
            fundDataDropdown?.other_vehicle && fundDataDropdown.other_vehicle.length > 0
              ? fundDataDropdown.other_vehicle.map((item) => ({
                  value: item.code_code,
                  text: item.code_description,
                }))
              : []
          }
        />
      </Flex>
    </Wrapper>
  )
}
