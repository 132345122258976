import { Button, Checkbox, notification, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../../../shared/api'
import { FundDetailDataContext } from '../../../../shared/api/context/FundDetailDataContext'
import { FundDetailsUniverses } from '../../../../shared/api/models/FundDetails'
import { useServiceState } from '../../../../shared/hooks/useServiceState'
const Flex = styled.div`
  flex: 1;
  display: flex;
`
const StyledHeader = styled.div`
  display: flex;
  padding: 1rem 1.5rem;
  justify-content: space-between;
  align-items: center;
`
type UniverseSelect = {
  universe_id: number
  is_in_universe: boolean
  is_active_in_universe: boolean
}
type Props = {}
export const Universes: React.FC<Props> = () => {
  const { dataFundDetail } = React.useContext(FundDetailDataContext)
  const { invoke: getUniverses, data: dataUniverses, loading } = useServiceState(
    APIService.fundDetailService.fetchFundUniverses,
  )

  const [isEdited, setIsEdited] = React.useState(false)
  const [listUniverseSelected, setListUniverseSelected] = React.useState<UniverseSelect[]>([])
  const [editedData, setEditedData] = React.useState<FundDetailsUniverses[]>([])
  const [loadingAssign, setLoadingAssign] = React.useState(false)

  React.useEffect(() => {
    dataFundDetail?.fund_id && getUniverses(dataFundDetail.fund_id)
  }, [getUniverses, dataFundDetail])

  const refetchUniverses = () => {
    dataFundDetail?.fund_id && getUniverses(dataFundDetail.fund_id)
    setListUniverseSelected([])
  }
  React.useEffect(() => {
    if (dataUniverses) {
      setEditedData(dataUniverses)
    }
  }, [dataUniverses])

  const getIsActive = (item: string) => {
    const selectedRow = editedData.find((i) => i.universe_id === parseInt(item))
    return selectedRow ? selectedRow.is_active_in_universe : false
  }
  const getIsInUniverse = (item: string) => {
    const selectedRow = editedData.find((i) => i.universe_id === parseInt(item))
    return selectedRow ? selectedRow.is_in_universe : false
  }

  const onSelectChange = (selectedRowKeys: string[]) => {
    setListUniverseSelected(
      selectedRowKeys.map((item) => ({
        universe_id: parseInt(item),
        is_active_in_universe: getIsActive(item),
        is_in_universe: getIsInUniverse(item),
      })),
    )
    setIsEdited(selectedRowKeys.length > 0)
  }

  const handleSubmit = () => {
    const universeSubmit = listUniverseSelected.map((item) => ({
      universe_id: item.universe_id,
      is_active_in_universe: getIsActive(item.universe_id.toString()),
      is_in_universe: getIsInUniverse(item.universe_id.toString()),
    }))
    setLoadingAssign(true)
    if (dataFundDetail?.fund_id) {
      APIService.fundDetailService
        .updateFundUniverses(dataFundDetail?.fund_id, { updates: universeSubmit })
        .then(() => {
          notification.success({
            message: `Updated successfully!`,
          })
          refetchUniverses()
        })
        .catch((err) => {
          notification.error({ message: `Failed to Update!` })
        })
        .finally(() => setLoadingAssign(false))
    }
  }

  const columns: ColumnProps<FundDetailsUniverses>[] = [
    {
      title: 'Universe ID',
      dataIndex: 'universe_id',
      key: 'universe_id',
      sorter: (a, b) => {
        if (a.universe_id && b.universe_id) {
          return a.universe_id - b.universe_id
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Name',
      dataIndex: 'universe_name',
      key: 'universe_name',
      sorter: (a, b) => {
        if (a.universe_name && b.universe_name) {
          return a.universe_name.localeCompare(b.universe_name)
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Description',
      dataIndex: 'universe_description',
      key: 'universe_description',
      sorter: (a, b) => {
        if (a.universe_description && b.universe_description) {
          return a.universe_description.localeCompare(b.universe_description)
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Status',
      dataIndex: 'status_in_universe',
      key: 'status_in_universe',
    },
    {
      title: 'Is in Universe',
      dataIndex: 'is_in_universe',
      key: 'is_in_universe',
      render: (_, record) => (
        <Checkbox
          checked={record.is_in_universe}
          onChange={(e) => {
            const index = editedData.findIndex((item) => item.universe_id === record.universe_id)
            setEditedData([
              ...editedData.slice(0, index),
              { ...editedData[index], is_in_universe: e.target.checked },
              ...editedData.slice(index + 1, editedData.length),
            ])
          }}
        />
      ),
    },
    {
      title: 'Is Active',
      dataIndex: 'is_active_in_universe',
      key: 'is_active_in_universe',
      render: (_, record) => (
        <Checkbox
          checked={record.is_active_in_universe}
          onChange={(e) => {
            const index = editedData.findIndex((item) => item.universe_id === record.universe_id)
            setEditedData([
              ...editedData.slice(0, index),
              { ...editedData[index], is_active_in_universe: e.target.checked },
              ...editedData.slice(index + 1, editedData.length),
            ])
          }}
        />
      ),
    },
  ]

  return (
    <Flex style={{ flexDirection: 'column' }}>
      <StyledHeader>
        <div style={{ fontSize: 14, fontWeight: 'bold' }}>Fund in Universes</div>
        <div>
          <Button
            type="primary"
            style={{ marginRight: '0.5rem' }}
            disabled={!isEdited}
            onClick={handleSubmit}
            loading={loadingAssign}
          >
            Update
          </Button>
          <Button type="primary" onClick={refetchUniverses}>
            Refresh
          </Button>
        </div>
      </StyledHeader>
      <Flex>
        <Table
          rowKey="universe_id"
          rowSelection={{
            // getCheckboxProps: (record) => {
            //   return record.universe_id === 1 ? 'checked'
            // },
            onChange: (selectedRowKeys) => {
              onSelectChange(selectedRowKeys as string[])
            },
          }}
          dataSource={editedData}
          columns={columns}
          style={{ flex: 1 }}
          pagination={dataUniverses && dataUniverses.length > 10 ? undefined : false}
          loading={loading}
        />
      </Flex>
    </Flex>
  )
}
