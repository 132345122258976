import React from 'react'
import { ListSearchExNewFundType } from '../../SearchExNewFundLabel'

const defaultValue = {
  boldText: '',
  filterArr: [] as ListSearchExNewFundType[],
  isFiltering: false,
}
type Props = {
  text: string
  filterArr: ListSearchExNewFundType[]
}
export const ExNewFundLabelSearchContext = React.createContext(defaultValue)
export const ExNewFundLabelSearchProvider: React.FC<Props> = ({ children, text, filterArr }) => {
  const isFiltering = text === '' ? false : true
  const value = {
    boldText: text,
    filterArr,
    isFiltering,
  }
  return <ExNewFundLabelSearchContext.Provider value={value}>{children}</ExNewFundLabelSearchContext.Provider>
}
