import { Col, Form, notification, Row, Spin } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import moment from 'moment'
import React from 'react'
import APIService from '../../../../shared/api'
import {
  DueDiligenceMeetingCreate,
  DueDiligenceMeetingResponse,
  DueDiligenceMeetingUpdate,
} from '../../../../shared/api/models/FirmDetails'
import { DividerForm } from '../../../../shared/components/DividerForm'
import {
  FormItemDatePicker,
  FormItemInput,
  FormItemSelect,
  FormItemTextArea,
} from '../../../../shared/components/FormItemComponents'

type Props = {
  selectedMeeting: DueDiligenceMeetingResponse | undefined
  showModal: boolean
  setShowModal: (value: boolean) => void
  setSelectedMeeting: (value: DueDiligenceMeetingResponse | undefined) => void
  updateMeeting: () => void
  firmId: string
}
export const MEETING_OPTIONS_TYPE = [
  {
    text: 'Site Review',
    value: '1',
  },
  {
    text: 'At HFR',
    value: '2',
  },
  {
    text: 'Other',
    value: '3',
  },
]
export const ModalEditTableMeeting: React.FC<Props> = ({
  selectedMeeting,
  showModal,
  setShowModal,
  setSelectedMeeting,
  updateMeeting,
  firmId,
}) => {
  const [loadingSubmit, setLoadingSubmit] = React.useState(false)
  const [form] = Form.useForm()

  React.useEffect(() => {
    if (selectedMeeting) {
      const initialValue = {
        ...selectedMeeting,
        meeting_date: selectedMeeting.meeting_date ? moment(selectedMeeting.meeting_date) : null,
      }
      form.setFieldsValue(initialValue)
    } else {
      form.resetFields()
    }
  }, [form, selectedMeeting])

  const handleUpdateRow = () => {
    if (selectedMeeting) {
      const submitUpdate: Partial<DueDiligenceMeetingUpdate> = {
        meeting_type: form.getFieldValue('meeting_type'),
        meeting_date: form.getFieldValue('meeting_date')
          ? moment(form.getFieldValue('meeting_date')).format('YYYY-MM-DD')
          : undefined,
        attendee: form.getFieldValue('attendee'),
        comments: form.getFieldValue('comments'),
        identifier: [firmId, selectedMeeting.meeting_type, moment(selectedMeeting.meeting_date).format('YYYY-MM-DD')],
      }
      setLoadingSubmit(true)
      APIService.firmDetailService
        .updateDueDiligenceMeeting(firmId, submitUpdate)
        .then(() => {
          notification.success({ message: 'Meeting Updated!' })
          updateMeeting()
        })
        .catch((err) => {
          console.error({ err })
          notification.error({ message: 'Meeting Update Failed!' })
        })
        .finally(() => {
          setLoadingSubmit(false)
          setShowModal(false)
          setSelectedMeeting(undefined)
          form.resetFields()
        })
    } else {
      setLoadingSubmit(true)
      const submitData: Partial<DueDiligenceMeetingCreate> = {
        meeting_type: form.getFieldValue('meeting_type'),
        meeting_date: form.getFieldValue('meeting_date')
          ? moment(form.getFieldValue('meeting_date')).format('YYYY-MM-DD')
          : undefined,
        attendee: form.getFieldValue('attendee'),
        comments: form.getFieldValue('comments'),
      }
      APIService.firmDetailService
        .createDueDiligenceMeeting(firmId, submitData)
        .then(() => {
          notification.success({ message: 'Meeting Created!' })
          updateMeeting()
        })
        .catch((err) => {
          console.error({ err })
          notification.error({ message: 'Meeting Create Failed!' })
        })
        .finally(() => {
          setLoadingSubmit(false)
          setShowModal(false)
          setSelectedMeeting(undefined)
          form.resetFields()
        })
    }
  }
  return (
    <Modal
      title={selectedMeeting ? 'Edit Meeting' : 'Create New Meeting'}
      visible={showModal}
      okText={
        loadingSubmit ? (
          <>
            <Spin style={{ marginRight: 4 }} />
          </>
        ) : (
          'OK'
        )
      }
      onOk={() => handleUpdateRow()}
      onCancel={() => {
        setSelectedMeeting(undefined)
        setShowModal(false)
      }}
    >
      <div>
        <DividerForm text="Meeting Information" />
        <Form layout="vertical" form={form}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <FormItemSelect name="meeting_type" label="Meeting Type" options={MEETING_OPTIONS_TYPE} />
            </Col>
            <Col span={24}>
              <FormItemDatePicker name="meeting_date" label="Meeting Date" />
            </Col>
            <Col span={24}>
              <FormItemInput name="attendee" label="Attendee" placeholder="Attendee" />
            </Col>
            <Col span={24}>
              <FormItemTextArea name="comments" label="Comments" row={4} />
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
