import { Col, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../../app/rootReducer'
import { FundDetailDropdownContext } from '../../../shared/api/context/FundDetailDropdownContext'
import { ExFundFieldChange, FundDetails } from '../../../shared/api/models/FundDetails'
import { FormItemSelect, FormItemTextArea } from '../../../shared/components/FormItemComponents'
import { RenderLabelExNewFund } from '../../../shared/components/RenderLabelExNewFund'
import { LeftNavExNewFundEnum } from '../../../shared/SearchExNewFundLabel'
import { EMERGING_MARKET_SET } from '../../Dashboard/FundDetail/FundInformation/Region/FundInfoRegion'
import { NextBackNewFundTab } from './NextBackExNewFundTab'
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 1rem;
  padding: 0 2rem;
`

const ARRAY_FIELD_VALIDATE: string[] = [
  'strategy_code_new',
  'sub_strategy_code_new',
  'region_inv_focus',
  'strategy_description',
  'region_inv_focus_country',
]
type Props = {
  setStepTab: (value: LeftNavExNewFundEnum) => void
  setActiveTab: (value: LeftNavExNewFundEnum) => void
  activeTab: LeftNavExNewFundEnum
  stepTab: LeftNavExNewFundEnum
  form: FormInstance
  setDataFundDetailChange: (action: React.SetStateAction<Partial<FundDetails & ExFundFieldChange>>) => void
  dataFundDetailChangeRef: React.MutableRefObject<Partial<FundDetails & ExFundFieldChange>>
}
export const ExNewFundInvestStrategy: React.FC<Props> = ({
  form,
  setStepTab,
  setActiveTab,
  setDataFundDetailChange,
  activeTab,
  stepTab,
  dataFundDetailChangeRef,
}) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)
  const GlobaldataDropdown = useSelector((state: RootState) => state.appData)
  const selectedStrategy = form.getFieldValue('strategy_code_new')

  const optionSubstrategy = React.useMemo(() => {
    const newSubStrategyOptions = selectedStrategy
      ? GlobaldataDropdown && GlobaldataDropdown.subStrategies && GlobaldataDropdown.subStrategies.length > 0
        ? GlobaldataDropdown.subStrategies
            .filter((i) => i.strategy_code === selectedStrategy)
            .map((item) => ({
              value: item.sub_strategy_code,
              text: item.sub_strategy_name,
            }))
        : []
      : []

    return newSubStrategyOptions
  }, [GlobaldataDropdown, selectedStrategy])

  React.useEffect(() => {
    if (
      dataFundDetailChangeRef?.current &&
      Object.keys(dataFundDetailChangeRef?.current).includes('strategy_code_new')
    ) {
      if (
        (selectedStrategy &&
          form.getFieldValue('sub_strategy_code_new') &&
          optionSubstrategy.length > 0 &&
          optionSubstrategy.findIndex((item) => item.value === form.getFieldValue('sub_strategy_code_new')) === -1) ||
        (!selectedStrategy && form.getFieldValue('sub_strategy_code_new'))
      ) {
        form.setFieldsValue({ sub_strategy_code_new: null })
      }
    }
  }, [form, selectedStrategy, optionSubstrategy, dataFundDetailChangeRef])

  const selectedRegion = form.getFieldValue('region_inv_focus')

  const regionCountryOptions = React.useMemo(() => {
    if (!fundDataDropdown) {
      return []
    }
    if (!fundDataDropdown.region_inv_focus_country || fundDataDropdown.region_inv_focus_country.length === 0) {
      return []
    }

    const options = selectedRegion
      ? fundDataDropdown.region_inv_focus_country.filter(
          (item) => item.code_code.slice(0, selectedRegion.length) === selectedRegion,
        )
      : fundDataDropdown.region_inv_focus_country
    return options.map((item) => ({ value: item.code_code, text: item.code_description }))
  }, [fundDataDropdown, selectedRegion])

  React.useEffect(() => {
    if (
      (selectedRegion &&
        form.getFieldValue('region_inv_focus_country') &&
        regionCountryOptions.length > 0 &&
        regionCountryOptions.findIndex((item) => item.value === form.getFieldValue('region_inv_focus_country')) ===
          -1) ||
      (!selectedRegion && form.getFieldValue('region_inv_focus_country'))
    ) {
      form.setFieldsValue({ region_inv_focus_country: null })
      setDataFundDetailChange((prev) => ({ ...prev, region_inv_focus_country: null }))
    }
  }, [form, regionCountryOptions, selectedRegion, setDataFundDetailChange])

  React.useEffect(() => {
    if (selectedRegion) {
      if (EMERGING_MARKET_SET.includes(selectedRegion)) {
        form.setFieldsValue({ is_emerging_markets_fund: true })
        setDataFundDetailChange((prev) => ({ ...prev, is_emerging_markets_fund: true }))
      } else {
        setDataFundDetailChange((prev) => ({ ...prev, is_emerging_markets_fund: false }))
        form.setFieldsValue({ is_emerging_markets_fund: false })
      }
    }
  }, [form, selectedRegion, setDataFundDetailChange])

  return (
    <Wrapper>
      <Row>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemSelect
            name="strategy_code_new"
            label={<RenderLabelExNewFund value="Main Strategy Classification" />}
            labelCol={12}
            wrapperCol={18}
            rules={[{ required: true, message: 'Main Strategy Classification is required' }]}
            options={
              GlobaldataDropdown?.strategies && GlobaldataDropdown?.strategies.length > 0
                ? GlobaldataDropdown.strategies.map((item) => ({
                    value: item.strategy_code,
                    text: item.strategy_name,
                  }))
                : []
            }
          />
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemSelect
            name="sub_strategy_code_new"
            label={<RenderLabelExNewFund value="Sub-Strategy Classification" />}
            labelCol={12}
            wrapperCol={18}
            disabled={!selectedStrategy}
            options={optionSubstrategy}
            rules={[{ required: true, message: 'Sub-Strategy Classification is required' }]}
          />
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemSelect
            name="region_inv_focus"
            label={<RenderLabelExNewFund value="Regional Investment Focus" />}
            labelCol={12}
            wrapperCol={18}
            rules={[{ required: true, message: 'Regional Investment Focus is required' }]}
            options={
              fundDataDropdown?.region_inv_focus && fundDataDropdown?.region_inv_focus.length > 0
                ? fundDataDropdown.region_inv_focus
                    .filter((i) => i.code_code !== '1')
                    .map((item) => ({
                      value: item.code_code,
                      text: item.code_description,
                    }))
                : []
            }
          />
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemSelect
            name="region_inv_focus_country"
            label={<RenderLabelExNewFund value="Sub-Region Focus" />}
            labelCol={12}
            wrapperCol={18}
            options={regionCountryOptions}
            disabled={!selectedRegion}
            rules={[{ required: true, message: 'Sub-Region is required' }]}
          />
        </Col>
      </Row>
      <Row>
        <FormItemTextArea
          name="strategy_description"
          label={<RenderLabelExNewFund value="Strategy Description" />}
          row={15}
          rules={[{ required: true, message: 'Strategy Description is required' }]}
        />
      </Row>
      <NextBackNewFundTab
        setStepTab={setStepTab}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        stepTab={stepTab}
        ArrayValidateField={ARRAY_FIELD_VALIDATE}
        form={form}
      />
    </Wrapper>
  )
}
