import { Form, Input, notification, Spin } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import APIService from '../../shared/api'
type Props = {
  showModal: boolean
  setShowModal: (value: boolean) => void
  updateUniverseManagerDetail: () => void
  indexId: number
}
export const UniverseManagerModalAddFund: React.FC<Props> = ({
  showModal,
  setShowModal,
  updateUniverseManagerDetail,
  indexId,
}) => {
  const [loadingSubmit, setLoadingSubmit] = React.useState(false)
  const [fundIds, setFundIds] = React.useState<string>()

  const submitFormIndexManager = () => {
    if (!fundIds || !fundIds.trim()) {
      return
    }
    const updatedFundIds = fundIds.trim().split('\n')
    setLoadingSubmit(true)
    APIService.universeManagerService
      .updateUniverseManager(indexId, { fund_ids: updatedFundIds })
      .then(() => {
        notification.success({ message: `Add ${updatedFundIds.length} funds to Universe Manager !` })
        setShowModal(false)
        setFundIds(undefined)
        updateUniverseManagerDetail()
      })
      .catch((err) => {
        console.error(err)
        notification.error({ message: 'Failed to add funds to Universe Manager!' })
      })
      .finally(() => setLoadingSubmit(false))
  }

  return (
    <Modal
      width={700}
      visible={showModal}
      title="Add New Fund"
      okText={
        loadingSubmit ? (
          <>
            <Spin style={{ marginRight: 4 }} />
          </>
        ) : (
          'OK'
        )
      }
      onOk={submitFormIndexManager}
      onCancel={() => {
        setShowModal(false)
      }}
    >
      <Form layout="vertical">
        <Form.Item label="Fund IDs" required rules={[{ required: true, message: 'Please enter Fund IDs' }]}>
          <Input.TextArea
            placeholder="Enter or copy/paste single or multiple Fund IDs separated by new line"
            value={fundIds}
            onChange={(e) => setFundIds(e.target.value)}
            rows={10}
          />
          <span>Funds which are already inside the group will be ignored</span>
        </Form.Item>
      </Form>
    </Modal>
  )
}
