import { Collapse, Spin } from 'antd'
import React from 'react'
import Plot from 'react-plotly.js'

const { Panel } = Collapse
/**
 * Component rendering Plotly graph within Collapsible Panel
 * @param header - header for the panel
 * @param graphData - data to plotted
 * @param graphLayout - layout object for the graph
 * @param loading - boolean to render spinning icon
 */
const PanelDetailGraph = ({
  header,
  graphData,
  graphLayout,
  loading = false,
}: {
  header: string
  graphData: any
  graphLayout: any
  loading?: boolean
}) => {
  if (!loading) {
    return (
      <Collapse defaultActiveKey={['1']}>
        <Panel showArrow={true} header={<span id="vami">{header}</span>} key="1">
          <Plot
            data={graphData}
            layout={graphLayout}
            useResizeHandler={true}
            style={{ width: '100%', height: '100%' }}
            config={{
              displaylogo: false,
            }}
          />
        </Panel>
      </Collapse>
    )
  } else {
    return (
      <Collapse defaultActiveKey={['1']}>
        <Panel showArrow={true} header={header} key="1">
          <Spin size="large" />
        </Panel>
      </Collapse>
    )
  }
}

export default PanelDetailGraph
