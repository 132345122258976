import { Button, List } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { RecentlyEditedResponse } from '../../../shared/api/models/Funds'
import { LoadingDetails } from '../../../shared/components/LoadingDetails'
import { addTabs, FundFirmTypeEnum } from '../../../slice/appSettingsSlice'
const TextID = styled(Text)`
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
`
const Flex = styled.div`
  display: flex;
  flex: 1;
`
const StyledList = styled(List)`
  flex: 1;
  padding: 8px;
  overflow: auto;
  max-height: 46vh;
  .ant-list-item {
    padding: 4px;
  }
`
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`
type Props = { data: RecentlyEditedResponse | undefined; loading: boolean }
export const CreatedUpdatedFunds: React.FC<Props> = ({ data, loading }) => {
  const dispatch = useDispatch()
  return (
    <Wrapper>
      {data && (
        <StyledList
          bordered
          dataSource={data?.funds}
          renderItem={(item: any) => (
            <List.Item>
              <div>
                <TextID>Fund #{item.fund_id}</TextID>
                <Flex style={{ marginBottom: 10 }}>
                  <Flex style={{ alignItems: 'baseline' }}>
                    <div>
                      <Button
                        type="link"
                        style={{ fontSize: 12, fontWeight: 'bold', color: '#7598E6', padding: 0 }}
                        onClick={() =>
                          dispatch(addTabs({ name: item.fund_name, id: item.fund_id, type: FundFirmTypeEnum.FUNDS }))
                        }
                      >
                        {item.fund_name}
                      </Button>
                    </div>
                  </Flex>
                </Flex>
              </div>
            </List.Item>
          )}
        />
      )}
      {loading && <LoadingDetails name="Loading List" />}
    </Wrapper>
  )
}
