import { Card, Divider } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { ExFundDetails } from '../../shared/api/models/ExFundDetails'
import { FUND_STATUS_SHOW_PERFORMANCE } from '../Dashboard/FirmDetail/FundOfFirm/FirmLatestPerformance'
import ExFundPerformanceItem from './ExFundDetail/ExFundPerformanceItem'

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  z-index: 1;
  margin-bottom: 8px;
  .ant-card-head {
    border-bottom: none;
    font-size: 1rem;
    padding: 0 1rem;
  }
  .ant-card-body {
    padding: 0;
    flex: 1;
  }
`

type Props = {
  shouldUpdate: boolean
  setShouldUpdate: (value: boolean) => void
  setUpdatedFundPerformance: (updatedFund?: string) => void
  dataExFunds: ExFundDetails[]
}

const ExDashboardPerformance: React.FC<Props> = ({
  shouldUpdate,
  dataExFunds,
  setShouldUpdate,
  setUpdatedFundPerformance,
}) => {
  return (
    <StyledCard size="small" title={'Latest Performance'}>
      {dataExFunds &&
        dataExFunds.length > 0 &&
        dataExFunds.map((fund) => {
          if (FUND_STATUS_SHOW_PERFORMANCE.includes(fund.fund_status)) {
            return (
              <div key={fund.fund_id}>
                <ExFundPerformanceItem
                  fund={fund}
                  shouldUpdate={shouldUpdate}
                  setShouldUpdate={setShouldUpdate}
                  setUpdatedFundPerformance={setUpdatedFundPerformance}
                />
                <Divider />
              </div>
            )
          }
          return null
        })}
    </StyledCard>
  )
}

export default ExDashboardPerformance
