import { Button, Col, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { DividerForm } from '../../../../shared/components/DividerForm'
import { FormItemDatePicker, FormItemInput, FormItemSelect } from '../../../../shared/components/FormItemComponents'
import { RenderLabelForm } from '../../../../shared/components/RenderLabelForm'
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 1rem;
  flex-direction: column;
`

const OPTION_DAYS_TYPE = [
  {
    value: 0,
    text: ' ',
  },
  { value: 1, text: 'Business' },
  { value: 2, text: 'Calendar' },
]
const OPTION_DAYS: any = []
let i: number
for (i = 1; i <= 365; i++) {
  OPTION_DAYS.push({ value: i, text: i.toString() })
}

const FORM_INPUT_HEADER = [
  {
    label: 'Capacity',
    name: 'capacity',
    type: 'float',
  },
  {
    label: 'Remaining Capacity',
    name: 'remaining_capacity',
    type: 'float',
  },
  {
    label: 'Remaining HFRX Capacity',
    name: 'remaining_hfrx_capacity',
    type: 'float',
  },
]
const GROUP_FORM_CENTER = [
  {
    label: 'Weekly Redemption',
    name_left: 'weekly_redemption_percent',
    options: OPTION_DAYS_TYPE,
    name_input: 'weekly_redemption_days',
    name_right: 'weekly_redemption_day_type',
  },
  {
    label: 'Weekly HFRX Redemption',
    name_left: 'weekly_hfrx_redemption_percent',
    options: OPTION_DAYS_TYPE,
    name_input: 'weekly_hfrx_redemption_days',
    name_right: 'weekly_hfrx_redemption_day_type',
  },
  {
    label: 'Monthly Redemption',
    name_left: 'monthly_redemption_percent',
    options: OPTION_DAYS_TYPE,
    name_input: 'monthly_redemption_days',
    name_right: 'monthly_redemption_day_type',
  },
]
const FORM_INPUT_FOOTER = [
  {
    label: 'Management Fee',
    name: 'pof_info_management_fee',
    suffix: '%',
    normalize: 'float',
  },
  {
    label: 'Index Pricing',
    name: 'index_pricing',
  },
  {
    label: 'ISIN',
    name: 'isin',
    notConnected: true,
  },
  {
    label: 'Liquidity',
    name: 'hfr_am_info_liquidity',
  },
]
export const HFRInvestmentInfo: React.FC = () => {
  return (
    <Wrapper>
      <Row>
        {FORM_INPUT_HEADER.map((item) => (
          <Col span={7} xl={7} lg={7} xs={24} key={item.name}>
            <FormItemInput
              name={item.name}
              label={<RenderLabelForm value={item.label} />}
              labelCol={12}
              wrapperCol={12}
              typeField={item.type === 'float' || 'number' ? 'number' : undefined}
              normalize={item.type}
              prefix="$"
              suffix="(mil)"
            />
          </Col>
        ))}
        <Col span={3} xl={3} lg={24} xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type="primary">Recalculate</Button>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12} xl={12} lg={12} xs={24}>
          <FormItemInput
            name="need_to_fund"
            label={<RenderLabelForm value="Need To Fund" />}
            labelCol={12}
            wrapperCol={12}
            typeField="number"
            normalize="float"
            prefix="$"
            suffix="(mil)"
          />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12} xl={12} lg={12} xs={24}>
          <FormItemInput
            name="investment_info_management_fee"
            label={<RenderLabelForm value="Management Fee" />}
            labelCol={12}
            wrapperCol={12}
            normalize="float"
            suffix="%"
          />
        </Col>
      </Row>

      <Row gutter={8}>
        <Col span={12} xl={12} lg={12} xs={24}>
          <FormItemInput
            notConnected
            name="incentive_fee"
            label={<RenderLabelForm value="Incentive Fee" />}
            labelCol={12}
            wrapperCol={12}
            suffix="%"
          />
        </Col>
      </Row>

      {GROUP_FORM_CENTER.map((item) => {
        return (
          <Row key={item.name_left} gutter={8}>
            <Col span={12} xl={12} lg={12} xs={16}>
              <FormItemInput
                name={item.name_left}
                label={<RenderLabelForm value={item.label} />}
                labelCol={12}
                wrapperCol={12}
                typeField="number"
                normalize="float"
                suffix="%"
              />
            </Col>
            <Col span={8} xl={8} lg={8} xs={12}>
              <Row align="middle">
                <div style={{ display: 'flex', flex: 1, marginRight: '0.5rem' }}>
                  <FormItemInput name={item.name_input} label=" with" typeField="number" normalize="float" />
                </div>
                <FormItemSelect name={item.name_right} wrapperCol={24} options={item.options} />
                <span style={{ marginLeft: 5, marginBottom: 10 }}>days</span>
              </Row>
            </Col>
          </Row>
        )
      })}

      <Row gutter={8}>
        <Col span={12} xl={12} lg={12} xs={12}>
          <FormItemInput
            name="subscription_days"
            label={<RenderLabelForm value="Subscriptions" />}
            typeField="number"
            normalize="float"
            labelCol={12}
            wrapperCol={12}
          />
        </Col>
        <Col span={4} xl={4} lg={4} xs={8}>
          <Row align="middle">
            <FormItemSelect name="subscription_day_type" wrapperCol={24} options={OPTION_DAYS_TYPE} />
            <span style={{ marginLeft: 5, marginBottom: 10 }}>days</span>
          </Row>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12} xl={12} lg={12} xs={24}>
          <FormItemDatePicker
            name="most_recent_review"
            label={<RenderLabelForm value="Most Recent Review" />}
            labelCol={12}
            wrapperCol={12}
          />
        </Col>
      </Row>
      <DividerForm text="FOF Info" />
      <Row>
        {FORM_INPUT_FOOTER.map((item) => {
          return (
            <Col span={12} xl={12} lg={12} xs={24} key={item.name}>
              <div style={{ marginRight: 50 }}>
                <FormItemInput
                  notConnected={item.notConnected}
                  name={item.name}
                  label={<RenderLabelForm value={item.label} />}
                  labelCol={12}
                  wrapperCol={12}
                  normalize={item.normalize}
                  suffix={item.suffix}
                />
              </div>
            </Col>
          )
        })}
      </Row>
    </Wrapper>
  )
}
