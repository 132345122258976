import React from 'react'
import Modal from 'antd/lib/modal/Modal'
import { Form, Input, notification } from 'antd'
import APIService from '../../shared/api'
import { AnalysisType } from '../../shared/api/models/Analysis'

type Props = {
  selectedGroup?: AnalysisType
  isOpen: boolean
  onRefetch: () => void
  onRequestClose: () => void
}

const AddFundModal: React.FC<Props> = ({ selectedGroup, isOpen, onRefetch, onRequestClose }) => {
  const [submitting, setSubmitting] = React.useState(false)
  const [fundIds, setFundIds] = React.useState<string>()
  const handleSubmit = () => {
    if (!selectedGroup) {
      notification.warn({ message: 'Please select a group to add funds to!' })
      return
    }
    if (!fundIds || !fundIds.trim()) {
      return
    }
    const updatedFundIds = fundIds.trim().split('\n')
    setSubmitting(true)
    APIService.analysisService
      .updateGroup({ groupCode: selectedGroup.group_code, fundIds: updatedFundIds })
      .then(() => {
        notification.success({ message: `Add ${updatedFundIds.length} funds to group ${selectedGroup.group_name}!` })
        onRequestClose()
        setFundIds(undefined)
        onRefetch()
      })
      .catch((err) => {
        console.error(err)
        notification.error({ message: 'Failed to add funds to group!' })
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <Modal
      width={700}
      visible={isOpen}
      onCancel={onRequestClose}
      title={'Add Fund'}
      onOk={handleSubmit}
      confirmLoading={submitting}
    >
      <Form layout="vertical">
        <Form.Item label="Fund IDs" required rules={[{ required: true, message: 'Please enter Fund IDs' }]}>
          <Input.TextArea
            placeholder="Enter or copy/paste single or multiple Fund IDs separated by new line"
            value={fundIds}
            onChange={(e) => setFundIds(e.target.value)}
            rows={10}
          />
          <span>Funds which are already inside the group will be ignored</span>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddFundModal
