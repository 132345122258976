import { Collapse } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { NextBackButton } from '../../../../../shared/components/NextBackButton'
import { ChecklistMain } from './ChecklistMain'
import { LegalDocuments } from './LegalDocuments'
import { OldBackgroundChecks } from './OldBackgroundChecks'

const Flex = styled.div`
  display: flex;
  flex: 1;
`
const { Panel } = Collapse
const StyledCollapse = styled(Collapse)`
  margin: 1rem;
  .ant-collapse-content-box {
    padding: 0;
  }
`
type Props = {}
export const Checklist: React.FC<Props> = () => {
  return (
    <Flex style={{ flexDirection: 'column' }}>
      <StyledCollapse style={{ margin: '1rem' }} defaultActiveKey="main">
        <Panel header="Main" key="main">
          <ChecklistMain />
        </Panel>
        <Panel header="Legal Documents" key="legalDocuments">
          <LegalDocuments />
        </Panel>
        <Panel header="Old Background Checks" key="oldBackgroundChecks">
          <OldBackgroundChecks />
        </Panel>
      </StyledCollapse>
      <NextBackButton />
    </Flex>
  )
}
