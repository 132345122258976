import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserProfileDetails } from '../shared/api/models/ResponsesTypes'
import APIService from '../shared/api'
import { notification } from 'antd'
import { AppThunk } from '../app/store'
import { logout } from './authSlice'

interface UserState {
  isLoading: boolean
  user: UserProfileDetails | null
  error: string | null
}

const initialState: UserState = {
  isLoading: true,
  user: null,
  error: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetchUserStart: (state) => {
      console.log('fetchUserStart')
      state.isLoading = true
    },
    fetchUserSuccess: (state, { payload }: PayloadAction<UserProfileDetails>) => {
      console.log('fetchUserSuccess')
      state.user = payload
      state.isLoading = false
      state.error = null
    },
    fetchUserFailure: (state, action) => {
      console.log('fetchUserFailure')
      state.isLoading = false
      state.error = action.payload
    },
  },
})

const { reducer, actions } = userSlice

export const { fetchUserFailure, fetchUserStart, fetchUserSuccess } = actions

export default reducer

export const fetchUser = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchUserStart())
    const user = await APIService.authService.fetchUserProfile()
    if (!user.data.has_manager_link_access) {
      notification.warn({
        message: 'Permission Denied',
        description: 'You do not have permission to access Manager Link',
      })
      dispatch(logout())
    } else {
      dispatch(fetchUserSuccess(user.data))
    }
  } catch (err) {
    dispatch(fetchUserFailure(err.toString()))
  }
}
