import { Button, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import styled from 'styled-components'
import EditableFormRow from './EditableTable/EditableFormRow'
import EditableCell from './EditableTable/EditableCell'
import { InputProps } from 'antd/lib/input'
import { Link } from 'react-router-dom'
import APIService from '../api'

const Flex = styled.div`
  flex: 1;
  display: flex;
`
const StyledHeader = styled.div`
  display: flex;
  padding: 1rem 1.5rem;
  justify-content: space-between;
  align-items: center;
`

export type FundFamilyType = {
  firm_id: string
  firm_name: string
  fund_id: string
  fund_name: string
  fund_family_code?: string | null
  fund_family_id?: string | null
  region_inv_focus?: string | null
  region_inv_focus_country?: string | null
  strategy_code?: string | null
  sub_strategy_code?: string | null
}

type Props = {
  variant?: 'firm' | 'fund'
  editable?: boolean
  isLoading?: boolean
  fundFamilyData: FundFamilyType[]
  onRefecth?: () => void
}

export const FundFamilyTable: React.FC<Props> = ({
  variant = 'fund',
  editable = false,
  isLoading,
  fundFamilyData,
  onRefecth,
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [isEdited, setIsEdited] = React.useState(false)
  const [editedData, setEditedData] = React.useState<FundFamilyType[]>([])
  const [dirtyData, setDirtyData] = React.useState<Record<string, boolean>>({})
  // refetch data
  React.useEffect(() => {
    if (fundFamilyData) {
      setEditedData(fundFamilyData)
      setIsEdited(false)
      setDirtyData({})
    }
  }, [fundFamilyData])
  //
  const handleSubmit = async () => {
    if (!isEdited) {
      return
    }
    setIsSubmitting(true)
    const changedData = editedData.reduce((prev, cur, index) => {
      if (cur.fund_family_code === null || cur.fund_family_code === undefined) {
        return prev
      }
      if (fundFamilyData[index].fund_family_code !== cur.fund_family_code) {
        return [...prev, cur]
      }
      return prev
    }, [] as FundFamilyType[])

    await Promise.all(
      changedData.map((d) => {
        return APIService.fundDetailService.updateFundBasicInfo(d.fund_id, {
          fund_family_code: d.fund_family_code,
        })
      }),
    )
    setIsSubmitting(false)
    setIsEdited(false)
    setDirtyData({})
    onRefecth && onRefecth()
  }

  ////////////////////////////////////////
  // columns
  ////////////////////////////////////////
  const columns: ColumnProps<FundFamilyType>[] = [
    {
      title: 'Fund Family ID',
      dataIndex: 'fund_family_id',
      key: 'fund_family_id',
      width: '12%',
      sorter: (a, b) => {
        return (a.fund_family_id || '').localeCompare(b.fund_family_id || '')
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Fund ID',
      dataIndex: 'fund_id',
      key: 'fund_id',
      width: '12%',
      sorter: (a, b) => {
        return (a.fund_family_id || '').localeCompare(b.fund_family_id || '')
      },
      sortDirections: ['descend', 'ascend'],
    },
    variant === 'firm'
      ? {
          title: 'Fund Name',
          dataIndex: 'fund_name',
          key: 'fund_name',
          width: '12%',
          sorter: (a, b) => {
            return (a.fund_name || '').localeCompare(b.fund_name || '')
          },
          sortDirections: ['descend', 'ascend'],
          render: (value, record) => {
            return <Link to={`/funds/${record.fund_id}/fund-family`}>{value}</Link>
          },
        }
      : {
          title: 'Fund Name',
          dataIndex: 'fund_name',
          key: 'fund_name',
          width: '12%',
          sorter: (a, b) => {
            return (a.fund_name || '').localeCompare(b.fund_name || '')
          },
          sortDirections: ['descend', 'ascend'],
          render: (value, record, index) => {
            if (index === 0) {
              return value
            }
            return <Link to={`/funds/${record.fund_id}/fund-family`}>{value}</Link>
          },
        },
    {
      title: 'Strategy',
      dataIndex: 'strategy_code',
      key: 'strategy_code',
      width: '12%',
      sorter: (a, b) => {
        return (a.strategy_code || '').localeCompare(b.strategy_code || '')
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Sub Strategy',
      dataIndex: 'sub_strategy_code',
      key: 'sub_strategy_code',
      width: '12%',
      sorter: (a, b) => {
        return (a.sub_strategy_code || '').localeCompare(b.sub_strategy_code || '')
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Regional Investment Focus',
      dataIndex: 'region_inv_focus',
      key: 'region_inv_focus',
      width: '12%',
      sorter: (a, b) => {
        return (a.region_inv_focus || '').localeCompare(b.region_inv_focus || '')
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Regional Investment Focus Sub-Region',
      dataIndex: 'region_inv_focus_country',
      key: 'region_inv_focus_country',
      width: '12%',
      sorter: (a, b) => {
        return (a.region_inv_focus_country || '').localeCompare(b.region_inv_focus_country || '')
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Fund Family Code',
      dataIndex: 'fund_family_code',
      key: 'fund_family_code',
      width: '12%',
      sorter: (a, b) => {
        return (a.fund_family_code || '').localeCompare(b.fund_family_code || '')
      },
      sortDirections: ['descend', 'ascend'],
      // @ts-ignore
      onCell: (record) => ({
        inputProps: { maxLength: 4 } as InputProps,
        record,
        editable,
        dataIndex: 'fund_family_code',
        isDirty: !!dirtyData[record.fund_id],
        editingRow: record,
        setEditingRow: (newRecord: FundFamilyType) => {
          // diff value
          setIsEdited(true)
          setDirtyData((prev) => {
            const newValue = { ...prev }
            newValue[newRecord.fund_id] = true
            return newValue
          })
          setEditedData((prev) => {
            const findIndex = prev.findIndex((d) => d.fund_id === newRecord.fund_id)
            if (findIndex < 0) {
              return prev
            }
            const newData = [...prev]
            newData[findIndex] = newRecord
            return newData
          })
        },
      }),
    },
  ]
  ////////////////////////////////////////

  const components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell,
    },
  }
  return (
    <Flex style={{ flexDirection: 'column' }}>
      <StyledHeader>
        <div style={{ fontSize: 14, fontWeight: 'bold' }}>Fund Family</div>
        <div>
          {editable && (
            <Button
              type="primary"
              style={{ marginRight: '0.5rem' }}
              disabled={!isEdited}
              onClick={handleSubmit}
              loading={isSubmitting}
            >
              Update
            </Button>
          )}
          {onRefecth && (
            <Button
              type="primary"
              loading={isLoading}
              onClick={() => {
                setIsEdited(false)
                onRefecth()
              }}
            >
              Refresh
            </Button>
          )}
        </div>
      </StyledHeader>
      <Flex>
        <Table
          rowKey="fund_id"
          rowClassName={() => 'editable-row'}
          dataSource={editedData}
          columns={columns}
          components={components}
          style={{ flex: 1 }}
          pagination={fundFamilyData && fundFamilyData.length > 10 ? undefined : false}
          loading={isLoading}
        />
      </Flex>
    </Flex>
  )
}
