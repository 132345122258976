import numeral from 'numeral'
import moment from 'moment'
class GenUtil {
  static clearNamespace({ namespace = '' }) {
    if (namespace) {
      for (const key in localStorage) {
        if (key.split('_')[0] === namespace) {
          localStorage.removeItem(key)
        }
      }
    }
  }

  static digitWithExactPrecision(x: string | number, precision = 2): string {
    return Number(x).toFixed(precision)
  }

  static digitWithMaxPrecision(x: string | number, precision = 2): number {
    return Number(Number(x).toFixed(precision))
  }

  static getformattedNumber(x: string | number): string {
    return numeral(x).format('0,0[.]00')
  }
  // returns date in YYYY-MM format
  static getFormattedYearAndMonth(date: string | moment.Moment, format: string | undefined = undefined): string {
    return format ? moment(date, format).format('YYYY-MM') : moment(date).format('YYYY-MM')
  }
  // returns date in YYYY-MM-DD format
  static getFormattedYearMonthAndDay(date: string | moment.Moment, format: string | undefined = undefined): string {
    return format ? moment(date, format).format('YYYY-MM-DD') : moment(date).format('YYYY-MM-DD')
  }
}

export default GenUtil
