import React from 'react'
import { ListSearchExFundType } from '../../SearchExFundLabel'

const defaultValue = {
  boldText: '',
  filterArr: [] as ListSearchExFundType[],
  isFiltering: false,
}
type Props = {
  text: string
  filterArr: ListSearchExFundType[]
}
export const ExFundDetailLabelSearchContext = React.createContext(defaultValue)
export const ExFundDetailLabelSearchProvider: React.FC<Props> = ({ children, text, filterArr }) => {
  const isFiltering = text === '' ? false : true
  const value = {
    boldText: text,
    filterArr,
    isFiltering,
  }
  return <ExFundDetailLabelSearchContext.Provider value={value}>{children}</ExFundDetailLabelSearchContext.Provider>
}
