import { Col, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'
import { FundDetailDataContext, PERFORMANCE_REQUEST_ENUM } from '../../../../shared/api/context/FundDetailDataContext'
import { FundDetailDropdownContext } from '../../../../shared/api/context/FundDetailDropdownContext'
import DataSheet, { DataSheetType } from '../../../../shared/components/DataSheet'
import { DividerForm } from '../../../../shared/components/DividerForm'
import { FormItemSelect } from '../../../../shared/components/FormItemComponents'
import { RenderLabelExFund } from '../../../../shared/components/RenderLabelExFund'
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
type Props = {
  form: FormInstance
}
export const ExPerformanceRateOfReturn: React.FC<Props> = ({ form }) => {
  const {
    dataROR,
    dataRORLoading,
    setRORDataChange,
    getPerformanceRequest,
    rorDataChange,
    dataFundDetail,
  } = React.useContext(FundDetailDataContext)
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)

  React.useEffect(() => {
    getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.ROR)
  }, [getPerformanceRequest])

  const isFundActive = form.getFieldValue('fund_status_code') === '2'

  return (
    <Wrapper>
      <Row>
        <Col span={8} xl={12} md={12} xs={24}>
          <FormItemSelect
            label={<RenderLabelExFund value="Reporting Style" />}
            name="reporting_style"
            labelCol={8}
            wrapperCol={16}
            disabled={isFundActive}
            options={
              fundDataDropdown?.reporting_style && fundDataDropdown.reporting_style.length > 0
                ? fundDataDropdown.reporting_style.map((item) => ({
                    value: item,
                    text: item,
                  }))
                : []
            }
            rules={[{ required: true, message: 'This field is required!' }]}
          />
        </Col>
        <Col span={8} xl={12} md={12} xs={24}>
          <FormItemSelect
            label={<RenderLabelExFund value="Denom" />}
            name="denomination"
            labelCol={8}
            wrapperCol={16}
            disabled={isFundActive}
            options={
              fundDataDropdown?.denomination && fundDataDropdown.denomination.length > 0
                ? fundDataDropdown.denomination.map((item) => ({
                    value: item.code_code,
                    text: item.code_description,
                  }))
                : []
            }
            rules={[{ required: true, message: 'This field is required!' }]}
          />
        </Col>
      </Row>
      <DividerForm />

      <DataSheet
        currencyValue={dataFundDetail?.denomination ? dataFundDetail.denomination : undefined}
        // isReadOnly={fundStatusField ? true : undefined}
        minDate={dataFundDetail?.inception_date ? dataFundDetail.inception_date : undefined}
        type={DataSheetType.ROR}
        draft={rorDataChange.values}
        initialData={dataROR}
        loading={dataRORLoading}
        canAdd
        setRORDataChange={setRORDataChange}
        isExternal
      />
      {isFundActive && (
        <Row style={{ marginTop: 8 }}>
          <div style={{ fontStyle: 'italic' }}>
            <b>Note:</b> Contact HFR at <a href="mailto:performance@hfr.com">performance@hfr.com</a> if any questions.
          </div>
        </Row>
      )}
    </Wrapper>
  )
}
