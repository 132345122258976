import React from 'react'
import { FormItemTextArea } from '../../../../shared/components/FormItemComponents'

export const FirmOwnershipDescription: React.FC = () => {
  return (
    <div style={{ padding: '0.5rem 3rem', flex: 1 }}>
      <FormItemTextArea row={15} name="ownership_description" maxLength={255} />
    </div>
  )
}
