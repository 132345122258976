import { Col, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'
import { FundDetailDropdownContext } from '../../../shared/api/context/FundDetailDropdownContext'
import { ExFundFieldChange, FundDetails } from '../../../shared/api/models/FundDetails'
import { FormItemInput, FormItemSelect } from '../../../shared/components/FormItemComponents'
import { RenderLabelExNewFund } from '../../../shared/components/RenderLabelExNewFund'
import { LeftNavExNewFundEnum } from '../../../shared/SearchExNewFundLabel'
import { NextBackNewFundTab } from './NextBackExNewFundTab'
const Flex = styled.div`
  display: flex;
  flex: 1;
  margin-top: 1rem;
  padding: 0 2rem;
  flex-direction: column;
`
type Props = {
  setStepTab: (value: LeftNavExNewFundEnum) => void
  setActiveTab: (value: LeftNavExNewFundEnum) => void
  activeTab: LeftNavExNewFundEnum
  stepTab: LeftNavExNewFundEnum
  form: FormInstance
  setDataFundDetailChange: (action: React.SetStateAction<Partial<FundDetails & ExFundFieldChange>>) => void
}
const ARRAY_FIELD_VALIDATE: string[] = [
  'contact_name',
  'contact_company',
  'contact_country',
  'contact_address1',
  'contact_city',
  'contact_postal_code',
  'contact_phone',
  'contact_email',
]
export const ExNewFundContact: React.FC<Props> = ({
  form,
  setStepTab,
  setDataFundDetailChange,
  setActiveTab,
  stepTab,
  activeTab,
}) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)
  const LIST_FORM = [
    {
      name: 'contact_name',
      label: 'Name',
      maxlength: 60,
      rules: [{ required: true, message: 'Name is required!' }],
    },
    {
      name: 'contact_company',
      label: 'Company',
      maxlength: 60,
      rules: [{ required: true, message: 'Company Name is required!' }],
    },
    {
      name: 'contact_country',
      label: 'Country',
      options:
        fundDataDropdown?.contact_country && fundDataDropdown?.contact_country.length > 0
          ? fundDataDropdown.contact_country.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
      rules: [{ required: true, message: 'Country Name is required!' }],
    },
    {
      name: 'contact_address1',
      label: 'Address',
      maxlength: 60,
      rules: [{ required: true, message: 'Address is required!' }],
    },
    {
      name: 'contact_address2',
      label: 'Address 2',
      maxlength: 60,
    },

    {
      name: 'contact_city',
      label: 'City',
      maxlength: 30,
      rules: [{ required: true, message: 'City Name is required!' }],
    },
    {
      name: 'contact_state',
      label: 'State',
      options:
        fundDataDropdown?.contact_state && fundDataDropdown?.contact_state.length > 0
          ? fundDataDropdown.contact_state.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
    },
    {
      name: 'contact_postal_code',
      label: 'Postal Code',
      maxlength: 10,
      rules: [{ required: true, message: 'Postal Code is required!' }],
    },
    {
      name: 'contact_phone',
      label: 'Phone',
      maxlength: 30,
      rules: [{ required: true, message: 'Phone is required!' }],
    },
    {
      name: 'contact_fax',
      label: 'Fax',
      maxlength: 30,
    },
    {
      name: 'contact_email',
      label: 'Email',
      maxlength: 255,
      rules: [{ required: true, message: 'Email is required!' }],
    },
    {
      name: 'contact_web',
      label: 'Website',
      maxlength: 60,
    },
  ]

  const contactCountry = form.getFieldValue('contact_country')

  React.useEffect(() => {
    if ((!contactCountry || contactCountry !== '205') && form.getFieldValue('contact_state')) {
      form.setFieldsValue({ contact_state: null })
      setDataFundDetailChange((prev) => {
        const { contact_state, ...rest } = prev
        return contact_state ? rest : prev
      })
    }
  }, [contactCountry, form, setDataFundDetailChange])

  return (
    <Flex>
      <Row>
        {LIST_FORM.map((item) => {
          return (
            <Col span={12} xl={12} md={16} xs={24} sm={24} key={item.name}>
              {item.options ? (
                <FormItemSelect
                  name={item.name}
                  label={<RenderLabelExNewFund value={item.label} />}
                  options={item.options}
                  wrapperCol={16}
                  rules={item.rules}
                  disabled={item.name === 'contact_state' && contactCountry !== '205'}
                />
              ) : (
                <FormItemInput
                  name={item.name}
                  label={<RenderLabelExNewFund value={item.label} />}
                  placeholder={item.label}
                  wrapperCol={16}
                  maxLength={item.maxlength ? item.maxlength : undefined}
                  rules={item.rules}
                />
              )}
            </Col>
          )
        })}
      </Row>
      <NextBackNewFundTab
        setStepTab={setStepTab}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        stepTab={stepTab}
        ArrayValidateField={ARRAY_FIELD_VALIDATE}
        form={form}
      />
    </Flex>
  )
}
