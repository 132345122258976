import { NumberOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form, Input, notification } from 'antd'
import { Store } from 'antd/lib/form/interface'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { RootState } from '../../app/rootReducer'
import APIService from '../../shared/api'
import { loginFailure, saveToken } from '../../slice/authSlice'

type Props = {
  email: string
  password: string
  rememberMe: boolean
}

const CheckCodeLogin: React.FC<Props> = ({ email, password, rememberMe }) => {
  const history = useHistory()
  const [loading, setLoading] = React.useState(false)
  const dispatch = useDispatch()
  const auth = useSelector((state: RootState) => state.auth)
  const { user } = useSelector((state: RootState) => state.user)
  const { isLoading } = auth

  React.useEffect(() => {
    const { error: authError, accessToken } = auth
    if (user && !authError && accessToken) {
      notification.success({ message: 'Welcome!' })
      history.push('/')
      setLoading(false)
    }
    if (authError) {
      setLoading(false)
    }
  }, [auth, history, user])

  const handleSubmit = async (values: Store) => {
    if (!email) {
      return
    }

    setLoading(true)
    const code = values.code ? values.code.trim() : ''
    try {
      const loginRes = await APIService.authService.login(email, password, code, rememberMe)
      dispatch(saveToken(loginRes.data.token))
    } catch (err) {
      dispatch(loginFailure(err.response.status))
      notification.error({ message: err.response.data.message })
    }
  }

  return (
    <Form onFinish={handleSubmit} layout="horizontal">
      <span>Enter Verification Code:</span>
      <Form.Item>
        <Input
          name={'email'}
          value={email}
          disabled
          prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="email"
        />
      </Form.Item>
      <Form.Item name="code" rules={[{ required: true, message: 'Verification Code is required' }]}>
        <Input
          formNoValidate={true}
          name="code"
          prefix={<NumberOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Verification Code"
        />
      </Form.Item>
      <Button loading={isLoading} type="primary" htmlType="submit" disabled={loading} style={{ flex: 2 }}>
        Submit
      </Button>
      <Button type="link" style={{ flex: 1 }} onClick={() => history.push('/auth/login')}>
        Return to Login
      </Button>
    </Form>
  )
}

export default CheckCodeLogin
