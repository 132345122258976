export const FundKeysMapping: { [key: string]: string } = {
  accepts_mgd_accts: 'Accepts Mng. Accts?',
  accepts_new_investment: 'Accepting New Investment',
  add_invest: 'Add Investment',
  added_to_db: 'Added To DB',
  administrative_fee: 'Admin. Fee %',
  administrator: 'Administrator',
  aifmd_compliant: 'AIFMD',
  allocation: 'Allocation',
  app_esgintegration: 'ESG Integration',
  app_impactinvest: 'Impact Investing',
  app_negativescreen: 'Negative/exclusionary screening',
  app_positivescreen: 'Positive / Best-in-class screening',
  app_sustaininvest: 'Sustainability theme Investing',
  auditor_name: 'Auditor',
  balance_redemption_return_days: 'Redemption Balance Returned within (days)',
  banking_agent: 'Banking Agent',
  benchmark1: 'Benchmark 1',
  benchmark2: 'Benchmark 2',
  benchmark3: 'Benchmark 3',
  bloomberg_ticker: 'Bloomberg Ticker',
  capacity: 'Capacity',
  constituent_count: '# of Constituents',
  consultant: 'Consultant',
  contact_status: 'Contact Status',
  custodian: 'Custodian',
  data_bloomberg: 'Bloomberg',
  data_factset: 'Factset',
  data_internal: 'ProprietaryOrInternal',
  data_msci: 'MSCI',
  data_other: 'Other',
  data_source: 'Data Source',
  data_sustainalytics: 'Sustainalytics',
  dead_fund: 'HFR Dead Funds Database',
  denomination: 'Denom.',
  domicile: 'Domicile',
  env_cleantech: 'Clean Technology',
  env_climatechange: 'Climate Change / Carbon',
  env_description: 'Environmental Description',
  env_greenbuilding: 'Green Building / Smart Growth',
  env_pollutiontaxes: 'Pollution / Toxic',
  env_sustainable: 'Sustainable Nature Resoures / Argiculture',
  env_waterconservation: 'Water Use Conservation',
  equity_redemption: 'Equity Redemptions',
  equity_subscription: 'Equity Subscriptions',
  firm_assets: 'Firm Assets',
  firm_assets_currency: 'Firm Assets Currency',
  firm_assets_date: 'Firm Asset Date',
  firm_id: 'Firm ID',
  firm_name: 'Firm Name',
  fund_assets: 'Fund Assets',
  fund_assets_date: 'Fund Asset Date',
  firm_denomination: 'Firm Denomination',
  fund_contact: 'Fund Contact',
  fund_id: 'Fund ID',
  fund_name: 'Fund Name',
  fund_status_code: 'Fund Status',
  gate_fund: 'Gate: Fund-Level',
  gate_investor: 'Gate: Investor-Level',
  gov_anticorruption: 'Anti Corruption Policies',
  gov_boarddiversity: 'Board Diversity',
  gov_boardindependence: 'Board Independence',
  gov_corppolcontrib: 'Corporate Political Contributions',
  gov_description: 'Governance Description',
  gov_executivecomp: 'Executive Compensation',
  hfr_am_info_liquidity: 'Liquidity',
  hfr_am_info_notes: 'HFR AM Info Notes',
  hfr_analyst: 'HFR Analyst',
  hfr_database: 'HFR Database',
  hfr_indices: 'HFR Indices',
  hfr_la_fund: 'HFR LA Fund',
  hfr_risk_premia: 'HFR Risk Premia AM Database',
  hfrb_fund: 'HFRB Fund',
  hfri500_eligibility: 'HFRI 500 Eligibility',
  hfri500_exclusion_reason: 'HFRI 500 Exclude Reason',
  hfri500_reviewed: 'HFRI 500 Reviewed',
  hfri_500_fund: 'HFRI 500 Fund',
  hfri_fund: 'HFRI Fund',
  hfri_i_fund: 'HFRI I Fund',
  hfri_institutional_fund: 'HFRI Institutional Fund',
  hfri_pending_fund: 'HFRI Pending Fund',
  hfri_pending_removal: 'HFRI Pending Removal',
  hfri_woman_access_fund: 'HFRI Woman Access',
  hfrrparity_fund: 'HFRRParity',
  hfrrparity_i_fund: 'HFRRParity-I',
  hfru_fund: 'HFRU Fund',
  hfrx_fund: 'HFRX Fund',
  hurdle_rate: 'Hurdle Rate',
  incentive_fee: 'Incentive Fee',
  inception_date: 'Inception',
  index_pricing: 'Index Pricing',
  investor_type: 'Accred. Investor Type',
  is_annual_performance_audit: 'Annual Performance Audit at',
  is_aum_distributed: 'Is AUM Distributed ?',
  is_nav_distributed: 'Is NAV Distributed ?',
  is_emerging_markets_fund: 'Is Emerging Markets',
  is_feeder_fund: 'Feeder Fund',
  is_fund_esg: 'Is Fund ESG?',
  is_hfr_funded: 'Is HFR Fund',
  is_high_watermark: 'High Watermark',
  is_hurdle_rate: 'Is Hurdle Rate',
  is_liquidated: 'Is Liquidated',
  is_lockup: 'Is Lockup',
  is_minority_fund: 'Is Minority Managed',
  is_offshore: 'Is Offshore',
  isin: 'ISIN',
  last_audit_date: 'Last Audit Date',
  legal_adviser: 'Legal Adviser',
  liquid_alt_product: 'Liquid Alt Product',
  liquid_alt_region: 'Liquid Alt Regions',
  liquid_alt_type: 'Liquid Alt Type',
  liquidated_at: 'Liquidated at',
  liquidity: 'Liquidity',
  lockup_period: 'Lockup Period',
  lockup_type: 'Lockup Type',
  max_12b1_fee: 'Max 12B-1 Fee %',
  max_deferred_fee: 'Max Deferred Sale Load Fee %',
  max_front_fee: 'Max Front-End Load Fee %',
  mgmt_fee: 'Mgmt. Fee %',
  min_invest: 'Min. Investment',
  min_invest_indv: 'Ind. Account Min.',
  minority_manage_level: 'Minority Manage Level',
  minority_manage_type: 'Minority Manage Type',
  monthly_redemption_day_type: 'Monthly Redemption Day Type',
  monthly_redemption_days: 'Monthly Redemption Days',
  monthly_redemption_percent: 'Monthly Redemption Percent',
  most_recent_review: 'Most Recent Review',
  need_to_fund: 'Need To Fund',
  notes: 'Note',
  om_date: 'OM Date',
  other_fees: 'Other Fee %',
  performance_reporting: 'Perf. Rept. to Investors',
  placement_agent: 'Placement Agent',
  prime_broker: 'Prime Broker',
  principals: 'Principals',
  product_class: 'Product Class',
  redemption_advance_notice: 'Redemption Advance Notice (days)',
  redemption_fee: 'Rdm. Fee %',
  redemption_fee_months: 'If Less Than',
  redemption_return_days: 'Redemption Return Days',
  redemption_return_percent: '% Redemption Returned within (days)',
  region_inv_focus: 'Regional Investment Focus',
  region_inv_focus_country: 'Country',
  registration_code: 'Registration',
  remaining_capacity: 'Remaining Capacity',
  remaining_hfrx_capacity: 'Remaining HFRX Capacity',
  reporting_frequency: 'Req. Freq',
  reporting_style: 'Reporting Style',
  run_sep_mgd_accts: 'Run Sep Mng Accts?',
  sales_fee: 'Sales Fee %',
  sec_pfid_number: 'SEC-PFID',
  sma_monthlyliquid_20mm: 'SMA Monthly $20MM',
  sma_monthlyliquid_50mm: 'SMA Monthly $50MM',
  sma_weeklyliquid_10mm: 'SMA Weekly $10MM',
  soc_avoidtobacco: 'Avoidance Of Tobacco or other Harmful Products',
  soc_communitydev: 'Community Development',
  soc_description: 'Social Description',
  soc_diversityantibias: 'Diversity Anti Bias',
  soc_humanrights: 'Human',
  soc_laborrelations: 'Labor Relations',
  soc_workplacebenefits: 'Workplace Benefits',
  soc_workplacesafety: 'Workplace Safety',
  strategy_code: 'Legacy Strategy',
  strategy_code_new: 'Strategy',
  strategy_description: 'Strategy Description',
  structure: 'Structure',
  style: 'Style',
  sub_strategy_code: 'Legacy Sub-Strategy',
  sub_strategy_code_new: 'Sub-Strategy',
  sub_strategy_desc: 'Sub-Strategy Desc',
  subscription_cutoff_time: 'Subscription Cutoff by',
  subscription_cutoff_timezone: 'Subscription Cutoff Timezone',
  subscription_day_type: 'Subscription Day Type',
  subscription_days: 'Subscriptions',
  subscription_settlement_days: 'Subscription Settment (days)',
  subscription_notice: 'Subscription Notice',
  subscription_settlement: 'Subscription Settlement',
  team_engagepcm: 'Engage PCM ?',
  ucitsiii: 'UCITSIII',
  use_of_leverage: 'Use of Leverage',
  use_of_leverage_type: 'Use of Leverage Type',
  weekly_hfrx_redemption_day_type: 'Weekly HFRX Redemption Day Type',
  weekly_hfrx_redemption_days: 'Weekly HFRX Redemption Days',
  weekly_hfrx_redemption_percent: 'Weekly HFRX Redemption',
  weekly_redemption_days: 'Weekly Redemption Days',
  weekly_redemption_percent: 'Weekly Redemption',
  weekly_redemption_day_type: 'Weekly Redemption Day Type',

  woman_managed: 'Woman Managed',
  added_to_hfri: 'Performance Added to HFRI as of',
  removed_from_hfri: 'Removed from HFRI after',

  subscription_advance_notice: 'Subscription Advanced Notice (days)',
  contact_name: 'Contact Name',
  contact_company: 'Contact Company',
  contact_country: 'Contact Country',
  contact_address1: 'Contact Address 1',
  contact_address2: 'Contact Address 2',
  contact_city: 'Contact City',
  contact_state: 'Contact State',
  contact_postal_code: 'Contact Postal Code',
  contact_phone: 'Contact Phone',
  contact_fax: 'Contact Fax',
  contact_email: 'Contact Email',
  contact_web: 'Contact Web',
  offshore_id: 'Offshore ID',
}
