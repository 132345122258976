import { TableOutlined } from '@ant-design/icons'
import { Button, Row } from 'antd'
import React from 'react'
import FirmSearchColumnBuilder from './ColumnBuilder/FirmSearchColumnBuilder'

type Props = {}

const FirmTableHeader: React.FC<Props> = () => {
  const [showColumnBuilder, setShowColumnBuilder] = React.useState(false)

  return (
    <Row justify="end">
      <Button type="default" onClick={() => setShowColumnBuilder(true)} style={{ marginLeft: 6 }}>
        <TableOutlined />
        Columns
      </Button>
      {showColumnBuilder && <FirmSearchColumnBuilder isOpen={showColumnBuilder} setOpen={setShowColumnBuilder} />}
    </Row>
  )
}

export default FirmTableHeader
