import { Col, Row, Spin } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../../../shared/api'
import { FundDetailDataContext } from '../../../../shared/api/context/FundDetailDataContext'
import { FundDetailDropdownContext } from '../../../../shared/api/context/FundDetailDropdownContext'
import { DividerForm } from '../../../../shared/components/DividerForm'
import { FormItemInput, FormItemSelect } from '../../../../shared/components/FormItemComponents'
import { NextBackButton } from '../../../../shared/components/NextBackButton'
import { RenderLabelForm } from '../../../../shared/components/RenderLabelForm'
import { useServiceState } from '../../../../shared/hooks/useServiceState'
import { IndexManagerType } from '../../../../shared/api/models/IndexManager'
import { FundIndexEligibilityDetailsForm } from './FundIndexEligibilityDetailsForm'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem;
`

type Props = { form: FormInstance; dataListIndices?: IndexManagerType[]; loadingListIndices?: boolean }
export const Indices: React.FC<Props> = ({ form, dataListIndices, loadingListIndices }) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)

  const { setDataFundDetailChange, dataFundDetailChange } = React.useContext(FundDetailDataContext)

  const selectedStrategy = form.getFieldValue('strategy_code')
  const selectedAsiStrategy = form.getFieldValue('asi_strategy')

  const { invoke: getLegacyStrategy, data: dataLegacy } = useServiceState(
    APIService.fundDetailService.fetchLegacyStrategy,
  )

  const { invoke: getAsiStrategy, data: dataAsi } = useServiceState(APIService.fundDetailService.fetchAsiStrategy)

  React.useEffect(() => {
    getLegacyStrategy()
    getAsiStrategy()
  }, [getAsiStrategy, getLegacyStrategy])

  const optionSubstrategy = React.useMemo(() => {
    return selectedStrategy
      ? dataLegacy && dataLegacy.sub_strategies && dataLegacy.sub_strategies.length > 0
        ? dataLegacy.sub_strategies
            .filter((i) => i.strategy_code === form.getFieldValue('strategy_code'))
            .map((item) => ({
              value: item.sub_strategy_code,
              text: item.sub_strategy_name,
            }))
        : []
      : []
  }, [dataLegacy, form, selectedStrategy])

  const optionAsiSubstrategy = React.useMemo(() => {
    console.log({ selectedAsiStrategy, a: dataAsi && dataAsi.asi_sub_strategies })
    return selectedAsiStrategy
      ? dataAsi && dataAsi.asi_sub_strategies && dataAsi.asi_sub_strategies.length > 0
        ? dataAsi.asi_sub_strategies
            .filter((i) => i.asi_strategy_id === selectedAsiStrategy)
            .map((item) => ({
              value: item.id,
              text: item.name,
            }))
        : []
      : []
  }, [dataAsi, selectedAsiStrategy])

  const indexElgibleManager = React.useMemo(() => {
    if (!dataListIndices || !dataListIndices.length) {
      return []
    }

    return dataListIndices.filter((manager) => {
      return !!manager.index_eligibility
    })
  }, [dataListIndices])

  // effect to check legacy subStrategy based on current strategy
  React.useEffect(() => {
    if (dataFundDetailChange?.current && Object.keys(dataFundDetailChange?.current).includes('strategy_code')) {
      if (
        (selectedStrategy &&
          form.getFieldValue('sub_strategy_code') &&
          optionSubstrategy.length > 0 &&
          optionSubstrategy.findIndex((item) => item.value === form.getFieldValue('sub_strategy_code')) === -1) ||
        (!selectedStrategy && form.getFieldValue('sub_strategy_code'))
      ) {
        form.setFieldsValue({ sub_strategy_code: null })
        setDataFundDetailChange((prev) => ({ ...prev, sub_strategy_code: null }))
      }
    }
  }, [dataFundDetailChange, form, optionSubstrategy, selectedStrategy, setDataFundDetailChange])

  // effect to check asi legacy subStrategy based on current asi strategy
  React.useEffect(() => {
    if (dataFundDetailChange?.current && Object.keys(dataFundDetailChange?.current).includes('asi_strategy')) {
      if (
        (selectedAsiStrategy &&
          form.getFieldValue('asi_sub_strategy') &&
          optionAsiSubstrategy.length > 0 &&
          optionAsiSubstrategy.findIndex((item) => item.value === form.getFieldValue('asi_sub_strategy')) === -1) ||
        (!selectedAsiStrategy && form.getFieldValue('asi_sub_strategy'))
      ) {
        form.setFieldsValue({ asi_sub_strategy: null })
        setDataFundDetailChange((prev) => ({ ...prev, sub_strategy_code: null }))
      }
    }
  }, [dataFundDetailChange, form, optionAsiSubstrategy, selectedAsiStrategy, setDataFundDetailChange])

  return (
    <Wrapper>
      <DividerForm text="Index Information" />
      <Row>
        <Col span={12} xl={12} md={12} xs={12} sm={12}>
          <FormItemSelect
            name="asi_strategy"
            label={<RenderLabelForm value="ASI Strategy" />}
            labelCol={8}
            wrapperCol={12}
            options={
              dataAsi?.asi_strategies && dataAsi.asi_strategies.length > 0
                ? dataAsi.asi_strategies.map((item) => ({
                    value: item.id,
                    text: item.name,
                  }))
                : []
            }
          />
        </Col>
        <Col span={12}>
          <FormItemSelect
            disabled={!selectedAsiStrategy}
            name="asi_sub_strategy"
            label={<RenderLabelForm value="ASI Sub-Strategy" />}
            labelCol={8}
            wrapperCol={12}
            options={optionAsiSubstrategy}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <FormItemSelect
            name="indexRegion"
            label={<RenderLabelForm value="Index Region" />}
            labelCol={8}
            wrapperCol={12}
            options={
              fundDataDropdown?.region_inv_focus && fundDataDropdown?.region_inv_focus.length > 0
                ? fundDataDropdown.region_inv_focus.map((item) => ({
                    value: item.code_code,
                    text: item.code_description,
                  }))
                : []
            }
            disabled
            notConnected
          />
        </Col>
        <Col span={12}>
          <FormItemInput
            name="countryIndexInfo"
            label={<RenderLabelForm value="Index Country" />}
            labelCol={8}
            wrapperCol={12}
            notConnected={true}
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <FormItemSelect
            name="strategy_code"
            label={<RenderLabelForm value="Legacy Strategy" />}
            labelCol={8}
            wrapperCol={12}
            options={
              dataLegacy?.legacy_strategies && dataLegacy.legacy_strategies.length > 0
                ? dataLegacy.legacy_strategies.map((item) => ({
                    value: item.strategy_code,
                    text: item.strategy_name,
                  }))
                : []
            }
          />
        </Col>

        <Col span={12}>
          <FormItemSelect
            disabled={!selectedStrategy}
            name="sub_strategy_code"
            label={<RenderLabelForm value="Legacy Sub-Strategy" />}
            labelCol={8}
            wrapperCol={12}
            options={optionSubstrategy}
          />
        </Col>
      </Row>
      <DividerForm text="Index Eligibility Information" />
      {loadingListIndices && <Spin tip="Loading Index Managers" />}
      <FundIndexEligibilityDetailsForm indexElgibleManager={indexElgibleManager} />
      <NextBackButton />
    </Wrapper>
  )
}
