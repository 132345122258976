import { Tabs } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { FirmDetailLabelSearchContext } from '../../../../shared/api/context/FirmDetailLabelSearchContext'
import { LeftNavFirmEnum, RightTabFirmEnum } from '../../../../shared/SearchFirmLabel'
import { FirmNonSecInfo } from './FirmNonSecInfo'
import { FirmSecInfo } from './FirmSecInfo'
const { TabPane } = Tabs

const StyledTabs = styled(Tabs)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`
const RIGHT_TAB_PANE = [
  {
    id: RightTabFirmEnum.SEC_INFO,
  },
  {
    id: RightTabFirmEnum.NON_SEC_INFO,
  },
]
export const FirmRegMain: React.FC = () => {
  const { isFiltering, filterArr } = React.useContext(FirmDetailLabelSearchContext)

  const listSearch = React.useMemo(() => {
    return filterArr.filter((item) => item.leftNav === LeftNavFirmEnum.FIRM_REG_INFORMATION)
  }, [filterArr])

  const renderTab = (index: RightTabFirmEnum) => {
    switch (index) {
      case RightTabFirmEnum.SEC_INFO:
        return <FirmSecInfo />
      case RightTabFirmEnum.NON_SEC_INFO:
        return <FirmNonSecInfo />

      default:
        break
    }
  }
  return (
    <StyledTabs hideAdd type="editable-card" defaultActiveKey="1">
      {RIGHT_TAB_PANE.map((item) => {
        const showTabPane = listSearch.findIndex((i) => i.rightTab && i.rightTab === item.id)
        if (showTabPane === -1 && isFiltering) {
          return null
        }
        return (
          <TabPane tab={item.id} key={item.id} closable={false}>
            {renderTab(item.id)}
          </TabPane>
        )
      })}
    </StyledTabs>
  )
}
