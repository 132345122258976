import { Col, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'
import { FundDetailDropdownContext } from '../../../shared/api/context/FundDetailDropdownContext'
import { ExFundFieldChange, FundDetails } from '../../../shared/api/models/FundDetails'
import { DividerForm } from '../../../shared/components/DividerForm'
import {
  FormItemCheckBox,
  FormItemDatePicker,
  FormItemInput,
  FormItemSelect,
} from '../../../shared/components/FormItemComponents'
import { RenderLabelExNewFund } from '../../../shared/components/RenderLabelExNewFund'
import { LeftNavExNewFundEnum } from '../../../shared/SearchExNewFundLabel'
import { NextBackNewFundTab } from './NextBackExNewFundTab'
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 1rem;
  padding: 0 2rem;
`
const ARRAY_FIELD_VALIDATE: string[] = ['fund_name', 'firm_id', 'inception_date', 'denomination', 'reporting_style']
type Props = {
  setStepTab: (value: LeftNavExNewFundEnum) => void
  setActiveTab: (value: LeftNavExNewFundEnum) => void
  activeTab: LeftNavExNewFundEnum
  stepTab: LeftNavExNewFundEnum
  form: FormInstance
  setDataFundDetailChange: (action: React.SetStateAction<Partial<FundDetails & ExFundFieldChange>>) => void
}

export const ExNewFundCreateFundProfile: React.FC<Props> = ({
  setStepTab,
  setActiveTab,
  setDataFundDetailChange,
  activeTab,
  stepTab,
  form,
}) => {
  const { fundDataDropdown, dataFirms, setFirmIdChoose } = React.useContext(FundDetailDropdownContext)

  const liquidAltTypeValue = form.getFieldValue('liquid_alt_type')
  const liquidAltProduct = form.getFieldValue('liquid_alt_product')
  const firmId = form.getFieldValue('firm_id')
  React.useEffect(() => {
    setFirmIdChoose(firmId)
  }, [firmId, setFirmIdChoose])

  const arrayValidate = React.useMemo(() => {
    return liquidAltProduct ? [...ARRAY_FIELD_VALIDATE, 'liquid_alt_type', 'liquid_alt_region'] : ARRAY_FIELD_VALIDATE
  }, [liquidAltProduct])

  const LIST_FORM = [
    {
      name: 'fund_name',
      label: 'Enter Fund Name',
      rules: [{ required: true, message: 'Fund Name is required' }],
    },
    {
      name: 'firm_id',
      label: 'Firm',
      rules: [{ required: true, message: 'Please input your Firm!' }],
      options:
        dataFirms && dataFirms.length > 0
          ? dataFirms.map((item) => ({
              value: item.firm_id,
              text: item.firm_name,
            }))
          : [],
    },
    {
      name: 'product_class',
      label: 'Product/Share Class (if applicable)',
      options:
        fundDataDropdown?.product_class && fundDataDropdown?.product_class.length > 0
          ? fundDataDropdown.product_class.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
    },
    {
      name: 'inception_date',
      label: 'Fund Inception Date: (MM/DD/YYYY)',
      isDatePicker: true,
      rules: [{ required: true, message: 'Fund Inception Date is required' }],
    },
    {
      name: 'denomination',
      label: 'Denomination',
      options:
        fundDataDropdown?.denomination && fundDataDropdown.denomination.length > 0
          ? fundDataDropdown.denomination.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
      rules: [{ required: true, message: 'Denomination is required' }],
    },
    {
      name: 'reporting_style',
      label: 'Performance Reporting Style',
      options:
        fundDataDropdown?.reporting_style && fundDataDropdown.reporting_style.length > 0
          ? fundDataDropdown.reporting_style.map((item) => ({
              value: item,
              text: item,
            }))
          : [],
      rules: [{ required: true, message: 'Performance Reporting Style is required' }],
    },
  ]
  const LIST_FORM_FOOTER = React.useMemo(() => {
    return [
      {
        name: 'liquid_alt_type',
        label: 'Type',
        options:
          fundDataDropdown && fundDataDropdown.liquid_alt_type && fundDataDropdown.liquid_alt_type.length > 0
            ? fundDataDropdown.liquid_alt_type.map((item) => ({ value: item.code_code, text: item.code_description }))
            : [],
        rules: liquidAltProduct ? [{ required: true, message: 'Type is required' }] : [],
      },
      {
        name: 'liquid_alt_region',
        label: 'Country Of Registration',
        disable: true,
        options:
          fundDataDropdown && fundDataDropdown.liquid_alt_region && fundDataDropdown.liquid_alt_region.length > 0
            ? fundDataDropdown.liquid_alt_region.map((item) => ({
                value: item.code_code,
                text: item.code_description,
              }))
            : [],
        rules: liquidAltProduct ? [{ required: true, message: 'Country Of Registration is required' }] : [],
      },
    ]
  }, [fundDataDropdown, liquidAltProduct])

  React.useEffect(() => {
    if (!form.getFieldValue('reporting_style')) {
      setDataFundDetailChange((prev) => ({ ...prev, reporting_style: 'Net of All Fees' }))
      form.setFieldsValue({ reporting_style: 'Net of All Fees' })
    }
  }, [form, setDataFundDetailChange])

  React.useEffect(() => {
    if (liquidAltTypeValue) {
      form.setFieldsValue({ liquid_alt_region: '1' })
      setDataFundDetailChange((prev) => ({ ...prev, liquid_alt_region: '1' }))
    } else {
      form.setFieldsValue({ liquid_alt_region: null })
      setDataFundDetailChange((prev) => {
        const { liquid_alt_region, ...rest } = prev
        return liquid_alt_region ? rest : prev
      })
    }
  }, [form, liquidAltTypeValue, setDataFundDetailChange])

  return (
    <Wrapper>
      <Row>
        {LIST_FORM.map((item) => {
          return (
            <Col span={12} xl={12} md={16} xs={24} sm={24} key={item.name}>
              {item.isDatePicker ? (
                <FormItemDatePicker
                  name={item.name}
                  label={<RenderLabelExNewFund value={item.label} />}
                  wrapperCol={16}
                  rules={item.rules}
                />
              ) : item.options ? (
                <FormItemSelect
                  name={item.name}
                  label={<RenderLabelExNewFund value={item.label} />}
                  options={item.options}
                  wrapperCol={16}
                  rules={item.rules}
                />
              ) : (
                <FormItemInput
                  name={item.name}
                  label={<RenderLabelExNewFund value={item.label} />}
                  wrapperCol={16}
                  rules={item.rules}
                />
              )}
            </Col>
          )
        })}
        {/* <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemCheckBox name="fundReportNavPerShare" text="Check If Fund Reports Nav Per Share" />
        </Col> */}
      </Row>
      <DividerForm />
      <Row>
        <div style={{ padding: '0.5rem 0' }}>
          If investment vehicle is a <b>Liquid Alternative Product</b> (i.e., Open-End Mutual Fund), please select the
          Type and Country of Registration below:
        </div>
      </Row>
      <Row>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemCheckBox name="liquid_alt_product" text={<RenderLabelExNewFund value="Liquid Alt Product" />} />
        </Col>
      </Row>
      {liquidAltProduct && (
        <Row>
          {LIST_FORM_FOOTER.map((item) => {
            return (
              <Col span={12} xl={12} md={16} xs={24} sm={24} key={item.name}>
                <FormItemSelect
                  name={item.name}
                  label={<RenderLabelExNewFund value={item.label} />}
                  options={item.options}
                  wrapperCol={16}
                  disabled={item.disable}
                  rules={item.rules}
                />
              </Col>
            )
          })}
        </Row>
      )}

      <NextBackNewFundTab
        setStepTab={setStepTab}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        stepTab={stepTab}
        ArrayValidateField={arrayValidate}
        form={form}
      />
    </Wrapper>
  )
}
