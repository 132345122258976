import { Spin } from 'antd'
import React from 'react'
type Props = {
  name: string
}
export const LoadingDetails: React.FC<Props> = ({ name }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        background: 'rgba(255, 255, 255, 0.5)',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 10,
      }}
    >
      <Spin tip={`${name}...`} />
    </div>
  )
}
