import { CheckOutlined, CloseOutlined, DeleteTwoTone, EditOutlined } from '@ant-design/icons'
import { Button, Col, Form, Modal, notification, Popconfirm, Row, Spin, Table, Tooltip } from 'antd'
import { Store } from 'antd/lib/form/interface'
import { ColumnProps } from 'antd/lib/table'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../../../../shared/api'
import { FundDetailDataContext } from '../../../../../shared/api/context/FundDetailDataContext'
import { LeverageResponseType } from '../../../../../shared/api/models/FundDetails'
import { Colors } from '../../../../../shared/colors'
import EditableCell from '../../../../../shared/components/EditableTable/EditableCell'
import EditableFormRow from '../../../../../shared/components/EditableTable/EditableFormRow'
import { FormItemDatePicker, FormItemInput } from '../../../../../shared/components/FormItemComponents'
import { useServiceState } from '../../../../../shared/hooks/useServiceState'
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  margin: 0 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-direction: column;
  .ant-table-cell {
    padding: 0px 8px;
  }
  .editable-cell {
    position: relative;
  }
  .editable-cell-value-wrap {
    padding: 0 12px;
  }
`

type Props = {}
export const FundInfoLeverage: React.FC<Props> = () => {
  const { dataFundDetail } = React.useContext(FundDetailDataContext)
  const { invoke: getLeverage, data: leverageData, loading: dataLoading } = useServiceState(
    APIService.fundDetailService.fetchFundLeverages,
  )
  const [localLeverage, setLocalLeverage] = React.useState<(LeverageResponseType & { key: string })[]>()
  const [editingRow, setEditingRow] = React.useState<LeverageResponseType & { key: string }>()
  const [submitting, setSubmitting] = React.useState(false)
  const [showAddNew, setShowAddNew] = React.useState(false)

  const [form] = Form.useForm()

  React.useEffect(() => {
    if (!dataFundDetail) {
      return
    }
    getLeverage(dataFundDetail.fund_id)
  }, [getLeverage, dataFundDetail])

  React.useEffect(() => {
    if (!leverageData) {
      return
    }
    setLocalLeverage(leverageData.map((item) => ({ ...item, key: item.date })))
  }, [leverageData])

  const handleUpdateRow = async () => {
    if (!editingRow || !dataFundDetail) {
      return
    }
    try {
      setSubmitting(true)
      await APIService.fundDetailService.updateFundLeverage(dataFundDetail.fund_id, {
        work_date: editingRow.key,
        updates: {
          minimum: editingRow.minimum ? parseFloat(editingRow.minimum.toString()) : undefined,
          maximum: editingRow.maximum ? parseFloat(editingRow.maximum.toString()) : undefined,
          average: editingRow.average ? parseFloat(editingRow.average.toString()) : undefined,
          work_date: editingRow.date,
        },
      })
      getLeverage(dataFundDetail.fund_id)
      notification.success({ message: 'Row updated successfully!' })
    } catch (err) {
      console.log(err)
      notification.error({ message: 'Failed to update row!' })
    } finally {
      setSubmitting(false)
      setEditingRow(undefined)
    }
  }

  const handleDeleteRow = async (date: string) => {
    if (!dataFundDetail) {
      return
    }
    if (date === '') {
      // newly added row
      setLocalLeverage((prev) => prev?.filter((item) => item.date !== ''))
      setEditingRow(undefined)
      return
    }
    try {
      setSubmitting(true)
      await APIService.fundDetailService.deleteFundLeverage(dataFundDetail.fund_id, date)
      getLeverage(dataFundDetail.fund_id)
      notification.success({ message: 'Row deleted successfully!' })
    } catch (error) {
      console.log(error)
      notification.error({ message: 'Fail to delete row!' })
    } finally {
      setSubmitting(false)
      setEditingRow(undefined)
    }
  }

  const handleAddRow = async (value: Store) => {
    if (!value || !dataFundDetail) {
      return
    }
    try {
      setSubmitting(true)
      await APIService.fundDetailService.createFundLeverage(dataFundDetail.fund_id, {
        work_date: moment(value.date).format('YYYY-MM-DD'),
        minimum: value.minimum ? parseFloat(value.minimum.toString()) : undefined,
        maximum: value.maximum ? parseFloat(value.maximum.toString()) : undefined,
        average: value.average ? parseFloat(value.average.toString()) : undefined,
      })
      form.resetFields()
      getLeverage(dataFundDetail.fund_id)
      notification.success({ message: 'Row created successfully!' })
      setShowAddNew(false)
    } catch (error) {
      console.log(error)
      notification.error({ message: `Fail to create new row! ${error.response.data.message || ''}` })
      localLeverage && setLocalLeverage(localLeverage?.filter((item) => item.key !== 'new-row'))
    } finally {
      setSubmitting(false)
      setEditingRow(undefined)
    }
  }

  const handleAddNewRow = () => {
    if (!showAddNew) {
      setShowAddNew(true)
    }
  }

  const columns: ColumnProps<LeverageResponseType & { key: string }>[] = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => {
        return a.date.localeCompare(b.date)
      },
      // @ts-ignore
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'date',
        editingRow,
        setEditingRow,
      }),
      width: '22.5%',
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Min (%)',
      dataIndex: 'minimum',
      key: 'min',
      // @ts-ignore
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'minimum',
        editingRow,
        setEditingRow,
      }),
      width: '22.5%',
      sorter: (a, b) => {
        return (a.minimum || 0) - (b.minimum || 0)
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Max (%)',
      dataIndex: 'maximum',
      key: 'maximum',
      sorter: (a, b) => {
        return (a.maximum || 0) - (b.maximum || 0)
      },
      sortDirections: ['descend', 'ascend'],
      // @ts-ignore
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'maximum',
        editingRow,
        setEditingRow,
      }),
      width: '22.5%',
    },
    {
      title: 'Average (%)',
      dataIndex: 'average',
      key: 'average',
      sorter: (a, b) => {
        return (a.average || 0) - (b.minimum || 0)
      },
      sortDirections: ['descend', 'ascend'],
      // @ts-ignore
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'average',
        editingRow,
        setEditingRow,
      }),
      width: '22.5%',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: '10%',
      render: (_, record) => (
        <>
          {!submitting && !editingRow && (
            <Button
              type="link"
              style={{ padding: '4px 8px' }}
              onClick={() => setEditingRow({ ...record, key: record.key })}
            >
              <Tooltip title="Edit">
                <EditOutlined />
              </Tooltip>
            </Button>
          )}
          {editingRow && editingRow.key === record.key && !submitting && (
            <>
              <Button type="link" style={{ padding: '4px 8px' }} onClick={() => handleUpdateRow()}>
                <Tooltip title="Save">
                  <CheckOutlined />
                </Tooltip>
              </Button>
              <Button
                type="link"
                style={{ color: Colors.gray, padding: '4px 8px' }}
                onClick={() => {
                  setEditingRow(undefined)
                  localLeverage && setLocalLeverage(localLeverage?.filter((item) => item.key !== 'new-row'))
                }}
              >
                <Tooltip title="Cancel">
                  <CloseOutlined />
                </Tooltip>
              </Button>
            </>
          )}
          {editingRow && editingRow.key === record.key && submitting && <Spin />}
          {!(editingRow && editingRow.key === record.key) && (
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => {
                handleDeleteRow(record.date)
              }}
            >
              <Button type="link" style={{ padding: '4px 8px' }}>
                <Tooltip title="Delete">
                  <DeleteTwoTone twoToneColor={Colors.danger} />
                </Tooltip>
              </Button>
            </Popconfirm>
          )}
        </>
      ),
    },
  ]

  const components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell,
    },
  }
  return (
    <Wrapper>
      <div>
        <Button type="primary" style={{ marginBottom: 8 }} onClick={handleAddNewRow}>
          Add New Row
        </Button>
      </div>
      <Table
        rowKey="key"
        rowClassName={() => 'editable-row'}
        components={components}
        loading={dataLoading}
        dataSource={localLeverage}
        columns={columns}
        scroll={{ y: 'calc(100vh - 478px)' }}
        pagination={{
          hideOnSinglePage: true,
          showSizeChanger: false,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
        style={{ flex: 1 }}
        bordered
      />
      <Modal title="Add New Leverage" visible={showAddNew} onCancel={() => setShowAddNew(false)} onOk={form.submit}>
        <Form form={form} layout="vertical" onFinish={handleAddRow}>
          <FormItemDatePicker name="date" label="Date" rules={[{ required: true, message: 'Date is required' }]} />
          <Row gutter={8}>
            <Col span={8}>
              <FormItemInput
                name="minimum"
                rules={[{ required: true, message: 'Minimum is required' }]}
                label="Min (%)"
                typeField="number"
              />
            </Col>
            <Col span={8}>
              <FormItemInput
                name="maximum"
                rules={[{ required: true, message: 'Maximum is required' }]}
                label="Max (%)"
                typeField="number"
              />
            </Col>
            <Col span={8}>
              <FormItemInput
                name="average"
                rules={[{ required: true, message: 'Average is required' }]}
                label="Average (%)"
                typeField="number"
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </Wrapper>
  )
}
