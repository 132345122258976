import { CheckOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Checkbox, Input, Tooltip } from 'antd'
import Table, { ColumnProps } from 'antd/lib/table'
import React from 'react'
import {
  DUE_DILIGENCE_REQUEST_ENUM,
  FundDetailDataContext,
} from '../../../../../shared/api/context/FundDetailDataContext'
import { ChecklistMainType, ChecklistMainUpdate } from '../../../../../shared/api/models/FirmDetails'

type Props = {}
export const ChecklistMain: React.FC<Props> = () => {
  const {
    getDueDiligenceRequest,
    setDataChangeChecklistMain,
    dataChecklistMain,
    loadingChecklistMain,
  } = React.useContext(FundDetailDataContext)

  const [editedDataChecklist, setEditedDataChecklist] = React.useState(dataChecklistMain)
  const [indexRowEdit, setIndexRowEdit] = React.useState(-1)
  const [textEditRow, setTextEditRow] = React.useState('')
  React.useEffect(() => {
    if (dataChecklistMain) {
      setEditedDataChecklist(dataChecklistMain)
    }
  }, [dataChecklistMain])

  React.useEffect(() => {
    getDueDiligenceRequest(DUE_DILIGENCE_REQUEST_ENUM.DUE_DILIGENCE_MAIN)
  }, [getDueDiligenceRequest])

  React.useEffect(() => {
    const dataChange: ChecklistMainUpdate[] | undefined = []
    if (dataChecklistMain && editedDataChecklist) {
      editedDataChecklist.map((item, index) => {
        if (item.status !== dataChecklistMain[index].status || item.source !== dataChecklistMain[index].source) {
          dataChange.push({ identifier: item.identifier, status: item.status, source: item.source })
        }
      })
      setDataChangeChecklistMain(dataChange.length > 0 ? dataChange : undefined)
    }
  }, [dataChecklistMain, editedDataChecklist, setDataChangeChecklistMain])

  const columns: ColumnProps<Partial<ChecklistMainType>>[] = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record, index) => (
        <Checkbox
          checked={record.status}
          disabled={editedDataChecklist && editedDataChecklist[index].readonly}
          onChange={(e) =>
            editedDataChecklist &&
            setEditedDataChecklist([
              ...editedDataChecklist?.slice(0, index),
              { ...editedDataChecklist[index], status: e.target.checked },
              ...editedDataChecklist?.slice(index + 1, editedDataChecklist.length),
            ])
          }
        ></Checkbox>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Operator ID',
      dataIndex: 'oper_id',
      key: 'oper_id',
    },
    {
      title: 'Record Date',
      dataIndex: 'rec_date',
      key: 'rec_date',
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      render: (_, record, index) =>
        indexRowEdit === index ? (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Input defaultValue={record.source} onChange={(e) => setTextEditRow(e.target.value)} />
            <Button
              type="link"
              onClick={() => {
                editedDataChecklist &&
                  setEditedDataChecklist([
                    ...editedDataChecklist?.slice(0, index),
                    { ...editedDataChecklist[index], source: textEditRow },
                    ...editedDataChecklist?.slice(index + 1, editedDataChecklist.length),
                  ])
                setIndexRowEdit(-1)
                setTextEditRow('')
              }}
            >
              <Tooltip title="Save">
                <CheckOutlined />
              </Tooltip>
            </Button>
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>{record.source}</div>
            <div>
              <Button
                type="link"
                onClick={() => setIndexRowEdit(index)}
                disabled={editedDataChecklist && editedDataChecklist[index].readonly}
              >
                <Tooltip title="Edit">
                  <EditOutlined />
                </Tooltip>
              </Button>
            </div>
          </div>
        ),
    },
  ]
  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <div style={{ marginTop: '0.5rem', display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="link"
          style={{ marginBottom: 8 }}
          onClick={() => {
            setEditedDataChecklist(dataChecklistMain)
            setIndexRowEdit(-1)
            setTextEditRow('')
          }}
        >
          Revert Changes
        </Button>
      </div>
      <Table
        rowKey="oper_id"
        dataSource={editedDataChecklist}
        columns={columns}
        style={{ flex: 1 }}
        loading={loadingChecklistMain}
        pagination={dataChecklistMain && dataChecklistMain.length > 10 ? undefined : false}
        bordered
      />
    </div>
  )
}
