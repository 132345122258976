import { Col, Input, Modal, notification, Row, Table } from 'antd'
import React, { useEffect } from 'react'
import APIService from '../../shared/api'
import { useServiceState } from '../../shared/hooks/useServiceState'

type Props = {
  isOpen: boolean
  onRequestClose: () => void
  selectedFirmId: string
  selectedFirmName: string
  onSubmitDeleting: () => void
}

const DeleteFirmModal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  selectedFirmId,
  selectedFirmName,
  onSubmitDeleting,
}) => {
  const [submitting, setSubmitting] = React.useState(false)
  const [submitText, setSubmitText] = React.useState<string>()

  const { data, invoke: previewData, loading } = useServiceState(APIService.firmDetailService.previewDeleteFirm)

  useEffect(() => {
    if (!selectedFirmId) {
      return
    }
    previewData(selectedFirmId)
  }, [previewData, selectedFirmId])

  const handleSubmit = async () => {
    if (!selectedFirmId) {
      return
    }
    try {
      setSubmitting(true)
      await APIService.firmDetailService.deleteFirm(selectedFirmId)
      notification.success({ message: 'Firm Deleted!' })
      onSubmitDeleting()
      onRequestClose()
    } catch (err) {
      notification.error({ message: err.response.data.message })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Modal
      title={`Delete Firm ${selectedFirmName} (${selectedFirmId})`}
      visible={isOpen}
      onCancel={() => onRequestClose()}
      onOk={handleSubmit}
      okText={'Delete'}
      confirmLoading={submitting}
      style={{ minHeight: '60vh' }}
      okButtonProps={
        submitText?.toLowerCase() !== selectedFirmId.toLowerCase()
          ? { disabled: true, className: 'modal-footer-hiden-button' }
          : { danger: true }
      }
    >
      <Row>
        <Col style={{ textAlign: 'center' }}>
          By deleting the firm, these number of linked entities in these tables will also be deleted. Please enter{' '}
          <b>Firm ID</b> to confirm.
          <Input
            style={{ textAlign: 'center' }}
            width="50%"
            value={submitText}
            onChange={(e) => setSubmitText(e.target.value)}
          />
        </Col>
      </Row>
      <Table
        size="small"
        bordered
        rowKey="table_name"
        loading={loading}
        dataSource={
          data
            ? Object.keys(data).map((key) => ({
                table_name: key,
                linked_entities: data[key],
              }))
            : []
        }
        columns={[
          {
            title: 'Table Name',
            dataIndex: 'table_name',
            key: 'table_name',
            sorter: {
              compare: (a, b) => {
                return a.table_name.toLowerCase() > b.table_name.toLowerCase() ? 1 : -1
              },
              multiple: 1,
            },
            defaultSortOrder: 'ascend',
          },
          {
            title: 'Number of Linked Entities',
            dataIndex: 'linked_entities',
            key: 'linked_entities',
            sorter: {
              compare: (a, b) => {
                return a.linked_entities - b.linked_entities
              },
              multiple: 2,
            },
            defaultSortOrder: 'descend',
          },
        ]}
      />
    </Modal>
  )
}

export default DeleteFirmModal
