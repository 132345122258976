import React, { Dispatch, SetStateAction, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import APIService from '..'
import { RootState } from '../../../app/rootReducer'
import { RORResponseType, UpdateRORType } from '../../components/DataSheet'
import { useDraft, ViewModeType } from '../../DraftManager'
import { useServiceState } from '../../hooks/useServiceState'
import { ChecklistLegalDoc, ChecklistMainType, ChecklistMainUpdate, LegalChecklistUpdate } from '../models/FirmDetails'
import {
  DueDiligenceInvestor,
  DueDiligenceInvestorUpdate,
  ExFundFieldChange,
  FundDetails,
  FundDropdownType,
  FundIndexEligibilityDetails,
} from '../models/FundDetails'
import { LatestPerformanceResponse } from '../services/fund.service'

export enum DUE_DILIGENCE_REQUEST_ENUM {
  DUE_DILIGENCE_MAIN,
  DUE_DILIGENCE_LEGAL_CHECKLIST,
  DUE_DILIGENCE_OLD_CHECKLIST,
  DUE_DILIGENCE_INVESTOR,
}

export enum PERFORMANCE_REQUEST_ENUM {
  LATEST_PERFORMANCE,
  ROR,
  ASSET,
  NAV,
  DISTRIBUTION,
}

export type PerformanceUpdateType = {
  rorChanges?: RORResponseType[]
  navChanges?: RORResponseType[]
  assetChanges?: RORResponseType[]
}

type ExtraTablesKeys = {
  _latestPerformanceDataChange: PerformanceUpdateType
  _RORDataChange: UpdateRORType
  _distributionDataChange: UpdateRORType
  _assetDataChange: UpdateRORType
  _navDataChange: UpdateRORType
  _dataChangeChecklistMain: ChecklistMainUpdate[]
  _dataChangeLegalChecklist: LegalChecklistUpdate[]
  _dataChangeOldChecklist: LegalChecklistUpdate[]
  _dataChangeInvestor: Partial<DueDiligenceInvestorUpdate>
}
export type FundDetailDataContextType = {
  dataFundDetail?: FundDetails
  loading: boolean
  refetch: () => void
  getDueDiligenceRequest: (type: DUE_DILIGENCE_REQUEST_ENUM) => void
  getPerformanceRequest: (type: PERFORMANCE_REQUEST_ENUM) => void
  //
  getFundDetail: () => void
  //Checklist Main
  dataChecklistMain?: ChecklistMainType[]
  loadingChecklistMain: boolean
  setDataChangeChecklistMain: (value: ChecklistMainUpdate[] | undefined) => void
  dataChangeChecklistMain: ChecklistMainUpdate[] | undefined
  //LegalChecklist
  dataLegalChecklist?: ChecklistLegalDoc[]
  loadingLegalChecklist: boolean
  setDataChangeLegalChecklist: (value: LegalChecklistUpdate[] | undefined) => void
  dataChangeLegalChecklist: LegalChecklistUpdate[] | undefined
  //Old Checklist
  dataOldChecklist?: ChecklistLegalDoc[]
  loadingOldChecklist: boolean
  setDataChangeOldChecklist: (value: LegalChecklistUpdate[] | undefined) => void
  dataChangeOldChecklist: LegalChecklistUpdate[] | undefined
  //investor opinion
  dataInvestorOpinion?: DueDiligenceInvestor
  loadingInvestorOpinion: boolean
  dataChangeInvestor: Partial<DueDiligenceInvestorUpdate> | undefined
  setDataChangeInvestor: Dispatch<SetStateAction<Partial<DueDiligenceInvestorUpdate> | undefined>>
  // Performance Update
  dataLatestPerformance: LatestPerformanceResponse | undefined
  latestPerformanceLoading: boolean
  performanceDataChange: PerformanceUpdateType | undefined
  setPerformanceDataChange: Dispatch<SetStateAction<PerformanceUpdateType | undefined>>
  //ROR
  setRORDataChange: Dispatch<SetStateAction<UpdateRORType>>
  rorDataChange: UpdateRORType
  dataRORLoading: boolean
  dataROR: RORResponseType[] | undefined
  // Distribution
  dataDistribution: RORResponseType[] | undefined
  dataDistributionLoading: boolean
  distributionDataChange: UpdateRORType
  setDistributionDataChange: Dispatch<SetStateAction<UpdateRORType>>
  //Asset
  setAssetDataChange: Dispatch<SetStateAction<UpdateRORType>>
  assetDataChange: UpdateRORType
  dataAssetLoading: boolean
  dataAsset: RORResponseType[] | undefined
  //Nav
  setNAVDataChange: Dispatch<SetStateAction<UpdateRORType>>
  navDataChange: UpdateRORType
  dataNAVLoading: boolean
  dataNAV: RORResponseType[] | undefined
  // Fund Index Eligibility Detail
  originalFundIndexEligibilityDetail?: FundIndexEligibilityDetails
  setOriginalFundIndexEligibilityDetail: Dispatch<SetStateAction<FundIndexEligibilityDetails | undefined>>
  fundIndexEligibilityDetailChange?: Partial<FundIndexEligibilityDetails>
  setFundIndexEligibilityDetailChange: Dispatch<SetStateAction<Partial<FundIndexEligibilityDetails> | undefined>>
  // Fund Detail Change
  dataFundDetailChange: React.MutableRefObject<Partial<FundDetails & ExFundFieldChange>> | null
  snapshot: Partial<FundDetails & ExFundFieldChange> | null
  setDataFundDetailChange: (
    action: SetStateAction<Partial<FundDetails & ExFundFieldChange>>,
    lastFieldChange?: string,
  ) => void
  clearSnapshot: () => void
  dataChanged: number
  setDataChanged: Dispatch<SetStateAction<number>>

  //View Mode
  viewMode: ViewModeType
  setViewMode: (value: ViewModeType) => void

  fundDataDropdown: FundDropdownType | undefined
  //
  userOptions: {
    value: string | number
    text: string
    disabled?: boolean
  }[]
}

const defaultValue: FundDetailDataContextType = {
  dataFundDetail: undefined,
  loading: true,
  refetch: () => undefined,
  getDueDiligenceRequest: () => undefined,
  getPerformanceRequest: () => undefined,
  //
  getFundDetail: () => undefined,
  //Checklist Main

  dataChecklistMain: undefined,
  loadingChecklistMain: true,
  setDataChangeChecklistMain: () => undefined,
  dataChangeChecklistMain: undefined,
  //Legal Checklist

  dataLegalChecklist: undefined,
  loadingLegalChecklist: true,
  setDataChangeLegalChecklist: () => undefined,
  dataChangeLegalChecklist: undefined,
  //Old Background Checklist

  dataOldChecklist: undefined,
  loadingOldChecklist: true,
  setDataChangeOldChecklist: () => undefined,
  dataChangeOldChecklist: undefined,
  //investor opinion

  dataInvestorOpinion: undefined,
  loadingInvestorOpinion: true,
  dataChangeInvestor: undefined,
  setDataChangeInvestor: () => undefined,

  // Performance Update
  dataLatestPerformance: undefined,
  latestPerformanceLoading: true,
  performanceDataChange: {},
  setPerformanceDataChange: () => undefined,

  //ROR
  setRORDataChange: () => undefined,
  dataRORLoading: true,
  dataROR: undefined,
  rorDataChange: { values: undefined },
  //Asset
  setAssetDataChange: () => undefined,
  assetDataChange: { values: undefined },
  dataAssetLoading: true,
  dataAsset: undefined,
  //Distribution
  dataDistribution: undefined,
  dataDistributionLoading: true,
  distributionDataChange: { values: undefined },
  setDistributionDataChange: () => undefined,
  //NAV
  setNAVDataChange: () => undefined,
  navDataChange: { values: undefined },
  dataNAVLoading: true,
  dataNAV: undefined,
  // Fund Index Eligibility Detail
  originalFundIndexEligibilityDetail: undefined,
  setOriginalFundIndexEligibilityDetail: () => undefined,
  fundIndexEligibilityDetailChange: undefined,
  setFundIndexEligibilityDetailChange: () => undefined,
  //Fund Detail Change
  dataFundDetailChange: null,
  snapshot: {},
  setDataFundDetailChange: () => undefined,
  clearSnapshot: () => undefined,
  dataChanged: 1,
  setDataChanged: () => undefined,

  //View Mode
  viewMode: 'internal',
  setViewMode: () => undefined,

  fundDataDropdown: undefined,
  //
  userOptions: [],
}
type Props = {
  id: string
  type: 'internal' | 'external'
  shouldUpdate?: boolean
  onClearUpdatedPerformance?: () => void
}
export const FundDetailDataContext = React.createContext<FundDetailDataContextType>(defaultValue)
export const FundDetailDataProvider: React.FC<Props> = ({
  children,
  id,
  type,
  shouldUpdate,
  onClearUpdatedPerformance,
}) => {
  const { invoke: getFundDetail, data: dataFundDetail, loading } = useServiceState(
    APIService.fundDetailService.fetchFundBasicInfo,
  )
  const { invoke: getDataDropdown, data: fundDataDropdown } = useServiceState(
    APIService.fundDetailService.fetchDataDropdown,
  )
  const { invoke: getInternalUsers, data: internalUsers } = useServiceState(APIService.userService.fetchInternalUsers)

  const [dataChanged, setDataChanged] = useState(1)
  const { clearSnapshot, saveSnapshot, loadSnapshot } = useDraft()

  React.useEffect(() => {
    getFundDetail(id)
  }, [getFundDetail, id])

  React.useEffect(() => {
    getDataDropdown()
  }, [getDataDropdown])

  React.useEffect(() => {
    getInternalUsers()
  }, [getInternalUsers])

  const userOptions = React.useMemo(() => {
    if (!internalUsers) {
      return []
    }

    return internalUsers.internal_users.sort().map((id) => {
      return {
        value: id,
        text: id,
      }
    })
  }, [internalUsers])

  React.useEffect(() => {
    if (shouldUpdate) {
      getFundDetail(id)
      onClearUpdatedPerformance && onClearUpdatedPerformance()
    }
  }, [getFundDetail, id, onClearUpdatedPerformance, shouldUpdate])

  React.useEffect(() => {
    const draft = loadSnapshot(type === 'internal' ? 'fundDraft' : 'exFundDraft', id)
    if (draft) {
      setSnapshot(draft)
      setPerformanceDataChange(draft._latestPerformanceDataChange || undefined)
      setRORDataChange(draft._RORDataChange || { values: undefined })
      setDistributionDataChange(draft._distributionDataChange || { values: undefined })
      setNAVDataChange(draft._navDataChange || { values: undefined })
      setAssetDataChange(draft._assetDataChange || { values: undefined })
      setDataChangeChecklistMain(draft._dataChangeChecklistMain)
      setDataChangeLegalChecklist(draft._dataChangeLegalChecklist)
      setDataChangeOldChecklist(draft._dataChangeOldChecklist)
      setDataChangeInvestor(draft._dataChangeInvestor)
      dataFundDetailChange.current = draft
      draft.viewMode && setViewMode(draft.viewMode)
    }
  }, [id, loadSnapshot, type])

  const refetch = () => {
    getFundDetail(id)
    setPerformanceDataChange(undefined)
    setRORDataChange({ values: undefined })
    setDistributionDataChange({ values: undefined })
    setNAVDataChange({ values: undefined })
    setAssetDataChange({ values: undefined })
    setDataChangeChecklistMain(undefined)
    setDataChangeLegalChecklist(undefined)
    setDataChangeOldChecklist(undefined)
    setDataChangeInvestor(undefined)
    setFundIndexEligibilityDetailChange(undefined)
  }

  const appSetting = useSelector((state: RootState) => state.appSettings)

  React.useEffect(() => {
    if (appSetting.fundDraft.find((item) => item.id === id)?.viewMode) {
      const view = appSetting.fundDraft.find((item) => item.id === id)?.viewMode
      view && setViewMode(view)
    }
  }, [appSetting.fundDraft, id])

  const { invoke: getDataChecklistMain, data: dataChecklistMain, loading: loadingChecklistMain } = useServiceState(
    APIService.firmDetailService.getChecklistMain,
  )

  const [dataChangeChecklistMain, setDataChangeChecklistMain] = React.useState<ChecklistMainUpdate[] | undefined>(
    undefined,
  )

  const { invoke: getChecklistLegalDoc, data: dataLegalChecklist, loading: loadingLegalChecklist } = useServiceState(
    APIService.firmDetailService.getLegalChecklist,
  )

  const [dataChangeLegalChecklist, setDataChangeLegalChecklist] = React.useState<LegalChecklistUpdate[] | undefined>(
    undefined,
  )

  const { invoke: getChecklistOldBackground, data: dataOldChecklist, loading: loadingOldChecklist } = useServiceState(
    APIService.firmDetailService.getOldBackgroundChecklist,
  )

  const [dataChangeOldChecklist, setDataChangeOldChecklist] = React.useState<LegalChecklistUpdate[] | undefined>(
    undefined,
  )

  const { invoke: getInvestorOpinion, data: dataInvestorOpinion, loading: loadingInvestorOpinion } = useServiceState(
    APIService.fundDetailService.fetchDueDiligenceInvestor,
  )

  const [dataChangeInvestor, setDataChangeInvestor] = React.useState<Partial<DueDiligenceInvestorUpdate> | undefined>(
    undefined,
  )

  const [snapshot, setSnapshot] = React.useState<Partial<FundDetails & ExFundFieldChange & ExtraTablesKeys> | null>(
    null,
  )

  const getDueDiligenceRequest = React.useCallback(
    (type: DUE_DILIGENCE_REQUEST_ENUM) => {
      if (!dataFundDetail) {
        return
      }
      switch (type) {
        case DUE_DILIGENCE_REQUEST_ENUM.DUE_DILIGENCE_MAIN:
          return getDataChecklistMain(dataFundDetail?.firm_id)
        case DUE_DILIGENCE_REQUEST_ENUM.DUE_DILIGENCE_LEGAL_CHECKLIST:
          return getChecklistLegalDoc(dataFundDetail?.firm_id)
        case DUE_DILIGENCE_REQUEST_ENUM.DUE_DILIGENCE_OLD_CHECKLIST:
          return getChecklistOldBackground(dataFundDetail?.firm_id)
        case DUE_DILIGENCE_REQUEST_ENUM.DUE_DILIGENCE_INVESTOR:
          return getInvestorOpinion(dataFundDetail?.fund_id)
      }
    },
    [dataFundDetail, getChecklistLegalDoc, getChecklistOldBackground, getDataChecklistMain, getInvestorOpinion],
  )

  const {
    data: latestPerformance,
    invoke: fetchLatestPerformance,
    loading: latestPerformanceLoading,
  } = useServiceState(APIService.fundService.fetchLatestPerformance)

  const { invoke: getFundROR, data: dataROR, loading: dataRORLoading } = useServiceState(
    APIService.fundDetailService.fetchFundROR,
  )

  const [performanceDataChange, setPerformanceDataChange] = React.useState<PerformanceUpdateType>()
  const [rorDataChange, setRORDataChange] = React.useState<UpdateRORType>({ values: undefined })
  const [assetDataChange, setAssetDataChange] = React.useState<UpdateRORType>({ values: undefined })
  const [navDataChange, setNAVDataChange] = React.useState<UpdateRORType>({ values: undefined })
  const [distributionDataChange, setDistributionDataChange] = React.useState<UpdateRORType>({ values: undefined })
  const [originalFundIndexEligibilityDetail, setOriginalFundIndexEligibilityDetail] = React.useState<
    Partial<FundIndexEligibilityDetails>
  >()
  const [fundIndexEligibilityDetailChange, setFundIndexEligibilityDetailChange] = React.useState<
    Partial<FundIndexEligibilityDetails>
  >()
  const [viewMode, setViewMode] = React.useState<ViewModeType>('internal')

  const { invoke: getNAVAssets, data: dataAsset, loading: dataAssetLoading } = useServiceState(
    APIService.fundDetailService.fetchNAVAssets,
  )

  const { invoke: getNAVNavs, data: dataNAV, loading: dataNAVLoading } = useServiceState(
    APIService.fundDetailService.fetchNAVNavs,
  )

  const { invoke: getDistributions, data: dataDistribution, loading: dataDistributionLoading } = useServiceState(
    APIService.fundDetailService.fetchFundDistributions,
  )

  const getPerformanceRequest = React.useCallback(
    (type: PERFORMANCE_REQUEST_ENUM) => {
      if (!dataFundDetail) {
        return
      }
      switch (type) {
        case PERFORMANCE_REQUEST_ENUM.LATEST_PERFORMANCE:
          return fetchLatestPerformance(dataFundDetail.fund_id)
        case PERFORMANCE_REQUEST_ENUM.ROR:
          return getFundROR(dataFundDetail.fund_id)
        case PERFORMANCE_REQUEST_ENUM.ASSET:
          return getNAVAssets(dataFundDetail.fund_id)
        case PERFORMANCE_REQUEST_ENUM.NAV:
          return getNAVNavs(dataFundDetail.fund_id)
        case PERFORMANCE_REQUEST_ENUM.DISTRIBUTION:
          return getDistributions(dataFundDetail.fund_id)
      }
    },
    [dataFundDetail, fetchLatestPerformance, getDistributions, getFundROR, getNAVAssets, getNAVNavs],
  )

  const dataFundDetailChange = useRef<Partial<FundDetails & ExFundFieldChange>>({})
  const debounceRef = useRef<any>(null)
  const setDataFundDetailChange = React.useCallback(
    (
      action: SetStateAction<Partial<FundDetails & ExFundFieldChange & ExtraTablesKeys>>,
      lastFieldChange?: string,
    ): void => {
      if (dataFundDetail) {
        dataFundDetailChange.current = typeof action === 'object' ? action : action(dataFundDetailChange.current)
        Object.keys(dataFundDetailChange.current).forEach((key) => {
          if (dataFundDetailChange.current[key as keyof Partial<FundDetails & ExFundFieldChange>] === undefined) {
            delete dataFundDetailChange.current[key as keyof Partial<FundDetails & ExFundFieldChange>]
          }
        })

        if (debounceRef.current) {
          clearTimeout(debounceRef.current)
        }
        // if (Object.keys(dataFundDetailChange.current).length > 0) {
        debounceRef.current = setTimeout(() => {
          saveSnapshot(
            type === 'internal' ? 'fundDraft' : 'exFundDraft',
            {
              id: id,
              data: Object.keys(dataFundDetailChange.current).length > 0 ? dataFundDetailChange.current : undefined,
              name: dataFundDetail.fund_name,
            },
            viewMode,
            lastFieldChange,
          )
          setDataChanged((prev) => prev + 1)
        }, 300)
        // }
      }
    },
    [dataFundDetail, id, saveSnapshot, type, viewMode],
  )

  // effect that detect changes in Tables like ROR/NAV/Assets/Distribution...
  React.useEffect(() => {
    setDataFundDetailChange((prevState) => {
      return {
        ...prevState,
        _latestPerformanceDataChange:
          Object.keys(performanceDataChange || {}).length > 0 ? performanceDataChange : undefined,
        _RORDataChange: rorDataChange.values ? rorDataChange : undefined,
        _distributionDataChange: distributionDataChange.values ? distributionDataChange : undefined,
        _navDataChange: navDataChange.values ? navDataChange : undefined,
        _assetDataChange: assetDataChange.values ? assetDataChange : undefined,
        _dataChangeChecklistMain: dataChangeChecklistMain,
        _dataChangeLegalChecklist: dataChangeLegalChecklist,
        _dataChangeOldChecklist: dataChangeOldChecklist,
        _dataChangeInvestor: dataChangeInvestor,
        _fundIndexEligibilityDetailChange: fundIndexEligibilityDetailChange,
      }
    })
  }, [
    assetDataChange,
    dataChangeChecklistMain,
    dataChangeInvestor,
    dataChangeLegalChecklist,
    dataChangeOldChecklist,
    distributionDataChange,
    navDataChange,
    performanceDataChange,
    rorDataChange,
    fundIndexEligibilityDetailChange,
    setDataFundDetailChange,
  ])

  const value = {
    dataFundDetail,
    loading,
    refetch,
    getFundDetail: () => getFundDetail(id),
    getDueDiligenceRequest,
    dataChecklistMain,
    loadingChecklistMain,
    setDataChangeChecklistMain,
    dataChangeChecklistMain,
    dataLegalChecklist,
    loadingLegalChecklist,
    setDataChangeLegalChecklist,
    dataChangeLegalChecklist,
    dataOldChecklist,
    loadingOldChecklist,
    setDataChangeOldChecklist,
    dataChangeOldChecklist,
    dataInvestorOpinion,
    loadingInvestorOpinion,
    dataChangeInvestor,
    setDataChangeInvestor,
    //Performance Request
    getPerformanceRequest,
    // Performance Update
    dataLatestPerformance: latestPerformance,
    latestPerformanceLoading,
    performanceDataChange,
    setPerformanceDataChange,
    //ROR
    dataROR,
    dataRORLoading,
    rorDataChange,
    setRORDataChange,
    //Asset
    setAssetDataChange,
    assetDataChange,
    dataAssetLoading,
    dataAsset,
    //Distribution
    setDistributionDataChange,
    dataDistribution,
    dataDistributionLoading,
    distributionDataChange,
    //Nav
    setNAVDataChange,
    navDataChange,
    dataNAVLoading,
    dataNAV,
    // Fund Index Eligibility Detail
    originalFundIndexEligibilityDetail,
    setOriginalFundIndexEligibilityDetail,
    fundIndexEligibilityDetailChange,
    setFundIndexEligibilityDetailChange,
    //Fund Detail Change
    dataFundDetailChange,
    setDataFundDetailChange,
    snapshot,
    dataChanged,
    setDataChanged,
    clearSnapshot: () => clearSnapshot(type === 'internal' ? 'fundDraft' : 'exFundDraft', id),
    //View Mode
    viewMode,
    setViewMode,
    fundDataDropdown,
    // Internal Users
    userOptions,
  }
  return <FundDetailDataContext.Provider value={value}>{children}</FundDetailDataContext.Provider>
}
