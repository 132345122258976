import { List } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../app/rootReducer'
import APIService from '../../shared/api'
import { useServiceState } from '../../shared/hooks/useServiceState'
import { addTabs, EX_OVERVIEW_KEY, FundFirmTypeEnum, PREFIX_EXTERNAL } from '../../slice/appSettingsSlice'

const TextID = styled(Text)`
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
`
const Flex = styled.div`
  display: flex;
  flex: 1;
`
const StyledList = styled(List)`
  flex: 1;
  padding: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  .ant-list-item {
    padding: 4px;
  }
`
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const ExRecentlyEditedTab: React.FC = () => {
  const { invoke: getRecentlyEdited, data: dataEdited, loading } = useServiceState(
    APIService.fundService.fetchRecentlyEdited,
  )
  const { exTabs } = useSelector((state: RootState) => state.appSettings)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (exTabs.activeTab === EX_OVERVIEW_KEY) {
      getRecentlyEdited()
    }
  }, [getRecentlyEdited, exTabs.activeTab])

  return (
    <Wrapper>
      {dataEdited && (
        <StyledList
          bordered
          loading={loading}
          dataSource={dataEdited?.funds}
          renderItem={(item: any) => (
            <List.Item style={{ width: '100%' }}>
              <div>
                <TextID>Fund #{item.fund_id}</TextID>
                <Flex style={{ marginBottom: 10 }}>
                  <Flex style={{ alignItems: 'baseline' }}>
                    <div>
                      <span
                        style={{ fontSize: 12, fontWeight: 'bold', color: '#7598E6', padding: 0, cursor: 'pointer' }}
                        onClick={() =>
                          dispatch(
                            addTabs({
                              name: item.fund_name,
                              id: `${PREFIX_EXTERNAL}-${item.fund_id}`,
                              type: FundFirmTypeEnum.FUNDS,
                            }),
                          )
                        }
                      >
                        {item.fund_name}
                      </span>
                    </div>
                  </Flex>
                </Flex>
              </div>
            </List.Item>
          )}
        />
      )}
    </Wrapper>
  )
}
