import { Tabs } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../app/rootReducer'
import { FirmDetailDataProvider } from '../../shared/api/context/FirmDetailDataContext'
import { FundDetailDropdownProvider } from '../../shared/api/context/FundDetailDropdownContext'
import { Colors } from '../../shared/colors'
import MainLayout from '../../shared/components/MainLayout'
import { LeftNavFirmEnum } from '../../shared/SearchFirmLabel'
import { FundFirmTypeEnum, MANAGER_LIST_KEY, updateFirmActiveTabs, removeFirmTabs } from '../../slice/appSettingsSlice'
import { RenderTitleTabPane } from '../Dashboard/Dashboard'
import { FirmDetailMain } from '../Dashboard/FirmDetail/FirmDetailMain'
import { FirmSearchColumnProvider } from './ColumnBuilder/FirmSearchColumnContext'
import FirmSearch from './FirmSearch'

const { TabPane } = Tabs

const StyledTab = styled(Tabs)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;

  .ant-tabs-nav {
    margin-bottom: 0%;
  }

  .ant-tabs-content-holder {
    overflow: hidden;
  }

  .ant-tabs-content {
    flex-grow: 1;
    background: white;
    border-left: solid 1px ${Colors.border};
    border-right: solid 1px ${Colors.border};
    border-bottom: solid 1px ${Colors.border};
    height: 100%;

    .ant-tabs-tabpane-active {
      height: 100%;
      display: flex;
    }

    .ant-card-head {
      min-height: auto;
    }
  }
`

type Props = {}

const ManagerList: React.FC<Props> = () => {
  const { firmTabs } = useSelector((state: RootState) => state.appSettings)

  const dispatch = useDispatch()

  return (
    <MainLayout>
      <StyledTab
        hideAdd
        type="editable-card"
        onChange={(key) => {
          dispatch(updateFirmActiveTabs(key))
        }}
        activeKey={firmTabs.activeTab}
        onEdit={(targetKey, action) => {
          if (action === 'remove') {
            dispatch(removeFirmTabs(targetKey.toString()))
          }
        }}
      >
        <TabPane tab="Manager List" key={MANAGER_LIST_KEY} closable={false}>
          <FundDetailDropdownProvider>
            <FirmSearchColumnProvider>
              <FirmSearch />
            </FirmSearchColumnProvider>
          </FundDetailDropdownProvider>
        </TabPane>
        {firmTabs &&
          firmTabs.tabList.map((item) => {
            return (
              <TabPane tab={<RenderTitleTabPane name={item.name} type={item.type} />} key={item.id} closable={true}>
                {item.type === FundFirmTypeEnum.FIRMS && (
                  <FundDetailDropdownProvider>
                    <FirmDetailDataProvider id={item.id}>
                      <FirmDetailMain initialPath={item.path as LeftNavFirmEnum} />
                    </FirmDetailDataProvider>
                  </FundDetailDropdownProvider>
                )}
              </TabPane>
            )
          })}
      </StyledTab>
    </MainLayout>
  )
}

export default ManagerList
