import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Col, Form, Input, notification, Row, Spin, Switch } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../shared/api'
import { CodeTableType } from '../../shared/api/models/CodeTable'

const Wrapper = styled('div')``

type Props = {
  selectedCodeTable?: CodeTableType
  showFormModal: boolean
  setShowFormModal: (value: boolean) => void
  setSelectedCodeTable: (value: CodeTableType | undefined) => void
  updateCodeTable: () => void
}

const CodeTableFormModal: React.FC<Props> = ({
  selectedCodeTable,
  showFormModal,
  setShowFormModal,
  setSelectedCodeTable,
  updateCodeTable,
}) => {
  const [loadingSubmit, setLoadingSubmit] = React.useState(false)

  const [form] = Form.useForm()

  React.useEffect(() => {
    if (selectedCodeTable) {
      form.setFieldsValue(selectedCodeTable)
    } else {
      form.resetFields()
    }
  }, [selectedCodeTable, form])

  const submitFormCodeTable = () => {
    form.validateFields(['code_id', 'code_code', 'code_description']).then(() => {
      const params: CodeTableType = {
        code_id: form.getFieldValue('code_id'),
        code_code: form.getFieldValue('code_code'),
        code_description: selectedCodeTable
          ? selectedCodeTable.code_description
          : form.getFieldValue('code_description'),
        is_active: form.getFieldValue('is_active') || false,
        new_code_description: selectedCodeTable ? form.getFieldValue('code_description') : undefined,
      }
      setLoadingSubmit(true)
      selectedCodeTable
        ? APIService.codeTableService
            .updateCodeTable(params)
            .then(() => {
              notification.success({ message: 'Code Table Updated!' })
              updateCodeTable()
            })
            .catch((err: any) => {
              console.error({ err })
              notification.error({ message: 'Code Table Update Failed!' })
            })
            .finally(() => {
              form.resetFields()
              setLoadingSubmit(false)
              setShowFormModal(false)
              setSelectedCodeTable(undefined)
            })
        : APIService.codeTableService
            .createCodeTable(params)
            .then(() => {
              notification.success({ message: 'Code Table Created!' })
              updateCodeTable()
            })
            .catch((err: any) => {
              console.error({ err })
              notification.error({ message: 'Code Table Create Failed!' })
            })
            .finally(() => {
              form.resetFields()
              setLoadingSubmit(false)
              setShowFormModal(false)
              setSelectedCodeTable(undefined)
            })
    })
  }

  return (
    <Modal
      width={700}
      visible={showFormModal}
      title={selectedCodeTable ? 'Update Code Table' : 'Add New Code Table'}
      okText={
        loadingSubmit ? (
          <>
            <Spin style={{ marginRight: 4 }} />
          </>
        ) : (
          'OK'
        )
      }
      onOk={submitFormCodeTable}
      onCancel={() => {
        setShowFormModal(false)
        form.resetFields()
        setSelectedCodeTable(undefined)
      }}
    >
      <Wrapper>
        <Form layout="vertical" form={form}>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="code_id"
                label="ID"
                rules={[
                  { required: true, message: 'ID is required' },
                  { max: 5, message: 'Maximum length is 5!' },
                ]}
                style={{ display: 'flex', flex: 1 }}
              >
                <Input placeholder="ID..." maxLength={5} disabled={!!selectedCodeTable} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="code_code"
                label="Code"
                rules={[
                  { required: true, message: 'Code is required' },
                  { max: 8, message: 'Maximum length is 8!' },
                ]}
                style={{ display: 'flex', flex: 1 }}
              >
                <Input placeholder="Code..." maxLength={8} disabled={!!selectedCodeTable} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="code_description"
                label="Description"
                rules={[
                  { required: true, message: 'Description is required' },
                  { max: 60, message: 'Maximum length is 60!' },
                ]}
                style={{ display: 'flex', flex: 1 }}
              >
                <Input.TextArea placeholder="Code Description..." maxLength={60} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="is_active"
                label="Is Active?"
                valuePropName="checked"
                rules={[{ required: true, message: 'Is Active is required' }]}
                style={{ display: 'flex', flex: 1 }}
              >
                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Wrapper>
    </Modal>
  )
}

export default CodeTableFormModal
