import { DeleteTwoTone, EditOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Input, notification, Popconfirm, Space, Tabs, Tooltip } from 'antd'
import Table, { ColumnProps } from 'antd/lib/table'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../shared/api'
import { FundDetailDataProvider } from '../../shared/api/context/FundDetailDataContext'
import { IndexManagerType } from '../../shared/api/models/IndexManager'
import { Colors } from '../../shared/colors'
import { TabContentWrapper } from '../../shared/components/TabContentWrapper'
import { useServiceState } from '../../shared/hooks/useServiceState'
import { LeftNavFundEnum } from '../../shared/SearchFundLabel'
import { FundDetailMain } from '../Dashboard/FundDetail/FundDetailMain'
import { IndexManagerDetails } from './IndexManagerDetails'
import IndexManagerFormModal from './IndexManagerFormModal'

const StyledTabs = styled(Tabs)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;

  .ant-tabs-nav {
    margin-bottom: 0%;
  }

  .ant-tabs-content-holder {
    overflow: hidden;
  }

  .ant-tabs-content {
    flex-grow: 1;
    background: white;
    border-left: solid 1px ${Colors.border};
    border-right: solid 1px ${Colors.border};
    border-bottom: solid 1px ${Colors.border};
    height: 100%;

    .ant-tabs-tabpane-active {
      height: 100%;
      display: flex;
    }

    .ant-card-head {
      min-height: auto;
    }
  }
`

const SymbolTab = styled.div`
  background: #1890ff;
  color: #fff;
  padding: 0 6px;
  margin-right: 2px;
  border-radius: 3px;
  font-size: 12px;
  align-self: center;
`

type ListTabType = {
  id: number
  name: string
  type: 'index' | 'funds'
  initialPath?: LeftNavFundEnum
  hyperlink?: string
}
type Props = {}

const IndexManager: React.FC<Props> = () => {
  const [showFormModal, setShowFormModal] = React.useState(false)
  const [selectedIndexManager, setSelectedIndexManager] = React.useState<IndexManagerType>()

  const [loadingDeleteIndexManager, setLoadingDeleteIndexManager] = React.useState(false)
  const [listTab, setListTab] = React.useState<ListTabType[]>([])
  const [activeTab, setActiveTab] = React.useState<string>('index-list')

  const searchInput = React.useRef<Input>(null)

  const { invoke: getAllIndex, data: dataIndexManager, loading: loadingIndexManager } = useServiceState(
    APIService.indexManagerService.getAllIndexManager,
  )

  React.useEffect(() => {
    getAllIndex()
  }, [getAllIndex])

  const updateIndexManager = () => {
    getAllIndex()
  }

  const handleSearch = (selectedKeys: React.ReactText[], confirm: (() => void) | undefined, _dataIndex: string) => {
    confirm && confirm()
  }

  const handleReset = (clearFilters: (() => void) | undefined) => {
    clearFilters && clearFilters()
  }

  const deleteIndexManager = (id: number) => {
    setLoadingDeleteIndexManager(true)
    APIService.indexManagerService
      .deleteIndexManager(id)
      .then(() => {
        notification.success({ message: 'Index Manager Delete!' })
        updateIndexManager()
      })
      .catch((err) => {
        console.error({ err })
        notification.error({ message: 'Index Manager Delete Failed!' })
      })
      .finally(() => {
        setLoadingDeleteIndexManager(false)
      })
  }

  const getColumnSearchProps = (dataIndex: string): ColumnProps<IndexManagerType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      const fieldData = record[dataIndex as keyof IndexManagerType] || ''
      return fieldData ? fieldData.toString().toLowerCase().includes(value.toString().toLowerCase()) : false
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select())
      }
    },
  })

  const columns: ColumnProps<IndexManagerType>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',

      sorter: (a, b) => {
        if (a.id && b.id) {
          return a.id - b.id
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('id'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <Button
          type="link"
          style={{ padding: '4px 8px' }}
          onClick={() => {
            if (!listTab.find((e) => e.id === record.id)) {
              setListTab([...listTab, { id: record.id, name: record.name, type: 'index' }])
            }
            setActiveTab(record.id.toString())
          }}
        >
          {record.name}
        </Button>
      ),
      sorter: (a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name)
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => {
        if (a.description && b.description) {
          return a.description.localeCompare(b.description)
        }
        return 0
      },

      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('description'),
    },
    {
      title: 'Index Eligibility',
      dataIndex: 'index_eligibility',
      key: 'index_eligibility',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        return a.index_eligibility === b.index_eligibility ? 0 : a.index_eligibility ? 1 : -1
      },
      render: (_, record) => {
        return record.index_eligibility ? 'yes' : ''
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 200,

      render: (_, record) => (
        <>
          <Button
            type="link"
            style={{ padding: '4px 8px' }}
            onClick={() => {
              setSelectedIndexManager(record)
              setShowFormModal(true)
            }}
          >
            <Tooltip title="Edit">
              <EditOutlined />
            </Tooltip>
          </Button>

          <Popconfirm title="Are you sure you want to delete?" onConfirm={() => deleteIndexManager(record.id)}>
            <Button type="link" style={{ padding: '4px 8px' }} loading={loadingDeleteIndexManager}>
              <Tooltip title="Delete">
                <DeleteTwoTone twoToneColor={Colors.danger} />
              </Tooltip>
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ]

  const renderIndex = (name: string) => {
    return (
      <div style={{ display: 'flex' }}>
        <SymbolTab>I</SymbolTab>
        <div style={{ marginLeft: 4 }}>{name}</div>
      </div>
    )
  }

  return (
    <StyledTabs
      hideAdd
      type="editable-card"
      onEdit={(targetKey, action) => {
        if (action === 'remove') {
          setListTab(listTab.filter((item) => item.id.toString() !== targetKey))
          setActiveTab('index-list')
        }
      }}
      onChange={(key) => {
        setActiveTab(key)
      }}
      activeKey={activeTab}
    >
      <Tabs.TabPane
        tab="Index Manager List"
        closable={false}
        key="index-list"
        style={{ display: 'flex', flexDirection: 'column', padding: 8 }}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="primary" onClick={() => setShowFormModal(true)}>
            Add New
          </Button>
        </div>

        <Table
          rowKey="id"
          scroll={{ y: 'calc(100vh - 380px)', x: 'min-content' }}
          size="small"
          dataSource={dataIndexManager}
          columns={columns}
          loading={loadingIndexManager}
          pagination={{ defaultPageSize: 20 }}
        />
        <IndexManagerFormModal
          selectedIndexManager={selectedIndexManager}
          showFormModal={showFormModal}
          setShowFormModal={setShowFormModal}
          setSelectedIndexManager={setSelectedIndexManager}
          updateIndexManager={updateIndexManager}
        />
      </Tabs.TabPane>
      {listTab.map((item) => {
        if (item.type === 'funds') {
          return (
            <Tabs.TabPane tab={item.name} closable={true} key={item.id}>
              <FundDetailDataProvider type={'internal'} id={item.id.toString()}>
                <FundDetailMain initialPath={item.initialPath} hyperlinkTable={item.hyperlink} />
              </FundDetailDataProvider>
            </Tabs.TabPane>
          )
        }
        return (
          <Tabs.TabPane tab={renderIndex(item.name)} closable={true} key={item.id}>
            <TabContentWrapper style={{ position: 'relative', background: 'white', height: '100%' }}>
              <IndexManagerDetails
                name={item.name}
                id={item.id}
                listTab={listTab}
                setListTab={setListTab}
                setActiveTab={setActiveTab}
              />
            </TabContentWrapper>
          </Tabs.TabPane>
        )
      })}
    </StyledTabs>
  )
}

export default IndexManager
