import { Row, notification } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import GroupTable from '../../Analysis/GroupTable'
import APIService from '../../../shared/api'
import { AnalysisType } from '../../../shared/api/models/Analysis'

type Props = {
  isOpen: boolean
  onRequestClose: () => void
  selectedFunds: string[]
}

const AddToModal: React.FC<Props> = ({ isOpen, onRequestClose, selectedFunds }) => {
  const [selectedGroup, setSelectedGroup] = React.useState<AnalysisType>()

  const [submitting, setSubmitting] = React.useState(false)

  const handleSubmit = () => {
    if (!selectedGroup) {
      notification.warn({ message: 'Please Select A Group!' })
      return
    }
    setSubmitting(true)
    APIService.analysisService
      .updateGroup({ groupCode: selectedGroup.group_code, fundIds: selectedFunds })
      .then(() => {
        notification.success({
          message: `Added ${selectedFunds.length} funds to group successfully!`,
        })
        onRequestClose()
      })
      .catch((err) => {
        notification.error({ message: `Failed to add funds to group ${selectedGroup}!` })
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <Modal
      title="Add To Group"
      visible={isOpen}
      onCancel={() => onRequestClose()}
      onOk={handleSubmit}
      confirmLoading={submitting}
    >
      <Row>
        <b>{selectedFunds.length} funds selected</b>
      </Row>
      <GroupTable isShownInModal onSelectGroup={setSelectedGroup} />
    </Modal>
  )
}

export default AddToModal
