import { Select } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/rootReducer'
import APIService from '../../../shared/api'
import { useServiceState } from '../../../shared/hooks/useServiceState'
import { updateFundSearchParams } from './SearchForm/redux/searchParamsSlice'

type Props = {}

const PeerGroupSearch: React.FC<Props> = () => {
  const { invoke: fetchPeerGroup, data: peerGroupData } = useServiceState(APIService.analysisService.getOwnerGroups)

  const { fundSearchParams: fundSearchParamsRedux } = useSelector((state: RootState) => state.searchParams)

  const dispatch = useDispatch()

  React.useEffect(() => {
    fetchPeerGroup()
  }, [fetchPeerGroup])

  return (
    <div style={{ flex: 1, display: 'flex', marginRight: 8, alignItems: 'center' }}>
      <span style={{ marginRight: 4 }}>Peer Group:</span>
      <Select
        mode="multiple"
        style={{ flex: 1 }}
        allowClear
        placeholder="Filter by Peer Group"
        value={fundSearchParamsRedux.peer_group_code?.value || undefined}
        onChange={(e) => {
          const selectedPeerGroups = e as string[]
          if (selectedPeerGroups.length > 0) {
            dispatch(
              updateFundSearchParams({
                ...fundSearchParamsRedux,
                peer_group_code: { value: selectedPeerGroups },
              }),
            )
          } else {
            const { peer_group_code: _, ...rest } = fundSearchParamsRedux
            dispatch(updateFundSearchParams(rest))
          }
        }}
        maxTagCount={2}
        options={peerGroupData
          ?.sort((a, b) => (a.group_name > b.group_name ? 1 : -1))
          .map((item) => ({
            value: item.group_code,
            label: `${item.group_name} (${item.group_code})`,
          }))}
        filterOption={(input, option: any) =>
          option.label && option.label && (option.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      ></Select>
    </div>
  )
}

export default PeerGroupSearch
