import Text from 'antd/lib/typography/Text'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Colors } from '../colors'
type Props = {
  text?: string | ReactNode
  notMargin?: boolean
}
const Flex = styled.div`
  flex: 1;
  display: flex;
`
export const DividerForm: React.FC<Props> = ({ text, notMargin }) => {
  return (
    <div style={{ margin: notMargin ? 0 : '1rem 0', display: 'flex' }}>
      <Flex style={{ alignItems: 'center' }}>
        <div>{text && <Text style={{ fontWeight: 'bold', marginRight: '1rem' }}>{text}</Text>}</div>
        <div style={{ flex: 1, height: 1, background: `${Colors.border}` }}></div>
      </Flex>
    </div>
  )
}
