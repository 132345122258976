import { AxiosInstance, AxiosPromise } from 'axios'
import { sanatizeToSnakeCase } from '../../strings'
import { UserProfileDetails } from '../models/ResponsesTypes'

export enum EventCodes {
  LOG_OUT_EVENT = 'LOG_OUT',
}
export interface SignUpParams {
  code: string
  firstName: string
  lastName: string
  email: string
  password: string
  passwordConfirm: string
  company: string
  jobTitle: string
  businessPhone: string
  address: string
  address2: string
  city: string
  state: string
  postalCode: string
  registrationCode: string
  fund_or_vehicle_name: string
  heard_about_hfr_from: string
  reports_fund_performance_to_hfr: boolean
  manager_link: boolean
}
export interface EventParams {
  code: EventCodes
  source: string
  user_agent: string
  os: string
  client_created_date: string
  fund_id: string
  url: string
  json_data: string
}
export interface AuthService {
  preLogin: (email: string, password: string) => AxiosPromise<{ disable_2fa: boolean }>
  login: (email: string, password: string, code: string, rememberMe: boolean) => AxiosPromise<{ token: string }>
  logout: () => AxiosPromise<void>
  signUp: (formData: Partial<SignUpParams>) => AxiosPromise<any>
  preSignUp: (email: string) => AxiosPromise<void>
  checkCode: (email: string, verificationCode: string) => AxiosPromise<void>
  requestPasswordReset: (email: string) => AxiosPromise<void>
  resetPassword: (email: string, code: string, newPassword: string) => AxiosPromise<void>
  updatePassword: ({ password, newPassword }: { password: string; newPassword: string }) => AxiosPromise<void>
  updateProfile: (params: Partial<UserProfileDetails>) => AxiosPromise<void>
  fetchUserProfile: () => AxiosPromise<UserProfileDetails>
  updateDbToMl: (
    email: string,
    password: string,
    fundName?: string,
    heardFrom?: string,
    isReportPerformance?: boolean,
  ) => AxiosPromise<void>
}

export const AuthService = (request: AxiosInstance): AuthService => {
  return {
    preLogin: (email, password) => request.post('/pre-login', { email, password, manager_link: true }),
    login: (email, password, code, rememberMe) =>
      request.post('/login', { email, password, code, remember_me: rememberMe, manager_link: true }),
    logout: () => request.post('/logout'),
    signUp: (formData) => request.post('/user', { ...sanatizeToSnakeCase(formData), manager_link: true }),
    preSignUp: (email) => request.post('/pre-sign-up', { email }),
    checkCode: (email, verificationCode) => request.post('/check-code', { email, code: verificationCode }),
    requestPasswordReset: (email) => request.post('/request-password-reset', { email }),
    resetPassword: (email, code, newPassword) =>
      request.post('/password-reset', {
        email,
        code,
        new_password: newPassword,
      }),
    updatePassword: ({ password, newPassword }) =>
      request.post('/manager-link/change-password', {
        password,
        new_password: newPassword,
      }),
    updateProfile: (params) => request.post('/user/update', params),
    fetchUserProfile: () => request.get('/me'),
    updateDbToMl: (email, password, fund_or_vehicle_name, heard_about_hfr_from, reports_fund_performance_to_hfr) =>
      request.post('/hfrdb-to-hfrml', {
        email,
        password,
        fund_or_vehicle_name,
        heard_about_hfr_from,
        reports_fund_performance_to_hfr,
      }),
  }
}
