import { Col, Form, Radio, Row, Select } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'
import { FundDetailDropdownContext } from '../../../shared/api/context/FundDetailDropdownContext'
import { FormItemDatePicker, FormItemInput } from '../../../shared/components/FormItemComponents'
import { RenderLabelExNewFund } from '../../../shared/components/RenderLabelExNewFund'
import { LeftNavExNewFundEnum } from '../../../shared/SearchExNewFundLabel'
import { NextBackNewFundTab } from './NextBackExNewFundTab'
const Flex = styled.div`
  display: flex;
  flex: 1;
  margin-top: 1rem;
  flex-direction: column;
  padding: 0 2rem;
`
const LIST_FORM_INPUT_BOTTOM = [
  {
    name: 'placement_agent',
    label: 'Placement Agent (Enter None, If Not Applicable)',
    maxLength: 60,
    rules: [
      { required: true, message: 'Placement Agent is required!' },
      {
        max: 60,
        message: 'Value should be less than 60 character',
      },
    ],
  },
  {
    label: 'Banking Agent (Enter None, If Not Applicable)',
    name: 'banking_agent',
    maxLength: 60,
    rules: [
      { required: true, message: 'Banking Agent is required!' },
      {
        max: 60,
        message: 'Value should be less than 60 character',
      },
    ],
  },
  {
    label: 'Custodian (Enter None, If Not Applicable)',
    name: 'custodian',
    maxLength: 60,
    rules: [
      { required: true, message: 'Custodian is required!' },
      {
        max: 60,
        message: 'Value should be less than 60 character',
      },
    ],
  },
  {
    label: 'Consultant (Enter None, If Not Applicable)',
    name: 'consultant',
    maxLength: 60,
    rules: [
      { required: true, message: 'Consultant is required!' },
      {
        max: 60,
        message: 'Value should be less than 60 character',
      },
    ],
  },
]
type Props = {
  setStepTab: (value: LeftNavExNewFundEnum) => void
  setActiveTab: (value: LeftNavExNewFundEnum) => void
  activeTab: LeftNavExNewFundEnum
  stepTab: LeftNavExNewFundEnum
  form: FormInstance
}

export const ExNewFundServiceProviders: React.FC<Props> = ({ form, setStepTab, setActiveTab, activeTab, stepTab }) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)

  const [isSpecifiedAuditorName, setIsSpecifiedAuditorName] = React.useState<boolean>(
    form.getFieldValue('auditor_name') === 'Other',
  )
  const [isSpecifiedLegalAdviser, setIsSpecifiedLegalAdviser] = React.useState<boolean>(
    form.getFieldValue('legal_adviser') === 'Other',
  )
  const [isSpecifiedPrimeBroker, setIsSpecifiedPrimeBroker] = React.useState<boolean>(
    form.getFieldValue('prime_broker') === 'Other',
  )
  const [isSpecifiedAdministrator, setIsSpecifiedAdministrator] = React.useState<boolean>(
    form.getFieldValue('administrator') === 'Other',
  )

  const ARRAY_FIELD_VALIDATE: string[] = React.useMemo(() => {
    const array = [
      'is_annual_performance_audit',
      'auditor_name',
      'legal_adviser',
      'administrator',
      'placement_agent',
      'banking_agent',
      'custodian',
      'consultant',
      'prime_broker',
    ]
    if (isSpecifiedAuditorName) {
      array.push('auditor_name_input')
    }
    if (isSpecifiedLegalAdviser) {
      array.push('legal_adviser_input')
    }
    if (isSpecifiedPrimeBroker) {
      array.push('prime_broker_input')
    }
    if (isSpecifiedAdministrator) {
      array.push('administrator_input')
    }
    return array
  }, [isSpecifiedAdministrator, isSpecifiedAuditorName, isSpecifiedLegalAdviser, isSpecifiedPrimeBroker])

  const optionsAuditorName =
    fundDataDropdown?.auditor_name && fundDataDropdown?.auditor_name.length > 0
      ? fundDataDropdown?.auditor_name.map((item) => ({ value: item, text: item }))
      : []

  const optionsLegalAdviser =
    fundDataDropdown?.legal_adviser && fundDataDropdown?.legal_adviser.length > 0
      ? fundDataDropdown?.legal_adviser.map((item) => ({ value: item, text: item }))
      : []
  const optionsPrimeBroker =
    fundDataDropdown?.prime_broker && fundDataDropdown?.prime_broker.length > 0
      ? fundDataDropdown?.prime_broker.map((item) => ({ value: item, text: item }))
      : []
  const optionsAdministrator =
    fundDataDropdown?.administrator && fundDataDropdown?.administrator.length > 0
      ? fundDataDropdown?.administrator.map((item) => ({ value: item, text: item }))
      : []

  React.useEffect(() => {
    if (!isSpecifiedAuditorName) {
      form.setFieldsValue({ auditor_name_input: null })
    }
    if (!isSpecifiedLegalAdviser) {
      form.setFieldsValue({ legal_adviser_input: null })
    }
    if (!isSpecifiedPrimeBroker) {
      form.setFieldsValue({ prime_broker_input: null })
    }
    if (!isSpecifiedAdministrator) {
      form.setFieldsValue({ administrator_input: null })
    }
  }, [form, isSpecifiedAdministrator, isSpecifiedAuditorName, isSpecifiedLegalAdviser, isSpecifiedPrimeBroker])

  return (
    <Flex>
      <Row>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <Form.Item
            name="is_annual_performance_audit"
            label={<RenderLabelExNewFund value="Annual Performance Audit" />}
            rules={[{ required: true, message: 'Annual Performance Audit is required!' }]}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <FormItemDatePicker
            name="last_audit_date"
            label={<RenderLabelExNewFund value="Last Audit Date" />}
            wrapperCol={16}
          />
        </Col>
      </Row>

      <Row>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <Form.Item
            name="auditor_name"
            label={<RenderLabelExNewFund value="Auditor Name" />}
            {...{ wrapperCol: { span: 16 } }}
            style={{ display: 'flex', flex: 1 }}
            rules={[
              {
                required: true,
                message: 'Auditor Name is required!',
              },
              ({ getFieldValue }) => ({
                validator() {
                  setIsSpecifiedAuditorName(getFieldValue('auditor_name') === 'Other')
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <Select showSearch optionFilterProp="children" allowClear>
              {optionsAuditorName.map((item) => {
                return (
                  <Select.Option value={item.value} key={item.value}>
                    {item.text}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24} style={{ fontStyle: 'italic' }}>
          <FormItemInput
            name="auditor_name_input"
            label={<RenderLabelExNewFund value="If Other, Specify Auditor Name" />}
            wrapperCol={16}
            rules={
              isSpecifiedAuditorName
                ? [
                    {
                      required: true,
                      message: 'Auditor Name is required!',
                    },
                  ]
                : undefined
            }
            disabled={!isSpecifiedAuditorName}
            placeholder="Auditor Name"
          />
        </Col>
      </Row>

      <Row>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <Form.Item
            name="legal_adviser"
            label={<RenderLabelExNewFund value="Legal Adviser" />}
            {...{ wrapperCol: { span: 16 } }}
            style={{ display: 'flex', flex: 1 }}
            rules={[
              {
                required: true,
                message: 'Legal Adviser is required!',
              },
              ({ getFieldValue }) => ({
                validator() {
                  setIsSpecifiedLegalAdviser(getFieldValue('legal_adviser') === 'Other')
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <Select showSearch optionFilterProp="children" allowClear>
              {optionsLegalAdviser.map((item) => {
                return (
                  <Select.Option value={item.value} key={item.value}>
                    {item.text}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24} style={{ fontStyle: 'italic' }}>
          <FormItemInput
            name="legal_adviser_input"
            label={<RenderLabelExNewFund value="If Other, Specify Legal Adviser" />}
            wrapperCol={16}
            disabled={!isSpecifiedLegalAdviser}
            placeholder="Legal Adviser"
            rules={
              isSpecifiedLegalAdviser
                ? [
                    {
                      required: true,
                      message: 'Legal Adviser is required!',
                    },
                  ]
                : undefined
            }
          />
        </Col>
      </Row>

      <Row>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <Form.Item
            name="prime_broker"
            label={<RenderLabelExNewFund value="Prime Broker" />}
            {...{ wrapperCol: { span: 16 } }}
            style={{ display: 'flex', flex: 1 }}
            rules={[
              { required: true, message: 'Prime Broker is required!' },
              ({ getFieldValue }) => ({
                validator() {
                  setIsSpecifiedPrimeBroker(getFieldValue('prime_broker') === 'Other')
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <Select showSearch optionFilterProp="children" allowClear>
              {optionsPrimeBroker.map((item) => {
                return (
                  <Select.Option value={item.value} key={item.value}>
                    {item.text}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24} style={{ fontStyle: 'italic' }}>
          <FormItemInput
            name="prime_broker_input"
            label={<RenderLabelExNewFund value="If Other, Specify Prime Broker" />}
            wrapperCol={16}
            maxLength={60}
            disabled={!isSpecifiedPrimeBroker}
            placeholder="Prime Broker"
            rules={
              isSpecifiedPrimeBroker
                ? [
                    {
                      required: true,
                      message: 'Prime Broker is required!',
                    },
                  ]
                : undefined
            }
          />
        </Col>
      </Row>

      <Row>
        <Col span={12} xl={12} md={16} xs={24} sm={24}>
          <Form.Item
            name="administrator"
            label={<RenderLabelExNewFund value="Administrator" />}
            {...{ wrapperCol: { span: 16 } }}
            style={{ display: 'flex', flex: 1 }}
            rules={[
              { required: true, message: 'Administrator is required!' },
              ({ getFieldValue }) => ({
                validator() {
                  setIsSpecifiedAdministrator(getFieldValue('administrator') === 'Other')
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <Select showSearch optionFilterProp="children" allowClear>
              {optionsAdministrator.map((item) => {
                return (
                  <Select.Option value={item.value} key={item.value}>
                    {item.text}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12} xl={12} md={16} xs={24} sm={24} style={{ fontStyle: 'italic' }}>
          <FormItemInput
            name="administrator_input"
            label={<RenderLabelExNewFund value="If Other, Specify Administrator" />}
            wrapperCol={16}
            disabled={!isSpecifiedAdministrator}
            placeholder="Administrator"
            rules={
              isSpecifiedAdministrator
                ? [
                    {
                      required: true,
                      message: 'Administrator is required!',
                    },
                  ]
                : undefined
            }
          />
        </Col>
      </Row>
      <Row>
        {LIST_FORM_INPUT_BOTTOM.map((item) => {
          return (
            <Col span={12} xl={12} md={16} xs={24} sm={24} key={item.name}>
              <FormItemInput
                name={item.name}
                wrapperCol={16}
                label={<RenderLabelExNewFund value={item.label} />}
                placeholder={item.label}
                rules={item.rules}
                maxLength={item.maxLength}
              />
            </Col>
          )
        })}
      </Row>
      <NextBackNewFundTab
        setStepTab={setStepTab}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        stepTab={stepTab}
        ArrayValidateField={ARRAY_FIELD_VALIDATE}
        form={form}
      />
    </Flex>
  )
}
