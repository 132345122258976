import moment from 'moment'
import React, { useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../app/rootReducer'
import { addDraft, removeDraft } from '../slice/appSettingsSlice'

export type DraftType = 'fundDraft' | 'firmDraft' | 'exFundDraft' | 'exFirmDraft'

export type ViewModeType = 'internal' | 'external'

export interface Snapshot {
  id: string
  name: string
  type?: DraftType
  snapshotId?: string
  data?: any
  userId?: string
  viewMode?: ViewModeType
  lastFieldChange?: string
  _lastFieldChanged?: string
}

type DraftManagerContextType = {
  saveSnapshot: (type: DraftType, data: Snapshot, viewMode?: ViewModeType, lastFieldChange?: string) => void
  loadSnapshot: (type: DraftType, id: string) => any | undefined
  clearSnapshot: (type: DraftType, id: string) => void
}
const initialValue: DraftManagerContextType = {
  clearSnapshot: () => undefined,
  loadSnapshot: () => undefined,
  saveSnapshot: () => undefined,
}
const DraftManagerContext = React.createContext<DraftManagerContextType>(initialValue)

export const DraftManagerProvider: React.FC<{ usage: 'internal' | 'external'; dateFields: string[] }> = ({
  usage,
  children,
  dateFields,
}) => {
  const { user } = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch()

  const getName = useCallback((type: string, id: string) => user && `${usage}_${type}_${btoa(user.email)}_${id}`, [
    usage,
    user,
  ])

  const saveSnapshot = useCallback(
    (type: DraftType, data: Snapshot, viewMode?: ViewModeType, lastFieldChange?: string) => {
      if (user && data.data) {
        const name = getName(type, data.id) || ''
        window.localStorage.setItem(name, JSON.stringify(data.data))
        dispatch(
          addDraft({
            type,
            data: {
              userId: user.email,
              name: data.name || data.id.replace('-', ' ').toUpperCase(),
              id: data.id,
              type,
              snapshotId: name,
              viewMode: viewMode,
              lastFieldChange,
            },
          }),
        )
      }
    },
    [dispatch, getName, user],
  )

  const loadSnapshot = useCallback(
    (type: DraftType, id: string) => {
      if (user) {
        const name = getName(type, id) || ''
        const rawData = window.localStorage.getItem(name)
        if (rawData) {
          const changedValues = JSON.parse(rawData)
          const convertObject = Object.keys(changedValues).reduce((acc, key) => {
            if (dateFields.includes(key)) {
              return { ...acc, [key]: moment(changedValues[key]) }
            }
            return { ...acc, [key]: changedValues[key] }
          }, {})
          return convertObject
        }
      }
      return
    },
    [getName, user],
  )

  const clearSnapshot = useCallback(
    (type: DraftType, id: string) => {
      if (user) {
        const name = getName(type, id) || ''
        window.localStorage.removeItem(name)
        dispatch(
          removeDraft({
            type,
            snapshotId: name,
          }),
        )
      }
    },
    [dispatch, getName, user],
  )
  return (
    <DraftManagerContext.Provider value={{ saveSnapshot, loadSnapshot, clearSnapshot }}>
      {children}
    </DraftManagerContext.Provider>
  )
}

export const useDraft = () => useContext(DraftManagerContext)
