import { DeleteTwoTone, EditOutlined } from '@ant-design/icons'
import { Button, notification, Popconfirm, Spin, Tooltip } from 'antd'
import Table, { ColumnProps } from 'antd/lib/table'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../../../shared/api'
import { FundDetailDataContext } from '../../../../shared/api/context/FundDetailDataContext'
import { PropertiesInfo } from '../../../../shared/api/models/FundDetails'
import { Colors } from '../../../../shared/colors'
import { NextBackButton } from '../../../../shared/components/NextBackButton'
import { useServiceState } from '../../../../shared/hooks/useServiceState'
import { ModalEditTableFundProperties } from './ModalEditTableFundProperties'
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  margin: 1rem;
  flex-direction: column;
`
export const FundProperties: React.FC = () => {
  const { dataFundDetail } = React.useContext(FundDetailDataContext)
  const { invoke: getFundProperties, data: dataFundProperties, loading } = useServiceState(
    APIService.fundDetailService.fetchFundProperties,
  )

  React.useEffect(() => {
    dataFundDetail && getFundProperties(dataFundDetail.fund_id)
  }, [dataFundDetail, getFundProperties])

  const [loadingDelete, setLoadingDelete] = React.useState(false)
  const [idDelete, setIdDelete] = React.useState<number | undefined>()
  const [showModal, setShowModal] = React.useState(false)
  const [selectedProperties, setSelectedProperties] = React.useState<PropertiesInfo | undefined>()

  const updateProperties = () => {
    dataFundDetail && getFundProperties(dataFundDetail.fund_id)
  }

  const deleteFundProperties = (prodId: number) => {
    if (dataFundDetail?.fund_id) {
      setLoadingDelete(true)
      APIService.fundDetailService
        .deleteFundProperties(dataFundDetail?.fund_id, prodId)
        .then(() => {
          notification.success({ message: 'Fund Property Delete!' })
          updateProperties()
        })
        .catch((err) => {
          console.error({ err })
          notification.error({ message: 'Fund Property Delete Failed!' })
        })
        .finally(() => {
          setLoadingDelete(false)
        })
    }
  }

  const columns: ColumnProps<PropertiesInfo>[] = [
    {
      title: 'ID',
      dataIndex: 'prop_id',
      key: 'prop_id',
      sorter: (a, b) => {
        return a.prop_id - b.prop_id
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Properties Value',
      dataIndex: 'prop_value',
      key: 'prop_value',

      sorter: (a, b) => {
        return a.prop_value.localeCompare(b.prop_value)
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Properties Name',
      dataIndex: 'prop_name',
      key: 'prop_name',

      sorter: (a, b) => {
        return a.prop_name.localeCompare(b.prop_name)
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <>
          {loadingDelete && record.prop_id === idDelete ? (
            <Spin />
          ) : (
            <>
              <Button
                type="link"
                style={{ padding: '4px 8px' }}
                onClick={() => {
                  setShowModal(true)
                  setSelectedProperties(record)
                }}
              >
                <Tooltip title="Edit">
                  <EditOutlined />
                </Tooltip>
              </Button>

              <Popconfirm
                title="Are you sure you want to delete?"
                onConfirm={() => {
                  deleteFundProperties(record.prop_id)
                  setIdDelete(record.prop_id)
                }}
              >
                <Button type="link" style={{ padding: '4px 8px' }}>
                  <Tooltip title="Delete">
                    <DeleteTwoTone twoToneColor={Colors.danger} />
                  </Tooltip>
                </Button>
              </Popconfirm>
            </>
          )}
        </>
      ),
    },
  ]
  return (
    <Wrapper>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="primary"
          style={{ marginBottom: 8, marginRight: 8 }}
          onClick={() => setShowModal(true)}
          disabled={loadingDelete}
        >
          Create New Fund Properties
        </Button>
      </div>
      <Table
        rowKey="prop_id"
        loading={loading}
        dataSource={dataFundProperties?.fund_properties}
        columns={columns}
        pagination={
          dataFundProperties?.fund_properties && dataFundProperties.fund_properties.length > 10 ? undefined : false
        }
        bordered
      />
      {showModal && dataFundDetail?.fund_id && (
        <ModalEditTableFundProperties
          isOpen={showModal}
          setIsOpen={setShowModal}
          selectedProperties={selectedProperties}
          setSelectedProperties={setSelectedProperties}
          fundId={dataFundDetail.fund_id}
          updateProperties={updateProperties}
          listProperties={dataFundProperties?.codes || []}
        />
      )}
      <NextBackButton />
    </Wrapper>
  )
}
