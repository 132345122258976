import { AxiosInstance, AxiosPromise } from 'axios'
import { CodeOfConductType } from '../models/CodeOfConduct'

export interface CodeOfConductService {
  getCodeOfConduct: () => AxiosPromise<CodeOfConductType>
  updateCodeOfConduct: (firmId: string, version: string, agreed?: boolean) => AxiosPromise<void>
}
export const CodeOfConductService = (request: AxiosInstance): CodeOfConductService => {
  return {
    getCodeOfConduct: () => request.get(`/manager-link/code-of-conduct`),
    updateCodeOfConduct: (firmId, version, agreed = true) =>
      request.post(`/manager-link/code-of-conduct`, { firm_id: firmId, version, agreed }),
  }
}
