import { Col, DatePicker, Form, notification, Row, Spin } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import moment from 'moment'
import React from 'react'
import APIService from '../../../../shared/api'
import { DueDiligenceMonthly, DueDiligenceMonthlyUpdate } from '../../../../shared/api/models/FundDetails'
import { DividerForm } from '../../../../shared/components/DividerForm'
import { FormItemInput, FormItemTextArea } from '../../../../shared/components/FormItemComponents'

type Props = {
  selectedMonthly: DueDiligenceMonthly | undefined
  showModal: boolean
  setShowModal: (value: boolean) => void
  setSelectedMonthly: (value: DueDiligenceMonthly | undefined) => void
  updateMonthly: () => void
  fundId: string
}

export const ModalEditTableMonthly: React.FC<Props> = ({
  selectedMonthly,
  showModal,
  setShowModal,
  setSelectedMonthly,
  updateMonthly,
  fundId,
}) => {
  const [loadingSubmit, setLoadingSubmit] = React.useState(false)
  const [form] = Form.useForm()

  React.useEffect(() => {
    if (selectedMonthly) {
      const initialValue = {
        ...selectedMonthly,
        username: selectedMonthly.oper_id,
        date: selectedMonthly.date ? moment(selectedMonthly.date) : null,
      }
      form.setFieldsValue(initialValue)
    } else {
      form.resetFields()
    }
  }, [form, selectedMonthly])

  const handleUpdateRow = () => {
    form.validateFields(['date', 'username']).then(() => {
      setLoadingSubmit(true)
      const submitData: Partial<DueDiligenceMonthlyUpdate> = {
        username: form.getFieldValue('username'),
        date: form.getFieldValue('date') ? moment(form.getFieldValue('date')).format('YYYY-MM') : undefined,
        monthly: form.getFieldValue('monthly'),
        slippage: form.getFieldValue('slippage'),
        internal: form.getFieldValue('internal'),
      }
      selectedMonthly
        ? APIService.fundDetailService
            .updateDueDiligenceMonthly(fundId, submitData)
            .then(() => {
              notification.success({ message: 'Monthly Updated!' })
              updateMonthly()
            })
            .catch((err) => {
              console.error({ err })
              notification.error({ message: 'Monthly Update Failed!' })
            })
            .finally(() => {
              setLoadingSubmit(false)
              setShowModal(false)
              setSelectedMonthly(undefined)
              form.resetFields()
            })
        : APIService.fundDetailService
            .createDueDiligenceMonthly(fundId, submitData)
            .then(() => {
              notification.success({ message: 'Monthly Created!' })
              updateMonthly()
            })
            .catch((err) => {
              console.error({ err })
              notification.error({ message: 'Monthly Create Failed!' })
            })
            .finally(() => {
              setLoadingSubmit(false)
              setShowModal(false)
              setSelectedMonthly(undefined)
              form.resetFields()
            })
    })
  }
  return (
    <Modal
      width="60vw"
      title={selectedMonthly ? 'Edit Monthly' : 'Create New Monthly'}
      visible={showModal}
      okText={
        loadingSubmit ? (
          <>
            <Spin style={{ marginRight: 4 }} />
          </>
        ) : (
          'OK'
        )
      }
      onOk={() => handleUpdateRow()}
      onCancel={() => {
        setSelectedMonthly(undefined)
        setShowModal(false)
      }}
    >
      <div>
        <DividerForm text="Monthly Information" />
        <Form layout="vertical" form={form}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item name="date" label="Date" rules={[{ required: true, message: 'Date is required' }]}>
                <DatePicker picker="month" />
              </Form.Item>
            </Col>
            {!selectedMonthly && (
              <Col span={24}>
                <FormItemInput
                  name="username"
                  label="User Name"
                  rules={[{ required: true, message: 'User Name is required' }]}
                />
              </Col>
            )}
            <Col span={24}>
              <FormItemTextArea name="monthly" label="Monthly" row={4} />
            </Col>
            <Col span={24}>
              <FormItemTextArea name="slippage" label="Slippage" row={4} />
            </Col>
            <Col span={24}>
              <FormItemTextArea name="internal" label="Internal" row={4} />
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
