import { Col, Form, notification, Row, Spin } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import APIService from '../../../../../shared/api'
import {
  DueDiligenceReceiveSepAct,
  DueDiligenceReceiveSepActUpdate,
} from '../../../../../shared/api/models/FirmDetails'
import { FormItemInput, FormItemSelect, FormItemTextArea } from '../../../../../shared/components/FormItemComponents'

type Props = {
  isShowModal: boolean
  setIsShowModal: (value: boolean) => void
  selectedReceiveMesseage: DueDiligenceReceiveSepAct | undefined
  setSelectedReceiveMesseage: (value: DueDiligenceReceiveSepAct | undefined) => void
  updateReceiveSepAct: () => void
  firmId: string
  fundId: string
}
export const RECORD_TYPE_OPTIONS = [
  {
    text: 'Letter of Intent',
    value: 'LOI',
  },
  {
    text: 'Signed TMA',
    value: 'TMA',
  },
  {
    text: 'Funded Account',
    value: 'FA',
  },
]
const LIST_FORM = [
  {
    name: 'prime_broker',
    label: 'Prime Broker',
    maxLength: 60,
  },
  {
    name: 'minimum',
    label: 'Minimum',
    typeField: 'number',
    normalize: 'float',
  },
  {
    name: 'target',
    label: 'Target',
    typeField: 'number',
    normalize: 'float',
  },
  {
    name: 'maximum',
    label: 'Maximum',
    typeField: 'number',
    normalize: 'float',
  },
  {
    name: 'reserved_capacity',
    label: 'Reserved Capacity',
    typeField: 'number',
    normalize: 'float',
  },
  {
    name: 'mgmt_fee',
    label: 'Management Fee',
    typeField: 'number',
    normalize: 'float',
  },
  {
    name: 'incentive',
    label: 'Incentive',
    typeField: 'number',
    normalize: 'float',
  },
  {
    name: 'hurdle_rate',
    label: 'Hurdle Rate',
    maxLength: 30,
  },
  {
    name: 'liquidity',
    label: 'Liquidity',
    typeField: 'number',
    normalize: 'number',
  },
]
export const ReceiveSepActModal: React.FC<Props> = ({
  isShowModal,
  setIsShowModal,
  updateReceiveSepAct,
  firmId,
  setSelectedReceiveMesseage,
  selectedReceiveMesseage,
  fundId,
}) => {
  const [loadingSubmit, setLoadingSubmit] = React.useState(false)

  const [form] = Form.useForm()
  React.useEffect(() => {
    if (selectedReceiveMesseage) {
      const initialForm = {
        ...selectedReceiveMesseage,
        record_type: selectedReceiveMesseage.rec_type,
      }
      form.setFieldsValue(initialForm)
    } else {
      form.resetFields()
    }
  }, [form, selectedReceiveMesseage])

  const submitCreateSentMess = () => {
    const submitData: Partial<DueDiligenceReceiveSepActUpdate> = selectedReceiveMesseage
      ? {
          fund_id: fundId,
          record_type: form.getFieldValue('record_type'),
          prime_broker: form.getFieldValue('prime_broker'),
          minimum: form.getFieldValue('minimum'),
          target: form.getFieldValue('target'),
          maximum: form.getFieldValue('maximum'),
          reserved_capacity: form.getFieldValue('reserved_capacity'),
          mgmt_fee: form.getFieldValue('mgmt_fee'),
          incentive: form.getFieldValue('incentive'),
          hurdle_rate: form.getFieldValue('hurdle_rate'),
          liquidity: form.getFieldValue('liquidity'),
          comment: form.getFieldValue('comment'),
        }
      : {
          fund_id: fundId,
          record_type: form.getFieldValue('record_type'),
        }

    form.validateFields(['record_type']).then(() => {
      setLoadingSubmit(true)
      selectedReceiveMesseage
        ? APIService.firmDetailService
            .updateDueDiligenceReceiveMess(firmId, submitData)
            .then(() => {
              notification.success({ message: 'Receive Message Updated!' })
              updateReceiveSepAct()
            })
            .catch((err) => {
              console.error({ err })
              notification.error({ message: 'Receive Message Update Failed!' })
            })
            .finally(() => {
              setLoadingSubmit(false)
              setIsShowModal(false)
              setSelectedReceiveMesseage(undefined)
              form.resetFields()
            })
        : APIService.firmDetailService
            .createDueDiligenceReceiveMess(firmId, submitData)
            .then(() => {
              notification.success({ message: 'Receive Message Created!' })
              updateReceiveSepAct()
            })
            .catch((err) => {
              console.error({ err })
              notification.error({ message: 'Receive Message Create Failed!' })
            })
            .finally(() => {
              setLoadingSubmit(false)
              setIsShowModal(false)
              setSelectedReceiveMesseage(undefined)
              form.resetFields()
            })
    })
  }
  return (
    <Modal
      visible={isShowModal}
      title={selectedReceiveMesseage ? 'Update Receive Message' : 'Create Receive Message'}
      okText={
        loadingSubmit ? (
          <>
            <Spin style={{ marginRight: 4 }} />
          </>
        ) : (
          'OK'
        )
      }
      onCancel={() => {
        setIsShowModal(false)
        setSelectedReceiveMesseage(undefined)
      }}
      onOk={submitCreateSentMess}
    >
      <div>
        <Form layout="vertical" form={form}>
          <Row gutter={8}>
            <Col span={selectedReceiveMesseage ? 12 : 24}>
              <FormItemSelect
                name="record_type"
                rules={[{ required: true, message: 'Record Type is required' }]}
                label="Record Type"
                options={RECORD_TYPE_OPTIONS}
              />
            </Col>
            {selectedReceiveMesseage &&
              LIST_FORM.map((item) => {
                return (
                  <Col span={12} key={item.name}>
                    <FormItemInput
                      name={item.name}
                      label={item.label}
                      maxLength={item.maxLength}
                      typeField={item.typeField}
                      normalize={item.normalize}
                    />
                  </Col>
                )
              })}
            <Col span={24}>
              {selectedReceiveMesseage && <FormItemTextArea name="comment" label="Comment" row={3} />}
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
