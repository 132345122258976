import { Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import moment from 'moment'
import React from 'react'
import { FundDetailDataContext, PERFORMANCE_REQUEST_ENUM } from '../../../../shared/api/context/FundDetailDataContext'
import DataSheet, { DataSheetType } from '../../../../shared/components/DataSheet'
import { DATE_FORMAT } from '../../../Dashboard/FundDetail/FundROR/PerformanceUpdate'

type Props = {
  form: FormInstance
}
export const ExPerformanceNAV: React.FC<Props> = ({ form }) => {
  const {
    dataNAV,
    dataNAVLoading,
    setNAVDataChange,
    getPerformanceRequest,
    navDataChange,
    dataFundDetail,
  } = React.useContext(FundDetailDataContext)

  React.useEffect(() => {
    getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.NAV)
  }, [getPerformanceRequest])

  const isFundActive = form.getFieldValue('fund_status_code') === '2'

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <DataSheet
        currencyValue={dataFundDetail?.denomination ? dataFundDetail.denomination : undefined}
        // isReadOnly={fundStatusField ? true : undefined}
        minDate={
          dataFundDetail?.inception_date
            ? moment(dataFundDetail.inception_date).subtract(1, 'month').format(DATE_FORMAT)
            : undefined
        }
        type={DataSheetType.NAV}
        draft={navDataChange.values}
        initialData={dataNAV}
        canAdd
        loading={dataNAVLoading}
        setRORDataChange={setNAVDataChange}
        isExternal
      />
      {isFundActive && (
        <Row style={{ marginTop: 8 }}>
          <div style={{ fontStyle: 'italic' }}>
            <b>Note:</b> Contact HFR at <a href="mailto:performance@hfr.com">performance@hfr.com</a> if any questions.
          </div>
        </Row>
      )}
    </div>
  )
}
