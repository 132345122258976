import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../app/rootReducer'
import APIService from '../../../../shared/api'
import { FundSearchColumnSourceResponse } from '../../../../shared/api/models/Funds'
import { useServiceState } from '../../../../shared/hooks/useServiceState'
import { updateFundSearchColumns } from '../../../../slice/appSettingsSlice'
import { FundSearchColumnPropsExtended } from './FundSearchColumnSource'

export type FundSearchAllColumnContextType = {
  dataAllColumns: FundSearchColumnSourceResponse | undefined
  defaultColumns: FundSearchColumnPropsExtended[]
  loading: boolean
}

const defaultValue: FundSearchAllColumnContextType = {
  dataAllColumns: undefined,
  defaultColumns: [],
  loading: true,
}

const DEFAULT_COLUMNS = [
  'fund_id',
  'fund_name',
  'firm_id',
  'firm_name',
  'fund_status_code',
  'latest_ror',
  'latest_ror_date',
  'fund_assets',
  'fund_assets_date',
  'is_fund_esg',
  'is_firm_esg',
]

export const FundSearchColumnContext = React.createContext<FundSearchAllColumnContextType>(defaultValue)
export const FundSearchColumnProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch()
  const { invoke: getAllColumns, data: dataAllColumns, loading: allColumnLoading } = useServiceState<
    FundSearchColumnSourceResponse,
    undefined
  >(APIService.fundService.fetchAllField)

  const { fundSearchColumns } = useSelector((state: RootState) => state.appSettings)

  React.useEffect(() => {
    getAllColumns()
  }, [getAllColumns])

  const defaultColumns: FundSearchColumnPropsExtended[] | undefined = React.useMemo(() => {
    if (!dataAllColumns) {
      return []
    }
    return DEFAULT_COLUMNS.map((defaultKey) => ({
      ...dataAllColumns[defaultKey],
      key: defaultKey,
      title: dataAllColumns[defaultKey].human_readable_name,
      sorter: true,
      dataIndex: defaultKey,
    }))
  }, [dataAllColumns])

  useEffect(() => {
    if (fundSearchColumns.length > 0) {
      return
    }
    if (defaultColumns.length) {
      dispatch(updateFundSearchColumns(defaultColumns.map((item) => item.key)))
    }
  }, [defaultColumns, dispatch, fundSearchColumns.length])

  const value = {
    dataAllColumns,
    loading: allColumnLoading,
    defaultColumns,
  }
  return <FundSearchColumnContext.Provider value={value}>{children}</FundSearchColumnContext.Provider>
}
