import React from 'react'
import styled from 'styled-components'
import { FundDetailDropdownContext } from '../../../../shared/api/context/FundDetailDropdownContext'
import { DividerForm } from '../../../../shared/components/DividerForm'
import {
  FormItemCheckBox,
  FormItemInput,
  FormItemSelect,
  FormItemTextArea,
} from '../../../../shared/components/FormItemComponents'
import { RenderLabelFormFirm } from '../../../../shared/components/RenderLabelFormFirm'
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 1rem 3rem;
  flex-direction: column;
`

export const FirmSecInfo: React.FC = () => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)
  return (
    <Wrapper>
      <FormItemCheckBox name="sec_registered" text={<RenderLabelFormFirm value="Registered" />} />
      <div style={{ display: 'flex' }}>
        <FormItemInput
          name="sec_number"
          label={<RenderLabelFormFirm value="SEC Number" />}
          labelCol={10}
          wrapperCol={14}
          maxLength={10}
        />
        <FormItemSelect
          name="sec_country"
          label={<RenderLabelFormFirm value="Country" />}
          labelCol={10}
          wrapperCol={14}
          allowClear
          options={
            fundDataDropdown?.sec_country && fundDataDropdown.sec_country.length > 0
              ? fundDataDropdown.sec_country.map((item) => ({
                  value: item.code_code,
                  text: item.code_description,
                }))
              : []
          }
        />
      </div>
      <div style={{ display: 'flex' }}>
        <FormItemInput
          name="iard_crd_number"
          label={<RenderLabelFormFirm value="IARD-CRD Number" />}
          labelCol={10}
          wrapperCol={14}
          maxLength={6}
        />
        <FormItemSelect
          name="sec_state"
          label={<RenderLabelFormFirm value="State" />}
          labelCol={10}
          wrapperCol={14}
          allowClear
          options={
            fundDataDropdown?.sec_state && fundDataDropdown.sec_state.length > 0
              ? fundDataDropdown.sec_state.map((item) => ({
                  value: item.code_code,
                  text: item.code_description,
                }))
              : []
          }
        />
      </div>
      <FormItemSelect
        name="organization_type"
        label={<RenderLabelFormFirm value="Org. Type" />}
        labelCol={5}
        wrapperCol={7}
        allowClear
        options={
          fundDataDropdown?.organization_type && fundDataDropdown.organization_type.length > 0
            ? fundDataDropdown.organization_type.map((item) => ({
                value: item.code_code,
                text: item.code_description,
              }))
            : []
        }
      />
      <FormItemTextArea
        name="org_desc"
        labelCol={24}
        wrapperCol={24}
        label="Org. Type Description"
        row={4}
        maxLength={200}
      />
      <DividerForm />
      <FormItemSelect
        name="sec_exemption_reason_type"
        label={<RenderLabelFormFirm value="Exempt. Reason" />}
        labelCol={5}
        wrapperCol={10}
        allowClear
        options={
          fundDataDropdown?.sec_exemption_reason_type && fundDataDropdown.sec_exemption_reason_type.length > 0
            ? fundDataDropdown.sec_exemption_reason_type.map((item) => ({
                value: item.code_code,
                text: item.code_description,
              }))
            : []
        }
      />
      <FormItemTextArea
        label="Exempt. Reason Description"
        labelCol={24}
        wrapperCol={24}
        name="sec_exemp_reason_desc"
        row={4}
        maxLength={200}
      />
    </Wrapper>
  )
}
