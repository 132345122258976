import { notification, Row } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import Table, { ColumnProps } from 'antd/lib/table'
import React from 'react'
import APIService from '../../../shared/api'
import { IndexManagerType } from '../../../shared/api/models/IndexManager'
import { useServiceState } from '../../../shared/hooks/useServiceState'

type Props = {
  isOpen: boolean
  onRequestClose: () => void
  selectedFunds: string[]
}

const AddToIndexModal: React.FC<Props> = ({ isOpen, onRequestClose, selectedFunds }) => {
  const { invoke: getAllIndex, data: dataIndexManager, loading: loadingIndexManager } = useServiceState<
    IndexManagerType[],
    undefined
  >(APIService.indexManagerService.getAllIndexManager)

  const [selectedIndex, setSelectedIndex] = React.useState<number | undefined>(undefined)
  const [submitting, setSubmitting] = React.useState(false)

  React.useEffect(() => {
    getAllIndex()
  }, [getAllIndex])

  const handleSubmit = () => {
    if (!selectedIndex) {
      onRequestClose()
      return
    }
    setSubmitting(true)
    APIService.indexManagerService
      .updateIndexManagerDetail(selectedIndex, { fund_ids: selectedFunds })
      .then(() => {
        notification.success({
          message: `Added ${selectedFunds.length} funds to Index successfully!`,
        })
        onRequestClose()
      })
      .catch((err) => {
        notification.error({ message: `Failed to add funds to Index!` })
      })
      .finally(() => setSubmitting(false))
  }

  const columns: ColumnProps<IndexManagerType>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',

      sorter: (a, b) => {
        if (a.id && b.id) {
          return a.id - b.id
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name)
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },
  ]

  const onSelectChange = (selectedRowKeys: number[]) => {
    setSelectedIndex(selectedRowKeys[0])
  }

  return (
    <Modal
      title="Add To Index"
      visible={isOpen}
      onCancel={() => onRequestClose()}
      onOk={handleSubmit}
      confirmLoading={submitting}
    >
      <Row>
        <b>{selectedFunds.length} funds selected</b>
      </Row>
      <Table
        rowKey="id"
        rowSelection={{
          type: 'radio',
          onChange: (selectedRowKeys) => {
            onSelectChange(selectedRowKeys as number[])
          },
        }}
        dataSource={dataIndexManager}
        columns={columns}
        loading={loadingIndexManager}
      />
    </Modal>
  )
}

export default AddToIndexModal
