import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Route, Router, Switch } from 'react-router-dom'
import './App.css'
import ProtectedRoute from './ProtectedRoute'
import './react-datasheet.css'
import Auth from './routes/Auth/Auth'
import FallbackUI from './shared/components/FallbackUI'
import { history } from './shared/history'
import Rollbar from 'rollbar'

const App: React.FC = () => {
  const rollbarAccessToken = process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN
  if (rollbarAccessToken) {
    new Rollbar({
      accessToken: rollbarAccessToken,
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        environment: 'production',
      },
    })
  }

  return (
    <Router history={history}>
      <ErrorBoundary FallbackComponent={FallbackUI}>
        <Switch>
          <Route path="/auth" component={Auth} />
          <Route path="/" component={ProtectedRoute} />
        </Switch>
      </ErrorBoundary>
    </Router>
  )
}

export default App
