import {
  ChromeOutlined,
  ContainerOutlined,
  FileTextOutlined,
  FileZipOutlined,
  HomeOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Col, Form, Modal, notification, Row, Spin } from 'antd'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../../../../shared/api'
import { FundDetailDropdownContext } from '../../../../../shared/api/context/FundDetailDropdownContext'
import { DueDiligenceContact } from '../../../../../shared/api/models/FundDetails'
import { FormItemInput, FormItemSelect } from '../../../../../shared/components/FormItemComponents'

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0 1.5rem;
    overflow: auto;
  }
`
type Props = {
  selectedContact: DueDiligenceContact | undefined
  showModal: boolean
  setShowModal: (value: boolean) => void
  setSelectedContact: (value: DueDiligenceContact | undefined) => void
  updateContact: () => void
  firmId: string
}
export const ModalEditTableContact: React.FC<Props> = ({
  selectedContact,
  showModal,
  setShowModal,
  setSelectedContact,
  updateContact,
  firmId,
}) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)
  const [loadingSubmit, setLoadingSubmit] = React.useState(false)
  const [isEditedContactName, setIsEditedContactName] = React.useState(false)
  const [form] = Form.useForm()

  React.useEffect(() => {
    if (selectedContact) {
      form.setFieldsValue(selectedContact)
    } else {
      form.resetFields()
    }
  }, [form, selectedContact])

  const handleUpdateRow = () => {
    const fieldSubmit = {
      old_contact_name: form.getFieldValue('contact_name'),
      first_name: form.getFieldValue('first_name'),
      last_name: form.getFieldValue('last_name'),
      email: form.getFieldValue('email'),
      title: form.getFieldValue('title'),
      department: form.getFieldValue('department'),
      phone: form.getFieldValue('phone'),
      fax: form.getFieldValue('fax'),
      web: form.getFieldValue('web'),
      city: form.getFieldValue('city'),
      state_code: form.getFieldValue('state_code'),
      zip: form.getFieldValue('zip'),
      country: form.getFieldValue('country'),
      address1: form.getFieldValue('address1'),
      address2: form.getFieldValue('address2'),
      address3: form.getFieldValue('address3'),
      description: form.getFieldValue('description'),
    }
    const submitData: Partial<DueDiligenceContact & { old_contact_name: string; new_contact_name: string }> =
      selectedContact && isEditedContactName
        ? {
            ...fieldSubmit,
            new_contact_name: form.getFieldValue('contact_name'),
            old_contact_name: selectedContact.contact_name,
          }
        : fieldSubmit

    form.validateFields(['contact_name']).then(() => {
      setLoadingSubmit(true)
      selectedContact
        ? APIService.firmDetailService
            .updateDueDiligenceContact(firmId, submitData)
            .then(() => {
              notification.success({ message: 'Contact Updated!' })
              updateContact()
            })
            .catch((err) => {
              console.error({ err })
              notification.error({ message: 'Contact Update Failed!' })
            })
            .finally(() => {
              setLoadingSubmit(false)
              setShowModal(false)
              setSelectedContact(undefined)
              form.resetFields()
              setIsEditedContactName(false)
            })
        : APIService.firmDetailService
            .createDueDiligenceContact(firmId, submitData)
            .then(() => {
              notification.success({ message: 'Contact Created!' })
              updateContact()
            })
            .catch((err) => {
              console.error({ err })
              notification.error({ message: 'Contact Create Failed!' })
            })
            .finally(() => {
              setLoadingSubmit(false)
              setShowModal(false)
              setSelectedContact(undefined)
              form.resetFields()
              setIsEditedContactName(false)
            })
    })
  }

  const LIST_FIELD = [
    {
      name: 'address1',
      label: 'Address 1',
      placeHolder: 'Address 1',
      prefix: <HomeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
      maxLength: 60,
    },
    {
      name: 'first_name',
      label: 'First Name',
      placeHolder: 'First Name',
      prefix: <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
      maxLength: 60,
    },
    {
      name: 'address2',
      label: 'Address 2',
      placeHolder: 'Address 2',
      prefix: <HomeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
      maxLength: 60,
    },
    {
      name: 'last_name',
      label: 'Last Name',
      placeHolder: 'Last Name',
      prefix: <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
      maxLength: 60,
    },
    {
      name: 'address3',
      label: 'Address 3',
      placeHolder: 'Address 3',
      prefix: <HomeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
      maxLength: 60,
    },
    {
      name: 'title',
      label: 'Title',
      placeHolder: 'Title',
      prefix: <MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
      maxLength: 60,
    },
    {
      name: 'city',
      label: 'City',
      placeHolder: 'City',
      prefix: <HomeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
      maxLength: 30,
    },
    {
      name: 'state_code',
      label: 'State',
      placeHolder: 'State',
      options:
        fundDataDropdown?.contact_state && fundDataDropdown.contact_state.length > 0
          ? fundDataDropdown.contact_state.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
    },
    {
      name: 'country',
      label: 'Country',
      placeHolder: 'Country',
      options:
        fundDataDropdown?.contact_country && fundDataDropdown.contact_country.length > 0
          ? fundDataDropdown.contact_country.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
    },
    {
      name: 'department',
      label: 'Department',
      placeHolder: 'Department',
      prefix: <ContainerOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
      maxLength: 60,
    },
    {
      name: 'zip',
      label: 'Zip',
      placeHolder: 'Zip',
      prefix: <FileZipOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
      maxLength: 10,
    },
    {
      name: 'phone',
      label: 'Phone',
      placeHolder: 'Phone',
      prefix: <PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
      maxLength: 30,
    },
    {
      name: 'email',
      label: 'E-mail',
      placeHolder: 'E-mail',
      prefix: <MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
      maxLength: 60,
    },
    {
      name: 'fax',
      label: 'Fax',
      placeHolder: 'Fax',
      prefix: <PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
      maxLength: 60,
    },
    {
      name: 'web',
      label: 'Web',
      placeHolder: 'Web',
      prefix: <ChromeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
      maxLength: 60,
    },
  ]

  return (
    <StyledModal
      title={selectedContact ? 'Edit Contact Due Diligence' : 'Create New Contact Due Diligence'}
      visible={showModal}
      width={800}
      okText={
        loadingSubmit ? (
          <>
            <Spin style={{ marginRight: 4 }} />
          </>
        ) : (
          'OK'
        )
      }
      onOk={() => handleUpdateRow()}
      onCancel={() => {
        setSelectedContact(undefined)
        setShowModal(false)
      }}
    >
      <div style={{ marginTop: '1rem' }}>
        <Form layout="horizontal" form={form}>
          <Row>
            <Col span={12}>
              <FormItemInput
                name="contact_name"
                label="Contact Name"
                placeholder="Contact Name"
                labelCol={8}
                wrapperCol={16}
                rules={[
                  { required: true, message: 'Contact Name is required' },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('old_contact_name') !== selectedContact?.contact_name) {
                        setIsEditedContactName(true)
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                maxLength={60}
              />
            </Col>
            {LIST_FIELD.map((item) => {
              return item.options ? (
                <Col span={12} key={item.name}>
                  <FormItemSelect
                    name={item.name}
                    label={item.label}
                    placeholder={item.placeHolder}
                    options={item.options}
                    labelCol={8}
                    wrapperCol={16}
                  />
                </Col>
              ) : (
                <Col span={12} key={item.name}>
                  <FormItemInput
                    name={item.name}
                    label={item.label}
                    labelCol={8}
                    wrapperCol={16}
                    prefix={item.prefix}
                    placeholder={item.placeHolder}
                    maxLength={item.maxLength}
                  />
                </Col>
              )
            })}
          </Row>

          <Row>
            <Col span={24}>
              <FormItemInput
                name="description"
                label="Description"
                placeholder="Description"
                prefix={<FileTextOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                maxLength={60}
                labelCol={4}
                wrapperCol={20}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </StyledModal>
  )
}
