import React from 'react'

type Props = {
  loading?: boolean
  submitForm?: boolean
}
export const NextBackButton: React.FC<Props> = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
      {/*<Button*/}
      {/*  onClick={() => {*/}
      {/*    console.log('back')*/}
      {/*  }}*/}
      {/*  style={{ marginRight: 8 }}*/}
      {/*>*/}
      {/*  Back*/}
      {/*</Button>*/}
      {/*<Button*/}
      {/*  htmlType={submitForm ? 'submit' : undefined}*/}
      {/*  type="primary"*/}
      {/*  loading={loading ? loading : undefined}*/}
      {/*  style={{ marginBottom: '1rem' }}*/}
      {/*>*/}
      {/*  Next*/}
      {/*</Button>*/}
    </div>
  )
}
