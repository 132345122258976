import { Row } from 'antd'
import React from 'react'
import { FirmDetailDataContext } from '../../../../shared/api/context/FirmDetailDataContext'
import DataSheet, { DataSheetType, RORResponseType, UpdateRORType } from '../../../../shared/components/DataSheet'
import { DividerForm } from '../../../../shared/components/DividerForm'
import { NextBackButton } from '../../../../shared/components/NextBackButton'

type Props = {
  dataFirmAsset: RORResponseType[] | undefined
  dataLoadingFirmAssets: boolean
  firmAssetsSubmitting: boolean
  firmAssetDataChange: UpdateRORType
  setFirmAssetsDataChange: (value: UpdateRORType) => void
  firmAssetsHFDataChange: UpdateRORType
  dataFirmAssetHF: RORResponseType[] | undefined
  loadingFirmAssetHF: boolean
  submittingFirmAssetHF: boolean
  onUpdateHFData: (value: UpdateRORType) => void
}
export const FirmAssets: React.FC<Props> = ({
  dataFirmAsset,
  dataLoadingFirmAssets,
  firmAssetDataChange,
  setFirmAssetsDataChange,
  firmAssetsSubmitting,
  firmAssetsHFDataChange,
  dataFirmAssetHF,
  loadingFirmAssetHF,
  submittingFirmAssetHF,
  onUpdateHFData,
}) => {
  const { dataFirmDetail } = React.useContext(FirmDetailDataContext)
  return (
    <Row style={{ padding: '1rem', display: 'flex', flexDirection: 'column' }}>
      <DividerForm text="Total Firm Assets (MM)" />
      <DataSheet
        type={DataSheetType.FIRM_ASSETS}
        draft={firmAssetDataChange.values}
        currencyValue={dataFirmDetail?.denomination}
        initialData={dataFirmAsset}
        loading={dataLoadingFirmAssets || firmAssetsSubmitting}
        canAdd
        setRORDataChange={setFirmAssetsDataChange}
        minDate={dataFirmDetail?.founded ? `${dataFirmDetail.founded}-01` : '2016-01'}
      />
      <DividerForm text="Total Firm Assets Dedicated To Hedge Funds (MM)" />
      <DataSheet
        currencyValue={dataFirmDetail?.denomination ? dataFirmDetail.denomination : undefined}
        type={DataSheetType.FIRM_ASSETS_HF}
        draft={firmAssetsHFDataChange.values}
        initialData={dataFirmAssetHF}
        loading={loadingFirmAssetHF || submittingFirmAssetHF}
        canAdd
        setRORDataChange={onUpdateHFData}
        minDate={dataFirmDetail?.founded ? `${dataFirmDetail.founded}-01` : '2016-01'}
      />
      <NextBackButton />
    </Row>
  )
}
