import React from 'react'
import { ListSearchExFirmType } from '../../SearchExFirmLabel'

const defaultValue = {
  boldText: '',
  filterArr: [] as ListSearchExFirmType[],
  isFiltering: false,
}
type Props = {
  text: string
  filterArr: ListSearchExFirmType[]
}
export const ExFirmDetailLabelSearchContext = React.createContext(defaultValue)
export const ExFirmDetailLabelSearchProvider: React.FC<Props> = ({ children, text, filterArr }) => {
  const isFiltering = text === '' ? false : true
  const value = {
    boldText: text,
    filterArr,
    isFiltering,
  }
  return <ExFirmDetailLabelSearchContext.Provider value={value}>{children}</ExFirmDetailLabelSearchContext.Provider>
}
