import { FormInstance } from 'antd/lib/form'
import { LeftNavExFundEnum } from '../../shared/SearchExFundLabel'

export type RequiredFieldType = Record<LeftNavExFundEnum, string[]>

type UpdateFieldsType = Partial<
  {
    [key in LeftNavExFundEnum]: string[]
  }
>

export class RequiredDataHelper {
  private static instance: RequiredDataHelper

  public requiredFields: Partial<RequiredFieldType> = {}

  public registerRequirement(key: LeftNavExFundEnum, fieldNames: string[]) {
    this.requiredFields = { ...this.requiredFields, [key]: fieldNames }
  }
  public addRequiredFields(fields: UpdateFieldsType) {
    Object.keys(fields).map((key) => {
      const leftNavKey = key as LeftNavExFundEnum
      const listField = this.requiredFields[leftNavKey]
      this.requiredFields[leftNavKey] = [
        ...(this.requiredFields[leftNavKey] || []),
        ...(fields[leftNavKey]?.filter((item) => listField && !listField.includes(item)) || []),
      ]
    })
  }

  public removeRequiredFields(fields: UpdateFieldsType) {
    Object.keys(fields).map((key) => {
      const leftNavKey = key as LeftNavExFundEnum
      const listFieldInLeftNav = fields[leftNavKey]
      this.requiredFields[leftNavKey] = [
        ...(this.requiredFields[leftNavKey]?.filter(
          (item) => listFieldInLeftNav && !listFieldInLeftNav.includes(item),
        ) || []),
      ]
    })
  }

  public isValidateField(key: LeftNavExFundEnum, fieldNames: string) {
    return this.requiredFields[key]?.includes(fieldNames)
  }

  public checkFormData(form: FormInstance) {
    return Object.keys(this.requiredFields).reduce<Partial<RequiredFieldType>>((s, key) => {
      const ckey = key as keyof RequiredFieldType
      const formData = form.getFieldsValue(this.requiredFields[ckey])
      const errorData = Object.keys(formData).filter(
        (fieldName) => formData[fieldName] === null || formData[fieldName] === undefined,
      )
      s[ckey] = errorData
      return s
    }, {})
  }

  static default() {
    if (!this.instance) {
      this.instance = new RequiredDataHelper()
    }
    return this.instance
  }
}
