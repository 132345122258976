export enum LeftNavExFundEnum {
  FUND_OVERVIEW = 'Overview',
  FUND_DETAIL = 'Fund Detail',
  INVESTMENT_STRATEGY = 'Investment Strategy',
  FEE_SCHEDULE = 'Fee Schedule',
  SERVICE_PROVIDERS = 'Service Providers',
  FUND_STRUCTURE = 'Fund Structure',
  FUND_ESG_FACTORS = 'Fund ESG Factors',
  FUND_DIVERITY_INCLUSION = 'Fund Diversity & Inclusion',
  FUND_PRINCIPALS = 'Fund Principals',
  FUND_CONTACT = 'Fund Contact',
  FUND_PERFORMANCE = 'Fund Performance',
  INSTRUMENTS = 'Instruments',
  REGIONS = 'Regions',
  INDUSTRY = 'Industry',
  INVESTOR_TYPE = 'Investor Type',
  FUND_CMIC = 'Fund CMIC',
  FUND_FAMILY = 'Fund Family',
}
export interface ListSearchExFundType {
  key: string
  name: string
  leftNav: LeftNavExFundEnum
}
export const SEARCH_FIELD_INDEX: ListSearchExFundType[] = [
  { key: 'liquid_alt_product', name: 'Liquid Alt Product', leftNav: LeftNavExFundEnum.FUND_DETAIL },
  {
    key: 'liquid_alt_type',
    name: 'Liquid alternative product type',
    leftNav: LeftNavExFundEnum.FUND_DETAIL,
  },
  {
    key: 'liquid_alt_region',
    name: 'Country of Registration',
    leftNav: LeftNavExFundEnum.FUND_DETAIL,
  },
  {
    key: 'product_class',
    name: 'Fund Share Class',
    leftNav: LeftNavExFundEnum.FUND_DETAIL,
  },
  {
    key: 'firm_assets',
    name: 'Firm Assets AUD (MM)',
    leftNav: LeftNavExFundEnum.FUND_DETAIL,
  },
  {
    key: 'fund_assets',
    name: 'Fund Assets',
    leftNav: LeftNavExFundEnum.FUND_DETAIL,
  },
  {
    key: 'firm_assets_date',
    name: 'Firm Assets Date',
    leftNav: LeftNavExFundEnum.FUND_DETAIL,
  },
  {
    key: 'inception_date',
    name: 'Inception Date',
    leftNav: LeftNavExFundEnum.FUND_DETAIL,
  },
  {
    key: 'ucitsiii',
    name: 'UCITS Compliant?',
    leftNav: LeftNavExFundEnum.FUND_DETAIL,
  },
  {
    key: 'accepts_new_investment',
    name: 'Open to New Investment?',
    leftNav: LeftNavExFundEnum.FUND_DETAIL,
  },
  {
    key: 'min_invest',
    name: 'Minimum Investment',
    leftNav: LeftNavExFundEnum.FUND_DETAIL,
  },
  {
    key: 'accepts_mgd_accts',
    name: `Is manager open to running a separately managed account pari-passu to this fund?`,
    leftNav: LeftNavExFundEnum.FUND_DETAIL,
  },
  {
    key: 'multi_manager_platform',
    name: `Does fund utilize an internal Multi-Manager Platform (aka, "Pods")?`,
    leftNav: LeftNavExFundEnum.FUND_DETAIL,
  },
  {
    key: 'add_invest',
    name: 'Additional Investments',
    leftNav: LeftNavExFundEnum.FUND_DETAIL,
  },

  {
    key: 'min_invest_indv',
    name: 'Managed Account Minimum Asset Size',
    leftNav: LeftNavExFundEnum.FUND_DETAIL,
  },

  {
    key: 'strategy_code_new',
    name: 'Main Strategy Classification',
    leftNav: LeftNavExFundEnum.INVESTMENT_STRATEGY,
  },
  {
    key: 'sub_strategy_code_new',
    name: 'Sub-Strategy Classification',
    leftNav: LeftNavExFundEnum.INVESTMENT_STRATEGY,
  },
  {
    key: 'region_inv_focus',
    name: 'Regional Investment Focus',
    leftNav: LeftNavExFundEnum.INVESTMENT_STRATEGY,
  },
  {
    key: 'region_inv_focus_country',
    name: 'Sub-Region Focus',
    leftNav: LeftNavExFundEnum.INVESTMENT_STRATEGY,
  },
  {
    key: 'strategy_description',
    name: 'Strategy Description',
    leftNav: LeftNavExFundEnum.INVESTMENT_STRATEGY,
  },
  {
    key: 'mgmt_fee',
    name: 'Management Fee %',
    leftNav: LeftNavExFundEnum.FEE_SCHEDULE,
  },
  {
    key: 'redemption_fee',
    name: 'Redemption Fee %',
    leftNav: LeftNavExFundEnum.FEE_SCHEDULE,
  },
  {
    key: 'redemption_fee_months',
    name: 'If less than (number of months)',
    leftNav: LeftNavExFundEnum.FEE_SCHEDULE,
  },
  {
    key: 'incentive_fee',
    name: 'Incentive Fee %',
    leftNav: LeftNavExFundEnum.FEE_SCHEDULE,
  },
  {
    key: 'sales_fee',
    name: 'Sales Fee %',
    leftNav: LeftNavExFundEnum.FEE_SCHEDULE,
  },
  {
    key: 'high_watermark',
    name: 'High Watermark',
    leftNav: LeftNavExFundEnum.FEE_SCHEDULE,
  },
  {
    key: 'hurdle_rate',
    name: 'Hurdle Rate',
    leftNav: LeftNavExFundEnum.FEE_SCHEDULE,
  },
  {
    key: 'max_12b1_fee',
    name: 'Maximum 12B-1 Fee %',
    leftNav: LeftNavExFundEnum.FEE_SCHEDULE,
  },
  {
    key: 'max_front_fee',
    name: 'Maximum Front-End Sales Load Fee %',
    leftNav: LeftNavExFundEnum.FEE_SCHEDULE,
  },
  {
    key: 'max_deferred_fee',
    name: 'Maximum Deferred Sales Load Fee %',
    leftNav: LeftNavExFundEnum.FEE_SCHEDULE,
  },
  {
    key: 'is_annual_performance_audit',
    name: 'Annual Performance Audit',
    leftNav: LeftNavExFundEnum.SERVICE_PROVIDERS,
  },
  {
    key: 'last_audit_date',
    name: 'Last Audit Date',
    leftNav: LeftNavExFundEnum.SERVICE_PROVIDERS,
  },
  {
    key: 'auditor_name',
    name: 'Auditor Name',
    leftNav: LeftNavExFundEnum.SERVICE_PROVIDERS,
  },
  {
    key: 'auditor_name_input',
    name: 'If Other, Specify Auditor Name',
    leftNav: LeftNavExFundEnum.SERVICE_PROVIDERS,
  },
  {
    key: 'legal_adviser',
    name: 'Legal Adviser',
    leftNav: LeftNavExFundEnum.SERVICE_PROVIDERS,
  },
  {
    key: 'legal_adviser_input',
    name: 'If Other, Specify Legal Adviser',
    leftNav: LeftNavExFundEnum.SERVICE_PROVIDERS,
  },
  {
    key: 'prime_broker',
    name: 'Prime Broker',
    leftNav: LeftNavExFundEnum.SERVICE_PROVIDERS,
  },
  {
    key: 'prime_broker_input',
    name: 'If Other, Specify Prime Broker',
    leftNav: LeftNavExFundEnum.SERVICE_PROVIDERS,
  },
  {
    key: 'administrator',
    name: 'Administrator',
    leftNav: LeftNavExFundEnum.SERVICE_PROVIDERS,
  },
  {
    key: 'administrator_input',
    name: 'If Other, Specify Administrator',
    leftNav: LeftNavExFundEnum.SERVICE_PROVIDERS,
  },
  {
    key: 'placement_agent',
    name: 'Placement Agent (Enter None, If Not Applicable)',
    leftNav: LeftNavExFundEnum.SERVICE_PROVIDERS,
  },
  {
    key: 'banking_agent',
    name: 'Banking Agent (Enter None, If Not Applicable)',
    leftNav: LeftNavExFundEnum.SERVICE_PROVIDERS,
  },
  {
    key: 'custodian',
    name: 'Custodian (Enter None, If Not Applicable)',
    leftNav: LeftNavExFundEnum.SERVICE_PROVIDERS,
  },
  {
    key: 'consultant',
    name: 'Consultant (Enter None, If Not Applicable)',
    leftNav: LeftNavExFundEnum.SERVICE_PROVIDERS,
  },
  {
    key: 'structure',
    name: 'Structure',
    leftNav: LeftNavExFundEnum.FUND_STRUCTURE,
  },
  {
    key: 'investor_type',
    name: 'Open To Investor Type',
    leftNav: LeftNavExFundEnum.FUND_STRUCTURE,
  },
  {
    key: 'equity_subscription',
    name: 'Subscription Frequency',
    leftNav: LeftNavExFundEnum.FUND_STRUCTURE,
  },
  {
    key: 'subscription_advance_notice',
    name: 'Subscription Advanced Notice (Days)',
    leftNav: LeftNavExFundEnum.FUND_STRUCTURE,
  },
  {
    key: 'subscription_cutoff_time',
    name: 'Subscription Cut Off By (Time)',
    leftNav: LeftNavExFundEnum.FUND_STRUCTURE,
  },
  {
    key: 'subscription_cutoff_timezone',
    name: 'Subscription Cut Off By (Time Zone)',
    leftNav: LeftNavExFundEnum.FUND_STRUCTURE,
  },
  {
    key: 'subscription_settlement_days',
    name: 'Subscription Settlement (Days)',
    leftNav: LeftNavExFundEnum.FUND_STRUCTURE,
  },
  {
    key: 'equity_redemption',
    name: 'Redemption Frequency',
    leftNav: LeftNavExFundEnum.FUND_STRUCTURE,
  },
  {
    key: 'redemption_advance_notice',
    name: 'Redemption Advanced Notice (Days)',
    leftNav: LeftNavExFundEnum.FUND_STRUCTURE,
  },
  {
    key: 'is_lockup',
    name: 'Lockup',
    leftNav: LeftNavExFundEnum.FUND_STRUCTURE,
  },
  {
    key: 'lockup_type',
    name: 'Lockup Type',
    leftNav: LeftNavExFundEnum.FUND_STRUCTURE,
  },
  {
    key: 'lockup_period',
    name: 'Lockup Period',
    leftNav: LeftNavExFundEnum.FUND_STRUCTURE,
  },
  {
    key: 'redemption_return_percent',
    name: 'Initial % Of Redemption Returned',
    leftNav: LeftNavExFundEnum.FUND_STRUCTURE,
  },
  {
    key: 'redemption_return_days',
    name: 'Initial Redemption Returned Within (Days)',
    leftNav: LeftNavExFundEnum.FUND_STRUCTURE,
  },
  {
    key: 'balance_redemption_return_days',
    name: 'Redemption Balance Returned Within (Days)',
    leftNav: LeftNavExFundEnum.FUND_STRUCTURE,
  },
  {
    key: 'gate_fund',
    name: 'Gate:Fund Level',
    leftNav: LeftNavExFundEnum.FUND_STRUCTURE,
  },
  {
    key: 'gate_investor',
    name: 'Investor Level',
    leftNav: LeftNavExFundEnum.FUND_STRUCTURE,
  },
  {
    key: 'performance_reporting',
    name: 'Performance Reporting Interval',
    leftNav: LeftNavExFundEnum.FUND_STRUCTURE,
  },
  {
    key: 'use_of_leverage_type',
    name: 'Leverage Type',
    leftNav: LeftNavExFundEnum.FUND_STRUCTURE,
  },
  {
    key: 'bloomberg_ticker',
    name: 'Ticker',
    leftNav: LeftNavExFundEnum.FUND_STRUCTURE,
  },
  {
    key: 'isin',
    name: 'ISIN',
    leftNav: LeftNavExFundEnum.FUND_STRUCTURE,
  },
  { key: 'is_fund_esg', name: 'Does Fund Consider ESG Factors?', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  { key: 'esg_strategy', name: 'ESG Strategy', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  { key: 'esg_sub_strategy', name: 'ESG Sub-Strategy', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  { key: 'app_positivescreen', name: 'Positive/best-in-class Screening', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  { key: 'app_negativescreen', name: 'Negative/Exclusionary Screening', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  { key: 'app_esgintegration', name: 'ESG Integration', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  { key: 'app_impactinvest', name: 'Impact Investing', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  { key: 'app_sustaininvest', name: 'Sustainability-themed Investing', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  { key: 'env_greenbuilding', name: 'Green Building/ Smart Growth', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  { key: 'env_climatechange', name: 'Climate Change/ Carbon', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  { key: 'env_cleantech', name: 'Clean Technology', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  { key: 'env_pollutiontaxes', name: 'Pollution / Toxics', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  {
    key: 'env_sustainable',
    name: 'Sustainable Natural Resources / Agriculture',
    leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS,
  },
  { key: 'env_waterconservation', name: 'Water Use & Conservation', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  {
    key: 'env_description',
    name: 'Additional Description Of How ENVIRONMENTAL Is Considered When Investing',
    leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS,
  },
  { key: 'soc_workplacesafety', name: 'Workplace Safety', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  { key: 'soc_laborrelations', name: 'Labor Relations', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  { key: 'soc_workplacebenefits', name: 'Workplace Benefits', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  { key: 'soc_diversityantibias', name: 'Diversity & Anti-Bias Issues', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  { key: 'soc_communitydev', name: 'Community Development', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  {
    key: 'soc_avoidtobacco',
    name: 'Avoidance Of Tobacco Or Other Harmful Products',
    leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS,
  },
  { key: 'soc_humanrights', name: 'Human Rights', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  {
    key: 'soc_description',
    name: 'Additional Description Of How SOCIAL Is Considered When Investing',
    leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS,
  },
  { key: 'gov_corppolcontrib', name: 'Corporate Political Contributions', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  { key: 'gov_executivecomp', name: 'Executive Compensation', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  { key: 'gov_boarddiversity', name: 'Board Diversity', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  { key: 'gov_anticorruption', name: 'Anti-Corruption Pilicies', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  { key: 'gov_boardindependence', name: 'Board Independence', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  {
    key: 'gov_description',
    name: 'Additional Description Of How GOVERNANCE Is Considered When Investing',
    leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS,
  },
  {
    key: 'team_engagepcm',
    name: `Does This Fund's Investment Team Engage With The Management Teams Of Companies In This Fund's Underlying Poritfolio, Re:ESG Or Diversity Issues?`,
    leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS,
  },
  { key: 'data_msci', name: 'MSCI', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  { key: 'data_sustainalytics', name: 'Sustainalytics', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  { key: 'data_bloomberg', name: 'Bloomberg', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  { key: 'data_factset', name: 'FactSet', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },
  {
    key: 'data_internal',
    name: 'Proprietary Or Internally Developed Metrics',
    leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS,
  },
  { key: 'data_other', name: 'Other', leftNav: LeftNavExFundEnum.FUND_ESG_FACTORS },

  {
    key: 'contact_name',
    name: 'Name',
    leftNav: LeftNavExFundEnum.FUND_CONTACT,
  },
  {
    key: 'contact_company',
    name: 'Company',
    leftNav: LeftNavExFundEnum.FUND_CONTACT,
  },
  {
    key: 'contact_country',
    name: 'Country',
    leftNav: LeftNavExFundEnum.FUND_CONTACT,
  },
  {
    key: 'contact_address1',
    name: 'Address 1',
    leftNav: LeftNavExFundEnum.FUND_CONTACT,
  },
  {
    key: 'contact_address2',
    name: 'Address 2',
    leftNav: LeftNavExFundEnum.FUND_CONTACT,
  },
  {
    key: 'contact_city',
    name: 'City',
    leftNav: LeftNavExFundEnum.FUND_CONTACT,
  },
  {
    key: 'contact_state',
    name: 'State',
    leftNav: LeftNavExFundEnum.FUND_CONTACT,
  },
  {
    key: 'contact_postal_code',
    name: 'Postal Code',
    leftNav: LeftNavExFundEnum.FUND_CONTACT,
  },
  {
    key: 'contact_phone',
    name: 'Phone',
    leftNav: LeftNavExFundEnum.FUND_CONTACT,
  },
  {
    key: 'contact_fax',
    name: 'Fax',
    leftNav: LeftNavExFundEnum.FUND_CONTACT,
  },
  {
    key: 'contact_email',
    name: 'Email',
    leftNav: LeftNavExFundEnum.FUND_CONTACT,
  },
  {
    key: 'contact_web',
    name: 'Website',
    leftNav: LeftNavExFundEnum.FUND_CONTACT,
  },
  {
    key: 'reporting_style',
    name: 'Reporting Style',
    leftNav: LeftNavExFundEnum.FUND_PERFORMANCE,
  },
  {
    key: 'denomination',
    name: 'Denom',
    leftNav: LeftNavExFundEnum.FUND_PERFORMANCE,
  },
  {
    key: 'has_securities',
    name: '',
    leftNav: LeftNavExFundEnum.FUND_CMIC,
  },
  {
    key: 'manager_confirms',
    name: '',
    leftNav: LeftNavExFundEnum.FUND_CMIC,
  },
  {
    key: 'separate_share_classes',
    name: '',
    leftNav: LeftNavExFundEnum.FUND_CMIC,
  },
  {
    key: 'pre_trade_compliance_restrictions',
    name: '',
    leftNav: LeftNavExFundEnum.FUND_CMIC,
  },
  {
    key: 'other_measures',
    name: '',
    leftNav: LeftNavExFundEnum.FUND_CMIC,
  },
  {
    key: 'subscription_advance_notice',
    name: '',
    leftNav: LeftNavExFundEnum.FUND_CMIC,
  },
]
