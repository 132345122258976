import Icon from '@ant-design/icons'
import { Button, Col, Collapse, Form, Row, Select, Spin, Table } from 'antd'
import { Store } from 'antd/lib/form/interface'
import { AxiosPromise } from 'axios'
import JsFileDownload from 'js-file-download'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../../app/rootReducer'
import APIService from '../../api'
import {
  FundDetailRegionalFocus,
  FundDetailsPreviewType,
  FundDetailStrategy,
  FundDetailSubStrategy,
} from '../../api/models/FundDetails'
import {
  PeerGroupFundBenchmarkResponse,
  PeerGroupUniverseStatsResponse,
  UserFundRecord,
} from '../../api/models/ResponsesTypes'
import { TrackingEventSource } from '../../api/services/event.service'
import { FetchPeerGroupPdfParams } from '../../api/services/peer-group.service'
import { FetchUserFundPeerGroupPdfParams } from '../../api/services/user-fund.service'
import { useServiceState } from '../../hooks/useServiceState'
import GenUtil from '../../utils/gen-util'
import { PerformanceVersusUniverseChart } from './PerformanceVersusUniverseChart'
import { resolveFundAndBenchmarkData, resolveUniverseStatData } from './utils'

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    padding: 16px 0;
  }
`

export enum UniverseSelectTypeEnum {
  HFR = 'HFR',
  LIST = 'LIST',
  PORTFOLIO = 'MODEL',
}

export interface PeerGroupTableDataType {
  name: string
  current: number | undefined
  prevMonth: number | undefined
  threeMonths: number | undefined
  sixMonths: number | undefined
  ytd: number | undefined
  oneYear: number | undefined
  twoYears: number | undefined
  threeYears: number | undefined
  fiveYears: number | undefined
}

const { Option } = Select

type Props = {
  source: TrackingEventSource
  fundId: string
  fundData?: FundDetailsPreviewType
  userFundData?: UserFundRecord
  endDateOptions: string[]
  fundAndBenchmarkService: ({
    fundId,
    benchmark1,
    benchmark2,
    benchmark3,
    endDate,
  }: {
    fundId: string
    benchmark1: string
    benchmark2: string
    benchmark3: string
    endDate: string | moment.Moment
  }) => AxiosPromise<PeerGroupFundBenchmarkResponse>
  universeStatsService: ({
    fundId,
    universeListId,
    fundListId,
    portfolioModelId,
    endDate,
    staticIndexIds,
    dynamicIndexIds,
    strategy,
    subStrategy,
    regionalFocus,
    inHFRI,
    inEmergingMarkets,
  }: {
    fundId: string
    universeListId?: string[]
    fundListId?: string[]
    portfolioModelId?: string[]
    endDate: string
    staticIndexIds: string[]
    dynamicIndexIds: string[]
    strategy: string[]
    subStrategy: string[]
    regionalFocus: string[]
    inHFRI: boolean
    inEmergingMarkets: boolean
  }) => AxiosPromise<PeerGroupUniverseStatsResponse>
}

const renderTable = (dataSource: PeerGroupTableDataType[], endDate?: string) => (
  <StyledTable dataSource={dataSource} rowKey="name" pagination={false}>
    <Table.Column key="name" dataIndex="name" title="" width={200} />
    <Table.Column
      key="current"
      dataIndex="current"
      title={endDate}
      sorter={(row1: PeerGroupTableDataType, row2: PeerGroupTableDataType) =>
        row1.current && row2.current ? row1.current - row2.current : 0
      }
      render={(text) => (text && GenUtil.digitWithMaxPrecision(text)) || null}
      align="right"
    />
    <Table.Column
      key="prevMonth"
      dataIndex="prevMonth"
      title="Prev Month"
      sorter={(row1: PeerGroupTableDataType, row2: PeerGroupTableDataType) =>
        row1.prevMonth && row2.prevMonth ? row1.prevMonth - row2.prevMonth : 0
      }
      render={(text) => (text && GenUtil.digitWithMaxPrecision(text)) || null}
      align="right"
    />
    <Table.Column
      key="threeMonths"
      dataIndex="threeMonths"
      title="3 Months"
      sorter={(row1: PeerGroupTableDataType, row2: PeerGroupTableDataType) =>
        row1.threeMonths && row2.threeMonths ? row1.threeMonths - row2.threeMonths : 0
      }
      render={(text) => (text && GenUtil.digitWithMaxPrecision(text)) || null}
      align="right"
    />
    <Table.Column
      key="sixMonths"
      dataIndex="sixMonths"
      title="6 Months"
      sorter={(row1: PeerGroupTableDataType, row2: PeerGroupTableDataType) =>
        row1.sixMonths && row2.sixMonths ? row1.sixMonths - row2.sixMonths : 0
      }
      render={(text) => (text && GenUtil.digitWithMaxPrecision(text)) || null}
      align="right"
    />
    <Table.Column
      key="ytd"
      dataIndex="ytd"
      title="YTD"
      sorter={(row1: PeerGroupTableDataType, row2: PeerGroupTableDataType) =>
        row1.ytd && row2.ytd ? row1.ytd - row2.ytd : 0
      }
      render={(text) => (text && GenUtil.digitWithMaxPrecision(text)) || null}
      align="right"
    />
    <Table.Column
      key="oneYear"
      dataIndex="oneYear"
      title="1 Year"
      sorter={(row1: PeerGroupTableDataType, row2: PeerGroupTableDataType) =>
        row1.oneYear && row2.oneYear ? row1.oneYear - row2.oneYear : 0
      }
      render={(text) => (text && GenUtil.digitWithMaxPrecision(text)) || null}
      align="right"
    />
    <Table.Column
      key="twoYears"
      dataIndex="twoYears"
      title="2 Year (ann)"
      sorter={(row1: PeerGroupTableDataType, row2: PeerGroupTableDataType) =>
        row1.twoYears && row2.twoYears ? row1.twoYears - row2.twoYears : 0
      }
      render={(text) => (text && GenUtil.digitWithMaxPrecision(text)) || null}
      align="right"
    />
    <Table.Column
      key="threeYears"
      dataIndex="threeYears"
      title="3 Year (ann)"
      sorter={(row1: PeerGroupTableDataType, row2: PeerGroupTableDataType) =>
        row1.threeYears && row2.threeYears ? row1.threeYears - row2.threeYears : 0
      }
      render={(text) => (text && GenUtil.digitWithMaxPrecision(text)) || null}
      align="right"
    />
    <Table.Column
      key="fiveYears"
      dataIndex="fiveYears"
      title="5 Year (ann)"
      sorter={(row1: PeerGroupTableDataType, row2: PeerGroupTableDataType) =>
        row1.fiveYears && row2.fiveYears ? row1.fiveYears - row2.fiveYears : 0
      }
      render={(text) => (text && GenUtil.digitWithMaxPrecision(text)) || null}
      align="right"
    />
  </StyledTable>
)

/**
 * Peer Group Tab in Fund Details
 * @param {string} fundId - ID of the current fund
 * @param {BenchmarkRecord[]} benchmarksOptions - The benchmarks data for the benchmark selections
 * @param {FundListRecord[]} universeListData  - Universe & user defined list data for dropdown (redux store)
 */
const PeerGroupForm: React.FC<Props> = ({
  source,
  fundId,
  fundData,
  userFundData,
  endDateOptions,
  fundAndBenchmarkService,
  universeStatsService,
}) => {
  const [form] = Form.useForm()

  const { strategies: strategyOptions, subStrategies: subStrategyOptions, benchmarks: benchmarkOptions } = useSelector(
    (state: RootState) => state.appData,
  )

  const { invoke: fetchRegionalFocus, data: regionalFocusOptions } = useServiceState(
    APIService.appDataService.fetchRegionalFocus,
  )

  const { invoke: fetchDynamicIndexes, data: hfrDynamicIndexes } = useServiceState(
    APIService.appDataService.fetchDynamicIndexes,
  )

  const { invoke: fetchStaticIndexes, data: hfrStaticIndexes } = useServiceState(
    APIService.appDataService.fetchStaticIndexes,
  )

  const { invoke: fetchPortfolioModels, data: portfolioModels } = useServiceState(
    APIService.portfolioModelService.fetchPortfolioModels,
  )

  const { invoke: fetchFundList, data: fundList } = useServiceState(APIService.fundUniverseListService.fetchFundList)

  const { invoke: fetchUniverseList, data: universeList } = useServiceState(
    APIService.fundUniverseListService.fetchUniverseList,
  )

  React.useEffect(() => {
    fetchRegionalFocus()
    fetchDynamicIndexes()
    fetchStaticIndexes()
    fetchPortfolioModels()
    fetchFundList()
    fetchUniverseList()
  }, [
    fetchDynamicIndexes,
    fetchFundList,
    fetchPortfolioModels,
    fetchRegionalFocus,
    fetchStaticIndexes,
    fetchUniverseList,
  ])

  const universeListData = React.useMemo(() => {
    const universes = universeList && [
      ...universeList.map((universeItem) => ({
        ...universeItem,
        id: `${UniverseSelectTypeEnum.HFR}-${universeItem.id}`,
        name: `(${UniverseSelectTypeEnum.HFR}) ${universeItem.name}`,
      })),
    ]
    const funds = fundList && [
      ...fundList.map((fundItem) => ({
        ...fundItem,
        id: `${UniverseSelectTypeEnum.LIST}-${fundItem.id}`,
        name: `(${UniverseSelectTypeEnum.LIST}) ${fundItem.name}`,
      })),
    ]
    const portfolios = portfolioModels && [
      ...portfolioModels.map((portfolioItem) => ({
        ...portfolioItem,
        id: `${UniverseSelectTypeEnum.PORTFOLIO}-${portfolioItem.id}`,
        name: `(${UniverseSelectTypeEnum.PORTFOLIO}) ${portfolioItem.name}`,
      })),
    ]
    const fundAndUniverseList = [...(universes || []), ...(funds || []), ...(portfolios || [])]
    return fundAndUniverseList
  }, [fundList, portfolioModels, universeList])

  const hfrIndexes = React.useMemo(() => {
    const staticIndexes =
      (hfrStaticIndexes &&
        hfrStaticIndexes.map((staticItem) => ({
          ...staticItem,
          id: `static-${staticItem.id}`,
          name: String(staticItem.name),
        }))) ||
      []
    const dynamicIndexes =
      (hfrDynamicIndexes &&
        hfrDynamicIndexes.map((dynamicItem) => ({
          ...dynamicItem,
          id: `dynamic-${dynamicItem.id}`,
          name: String(dynamicItem.name),
        }))) ||
      []
    return [...staticIndexes, ...dynamicIndexes]
  }, [hfrDynamicIndexes, hfrStaticIndexes])

  const { user } = useSelector((state: RootState) => state.user)

  const [selectedEndDate, setSelectedEndDate] = React.useState<string>()

  const { data: fundAndBenchmarkData, invoke: invokeFundAndBenchmark, loading: fundBenchmarkLoading } = useServiceState(
    fundAndBenchmarkService,
  )
  const { data: universeStatData, invoke: invokeUniverseStatData, loading: universeStatLoading } = useServiceState(
    universeStatsService,
  )

  const [selectedBenchmarks, setSelectedBenchmarks] = React.useState<string[]>()
  const [filteredSubStrategy, setFilteredSubStrategy] = React.useState<FundDetailSubStrategy[]>([])

  React.useEffect(() => {
    const strategyFromForm = form.getFieldValue('strategies')
    if (!subStrategyOptions) {
      return
    }
    if (strategyFromForm && strategyFromForm.length > 0) {
      setFilteredSubStrategy(
        subStrategyOptions.filter(
          (subStrategy: FundDetailSubStrategy) =>
            subStrategy.strategy_code && strategyFromForm.includes(subStrategy.strategy_code),
        ),
      )
    } else {
      setFilteredSubStrategy(subStrategyOptions)
    }
  }, [form, subStrategyOptions, setFilteredSubStrategy])

  const renderBenchmarkOptions = () => {
    return benchmarkOptions
      ? benchmarkOptions.map((option) => {
          return (
            <Option key={option.id} value={option.id}>
              {option.name}
            </Option>
          )
        })
      : []
  }

  // TODO: Implement validate one of
  // const validateOneOf = (_rule: any, _value: any, callback: any) => {
  //   console.log({ values: form.getFieldValue('strategies'), _value })
  //   const strategyValues = form.getFieldValue('strategies')
  //   const subStrategyValues = form.getFieldValue('subStrategy')
  //   const regionalFocusValues = form.getFieldValue('regionalFocus')
  //   const inHFRIValue = form.getFieldValue('inHFRI')
  //   const inEmergingMarketValue = form.getFieldValue('inEmergingMarkets')

  //   if (
  //     (!strategyValues || strategyValues.length <= 0) &&
  //     (!subStrategyValues || subStrategyValues.length <= 0) &&
  //     (!regionalFocusValues || regionalFocusValues.length <= 0) &&
  //     inHFRIValue === undefined &&
  //     inEmergingMarketValue === undefined
  //   ) {
  //     callback('Requiring at least one of Strategy, Sub-Strategy, Regional Focus, In HFRI, In Emerging Market')
  //   } else {
  //     callback()
  //   }
  // }

  const handleValuesChange = (changedValues: Store, _: Store) => {
    if (changedValues.strategies && subStrategyOptions) {
      setFilteredSubStrategy(
        subStrategyOptions.filter(
          (subStrategy: FundDetailSubStrategy) =>
            subStrategy.strategy_code && changedValues.strategies.includes(subStrategy.strategy_code),
        ),
      )
    }
  }

  const handleSubmit = (values: Store) => {
    const extractedEmptyArrayProps = Object.entries(values).reduce((result, [key, value]) => {
      if (value && Array.isArray(value) && value.length <= 0) {
        return result
      }
      return {
        ...result,
        [key]: value,
      }
    }, {} as any)
    setSelectedEndDate(extractedEmptyArrayProps.endDate)
    invokeFundAndBenchmark({
      fundId,
      benchmark1: extractedEmptyArrayProps.benchmark1,
      benchmark2: extractedEmptyArrayProps.benchmark2,
      benchmark3: extractedEmptyArrayProps.benchmark3,
      endDate: extractedEmptyArrayProps.endDate,
    })
    setSelectedBenchmarks([
      extractedEmptyArrayProps.benchmark1,
      extractedEmptyArrayProps.benchmark2,
      extractedEmptyArrayProps.benchmark3,
    ])

    const universeList = extractedEmptyArrayProps.universeList
      .filter((item: string) => item.includes(UniverseSelectTypeEnum.HFR))
      .map((item: string) => Number.parseInt(item.split('-')[1]))
    const fundList = extractedEmptyArrayProps.universeList
      .filter((item: string) => item.includes(UniverseSelectTypeEnum.LIST))
      .map((item: string) => Number.parseInt(item.split('-')[1]))
    const modelList = extractedEmptyArrayProps.universeList
      .filter((item: string) => item.includes(UniverseSelectTypeEnum.PORTFOLIO))
      .map((item: string) => Number.parseInt(item.split('-')[1]))
    const staticIndexIds = form.getFieldValue('hfrIndexes')
      ? form
          .getFieldValue('hfrIndexes')
          .filter((item: string) => item.includes('static'))
          .map((item: string) => Number.parseInt(item.split('-')[1]))
      : []
    const dynamicIndexIds = form.getFieldValue('hfrIndexes')
      ? form
          .getFieldValue('hfrIndexes')
          .filter((item: string) => item.includes('dynamic'))
          .map((item: string) => Number.parseInt(item.split('-')[1]))
      : []
    invokeUniverseStatData({
      fundId,
      universeListId: universeList.length > 0 ? universeList : undefined,
      fundListId: fundList.length > 0 ? fundList : undefined,
      portfolioModelId: modelList.length > 0 ? modelList : undefined,
      staticIndexIds: staticIndexIds && staticIndexIds.length > 0 ? staticIndexIds : undefined,
      dynamicIndexIds: dynamicIndexIds && dynamicIndexIds.length > 0 ? dynamicIndexIds : undefined,
      inHFRI: extractedEmptyArrayProps.inHFRI ? JSON.parse(extractedEmptyArrayProps.inHFRI) : undefined,
      inEmergingMarkets: extractedEmptyArrayProps.inEmergingMarkets
        ? JSON.parse(extractedEmptyArrayProps.inEmergingMarkets)
        : undefined,
      regionalFocus: extractedEmptyArrayProps.regionalFocus,
      strategy: extractedEmptyArrayProps.strategies,
      subStrategy: extractedEmptyArrayProps.subStrategy,
      endDate: extractedEmptyArrayProps.endDate,
    })

    // send event
    const jsonData = {
      fund_id: fundId,
      end_date: extractedEmptyArrayProps.endDate,
      benchmark1: extractedEmptyArrayProps.benchmark1,
      benchmark2: extractedEmptyArrayProps.benchmark2,
      benchmark3: extractedEmptyArrayProps.benchmark3,

      in_emerging_markets: extractedEmptyArrayProps.inEmergingMarkets
        ? JSON.parse(extractedEmptyArrayProps.inEmergingMarkets)
        : undefined,
      in_HFRI: extractedEmptyArrayProps.inHFRI ? JSON.parse(extractedEmptyArrayProps.inHFRI) : undefined,
      strategy: extractedEmptyArrayProps.strategies,
      sub_strategy: extractedEmptyArrayProps.subStrategy,
      regional_focus: extractedEmptyArrayProps.regionalFocus,
      static_index_ids: staticIndexIds && staticIndexIds.length > 0 ? staticIndexIds : undefined,
      dynamic_index_ids: dynamicIndexIds && dynamicIndexIds.length > 0 ? dynamicIndexIds : undefined,
      universe_list_id: universeList.length > 0 ? universeList : undefined,
    }
    APIService.eventService.sendViewPgaEvent(source, JSON.parse(JSON.stringify(jsonData)))
  }

  const generatePeerGroupPdfReport = async () => {
    if (!fundData && !userFundData) {
      return
    }
    const universeList = form
      .getFieldValue('universeList')
      .filter((item: string) => item.includes(UniverseSelectTypeEnum.HFR))
      .map((item: string) => Number.parseInt(item.split('-')[1]))
    const fundList = form
      .getFieldValue('universeList')
      .filter((item: string) => item.includes(UniverseSelectTypeEnum.LIST))
      .map((item: string) => Number.parseInt(item.split('-')[1]))
    const modelList = form
      .getFieldValue('universeList')
      .filter((item: string) => item.includes(UniverseSelectTypeEnum.PORTFOLIO))
      .map((item: string) => Number.parseInt(item.split('-')[1]))
    const staticIndexIds = form.getFieldValue('hfrIndexes')
      ? form
          .getFieldValue('hfrIndexes')
          .filter((item: string) => item.includes('static'))
          .map((item: string) => Number.parseInt(item.split('-')[1]))
      : []
    const dynamicIndexIds = form.getFieldValue('hfrIndexes')
      ? form
          .getFieldValue('hfrIndexes')
          .filter((item: string) => item.includes('dynamic'))
          .map((item: string) => Number.parseInt(item.split('-')[1]))
      : []
    const strategy = form.getFieldValue('strategies') || []
    const subStrategy = form.getFieldValue('subStrategy') || []
    const regionalFocus = form.getFieldValue('regionalFocus')
      ? form.getFieldValue('regionalFocus').map((item: string) => Number(item))
      : []
    const inHFRI = form.getFieldValue('inHFRI')
    const inEmergingMarkets = form.getFieldValue('inEmergingMarkets')

    if (fundData) {
      const data: FetchPeerGroupPdfParams = {
        end_date: form.getFieldValue('endDate'),
        benchmark1: form.getFieldValue('benchmark1'),
        benchmark2: form.getFieldValue('benchmark2'),
        benchmark3: form.getFieldValue('benchmark3'),
        universe_list_id: universeList.length ? universeList : undefined,
        fund_list_id: fundList.length ? fundList : undefined,
        portfolio_model_id: modelList.length ? modelList : undefined,
        static_index_ids: staticIndexIds.length ? staticIndexIds : undefined,
        dynamic_index_ids: dynamicIndexIds.length ? dynamicIndexIds : undefined,
        strategy: strategy.length ? strategy : undefined,
        sub_strategy: subStrategy.length ? subStrategy : undefined,
        regional_focus: regionalFocus.length ? regionalFocus : undefined,
        in_HFRI: inHFRI ? JSON.parse(inHFRI) : undefined,
        in_emerging_markets: inEmergingMarkets ? JSON.parse(inEmergingMarkets) : undefined,
      }
      const res = await APIService.peerGroupAnalysisService.fetchPeerGroupPdfReport(fundId, data)
      JsFileDownload(res.data, `${fundData.fund_name.replace('-', ' ').replace(',', ' ')} PGA.pdf`, 'application/pdf')
    } else if (userFundData) {
      const data: FetchUserFundPeerGroupPdfParams = {
        end_date: form.getFieldValue('endDate'),
        benchmark1: form.getFieldValue('benchmark1'),
        benchmark2: form.getFieldValue('benchmark2'),
        benchmark3: form.getFieldValue('benchmark3'),
        universe_list_ids: universeList.length ? universeList : undefined,
        fund_list_ids: fundList.length ? fundList : undefined,
        portfolio_model_ids: modelList.length ? modelList : undefined,
        static_index_ids: staticIndexIds.length ? staticIndexIds : undefined,
        dynamic_index_ids: dynamicIndexIds.length ? dynamicIndexIds : undefined,
        strategy: strategy.length ? strategy : undefined,
        sub_strategy: subStrategy.length ? subStrategy : undefined,
        regional_focus: regionalFocus.length ? regionalFocus : undefined,
        in_HFRI: inHFRI ? JSON.parse(inHFRI) : undefined,
        in_emerging_markets: inEmergingMarkets ? JSON.parse(inEmergingMarkets) : undefined,
      }
      const res = await APIService.userFundService.fetchFundPdfReport({ fundId, data })
      JsFileDownload(res.data, `${userFundData.name.replace('-', ' ').replace(',', ' ')} PGA.pdf`, 'application/pdf')
    }
  }

  const resolvedUniverseStatData = resolveUniverseStatData(universeStatData)
  const resolvedFundAndBenchmarkData: PeerGroupTableDataType[] = React.useMemo(() => {
    if (!benchmarkOptions || !selectedBenchmarks || !fundAndBenchmarkData) {
      return []
    }
    return resolveFundAndBenchmarkData(fundAndBenchmarkData, benchmarkOptions, selectedBenchmarks)
  }, [benchmarkOptions, fundAndBenchmarkData, selectedBenchmarks])

  React.useEffect(() => {
    if (universeListData && universeListData.length > 0) {
      form.setFieldsValue({ universeList: [universeListData[0].id] })
    }
  }, [form, universeListData])

  return (
    <div id="peer-group-wrapper" style={{ display: 'flex', flex: 1, flexDirection: 'column', width: '100%' }}>
      <Form form={form} onFinish={handleSubmit} layout="vertical" onValuesChange={handleValuesChange}>
        <Row gutter={16}>
          <Col span={8}>
            <Button type="primary" htmlType="submit" style={{ marginRight: 16 }}>
              Generate Report
            </Button>
            <Button
              disabled={
                !(
                  form.getFieldValue('endDate') &&
                  form.getFieldValue('universeList') &&
                  form.getFieldValue('universeList').length &&
                  form.getFieldValue('benchmark1') &&
                  form.getFieldValue('benchmark2') &&
                  form.getFieldValue('benchmark3')
                ) || user?.hfrdb_trial
              }
              onClick={generatePeerGroupPdfReport}
            >
              <Icon type="printer" /> Print
            </Button>
          </Col>

          <Col span={8} offset={8}>
            <Form.Item
              label="End Date"
              name="endDate"
              rules={[{ required: true, message: 'End date is required' }]}
              initialValue={endDateOptions[0]}
            >
              <Select>
                {endDateOptions.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Universe List"
              name="universeList"
              rules={[{ type: 'array' }, { required: true, message: 'Universe List is required' }]}
            >
              <Select
                mode="multiple"
                disabled={user?.hfrml_external_user}
                style={{ width: '100%' }}
                placeholder="Select Universe"
                allowClear
                showSearch
                filterOption={(input, option) => {
                  if (!option || !option.props || !option.props.children) {
                    return false
                  }
                  return (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }}
              >
                {universeListData &&
                  universeListData.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label="HFR Index Constituents" name="hfrIndexes" rules={[{ type: 'array' }]}>
              <Select
                mode="multiple"
                placeholder="Select HFR Index"
                disabled={user?.hfrml_external_user}
                allowClear
                showSearch
                filterOption={(input, option) => {
                  if (!option || !option.props || !option.props.children) {
                    return false
                  }
                  return (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }}
              >
                {hfrIndexes &&
                  hfrIndexes.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Benchmark 1"
              name="benchmark1"
              rules={[{ required: true, message: 'Benchmark 1 is required' }]}
              initialValue="2899"
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Select Benchmark 1"
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option.props &&
                  option.props.children &&
                  (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {renderBenchmarkOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Strategy"
              name="strategies"
              rules={[{ type: 'array' }]}
              initialValue={
                fundData && fundData.strategy.strategy_code
                  ? [fundData.strategy.strategy_code]
                  : userFundData && userFundData.strategy_code
                  ? [userFundData.strategy_code]
                  : undefined
              }
            >
              <Select
                disabled={user?.hfrml_external_user}
                mode="multiple"
                style={{ width: '100%' }}
                showSearch
                allowClear
                placeholder="Select Strategy"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option.props &&
                  option.props.children &&
                  (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {strategyOptions &&
                  strategyOptions.map((item: FundDetailStrategy) => {
                    const { strategy_code, strategy_name } = item
                    return (
                      <Option key={strategy_code} value={strategy_code}>
                        {strategy_name}
                      </Option>
                    )
                  })}
              </Select>
            </Form.Item>
            <Form.Item label="Regional Focus" name="regionalFocus">
              <Select
                mode="multiple"
                optionFilterProp="children"
                showSearch
                allowClear
                placeholder="Select Regional Focus"
                filterOption={(input, option: any) =>
                  option.props &&
                  option.props.children &&
                  (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: '100%' }}
              >
                {regionalFocusOptions &&
                  regionalFocusOptions.map((item: FundDetailRegionalFocus) => {
                    const { id, name } = item
                    return (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    )
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Benchmark 2"
              name="benchmark2"
              rules={[{ required: true, message: 'Benchmark 2 is required' }]}
              initialValue="2898"
            >
              <Select
                style={{ width: '100%' }}
                placeholder="Select Benchmark 2"
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option.props &&
                  option.props.children &&
                  (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {renderBenchmarkOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Sub - Strategy"
              name="subStrategy"
              initialValue={
                fundData && fundData.sub_strategy.sub_strategy_code
                  ? [fundData.sub_strategy.sub_strategy_code]
                  : userFundData && userFundData.sub_strategy_code
                  ? [userFundData.sub_strategy_code]
                  : undefined
              }
            >
              <Select
                mode="multiple"
                placeholder="Select Sub Strategy"
                style={{ width: '100%' }}
                showSearch
                allowClear
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option.props &&
                  option.props.children &&
                  (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {filteredSubStrategy.map((item: FundDetailSubStrategy) => {
                  const { sub_strategy_code, sub_strategy_name } = item
                  return (
                    <Option key={sub_strategy_code} value={sub_strategy_code}>
                      {sub_strategy_name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item label="In HFRI" name="inHFRI">
                  <Select style={{ width: '100%' }} placeholder="Is in HFRI?" allowClear>
                    <Option key={'true'} value={'true'}>
                      Yes
                    </Option>
                    <Option key={'false'} value={'false'}>
                      No
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="In Emerging Market" name="inEmergingMarkets">
                  <Select style={{ width: '100%' }} placeholder="Is in Emerging Market?" allowClear>
                    <Option key={'true'} value={'true'}>
                      Yes
                    </Option>
                    <Option key={'false'} value={'false'}>
                      No
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Benchmark 3"
              name="benchmark3"
              rules={[{ required: true, message: 'Benchmark 3 is required' }]}
              initialValue="50141"
            >
              <Select
                placeholder="Select Benchmark 3"
                style={{ width: '100%' }}
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option.props &&
                  option.props.children &&
                  (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {renderBenchmarkOptions()}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row style={{ marginTop: '20px' }} id="fund-and-benchmark">
        <Col span={24}>
          <Collapse defaultActiveKey={['1']}>
            <Collapse.Panel header="Fund and Benchmark Performance" key="1" showArrow>
              <Spin spinning={fundBenchmarkLoading}>
                {renderTable(resolvedFundAndBenchmarkData, fundAndBenchmarkData?.common_end_date)}
              </Spin>
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>
      <Row style={{ marginTop: '20px' }} id="universe-statistics">
        <Col span={24}>
          <Collapse defaultActiveKey={['1']}>
            <Collapse.Panel header="Universe Statistics" key="1" showArrow>
              <Spin spinning={universeStatLoading}>{renderTable(resolvedUniverseStatData, selectedEndDate)}</Spin>
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>
      <Row style={{ marginTop: '20px' }} id="performance-universe">
        <Col span={24}>
          <Collapse defaultActiveKey={['1']}>
            <Collapse.Panel header="Performance vs Universe" key="1" showArrow>
              <Spin spinning={universeStatLoading}>
                <PerformanceVersusUniverseChart
                  data={[
                    ...resolvedUniverseStatData,
                    ...resolvedFundAndBenchmarkData.filter(
                      (fundBenchmarkData: PeerGroupTableDataType) => fundBenchmarkData.name === 'Fund',
                    ),
                  ]}
                  endDate={selectedEndDate}
                />
              </Spin>
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>
    </div>
  )
}

export default PeerGroupForm
