import { CopyOutlined, DeleteOutlined, EditOutlined, UserAddOutlined } from '@ant-design/icons'
import { Button, notification, Popconfirm, Row } from 'antd'
import React, { useRef } from 'react'
import APIService from '../../shared/api'
import { AnalysisGroupListContext } from '../../shared/api/context/AnalysisGroupListContext'
import { AnalysisType } from '../../shared/api/models/Analysis'
import AssignUserModal from './AssignUserModal'
import DuplicateGroupModal from './DuplicateGroupModal'
import GroupTable from './GroupTable'
import MovetToGroupModal from './MoveToGroupModal'
import styled from 'styled-components'
import { ResponsiveTitle } from '../../shared/components/ResponsiveTitle'

const Container = styled.div`
  width: 100%;
`
type Props = {
  onSelectGroup: (group?: AnalysisType) => void
  selectedGroup?: AnalysisType
}

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`

const GroupList: React.FC<Props> = ({ selectedGroup, onSelectGroup }) => {
  const [showDuplicateModal, setShowDuplicateModal] = React.useState(false)
  const [showCopyModal, setShowCopyModal] = React.useState(false)
  const [showAssignModal, setShowAssignModal] = React.useState(false)
  const [expandedKeys, setExpandedKeys] = React.useState<number[]>()
  const { addedRowTree, setAddedRow, refetchGroup } = React.useContext(AnalysisGroupListContext)
  const actionContainerRef = useRef(null)

  const handleExpand = (expanded: boolean, record: AnalysisType) => {
    if (addedRowTree && addedRowTree.length > 0) {
      setAddedRow(undefined)
    }
    setExpandedKeys((prev) =>
      !expanded ? prev?.filter((item) => item !== record.group_code) : [...(prev || []), record.group_code],
    )
  }

  const handleDeleteGroup = () => {
    if (!selectedGroup) {
      return
    }
    APIService.analysisService.deleteGroup(selectedGroup.group_code).then(() => {
      notification.success({ message: `Delete ${selectedGroup.group_name} successfully!` })
      onSelectGroup(undefined)
      refetchGroup()
    })
  }

  React.useEffect(() => {
    if (!addedRowTree) {
      return
    }
    setExpandedKeys(addedRowTree)
  }, [addedRowTree])

  React.useLayoutEffect(() => {
    if (!addedRowTree) {
      return
    }
    document.querySelector<HTMLElement>(`.gc-${addedRowTree[0]}`)?.scrollIntoView({ behavior: 'smooth' })
  }, [addedRowTree, expandedKeys, setAddedRow])

  return (
    <Container>
      <GroupTable
        selectedGroup={selectedGroup}
        onSelectGroup={onSelectGroup}
        expandedRowKeys={expandedKeys}
        onExpand={handleExpand}
      />
      {selectedGroup && (
        <Row style={{ padding: '0 0.5rem', marginTop: 8 }} gutter={8}>
          <ActionsContainer ref={actionContainerRef}>
            <Button type={'default'} onClick={() => setShowDuplicateModal(true)}>
              <CopyOutlined />
              <ResponsiveTitle visibleWidth={1000}>Duplicate</ResponsiveTitle>
            </Button>

            <Button onClick={() => setShowCopyModal(true)}>
              <EditOutlined />
              <ResponsiveTitle visibleWidth={1100}>Move to</ResponsiveTitle>
            </Button>
            <Button onClick={() => setShowAssignModal(true)}>
              <UserAddOutlined />
              <ResponsiveTitle visibleWidth={1250}>Assign User</ResponsiveTitle>
            </Button>
            <Popconfirm
              title={
                <div>
                  Are you sure you want to delete <b>{selectedGroup.group_name}</b>?
                </div>
              }
              onConfirm={handleDeleteGroup}
            >
              <Button danger>
                <DeleteOutlined />
                <ResponsiveTitle visibleWidth={1400}>Delete</ResponsiveTitle>
              </Button>
            </Popconfirm>
          </ActionsContainer>

          <DuplicateGroupModal
            selectedGroup={selectedGroup}
            isOpen={showDuplicateModal}
            onRequestClose={() => setShowDuplicateModal(false)}
          />
          <MovetToGroupModal
            selectedGroup={selectedGroup}
            isOpen={showCopyModal}
            onRequestClose={() => setShowCopyModal(false)}
          />
          <AssignUserModal
            selectedGroup={selectedGroup}
            isOpen={showAssignModal}
            onRequestClose={() => setShowAssignModal(false)}
          />
        </Row>
      )}
    </Container>
  )
}

export default GroupList
