import { notification } from 'antd'
import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import nProgress from 'nprogress'
import qs from 'querystring'
import store from '../../app/store'
import { clearToken } from '../../slice/authSlice'
import { history } from '../history'
import '../nprogress.css'
import { ActivityLogService } from './services/activity-log.service'
import { AdminService } from './services/admin.service'
import { AnalysisService } from './services/analysis.service'
import { AuthService } from './services/auth.service'
import { BenchmarkService } from './services/benchmark.service'
import { CodeOfConductService } from './services/code-of-conduct.service'
import { CodeTableService } from './services/code-table.service'
import { CommonService } from './services/common.service'
import { ContentService } from './services/content.service'
import { AppDataService } from './services/data.service'
import { EventService } from './services/event.service'
import { FirmDetailService } from './services/firm-detail.service'
import { FirmService } from './services/firm.service'
import { FundDetailService } from './services/fund-detail.service'
import { FundAndUniverseListService } from './services/fund-universe-list'
import { FundService } from './services/fund.service'
import { IndexManagerService } from './services/index-manager.service'
import { MinorityService } from './services/minority.service'
import { PeerGroupAnalysisService } from './services/peer-group.service'
import { PortfolioModelService } from './services/portfolio-model.service'
import { UniverseManagerService } from './services/universe-manager.service'
import { UserFundService } from './services/user-fund.service'
import { UserService } from './services/user.service'

class APIClient {
  private request: AxiosInstance

  public accessToken: string | null = null
  private activeRequests: AxiosRequestConfig[] = []
  private requestTimeOut?: number
  public authService: AuthService
  public commonService: CommonService
  public appDataService: AppDataService
  public portfolioModelService: PortfolioModelService
  public fundUniverseListService: FundAndUniverseListService
  public fundService: FundService
  public firmService: FirmService
  public fundDetailService: FundDetailService
  public firmDetailService: FirmDetailService
  public benchmarkService: BenchmarkService
  public activityLogService: ActivityLogService
  public analysisService: AnalysisService
  public userService: UserService
  public indexManagerService: IndexManagerService
  public codeOfConductService: CodeOfConductService
  public peerGroupAnalysisService: PeerGroupAnalysisService
  public userFundService: UserFundService
  public universeManagerService: UniverseManagerService
  public codeTableService: CodeTableService
  public adminService: AdminService
  public contentService: ContentService
  public eventService: EventService
  public minorityService: MinorityService

  private paramsSerialize(params: any) {
    return qs.stringify(
      Object.keys(params).reduce((s: { [key: string]: any }, key: string) => {
        if (!params[key] || (Array.isArray(params[key]) && params[key].length === 0)) {
          return s
        }
        return {
          [key]: Array.isArray(params[key]) ? JSON.stringify(params[key]) : params[key],
          ...s,
        }
      }, {}),
    )
  }

  constructor() {
    this.request = Axios.create({
      baseURL: process.env.REACT_APP_AUTH_API_URL,
      maxRedirects: 0,
      paramsSerializer: (params) => this.paramsSerialize(params),
    })

    this.authService = AuthService(this.request)
    this.commonService = CommonService(this.request)
    this.appDataService = AppDataService(this.request)
    this.portfolioModelService = PortfolioModelService(this.request)
    this.fundUniverseListService = FundAndUniverseListService(this.request)
    this.fundService = FundService(this.request)
    this.firmService = FirmService(this.request)
    this.fundDetailService = FundDetailService(this.request)
    this.firmDetailService = FirmDetailService(this.request)
    this.benchmarkService = BenchmarkService(this.request)
    this.activityLogService = ActivityLogService(this.request)
    this.analysisService = AnalysisService(this.request)
    this.userService = UserService(this.request)
    this.indexManagerService = IndexManagerService(this.request)
    this.codeOfConductService = CodeOfConductService(this.request)
    this.peerGroupAnalysisService = PeerGroupAnalysisService(this.request)
    this.userFundService = UserFundService(this.request)
    this.universeManagerService = UniverseManagerService(this.request)
    this.codeTableService = CodeTableService(this.request)
    this.adminService = AdminService(this.request)
    this.contentService = ContentService(this.request)
    this.eventService = EventService(this.request)
    this.minorityService = MinorityService(this.request)

    this.request.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error) {
          if (this.accessToken && error.response && error.response.status === 401) {
            this.accessToken = null
            store.dispatch(clearToken())
            notification.error({ message: 'Unauthorized!' })
            history.push('/auth')
            return
          }
        }
        throw error
      },
    )

    this.request.interceptors.request.use((config) => {
      if (this.accessToken) {
        config.headers.Authorization = `Bearer ${this.accessToken}`
      }
      return config
    })
    this._setupLoadingIndicator()
  }

  private static shouldShowProgressBar(req: AxiosRequestConfig) {
    // No Progress Bar when Downloading files.
    if (!(req.url && req.url.startsWith('/files/'))) {
      nProgress.start()
      nProgress.set(0.1)
    }
  }

  private _setupLoadingIndicator() {
    this.request.interceptors.request.use((req) => {
      if (this.requestTimeOut) {
        clearTimeout(this.requestTimeOut)
      }
      this.requestTimeOut = setTimeout(() => nProgress.done(), 10000)
      this.activeRequests.push(req)
      APIClient.shouldShowProgressBar(req)
      return req
    })

    this.request.interceptors.response.use(
      (res) => {
        if (res) {
          this.activeRequests = this.activeRequests.filter((i) => i !== res.config)
          if (!this.activeRequests.length) {
            nProgress.done()
          }
        }
        return res
      },
      (error) => {
        if (Axios.isCancel(error)) {
          const canceledIndex = this.activeRequests.findIndex((i) => i.url && i.url.includes(error.message))
          this.activeRequests = this.activeRequests.filter((_, i) => i !== canceledIndex)
        } else {
          this.activeRequests = this.activeRequests.filter((i) => i !== error.config)
        }
        if (!this.activeRequests.length) {
          nProgress.done()
        }
        throw error
      },
    )
  }

  public setToken(token: string) {
    this.accessToken = token
  }
  public clearToken() {
    this.accessToken = null
  }
}

const APIService = new APIClient()
export default APIService
