import Icon, { QuestionCircleFilled } from '@ant-design/icons'
import { Button, Form, Input, notification, Row, Tooltip, Typography } from 'antd'
import { useForm } from 'antd/lib/form/util'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../../app/rootReducer'
import APIService from '../../../shared/api'
import ExternalMainLayout from '../../../shared/components/ExternalMainLayout'
import MainLayout from '../../../shared/components/MainLayout'
const Wrapper = styled('div')`
  width: 100%;
  background: #fff;
  padding: 16px;
  overflow: hidden;
`
const { Title } = Typography

const Header = styled(Title)`
  position: relative;
  padding: 10px 0;
  height: auto;
  margin: 0.5rem 0 1.5rem 0;
  display: block;
`

export const UserProfileChangePasswordForm: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.user)
  return user && user.hfrml_internal_user ? (
    <MainLayout>
      <RenderChangePassword />
    </MainLayout>
  ) : (
    <ExternalMainLayout>
      <RenderChangePassword />
    </ExternalMainLayout>
  )
}

export const RenderChangePassword: React.FC = () => {
  const [loadingSubmit, setLoadingSubmit] = React.useState(false)
  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  }

  const [form] = useForm()

  const handleSubmit = () => {
    setLoadingSubmit(true)
    APIService.authService
      .updatePassword({ password: form.getFieldValue('yourPassword'), newPassword: form.getFieldValue('newPassword') })
      .then(() => {
        notification.success({ message: 'Password Updated!' })
      })
      .catch((err) => {
        console.error({ err })
        notification.error({ message: 'Password Update Failed!' })
      })
      .finally(() => {
        setLoadingSubmit(false)
      })
  }
  return (
    <Wrapper>
      <Form onFinish={handleSubmit} className="change-password-form" {...formItemLayout} form={form}>
        <Header level={4}>Change Password</Header>
        <Form.Item
          style={{ marginTop: 40 }}
          label="Your Password"
          name="yourPassword"
          rules={[{ required: true, message: 'Password is required!' }]}
        >
          <Input
            prefix={<Icon type="key" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Your Current password"
          />
        </Form.Item>
        <Form.Item
          label={'Choose Password'}
          name="newPassword"
          rules={[
            { required: true, message: 'Password is required' },
            {
              pattern: RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'),
              message: (
                <Tooltip title="Password must contain atleast 8 characters, 1 number, 1 lowercase, 1 uppercase and 1 special character">
                  <div>
                    <QuestionCircleFilled style={{ marginRight: 5 }} />
                    <span>Password is invalid</span>
                  </div>
                </Tooltip>
              ),
            },
          ]}
        >
          <Input
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Choose Password"
          />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          rules={[
            { required: true, message: 'Confirm password is required!' },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve()
                }
                return Promise.reject('The two passwords that you entered do not match!')
              },
            }),
          ]}
        >
          <Input
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Confirm Password"
          />
        </Form.Item>
        <Row style={{ justifyContent: 'center' }}>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{ marginTop: 50, width: 160 }}
            loading={loadingSubmit}
          >
            Update
          </Button>
        </Row>
      </Form>
    </Wrapper>
  )
}
