import React, { useEffect } from 'react'
import { Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import styled from 'styled-components'
import APIService from '../../../../shared/api'
import { FundDetailDropdownContext } from '../../../../shared/api/context/FundDetailDropdownContext'
import { DividerForm } from '../../../../shared/components/DividerForm'
import { FormItemInput, FormItemSelect, FormItemTextArea } from '../../../../shared/components/FormItemComponents'
import { RenderLabelFormFirm } from '../../../../shared/components/RenderLabelFormFirm'
import { useServiceState } from '../../../../shared/hooks/useServiceState'
import { FirmDetailDataContext } from '../../../../shared/api/context/FirmDetailDataContext'
import moment from 'moment'
import { Colors } from '../../../../shared/colors'

const Wrapper = styled.div`
  padding: 1rem 3rem;
  display: flex;
  flex: 1;
`

const Flex = styled.div`
  display: flex;
  flex: 1;
`
export const FirmContactInfo: React.FC = () => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)

  const { dataFirmDetail } = React.useContext(FirmDetailDataContext)
  const { invoke: getFirmUsers, data: firmUsers, loading: fetchingFirmUser } = useServiceState(
    APIService.firmDetailService.fetchFirmUser,
  )

  useEffect(() => {
    if (!dataFirmDetail) {
      return
    }
    getFirmUsers(dataFirmDetail.firm_id)
  }, [dataFirmDetail, getFirmUsers])

  const userColumns: ColumnProps<any>[] = [
    {
      title: 'Company',
      key: 'company',
      dataIndex: 'company',
      sorter: (a, b) => (b.company > a.company ? -1 : 1),
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      defaultSortOrder: 'ascend',
      render: (value, record) => {
        return value ? (
          <a
            href={`${process.env.REACT_APP_AUTH_API_URL}${record.change_user_admin_url}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {value}
          </a>
        ) : null
      },
      sorter: (a, b) => (b.email > a.email ? -1 : 1),
    },
    {
      title: 'First Name',
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: (a, b) => (b.email > a.email ? -1 : 1),
    },
    {
      title: 'Last Name',
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: (a, b) => (b.email > a.email ? -1 : 1),
    },
    {
      title: 'User Status',
      key: 'active',
      dataIndex: 'active',
      render: (value) => {
        return value ? (
          <span style={{ color: Colors.success, fontWeight: 500 }}>Active</span>
        ) : (
          <span style={{ color: Colors.danger, fontWeight: 500 }}>Inactive</span>
        )
      },
      sorter: (a, b) => (b.email > a.email ? -1 : 1),
    },
    {
      title: 'Last Activity',
      key: 'last_event_timestamp',
      dataIndex: 'last_event_timestamp',
      sorter: (a, b) => (moment(b).isAfter(moment(a)) ? 1 : -1),
      render: (value, record) => {
        return value ? (
          <a
            href={`${process.env.REACT_APP_AUTH_API_URL}${record.user_events_url}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {moment(value).format('YYYY-MM-DD HH:mm')}
          </a>
        ) : null
      },
    },
  ]

  return (
    <Wrapper>
      <Flex style={{ flexDirection: 'column' }}>
        <FormItemInput
          name="contact_name1"
          label={<RenderLabelFormFirm value="Contact 1" />}
          labelCol={6}
          wrapperCol={12}
          maxLength={60}
        />
        <FormItemInput name="contact_name2" label="Contact 2" labelCol={6} wrapperCol={12} maxLength={60} />
        <FormItemInput
          name="contact_address1"
          label={<RenderLabelFormFirm value="Address 1" />}
          labelCol={6}
          wrapperCol={12}
          maxLength={60}
        />
        <FormItemInput name="contact_address2" label="Address 2" labelCol={6} wrapperCol={12} maxLength={60} />
        <Flex>
          <FormItemInput
            name="contact_city"
            label={<RenderLabelFormFirm value="City" />}
            labelCol={12}
            wrapperCol={8}
            maxLength={30}
          />
          <FormItemSelect
            name="contact_state"
            label={<RenderLabelFormFirm value="State" />}
            labelCol={4}
            allowClear
            wrapperCol={8}
            options={
              fundDataDropdown?.contact_state && fundDataDropdown.contact_state.length > 0
                ? fundDataDropdown.contact_state.map((item) => ({
                    value: item.code_code,
                    text: item.code_description,
                  }))
                : []
            }
          />
        </Flex>
        <Flex>
          <FormItemInput
            name="contact_postal_code"
            label={<RenderLabelFormFirm value="Postal Code" />}
            labelCol={12}
            wrapperCol={8}
            maxLength={10}
          />
          <FormItemSelect
            name="country"
            label={<RenderLabelFormFirm value="Country" />}
            labelCol={4}
            wrapperCol={8}
            options={
              fundDataDropdown?.contact_country && fundDataDropdown.contact_country.length > 0
                ? fundDataDropdown.contact_country.map((item) => ({
                    value: item.code_code,
                    text: item.code_description,
                  }))
                : []
            }
          />
        </Flex>
        <Flex>
          <FormItemInput
            name="contact_phone"
            label={<RenderLabelFormFirm value="Phone" />}
            labelCol={12}
            wrapperCol={8}
            maxLength={30}
          />
          <FormItemInput
            name="contact_fax"
            label={<RenderLabelFormFirm value="Fax" />}
            labelCol={4}
            wrapperCol={8}
            maxLength={30}
          />
        </Flex>
        <FormItemInput
          name="contact_email"
          label={<RenderLabelFormFirm value="Email" />}
          labelCol={6}
          wrapperCol={12}
          maxLength={60}
        />
        <FormItemInput
          name="contact_web"
          label={<RenderLabelFormFirm value="Web" />}
          labelCol={6}
          wrapperCol={12}
          maxLength={60}
        />
        <DividerForm />
        <FormItemTextArea
          label={<RenderLabelFormFirm value="HFR Notes" />}
          row={5}
          name="contact_description"
          maxLength={4000}
        />
        <DividerForm />
        <Table
          title={() => 'ML Users'}
          size="small"
          columns={userColumns}
          dataSource={firmUsers}
          loading={fetchingFirmUser}
        />
      </Flex>
    </Wrapper>
  )
}
