import { FormInstance } from 'antd/lib/form'
import React from 'react'
import APIService from '../../../shared/api'
import { FirmDetailDataContext } from '../../../shared/api/context/FirmDetailDataContext'
import { FirmESGSection } from '../../../shared/components/FirmESGSection'
import { MODE_ESG_VIEW } from '../../../shared/components/FundESGSection'
import { useServiceState } from '../../../shared/hooks/useServiceState'
type Props = {
  form: FormInstance
  modeView: MODE_ESG_VIEW
}
export const FirmESGPolicies: React.FC<Props> = ({ form, modeView }) => {
  const { dataFirmDetail } = React.useContext(FirmDetailDataContext)

  const { data: dataExFunds, invoke: getExFund } = useServiceState(APIService.fundService.fetchExFunds)

  const listFundOfFirm = React.useMemo(() => {
    if (!dataExFunds) {
      return []
    }
    const list = dataFirmDetail
      ? dataExFunds
          .filter((i) => i.firm_id === dataFirmDetail.firm_id)
          .map((item) => ({ fund_id: item.fund_id, fund_name: item.fund_name }))
      : []
    return list
  }, [dataExFunds, dataFirmDetail])

  React.useEffect(() => {
    getExFund()
  }, [getExFund, modeView])

  return <FirmESGSection form={form} modeView={MODE_ESG_VIEW.EXTERNAL} dataListFund={listFundOfFirm} />
}
