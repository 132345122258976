import { Button, DatePicker, Pagination, Row, Table } from 'antd'
import { SorterResult } from 'antd/lib/table/interface'
import JsFileDownload from 'js-file-download'
import moment from 'moment'
import Papa from 'papaparse'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../../app/rootReducer'
import APIService from '../../../shared/api'
import { PERFORMANCE_REQUEST_ENUM } from '../../../shared/api/context/FundDetailDataContext'
import { DividerForm } from '../../../shared/components/DividerForm'
import { useServiceState } from '../../../shared/hooks/useServiceState'
import { resetAsset, resetNav, resetROR } from '../../../slice/appEventSlice'

const Wrapper = styled('div')``

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`

type HistoricalDataTableType = {
  yyyymm?: string
  old: number | null
  new: number | null
  author: string
  date: string
}

type Props = {
  selectedDate?: string
  setSelectedDate?: (selectedDate: string | undefined) => void
  type: PERFORMANCE_REQUEST_ENUM
  fundId: string
}

const HistoricalData: React.FC<Props> = ({ selectedDate, setSelectedDate, type, fundId }) => {
  const { fundAssetUpdated, fundRorUpdated, fundNavUpdated } = useSelector((state: RootState) => state.appEvent)
  const dispatch = useDispatch()

  const { invoke: getAllLogEvent, data: dataAllEvent, loading: loadingAllEvent } = useServiceState(
    APIService.activityLogService.fetchDataEvent,
  )
  const { invoke: getLogEvent, data: dataLogEvent, loading } = useServiceState(
    APIService.activityLogService.fetchDataEvent,
  )

  const [showAll, setShowAll] = React.useState(false)

  const [page, setPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(10)
  const [sortBy, setSortBy] = React.useState<string | undefined>()
  const [orderBy, setOrderBy] = React.useState<string | undefined>()

  const resolvedType = React.useMemo(() => {
    switch (type) {
      case PERFORMANCE_REQUEST_ENUM.ROR:
        return 'UPDATE_FUND_RORS'
      case PERFORMANCE_REQUEST_ENUM.NAV:
        return 'UPDATE_FUND_NAVS'
      case PERFORMANCE_REQUEST_ENUM.ASSET:
        return 'UPDATE_FUND_ASSETS'
      default:
        return undefined
    }
  }, [type])

  // refetch events when flag `fundRorUpdated` is true, triggered by successfully update Fund ROR or Latest Performance
  React.useEffect(() => {
    if (fundRorUpdated) {
      getLogEvent({
        where: {
          code: 'UPDATE_FUND_RORS',
          fund_id: { equals: fundId },
        },
        limit: 100,
        page: 1,
        order_by: 'DESC',
        sort_by: 'created_date',
        show_json_data: true,
      })

      getAllLogEvent({
        where: {
          code: resolvedType,
          fund_id: { equals: fundId },
        },
        limit: pageSize,
        page,
        order_by: orderBy || 'DESC',
        sort_by: sortBy || 'created_date',
        show_json_data: true,
      })
      dispatch(resetROR())
    }
  }, [dispatch, fundId, fundRorUpdated, getAllLogEvent, getLogEvent, orderBy, page, pageSize, resolvedType, sortBy])

  React.useEffect(() => {
    if (fundNavUpdated) {
      getLogEvent({
        where: {
          code: 'UPDATE_FUND_NAVS',
          fund_id: { equals: fundId },
        },
        limit: 100,
        page: 1,
        order_by: 'DESC',
        sort_by: 'created_date',
        show_json_data: true,
      })

      getAllLogEvent({
        where: {
          code: resolvedType,
          fund_id: { equals: fundId },
        },
        limit: pageSize,
        page,
        order_by: orderBy || 'DESC',
        sort_by: sortBy || 'created_date',
        show_json_data: true,
      })
      dispatch(resetNav())
    }
  }, [dispatch, fundId, fundNavUpdated, getAllLogEvent, getLogEvent, orderBy, page, pageSize, resolvedType, sortBy])

  React.useEffect(() => {
    if (fundAssetUpdated) {
      getLogEvent({
        where: {
          code: 'UPDATE_FUND_ASSETS',
          fund_id: { equals: fundId },
        },
        limit: 100,
        page: 1,
        order_by: 'DESC',
        sort_by: 'created_date',
        show_json_data: true,
      })

      getAllLogEvent({
        where: {
          code: resolvedType,
          fund_id: { equals: fundId },
        },
        limit: pageSize,
        page,
        order_by: orderBy || 'DESC',
        sort_by: sortBy || 'created_date',
        show_json_data: true,
      })
      dispatch(resetAsset())
    }
  }, [dispatch, fundId, fundAssetUpdated, getAllLogEvent, getLogEvent, orderBy, page, pageSize, resolvedType, sortBy])

  React.useEffect(() => {
    getAllLogEvent({
      where: {
        code: resolvedType,
        fund_id: { equals: fundId },
      },
      limit: pageSize,
      page,
      order_by: orderBy || 'DESC',
      sort_by: sortBy || 'created_date',
      show_json_data: true,
    })
  }, [getAllLogEvent, fundId, orderBy, page, pageSize, resolvedType, sortBy])

  React.useEffect(() => {
    getLogEvent({
      where: {
        code: resolvedType,
        fund_id: { equals: fundId },
      },
      limit: 100,
      page: 1,
      order_by: 'DESC',
      sort_by: 'created_date',
      show_json_data: true,
    })
  }, [fundId, getLogEvent, resolvedType])

  React.useEffect(() => {
    setSelectedDate && setSelectedDate(selectedDate)
  }, [selectedDate, setSelectedDate])

  React.useEffect(() => {
    if (selectedDate) {
      setShowAll(false)
    }
  }, [selectedDate])

  const handlePageSizeChange = (current: number, size: number) => {
    setPageSize(size)
    if (current !== 1) {
      setPage(1)
    }
  }

  const handleTableChange = (sorter: SorterResult<any>) => {
    setSortBy(sorter.order ? sorter.columnKey?.toString() : undefined)
    setOrderBy(sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : undefined)
  }

  const allHistoricalData = React.useMemo(() => {
    if (!dataAllEvent) {
      return []
    }
    const dataSource: HistoricalDataTableType[] = []
    dataAllEvent.events.map((event) => {
      const changedDate = event.change_data
      changedDate &&
        Object.keys(changedDate).length > 0 &&
        Object.keys(changedDate).map((dateAsKey) => {
          dataSource.push({
            yyyymm: dateAsKey,
            date: event.created_date,
            old: changedDate[dateAsKey].old,
            new: changedDate[dateAsKey].new,
            author: event.user__username,
          })
        })
    })
    return dataSource
  }, [dataAllEvent])

  const historicalDataSource = React.useMemo(() => {
    if (!dataLogEvent || !selectedDate) {
      return []
    }
    const dataSource: HistoricalDataTableType[] = []
    dataLogEvent.events.map((event) => {
      const selectedDateAsKey = selectedDate.replace('-', '0')
      event.change_data &&
        selectedDateAsKey in event.change_data &&
        dataSource.push({
          date: event.created_date,
          old: event.change_data[selectedDateAsKey].old,
          new: event.change_data[selectedDateAsKey].new,
          author: event.user__username,
        })
    })
    return dataSource
  }, [dataLogEvent, selectedDate])

  const handleExportHistorical = (data: HistoricalDataTableType[]) => {
    JsFileDownload(Papa.unparse(data), `historical-data.csv`, 'application/csv')
  }

  return (
    <Wrapper>
      <DividerForm text="Historical Data" />
      <Row>
        <DatePicker
          picker="month"
          placeholder="Select Month"
          value={selectedDate ? moment(selectedDate) : undefined}
          onChange={(e) => {
            setSelectedDate && setSelectedDate(e ? moment(e).format('YYYY-MM') : undefined)
          }}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="link"
          onClick={() => {
            setShowAll(true)
            setSelectedDate && setSelectedDate(undefined)
          }}
        >
          Show All Events
        </Button>
      </Row>
      {selectedDate && <h4>Top 100 Events For {selectedDate}</h4>}
      {showAll && <h4>All Events</h4>}
      {showAll ? (
        <Table
          rowKey="date"
          loading={loadingAllEvent}
          dataSource={allHistoricalData}
          columns={[
            {
              title: 'Updated Date',
              dataIndex: 'date',
              key: 'date',
            },
            {
              title: 'YYYYMM',
              dataIndex: 'yyyymm',
              key: 'yyyymm',
            },
            {
              title: 'Old Value',
              dataIndex: 'old',
              key: 'old',
            },
            {
              title: 'New Value',
              dataIndex: 'new',
              key: 'new',
            },
            {
              title: 'Author',
              dataIndex: 'author',
              key: 'author',
            },
          ]}
          size="small"
          pagination={false}
          onChange={(_pagination, _filter, sorter: any) => {
            handleTableChange(sorter)
          }}
          footer={(record) =>
            record.length > 0 ? (
              <Footer>
                <Button
                  type="primary"
                  onClick={() => handleExportHistorical(allHistoricalData)}
                  disabled={allHistoricalData.length === 0}
                >
                  Export
                </Button>

                <Pagination
                  showSizeChanger
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                  onChange={(currentPage) => setPage(currentPage)}
                  total={dataLogEvent ? dataLogEvent.total : 0}
                  current={page}
                  pageSize={pageSize}
                  pageSizeOptions={['10', '25', '50', '100']}
                  onShowSizeChange={handlePageSizeChange}
                />
              </Footer>
            ) : null
          }
        />
      ) : (
        <>
          <Table
            rowKey="date"
            loading={loading}
            dataSource={historicalDataSource}
            columns={[
              {
                title: 'Updated Date',
                dataIndex: 'date',
                key: 'date',
              },
              {
                title: 'Old Value',
                dataIndex: 'old',
                key: 'old',
              },
              {
                title: 'New Value',
                dataIndex: 'new',
                key: 'new',
              },
              {
                title: 'Author',
                dataIndex: 'author',
                key: 'author',
              },
            ]}
          />
          <Button
            type="primary"
            style={{ position: 'absolute', bottom: 16, left: 10 }}
            onClick={() => handleExportHistorical(historicalDataSource)}
            disabled={historicalDataSource.length === 0}
          >
            Export
          </Button>
        </>
      )}
    </Wrapper>
  )
}

export default HistoricalData
