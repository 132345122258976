import { Col, Form, notification, Row, Spin } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import APIService from '../../../../../shared/api'
import {
  PrincipalCreateParams,
  PrincipalsResponse,
  PrincipalsUpdateParams,
} from '../../../../../shared/api/models/FundDetails'
import { DividerForm } from '../../../../../shared/components/DividerForm'
import { FormItemInput } from '../../../../../shared/components/FormItemComponents'

type Props = {
  selectedPrincipals: PrincipalsResponse | undefined
  showModal: boolean
  setShowModal: (value: boolean) => void
  setSelectedPrincipals: (value: PrincipalsResponse | undefined) => void
  updatePrincipals: () => void
  fundId: string
}
export const ModalEditPrincipals: React.FC<Props> = ({
  selectedPrincipals,
  showModal,
  setShowModal,
  setSelectedPrincipals,
  updatePrincipals,
  fundId,
}) => {
  const [loadingSubmit, setLoadingSubmit] = React.useState(false)
  const [form] = Form.useForm()

  React.useEffect(() => {
    if (selectedPrincipals) {
      form.setFieldsValue(selectedPrincipals)
    } else {
      form.resetFields()
    }
  }, [form, selectedPrincipals])

  const handleUpdateRow = () => {
    form.validateFields(['rank', 'name', 'title']).then(() => {
      if (selectedPrincipals) {
        const submitUpdate: Partial<PrincipalsUpdateParams> = {
          identifier: [fundId, selectedPrincipals.rank, selectedPrincipals.name],
          rank: form.getFieldValue('rank'),
          name: form.getFieldValue('name'),
          title: form.getFieldValue('title'),
        }
        setLoadingSubmit(true)
        APIService.fundDetailService
          .updateFundPrincipals(fundId, submitUpdate)
          .then(() => {
            notification.success({ message: 'Pricipal Updated!' })
            updatePrincipals()
          })
          .catch((err) => {
            console.error({ err })
            notification.error({ message: 'Pricipal Update Failed!' })
          })
          .finally(() => {
            setLoadingSubmit(false)
            setShowModal(false)
            setSelectedPrincipals(undefined)
            form.resetFields()
          })
      } else {
        setLoadingSubmit(true)
        const submitData: PrincipalCreateParams = {
          fund_id: fundId,
          rank: form.getFieldValue('rank'),
          name: form.getFieldValue('name'),
          title: form.getFieldValue('title'),
        }
        APIService.fundDetailService
          .createFundPrincipals(fundId, submitData)
          .then(() => {
            notification.success({ message: 'Principal Created!' })
            updatePrincipals()
          })
          .catch((err) => {
            console.error({ err })
            notification.error({ message: 'Principal Create Failed!' })
          })
          .finally(() => {
            setLoadingSubmit(false)
            setShowModal(false)
            setSelectedPrincipals(undefined)
            form.resetFields()
          })
      }
    })
  }
  return (
    <Modal
      title={selectedPrincipals ? 'Edit Principal' : 'Create New Principal'}
      visible={showModal}
      okText={
        loadingSubmit ? (
          <>
            <Spin style={{ marginRight: 4 }} />
          </>
        ) : (
          'OK'
        )
      }
      onOk={() => handleUpdateRow()}
      onCancel={() => {
        setSelectedPrincipals(undefined)
        setShowModal(false)
      }}
    >
      <div>
        <DividerForm text="Principal Information" />
        <Form layout="vertical" form={form}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <FormItemInput
                name="rank"
                label="Rank"
                placeholder="Rank"
                typeField="number"
                normalize="number"
                rules={[{ required: true, message: 'Rank is required!' }]}
              />
            </Col>
            <Col span={24}>
              <FormItemInput
                name="name"
                label="Name"
                placeholder="Name"
                maxLength={60}
                rules={[{ required: true, message: 'Principal Name is required!' }]}
              />
            </Col>
            <Col span={24}>
              <FormItemInput
                name="title"
                label="Title"
                placeholder="Title"
                maxLength={60}
                rules={[{ required: true, message: 'Principal Title is required!' }]}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
