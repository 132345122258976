import { DeleteTwoTone, EditOutlined } from '@ant-design/icons'
import { Button, notification, Popconfirm, Spin, Table, Tabs, Tooltip } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import moment from 'moment'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import APIService from '../../shared/api'
import { SearchQueryList } from '../../shared/api/models/Funds'
import { Colors } from '../../shared/colors'
import { useServiceState } from '../../shared/hooks/useServiceState'
import { ModalEditQuerySearch } from '../Dashboard/DashboardTab/ModalEditQuerySearch'
import { updateFundSearchParams } from '../Dashboard/DashboardTab/SearchForm/redux/searchParamsSlice'
const StyledTabs = styled(Tabs)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;

  .ant-tabs-nav {
    margin-bottom: 0%;
  }

  .ant-tabs-content-holder {
    overflow: hidden;
  }

  .ant-tabs-content {
    flex-grow: 1;
    background: white;
    border-left: solid 1px ${Colors.border};
    border-right: solid 1px ${Colors.border};
    border-bottom: solid 1px ${Colors.border};
    height: 100%;

    .ant-tabs-tabpane-active {
      height: 100%;
      display: flex;
    }

    .ant-card-head {
      min-height: auto;
    }
  }
`

export const SearchQueryManage: React.FC = () => {
  const { invoke: fetchSearchQuery, data: dataSearchQuery, loading } = useServiceState(
    APIService.fundService.fetchFundSearchQuery,
  )

  const [showModal, setShowModal] = React.useState(false)
  const [selectedQuery, setSelectedQuery] = React.useState<SearchQueryList | undefined>()
  const [loadingDelete, setLoadingDelete] = React.useState(false)
  const [idDelete, setIdDelete] = React.useState<number>()

  const dispatch = useDispatch()
  const history = useHistory()

  React.useEffect(() => {
    fetchSearchQuery()
  }, [fetchSearchQuery])

  const refetchSearchQuery = () => {
    fetchSearchQuery()
  }

  const deleteQuery = (id: number) => {
    setLoadingDelete(true)
    APIService.fundService
      .deleteFundSearchQueryDetail(id)
      .then(() => {
        notification.success({ message: 'Search Query Delete!' })
        refetchSearchQuery()
      })
      .catch((err) => {
        console.error({ err })
        notification.error({ message: 'Search Query Delete Failed!' })
      })
      .finally(() => {
        setLoadingDelete(false)
      })
  }

  const handleSelectQuery = async (id: number) => {
    try {
      const res = await APIService.fundService.fetchFundSearchQueryDetail(id)
      res.data.json && dispatch(updateFundSearchParams(res.data.json))
      history.push('/')
    } catch (error) {
      console.error(error)
      notification.error({ message: 'Failed to get query detail!' })
    }
  }

  const columns: ColumnProps<SearchQueryList>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',

      sorter: (a, b) => {
        if (a.id && b.id) {
          return a.id - b.id
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => (
        <span
          style={{ cursor: 'pointer', color: Colors.primary }}
          onClick={() => {
            handleSelectQuery(record.id)
          }}
        >
          {value}
        </span>
      ),
      sorter: (a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name)
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'User Name',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Created Date',
      dataIndex: 'created_date',
      key: 'created_date',
      render: (text) => <div>{moment(text).format('YYYY-MM-DD')}</div>,
    },
    {
      title: 'Updated Date',
      dataIndex: 'updated_date',
      key: 'updated_date',
      render: (text) => <div>{moment(text).format('YYYY-MM-DD')}</div>,
    },

    {
      title: 'Action',
      dataIndex: 'action',
      width: 200,

      render: (_, record) =>
        loadingDelete && record.id === idDelete ? (
          <Spin />
        ) : (
          <>
            <Button
              type="link"
              style={{ padding: '4px 8px' }}
              onClick={() => {
                setSelectedQuery(record)
                setShowModal(true)
              }}
            >
              <Tooltip title="Edit">
                <EditOutlined />
              </Tooltip>
            </Button>
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => {
                setIdDelete(record.id)
                deleteQuery(record.id)
              }}
            >
              <Button type="link" style={{ padding: '4px 8px' }}>
                <Tooltip title="Delete">
                  <DeleteTwoTone twoToneColor={Colors.danger} />
                </Tooltip>
              </Button>
            </Popconfirm>
          </>
        ),
    },
  ]

  return (
    <StyledTabs hideAdd type="editable-card">
      <Tabs.TabPane
        tab="Manage Search Query"
        closable={false}
        key="index-list"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Table
          rowKey="id"
          scroll={{ y: 'calc(100vh - 320px)', x: 'min-content' }}
          dataSource={dataSearchQuery}
          columns={columns}
          loading={loading}
          size="small"
          pagination={{ defaultPageSize: 20 }}
        />
      </Tabs.TabPane>
      {selectedQuery && showModal && (
        <ModalEditQuerySearch
          showModal={showModal}
          setShowModal={setShowModal}
          selectedQuery={{ name: selectedQuery.name, id: selectedQuery.id }}
          refetchQuery={refetchSearchQuery}
        />
      )}
    </StyledTabs>
  )
}
