import React from 'react'

import EditableContext from './EditableContext'
import { Form } from 'antd'
type Props = {}

/**
 * Editable Row  represent a row in Editable Table, that is wrapped with React context to provide the `form` to all its cells
 * @param restProps - all other props provided by the Table's components
 */
const EditableFormRow: React.FC<Props> = ({ ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

export default EditableFormRow
