import { Collapse } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../../../app/rootReducer'
import { FundDetailDataContext } from '../../../../shared/api/context/FundDetailDataContext'
import { FundDetailLabelSearchContext } from '../../../../shared/api/context/FundDetailLabelSearchContext'
import { NextBackButton } from '../../../../shared/components/NextBackButton'
import { RenderLabelForm } from '../../../../shared/components/RenderLabelForm'
import { CollapseFundEnum, LeftNavFundEnum, renderSearchListFund } from '../../../../shared/SearchFundLabel'
import { HFRArchiveNotes } from './HFRArchiveNotes'
import { HFRInvestmentInfo } from './HFRInvestmentInfo'
import { HFRNotes } from './HFRNotes'
const Wrapper = styled.div`
  display: flex;
  flex: 1;
`
const { Panel } = Collapse
const StyledCollapse = styled(Collapse)`
  .ant-collapse-content-box {
    padding: 0;
  }
`

const LIST_COLLAPSE = [
  {
    id: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    id: CollapseFundEnum.HFR_AM_INFO_NOTE,
  },
  {
    id: CollapseFundEnum.HFR_AM_INFO_ARCHIVE_NOTES,
  },
]
export const HFRAMInfoMain: React.FC = () => {
  const { isFiltering, filterArr } = React.useContext(FundDetailLabelSearchContext)

  const arrCollapse = React.useMemo(() => {
    return isFiltering
      ? LIST_COLLAPSE.filter(
          (item) =>
            filterArr
              .filter((item) => item.leftNav === LeftNavFundEnum.HFR_AM_INFO)
              .findIndex((i) => i.collapse && i.collapse === item.id) > -1,
        )
      : LIST_COLLAPSE
  }, [filterArr, isFiltering])

  const [activeCollapse, setActiveCollapse] = React.useState<string | string[]>(CollapseFundEnum.INVESTMENT_INFO)
  const { dataFundDetail } = React.useContext(FundDetailDataContext)

  const appSetting = useSelector((state: RootState) => state.appSettings)
  const lastKey = appSetting.fundDraft.find((item) => item.id === dataFundDetail?.fund_id)?.lastFieldChange

  React.useEffect(() => {
    if (lastKey) {
      const activeCollapse = renderSearchListFund.find((item) => item.key === lastKey)?.collapse
      activeCollapse &&
        LIST_COLLAPSE.findIndex((item) => item.id === activeCollapse) > -1 &&
        setActiveCollapse((prev) => {
          if (prev.includes(activeCollapse)) {
            return prev
          }
          return activeCollapse
        })
    }
  }, [lastKey])

  React.useEffect(() => {
    if (isFiltering) {
      setActiveCollapse(
        arrCollapse.map((item) => {
          return item.id
        }),
      )
    }
  }, [arrCollapse, isFiltering])

  const renderCollapse = (id: CollapseFundEnum) => {
    switch (id) {
      case CollapseFundEnum.INVESTMENT_INFO:
        return <HFRInvestmentInfo />
      case CollapseFundEnum.HFR_AM_INFO_NOTE:
        return <HFRNotes />
      case CollapseFundEnum.HFR_AM_INFO_ARCHIVE_NOTES:
        return <HFRArchiveNotes />
      default:
        break
    }
  }
  return (
    <Wrapper style={{ flexDirection: 'column' }}>
      <StyledCollapse
        style={{ margin: '1rem' }}
        activeKey={activeCollapse}
        onChange={(key) => (key ? setActiveCollapse(key) : setActiveCollapse([]))}
      >
        {arrCollapse.map((item) => {
          return (
            <Panel header={<RenderLabelForm value={item.id} />} key={item.id}>
              {renderCollapse(item.id)}
            </Panel>
          )
        })}
      </StyledCollapse>
      <NextBackButton />
    </Wrapper>
  )
}
