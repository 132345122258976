import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

const FFPerfectScrollbar: React.FC = ({ children }) => (
  <PerfectScrollbar
    // Fix for scrolling bug in Firefox
    containerRef={(ref) => {
      if (ref) {
        // @ts-ignore
        ref._getBoundingClientRect = ref.getBoundingClientRect

        ref.getBoundingClientRect = () => {
          // @ts-ignore
          const original = ref._getBoundingClientRect()

          return { ...original, height: Math.round(original.height) }
        }
      }
    }}
  >
    {children}
  </PerfectScrollbar>
)

export default FFPerfectScrollbar
