import { Collapse } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../../../../app/rootReducer'
import { FundDetailDataContext } from '../../../../../shared/api/context/FundDetailDataContext'
import { FundDetailLabelSearchContext } from '../../../../../shared/api/context/FundDetailLabelSearchContext'
import { NextBackButton } from '../../../../../shared/components/NextBackButton'
import { RenderLabelForm } from '../../../../../shared/components/RenderLabelForm'
import {
  CollapseFundEnum,
  LeftNavFundEnum,
  renderSearchListFund,
  RightTabFundEnum,
} from '../../../../../shared/SearchFundLabel'
import { InvestmentInfo } from './InvestmentInfo'
import { StrategyDescription } from './StrategyDescription'

const Flex = styled.div`
  display: flex;
  flex: 1;
`
const { Panel } = Collapse

const COLLAPSE_LIST = [
  {
    id: CollapseFundEnum.INVESTMENT_INFO,
  },
  {
    id: CollapseFundEnum.STRATEGY_DESCRIPTION,
  },
]
type Props = { form: FormInstance }
export const FundInvestmentInfoMain: React.FC<Props> = ({ form }) => {
  const { isFiltering, filterArr } = React.useContext(FundDetailLabelSearchContext)

  const arrCollapse = React.useMemo(() => {
    return isFiltering
      ? COLLAPSE_LIST.filter(
          (item) =>
            filterArr
              .filter(
                (item) =>
                  item.leftNav === LeftNavFundEnum.INFORMATION &&
                  item.rightTab === RightTabFundEnum.FUND_INVESTMENT_INFO,
              )
              .findIndex((i) => i.collapse && i.collapse === item.id) > -1,
        )
      : COLLAPSE_LIST
  }, [filterArr, isFiltering])

  const [activeCollapse, setActiveCollapse] = React.useState<string | string[]>(CollapseFundEnum.INVESTMENT_INFO)

  React.useEffect(() => {
    if (isFiltering) {
      setActiveCollapse(
        arrCollapse.map((item) => {
          return item.id
        }),
      )
    }
  }, [arrCollapse, isFiltering])

  const { dataFundDetail } = React.useContext(FundDetailDataContext)

  const appSetting = useSelector((state: RootState) => state.appSettings)
  const lastKey = appSetting.fundDraft.find((item) => item.id === dataFundDetail?.fund_id)?.lastFieldChange

  React.useEffect(() => {
    if (lastKey) {
      const activeCollapse = renderSearchListFund.find((item) => item.key === lastKey)?.collapse
      activeCollapse &&
        COLLAPSE_LIST.findIndex((item) => item.id === activeCollapse) > -1 &&
        setActiveCollapse((prev) => {
          if (prev.includes(activeCollapse)) {
            return prev
          }
          return activeCollapse
        })
    }
  }, [lastKey])

  const renderCollapse = (id: CollapseFundEnum) => {
    switch (id) {
      case CollapseFundEnum.INVESTMENT_INFO:
        return <InvestmentInfo form={form} />
      case CollapseFundEnum.STRATEGY_DESCRIPTION:
        return <StrategyDescription />
      default:
        break
    }
  }
  return (
    <Flex style={{ flexDirection: 'column' }}>
      <Collapse
        style={{ margin: '0 0.5rem' }}
        activeKey={activeCollapse}
        onChange={(key) => (key ? setActiveCollapse(key) : setActiveCollapse([]))}
      >
        {arrCollapse.map((item) => {
          return (
            <Panel header={<RenderLabelForm value={item.id} />} key={item.id}>
              {renderCollapse(item.id)}
            </Panel>
          )
        })}
      </Collapse>
      <NextBackButton />
    </Flex>
  )
}
