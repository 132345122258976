import { AxiosInstance, AxiosPromise } from 'axios'
import { IndexManagerDetailType, IndexManagerDetailUpdate, IndexManagerType } from '../models/IndexManager'

export interface IndexManagerService {
  getAllIndexManager: () => AxiosPromise<IndexManagerType[]>
  createIndexManager: (name: string, description: string, index_eligibility?: boolean) => AxiosPromise<void>
  updateIndexManager: (
    indexId: number,
    name: string,
    description: string,
    index_eligibility?: boolean,
  ) => AxiosPromise<void>
  deleteIndexManager: (indexId: number) => AxiosPromise<void>
  getIndexManagerDetail: (indexId: number) => AxiosPromise<IndexManagerDetailType>
  updateIndexManagerDetail: (indexId: number, params: Partial<IndexManagerDetailUpdate>) => AxiosPromise<void>
}
export const IndexManagerService = (request: AxiosInstance): IndexManagerService => {
  return {
    getAllIndexManager: () => request.get(`/manager-link/hfr-indices`),
    createIndexManager: (name, description, index_eligibility) =>
      request.post(`/manager-link/hfr-indices`, { name, description, index_eligibility }),
    updateIndexManager: (indexId, name, description, index_eligibility) =>
      request.put(`/manager-link/hfr-indices/${indexId}`, { name, description, index_eligibility }),
    deleteIndexManager: (indexId) => request.delete(`/manager-link/hfr-indices/${indexId}`),
    getIndexManagerDetail: (indexId) => request.get(`/manager-link/hfr-indices/${indexId}`),
    updateIndexManagerDetail: (indexId, params) => request.put(`/manager-link/hfr-indices/${indexId}`, params),
  }
}
