import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ActivitiesFiltered } from '../../../../../shared/api/models/ActivityLog'

export interface SearchParamsType {
  fundSearchParams: {
    [key: string]: { value: string[]; type?: 'equals' | 'contains' | 'array_anyof' }
  }
  exFundSearchParams: Record<string, React.ReactText[] | null>

  firmSearchParams: {
    [key: string]: { value: string[]; type?: 'equals' | 'contains' | 'array_anyof' }
  }
  activityLogParams: ActivitiesFiltered
}

const initialState: SearchParamsType = {
  fundSearchParams: {},
  exFundSearchParams: {},
  firmSearchParams: {},
  activityLogParams: {},
}

const searchParams = createSlice({
  name: 'searchParams',
  initialState,
  reducers: {
    updateFundSearchParams: (state, action: PayloadAction<SearchParamsType['fundSearchParams']>) => {
      state.fundSearchParams = action.payload
    },
    updateExFundSearchParams: (state, action: PayloadAction<SearchParamsType['exFundSearchParams']>) => {
      state.exFundSearchParams = action.payload
    },
    updateFirmSearchParams: (state, action: PayloadAction<SearchParamsType['firmSearchParams']>) => {
      state.firmSearchParams = action.payload
    },
    updateActivityLogParams: (state, action: PayloadAction<SearchParamsType['activityLogParams']>) => {
      state.activityLogParams = action.payload
    },
  },
})

const { reducer, actions } = searchParams

export const {
  updateFundSearchParams,
  updateExFundSearchParams: updateExSearchParams,
  updateFirmSearchParams,
  updateActivityLogParams,
} = actions
export default reducer
