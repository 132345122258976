import { RcFile } from 'antd/lib/upload'
import { AxiosInstance, AxiosPromise } from 'axios'
import { RORResponseType } from '../../components/DataSheet'
import { sanatizeToSnakeCase } from '../../strings'
import { ExFundDetails } from '../models/ExFundDetails'
import { FetchFundParams } from '../models/FetchFundParams'
import {
  FirmDetailResponse,
  FundAllInfo,
  FundDetailsPreviewType,
  FundSearchParams,
  FundSearchResponse,
  ListFundIdResponse,
} from '../models/FundDetails'
import {
  BulkUpdateFund,
  FavoritedFundsResponse,
  FavoritedFundUpdate,
  FundSearchColumnSourceResponse,
  RecentlyEditedResponse,
  RORImportResponse,
  SearchQueryList,
} from '../models/Funds'
import { AUMRecord, ListResponse, ProfileStats, RORDetails, RORRecord } from '../models/ResponsesTypes'
import { FlaggedDataType } from './fund-detail.service'
import { MinorityType } from './minority.service'

export interface FetchFundPdfParams {
  start_date: string
  end_date: string
  benchmark1: string
  benchmark2: string
  benchmark3: string
}

export interface LatestPerformanceResponse {
  latest_ror: string | null
  latest_ror_value: number | null
  latest_asset: string | null
  latest_asset_value: number | null
  latest_nav: string | null
  latest_nav_value: number | null
  curr_code: string | null
}

export interface LatestPerformanceRequest {
  rors?: RORResponseType[]
  navs?: RORResponseType[]
  assets?: RORResponseType[]
}

export interface FundService {
  fetchRORDetails: ({
    fundId,
    fromDate,
    toDate,
  }: {
    fundId: string
    fromDate?: string
    toDate?: string
  }) => AxiosPromise<RORDetails>
  fetchAUMDetails: ({
    fundId,
    fromDate,
    toDate,
  }: {
    fundId: string
    fromDate?: string
    toDate?: string
  }) => AxiosPromise<ListResponse<AUMRecord>>
  fetchRORDetailsForBenchmarks: ({
    fundIds,
    fromDate,
    toDate,
  }: {
    fundIds: string[]
    fromDate?: string
    toDate?: string
  }) => AxiosPromise<ListResponse<RORRecord>>

  fetchFunds: (params: Partial<FetchFundParams>) => AxiosPromise<ListResponse<FundAllInfo>>
  fetchExFunds: () => AxiosPromise<ExFundDetails[]>
  fetchFundName: ({ text }: { text: string }) => AxiosPromise<{ fund_id: string; fund_name: string }[]>
  fetchFundIds: (params: Partial<FetchFundParams>) => AxiosPromise<ListResponse<string>>
  exportFunds: (params: Partial<FetchFundParams>) => AxiosPromise<Blob>
  generateFundPdfReport: ({ fundId, data }: { fundId: string; data: FetchFundPdfParams }) => AxiosPromise<Blob>

  fetchFirmDetails: (firmId: string) => AxiosPromise<FirmDetailResponse>
  fetchProfileStatsDetail: ({
    fundId,
    benchmark1,
    benchmark2,
    benchmark3,
    startDate,
    endDate,
  }: {
    fundId: string
    benchmark1: string
    benchmark2: string
    benchmark3: string
    startDate?: string
    endDate?: string
  }) => AxiosPromise<ProfileStats>
  fetchStateCodes: () => AxiosPromise<string[]>
  fetchRORDateRange: () => AxiosPromise<{
    start_date: string
    end_date: string
  }>
  fetchFundDetails: (fundId: string) => AxiosPromise<FundDetailsPreviewType>
  fetchFundPdfReport: ({ fundId, data }: { fundId: string; data: FetchFundPdfParams }) => AxiosPromise<Blob>
  bulkUpdateFund: (params: Partial<BulkUpdateFund>) => AxiosPromise<void>
  fetchRecentlyEdited: () => AxiosPromise<RecentlyEditedResponse>
  fetchFavorited: () => AxiosPromise<FavoritedFundsResponse[]>
  updateFavorited: (params: Partial<FavoritedFundUpdate>) => AxiosPromise<void>
  fetchListCreatedFund: (params?: { all_users: boolean }) => AxiosPromise<RecentlyEditedResponse>
  fetchListUpdateFund: (params?: { all_users: boolean }) => AxiosPromise<RecentlyEditedResponse>
  //Fund Search
  searchFunds: (params: Partial<FundSearchParams>) => AxiosPromise<FundSearchResponse>
  fetchFundSearchAllFundId: (params: Partial<FundSearchParams>) => AxiosPromise<ListFundIdResponse>
  fetchAllField: () => AxiosPromise<FundSearchColumnSourceResponse>
  //Import, Export ROR
  fetchRORExport: (tableName: string, id: string) => AxiosPromise
  uploadRORImport: (file: RcFile) => AxiosPromise<RORImportResponse[]>
  //Fund Search Query
  fetchFundSearchQuery: () => AxiosPromise<SearchQueryList[]>
  createFundSearchQuery: (name: string, json: string) => AxiosPromise<void>
  fetchMeFundSearch: () => AxiosPromise<SearchQueryList[]>
  fetchFundSearchMinorityType: () => AxiosPromise<MinorityType[]>

  fetchFundSearchQueryDetail: (queryId: number) => AxiosPromise
  updateFundSearchQueryDetail: (queryId: number, name: string, json: string) => AxiosPromise<void>
  deleteFundSearchQueryDetail: (queryId: number) => AxiosPromise<void>
  fetchLatestPerformance: (fundId: string) => AxiosPromise<LatestPerformanceResponse>
  updateLatestPerformance: (fundId: string, latestPerformanceData: LatestPerformanceRequest) => AxiosPromise

  validateLatestPerformance: (
    fundId: string,
    latestPerformanceData: LatestPerformanceRequest,
  ) => AxiosPromise<{ flagged_rors: FlaggedDataType; flagged_assets: FlaggedDataType }>
  // Export File
  postDownloadFile: (params: {
    file_type: string
    data: Partial<FundSearchParams & { fund_id: string }>
  }) => AxiosPromise<{ url: string }>
  downloadFileByUrl: (url: string) => AxiosPromise
}

export const FundService = (request: AxiosInstance): FundService => {
  return {
    fetchRORDetails: ({ fundId, fromDate, toDate }) =>
      request.get(`/funds-api/fund/${fundId.trim()}/ror`, {
        params: {
          manager_link: true,
          from: fromDate ? fromDate : null,
          to: toDate ? toDate : null,
        },
      }),
    fetchAUMDetails: ({ fundId, fromDate, toDate }) =>
      request.get(`/funds-api/fund/${fundId.trim()}/aum`, {
        params: {
          manager_link: true,
          from: fromDate ? fromDate : null,
          to: toDate ? toDate : null,
        },
      }),
    fetchRORDetailsForBenchmarks: ({ fundIds, fromDate, toDate }) =>
      request.get('/funds-api/fund/ror', {
        params: {
          fund_ids: JSON.stringify(fundIds.map((id) => Number(id.trim()))),
          from: fromDate ? fromDate : null,
          to: toDate ? toDate : null,
        },
      }),

    fetchFunds: (params: Partial<FetchFundParams>) =>
      request.post('/funds-api/fund', { ...sanatizeToSnakeCase(params) }),
    fetchFundDetails: (fundId) => request.get(`/funds-api/fund/${fundId.trim()}`, { params: { manager_link: true } }),
    fetchExFunds: () => request.get(`manager-link/funds`),
    fetchFundName: ({ text }) => request.get(`/manager-link/funds/name-search`, { params: { query: text, page: 1 } }),
    fetchFundIds: (params: Partial<FetchFundParams>) =>
      request.post('/funds-api/fund/ids', { ...sanatizeToSnakeCase(params) }),
    exportFunds: (params: Partial<FetchFundParams>) =>
      request.post(
        '/funds-api/fund/export',
        { ...sanatizeToSnakeCase(params) },
        {
          responseType: 'blob',
        },
      ),
    generateFundPdfReport: ({ fundId, data }: { fundId: string; data: FetchFundPdfParams }) =>
      request.post(`/funds-api/fund/${fundId}/pdf`, data, {
        responseType: 'blob',
      }),

    fetchFirmDetails: (firmId) => request.get(`/funds-api/firm/${firmId.trim()}`),
    fetchProfileStatsDetail: ({ fundId, benchmark1, benchmark2, benchmark3, startDate, endDate }) => {
      return request.get(`/funds-api/fund/${parseInt(fundId)}/profile-stats`, {
        params: {
          manager_link: true,
          benchmark1: parseInt(benchmark1, 10),
          benchmark2: parseInt(benchmark2, 10),
          benchmark3: parseInt(benchmark3, 10),
          start_date: startDate ? startDate : null,
          end_date: endDate ? endDate : null,
        },
      })
    },
    fetchStateCodes: () => request.get('/funds-api/data/state-codes'),
    fetchRORDateRange: () => request.get('/funds-api/data/ror-date-range'),
    fetchFundPdfReport: ({ fundId, data }: { fundId: string; data: FetchFundPdfParams }) =>
      request.post(
        `/funds-api/fund/${fundId}/pdf`,
        { ...data, manager_link: true },
        {
          responseType: 'blob',
        },
      ),
    bulkUpdateFund: (params) => request.post(`/manager-link/bulk-update`, params),
    fetchRecentlyEdited: () => request.get(`/manager-link/recently-edited-funds`),
    fetchFavorited: () => request.get(`/manager-link/favorited-funds`),
    updateFavorited: (params) => request.put(`/manager-link/favorited-funds`, params),
    fetchListCreatedFund: (params) =>
      request.get(`/manager-link/created-funds`, params ? { params: params } : undefined),

    fetchListUpdateFund: (params) =>
      request.get(`/manager-link/updated-funds`, params ? { params: params } : undefined),

    //Fund Search
    searchFunds: (params) => request.post(`/manager-link/funds/search`, params),
    fetchFundSearchAllFundId: (params) => request.post(`/manager-link/funds/search/fund_ids`, params),
    fetchAllField: () => request.get(`/manager-link/funds/search/fields-metadata`),
    //Import, Export ROR
    fetchRORExport: (tableName, id) =>
      request.post(`/manager-link/rorlike-tables/csv-export`, { table_name: tableName, fund_or_firm_id: id }),
    uploadRORImport: (file) => {
      const formData = new FormData()
      formData.append('csv_file', file)
      return request.post(`/manager-link/rorlike-tables/csv-import`, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
    },
    fetchFundSearchMinorityType: () => request.get(`/manager-link/minority-types`),
    fetchFundSearchQuery: () => request.get(`/manager-link/query`),
    fetchMeFundSearch: () => request.get(`/manager-link/query/me`),
    createFundSearchQuery: (name, json) => request.post(`/manager-link/query`, { name: name, json: json }),

    fetchFundSearchQueryDetail: (queryId) => request.get(`/manager-link/query/${queryId}`),
    updateFundSearchQueryDetail: (queryId, name, json) =>
      request.put(`/manager-link/query/${queryId}`, { name: name, json: json }),
    deleteFundSearchQueryDetail: (queryId) => request.delete(`/manager-link/query/${queryId}`),

    fetchLatestPerformance: (fundId) => request.get(`/manager-link/funds/${fundId}/latest-performance`),
    updateLatestPerformance: (fundId, data) => request.post(`/manager-link/funds/${fundId}/latest-performance`, data),
    validateLatestPerformance: (fundId, data) =>
      request.post(`/manager-link/funds/${fundId}/flag-latest-performance`, data),
    postDownloadFile: (params) => request.post(`/manager-link/files`, params),
    downloadFileByUrl: (url) => request.get(`/manager-link/files/${url}`),
  }
}
