import { Spin, Tabs, Tooltip } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { RootState } from '../../app/rootReducer'
import { FirmDetailDataProvider } from '../../shared/api/context/FirmDetailDataContext'
import { FundDetailDataProvider } from '../../shared/api/context/FundDetailDataContext'
import { FundDetailDropdownProvider } from '../../shared/api/context/FundDetailDropdownContext'
import { Colors } from '../../shared/colors'
import MainLayout from '../../shared/components/MainLayout'
import { DraftManagerProvider } from '../../shared/DraftManager'
import { history } from '../../shared/history'
import { LeftNavFirmEnum } from '../../shared/SearchFirmLabel'
import { LeftNavFundEnum } from '../../shared/SearchFundLabel'
import { addTabs, DASHBOARD_KEY, FundFirmTypeEnum, removeTabs, updateActiveTabs } from '../../slice/appSettingsSlice'
import DashboardTab from './DashboardTab/DashboardTab'
import { FIRM_DATE_FIELDS, FirmDetailMain } from './FirmDetail/FirmDetailMain'
import { FUND_DATE_FIELDS, FundDetailMain } from './FundDetail/FundDetailMain'
import { LeftNavExFundEnum } from '../../shared/SearchExFundLabel'
import { LeftNavExFirmEnum } from '../../shared/SearchExFirmLabel'

const { TabPane } = Tabs

const SymbolTab = styled.div`
  background: #1890ff;
  color: #fff;
  padding: 0 4px;
  margin-right: 2px;
  border-radius: 3px;
  font-size: 12px;
  align-self: center;
`

const StyledTabs = styled(Tabs)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;

  .ant-tabs-nav {
    margin-bottom: 0%;
  }

  .ant-tabs-content-holder {
    overflow: hidden;
    height: 100%;
  }

  .ant-tabs-content {
    flex-grow: 1;
    background: white;
    border-left: solid 1px ${Colors.border};
    border-right: solid 1px ${Colors.border};
    border-bottom: solid 1px ${Colors.border};
    height: 100%;

    .ant-tabs-tabpane-active {
      height: 100%;
      display: flex;
    }

    .ant-card-head {
      min-height: auto;
    }
  }
`

const Dashboard: React.FC<RouteComponentProps<{
  fundId: string
  firmId: string
  activeFundNav: string
  activeFirmNav: string
}>> = ({ match }) => {
  const { params, path } = match
  const { search } = useLocation()

  const { fundFirmTabs } = useSelector((state: RootState) => state.appSettings)

  const dispatch = useDispatch()

  React.useEffect(() => {
    // effect to resolve tabs from url
    const fundIdFromUrl = params.fundId
    const firmIdFromUrl = params.firmId
    const activeFundNav = params.activeFundNav
    const activeFirmNav = params.activeFirmNav
    const query = new URLSearchParams(search)
    const isPreview = query.get('preview') === '1'
    const previewTab = query.get('preview_tab') || undefined

    const selectActiveNav = (value: string | undefined, type: FundFirmTypeEnum) => {
      if (type === FundFirmTypeEnum.FUNDS) {
        if (value === 'fund-family') {
          return LeftNavExFundEnum.FUND_FAMILY
        }
        return undefined
      }

      if (type === FundFirmTypeEnum.FIRMS_IN_FUNDS) {
        if (value === 'fund-family') {
          return LeftNavExFirmEnum.FUND_FAMILY
        }
        return undefined
      }
    }

    if (fundIdFromUrl) {
      dispatch(
        addTabs({
          id: fundIdFromUrl,
          type: FundFirmTypeEnum.FUNDS,
          isPreview,
          previewTab,
          activeNav: activeFundNav ? selectActiveNav(activeFundNav, FundFirmTypeEnum.FUNDS) : undefined,
        }),
      )
      history.push('/')
    }
    if (firmIdFromUrl) {
      dispatch(
        addTabs({
          id: firmIdFromUrl,
          type: FundFirmTypeEnum.FIRMS_IN_FUNDS,
          activeNav: activeFirmNav ? selectActiveNav(activeFirmNav, FundFirmTypeEnum.FIRMS_IN_FUNDS) : undefined,
        }),
      )
      history.push('/')
    }
  }, [dispatch, fundFirmTabs.activeTab, fundFirmTabs.tabList, params.firmId, params.fundId, path])

  return (
    <MainLayout>
      <DraftManagerProvider usage="internal" dateFields={[...FUND_DATE_FIELDS, ...FIRM_DATE_FIELDS]}>
        <FundDetailDropdownProvider>
          <StyledTabs
            hideAdd
            type="editable-card"
            onChange={(key) => {
              if (key === DASHBOARD_KEY) {
                dispatch(updateActiveTabs(DASHBOARD_KEY))
              }
              // history.push(key === DASHBOARD_KEY ? '/' : `/funds/${key}`)
              dispatch(updateActiveTabs(key))
            }}
            activeKey={fundFirmTabs.activeTab}
            onEdit={(targetKey, action) => {
              if (action === 'remove') {
                dispatch(removeTabs(targetKey.toString()))
              }
            }}
          >
            <TabPane tab="Dashboard" key={DASHBOARD_KEY} closable={false}>
              <DashboardTab />
            </TabPane>
            {fundFirmTabs &&
              fundFirmTabs.tabList.map((item) => {
                return (
                  <TabPane tab={<RenderTitleTabPane name={item.name} type={item.type} />} key={item.id} closable={true}>
                    {item.type === FundFirmTypeEnum.FUNDS ? (
                      <FundDetailDataProvider id={item.id} type={'internal'}>
                        <FundDetailMain
                          initialPath={item.path as LeftNavFundEnum}
                          previewTab={item.previewTab}
                          isPreview={item.isPreview}
                        />
                      </FundDetailDataProvider>
                    ) : (
                      <FirmDetailDataProvider id={item.id}>
                        <FirmDetailMain initialPath={item.path as LeftNavFirmEnum} />
                      </FirmDetailDataProvider>
                    )}
                  </TabPane>
                )
              })}
          </StyledTabs>
        </FundDetailDropdownProvider>
      </DraftManagerProvider>
    </MainLayout>
  )
}

export default Dashboard
type TitleProps = {
  name: string | undefined
  type: FundFirmTypeEnum
}
export const RenderTitleTabPane: React.FC<TitleProps> = ({ name, type }) => {
  if (!name) {
    return <Spin />
  }
  return (
    <div style={{ display: 'flex' }}>
      {type === FundFirmTypeEnum.FIRMS_IN_FUNDS && <SymbolTab>M</SymbolTab>}
      {name.length > 50 ? (
        <Tooltip title={name}>
          <div>{name.slice(0, 50)}...</div>
        </Tooltip>
      ) : (
        name
      )}
    </div>
  )
}
