import { combineReducers, PayloadAction } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import searchParamsSlice from '../routes/Dashboard/DashboardTab/SearchForm/redux/searchParamsSlice'
import appDataSlice from '../slice/appDataSlice'
import appEventSlice from '../slice/appEventSlice'
import appSettingsSlice from '../slice/appSettingsSlice'
import authSlice from '../slice/authSlice'
import userSlice from '../slice/userSlice'

const appSettingsConfig = {
  key: 'appSettings',
  storage: storage,
  blacklist: ['showPrompt'],
}

const appReducer = combineReducers({
  // slices go here
  searchParams: searchParamsSlice,
  auth: authSlice,
  user: userSlice,
  appData: appDataSlice,
  appSettings: persistReducer(appSettingsConfig, appSettingsSlice),
  appEvent: appEventSlice,
})

const rootReducer = (state: ReturnType<typeof appReducer>, action: PayloadAction) => {
  if (action.type === 'auth/logoutSuccess') {
    const intialState = appReducer(undefined, action)
    return {
      ...intialState,
      appSettings: {
        ...intialState.appSettings,
        // Keep fund/firm drafts
        exFundDraft: state.appSettings.exFundDraft,
        exFirmDraft: state.appSettings.exFirmDraft,
        fundDraft: state.appSettings.fundDraft,
        firmDraft: state.appSettings.firmDraft,
      },
    }
  }
  return appReducer(state, action)
}

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
