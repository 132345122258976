import { Row } from 'antd'
import React from 'react'
import { FundDetailDataContext, PERFORMANCE_REQUEST_ENUM } from '../../../../shared/api/context/FundDetailDataContext'
import DataSheet, { DataSheetType } from '../../../../shared/components/DataSheet'
import { NextBackButton } from '../../../../shared/components/NextBackButton'

type Props = {}
export const Distributions: React.FC<Props> = () => {
  const {
    distributionDataChange,
    dataDistribution,
    dataDistributionLoading,
    setDistributionDataChange,
    getPerformanceRequest,
    dataFundDetail,
  } = React.useContext(FundDetailDataContext)

  React.useEffect(() => {
    getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.DISTRIBUTION)
  }, [getPerformanceRequest])

  return (
    <Row style={{ padding: '1rem', display: 'flex', flexDirection: 'column' }}>
      <DataSheet
        currencyValue={dataFundDetail?.denomination ? dataFundDetail.denomination : undefined}
        type={DataSheetType.DISTRIBUTION}
        canAdd
        draft={distributionDataChange.values}
        initialData={dataDistribution}
        loading={dataDistributionLoading}
        setRORDataChange={setDistributionDataChange}
        minDate={dataFundDetail?.inception_date ? dataFundDetail.inception_date : undefined}
      />

      <NextBackButton />
    </Row>
  )
}
