import { ContainerOutlined, InboxOutlined, SearchOutlined } from '@ant-design/icons'
import { AutoComplete, Button, Input, notification, Popconfirm, Select } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../../app/rootReducer'
import APIService from '../../../shared/api'
import { ViewModeType } from '../../../shared/DraftManager'
import { LeftNavFundEnum, ListSearchFundType, renderSearchListFund } from '../../../shared/SearchFundLabel'
import { addTabs, FundFirmTypeEnum, PREFIX_EXTERNAL } from '../../../slice/appSettingsSlice'
import { Link } from 'react-router-dom'

const Flex = styled.div`
  display: flex;
  flex: 1;
`
const TextID = styled(Text)`
  font-size: 14px;
`

type PropsCardTitle = {
  id: string
  name: string
  firmName: string
  firmId: string
  optionFilter: ListSearchFundType[]
  textFilterSearch: string
  setOptionFilter: (value: ListSearchFundType[]) => void
  setTextFilterSearch: (value: string) => void
  setActiveTab: (index: LeftNavFundEnum) => void
  activeTab: LeftNavFundEnum
  loadingSubmitForm: boolean
  onPreview: () => void
  isPreviewing: boolean
  isUpdateFund: boolean
  onCancel?: () => void
  setViewMode?: (value: ViewModeType) => void
}
export const CardTitle: React.FC<PropsCardTitle> = ({
  id,
  name,
  firmName,
  firmId,
  setOptionFilter,
  setTextFilterSearch,
  textFilterSearch,
  setActiveTab,
  activeTab,
  loadingSubmitForm,
  onPreview,
  isPreviewing,
  isUpdateFund,
  onCancel,
  setViewMode,
}) => {
  const [optionShow, setOptionShow] = React.useState(renderSearchListFund)
  const dispatch = useDispatch()

  const [showCloneModal, setShowCloneModal] = React.useState(false)
  const [clonedFundName, setClonedFundName] = React.useState<string>()
  const [isCloning, setIsCloning] = React.useState(false)

  const handleSearch = (value: string) => {
    setTextFilterSearch(value)
    const newOption = renderSearchListFund.filter((item) => item.name.toUpperCase().indexOf(value.toUpperCase()) !== -1)
    setOptionFilter(newOption)
    setOptionShow(newOption)
    if (newOption.findIndex((item) => item.leftNav === activeTab) === -1 && newOption.length > 0) {
      setActiveTab(newOption[0].leftNav)
    }
  }

  const handleClone = async (fundName?: string) => {
    if (!fundName) {
      notification.warn({ message: 'Fund Name is required!' })
      return
    }
    try {
      setIsCloning(true)
      const data = await APIService.fundDetailService.cloneFund(id, fundName)
      dispatch(addTabs({ id: data.data.fund_id, type: FundFirmTypeEnum.FUNDS }))
      notification.success({ message: 'Fund cloned successfully!' })
      setShowCloneModal(false)
      setClonedFundName(undefined)
    } catch (error) {
      notification.error({ message: 'Failed to clone fund!' })
    } finally {
      setIsCloning(false)
    }
  }

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }

    // if (!id) {
    //   dispatch(removeTabs('new-fund'))
    // } else {
    //   dispatch(removeTabs(id))
    // }
  }

  const appSetting = useSelector((state: RootState) => state.appSettings)

  const { user } = useSelector((state: RootState) => state.user)

  const isEdited = appSetting.fundDraft.some((item) => item.id === id)

  return (
    <Flex style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
      <div>
        <Flex style={{ alignItems: 'center' }}>
          <TextID>Fund ID: {id}</TextID>
          <Flex style={{ alignItems: 'center', marginLeft: '1rem' }}>
            <TextID>Firm ID: {firmId}</TextID>
            <Flex style={{ alignItems: 'center', marginLeft: '1rem' }}>
              {firmName && <ContainerOutlined style={{ color: '#7598E6' }} />}
              {firmId && firmName && (
                <Text style={{ fontSize: 14 }}>
                  <Button
                    style={{ color: '#7598E6', border: 'none' }}
                    onClick={() => {
                      dispatch(
                        addTabs(
                          setViewMode
                            ? { name: firmName, id: firmId, type: FundFirmTypeEnum.FIRMS_IN_FUNDS }
                            : {
                                name: firmName,
                                id: `${PREFIX_EXTERNAL}-${firmId}`,
                                type: FundFirmTypeEnum.FIRMS_IN_FUNDS,
                              },
                        ),
                      )
                    }}
                  >
                    {firmName}
                  </Button>
                </Text>
              )}
            </Flex>
          </Flex>
        </Flex>

        <Flex style={{ alignItems: 'baseline', flexWrap: 'wrap' }}>
          <div>
            <Text style={{ fontSize: 18, fontWeight: 'bold', color: '#484848', whiteSpace: 'normal' }}>
              {isUpdateFund ? <Text style={{ color: '#b58742' }}>(Edited)</Text> : ''}
              {name}
              {/* MontLake Q Brands Premiere Palm-Tree Alpha Equity */}
            </Text>
          </div>
          <div style={{ marginLeft: '1rem' }}>
            <InboxOutlined /> <Link to={`/funds/${id}?preview=1&preview_tab=Profile`}>Fund Profile</Link> |{' '}
            <Link to={`/funds/${id}?preview=1&preview_tab=Peer Group`}>Peer Group Analysis </Link>
          </div>
        </Flex>
      </div>
      <Flex style={{ justifyContent: 'flex-end', alignItems: 'center', margin: '8px 0' }}>
        {!isPreviewing && (
          <>
            <AutoComplete
              onSelect={(value, options) => {
                if (options.label) {
                  const text = options.label.toLocaleString()
                  setTextFilterSearch(text)
                  const newOption = renderSearchListFund.filter(
                    (item) => item.name.toUpperCase().indexOf(text.toUpperCase()) !== -1,
                  )
                  setOptionShow(newOption)
                  const saveOption = newOption.filter((item) => item.key === value)
                  setOptionFilter(saveOption)
                  if (saveOption.findIndex((item) => item.leftNav === activeTab) === -1 && newOption.length > 0) {
                    setActiveTab(saveOption[0].leftNav)
                  }
                }
              }}
              onSearch={handleSearch}
              options={optionShow.map((item) => ({ value: item.key, label: item.name }))}
              allowClear
              value={textFilterSearch}
              style={{ width: 300, height: 32, marginRight: 8 }}
            >
              <Input placeholder="Go to Field" prefix={<SearchOutlined />} />
            </AutoComplete>
            {isUpdateFund ? (
              <Popconfirm
                placement="bottomLeft"
                title={'You have unsaved changes, are you sure you want to cancel?'}
                onConfirm={handleCancel}
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ marginRight: 8 }}>Cancel</Button>
              </Popconfirm>
            ) : (
              <Button onClick={handleCancel} style={{ marginRight: 8 }}>
                Cancel
              </Button>
            )}
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: 8 }}
              loading={loadingSubmitForm}
              disabled={user?.hfrml_readonly_internal_user || !isUpdateFund}
            >
              Save
            </Button>
            <Button style={{ marginRight: 8 }} loading={isCloning} onClick={() => setShowCloneModal(true)}>
              Clone
            </Button>
            <Modal
              title="Clone Fund"
              visible={showCloneModal}
              onCancel={() => setShowCloneModal(false)}
              confirmLoading={isCloning}
              onOk={() => {
                handleClone(clonedFundName)
              }}
            >
              <label>Fund Name</label>
              <Input
                style={{ marginTop: 5 }}
                placeholder="Enter Fund Name"
                value={clonedFundName}
                onChange={(e) => setClonedFundName(e.target.value)}
              />
            </Modal>
            {setViewMode && (
              <Select
                disabled={isEdited}
                style={{ marginRight: 8, width: 160 }}
                defaultValue="internal"
                onChange={(e) => {
                  setViewMode && setViewMode(e as ViewModeType)
                  dispatch(addTabs({ id: id, name: name, viewMode: 'external', type: FundFirmTypeEnum.FUNDS }))
                }}
              >
                <Select.Option value="internal" disabled>
                  Internal View
                </Select.Option>
                <Select.Option value="external">External View</Select.Option>
              </Select>
            )}
          </>
        )}
        <Button onClick={onPreview}>{isPreviewing ? 'Close' : 'Preview'}</Button>
      </Flex>
    </Flex>
  )
}
