import { DeleteTwoTone } from '@ant-design/icons'
import { Button, notification, Popconfirm, Table, Tooltip } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../../../../shared/api'
import { FundDetailDataContext } from '../../../../../shared/api/context/FundDetailDataContext'
import { DueDiligenceSentSepAct } from '../../../../../shared/api/models/FirmDetails'
import { Colors } from '../../../../../shared/colors'
import { useServiceState } from '../../../../../shared/hooks/useServiceState'
import { SentSepActModal } from './SentSepActModal'
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 1rem;
  flex-direction: column;
`
export const SentSepAcct: React.FC = () => {
  const [isShowModal, setIsShowModal] = React.useState(false)
  const { dataFundDetail } = React.useContext(FundDetailDataContext)
  const [loadingDelete, setLoadingDelete] = React.useState(false)

  const { invoke: getListMessage, data: listMesseage, loading: dataLoading } = useServiceState(
    APIService.firmDetailService.getDueDiligenceSentMess,
  )

  React.useEffect(() => {
    dataFundDetail?.firm_id && getListMessage(dataFundDetail.firm_id)
  }, [dataFundDetail, getListMessage])

  const updateSentSepAct = () => {
    getListMessage(dataFundDetail?.firm_id)
  }

  const deleteMessage = (fundId: string) => {
    setLoadingDelete(true)
    dataFundDetail?.firm_id &&
      APIService.firmDetailService
        .deleteDueDiligenceSentMess(dataFundDetail?.firm_id, { fund_id: fundId })
        .then(() => {
          notification.success({ message: 'Send Message Created!' })
          updateSentSepAct()
        })
        .catch((err) => {
          console.error({ err })
          notification.error({ message: 'Send Message Create Failed!' })
        })
        .finally(() => {
          setLoadingDelete(false)
        })
  }

  const columns: ColumnProps<DueDiligenceSentSepAct>[] = [
    {
      title: 'Firm ID',
      dataIndex: 'firm_id',
      key: 'firm_id',
      sorter: (a, b) => {
        return a.firm_id.localeCompare(b.firm_id)
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Fund ID',
      dataIndex: 'fund_id',
      key: 'fund_id',
      sorter: (a, b) => {
        return a.fund_id.localeCompare(b.fund_id)
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Operator ID',
      dataIndex: 'oper_id',
      key: 'oper_id',
      sorter: (a, b) => {
        return a.oper_id.localeCompare(b.oper_id)
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Send To',
      dataIndex: 'sent_to',
      key: 'sent_to',
      sorter: (a, b) => {
        return a.sent_to.localeCompare(b.sent_to)
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Sent Date',
      dataIndex: 'sent_date',
      key: 'sent_date',
      sorter: (a, b) => {
        return a.sent_date.localeCompare(b.sent_date)
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => (
        <>
          <Popconfirm title="Are you sure you want to delete?" onConfirm={() => deleteMessage(record.fund_id)}>
            <Button type="link" style={{ padding: '4px 8px' }} loading={loadingDelete}>
              <Tooltip title="Delete">
                <DeleteTwoTone twoToneColor={Colors.danger} />
              </Tooltip>
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ]
  return (
    <Wrapper>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="primary"
          style={{ marginBottom: '1rem' }}
          onClick={() => setIsShowModal(true)}
          disabled={listMesseage && listMesseage.length > 0}
        >
          Create New Message
        </Button>
      </div>
      <Table
        rowKey="firm_id"
        dataSource={listMesseage}
        columns={columns}
        pagination={listMesseage && listMesseage.length > 10 ? undefined : false}
        bordered
        style={{ flex: 1 }}
        loading={dataLoading}
      />
      {dataFundDetail?.firm_id && (
        <SentSepActModal
          firmId={dataFundDetail.firm_id}
          isShowModal={isShowModal}
          setIsShowModal={setIsShowModal}
          updateSentSepAct={updateSentSepAct}
          fundId={dataFundDetail.fund_id}
        />
      )}
    </Wrapper>
  )
}
