import { Button } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { LeftNavExNewFundEnum } from '../../../shared/SearchExNewFundLabel'
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  margin-top: 1rem;
  flex-direction: column;
  padding: 0 2rem;
`
type Props = { setActiveTab: (value: LeftNavExNewFundEnum) => void; loadingSubmit: boolean }
export const ExNewFundFinalStep: React.FC<Props> = ({ setActiveTab, loadingSubmit }) => {
  return (
    <Wrapper>
      <h3 style={{ margin: 0, marginTop: '4rem', textAlign: 'center' }}>
        You&apos;ve completed all the steps - please click <b>Create</b> to create your fund!
      </h3>

      <div style={{ marginTop: '2rem', display: 'flex', justifyContent: 'center' }}>
        <Button onClick={() => setActiveTab(LeftNavExNewFundEnum.FUND_PERFORMANCE)}>Back</Button>
        <Button type="primary" htmlType="submit" style={{ marginLeft: 8 }} loading={loadingSubmit}>
          Create
        </Button>
      </div>
    </Wrapper>
  )
}
