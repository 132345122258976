import React from 'react'
import styled from 'styled-components'
import { FormItemTextArea } from '../../../../../shared/components/FormItemComponents'
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 0 1rem;
`
type Props = {}
export const StrategyDescription: React.FC<Props> = () => {
  return (
    <Wrapper>
      <FormItemTextArea name="strategy_description" row={15} />
    </Wrapper>
  )
}
