import { DeleteTwoTone, DownloadOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Checkbox, Input, notification, Popconfirm, Space, Tabs, Tooltip } from 'antd'
import Table, { ColumnProps } from 'antd/lib/table'
import JsFileDownload from 'js-file-download'
import Papa from 'papaparse'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../shared/api'
import { CodeTableType } from '../../shared/api/models/CodeTable'
import { Colors } from '../../shared/colors'
import { useServiceState } from '../../shared/hooks/useServiceState'
import CodeTableFormModal from './CodeTableFormModal'

const StyledTabs = styled(Tabs)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;

  .ant-tabs-nav {
    margin-bottom: 0%;
  }

  .ant-tabs-content-holder {
    overflow: hidden;
  }

  .ant-tabs-content {
    flex-grow: 1;
    background: white;
    border-left: solid 1px ${Colors.border};
    border-right: solid 1px ${Colors.border};
    border-bottom: solid 1px ${Colors.border};
    height: 100%;

    .ant-tabs-tabpane-active {
      height: 100%;
      display: flex;
    }

    .ant-card-head {
      min-height: auto;
    }
  }
`
type Props = {}

const CodeTable: React.FC<Props> = () => {
  const [showFormModal, setShowFormModal] = React.useState(false)
  const [selectedCodeTable, setSelectedCodeTable] = React.useState<CodeTableType>()
  const [loadingDeleteCodeTable, setLoadingDeleteCodeTable] = React.useState(false)
  const [currentSource, setCurrentSource] = React.useState<CodeTableType[]>([])
  const searchInput = React.useRef<Input>(null)

  const { invoke: getTableList, data: tableList, loading: loadingTableList } = useServiceState(
    APIService.codeTableService.getCodeTableList,
  )

  React.useEffect(() => {
    getTableList()
  }, [getTableList])

  React.useEffect(() => {
    if (tableList) {
      setCurrentSource(tableList)
    }
  }, [tableList])

  const updateCodeTable = () => {
    getTableList()
  }

  const handleSearch = (selectedKeys: React.ReactText[], confirm: (() => void) | undefined, _dataIndex: string) => {
    confirm && confirm()
  }

  const handleReset = (clearFilters: (() => void) | undefined) => {
    clearFilters && clearFilters()
  }

  const deleteCodeTable = (record: CodeTableType) => {
    setLoadingDeleteCodeTable(true)
    const params: Partial<CodeTableType> = {
      code_id: record.code_id,
      code_code: record.code_code,
      code_description: record.code_description,
    }
    APIService.codeTableService
      .deleteCodeTable(params)
      .then(() => {
        notification.success({ message: 'Code Table Deleted!' })
        updateCodeTable()
      })
      .catch((err: any) => {
        console.error({ err })
        notification.error({ message: 'Code Table Delete Failed!' })
      })
      .finally(() => {
        setLoadingDeleteCodeTable(false)
      })
  }

  const handleExportCodeTable = () => {
    JsFileDownload(Papa.unparse(currentSource), `codetable-results.csv`, 'application/csv')
  }

  const getColumnSearchProps = (dataIndex: string): ColumnProps<CodeTableType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      const rowData = record[dataIndex as keyof CodeTableType]
      return rowData ? rowData.toString().toLowerCase().includes(value.toString().toLowerCase()) : false
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select())
      }
    },
  })

  const columns: ColumnProps<CodeTableType>[] = [
    {
      title: 'ID',
      dataIndex: 'code_id',
      key: 'code_id',
      sorter: (a, b) => {
        if (a.code_id && b.code_id) {
          return a.code_id.localeCompare(b.code_id)
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('code_id'),
    },
    {
      title: 'Code',
      dataIndex: 'code_code',
      key: 'code_code',

      sorter: (a, b) => {
        if (a.code_code && b.code_code) {
          return a.code_code.localeCompare(b.code_code)
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('code_code'),
    },
    {
      title: 'Description',
      dataIndex: 'code_description',
      key: 'code_description',
      sorter: (a, b) => {
        if (a.code_description && b.code_description) {
          return a.code_description.localeCompare(b.code_description)
        }
        return 0
      },

      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('code_description'),
    },
    {
      title: 'Is Active',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (_, record) => <Checkbox checked={record.is_active ? true : false}></Checkbox>,
    },

    {
      title: 'Action',
      dataIndex: 'action',
      width: 100,
      render: (_, record) => (
        <>
          <Button
            type="link"
            style={{ padding: '4px 8px' }}
            onClick={() => {
              setSelectedCodeTable(record)
              setShowFormModal(true)
            }}
          >
            <Tooltip title="Edit">
              <EditOutlined />
            </Tooltip>
          </Button>

          <Popconfirm title="Are you sure you want to delete?" onConfirm={() => deleteCodeTable(record)}>
            <Button type="link" style={{ padding: '4px 8px' }} loading={loadingDeleteCodeTable}>
              <Tooltip title="Delete">
                <DeleteTwoTone twoToneColor={Colors.danger} />
              </Tooltip>
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ]
  return (
    <StyledTabs hideAdd type="editable-card">
      <Tabs.TabPane
        tab="Code Table"
        closable={false}
        key="code-table"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="primary" style={{ margin: 8 }} onClick={() => setShowFormModal(true)}>
            Add New
          </Button>
        </div>
        <Table
          rowKey="code_id"
          scroll={{ y: 'calc(100vh - 360px)', x: 'min-content' }}
          size="small"
          dataSource={tableList}
          columns={columns}
          loading={loadingTableList}
          pagination={{ defaultPageSize: 20 }}
          onChange={(_pagination, _filter: any, _sorter: any, extra) => {
            setCurrentSource(extra.currentDataSource)
          }}
        />
        <CodeTableFormModal
          selectedCodeTable={selectedCodeTable}
          showFormModal={showFormModal}
          setShowFormModal={setShowFormModal}
          setSelectedCodeTable={setSelectedCodeTable}
          updateCodeTable={updateCodeTable}
        />
        <Button
          type="primary"
          style={{ position: 'absolute', bottom: 110, left: 36 }}
          onClick={handleExportCodeTable}
          disabled={currentSource.length === 0}
        >
          <DownloadOutlined />
          Export
        </Button>
      </Tabs.TabPane>
    </StyledTabs>
  )
}

export default CodeTable
