import { Form, Modal, notification, Spin } from 'antd'
import { useForm } from 'antd/lib/form/util'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/rootReducer'
import APIService from '../../../shared/api'
import { FormItemInput } from '../../../shared/components/FormItemComponents'
type Props = {
  showModal: boolean
  setShowModal: (value: boolean) => void
  selectedQuery?: { id: number; name: string } | undefined
  refetchQuery: () => void
  setCreatedQueryName?: (value: string | undefined) => void
}
export const ModalEditQuerySearch: React.FC<Props> = ({
  showModal,
  setShowModal,
  selectedQuery,
  setCreatedQueryName,
  refetchQuery,
}) => {
  const [loadingSubmit, setLoadingSubmit] = React.useState(false)
  const [form] = useForm()
  const paramsSearch = useSelector((state: RootState) => state.searchParams)
  const { fundSearchColumns } = useSelector((state: RootState) => state.appSettings)

  const handleChangeQuery = () => {
    form.validateFields(['searchName']).then(() => {
      setLoadingSubmit(true)
      const name = form.getFieldValue('searchName')
      selectedQuery
        ? APIService.fundService
            .updateFundSearchQueryDetail(
              selectedQuery.id,
              name,
              JSON.parse(JSON.stringify({ params: paramsSearch.fundSearchParams, columns: fundSearchColumns })),
            )
            .then(() => {
              notification.success({ message: 'Search Query Updated!' })
              refetchQuery()
            })
            .catch((err) => {
              console.error({ err })
              notification.error({ message: 'Search Query Updated Failed!' })
            })
            .finally(() => {
              setLoadingSubmit(false)
              setShowModal(false)
            })
        : APIService.fundService
            .createFundSearchQuery(
              name,
              JSON.parse(JSON.stringify({ params: paramsSearch.fundSearchParams, columns: fundSearchColumns })),
            )
            .then(() => {
              notification.success({ message: 'Search Query Created!' })
              setCreatedQueryName && setCreatedQueryName(name)
              refetchQuery()
            })
            .catch((err) => {
              console.error({ err })
              notification.error({ message: 'Search Query Create Failed!' })
            })
            .finally(() => {
              setLoadingSubmit(false)
              setShowModal(false)
            })
    })
  }

  React.useEffect(() => {
    if (selectedQuery) {
      form.setFieldsValue({ searchName: selectedQuery.name })
    } else {
      form.resetFields()
    }
  }, [form, selectedQuery])

  return (
    <Modal
      title={selectedQuery ? 'Update your Query' : 'Create your Query'}
      visible={showModal}
      width={500}
      centered
      okText={
        loadingSubmit ? (
          <>
            <Spin style={{ marginRight: 4 }} />
          </>
        ) : (
          'OK'
        )
      }
      onOk={() => {
        handleChangeQuery()
      }}
      onCancel={() => {
        setShowModal(false)
        form.resetFields()
      }}
    >
      <Form layout="vertical" form={form}>
        <FormItemInput
          name="searchName"
          label="Query Name"
          maxLength={100}
          rules={[{ required: true, message: 'Please enter Query Name' }]}
        />
      </Form>
    </Modal>
  )
}
