/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import {
  createMigrate,
  MigrationManifest,
  PersistConfig,
  PersistedState,
  persistReducer,
  persistStore,
} from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import rootReducer, { RootState } from './rootReducer'

const resetStoreMigration = (state: PersistedState) => {
  if (!state) {
    return { _persist: { version: -1, rehydrated: false } }
  }
  localStorage.removeItem('persist:root')
  return { _persist: state._persist }
}

// Do a migration to reset the persisted store for a specific version
const migrations: MigrationManifest = {
  0: resetStoreMigration,
}

const persistConfig: PersistConfig<RootState> = {
  key: 'root',
  storage,
  whitelist: ['auth', 'searchParams', 'exSearchParams'],
  blacklist: ['auth.userLoading'],
  version: 2,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(migrations, { debug: false }),
}

// TODO: Fix this!!!
// @ts-ignore
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
})

export const persistor = persistStore(store)

// @ts-ignore
if (process.env.NODE_ENV === 'development' && module.hot) {
  // @ts-ignore
  module.hot.accept('./rootReducer', () => {
    // eslint-disable-next-line
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer)
  })
}

export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

export default store
