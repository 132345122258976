import React from 'react'
import { FundDetailDropdownContext } from '../../../../../shared/api/context/FundDetailDropdownContext'
import { useServiceState } from '../../../../../shared/hooks/useServiceState'
import APIService from '../../../../../shared/api'
import { useDebounce } from 'use-debounce'
import { ExFirmDetails, FirmDetails } from '../../../../../shared/api/models/FirmDetails'
import styled from 'styled-components'
import Text from 'antd/lib/typography/Text'
import { Col, Form, Modal, Row, Select, Spin } from 'antd'
import { ModalProps } from 'antd/lib/modal'
import { RenderLabelForm } from '../../../../../shared/components/RenderLabelForm'
import { FundDetailDataContext } from '../../../../../shared/api/context/FundDetailDataContext'

const Flex = styled.div`
  display: flex;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  border: 1px #d9d9d9 solid;
  border-radius: 4px;
  padding: 8px;
  height: 400px;
  margin-top: 8px;
`

type FirmSelectModalProps = Omit<ModalProps, 'onOk'> & {
  onOk?: (contactInfo: FirmDetails) => void
}
export const FirmSelectModal = ({ onOk, onCancel, ...props }: FirmSelectModalProps) => {
  const { dataFirms, fundDataDropdown, loading } = React.useContext(FundDetailDropdownContext)
  const { dataFundDetail } = React.useContext(FundDetailDataContext)

  const { invoke: getFirmDetail, data: dataFirmDetail, loading: loadingFirmDetail } = useServiceState(
    APIService.firmDetailService.fetchFirmInfo,
  )

  const [searchText, setSearchText] = React.useState('')
  const [optionFirm, setOptionFirm] = React.useState<{ value: string; label: string }[] | undefined>()
  const [selectedFirmId, setSelectedFirmId] = React.useState<string | undefined>(dataFundDetail?.firm_id)

  const [debouncedText] = useDebounce(searchText, 500)

  const compareFirm = (a: ExFirmDetails, b: ExFirmDetails) => {
    return a.firm_name && b.firm_name ? a.firm_name.localeCompare(b.firm_name) : 0
  }
  const sortedFirms = React.useMemo(() => {
    return dataFirms ? dataFirms.sort(compareFirm) : undefined
  }, [dataFirms])

  const filterOptions = React.useCallback(
    (searchInput: string) => {
      if (!sortedFirms) {
        return []
      }

      if (!searchInput) {
        const slicedFirms = sortedFirms.slice(0, 20).map((item) => ({ value: item.firm_id, label: item.firm_name }))
        return slicedFirms
      }
      const lowercaseSearch = searchInput.toLowerCase()
      const result = sortedFirms
        .filter((item) => {
          return (
            (item.firm_name && item.firm_name.toLowerCase().includes(lowercaseSearch)) ||
            item.firm_id.toLowerCase().includes(lowercaseSearch)
          )
        })
        .slice(0, 20)
        .map((item) => ({ value: item.firm_id, label: item.firm_name }))

      return result
    },
    [sortedFirms],
  )

  React.useEffect(() => {
    const newOptions = filterOptions(debouncedText || '')
    setOptionFirm(newOptions)
  }, [filterOptions, debouncedText])

  React.useEffect(() => {
    if (!selectedFirmId) {
      return
    }

    getFirmDetail(selectedFirmId)
  }, [selectedFirmId])

  const { stateLabel, countryLabel } = React.useMemo(() => {
    if (!selectedFirmId || !dataFirmDetail || !fundDataDropdown) {
      return {
        stateLabel: '',
        countryLabel: '',
      }
    }
    let state = dataFirmDetail?.contact_state
    if (state && fundDataDropdown.contact_state && fundDataDropdown.contact_state.length) {
      const foundState = fundDataDropdown.contact_state.find((op) => op.code_code === state)
      if (foundState) {
        state = foundState.code_description
      }
    }
    //
    let country = dataFirmDetail?.country
    if (country && fundDataDropdown.contact_country && fundDataDropdown.contact_country.length) {
      const foundCountry = fundDataDropdown.contact_country.find((op) => op.code_code === country)
      if (foundCountry) {
        country = foundCountry.code_description
      }
    }
    //
    return {
      stateLabel: state || '',
      countryLabel: country || '',
    }
  }, [fundDataDropdown, selectedFirmId, dataFirmDetail])

  const handleOk = () => {
    if (!dataFirmDetail || !onOk) {
      return
    }
    onOk(dataFirmDetail)
    setSelectedFirmId(dataFundDetail?.firm_id)
  }

  const handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setSelectedFirmId(dataFundDetail?.firm_id)
    onCancel && onCancel(e)
  }
  return (
    <Modal
      title="Import Contact from Firm"
      {...props}
      width={1000}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ disabled: !selectedFirmId, loading: loadingFirmDetail }}
    >
      <Flex style={{ flexDirection: 'column' }}>
        <Text strong>Select Firm</Text>
        <Select
          aria-autocomplete="none"
          showSearch
          placeholder="Select Firm"
          filterOption={false}
          loading={loading}
          searchValue={searchText}
          onSearch={setSearchText}
          value={selectedFirmId}
          style={{ marginBottom: '4px' }}
          onSelect={(val) => {
            setSelectedFirmId(val)
          }}
        >
          {(optionFirm || []).map((op) => {
            return (
              <Select.Option key={`firm_option_${op.value}`} value={op.value}>
                {op.label}
              </Select.Option>
            )
          })}
        </Select>
        <Spin spinning={loadingFirmDetail}>
          <Wrapper>
            <Item label="Name" value={dataFirmDetail?.contact_name1} hideValue={!selectedFirmId || loadingFirmDetail} />
            <Item
              label="Address 1"
              value={dataFirmDetail?.contact_address1}
              hideValue={!selectedFirmId || loadingFirmDetail}
            />
            <Item
              label="Address 2"
              value={dataFirmDetail?.contact_address2}
              hideValue={!selectedFirmId || loadingFirmDetail}
            />
            <Row>
              <Col span={12}>
                <Item
                  label="City"
                  value={dataFirmDetail?.contact_city}
                  hideValue={!selectedFirmId || loadingFirmDetail}
                  labelCol={12}
                  wrapperCol={8}
                />
              </Col>
              <Col span={12}>
                <Item
                  label="State"
                  value={stateLabel}
                  labelCol={4}
                  hideValue={!selectedFirmId || loadingFirmDetail}
                  wrapperCol={8}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Item
                  label="Postal Code"
                  value={dataFirmDetail?.contact_postal_code}
                  hideValue={!selectedFirmId || loadingFirmDetail}
                  labelCol={12}
                  wrapperCol={8}
                />
              </Col>
              <Col span={12}>
                <Item
                  label="Country"
                  value={countryLabel}
                  hideValue={!selectedFirmId || loadingFirmDetail}
                  labelCol={4}
                  wrapperCol={8}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Item
                  label="Phone"
                  value={dataFirmDetail?.contact_phone}
                  hideValue={!selectedFirmId || loadingFirmDetail}
                  labelCol={12}
                  wrapperCol={8}
                />
              </Col>
              <Col span={12}>
                <Item
                  label="Fax"
                  value={dataFirmDetail?.contact_fax}
                  hideValue={!selectedFirmId || loadingFirmDetail}
                  labelCol={4}
                  wrapperCol={8}
                />
              </Col>
            </Row>
            <Item
              label="Email"
              value={dataFirmDetail?.contact_email}
              hideValue={!selectedFirmId || loadingFirmDetail}
            />
            <Item label="Web" value={dataFirmDetail?.contact_web} hideValue={!selectedFirmId || loadingFirmDetail} />
          </Wrapper>
        </Spin>
      </Flex>
    </Modal>
  )
}

const TextWrapper = styled.div`
  width: 100%;
  padding: 4px 11px 4px 11px;
  border: 1px solid #d9d9d9;
  overflow: auto;
`

type ItemProps = {
  label: string
  value?: string
  labelCol?: number
  wrapperCol?: number
  left?: boolean
  area?: boolean
  hideValue?: boolean
}
const Item = ({ label, value, labelCol = 6, wrapperCol = 12, left, area, hideValue = true }: ItemProps) => {
  const hasNoValue = hideValue || !value
  return (
    <Form.Item
      labelAlign={left ? 'left' : 'right'}
      label={<RenderLabelForm value={label} />}
      labelCol={{ span: labelCol }}
      wrapperCol={{ span: wrapperCol }}
    >
      <TextWrapper style={{ height: area ? '110px' : '32px', backgroundColor: hasNoValue ? '#f5f5f5' : 'white' }}>
        <Text>{hasNoValue ? '' : value}</Text>
      </TextWrapper>
    </Form.Item>
  )
}
