import { DownloadOutlined } from '@ant-design/icons'
import { Collapse, Drawer } from 'antd'
import Table, { ColumnProps } from 'antd/lib/table'
import React, { useCallback, useMemo } from 'react'
import ReactJson from 'react-json-view'
import APIService from '../../shared/api'
import { FundDetailDropdownContext } from '../../shared/api/context/FundDetailDropdownContext'
import { ActivityLogType } from '../../shared/api/models/ActivityLog'
import { FundDropdownCodeType, FundDropdownType } from '../../shared/api/models/FundDetails'
import { FundKeysMapping } from '../../shared/api/models/FundKeysMapping'
import { useServiceState } from '../../shared/hooks/useServiceState'
import { displayFundIndexEligibilitySelectedOption } from '../Dashboard/FundDetail/Indices/utils'

type Props = {
  data: ActivityLogType
  showDrawer: boolean
  setShowDrawer: (value: boolean) => void
}

type TableType = {
  fieldName: string
  oldValue: string
  newValue: string
}
type TableRORType = {
  fundId: string
  date: string
  oldValue: string
  newValue: string
}

const columns: ColumnProps<TableType>[] = [
  {
    title: 'Field Name',
    dataIndex: 'fieldName',
    key: 'fieldName',
  },
  {
    title: 'Old Value',
    dataIndex: 'oldValue',
    key: 'oldValue',
  },
  {
    title: 'New Value',
    dataIndex: 'newValue',
    key: 'newValue',
  },
]

const columnsUpdateROR: ColumnProps<TableRORType>[] = [
  {
    title: 'Fund ID',
    dataIndex: 'fundId',
    key: 'fundId',
  },
  {
    title: 'Date',
    dataIndex: 'date',
  },
  {
    title: 'Old Value',
    dataIndex: 'oldValue',
    key: 'oldValue',
  },
  {
    title: 'New Value',
    dataIndex: 'newValue',
    key: 'newValue',
  },
]

const ROR_TABLE_UPLOAD = [
  'UPLOAD_RORS',
  'UPLOAD_ASSETS',
  'UPLOAD_NAVS',
  'UPDATE_XIGNITE_INDEX_DATA',
  'UPDATE_XIGNITE_LIBOR_DATA',
  'ALTERYX_UPLOAD_ASSETS',
  'ALTERYX_UPLOAD_NAVS',
  'ALTERYX_UPLOAD_RORS',
]

export const DrawerActivityLogInfo: React.FC<Props> = ({ showDrawer, setShowDrawer, data }) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)
  const { invoke: getLogDetail, data: dataLogDetail, loading } = useServiceState(
    APIService.activityLogService.fetchEventDetail,
  )
  React.useEffect(() => {
    getLogDetail(data.id.toString())
  }, [getLogDetail, data.id])

  // function
  const findDescription = useCallback(
    (changeKey: keyof FundDropdownType, value: string) => {
      if (!fundDataDropdown || !fundDataDropdown.hasOwnProperty(changeKey)) {
        return value
      }

      const dropdownListAtKey = fundDataDropdown[changeKey]
      if (typeof dropdownListAtKey[0] === 'string') {
        return value
      }

      const dropdownCodeList = dropdownListAtKey as FundDropdownCodeType[]
      return dropdownCodeList.find((item) => item.code_code === value)?.code_description || value
    },
    [fundDataDropdown],
  )
  //
  const dataTable = useMemo(() => {
    return (
      dataLogDetail &&
      Object.keys(dataLogDetail.change_data).reduce((acc: TableType[], key) => {
        if (typeof dataLogDetail.change_data[key].new === 'boolean') {
          return [
            ...acc,
            {
              fieldName: FundKeysMapping.hasOwnProperty(key) ? FundKeysMapping[key] : key.toUpperCase(),
              oldValue: dataLogDetail.change_data[key].old ? 'Yes' : 'No',
              newValue: dataLogDetail.change_data[key].new ? 'Yes' : 'No',
            },
          ]
        }
        return [
          ...acc,
          {
            fieldName: FundKeysMapping.hasOwnProperty(key) ? FundKeysMapping[key] : key.toUpperCase(),
            oldValue: findDescription(
              key as keyof FundDropdownType,
              dataLogDetail.change_data[key].old ? String(dataLogDetail.change_data[key].old) : '',
            ),
            newValue: findDescription(
              key as keyof FundDropdownType,
              dataLogDetail.change_data[key].new ? String(dataLogDetail.change_data[key].new) : '',
            ),
          },
        ]
      }, [])
    )
  }, [dataLogDetail, findDescription])

  const dataRORTable = useMemo(() => {
    return dataLogDetail && ROR_TABLE_UPLOAD.includes(dataLogDetail.code)
      ? Object.keys(dataLogDetail.change_data).reduce((acc: TableRORType[], key) => {
          const fundId = key

          const field = Object.keys(dataLogDetail.change_data[key]).reduce((accum: TableRORType[], k) => {
            //@ts-ignore
            const oldValue = dataLogDetail.change_data[key][k].old
            //@ts-ignore
            const newValue = dataLogDetail.change_data[key][k].new
            return [
              ...accum,
              {
                fundId: fundId,
                date: k,
                oldValue: oldValue !== null || oldValue !== undefined ? oldValue : '',
                newValue: newValue !== null || newValue !== undefined ? newValue : '',
              },
            ]
          }, [])
          return [...acc, ...field]
        }, [])
      : []
  }, [dataLogDetail])

  const jsonData = useMemo(() => {
    if (!dataLogDetail || !dataLogDetail.json_data) {
      return {}
    }

    const json_data = dataLogDetail.json_data as Record<string, any>
    // if (data.code === 'UPDATE_FUND_INDEX_ELIGIBILITY_DETAILS') {
    //   const keys = Object.keys(json_data)
    //   const transformedData = keys.reduce((prev, key) => {
    //     const mappedValue = displayFundIndexEligibilitySelectedOption(key, json_data[key])
    //     return {
    //       ...prev,
    //       [key]: mappedValue,
    //     }
    //   }, {})
    //   return transformedData
    // }

    return json_data
  }, [dataLogDetail])

  const [showRaw, setShowRaw] = React.useState(false)

  const handleCollapseChange = () => {
    setShowRaw(!showRaw)
  }

  return (
    <Drawer
      placement="right"
      width={700}
      closable={true}
      onClose={() => setShowDrawer(false)}
      visible={showDrawer}
      bodyStyle={{ paddingTop: 80 }}
    >
      {dataLogDetail?.event_file_url && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '0.5rem' }}>
          <a href={dataLogDetail.event_file_url} rel="noopener noreferrer" target="_blank" style={{ fontSize: 14 }}>
            <DownloadOutlined style={{ marginRight: 4 }} />
            Download File
          </a>
        </div>
      )}

      {dataLogDetail && ROR_TABLE_UPLOAD.includes(dataLogDetail.code) ? (
        <Table rowKey={'fundId'} dataSource={dataRORTable} columns={columnsUpdateROR} bordered loading={loading} />
      ) : (
        <Table rowKey={'fieldName'} dataSource={dataTable} columns={columns} bordered loading={loading} />
      )}

      <Collapse onChange={handleCollapseChange}>
        <Collapse.Panel header={showRaw ? 'Hide raw request' : 'Show raw request'} key="show">
          <ReactJson src={jsonData} name={false} indentWidth={2} />
        </Collapse.Panel>
      </Collapse>
    </Drawer>
  )
}
