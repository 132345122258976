import { Col, List, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import Table, { ColumnProps } from 'antd/lib/table'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { addTabs, FundFirmTypeEnum, PREFIX_EXTERNAL } from '../../slice/appSettingsSlice'
import { FundDetailDropdownContext } from '../api/context/FundDetailDropdownContext'
import { ExFundDetails } from '../api/models/ExFundDetails'
import { FundDropdownCodeType, FundDropdownType } from '../api/models/FundDetails'
import { Colors } from '../colors'
import { LeftNavExFundEnum } from '../SearchExFundLabel'
import { DividerForm } from './DividerForm'
import { FormItemRadio, FormItemSelect } from './FormItemComponents'
import { MODE_ESG_VIEW } from './FundESGSection'
import { RenderLabelExFirm } from './RenderLabelExFirm'
import { RenderLabelFormFirm } from './RenderLabelFormFirm'
import { updateFieldValue } from '../utils/formUtils'
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 0 1rem;
  margin-top: 1rem;
  flex-direction: column;
`
const FundFirmName = styled.div`
  color: ${Colors.primary};
  cursor: pointer;
`
type Props = {
  form: FormInstance
  modeView: MODE_ESG_VIEW
  dataListFund?: {
    fund_id: string
    fund_name: string
  }[]
}
const FIRM_INSTRUMENTS = [
  {
    name: 'applied_firmculture',
    label: 'Firm Culture',
  },
  {
    name: 'applied_investmentprocess',
    label: 'Investment Process',
  },
  {
    name: 'applied_portfolioconstruction',
    label: 'Portfolio Construction',
  },
  {
    name: 'applied_esgproducts',
    label: 'ESG Products',
  },
  {
    name: 'applied_esgintegration',
    label: 'ESG Integration',
  },
  {
    name: 'applied_esgactivist',
    label: 'Activist / Engaged Ownership',
  },
  {
    name: 'applied_diversityinclusion',
    label: 'Diversity And Inclusion',
  },
]

export const FirmESGSection: React.FC<Props> = ({ form, modeView, dataListFund }) => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)
  const isFirmEsg = form.getFieldValue('is_firm_esg')
  const isFirmPolicies = isFirmEsg === '1'
  const receiveGuidance = form.getFieldValue('receive_guidance')
  // change all sub fields of is_firm_esg to no when itself is no
  useEffect(() => {
    if (isFirmEsg === '2') {
      FIRM_INSTRUMENTS.map((field) => {
        const currentValue = form.getFieldValue(field.name)
        if (currentValue !== '2') {
          updateFieldValue(form, field.name, '2')
        }
        return null
      })
    }
  }, [isFirmEsg, form])
  // change all sub fields of receiveGuidance to no when itself is no
  useEffect(() => {
    if (receiveGuidance === '2') {
      //
      const currentValue = form.getFieldValue('thirdparty_guidance')
      !!currentValue && updateFieldValue(form, 'thirdparty_guidance', null)
    }
  }, [receiveGuidance, form])

  const dispatch = useDispatch()

  const renderLabelField = (value: string) => {
    return modeView === MODE_ESG_VIEW.EXTERNAL ? (
      <RenderLabelExFirm value={value} />
    ) : (
      <RenderLabelFormFirm value={value} />
    )
  }

  const columns: ColumnProps<Partial<ExFundDetails>>[] = [
    {
      title: 'Fund ID',
      dataIndex: 'fund_id',
      key: 'fund_id',
    },
    {
      title: 'Fund Name',
      dataIndex: 'fund_name',
      key: 'fund_name',
      render: (_, record) => (
        <FundFirmName
          onClick={() => {
            dispatch(
              addTabs({
                name: record.fund_name,
                id: `${PREFIX_EXTERNAL}-${record.fund_id}`,
                type: FundFirmTypeEnum.FUNDS,
                activeNav: LeftNavExFundEnum.FUND_ESG_FACTORS,
              }),
            )
          }}
        >
          {record.fund_name}
        </FundFirmName>
      ),
    },
  ]

  const optionEsg =
    fundDataDropdown?.is_firm_esg && fundDataDropdown.is_firm_esg.length > 0
      ? fundDataDropdown.is_firm_esg
          .map((item) => ({
            value: item.code_code,
            title: item.code_description,
          }))
          .filter((item) => modeView === MODE_ESG_VIEW.INTERNAL || item.value !== '0')
      : []

  const findOptionsRadio = (name: string) => {
    return fundDataDropdown &&
      fundDataDropdown[name as keyof FundDropdownType] &&
      fundDataDropdown[name as keyof FundDropdownType].length > 0
      ? (fundDataDropdown[name as keyof FundDropdownType] as Array<FundDropdownCodeType | string>)
          .map((item) =>
            typeof item === 'string'
              ? { value: item, title: item }
              : {
                  value: item.code_code,
                  title: item.code_description,
                },
          )
          .filter(
            (item) =>
              modeView === MODE_ESG_VIEW.INTERNAL || (typeof item === 'string' ? item !== '0' : item.value !== '0'),
          )
      : []
  }

  return (
    <Wrapper>
      <Row style={{ flexDirection: 'column', marginTop: '1rem' }}>
        <h3 style={{ margin: 0 }}>Update Firm ESG Policies</h3>
        <div>All Fields Are Required unless otherwise Noted</div>
        <DividerForm />
        <Row>
          <Col span={24}>
            <FormItemRadio
              name="is_firm_esg"
              label={renderLabelField('Does Your Firm Have A Formal Firmwide ESG Policy?')}
              options={optionEsg}
            />
          </Col>
        </Row>
        <div style={{ fontStyle: 'italic' }}>If Yes, please answer the following:</div>
        <div style={{ fontWeight: 'bold' }}>HOW IS ESG APPLIED AT YOUR FIRM</div>
        <div style={{ fontStyle: 'italic' }}>Select &quot;Yes&quot; or &quot;No&quot; foreach:</div>
        <List
          bordered
          dataSource={FIRM_INSTRUMENTS}
          style={{ width: '100%' }}
          renderItem={(item) => (
            <List.Item
              style={{
                padding: '0 24px',
              }}
            >
              <div style={{ width: '100%' }}>
                <FormItemRadio
                  style={{
                    marginBottom: 0,
                  }}
                  name={item.name}
                  label={renderLabelField(item.label)}
                  options={findOptionsRadio(item.name)}
                  disabled={!isFirmPolicies}
                  labelAlign="left"
                  {...{ labelCol: { span: 18 }, wrapperCol: { span: 6 } }}
                />
              </div>
            </List.Item>
          )}
        />
      </Row>
      <Row style={{ flexDirection: 'column', marginTop: '1rem' }}>
        <Row>
          <Col span={24}>
            <FormItemRadio
              name="receive_guidance"
              options={findOptionsRadio('receive_guidance')}
              label={renderLabelField(
                'Does Your Firm Receive ESG Investment Guidance From Or Follow The Principles Of A Third-Party Company/Association/Network?',
              )}
            />
          </Col>
        </Row>
        <div style={{ fontStyle: 'italic' }}>If Yes, please answer the following:</div>
        <Row style={{ width: '100%' }}>
          <Col span={12} xl={12} md={12} xs={24}>
            <FormItemSelect
              name="thirdparty_guidance"
              label={renderLabelField('Identify The Third-Party Company')}
              labelCol={12}
              wrapperCol={12}
              labelAlign="left"
              options={
                fundDataDropdown?.thirdparty_guidance && fundDataDropdown.thirdparty_guidance.length > 0
                  ? fundDataDropdown.thirdparty_guidance.map((item) => ({
                      value: item.code_code,
                      text: item.code_description,
                    }))
                  : []
              }
              disabled={!receiveGuidance}
            />
          </Col>
        </Row>
        <Row>
          <FormItemRadio
            name="future"
            options={findOptionsRadio('future')}
            label={renderLabelField('Do You Have Plans To Incorporate ESG In The Future?')}
          />
        </Row>
        {dataListFund && (
          <Row style={{ flexDirection: 'column' }}>
            <h3 style={{ margin: 0 }}>Open Fund ESG</h3>
            <Table
              rowKey="fund_id"
              style={{ flex: 1 }}
              dataSource={dataListFund}
              pagination={dataListFund && dataListFund.length > 10 ? undefined : false}
              columns={columns}
            />
          </Row>
        )}
      </Row>
    </Wrapper>
  )
}
