import { Col, Divider, Empty, Form, Input, notification, Row, Select, Spin } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import styled from 'styled-components'
import { useDebounce } from 'use-debounce'
import APIService from '../../shared/api'
import { BenchmarkType } from '../../shared/api/models/Benchmark'
import { useServiceState } from '../../shared/hooks/useServiceState'

const Wrapper = styled('div')``

type Props = {
  selectedBenchmark?: BenchmarkType
  showFormModal: boolean
  setShowFormModal: (value: boolean) => void
  setSelectedBenchmark: (value: BenchmarkType | undefined) => void
  updateBenchmark: () => void
}
type SelectType = {
  id: string
  name: string
}
const BenchmarkFormModal: React.FC<Props> = ({
  selectedBenchmark,
  showFormModal,
  setShowFormModal,
  setSelectedBenchmark,
  updateBenchmark,
}) => {
  const [loadingSubmit, setLoadingSubmit] = React.useState(false)

  const [textSearch, setTextSearch] = React.useState('')
  const [debouncedText] = useDebounce(textSearch, 500)
  const { data: funds, invoke: invokeSearch, loading } = useServiceState(APIService.fundService.fetchFundName)

  React.useEffect(() => {
    invokeSearch({ text: debouncedText })
  }, [debouncedText, invokeSearch])

  const [form] = Form.useForm()

  React.useEffect(() => {
    if (selectedBenchmark) {
      form.setFieldsValue(selectedBenchmark)
    } else {
      form.resetFields()
    }
  }, [selectedBenchmark, form])

  const submitFormBenchmark = () => {
    const submitData = selectedBenchmark
      ? {
          benchmark_disclaimer: form.getFieldValue('benchmark_disclaimer'),
        }
      : {
          fund_id: form.getFieldValue('fund_name'),
          benchmark_disclaimer: form.getFieldValue('benchmark_disclaimer'),
        }
    form.validateFields(['fund_name', 'benchmark_disclaimer']).then(() => {
      setLoadingSubmit(true)
      selectedBenchmark
        ? APIService.benchmarkService
            .updateBenchmark(selectedBenchmark.fund_id, submitData)
            .then(() => {
              notification.success({ message: 'Benchmark Updated!' })
              updateBenchmark()
            })
            .catch((err) => {
              console.error({ err })
              notification.error({ message: 'Benchmark Update Failed!' })
            })
            .finally(() => {
              setLoadingSubmit(false)
              setShowFormModal(false)
              setSelectedBenchmark(undefined)
            })
        : APIService.benchmarkService
            .createBenchmark(submitData)
            .then(() => {
              notification.success({ message: 'Benchmark Created!' })
              updateBenchmark()
            })
            .catch((err) => {
              console.error({ err })
              notification.error({
                message:
                  err.response.data.error_code && err.response.data.error_code === 'ENTITY_EXISTS'
                    ? 'Benchmark already exists!'
                    : 'Failed to add benchmark!',
              })
            })
            .finally(() => {
              setLoadingSubmit(false)
              setShowFormModal(false)
              setSelectedBenchmark(undefined)
            })
    })
  }

  return (
    <Modal
      width={700}
      visible={showFormModal}
      title={selectedBenchmark ? 'Update Benchmark' : 'Add New Benchmark'}
      okText={
        loadingSubmit ? (
          <>
            <Spin style={{ marginRight: 4 }} />
          </>
        ) : (
          'OK'
        )
      }
      onOk={submitFormBenchmark}
      onCancel={() => {
        setShowFormModal(false)
        form.resetFields()
        setSelectedBenchmark(undefined)
      }}
    >
      <Wrapper>
        <Form layout="vertical" form={form}>
          <Divider orientation="left">Information</Divider>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="fund_name"
                label="Fund Name"
                rules={[{ required: true, message: 'Fund Name is required' }]}
                style={{ display: 'flex', flex: 1 }}
              >
                <Select
                  disabled={!!selectedBenchmark}
                  showSearch
                  loading={loading}
                  notFoundContent={loading ? <Spin size="small" /> : <Empty />}
                  placeholder="Select Fund"
                  filterOption={false}
                  onSearch={(e) => {
                    setTextSearch(e)
                  }}
                >
                  {funds?.map((item) => {
                    return (
                      <Select.Option value={item.fund_id} key={item.fund_id}>
                        {item.fund_name}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {selectedBenchmark && (
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item label="Strategy" name="strategy_name">
                  <Input placeholder="Strategy" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Sub-Strategy" name="sub_strategy_name">
                  <Input placeholder="Sub Strategy" disabled />
                </Form.Item>
              </Col>
            </Row>
          )}
          {selectedBenchmark && (
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item label="Strategy (New)" name="strategy_new_name">
                  <Input placeholder="Strategy (New)" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Sub-Strategy (New)" name="sub_strategy_new_name">
                  <Input placeholder="Sub Strategy (New)" disabled />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              <Form.Item
                label="Benchmark Disclaimer"
                name="benchmark_disclaimer"
                rules={[{ required: true, message: 'Benchmark Disclaimer is required' }]}
              >
                <Input.TextArea placeholder="Benchmark Disclaimer" rows={4} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Wrapper>
    </Modal>
  )
}

export default BenchmarkFormModal
