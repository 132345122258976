import { Button, Modal, notification, Row, Upload } from 'antd'
import moment from 'moment'
import Papa from 'papaparse'
import React from 'react'
import { RORResponseType } from './DataSheet'

type Props = {
  isOpen: boolean
  setOpen: (value: boolean) => void
  setFileData: (value: RORResponseType[]) => void
  setIsUpdate: (value: boolean) => void
  minDate?: string | null
}
export const ModalConfirmUpload: React.FC<Props> = ({ isOpen, setOpen, setFileData, setIsUpdate, minDate }) => {
  return (
    <Modal
      title="Import File"
      visible={isOpen}
      footer={
        <Row justify="end">
          <div style={{ marginRight: '0.5' }}>
            <Button type="link" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </div>
          <Upload
            accept=".xls, .csv, .txt"
            showUploadList={false}
            beforeUpload={(file) => {
              Papa.parse<any>(file, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                  // validate year from file. Should be between Inception Date and now
                  if (
                    results.data.some((row) => {
                      const textYear = Object.keys(row).find((item) => item.toLowerCase() === 'year') || ''
                      if (
                        !textYear ||
                        (minDate && +row[textYear] < moment(minDate).year()) ||
                        +row[textYear] > moment().year()
                      ) {
                        return true
                      }
                    })
                  ) {
                    notification.error({
                      message: 'Invalid file format. Make sure the years are in range of Inception Date and now!',
                    })
                    return false
                  }
                  const reducerData = results.data.reduce((acc, cur) => {
                    const textYear = Object.keys(cur).find((item) => item.toLowerCase() === 'year') || ''

                    const yearData: RORResponseType[] = Object.keys(cur).reduce((accum, key) => {
                      if (cur[key] === '' && key.toLowerCase() !== 'year') {
                        return accum
                      }
                      return key.toLowerCase() === 'year'
                        ? [...accum]
                        : key.length === 1
                        ? [...accum, { yyyy_mm: `${cur[textYear]}-0${key}`, value: +cur[key] }]
                        : [...accum, { yyyy_mm: `${cur[textYear]}-${key}`, value: +cur[key] }]
                    }, [] as RORResponseType[])
                    return [...acc, ...yearData]
                  }, [] as RORResponseType[])
                  notification.success({ message: `File ${file.name} imported!` })
                  setFileData(reducerData)
                  setIsUpdate(true)
                  setOpen(false)
                },
              })
              // Prevent upload
              return false
            }}
          >
            <Button type="primary" style={{ marginLeft: '0.5rem' }}>
              Continue
            </Button>
          </Upload>
        </Row>
      }
    >
      <label style={{ marginBottom: '2rem' }}>
        All data will be overwritten. You still can revert the changes later.
      </label>
    </Modal>
  )
}
