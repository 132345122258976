import { DeleteTwoTone, EditOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Input, notification, Popconfirm, Row, Space, Tabs, Tooltip } from 'antd'
import Table, { ColumnProps } from 'antd/lib/table'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../shared/api'
import { FundDetailDataProvider } from '../../shared/api/context/FundDetailDataContext'
import { UniverseManagerList } from '../../shared/api/models/UniverseManager'
import { Colors } from '../../shared/colors'
import { TabContentWrapper } from '../../shared/components/TabContentWrapper'
import { useServiceState } from '../../shared/hooks/useServiceState'
import { FundDetailMain } from '../Dashboard/FundDetail/FundDetailMain'
import { UniverseManagerDetails } from './UniverseManagerDetails'
import UniverseManagerFormModal from './UniverseManagerFormModal'

const StyledTabs = styled(Tabs)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;

  .ant-tabs-nav {
    margin-bottom: 0%;
  }

  .ant-tabs-content-holder {
    overflow: hidden;
  }

  .ant-tabs-content {
    flex-grow: 1;
    background: white;
    border-left: solid 1px ${Colors.border};
    border-right: solid 1px ${Colors.border};
    border-bottom: solid 1px ${Colors.border};
    height: 100%;

    .ant-tabs-tabpane-active {
      height: 100%;
      display: flex;
    }

    .ant-card-head {
      min-height: auto;
    }
  }
`

type ListTabType = {
  id: number
  name: string
  type: 'universe' | 'funds'
}
type Props = {}

const UniverseManager: React.FC<Props> = () => {
  const [showFormModal, setShowFormModal] = React.useState(false)
  const [selectedUniverseManager, setSelectedUniverseManager] = React.useState<UniverseManagerList>()

  const [loadingDeleteUniverseManager, setLoadingDeleteUniverseManager] = React.useState(false)
  const [listTab, setListTab] = React.useState<ListTabType[]>([])
  const [activeTab, setActiveTab] = React.useState<string>('universe-list')

  const searchInput = React.useRef<Input>(null)

  const { invoke: getUniverseList, data: dataUniverseManager, loading: loadingUniverseManager } = useServiceState(
    APIService.universeManagerService.getUniverseManagerList,
  )

  React.useEffect(() => {
    getUniverseList()
  }, [getUniverseList])

  const updateUniverseManager = () => {
    getUniverseList()
  }

  const handleSearch = (selectedKeys: React.ReactText[], confirm: (() => void) | undefined, _dataIndex: string) => {
    confirm && confirm()
  }

  const handleReset = (clearFilters: (() => void) | undefined) => {
    clearFilters && clearFilters()
  }

  const deleteUniverseManager = (id: number) => {
    setLoadingDeleteUniverseManager(true)
    APIService.universeManagerService
      .deleteUniverseManager(id)
      .then(() => {
        notification.success({ message: 'Universe Manager Delete!' })
        updateUniverseManager()
      })
      .catch((err) => {
        console.error({ err })
        notification.error({ message: 'Universe Manager Delete Failed!' })
      })
      .finally(() => {
        setLoadingDeleteUniverseManager(false)
      })
  }

  const getColumnSearchProps = (dataIndex: string): ColumnProps<UniverseManagerList> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex as keyof UniverseManagerList]
        ? record[dataIndex as keyof UniverseManagerList]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select())
      }
    },
  })

  const columns: ColumnProps<UniverseManagerList>[] = [
    {
      title: 'ID',
      dataIndex: 'universe_id',
      key: 'universe_id',

      sorter: (a, b) => {
        if (a.universe_id && b.universe_id) {
          return a.universe_id - b.universe_id
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('universe_id'),
    },
    {
      title: 'Name',
      dataIndex: 'universe_name',
      key: 'universe_name',
      render: (_, record) => (
        <Button
          type="link"
          style={{ padding: '4px 8px' }}
          onClick={() => {
            if (!listTab.find((e) => e.id === record.universe_id)) {
              setListTab([...listTab, { id: record.universe_id, name: record.universe_name, type: 'universe' }])
            }
            setActiveTab(record.universe_id.toString())
          }}
        >
          {record.universe_name}
        </Button>
      ),
      sorter: (a, b) => {
        if (a.universe_name && b.universe_name) {
          return a.universe_name.localeCompare(b.universe_name)
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('universe_name'),
    },
    {
      title: 'Description',
      dataIndex: 'universe_description',
      key: 'universe_description',
      sorter: (a, b) => {
        if (a.universe_description && b.universe_description) {
          return a.universe_description.localeCompare(b.universe_description)
        }
        return 0
      },

      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('universe_description'),
    },
    {
      title: 'Is Active',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (_, record) => <Checkbox checked={record.is_active ? true : false}></Checkbox>,
    },

    {
      title: 'Action',
      dataIndex: 'action',
      width: 200,
      render: (_, record) => (
        <>
          <Button
            type="link"
            style={{ padding: '4px 8px' }}
            onClick={() => {
              setSelectedUniverseManager(record)
              setShowFormModal(true)
            }}
          >
            <Tooltip title="Edit">
              <EditOutlined />
            </Tooltip>
          </Button>

          <Popconfirm
            title="Are you sure you want to delete?"
            onConfirm={() => deleteUniverseManager(record.universe_id)}
          >
            <Button type="link" style={{ padding: '4px 8px' }} loading={loadingDeleteUniverseManager}>
              <Tooltip title="Delete">
                <DeleteTwoTone twoToneColor={Colors.danger} />
              </Tooltip>
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ]
  return (
    <StyledTabs
      hideAdd
      type="editable-card"
      onEdit={(targetKey, action) => {
        if (action === 'remove') {
          setListTab(listTab.filter((item) => item.id.toString() !== targetKey))
          setActiveTab('universe-list')
        }
      }}
      onChange={(key) => {
        setActiveTab(key)
      }}
      activeKey={activeTab}
    >
      <Tabs.TabPane tab="Universe List Manager" closable={false} key="universe-list">
        <div style={{ position: 'relative', minHeight: '100%', display: 'flex', flex: 1, flexDirection: 'column' }}>
          <Row>
            <Col span={2} offset={22} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button type="primary" style={{ margin: 8 }} onClick={() => setShowFormModal(true)}>
                Add New
              </Button>
            </Col>
          </Row>
          <Table
            rowKey="id"
            style={{ flex: 1 }}
            dataSource={dataUniverseManager}
            columns={columns}
            loading={loadingUniverseManager}
            scroll={{ y: 'calc(100vh - 380px)' }}
            size="small"
            pagination={{ defaultPageSize: 20 }}
          />
          <UniverseManagerFormModal
            selectedUniverseManager={selectedUniverseManager}
            showFormModal={showFormModal}
            setShowFormModal={setShowFormModal}
            setSelectedUniverseManager={setSelectedUniverseManager}
            updateUniverseManager={updateUniverseManager}
          />
        </div>
      </Tabs.TabPane>
      {listTab.map((item) => {
        if (item.type === 'funds') {
          return (
            <Tabs.TabPane tab={`Fund - ${item.name}`} closable={true} key={item.id}>
              <FundDetailDataProvider id={item.id.toString()} type={'internal'}>
                <FundDetailMain />
              </FundDetailDataProvider>
            </Tabs.TabPane>
          )
        }
        return (
          <Tabs.TabPane tab={`Universe - ${item.name}`} closable={true} key={item.id}>
            <TabContentWrapper style={{ position: 'relative', background: 'white', height: '100%' }}>
              <UniverseManagerDetails
                name={item.name}
                universeId={item.id}
                listTab={listTab}
                setListTab={setListTab}
                setActiveTab={setActiveTab}
              />
            </TabContentWrapper>
          </Tabs.TabPane>
        )
      })}
    </StyledTabs>
  )
}

export default UniverseManager
