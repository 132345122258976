import { NumberOutlined, QuestionCircleOutlined, ShopOutlined } from '@ant-design/icons'
import { Button, Checkbox, Divider, Form, Input, notification, Row } from 'antd'
import { Store } from 'antd/lib/form/interface'
import { useForm } from 'antd/lib/form/util'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../../shared/api'

const SignUpContainer = styled.div`
  display: flex;
  background: white;
  width: 30%;
  min-width: 500px;
  flex-direction: column;
  justify-content: safe center;
  align-items: center;
  padding: 0 40px;
  overflow-y: auto;
  form {
    margin: auto;
    padding: 40px 0;
    width: 100%;
  }
`

type Props = {
  email: string
  password: string
  setIsDbToMl: (isDbToMl: boolean) => void
}

const DbToMlForm: React.FC<Props> = ({ email, password, setIsDbToMl }) => {
  const [form] = useForm()

  const [loading, setLoading] = React.useState(false)
  const [isUpdateSuccess, setIsUpdateSuccess] = React.useState(false)

  const handleSubmit = async (e: Store) => {
    setLoading(true)
    try {
      await APIService.authService.updateDbToMl(
        email,
        password,
        e.fund_or_vehicle_name,
        e.heard_about_hfr_from,
        e.reports_fund_performance_to_hfr,
      )
      notification.success({ message: 'Verification requested!' })
      setIsUpdateSuccess(true)
    } catch (err) {
      notification.error({ message: err.response.data.message })
    } finally {
      setLoading(false)
    }
  }

  return (
    <SignUpContainer>
      {!isUpdateSuccess ? (
        <Form onFinish={handleSubmit} className="login-form" layout="horizontal" form={form}>
          <Divider orientation={'left'}>Update From HFR Database to Manager Link</Divider>
          <Form.Item
            name="fund_or_vehicle_name"
            rules={[{ required: true, message: 'Fund or vehicle name is required' }]}
          >
            <Input
              prefix={<ShopOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="text"
              placeholder="Fund Name / Vehicle Name"
            />
          </Form.Item>
          <Form.Item name="heard_about_hfr_from">
            <Input
              prefix={<QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="text"
              placeholder="Please tell us how you heard about HFR"
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }} name="reports_fund_performance_to_hfr" valuePropName="checked">
            <Checkbox>Check if you currently report fund performance to HFR</Checkbox>
          </Form.Item>

          <Form.Item name="honeypot" style={{ visibility: 'hidden' }}>
            <Input
              prefix={<NumberOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="text"
              placeholder="Honey Pot?"
            />
          </Form.Item>
          <Row justify="space-between">
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ flex: 2 }}
            >
              Submit
            </Button>
            <Button type="link" onClick={() => setIsDbToMl(false)}>
              Cancel
            </Button>
          </Row>
        </Form>
      ) : (
        <h2>
          Thank you for registering with us. Before login to ManagerLink, your request needs to be approved by HFR Team.
        </h2>
      )}
    </SignUpContainer>
  )
}

export default DbToMlForm
