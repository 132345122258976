import { Button, notification, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import APIService from '../../../shared/api'
import { FavoritedFundsResponse } from '../../../shared/api/models/Funds'
import { Colors } from '../../../shared/colors'
import { addTabs, FundFirmTypeEnum } from '../../../slice/appSettingsSlice'

const FundFirmName = styled('span')`
  color: ${Colors.primary};
  cursor: pointer;
`
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  .ant-table-fixed-left .ant-table-body-inner {
    overflow-x: hidden;
  }

  .ant-table-content {
    min-height: calc(70vh - 300px);
  }

  .ant-table-footer {
    padding: 16px 0 0 0;
    background: none;
  }
`
type Props = {
  dataFavorited: FavoritedFundsResponse[] | undefined
  loading: boolean
  onChangeFavorited: () => void
}
export const FavoritedTab: React.FC<Props> = ({ dataFavorited, loading, onChangeFavorited }) => {
  const [listFundSelected, setListFundSelected] = React.useState<string[]>([])
  const [isEdited, setIsEdited] = React.useState(false)
  const [loadingDelete, setLoadingDelete] = React.useState(false)

  const dispatch = useDispatch()

  const columns: ColumnProps<FavoritedFundsResponse>[] = [
    {
      title: 'Fund ID',
      dataIndex: 'fund_id',
      key: 'fund_id',
      width: '30%',
      sorter: (a, b) => {
        if (a.fund_id && b.fund_id) {
          return a.fund_id.localeCompare(b.fund_id)
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Fund Name',
      dataIndex: 'fund_name',
      key: 'fund_name',
      width: '70%',
      sorter: (a, b) => {
        if (a.fund_name && b.fund_name) {
          return a.fund_name.localeCompare(b.fund_name)
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
      render: (_, record) => (
        <FundFirmName
          onClick={() =>
            dispatch(addTabs({ name: record.fund_name, id: record.fund_id, type: FundFirmTypeEnum.FUNDS }))
          }
        >
          {record.fund_name}
        </FundFirmName>
      ),
    },
  ]

  const onSelectChange = (selectedRowKeys: string[]) => {
    setListFundSelected(selectedRowKeys)
    setIsEdited(selectedRowKeys.length > 0)
  }

  const handleSubmit = () => {
    setLoadingDelete(true)

    APIService.fundService
      .updateFavorited({ delete: listFundSelected })
      .then(() => {
        notification.success({
          message: `Delete ${listFundSelected.length} funds to Favorited Funds successfully!`,
        })
        onChangeFavorited()
      })
      .catch((err) => {
        notification.error({ message: `Failed to delete funds to Favorited Funds!` })
      })
      .finally(() => setLoadingDelete(false))
  }

  return (
    <Wrapper>
      <Table
        rowKey="fund_id"
        rowSelection={{
          onChange: (selectedRowKeys) => {
            onSelectChange(selectedRowKeys as string[])
          },
        }}
        dataSource={dataFavorited}
        columns={columns}
        scroll={{ y: 'calc(70vh - 360px)', x: 'min-content' }}
        pagination={dataFavorited && dataFavorited.length > 10 ? undefined : false}
        loading={loading}
      />
      <div style={{ display: 'flex' }}>
        <Button type="primary" danger disabled={!isEdited} onClick={handleSubmit} loading={loadingDelete}>
          Delete
        </Button>
      </div>
    </Wrapper>
  )
}
