import { AxiosInstance, AxiosPromise } from 'axios'

export interface ArticlesResponse {
  id: number
  name: string
  created_date: string
}

export interface ArticleDetailResponse {
  id: number
  name: string
  content: string
  created_date: string
}

export interface ContentService {
  fetchArticles: () => AxiosPromise<ArticlesResponse[]>
  fetchArticleDetail: (articleId: number) => AxiosPromise<ArticleDetailResponse>
}

export const ContentService = (request: AxiosInstance): ContentService => {
  return {
    fetchArticles: () => request.get('/manager-link/article'),
    fetchArticleDetail: (articleId) => request.get(`/manager-link/article/${articleId}`),
  }
}
