import { TableOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../app/rootReducer'
import APIService from '../../../../shared/api'
import { FirmDetailDataContext } from '../../../../shared/api/context/FirmDetailDataContext'
import { ExFundDetails } from '../../../../shared/api/models/ExFundDetails'
import FundOfFirmTable, { EXFUND_FIRM_TABLE } from '../../../../shared/components/FundOfFirmTable'
import { useServiceState } from '../../../../shared/hooks/useServiceState'
import { LeftNavFirmEnum } from '../../../../shared/SearchFirmLabel'
import ColumnBuilder from '../../../ExternalDashboard/ExColumnBuilder/ColumnBuilder'
import {
  allColumns,
  ColumnPropsExtended,
  ColumnSource,
  defaultColumns,
  fundFamilyColumns,
} from '../../../ExternalDashboard/ExColumnBuilder/columnSource'
import { FundSearchColumnContext } from '../../DashboardTab/ColumnBuilder/FundSearchColumnContext'
import FirmLatestPerformance from './FirmLatestPerformance'

type Props = { setActiveTab: (value: LeftNavFirmEnum) => void }
export const FundsOfFirm: React.FC<Props> = ({ setActiveTab }) => {
  const [showColumnBuilder, setShowColumnBuilder] = React.useState(false)
  const { fundsOfFirmColumns } = useSelector((rootState: RootState) => rootState.appSettings)

  const { dataAllColumns } = React.useContext(FundSearchColumnContext)

  const finalAllColumns: ColumnSource[] = useMemo(() => {
    const indexFamilyColumns: ColumnPropsExtended[] =
      dataAllColumns && Object.keys(dataAllColumns).length > 0
        ? Object.keys(dataAllColumns)
            .filter((key) => key.includes('index_family_'))
            .map((indexFamilyKey) => ({
              title: dataAllColumns[indexFamilyKey].human_readable_name,
              dataIndex: indexFamilyKey,
              key: indexFamilyKey as keyof ExFundDetails,
              sorter: true,
            }))
        : []

    return [
      ...allColumns,
      {
        groupName: 'HFR Index Family',
        columns: indexFamilyColumns,
      },
      ...fundFamilyColumns,
    ]
  }, [dataAllColumns])

  const flattenAllColumns = finalAllColumns.flatMap((item) => item.columns)

  const fofColumns =
    fundsOfFirmColumns.length > 0
      ? fundsOfFirmColumns.reduce((acc, key) => {
          const foundColumn = flattenAllColumns.find((item) => item.key === key)
          if (foundColumn) {
            return [...acc, foundColumn]
          }
          return acc
        }, [] as ColumnPropsExtended[])
      : // map((key: any) => flattenAllColumns.find((item) => item.key === key))
        defaultColumns

  const { dataFirmDetail } = React.useContext(FirmDetailDataContext)

  const { invoke: getFundOfFirm, data: dataFundsFirm, loading: loadingFirm } = useServiceState(
    APIService.firmDetailService.fetchFundsOfFirm,
  )

  React.useEffect(() => {
    if (!dataFirmDetail) {
      return
    }
    getFundOfFirm(dataFirmDetail.firm_id)
  }, [dataFirmDetail, getFundOfFirm])

  return (
    <div style={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column', padding: '1rem' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="default" onClick={() => setShowColumnBuilder(true)} style={{ marginLeft: 6 }}>
          <TableOutlined />
          Columns
        </Button>
      </div>
      {showColumnBuilder && (
        <ColumnBuilder isOpen={showColumnBuilder} setOpen={setShowColumnBuilder} isInternal={true} />
      )}
      <FundOfFirmTable
        dataSource={dataFundsFirm}
        loadingTable={loadingFirm}
        type={EXFUND_FIRM_TABLE.FIRM}
        initialColumn={fofColumns}
        setActiveTab={setActiveTab}
        firmId={dataFirmDetail?.firm_id}
      />
      {dataFundsFirm && <FirmLatestPerformance dataFundsFirm={dataFundsFirm} />}
    </div>
  )
}
