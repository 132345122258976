import { Button, Checkbox } from 'antd'
import Table, { ColumnProps } from 'antd/lib/table'
import React from 'react'
import {
  DUE_DILIGENCE_REQUEST_ENUM,
  FundDetailDataContext,
} from '../../../../../shared/api/context/FundDetailDataContext'
import { ChecklistLegalDoc, LegalChecklistUpdate } from '../../../../../shared/api/models/FirmDetails'

type Props = {}
export const LegalDocuments: React.FC<Props> = () => {
  const {
    dataLegalChecklist,
    loadingLegalChecklist,
    getDueDiligenceRequest,
    setDataChangeLegalChecklist,
  } = React.useContext(FundDetailDataContext)

  const [editedDataChecklist, setEditedDataChecklist] = React.useState(dataLegalChecklist)

  React.useEffect(() => {
    if (dataLegalChecklist) {
      setEditedDataChecklist(dataLegalChecklist)
    }
  }, [dataLegalChecklist])

  React.useEffect(() => {
    getDueDiligenceRequest(DUE_DILIGENCE_REQUEST_ENUM.DUE_DILIGENCE_LEGAL_CHECKLIST)
  }, [getDueDiligenceRequest])

  React.useEffect(() => {
    const dataChange: LegalChecklistUpdate[] | undefined = []
    if (dataLegalChecklist && editedDataChecklist) {
      editedDataChecklist.map((item, index) => {
        if (item.status !== dataLegalChecklist[index].status) {
          dataChange.push({ identifier: item.identifier, status: item.status })
        }
      })
      setDataChangeLegalChecklist(dataChange.length > 0 ? dataChange : undefined)
    }
  }, [dataLegalChecklist, editedDataChecklist, setDataChangeLegalChecklist])

  const columns: ColumnProps<Partial<ChecklistLegalDoc>>[] = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record, index) => (
        <Checkbox
          key={index}
          checked={record.status}
          onChange={(e) =>
            editedDataChecklist &&
            setEditedDataChecklist([
              ...editedDataChecklist?.slice(0, index),
              { ...editedDataChecklist[index], status: e.target.checked },
              ...editedDataChecklist?.slice(index + 1, editedDataChecklist.length),
            ])
          }
        ></Checkbox>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Operator ID',
      dataIndex: 'oper_id',
      key: 'oper_id',
    },
    {
      title: 'Record Date',
      dataIndex: 'rec_date',
      key: 'rec_date',
    },
  ]
  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <div style={{ marginTop: '0.5rem', display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="link"
          style={{ marginBottom: 8 }}
          onClick={() => {
            setEditedDataChecklist(dataLegalChecklist)
          }}
        >
          Revert Changes
        </Button>
      </div>
      <Table
        rowKey="description"
        dataSource={editedDataChecklist}
        columns={columns}
        style={{ flex: 1 }}
        loading={loadingLegalChecklist}
        pagination={editedDataChecklist && editedDataChecklist.length > 10 ? undefined : false}
        bordered
      />
    </div>
  )
}
