import { AxiosInstance, AxiosPromise } from 'axios'
import { UniverseDetailsType, UniverseManagerCreateType, UniverseManagerList } from '../models/UniverseManager'

export interface UniverseManagerService {
  getUniverseManagerList: () => AxiosPromise<UniverseManagerList[]>
  createUniverseManager: (params: Partial<UniverseManagerCreateType>) => AxiosPromise<void>
  updateUniverseManager: (universeId: number, params: Partial<UniverseManagerCreateType>) => AxiosPromise<void>
  deleteUniverseManager: (universeId: number) => AxiosPromise<void>
  getUniverseManagerDetail: (universeId: number) => AxiosPromise<UniverseDetailsType>
}
export const UniverseManagerService = (request: AxiosInstance): UniverseManagerService => {
  return {
    getUniverseManagerList: () => request.get(`/manager-link/legacy-universe-list`),
    createUniverseManager: (params) => request.post(`/manager-link/legacy-universe-list`, params),
    updateUniverseManager: (universeId, params) =>
      request.put(`/manager-link/legacy-universe-list/${universeId}`, params),
    deleteUniverseManager: (universeId) => request.delete(`/manager-link/legacy-universe-list/${universeId}`),
    getUniverseManagerDetail: (universeId) => request.get(`/manager-link/legacy-universe-list/${universeId}`),
  }
}
