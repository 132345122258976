import { Modal, notification, Checkbox } from 'antd'
import React from 'react'
import GroupTable from './GroupTable'
import { AnalysisType } from '../../shared/api/models/Analysis'
import APIService from '../../shared/api'
import { AnalysisGroupListContext } from '../../shared/api/context/AnalysisGroupListContext'

type Props = {
  isOpen: boolean
  onRequestClose: () => void
  selectedGroup: AnalysisType
}

const MoveToGroupModal: React.FC<Props> = ({ selectedGroup, isOpen, onRequestClose }) => {
  const [moveToGroup, setMoveToGroup] = React.useState<AnalysisType>()
  const [submitting, setSubmitting] = React.useState(false)
  const { refetchGroup, setAddedRow } = React.useContext(AnalysisGroupListContext)
  const [isMoveToSubGroup, setMoveToSubGroup] = React.useState(false)

  const handleSubmit = () => {
    setSubmitting(true)
    APIService.analysisService
      .updateGroup({ groupCode: selectedGroup.group_code, parentGroupCode: moveToGroup?.group_code || -1 })
      .then(() => {
        notification.success({
          message: `Moved ${selectedGroup.group_name} into ${moveToGroup?.group_name || 'Root'}!`,
        })
        refetchGroup()
        onRequestClose()
        setAddedRow && setAddedRow(selectedGroup.group_code)
      })
      .catch((err) => {
        notification.error({ message: 'Failed To Move The Selected Group!' })
        console.error(err)
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <Modal
      width={700}
      visible={isOpen}
      title={
        <div>
          Move <b>{selectedGroup.group_name}</b> To...
        </div>
      }
      onCancel={onRequestClose}
      onOk={handleSubmit}
      confirmLoading={submitting}
    >
      <Checkbox
        style={{ marginBottom: 8 }}
        checked={isMoveToSubGroup}
        onChange={(e) => {
          if (e.target.checked) {
            setMoveToGroup(undefined)
          }
          setMoveToSubGroup(e.target.checked)
        }}
      >
        Move To Sub-Group
      </Checkbox>
      {isMoveToSubGroup && <GroupTable isShownInModal onSelectGroup={setMoveToGroup} />}
    </Modal>
  )
}

export default MoveToGroupModal
