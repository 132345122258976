import { Button, List } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../../app/rootReducer'
import APIService from '../../../shared/api'
import { useServiceState } from '../../../shared/hooks/useServiceState'
import { addTabs, DASHBOARD_KEY, FundFirmTypeEnum } from '../../../slice/appSettingsSlice'

const TextID = styled(Text)`
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
`
const Flex = styled.div`
  display: flex;
  flex: 1;
`
const StyledList = styled(List)`
  flex: 1;
  border: none;
  .ant-list-item {
    padding: 4px;
  }
`
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 550px);
  flex: 1;
  flex-direction: column;
`
export const RecentlyEditedTab: React.FC = () => {
  const { invoke: getRecentlyEdited, data: dataEdited, loading } = useServiceState(
    APIService.fundService.fetchRecentlyEdited,
  )
  const { fundFirmTabs } = useSelector((state: RootState) => state.appSettings)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (fundFirmTabs.activeTab === DASHBOARD_KEY) {
      getRecentlyEdited()
    }
  }, [getRecentlyEdited, fundFirmTabs.activeTab])

  return (
    <Wrapper>
      {dataEdited && (
        <StyledList
          bordered
          loading={loading}
          dataSource={dataEdited?.funds}
          renderItem={(item: any) => (
            <List.Item style={{ width: '100%' }}>
              <div>
                <TextID>Fund #{item.fund_id}</TextID>
                <Flex style={{ alignItems: 'baseline' }}>
                  <div>
                    <Button
                      type="link"
                      style={{ fontSize: 12, fontWeight: 'bold', color: '#7598E6', padding: 0 }}
                      onClick={() =>
                        dispatch(addTabs({ name: item.fund_name, id: item.fund_id, type: FundFirmTypeEnum.FUNDS }))
                      }
                    >
                      {item.fund_name}
                    </Button>
                  </div>
                </Flex>
              </div>
            </List.Item>
          )}
        />
      )}
    </Wrapper>
  )
}
