import { Col, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { FundDetailDropdownContext } from '../../../shared/api/context/FundDetailDropdownContext'
import { FormItemInput, FormItemSelect } from '../../../shared/components/FormItemComponents'
import { RenderLabelExFirm } from '../../../shared/components/RenderLabelExFirm'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 0 1rem;
  margin-top: 1rem;
  flex-direction: column;
`

type Props = {}
const ContactInformation: React.FC<Props> = () => {
  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)
  const LIST_FORM_CONTACT = [
    {
      name: 'contact_name1',
      label: 'Contact Name 1',
    },
    {
      name: 'contact_name2',
      label: 'Contact Name 2',
    },
    {
      name: 'contact_address1',
      label: 'Address',
    },
    {
      name: 'contact_address2',
      label: 'Address 2',
    },
    {
      name: 'contact_city',
      label: 'City',
    },
    {
      name: 'contact_state',
      label: 'State',
      options:
        fundDataDropdown?.contact_state && fundDataDropdown.contact_state.length > 0
          ? fundDataDropdown.contact_state.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
    },
    {
      name: 'contact_postal_code',
      label: 'Zip',
    },
    {
      name: 'country',
      label: 'Country',
      options:
        fundDataDropdown?.country && fundDataDropdown.country.length > 0
          ? fundDataDropdown.country.map((item) => ({
              value: item.code_code,
              text: item.code_description,
            }))
          : [],
    },
    {
      name: 'contact_phone',
      label: 'Phone',
    },
    {
      name: 'contact_fax',
      label: 'Fax',
    },
    {
      name: 'contact_email',
      label: 'Email',
    },
    {
      name: 'contact_web',
      label: 'Website',
    },
  ]
  return (
    <Wrapper>
      {LIST_FORM_CONTACT.map((item) => {
        return (
          <Row key={item.name}>
            <Col span={12} xl={12} md={16} xs={24} sm={24}>
              {item.options ? (
                <FormItemSelect
                  name={item.name}
                  label={<RenderLabelExFirm value={item.label} />}
                  options={item.options}
                  labelCol={12}
                  wrapperCol={12}
                />
              ) : (
                <FormItemInput
                  name={item.name}
                  label={<RenderLabelExFirm value={item.label} />}
                  labelCol={12}
                  wrapperCol={12}
                />
              )}
            </Col>
          </Row>
        )
      })}
    </Wrapper>
  )
}

export default ContactInformation
