import Table, { ColumnProps } from 'antd/lib/table'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../../../shared/api'
import { FundDetailDataContext } from '../../../../shared/api/context/FundDetailDataContext'
import { NextBackButton } from '../../../../shared/components/NextBackButton'
import { useServiceState } from '../../../../shared/hooks/useServiceState'
const Flex = styled.div`
  display: flex;
  flex: 1;
  padding: 0.5rem 1rem;
  .ant-table-cell {
    padding: 0px 8px;
  }
`
type TableType = {
  year: number
  jan?: string
  feb?: string
  mar?: string
  apr?: string
  may?: string
  jun?: string
  jul?: string
  aug?: string
  sep?: string
  oct?: string
  nov?: string
  dec?: string
  ytd?: string
}
const MonthList = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']

type Props = {}
export const APGL: React.FC<Props> = () => {
  const { dataFundDetail } = React.useContext(FundDetailDataContext)
  const { invoke: getAPGL, data: dataAPGL, loading: dataLoading } = useServiceState(
    APIService.fundDetailService.fetchFundAPGL,
  )

  const dataSource =
    dataAPGL &&
    dataAPGL.monthly_data.reduce((acc, current) => {
      const currentYear = current.year
      const monthCurrent = current.month
      const index = acc.findIndex((item) => item.year === currentYear)
      if (index > -1) {
        return [
          ...acc.slice(0, index),
          { ...acc[index], [MonthList[monthCurrent - 1]]: current.value },
          ...acc.slice(index + 1, acc.length),
        ]
      }
      const currentytd = dataAPGL.ytd_data[currentYear] || undefined
      return currentytd
        ? [...acc, { year: currentYear, ytd: currentytd, [MonthList[monthCurrent - 1]]: current.value }]
        : [...acc, { year: currentYear }]
    }, [] as TableType[])

  React.useEffect(() => {
    if (!dataFundDetail) {
      return
    }
    getAPGL(dataFundDetail.fund_id)
  }, [dataFundDetail, getAPGL])
  const columns: ColumnProps<TableType>[] = [
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: 'Jan',
      dataIndex: 'jan',
      key: 'jan',
    },
    {
      title: 'Feb',
      dataIndex: 'feb',
      key: 'feb',
    },
    {
      title: 'Mar',
      dataIndex: 'mar',
      key: 'mar',
    },
    {
      title: 'Apr',
      dataIndex: 'apr',
      key: 'apr',
    },
    {
      title: 'May',
      dataIndex: 'may',
      key: 'may',
    },
    {
      title: 'Jun',
      dataIndex: 'jun',
      key: 'jun',
    },
    {
      title: 'Jul',
      dataIndex: 'jul',
      key: 'jul',
    },
    {
      title: 'Aug',
      dataIndex: 'aug',
      key: 'aug',
    },
    {
      title: 'Sep',
      dataIndex: 'sep',
      key: 'sep',
    },
    {
      title: 'Oct',
      dataIndex: 'oct',
      key: 'oct',
    },
    {
      title: 'Nov',
      dataIndex: 'nov',
      key: 'nov',
    },
    {
      title: 'Dec',
      dataIndex: 'dec',
      key: 'dec',
    },
    {
      title: 'Annual',
      dataIndex: 'ytd',
      key: 'ytd',
    },
  ]

  return (
    <Flex style={{ flexDirection: 'column' }}>
      <div>
        <Table
          rowKey="year"
          bordered={true}
          loading={dataLoading}
          dataSource={dataSource}
          columns={columns}
          pagination={dataSource && dataSource.length < 10 ? false : undefined}
        />
      </div>
      <NextBackButton />
    </Flex>
  )
}
