import { Collapse } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { NextBackButton } from '../../../../../shared/components/NextBackButton'
import { ReceivedSepAcct } from './ReceivedSepAcct'
import { SentSepAcct } from './SentSepAcct'
const Flex = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
`
const { Panel } = Collapse
const StyledCollapse = styled(Collapse)`
  margin: 1rem;
  .ant-collapse-content-box {
    padding: 0;
    flex: 1;
    display: flex;
    overflow: hidden;
  }
`
type Props = {}
export const SepAcct: React.FC<Props> = () => {
  return (
    <Flex>
      <StyledCollapse style={{ margin: '1rem' }} defaultActiveKey="sent">
        <Panel header="Sent" key="sent" style={{ padding: 0 }}>
          <SentSepAcct />
        </Panel>
        <Panel header="Received" key="Received">
          <ReceivedSepAcct />
        </Panel>
      </StyledCollapse>
      <NextBackButton />
    </Flex>
  )
}
