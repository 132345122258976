import { DeleteTwoTone, EditOutlined } from '@ant-design/icons'
import { Button, notification, Popconfirm, Spin, Table, Tooltip } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../../../shared/api'
import { FirmDetailDataContext } from '../../../../shared/api/context/FirmDetailDataContext'
import { CodeOfConductResponse } from '../../../../shared/api/models/FirmDetails'
import { Colors } from '../../../../shared/colors'
import { useServiceState } from '../../../../shared/hooks/useServiceState'
import { ModalEditConduct } from './ModalEditConduct'
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-x: auto;
`
export const CodeOfConduct: React.FC = () => {
  const { dataFirmDetail } = React.useContext(FirmDetailDataContext)
  const [showModal, setShowModal] = React.useState(false)
  const [selectedConduct, setSelectedConduct] = React.useState<CodeOfConductResponse | undefined>()
  const [loadingDelete, setLoadingDelete] = React.useState(false)
  const [itemDelete, setItemDelete] = React.useState<CodeOfConductResponse | undefined>()
  const { invoke: getCodeOfConduct, data: dataCodeOfConduct, loading } = useServiceState(
    APIService.firmDetailService.fetchCodeOfConduct,
  )

  React.useEffect(() => {
    getCodeOfConduct(dataFirmDetail?.firm_id)
  }, [dataFirmDetail, getCodeOfConduct])

  const deleteConduct = (record: CodeOfConductResponse) => {
    if (dataFirmDetail?.firm_id) {
      setLoadingDelete(true)
      APIService.firmDetailService
        .deleteCodeOfConduct(dataFirmDetail.firm_id, {
          email: record.email,
          time_stamp: record.time_stamp,
          agreement_version: record.agreement_version,
          code_of_conduct: record.code_of_conduct,
        })
        .then(() => {
          notification.success({ message: 'Code Of Conduct Delete!' })
          updateConduct()
          setItemDelete(undefined)
        })
        .catch((err) => {
          console.error({ err })
          notification.error({ message: 'Code Of Conduct Delete Failed!' })
        })
        .finally(() => {
          setLoadingDelete(false)
        })
    }
  }

  const updateConduct = () => {
    dataFirmDetail && getCodeOfConduct(dataFirmDetail.firm_id)
  }

  const columns: ColumnProps<CodeOfConductResponse>[] = [
    {
      title: 'Time Stamp',
      dataIndex: 'time_stamp',
      key: 'time_stamp',
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: 'Job Title',
      dataIndex: 'job_title',
      key: 'job_title',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Agreement Version',
      dataIndex: 'agreement_version',
      key: 'agreement_version',
    },
    {
      title: 'Code Of Conduct',
      dataIndex: 'code_of_conduct',
      key: 'code_of_conduct',
      width: 40,
    },
    {
      title: 'SOURCE',
      dataIndex: 'source',
      key: 'source',
    },
    {
      title: 'Initials',
      dataIndex: 'initials',
      key: 'initials',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) =>
        loadingDelete && record.email === itemDelete?.email && record.time_stamp === itemDelete.time_stamp ? (
          <Spin />
        ) : (
          <>
            <Button
              type="link"
              style={{ padding: '4px 8px' }}
              onClick={() => {
                setShowModal(true)
                setSelectedConduct(record)
              }}
            >
              <Tooltip title="Edit">
                <EditOutlined />
              </Tooltip>
            </Button>

            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => {
                setItemDelete(record)
                deleteConduct(record)
              }}
            >
              <Button type="link" style={{ padding: '4px 8px' }}>
                <Tooltip title="Delete">
                  <DeleteTwoTone twoToneColor={Colors.danger} />
                </Tooltip>
              </Button>
            </Popconfirm>
          </>
        ),
    },
  ]
  return (
    <Wrapper>
      <div
        style={{
          padding: '0.5rem 1.5rem',
          background: 'rgb(250, 250, 250)',
          borderBottom: `solid 1px ${Colors.border}`,
          fontWeight: 'bold',
        }}
      >
        Submitter Code of Conduct
      </div>
      <div style={{ marginTop: '0.5rem', display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="primary"
          style={{ marginBottom: 8, marginRight: 8 }}
          onClick={() => setShowModal(true)}
          disabled={loadingDelete}
        >
          Create New Code Of Conduct
        </Button>
      </div>
      <Table
        size="small"
        rowKey="source"
        loading={loading}
        bordered
        dataSource={dataCodeOfConduct}
        columns={columns}
        pagination={dataCodeOfConduct && dataCodeOfConduct.length > 10 ? undefined : false}
      />
      {dataFirmDetail?.firm_id && (
        <ModalEditConduct
          showModal={showModal}
          selectedConduct={selectedConduct}
          setShowModal={setShowModal}
          setSelectedConduct={setSelectedConduct}
          updateConduct={updateConduct}
          firmId={dataFirmDetail.firm_id}
        />
      )}
    </Wrapper>
  )
}
