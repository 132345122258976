import { Spin } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'
import { RootState } from './app/rootReducer'
import { Admin } from './routes/Admin/Admin'
import Analysis from './routes/Analysis/Analysis'
import { UserProfileChangePasswordForm } from './routes/Auth/ChangePassword/UserProfileChangePasswordForm'
import Dashboard from './routes/Dashboard/Dashboard'
import ExternalDashboard from './routes/ExternalDashboard/ExDashboard'
import { HelpAndFaqMain } from './routes/HelpAndFaq/HelpAndFaqMain'
import ManagerList from './routes/ManagerList/ManagerList'
import System from './routes/System/System'
import { history } from './shared/history'
import { fetchAppData } from './slice/appDataSlice'
import { saveToken } from './slice/authSlice'
import { fetchUser } from './slice/userSlice'

type Props = {} & RouteComponentProps

const ProtectedRoute: React.FC<Props> = ({ location }) => {
  const dispatch = useDispatch()
  const auth = useSelector((state: RootState) => state.auth)
  const accessToken = auth?.accessToken
  const { user, isLoading: userLoading } = useSelector((state: RootState) => state.user)

  const [loading, setLoading] = useState(true)

  React.useEffect(() => {
    // fetching user to check if the user has the permission or not
    const qs = new URLSearchParams(location.search)

    const token = accessToken || (qs.has('token') && qs.get('token'))

    if (token) {
      dispatch(saveToken(token))
      dispatch(fetchUser())
      return
    }
    history.push('/auth')
  }, [accessToken, dispatch, location.search])

  React.useEffect(() => {
    // fetch app data when user is available
    if (!userLoading && user && accessToken) {
      dispatch(fetchAppData())
      setLoading(false)
    }
  }, [accessToken, dispatch, user, userLoading])

  if (loading) {
    return (
      <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spin tip="Loading User..." />
      </div>
    )
  }

  if (!accessToken || !user) {
    return null
  }

  return user.hfrml_internal_user ? (
    <Switch>
      <Route path="/" exact component={Dashboard} />
      <Route path="/manager-list" component={ManagerList} />
      <Route path="/funds/:fundId/:activeFundNav?" component={Dashboard} />
      <Route path="/firms/:firmId/:activeFirmNav?" component={Dashboard} />
      <Route path="/analysis" component={Analysis} />
      <Route path="/help-and-faqs" component={HelpAndFaqMain} />
      <Route path="/user/change-password" component={UserProfileChangePasswordForm} />
      <Route path="/system" component={System} />
      <Route path="/admin" component={Admin} />
      <Redirect to="/" />
    </Switch>
  ) : (
    <Switch>
      <Route path="/" exact component={ExternalDashboard} />
      <Route exact path="/funds/:fundId/preview/profile" component={ExternalDashboard} />
      <Route exact path="/funds/:fundId/preview/peergroup" component={ExternalDashboard} />
      <Route path="/funds/:fundId/:fundEsg?" component={ExternalDashboard} />
      <Route path="/firms/:firmId/:firmEsg?" component={ExternalDashboard} />
      <Route path="/help-and-faqs" component={HelpAndFaqMain} />
      <Route path="/user/change-password" component={UserProfileChangePasswordForm} />
      <Redirect to="/" />
    </Switch>
  )
}

export default ProtectedRoute
