import { Col, Form, Modal, notification, Row, Spin } from 'antd'
import React from 'react'
import APIService from '../../../../shared/api'
import { PropertiesInfo } from '../../../../shared/api/models/FundDetails'
import { FormItemInput, FormItemSelect } from '../../../../shared/components/FormItemComponents'

type Props = {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  selectedProperties: PropertiesInfo | undefined
  setSelectedProperties: (value: PropertiesInfo | undefined) => void
  updateProperties: () => void
  fundId: string
  listProperties: { prop_id: number; prop_name: string }[]
}
export const ModalEditTableFundProperties: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  selectedProperties,
  setSelectedProperties,
  fundId,
  updateProperties,
  listProperties,
}) => {
  const [loadingSubmit, setLoadingSubmit] = React.useState(false)
  const [form] = Form.useForm()
  React.useEffect(() => {
    if (selectedProperties) {
      form.setFieldsValue(selectedProperties)
    } else {
      form.resetFields()
    }
  }, [form, selectedProperties])

  const handleUpdateRow = () => {
    const fieldSubmit = {
      prop_id: form.getFieldValue('prop_id'),
      prop_value: form.getFieldValue('prop_value'),
    }
    form.validateFields(['prop_name', 'prop_value']).then(() => {
      setLoadingSubmit(true)
      selectedProperties
        ? APIService.fundDetailService
            .updateFundProperties(fundId, fieldSubmit)
            .then(() => {
              notification.success({ message: 'Fund Property Updated!' })
              updateProperties()
            })
            .catch((err) => {
              console.error({ err })
              notification.error({ message: 'Fund Property Update Failed!' })
            })
            .finally(() => {
              setLoadingSubmit(false)
              setIsOpen(false)
              setSelectedProperties(undefined)
              form.resetFields()
            })
        : APIService.fundDetailService
            .createFundProperties(fundId, fieldSubmit)
            .then(() => {
              notification.success({ message: 'Fund Property Created!' })
              updateProperties()
            })
            .catch((err) => {
              console.error({ err })
              notification.error({ message: 'Fund Property Create Failed!' })
            })
            .finally(() => {
              setLoadingSubmit(false)
              setIsOpen(false)
              setSelectedProperties(undefined)
              form.resetFields()
            })
    })
  }
  return (
    <Modal
      title={selectedProperties ? 'Edit Fund Properties' : 'Create New Fund Properties'}
      visible={isOpen}
      okText={
        loadingSubmit ? (
          <>
            <Spin style={{ marginRight: 4 }} />
          </>
        ) : (
          'OK'
        )
      }
      onOk={() => handleUpdateRow()}
      onCancel={() => {
        setSelectedProperties(undefined)
        setIsOpen(false)
      }}
    >
      <div>
        <Form layout="vertical" form={form}>
          <Row gutter={8}>
            <Col span={24}>
              <FormItemSelect
                disabled={!!selectedProperties}
                label="Property Name"
                name="prop_id"
                rules={[{ required: true, message: 'Property Name is required' }]}
                options={listProperties.map((item) => ({ text: item.prop_name, value: item.prop_id }))}
              />
            </Col>
            <Col span={24}>
              <FormItemInput
                name="prop_value"
                rules={[{ required: true, message: 'Property Value is required' }]}
                label="Property Value"
                placeholder="Property Value"
                maxLength={60}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
