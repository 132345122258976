import { FormInstance } from 'antd/lib/form'
import * as React from 'react'
import { LeftNavExFundEnum } from '../SearchExFundLabel'

export const useFieldValidate = (
  arrayFieldValidate: string[],
  isDirty: boolean,
  form: FormInstance,
  onUpdateTabDirty: (params: LeftNavExFundEnum, isDirty: boolean) => void,
  param: LeftNavExFundEnum,
) => {
  // mark tab dirty
  React.useEffect(() => {
    return () => {
      if (!isDirty) {
        onUpdateTabDirty(param, true)
      }
    }
  }, [isDirty, onUpdateTabDirty, param])

  // re-validate the corresponding required fields when visit a dirty tab
  React.useEffect(() => {
    if (isDirty) {
      form.validateFields(arrayFieldValidate)
    }
  }, [arrayFieldValidate, form, isDirty])
}
