import { Form, Input, notification, Spin } from 'antd'
import { useForm } from 'antd/lib/form/util'
import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import APIService from '../../shared/api'
import { IndexManagerActionFund } from './IndexManagerDetails'
type Props = {
  showModal: boolean
  setShowModal: (value: boolean) => void
  updateIndexManagerDetail: () => void
  indexId: number
  actionFunds: IndexManagerActionFund
}
export const IndexManagerModalAddFund: React.FC<Props> = ({
  actionFunds,
  showModal,
  setShowModal,
  updateIndexManagerDetail,
  indexId,
}) => {
  const [loadingSubmit, setLoadingSubmit] = React.useState(false)
  const [form] = useForm()

  const submitFormIndexManager = () => {
    form.validateFields(['funds']).then(() => {
      const fundIds = form.getFieldValue('funds')
      if (!fundIds || !fundIds.trim()) {
        return
      }
      const updatedFundIds = fundIds.trim().split('\n')
      setLoadingSubmit(true)
      actionFunds === IndexManagerActionFund.ADD_FUNDS
        ? APIService.indexManagerService
            .updateIndexManagerDetail(indexId, { fund_ids: updatedFundIds })
            .then(() => {
              notification.success({ message: `Add ${updatedFundIds.length} funds to Index Manager !` })
              setShowModal(false)
              updateIndexManagerDetail()
            })
            .catch((err) => {
              console.error(err)
              notification.error({ message: 'Failed to add funds to Index Manager!' })
            })
            .finally(() => setLoadingSubmit(false))
        : APIService.indexManagerService
            .updateIndexManagerDetail(indexId, { delete_fund_ids: updatedFundIds })
            .then(() => {
              notification.success({ message: `Funds deleted from Index Manager !` })
              setShowModal(false)
              updateIndexManagerDetail()
            })
            .catch((err) => {
              notification.error({ message: `Failed to delete funds!` })
            })
            .finally(() => setLoadingSubmit(true))
    })
  }

  return (
    <Modal
      width={700}
      visible={showModal}
      title={actionFunds === IndexManagerActionFund.ADD_FUNDS ? 'Add New Funds' : 'Delete Funds'}
      okText={
        loadingSubmit ? (
          <>
            <Spin style={{ marginRight: 4 }} />
          </>
        ) : (
          'OK'
        )
      }
      onOk={submitFormIndexManager}
      onCancel={() => {
        setShowModal(false)
      }}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label="Fund IDs"
          required
          rules={[{ required: true, message: 'Please enter Fund IDs' }]}
          name="funds"
        >
          <Input.TextArea
            placeholder="Enter or copy/paste single or multiple Fund IDs separated by new line"
            rows={10}
          />
        </Form.Item>
        <div>
          <span>
            {actionFunds === IndexManagerActionFund.ADD_FUNDS
              ? 'Funds which are already inside the group will be ignored'
              : 'Funds which are outside the group will be ignored'}
          </span>
        </div>
      </Form>
    </Modal>
  )
}
