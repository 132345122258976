import React, { FC } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/rootReducer'
import { Button, List } from 'antd'
import { addTabs, FundFirmTypeEnum } from '../../../slice/appSettingsSlice'
import Text from 'antd/lib/typography/Text'

const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 550px);
`
const TextID = styled(Text)`
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
`
const StyledList = styled(List)`
  flex: 1;
  overflow: auto;
  border: none;
  .ant-list-item {
    padding: 4px;
  }
`
const Flex = styled.div`
  display: flex;
  flex: 1;
`

export const DraftTab: FC = () => {
  const { fundDraft, firmDraft } = useSelector((state: RootState) => state.appSettings)
  const { user } = useSelector((state: RootState) => state.user)
  const myDrafts = [
    ...fundDraft.filter((value) => value.userId === user?.email),
    ...firmDraft.filter((value) => value.userId === user?.email),
  ]

  const dispatch = useDispatch()
  return (
    <Wrapper>
      <StyledList
        bordered
        dataSource={myDrafts}
        renderItem={(item: any) => (
          <List.Item style={{ width: '100%' }}>
            <div>
              <TextID>
                {item.type === 'fundDraft' ? 'Fund' : 'Firm'} #{item.id}
              </TextID>
              <Flex style={{ marginBottom: 0 }}>
                <Flex style={{ alignItems: 'baseline' }}>
                  <div>
                    <Button
                      type="link"
                      style={{ fontSize: 12, fontWeight: 'bold', color: '#7598E6', padding: 0 }}
                      onClick={() =>
                        dispatch(
                          addTabs({
                            name: item.name,
                            id: item.id,
                            type: item.type === 'fundDraft' ? FundFirmTypeEnum.FUNDS : FundFirmTypeEnum.FIRMS_IN_FUNDS,
                          }),
                        )
                      }
                    >
                      {item.name}
                    </Button>
                  </div>
                </Flex>
              </Flex>
            </div>
          </List.Item>
        )}
      />
    </Wrapper>
  )
}
