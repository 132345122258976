import { CheckOutlined, CloseOutlined, DeleteTwoTone, EditOutlined } from '@ant-design/icons'
import { Button, Col, Form, Modal, notification, Popconfirm, Row, Select, Spin, Table, Tooltip } from 'antd'
import { Store } from 'antd/lib/form/interface'
import { ColumnProps } from 'antd/lib/table'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import APIService from '../api'
import { FundDetailDataContext } from '../api/context/FundDetailDataContext'
import { InvestorType } from '../api/models/FundDetails'
import { Colors } from '../colors'
import { useServiceState } from '../hooks/useServiceState'
import EditableCell from './EditableTable/EditableCell'
import EditableFormRow from './EditableTable/EditableFormRow'
import { FormItemDatePicker, FormItemInput } from './FormItemComponents'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  margin: 0 2rem;
  margin-top: 1rem;
  flex-direction: column;
  .ant-table-cell {
    padding: 0px 8px;
  }
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 0 12px;
  }
`
type Props = { isOverview?: boolean; dataDropdown?: { [key: number]: string }[] }
export const InvestorTypeTable: React.FC<Props> = ({ isOverview, dataDropdown }) => {
  const { dataFundDetail } = React.useContext(FundDetailDataContext)
  const { invoke: getInvestorType, data: investorTypeData, loading: dataLoading } = useServiceState(
    APIService.fundDetailService.fetchFundInvestorTypes,
  )
  React.useEffect(() => {
    if (!dataFundDetail) {
      return
    }
    getInvestorType(dataFundDetail.fund_id)
  }, [getInvestorType, dataFundDetail])

  const selectOptions = dataDropdown
    ? dataDropdown.map((item) => {
        const key = Object.keys(item)[0]
        //@ts-ignore
        const value = item[key]
        return { label: value, value: key }
      })
    : undefined

  const [localData, setLocalData] = React.useState<(InvestorType & { key: string })[]>()
  const [editingRow, setEditingRow] = React.useState<InvestorType & { key: string }>()
  const [submitting, setSubmitting] = React.useState(false)
  const [showAddNew, setShowAddNew] = React.useState(false)

  const [form] = Form.useForm()

  React.useEffect(() => {
    if (!investorTypeData) {
      return
    }
    setLocalData(
      investorTypeData.map((item) => ({
        ...item,
        resolvedQuarter: item.date,
        key: `${item.date}_${item.name}`,
      })),
    )
  }, [investorTypeData])

  const handleAddRow = async (value: Store) => {
    if (!value || !dataFundDetail || !selectOptions) {
      return
    }
    const selectedInvestor = selectOptions?.find((item) => item.label === value.name)
    if (!selectedInvestor) {
      return
    }
    try {
      setSubmitting(true)
      await APIService.fundDetailService.createFundInvestorType(dataFundDetail.fund_id, {
        work_date: moment(value.date).format('YYYY-MM-DD'),
        name: selectedInvestor?.value,
        assets: value.assets ? parseFloat(value.assets.toString()) : undefined,
        number: value.number ? parseFloat(value.number.toString()) : undefined,
      })
      form.resetFields()
      getInvestorType(dataFundDetail.fund_id)
      notification.success({ message: 'Row created successfully!' })
      setShowAddNew(false)
    } catch (error) {
      console.log(error)
      notification.error({ message: 'Fail to create new row!' })
    } finally {
      setSubmitting(false)
      setEditingRow(undefined)
    }
  }

  const handleAddNewRow = () => {
    if (!showAddNew) {
      setShowAddNew(true)
    }
  }

  const handleUpdateRow = async () => {
    if (!editingRow || !dataFundDetail) {
      return
    }
    try {
      setSubmitting(true)
      await APIService.fundDetailService.updateFundInvestorType(dataFundDetail.fund_id, {
        work_date: editingRow.key.split('_')[0],
        name: editingRow.key.split('_')[1],
        updates: {
          work_date: editingRow.date,
          name: editingRow.name,
          assets: editingRow.assets ? parseFloat(editingRow.assets.toString()) : undefined,
          number: editingRow.number ? parseFloat(editingRow.number.toString()) : undefined,
        },
      })
      getInvestorType(dataFundDetail.fund_id)
      notification.success({ message: 'Row updated successfully!' })
    } catch (err) {
      console.log(err)
      notification.error({ message: 'Failed to update row!' })
    } finally {
      setSubmitting(false)
      setEditingRow(undefined)
    }
  }

  const handleDeleteRow = async (date: string, name: string) => {
    if (!dataFundDetail) {
      return
    }
    if (date === '') {
      // newly added row
      setLocalData((prev) => prev?.filter((item) => item.date !== ''))
      setEditingRow(undefined)
      return
    }
    try {
      setSubmitting(true)
      await APIService.fundDetailService.deleteFundInvestorType(dataFundDetail.fund_id, date, name)
      getInvestorType(dataFundDetail.fund_id)
      notification.success({ message: 'Row deleted successfully!' })
    } catch (error) {
      console.log(error)
      notification.error({ message: 'Fail to delete row!' })
    } finally {
      setSubmitting(false)
      setEditingRow(undefined)
    }
  }

  const columns: ColumnProps<InvestorType & { key: string }>[] = [
    {
      title: 'Date',
      key: 'quarter',
      dataIndex: 'resolvedQuarter',
      sorter: (a, b) => {
        return a.date.localeCompare(b.date)
      },
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
      // @ts-ignore
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'resolvedQuarter',
        editingRow,
        setEditingRow,
      }),
      width: '20%',
    },
    {
      title: 'Investor Type',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <span>{selectOptions?.find((item) => item.value === record.name)?.label || ''}</span>,
      sorter: (a, b) => {
        return a.name.localeCompare(b.name)
      },
      sortDirections: ['descend', 'ascend'],
      // @ts-ignore
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'name',
        editingRow,
        setEditingRow,
        optionSelect: selectOptions,
      }),
      width: '20%',
    },
    {
      title: '% of Total Assets',
      dataIndex: 'assets',
      key: 'assets',
      sorter: (a, b) => {
        return (a.assets || 0) - (b.assets || 0)
      },
      sortDirections: ['descend', 'ascend'],
      // @ts-ignore
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'assets',
        editingRow,
        setEditingRow,
      }),
      width: '15%',
    },
    {
      title: '% of All Investors',
      dataIndex: 'number',
      key: 'number',
      sorter: (a, b) => {
        return (a.number || 0) - (b.number || 0)
      },
      sortDirections: ['descend', 'ascend'],
      // @ts-ignore
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'number',
        editingRow,
        setEditingRow,
      }),
      width: '15%',
    },
    ...(!isOverview
      ? [
          {
            title: 'Action',
            dataIndex: 'action',
            width: '10%',
            render: (_: any, record: any) => (
              <>
                {!submitting && !editingRow && (
                  <Button
                    type="link"
                    style={{ padding: '4px 8px' }}
                    onClick={() => setEditingRow({ ...record, key: record.key })}
                  >
                    <Tooltip title="Edit">
                      <EditOutlined />
                    </Tooltip>
                  </Button>
                )}
                {editingRow && editingRow.key === record.key && !submitting && (
                  <>
                    <Button type="link" style={{ padding: '4px 8px' }} onClick={() => handleUpdateRow()}>
                      <Tooltip title="Save">
                        <CheckOutlined />
                      </Tooltip>
                    </Button>
                    <Button
                      type="link"
                      style={{ color: Colors.gray, padding: '4px 8px' }}
                      onClick={() => {
                        setEditingRow(undefined)
                        localData && setLocalData(localData?.filter((item) => item.key !== 'new-row'))
                      }}
                    >
                      <Tooltip title="Cancel">
                        <CloseOutlined />
                      </Tooltip>
                    </Button>
                  </>
                )}
                {editingRow && editingRow.key === record.key && submitting && <Spin />}
                {!(editingRow && editingRow.key === record.key) && (
                  <Popconfirm
                    title="Are you sure you want to delete?"
                    onConfirm={() => {
                      handleDeleteRow(record.date, record.name)
                    }}
                  >
                    <Button type="link" style={{ padding: '4px 8px' }}>
                      <Tooltip title="Delete">
                        <DeleteTwoTone twoToneColor={Colors.danger} />
                      </Tooltip>
                    </Button>
                  </Popconfirm>
                )}
              </>
            ),
          },
        ]
      : []),
  ]

  const components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell,
    },
  }
  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <Wrapper>
        {!isOverview && (
          <div>
            <Button type="primary" style={{ marginBottom: 8 }} onClick={handleAddNewRow}>
              Add New Row
            </Button>
          </div>
        )}
        <Table
          rowKey="key"
          rowClassName={() => 'editable-row'}
          components={components}
          loading={dataLoading}
          dataSource={localData}
          columns={columns}
          scroll={{ y: 'calc(100vh - 478px)' }}
          pagination={{
            hideOnSinglePage: true,
            showSizeChanger: false,
            defaultPageSize: 50,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
          style={{ flex: 1 }}
          bordered
        />
        <Modal
          title="Add New Investor Type"
          visible={showAddNew}
          onCancel={() => {
            setShowAddNew(false)
            form.resetFields()
          }}
          onOk={form.submit}
        >
          <Form form={form} layout="vertical" onFinish={handleAddRow}>
            <FormItemDatePicker
              name="date"
              label="Date"
              rules={[{ required: true, message: 'Date is required' }]}
              picker="quarter"
            />
            <Form.Item
              label="Investor Type"
              name={'name'}
              rules={[{ required: true, message: 'Investor Type is required' }]}
            >
              <Select
                style={{
                  width: '100%',
                }}
                options={dataDropdown?.map((item) => {
                  const key = Object.keys(item)[0]
                  // @ts-ignore
                  const value = item[key]
                  return { text: value, value: value }
                })}
                showSearch
                allowClear
                filterOption={(inputValue, option) => {
                  return option?.value.toString().toUpperCase().indexOf(inputValue.toString().toUpperCase()) !== -1
                }}
              />
            </Form.Item>
            <Row gutter={8}>
              <Col span={12}>
                <FormItemInput
                  name="assets"
                  label="% of Total Assets"
                  rules={[{ required: true, message: 'This field is required' }]}
                  typeField="number"
                />
              </Col>
              <Col span={12}>
                <FormItemInput
                  name="number"
                  label="% of All Investors"
                  rules={[{ required: true, message: 'This field is required' }]}
                  typeField="number"
                />
              </Col>
            </Row>
          </Form>
        </Modal>
      </Wrapper>
    </div>
  )
}
