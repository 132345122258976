import {
  FieldTimeOutlined,
  HighlightOutlined,
  HomeOutlined,
  InfoOutlined,
  MailOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Col, Form, Modal, notification, Row, Spin } from 'antd'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../../../shared/api'
import { CodeOfConductResponse } from '../../../../shared/api/models/FirmDetails'
import { FormItemDatePicker, FormItemInput, FormItemSelect } from '../../../../shared/components/FormItemComponents'

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0 1.5rem;
    overflow: auto;
  }
`
type Props = {
  selectedConduct: CodeOfConductResponse | undefined
  showModal: boolean
  setShowModal: (value: boolean) => void
  setSelectedConduct: (value: CodeOfConductResponse | undefined) => void
  updateConduct: () => void
  firmId: string
}
export const ModalEditConduct: React.FC<Props> = ({
  selectedConduct,
  showModal,
  setShowModal,
  setSelectedConduct,
  updateConduct,
  firmId,
}) => {
  const [loadingSubmit, setLoadingSubmit] = React.useState(false)

  const [form] = Form.useForm()

  React.useEffect(() => {
    if (selectedConduct) {
      form.setFieldsValue({
        ...selectedConduct,
        time_stamp: selectedConduct.time_stamp ? moment(selectedConduct.time_stamp) : null,
      })
    } else {
      form.resetFields()
    }
  }, [form, selectedConduct])

  const handleUpdateRow = () => {
    const submitData = {
      email: form.getFieldValue('email'),
      time_stamp: selectedConduct
        ? selectedConduct.time_stamp
        : form.getFieldValue('time_stamp')
        ? moment(form.getFieldValue('time_stamp')).format('YYYY-MM-DD')
        : undefined,
      code_of_conduct: form.getFieldValue('code_of_conduct'),
      original_code_of_conduct: selectedConduct?.code_of_conduct,
      first_name: form.getFieldValue('first_name'),
      last_name: form.getFieldValue('last_name'),
      job_title: form.getFieldValue('job_title'),
      company: form.getFieldValue('company'),
      initials: form.getFieldValue('initials'),
      source: form.getFieldValue('source'),
      agreement_version: form.getFieldValue('agreement_version'),
      original_agreement_version: selectedConduct?.agreement_version,
    }

    form.validateFields(['email', 'time_stamp']).then(() => {
      setLoadingSubmit(true)
      selectedConduct
        ? APIService.firmDetailService
            .updateCodeOfConduct(firmId, submitData)
            .then(() => {
              notification.success({ message: 'Code Of Conduct Updated!' })
              updateConduct()
            })
            .catch((err) => {
              console.error({ err })
              notification.error({ message: 'Code Of Conduct Update Failed!' })
            })
            .finally(() => {
              setLoadingSubmit(false)
              setShowModal(false)
              setSelectedConduct(undefined)
              form.resetFields()
            })
        : APIService.firmDetailService
            .createCodeOfConduct(firmId, submitData)
            .then(() => {
              notification.success({ message: 'Code Of Conduct Created!' })
              updateConduct()
            })
            .catch((err) => {
              console.error({ err })
              notification.error({ message: 'Code Of Conduct Create Failed!' })
            })
            .finally(() => {
              setLoadingSubmit(false)
              setShowModal(false)
              setSelectedConduct(undefined)
              form.resetFields()
            })
    })
  }

  const LIST_FIELD = [
    {
      name: 'email',
      label: 'E-mail',
      placeHolder: 'E-mail',
      prefix: <MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
      rules: [{ required: true, message: 'Email is required' }],
    },
    {
      name: 'time_stamp',
      label: 'Time Stamp',
      placeHolder: 'Time Stamp',
      prefix: <FieldTimeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
      rules: [{ required: true, message: 'Time Stamp is required' }],
      isDate: true,
    },
    {
      name: 'code_of_conduct',
      label: 'Code Of Conduct',
      options: [
        { text: 'Yes', value: 'Yes' },
        { text: 'No', value: 'No' },
      ],
      placeHolder: 'Code Of Conduct',
    },
    {
      name: 'first_name',
      label: 'First Name',
      placeHolder: 'First Name',
      prefix: <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
      maxLength: 60,
    },
    {
      name: 'last_name',
      label: 'Last Name',
      placeHolder: 'Last Name',
      prefix: <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
      maxLength: 60,
    },
    {
      name: 'job_title',
      label: 'Job Title',
      placeHolder: 'Job Title',
      prefix: <MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
      maxLength: 60,
    },
    {
      name: 'company',
      label: 'Company',
      placeHolder: 'Company',
      prefix: <HomeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
      maxLength: 60,
    },
    {
      name: 'initials',
      label: 'Initials',
      placeHolder: 'Initials',
      prefix: <InfoOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
      maxLength: 30,
    },
    {
      name: 'source',
      label: 'Source',
      placeHolder: 'Source',
      prefix: <InfoOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
      maxLength: 30,
    },
    {
      name: 'agreement_version',
      label: 'Agreement Version',
      placeHolder: 'Agreement Version',
      prefix: <HighlightOutlined style={{ color: 'rgba(0,0,0,.25)' }} />,
      maxLength: 30,
    },
  ]

  return (
    <StyledModal
      title={selectedConduct ? 'Edit Code Of Conduct' : 'Create New Code Of Conduct'}
      visible={showModal}
      width={600}
      okText={
        loadingSubmit ? (
          <>
            <Spin style={{ marginRight: 4 }} />
          </>
        ) : (
          'OK'
        )
      }
      onOk={() => handleUpdateRow()}
      onCancel={() => {
        setSelectedConduct(undefined)
        setShowModal(false)
      }}
    >
      <div style={{ marginTop: '1rem' }}>
        <Form layout="vertical" form={form}>
          <Row gutter={8}>
            {LIST_FIELD.map((item) => {
              return item.isDate ? (
                <FormItemDatePicker
                  name={item.name}
                  label={item.label}
                  rules={item.rules}
                  disabled={!!selectedConduct}
                />
              ) : item.options ? (
                <Col span={12} key={item.name}>
                  <FormItemSelect
                    name={item.name}
                    label={item.label}
                    options={item.options}
                    placeholder={item.placeHolder}
                  />
                </Col>
              ) : (
                <Col span={12} key={item.name}>
                  <FormItemInput
                    disabled={item.name === 'email' && !!selectedConduct}
                    rules={item.rules}
                    name={item.name}
                    label={item.label}
                    prefix={item.prefix}
                    placeholder={item.placeHolder}
                    maxLength={item.maxLength}
                  />
                </Col>
              )
            })}
          </Row>
        </Form>
      </div>
    </StyledModal>
  )
}
