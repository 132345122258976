import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Col, Divider, Form, Input, notification, Row, Spin, Switch } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../shared/api'
import { UniverseManagerCreateType, UniverseManagerList } from '../../shared/api/models/UniverseManager'

const Wrapper = styled('div')``

type Props = {
  selectedUniverseManager?: UniverseManagerList
  showFormModal: boolean
  setShowFormModal: (value: boolean) => void
  setSelectedUniverseManager: (value: UniverseManagerList | undefined) => void
  updateUniverseManager: () => void
}

const UniverseManagerFormModal: React.FC<Props> = ({
  selectedUniverseManager,
  showFormModal,
  setShowFormModal,
  setSelectedUniverseManager,
  updateUniverseManager,
}) => {
  const [loadingSubmit, setLoadingSubmit] = React.useState(false)

  const [form] = Form.useForm()

  React.useEffect(() => {
    if (selectedUniverseManager) {
      form.setFieldsValue(selectedUniverseManager)
    } else {
      form.resetFields()
    }
  }, [selectedUniverseManager, form])

  const submitFormUniverseManager = () => {
    form.validateFields(['universe_id', 'universe_name', 'universe_description']).then(() => {
      const params: Partial<UniverseManagerCreateType> = selectedUniverseManager
        ? {
            name: form.getFieldValue('universe_name'),
            description: form.getFieldValue('universe_description'),
            is_active: form.getFieldValue('is_active'),
          }
        : {
            universe_id: parseInt(form.getFieldValue('universe_id')),
            name: form.getFieldValue('universe_name'),
            description: form.getFieldValue('universe_description'),
            is_active: form.getFieldValue('is_active') !== undefined ? form.getFieldValue('is_active') : null,
          }
      setLoadingSubmit(true)
      selectedUniverseManager
        ? APIService.universeManagerService
            .updateUniverseManager(selectedUniverseManager.universe_id, params)
            .then(() => {
              notification.success({ message: 'Universe Manager Updated!' })
              updateUniverseManager()
            })
            .catch((err: any) => {
              console.error({ err })
              notification.error({ message: 'Universe Manager Update Failed!' })
            })
            .finally(() => {
              form.resetFields()
              setLoadingSubmit(false)
              setShowFormModal(false)
              setSelectedUniverseManager(undefined)
            })
        : APIService.universeManagerService
            .createUniverseManager(params)
            .then(() => {
              notification.success({ message: 'Universe Manager Created!' })
              updateUniverseManager()
            })
            .catch((err: any) => {
              console.error({ err })
              notification.error({ message: 'Universe Manager Create Failed!' })
            })
            .finally(() => {
              form.resetFields()
              setLoadingSubmit(false)
              setShowFormModal(false)
              setSelectedUniverseManager(undefined)
            })
    })
  }

  return (
    <Modal
      width={700}
      visible={showFormModal}
      title={selectedUniverseManager ? 'Update Universe Manager' : 'Add New Universe Manager'}
      okText={
        loadingSubmit ? (
          <>
            <Spin style={{ marginRight: 4 }} />
          </>
        ) : (
          'OK'
        )
      }
      onOk={submitFormUniverseManager}
      onCancel={() => {
        setShowFormModal(false)
        setSelectedUniverseManager(undefined)
      }}
    >
      <Wrapper>
        <Form layout="vertical" form={form}>
          <Divider orientation="left">Information</Divider>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="universe_id"
                label="Universe ID"
                rules={[{ required: true, message: 'Universe ID is required' }]}
                style={{ display: 'flex', flex: 1 }}
              >
                <Input placeholder="Universe ID..." disabled={!!selectedUniverseManager} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="universe_name"
                label="Name"
                rules={[{ required: true, message: 'Name is required' }]}
                style={{ display: 'flex', flex: 1 }}
              >
                <Input placeholder="Name..." />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="universe_description"
                label="Description"
                rules={[{ required: true, message: 'Description is required' }]}
                style={{ display: 'flex', flex: 1 }}
              >
                <Input placeholder="Description..." />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="is_active"
                valuePropName="checked"
                label="Is Active?"
                rules={[{ required: true, message: 'Is Active is required' }]}
                style={{ display: 'flex', flex: 1 }}
              >
                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Wrapper>
    </Modal>
  )
}

export default UniverseManagerFormModal
