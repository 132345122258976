import React from 'react'
import { ExFundDetailLabelSearchContext } from '../api/context/ExFundDetailLabelSearchContext'
import { Colors } from '../colors'
type Props = {
  value: string
  boldText?: string
  subtitle?: React.ReactElement
}
export const RenderLabelExFund: React.FC<Props> = ({ value, subtitle }) => {
  const { boldText } = React.useContext(ExFundDetailLabelSearchContext)
  const index = value?.toUpperCase().indexOf(boldText.toUpperCase())
  return (
    <span>
      {boldText === '' || index === -1 ? (
        <span style={{ whiteSpace: 'normal' }}>
          {value}
          {subtitle}
        </span>
      ) : (
        <span style={{ whiteSpace: 'normal' }}>
          <span>{value.slice(0, index)}</span>
          <span style={{ fontWeight: 'bold', background: `${Colors.border}` }}>
            {value.slice(index, index + boldText.length)}
          </span>
          <span>{value.slice(index + boldText.length, value.length)}</span>
          {subtitle}
        </span>
      )}
    </span>
  )
}
