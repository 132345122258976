import { Col, Modal, Row, Spin, Tabs } from 'antd'
import Form from 'antd/lib/form'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../../../shared/api'
import { FundDetailDataContext } from '../../../../shared/api/context/FundDetailDataContext'
import { DividerForm } from '../../../../shared/components/DividerForm'
import {
  FormItemCheckBox,
  FormItemDatePicker,
  FormItemInputNumber,
  FormItemSelect,
} from '../../../../shared/components/FormItemComponents'
import { RenderLabelForm } from '../../../../shared/components/RenderLabelForm'
import { useServiceState } from '../../../../shared/hooks/useServiceState'
import { IndexManagerType } from '../../../../shared/api/models/IndexManager'
import { useForm } from 'antd/lib/form/util'

import {
  ConfirmedInfo,
  ExclusionReasonOptions,
  FUND_INFO_NO_DATA_LABEL,
  IndexEligibilityOptions,
  MonthlyOptions,
  QuarterlyOptions,
  ReviewerInfo,
  SubscriptionDayTypeDic,
  WaiveCriteriaForm,
  transformResponseToDataForm,
} from './utils'
import { FundDetailDropdownContext } from '../../../../shared/api/context/FundDetailDropdownContext'
import { FundIndexEligibilityDetailsResponse } from '../../../../shared/api/models/FundDetails'
import { CloseCircleFilled } from '@ant-design/icons'

type Props = {
  indexElgibleManager: IndexManagerType[]
}
export const FundIndexEligibilityDetailsForm: React.FC<Props> = ({ indexElgibleManager }) => {
  const [showConfirm, setShowConfirm] = React.useState(false)
  const [activeKey, setActiveKey] = React.useState<string>()
  const [tempKey, setTempKey] = React.useState<string>()

  const { fundIndexEligibilityDetailChange, setFundIndexEligibilityDetailChange } = React.useContext(
    FundDetailDataContext,
  )

  React.useEffect(() => {
    if (!indexElgibleManager.length) {
      return
    }
    setActiveKey(`${indexElgibleManager[0].id}`)
  }, [indexElgibleManager])

  const handleTabChange = (key: string) => {
    if (!fundIndexEligibilityDetailChange) {
      setActiveKey(key)
      setTempKey(key)
      return
    }
    setTempKey(key)
    setShowConfirm(true)
  }

  const handleOk = () => {
    setFundIndexEligibilityDetailChange(undefined)
    setActiveKey(tempKey)
    setShowConfirm(false)
  }

  const handleCancel = () => {
    setShowConfirm(false)
  }

  return (
    <>
      <Tabs activeKey={activeKey} onChange={handleTabChange}>
        {indexElgibleManager.map((tabData) => {
          return (
            <Tabs.TabPane tab={tabData.name} key={`${tabData.id}`}>
              <TabContent data={tabData} isActive={activeKey === `${tabData.id}`} />
            </Tabs.TabPane>
          )
        })}
      </Tabs>
      <Modal title="Discard Changes?" visible={showConfirm} onOk={handleOk} onCancel={handleCancel}>
        <span>
          <p>You have unsaved changes. </p>
          <p>The changes here should be saved or discarded before continue.</p>
          Select <b>OK</b> to discard the changes or <b>Cancel</b> to continue.
        </span>
      </Modal>
    </>
  )
}

const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 1rem;
  padding-bottom: 1rem;
`

type TabContentProps = {
  data: IndexManagerType
  isActive?: boolean
}
const TabContent: React.FC<TabContentProps> = ({ data, isActive }) => {
  const [form] = useForm()

  const { fundDataDropdown } = React.useContext(FundDetailDropdownContext)

  const {
    dataFundDetail,
    setOriginalFundIndexEligibilityDetail,
    fundIndexEligibilityDetailChange,
    setFundIndexEligibilityDetailChange,
  } = React.useContext(FundDetailDataContext)

  const { invoke: getDetails, data: details, loading: loadingGetDetails } = useServiceState(
    APIService.fundDetailService.fetchFundIndexEligibilityDetails,
  )

  // set origin data
  React.useEffect(() => {
    if (!isActive) {
      return
    }
    const fundId: string | undefined = dataFundDetail?.fund_id
    if (!fundId) {
      setOriginalFundIndexEligibilityDetail(undefined)
      return
    }
    if (!details) {
      setOriginalFundIndexEligibilityDetail({
        fund_id: fundId,
        index_family_id: data.id,
      })
      return
    }
    const currentBody: Partial<FundIndexEligibilityDetailsResponse> = transformResponseToDataForm({
      fund_id: fundId,
      index_family_id: data.id,
      ...details,
    })
    setOriginalFundIndexEligibilityDetail(currentBody)
  }, [details, isActive])
  // refetch form data if changed data reset
  React.useEffect(() => {
    const fundId: string | undefined = dataFundDetail?.fund_id
    if (!fundId || !data.id || fundIndexEligibilityDetailChange) {
      return
    }
    getDetails({
      fundId,
      indexFamilyId: data.id,
    })
  }, [fundIndexEligibilityDetailChange])
  // set form data after fetch
  React.useEffect(() => {
    if (loadingGetDetails) {
      return
    }
    if (!details) {
      form.resetFields()
      return
    }
    form.setFieldsValue(transformResponseToDataForm(details))
  }, [loadingGetDetails])

  /////////////////////////////////////////
  const subscriptionInfo = React.useMemo(() => {
    if (!dataFundDetail) {
      return FUND_INFO_NO_DATA_LABEL
    }

    const dayType = SubscriptionDayTypeDic[`${dataFundDetail.subscription_day_type}`]

    if (!dayType || !dataFundDetail.subscription_days) {
      return FUND_INFO_NO_DATA_LABEL
    }

    return `(Fund Info: ${dataFundDetail.subscription_days} ${dayType} days)`
  }, [dataFundDetail])
  //
  const equityRedemptionInfo = React.useMemo(() => {
    if (!dataFundDetail || !fundDataDropdown?.equity_redemption || !dataFundDetail.equity_redemption) {
      return FUND_INFO_NO_DATA_LABEL
    }

    const codeDescription = fundDataDropdown.equity_redemption.find(
      (op) => op.code_code === dataFundDetail.equity_redemption,
    )?.code_description

    return codeDescription ? `(Fund Info: ${codeDescription})` : FUND_INFO_NO_DATA_LABEL
  }, [dataFundDetail, fundDataDropdown?.equity_redemption])
  //
  const fundGateInfo = React.useMemo(() => {
    if (!dataFundDetail || !dataFundDetail.gate_fund || !fundDataDropdown) {
      return
    }

    return fundDataDropdown.gate_fund.find((op) => op.code_code === dataFundDetail.gate_fund)?.code_description
  }, [dataFundDetail, fundDataDropdown])
  //
  const fundInvestorInfo = React.useMemo(() => {
    if (!dataFundDetail || !dataFundDetail.gate_investor || !fundDataDropdown) {
      return
    }
    return fundDataDropdown.gate_investor.find((op) => op.code_code === dataFundDetail.gate_investor)?.code_description
  }, [dataFundDetail, fundDataDropdown])
  /////////////////////////////////////////
  return (
    <TabWrapper>
      <Form
        form={form}
        onValuesChange={(changedValues) => {
          const convertObject = Object.keys(changedValues).reduce((acc, key) => {
            const curValue = changedValues[key]
            // check if it is a valid moment object
            if (curValue && typeof curValue === 'object' && curValue?._isAMomentObject && !curValue?._isValid) {
              return { ...acc, [key]: null }
            }
            return { ...acc, [key]: curValue !== undefined ? curValue : null }
          }, {})
          setFundIndexEligibilityDetailChange({
            ...fundIndexEligibilityDetailChange,
            ...convertObject,
          })
        }}
      >
        <Spin tip="Loading" spinning={loadingGetDetails}>
          <Row>
            <Col span={9}>
              <FormItemDatePicker
                label={<RenderLabelForm value={`Performance Added to ${data.name} as of`} />}
                name={`performance_added_to_family_as_of`}
                labelCol={15}
                wrapperCol={8}
                picker="month"
                allowClear
              />
            </Col>
            <Col span={9}>
              <FormItemDatePicker
                label={<RenderLabelForm value={`Removed from ${data.name} after`} />}
                name={`removed_from_family_after`}
                labelCol={15}
                wrapperCol={8}
                picker="month"
                allowClear
              />
            </Col>
            <Col span={6}>
              <FormItemCheckBox text={`${data.name} Pending Removal`} name={`family_pending_removal`} />
            </Col>
          </Row>

          <DividerForm
            text={`Accepting New Investments (Fund Info: ${dataFundDetail?.accepts_new_investment ? 'Yes' : 'No'})`}
          />
          <ConfirmedInfo prefixName="accepting_new_investments">
            <Col span={6}>
              <FormItemSelect
                name="accepting_new_investments"
                label="Response to HFR Index Admin"
                labelAlign="left"
                labelCol={23}
                wrapperCol={23}
                options={[
                  {
                    value: 'yes',
                    text: 'Yes',
                  },
                  {
                    value: 'no',
                    text: 'No',
                  },
                ]}
                allowClear
              />
            </Col>
          </ConfirmedInfo>

          <DividerForm text={`Subscriptions ${subscriptionInfo}`} />
          <ConfirmedInfo prefixName="subscriptions_monthly">
            <Col span={6}>
              <FormItemSelect
                name="subscriptions_monthly"
                label="Response to HFR Index Admin"
                labelAlign="left"
                placeholder="monthly or less"
                labelCol={23}
                wrapperCol={23}
                options={MonthlyOptions}
                allowClear
              />
            </Col>
          </ConfirmedInfo>

          <DividerForm
            text={`Subscriptions, Advanced Notice ${
              dataFundDetail?.subscription_advance_notice
                ? `(Fund Info: ${dataFundDetail.subscription_advance_notice} days)`
                : FUND_INFO_NO_DATA_LABEL
            }`}
          />
          <ConfirmedInfo prefixName="subscriptions_advanced">
            <Col span={6}>
              <FormItemInputNumber
                name="subscriptions_advanced"
                label={<RenderLabelForm value="Response to HFR Index Admin" />}
                labelAlign="left"
                labelCol={23}
                wrapperCol={23}
                placeholder="30 days or less"
                min={0}
                max={30}
                allowClear
              />
            </Col>
          </ConfirmedInfo>

          <DividerForm text={`Redemptions ${equityRedemptionInfo}`} />
          <ConfirmedInfo prefixName="redemptions_quarterly">
            <Col span={6}>
              <FormItemSelect
                name="redemptions_quarterly"
                label="Response to HFR Index Admin"
                labelAlign="left"
                placeholder="quarterly or less"
                labelCol={23}
                wrapperCol={23}
                options={QuarterlyOptions}
                allowClear
              />
            </Col>
          </ConfirmedInfo>

          <DividerForm
            text={`Redemptions, Advanced Notice ${
              dataFundDetail?.redemption_advance_notice
                ? `(Fund Info: ${dataFundDetail.redemption_advance_notice} days)`
                : FUND_INFO_NO_DATA_LABEL
            }`}
          />
          <ConfirmedInfo prefixName="redemptions_advanced">
            <Col span={6}>
              <FormItemInputNumber
                name="redemptions_advanced"
                label={<RenderLabelForm value="Response to HFR Index Admin" />}
                labelAlign="left"
                labelCol={23}
                wrapperCol={23}
                placeholder="90 days or less"
                min={0}
                max={90}
                allowClear
              />
            </Col>
          </ConfirmedInfo>

          <WaiveCriteriaForm name="lockup" label="Lockup" fundInfo={dataFundDetail?.is_lockup ? 'Yes' : 'No'} />
          <WaiveCriteriaForm name="gate_investor" label="Gate Investor" fundInfo={fundGateInfo} />
          <WaiveCriteriaForm name="gate_fund" label="Gate Fund" fundInfo={fundInvestorInfo} />

          <DividerForm text="Eligibility Status" />
          <ReviewerInfo prefixName="eligibility_status">
            <Col span={8}>
              <FormItemSelect
                name={'eligibility_status'}
                label={<RenderLabelForm value="Eligibility Status" />}
                labelCol={10}
                wrapperCol={13}
                options={IndexEligibilityOptions}
                allowClear
              />
            </Col>
          </ReviewerInfo>

          <DividerForm text="Exclusion Reason" />
          <ReviewerInfo prefixName="exclusion_reason">
            <Col span={8}>
              <FormItemSelect
                name="exclusion_reason"
                label="Exclusion Reason"
                labelCol={10}
                wrapperCol={13}
                options={ExclusionReasonOptions}
                allowClear
              />
            </Col>
          </ReviewerInfo>
        </Spin>
      </Form>
    </TabWrapper>
  )
}
