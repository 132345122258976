import { SearchOutlined } from '@ant-design/icons'
import { Button, Input, Space, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import styled from 'styled-components'
import APIService from '../../shared/api'
import { FundDetailDropdownContext } from '../../shared/api/context/FundDetailDropdownContext'
import { FundBenchmarkResponse } from '../../shared/api/models/Benchmark'
import { useServiceState } from '../../shared/hooks/useServiceState'
import { FundFirmTypeEnum } from '../../slice/appSettingsSlice'
const Wrapper = styled('div')`
  width: 100%;
  p {
    margin: 0;
  }
  > .ant-tabs-card .ant-tabs-content {
    margin-top: -16px;
  }
  > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
    padding: 16px;
  }
  > .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
  }
  .ant-table-content {
    min-height: calc(100vh - 380px);
  }
`
type ListTabType = {
  id: string
  name: string
  type: FundFirmTypeEnum | 'list-funds'
}
type Props = {
  fundId: string
  listTab: ListTabType[]
  setListTab: (value: ListTabType[]) => void
  setActiveTab: (value: string) => void
}
export const ListOfFundBenchmark: React.FC<Props> = ({ fundId, listTab, setListTab, setActiveTab }) => {
  const { dataBenchmark } = React.useContext(FundDetailDropdownContext)
  const { invoke: getFundBenchmark, data: dataFundBenchmark, loading } = useServiceState(
    APIService.benchmarkService.getFundBenchmark,
  )
  const searchInput = React.useRef<Input>(null)

  React.useEffect(() => {
    getFundBenchmark(fundId)
  }, [fundId, getFundBenchmark])

  const handleSearch = (selectedKeys: React.ReactText[], confirm: (() => void) | undefined, _dataIndex: string) => {
    confirm && confirm()
    // setSearchText(selectedKeys[0])
    // setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters: (() => void) | undefined) => {
    clearFilters && clearFilters()
    // setSearchText(undefined)
  }

  const getColumnSearchProps = (dataIndex: string): ColumnProps<FundBenchmarkResponse> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex as keyof FundBenchmarkResponse]
        ? record[dataIndex as keyof FundBenchmarkResponse]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select())
      }
    },
  })

  const columns: ColumnProps<FundBenchmarkResponse>[] = [
    {
      title: 'Fund ID',
      dataIndex: 'fund_id',
      key: 'fund_id',
      width: 135,
      sorter: (a, b) => {
        if (a.fund_id && b.fund_id) {
          return a.fund_id.localeCompare(b.fund_id)
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('fund_id'),
    },
    {
      title: 'Fund Name',
      dataIndex: 'fund_name',
      key: 'fund_name',
      render: (_, record) => (
        <Button
          type="link"
          style={{ padding: '4px 8px' }}
          onClick={() => {
            if (!listTab.find((e) => e.id.split('-')[1] === record.fund_id)) {
              setListTab([
                ...listTab,
                { id: 'funds-' + record.fund_id, name: record.fund_name, type: FundFirmTypeEnum.FUNDS },
              ])
            }
            setActiveTab('funds-' + record.fund_id)
          }}
        >
          {record.fund_name}
        </Button>
      ),
      sorter: (a, b) => {
        if (a.fund_name && b.fund_name) {
          return a.fund_name.localeCompare(b.fund_name)
        }
        return 0
      },
      width: 250,
      ellipsis: true,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('fund_name'),
    },
    {
      title: 'Benchmark 1',
      dataIndex: 'benchmark1',
      key: 'benchmark1',
      width: 250,
      sorter: (a, b) => {
        if (a.benchmark1 && b.benchmark1) {
          return a.benchmark1.localeCompare(b.benchmark1)
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <div>
          {dataBenchmark && dataBenchmark.length > 0 && dataBenchmark.find((item) => item.fund_id === text)?.fund_name}
        </div>
      ),
    },
    {
      title: 'Benchmark 2',
      dataIndex: 'benchmark2',
      key: 'benchmark2',
      width: 250,
      render: (text) => (
        <div>
          {dataBenchmark && dataBenchmark.length > 0 && dataBenchmark.find((item) => item.fund_id === text)?.fund_name}
        </div>
      ),
      sorter: (a, b) => {
        if (a.benchmark2 && b.benchmark2) {
          return a.benchmark2.localeCompare(b.benchmark2)
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Benchmark 3',
      dataIndex: 'benchmark3',
      key: 'benchmark3',
      width: 250,
      render: (text) => (
        <div>
          {dataBenchmark && dataBenchmark.length > 0 && dataBenchmark.find((item) => item.fund_id === text)?.fund_name}
        </div>
      ),

      sorter: (a, b) => {
        if (a.benchmark3 && b.benchmark3) {
          return a.benchmark3.localeCompare(b.benchmark3)
        }
        return 0
      },
      sortDirections: ['descend', 'ascend'],
    },
  ]
  return (
    <Wrapper className="card-container">
      <Table
        rowKey="fund_id"
        dataSource={dataFundBenchmark}
        columns={columns}
        scroll={{ y: 'calc(100vh - 380px)', x: 'min-content' }}
        loading={loading}
      />
    </Wrapper>
  )
}
